import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Icon, Popup, Select, Button, Input } from "semantic-ui-react";
import { Form as NewComponentForm, SideNav } from "../../new-components";
import { staffOvertimeFetchData } from "../../store/actions/overtime";
import {
  confirmStaffSalary,
  fetchConfirmedStaffSalary,
  fetchStaffSalary,
  resetStaffSalary,
} from "../../store/actions/staffSalary";
import { GetEndDate, GetStartDate } from "../../utilities/CommonFunctions";
import { generateBracketOptions } from "./CreateStaffSalaryBrackets";
import HoverTable from "HoverTable";
import Description from "Description";
import DatePicker from "react-datepicker";
import { BRACKET } from "../../containers/Staff/data";
import { Translate } from "react-auto-translate";

const initAddition = {
  value: 0,
  option: {
    id: -1,
    label: "Select Earning Option",
    value: "none",
    include_in_leave_calc: true,
  },
};

const initDeduction = {
  value: 0,
  option: {
    id: -1,
    label: "Select Deduction Option",
    value: "none",
    include_in_leave_calc: true,
  },
};
const commissionBreakUpHeader = ["StartDate", "EndDate", "Revenue", "Commission"];
const commisionBreakUpRender = [
  ({ start_date }) => {
    return <Description title={start_date} />;
  },
  ({ end_date }) => {
    return <Description title={end_date} />;
  },
  ({ revenue }) => {
    return <Description title={revenue} />;
  },
  ({ commission }) => {
    return <Description title={commission} />;
  },
];

function overtimeCalculator(earnings, deductions, ot) {
  const totalInHand = grossSalaryInHand(earnings, deductions);
  const overTime = (totalInHand / 30 / 24) * ot;
  return overTime.toFixed(2);
}

function grossSalaryInHand(earnings, deductions) {
  let total = 0;
  earnings.forEach((earning) => {
    total += parseInt(earning.value) || 0;
  });
  deductions.forEach((deduction) => {
    if (deduction.option.value !== "unpaid-leaves") {
      total -= parseInt(deduction.value) || 0;
    }
  });
  return total;
}

function AssignStaffSalary({ staffId, close, condition, openPaySlip, activeStaff }) {
  const dispatch = useDispatch();
  const defaultSelectedMonth = moment(new Date()).format("YYYY-MM");
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(defaultSelectedMonth);
  const [showConfirmedSalary, setShowConfirmedSalary] = useState(true);
  const [staffSalaryError, setStaffSalaryError] = useState(false);
  const [openSalaryEarning, setOpenSalaryEarning] = useState(true);
  const [openSalarydeduction, setOpenSalarydeduction] = useState(true);
  const [staffSalaryForm, setStaffSalaryForm] = useState({
    addition: [initAddition],
    deduction: [initDeduction],
  });
  const staffOvertime = useSelector((state) => state.staffOvertime);
  const staffSalary = useSelector((state) => state.staffSalary.data);
  const brackets = useSelector((state) => state.staffBracket.activeBrackets);
  const staffBracket = brackets.filter((bracket) => bracket.id === staffSalary?.bracket);
  const confirmedSalary = useSelector((state) => state.staffSalary.confirmedSalary) || null;
  const totalSalary = confirmedSalary
    ? confirmedSalary.addition.reduce((total, inc) => total + inc.value, 0) -
      confirmedSalary.deduction.reduce((total, inc) => total + inc.value, 0)
    : null;

  function showStaffOptions() {
    return activeStaff.map((staff) => {
      return { id: staff.id, text: staff.user.name, value: staff.id };
    });
  }

  const confirmStaffSalaryHandler = () => {
    const { addition, deduction } = staffSalaryForm;
    const payload = {
      staff: selectedStaff,
      bracket: staffSalary.bracket,
      month: selectedMonth + "-01",
    };
    payload.addition = addition
      .filter((i) => i.option.label !== "Select Earning Option")
      .map(({ value, option }) => ({
        value: value || 0,
        name: option.label,
      }));
    payload.deduction = deduction
      .filter((i) => i.option.label !== "Select Deduction Option")
      .map(({ value, option }) => ({
        value: value || 0,
        name: option.label,
      }));
    dispatch(confirmStaffSalary(selectedStaff, payload));
    reset();
  };

  const commisionBreakUpUI = () => {
    const commissionBreakUp = staffSalary?.commission?.break_up.length
      ? staffSalary?.commission?.break_up
      : [
          {
            start_date: staffSalary?.commission?.start_date,
            end_date: staffSalary?.commission?.end_date,
            revenue: staffSalary?.commission?.revenue,
            commission: staffSalary?.commission?.commission,
          },
        ];
    return (
      <div>
        <HoverTable header={commissionBreakUpHeader} src={commissionBreakUp} renderSpecial={commisionBreakUpRender} />
      </div>
    );
  };

  function leaveValueCalculator(addition, deduction, selectedMonth) {
    let earnings = 0,
      deductions = 0;
    addition.forEach((i) => {
      if (i.option?.include_in_leave_calc && !isNaN(parseFloat(i.value))) {
        earnings += parseFloat(i.value);
      }
    });
    deduction.forEach((i) => {
      if (i.option?.include_in_leave_calc && !isNaN(parseFloat(i.value))) {
        deductions += parseFloat(i.value);
      }
    });

    const total = earnings - deductions;
    const temp = new Date(selectedMonth);
    const totalDays = new Date(temp.getFullYear(), temp.getMonth() + 1, 0).getDate();
    if (total < 0) {
      return 0;
    }
    return (total / totalDays).toFixed(2);
  }

  function updateStaffLeaveCalculation() {
    const { leaves } = staffSalary;
    const { addition, deduction } = staffSalaryForm;
    const leaveIndex = deduction.findIndex((item) => item.option.value === "unpaid-leaves");
    const newDeduction = deduction;
    const totalSalary = leaveValueCalculator(addition, deduction, selectedMonth) || 0;

    newDeduction[leaveIndex] = {
      value: (totalSalary * leaves).toFixed(2),
      option: {
        label: `Unpaid leaves (${totalSalary} X ${leaves} days)`,
        value: "unpaid-leaves",
      },
    };

    setStaffSalaryForm({
      ...staffSalaryForm,
      deduction: [...newDeduction],
    });
  }

  useEffect(() => {
    if (staffBracket.length) {
      const { addition, deduction } = staffBracket[0];
      let totalOvertimeValue = 0;
      staffOvertime.forEach((ele) => {
        totalOvertimeValue = totalOvertimeValue + ele.duration_in_hours;
      });
      const temp = { ...staffSalaryForm };
      temp.addition = addition.map(({ name, value, include_in_leave_calc }) => {
        const entry = BRACKET.addition.filter((i) => i.value === name);
        if (entry.length === 1) {
          return {
            value,
            option: {
              ...entry[0],
              include_in_leave_calc: include_in_leave_calc,
            },
          };
        } else {
          return {
            value,
            option: {
              id: -1,
              value: name,
              label: name,
            },
          };
        }
      });
      temp.addition.push({
        value: staffSalary?.commission?.commission || 0,
        option: {
          label: "Commission",
          value: "commission",
        },
      });
      temp.deduction = deduction.map(({ name, value, include_in_leave_calc }) => {
        const entry = BRACKET.deduction.filter((i) => i.value === name);
        if (entry.length === 1) {
          return {
            value,
            option: {
              ...entry[0],
              include_in_leave_calc: include_in_leave_calc,
            },
          };
        } else {
          return {
            value,
            option: {
              id: -1,
              value: name,
              label: name,
            },
          };
        }
      });

      const totalSalary = leaveValueCalculator(temp.addition, temp.deduction, selectedMonth);
      // Overtime is here
      temp.addition.push({
        value: overtimeCalculator(temp.addition, temp.deduction, totalOvertimeValue),
        option: {
          label: `Overtime`,
          value: "overtime",
        },
      });
      temp.deduction.push({
        value: (totalSalary * staffSalary.leaves).toFixed(2),
        option: {
          label: `Unpaid leaves (${totalSalary} X ${staffSalary.leaves} days)`,
          value: "unpaid-leaves",
        },
      });
      setStaffSalaryForm({ ...temp });
      setStaffSalaryError(false);
    } else if (selectedMonth && selectedStaff && staffBracket.length === 0) {
      setStaffSalaryForm({
        addition: [initAddition],
        deduction: [initDeduction],
      });
      setStaffSalaryError(true);
      dispatch(resetStaffSalary());
    }
  }, [staffSalary]);

  useEffect(() => {
    if (staffId) {
      setSelectedStaff(staffId);
    }
  }, [staffId]);

  useEffect(() => {
    if (selectedMonth && selectedStaff) {
      const date = new Date(moment(selectedMonth, "YYYY-MM"));
      dispatch(fetchStaffSalary(selectedStaff, selectedMonth));
      dispatch(fetchConfirmedStaffSalary(selectedStaff, selectedMonth));
      dispatch(staffOvertimeFetchData(GetStartDate(date), GetEndDate(date), selectedStaff));
    }
  }, [selectedStaff, selectedMonth]);

  function reset() {
    setSelectedStaff(null);
    setSelectedMonth(defaultSelectedMonth);
    setStaffSalaryError(false);
    setStaffSalaryForm({
      addition: [initAddition],
      deduction: [initDeduction],
    });
    close();
  }

  return (
    <SideNav condition={condition} onCross={() => reset()}>
      <NewComponentForm style={{ padding: "20px 0px" }}>
        <NewComponentForm.Title>
          <Translate>Staff Salary</Translate>
        </NewComponentForm.Title>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Select
            style={{ padding: "16px 10px", zIndex: "20" }}
            className="StaffAttendanceSelect"
            value={selectedStaff}
            onChange={(e, { value }) => setSelectedStaff(value)}
            placeholder={<Translate>Select Staff</Translate>}
            options={showStaffOptions()}
          />
          {!!confirmedSalary && (
            <Button
              className="StaffAttendanceSelect"
              content={<Translate>Payslip</Translate>}
              onClick={() => {
                openPaySlip(selectedStaff);
              }}
            />
          )}
        </div>
        {staffSalaryError && (
          <p className="errors">
            <span>
              <Translate>No Salary has been assigned to this staff.</Translate>
            </span>
          </p>
        )}
        <div className="StaffAttendanceChangeMonth salaryDatePickerContainer">
          <h4 className="StaffAttendanceSubText">
            <Translate>Select Month</Translate>
          </h4>
          <DatePicker
            selected={moment(selectedMonth, "YYYY-MM").toDate()}
            onChange={(date) => {
              const monthYear = moment(date).format("YYYY-MM");
              setSelectedMonth(monthYear);
            }}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            customInput={<Input disabled={true} icon="calendar" />}
          />
        </div>

        {confirmedSalary && (
          <>
            <p className="info">
              <Icon name="info" color="teal" />
              <span>
                <Translate>
                  Salary for this month already exists. Confirming new salary here will override the below salary.
                </Translate>
              </span>
            </p>
            <NewComponentForm.SubHeading
              isOpen={showConfirmedSalary}
              onClick={() => setShowConfirmedSalary(!showConfirmedSalary)}
            >
              <Translate>Confirmed Salary</Translate>
            </NewComponentForm.SubHeading>
            {showConfirmedSalary && (
              <table className="existing-salary-table">
                <tbody>
                  <tr className="head">
                    <td>
                      <Translate>Earning</Translate>
                    </td>
                  </tr>
                  {confirmedSalary.addition.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        <Translate>{item.name}</Translate>
                      </td>
                      <td>&#x20B9; {item.value}</td>
                    </tr>
                  ))}
                  <tr className="head">
                    <td>
                      <Translate>Deduction</Translate>
                    </td>
                  </tr>
                  {confirmedSalary.deduction.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        <Translate>{item.name}</Translate>
                      </td>
                      <td>&#x20B9; {item.value}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                  </tr>
                  <tr className="head">
                    <td>
                      <Translate>Total</Translate>
                    </td>
                    <td>&#x20B9; {totalSalary}</td>
                  </tr>
                </tfoot>
              </table>
            )}
          </>
        )}

        {!selectedStaff || selectedMonth === "" ? (
          <p className="info">
            <Icon name="info" />
            <span>
              <Translate>Select month to fetch their salary information.</Translate>
            </span>
          </p>
        ) : (
          !staffSalaryError && (
            <p className="info">
              <Icon name="info" />
              <span>
                <Translate>Only checked ones are included in unpaid leaves calculation.</Translate>
              </span>
            </p>
          )
        )}
        {!selectedStaff || selectedMonth === "" || staffSalaryError ? null : (
          <>
            <NewComponentForm.SubHeading
              isOpen={openSalaryEarning}
              onClick={() => setOpenSalaryEarning(!openSalaryEarning)}
            >
              <Translate> Earnings</Translate>
            </NewComponentForm.SubHeading>
            {openSalaryEarning && (
              <NewComponentForm.FieldContainer>
                {staffSalaryForm.addition.map((earning, index) => (
                  <NewComponentForm.SingleLineFieldsContainer key={index}>
                    <Checkbox
                      style={{ marginRight: "15px" }}
                      onChange={() => {
                        let earnings = staffSalaryForm.addition;
                        earnings[index].option.include_in_leave_calc = !earning?.option?.include_in_leave_calc;
                        setStaffSalaryForm({
                          ...staffSalaryForm,
                          addition: [...earnings],
                        });
                        updateStaffLeaveCalculation();
                      }}
                      checked={earning?.option?.include_in_leave_calc}
                    />
                    <Popup
                      content={commisionBreakUpUI()}
                      disabled={earning.option.label !== "Commission"}
                      trigger={
                        <NewComponentForm.AsyncSelect
                          placeholder="Select Earning Option"
                          value={earning.option}
                          toDisplay={<Translate>{earning.option.label}</Translate>}
                        >
                          {generateBracketOptions("addition", staffSalaryForm.addition).map(
                            (earningOption, optionIndex) => {
                              return (
                                <NewComponentForm.AsyncSelectOption
                                  key={optionIndex}
                                  onClick={() => {
                                    const newEarnings = staffSalaryForm.addition;
                                    newEarnings[index] = {
                                      ...newEarnings[index],
                                      option: earningOption,
                                    };
                                    setStaffSalaryForm({
                                      ...staffSalaryForm,
                                      addition: [...newEarnings],
                                    });
                                    updateStaffLeaveCalculation();
                                  }}
                                  value={earningOption}
                                >
                                  <Translate>{earningOption.label}</Translate>
                                </NewComponentForm.AsyncSelectOption>
                              );
                            }
                          )}
                        </NewComponentForm.AsyncSelect>
                      }
                    />
                    <NewComponentForm.PriceFieldContainer>
                      <NewComponentForm.PriceField
                        style={{ cursor: "text" }}
                        value={earning.value}
                        onChange={(e) => {
                          if (earning.option.value !== "commission" && earning.option.value !== "overtime") {
                            const newEarnings = staffSalaryForm.addition;
                            newEarnings[index] = {
                              ...newEarnings[index],
                              value: e.target.value,
                            };
                            setStaffSalaryForm({
                              ...staffSalaryForm,
                              addition: [...newEarnings],
                            });
                            updateStaffLeaveCalculation();
                          }
                        }}
                        type="text"
                        placeholder={<Translate>Value</Translate>}
                      />
                    </NewComponentForm.PriceFieldContainer>
                    <div
                      className="RemovePositionService"
                      onClick={() => {
                        const newPositions = [
                          ...staffSalaryForm.addition.slice(0, index),
                          ...staffSalaryForm.addition.slice(index + 1, staffSalaryForm.addition.length),
                        ];
                        setStaffSalaryForm({
                          ...staffSalaryForm,
                          addition: [...newPositions],
                        });
                      }}
                    >
                      <Icon className="close" />
                    </div>
                  </NewComponentForm.SingleLineFieldsContainer>
                ))}
                <NewComponentForm.AddNewSelectField
                  onClick={() => {
                    const newAddition = [...staffSalaryForm.addition, initAddition];
                    const temp = {
                      ...staffSalaryForm,
                      addition: [...newAddition],
                    };
                    setStaffSalaryForm({ ...temp });
                  }}
                >
                  <Translate>Add Earning Option</Translate>
                </NewComponentForm.AddNewSelectField>
              </NewComponentForm.FieldContainer>
            )}
            <NewComponentForm.Divider> </NewComponentForm.Divider>
            <NewComponentForm.SubHeading
              isOpen={openSalarydeduction}
              onClick={() => setOpenSalarydeduction(!openSalarydeduction)}
            >
              <Translate>Deductions</Translate>
            </NewComponentForm.SubHeading>
            {openSalarydeduction && (
              <NewComponentForm.FieldContainer>
                {staffSalaryForm.deduction.map((deduction, index) => {
                  return (
                    <NewComponentForm.SingleLineFieldsContainer key={index}>
                      <Checkbox
                        style={{ marginRight: "15px" }}
                        onChange={() => {
                          let deductions = staffSalaryForm.deduction;
                          deductions[index].option.include_in_leave_calc = !deduction?.option?.include_in_leave_calc;
                          setStaffSalaryForm({
                            ...staffSalaryForm,
                            deduction: [...deductions],
                          });
                          updateStaffLeaveCalculation();
                        }}
                        checked={deduction?.option?.include_in_leave_calc}
                      />
                      <NewComponentForm.AsyncSelect
                        placeholder="Select Deduction Option"
                        value={deduction.option}
                        toDisplay={<Translate>{deduction.option.label}</Translate>}
                      >
                        {generateBracketOptions("deduction", staffSalaryForm.deduction).map(
                          (deductionOption, optionIndex) => {
                            return (
                              <NewComponentForm.AsyncSelectOption
                                key={optionIndex}
                                onClick={() => {
                                  const newDeductions = staffSalaryForm.deduction;
                                  newDeductions[index] = {
                                    ...newDeductions[index],
                                    option: deductionOption,
                                  };
                                  setStaffSalaryForm({
                                    ...staffSalaryForm,
                                    deduction: [...newDeductions],
                                  });
                                  updateStaffLeaveCalculation();
                                }}
                                value={deductionOption}
                              >
                                <Translate>{deductionOption.label}</Translate>
                              </NewComponentForm.AsyncSelectOption>
                            );
                          }
                        )}
                      </NewComponentForm.AsyncSelect>
                      <NewComponentForm.PriceFieldContainer>
                        <NewComponentForm.PriceField
                          style={{ cursor: "text" }}
                          value={deduction.value}
                          onChange={(e) => {
                            const newDeductions = staffSalaryForm.deduction;
                            newDeductions[index] = {
                              ...newDeductions[index],
                              value: e.target.value,
                            };
                            setStaffSalaryForm({
                              ...staffSalaryForm,
                              deduction: [...newDeductions],
                            });
                            updateStaffLeaveCalculation();
                          }}
                          type="text"
                          placeholder={<Translate>Value</Translate>}
                        />
                      </NewComponentForm.PriceFieldContainer>
                      <div
                        className="RemovePositionService"
                        onClick={() => {
                          const newPositions = [
                            ...staffSalaryForm.deduction.slice(0, index),
                            ...staffSalaryForm.deduction.slice(index + 1, staffSalaryForm.deduction.length),
                          ];
                          setStaffSalaryForm({
                            ...staffSalaryForm,
                            deduction: [...newPositions],
                          });
                        }}
                      >
                        <Icon className="close" />
                      </div>
                    </NewComponentForm.SingleLineFieldsContainer>
                  );
                })}
                <NewComponentForm.AddNewSelectField
                  onClick={() => {
                    setStaffSalaryForm({
                      ...staffSalaryForm,
                      deduction: [...staffSalaryForm.deduction, initDeduction],
                    });
                  }}
                >
                  <Translate>Add Deduction Option</Translate>
                </NewComponentForm.AddNewSelectField>
              </NewComponentForm.FieldContainer>
            )}
            <NewComponentForm.SubmitButton disabled={staffSalaryError} onClick={confirmStaffSalaryHandler}>
              <Translate>Confirm</Translate>
            </NewComponentForm.SubmitButton>
          </>
        )}
      </NewComponentForm>
    </SideNav>
  );
}

export default AssignStaffSalary;
