import React, { useEffect, useState, useCallback } from "react";
import "./index.scss";
import Fuse from "fuse.js";

import { useDispatch, useSelector } from "react-redux";
import {
  branchWalletAggregatedData,
  branchWalletFetchData,
  branchWalletPostData,
  branchWalletPutData
} from "../../store/actions/branchWallet";
import {
  expenseCategoriesFetchData,
  expenseCategoriesPostData,
  expenseCategoriesPutData,
  expenseCategoryEditStatus,
} from "../../store/actions/expenseCategories";

import Moment from "moment";
import exportFromJSON from "export-from-json";

import Accordion from "Accordion";
import HoverTable from "HoverTable";
import Modal from "Modal";
import Description from "Description";
import { Form as Forms, Form, Icon, Radio, Button, Dropdown, Popup } from "semantic-ui-react";
import { Doughnut } from "react-chartjs-2";
import { debounce } from "lodash";
import { Search } from "../../new-components";
import Toggler from "../../components/Toggler";
import Ellipsis from "../../components/Ellipsis";
import FileUpload from "../../components/FileUpload";
import { API_BASE_URL, BRANCH_WALLET_API, EXPENSE_IMAGE_UPLOAD_API, PAYMENT_METHOD } from "../../utilities/constants";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { Translate } from "react-auto-translate";
import { useDownloadExpenseCSV } from "./downloadExpensesDataCSV";
import { useDownloadExpensePDF } from "./downloadExpensesDataPDF";

import { EXPENSE_APPROVAL_OPTIONS } from "../../utilities/constants";

const tableHeadersCategories = ["Name", "Description", "Cost", "Action"];


const calcDateDifference = (start, end) => {
  start = new Date(start);
  end = new Date(end);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

const legendLeft = {
  display: true,
  position: "left",
  // align: 'end',
  fullWidth: true,
  reverse: false,
  labels: {
    // fontColor: 'rgb(255, 99, 132)',
    padding: 10,
    boxWidth: 12,
    margin: 0,
  },
};

const expenseDataFormat = {
  id: null,
  type: "",
  category: "",
  category_id: null,
  sub_type: "",
  category_qtn: "",
  amount: 0,
  date: Moment(new Date()).format("YYYY-MM-DD"), //current
  transaction_type: null,
  payment_mode: null,
  image: null,
};

const expenseCategoryDataFormat = {
  id: null,
  name: null,
  custom: "",
  cost: null,
};

const dailyExpenseAnalyticsData = {
  labels: ["credit", "debit"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#20B2AA", "#F08080"],
      hoverBackgroundColor: ["#20B2AA", "#F08080"],
    },
  ],
};

const monthlyExpenseAnalyticsData = {
  labels: ["credit", "debit"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#20B2AA", "#F08080"],
      hoverBackgroundColor: ["#20B2AA", "#F08080"],
    },
  ],
};

const selectedExpenseAnalyticsData = {
  labels: ["credit", "debit"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#20B2AA", "#F08080"],
      hoverBackgroundColor: ["#20B2AA", "#F08080"],
    },
  ],
};

const ExpenseApprovalColumn = (value, user, ExpenseApproveReject, aclUserPermissions) => {
  const { formatted_component_permissions } = aclUserPermissions;

  if (
    formatted_component_permissions.includes("staff_access") ||
    formatted_component_permissions.includes("employee_access")
  ) {
    return value.approval_status !== null ? (
      <Description
        className={EXPENSE_APPROVAL_OPTIONS[value.approval_status]}
        title={EXPENSE_APPROVAL_OPTIONS[value.approval_status]}
      />
    ) : (
      <Description title="status not defined" />
    );
  } else if (
    formatted_component_permissions.includes("owner_access") ||
    formatted_component_permissions.includes("admin_access")
  ) {
    return value.approval_status === 0 ? (
      [
        <Button
          key={1}
          onClick={() => {
            ExpenseApproveReject(value, 2);
          }}
        >
          <Translate>Reject</Translate>
        </Button>,
        <Button
          key={0}
          primary
          onClick={() => {
            ExpenseApproveReject(value, 1);
          }}
        >
          <Translate>Approve</Translate>
        </Button>,
      ]
    ) : (
      <Description
        className={EXPENSE_APPROVAL_OPTIONS[value.approval_status]}
        title={<Translate>{EXPENSE_APPROVAL_OPTIONS[value.approval_status]}</Translate>}
      />
    );
  }
  return value.approval_status ? (
    <Description
      className={EXPENSE_APPROVAL_OPTIONS[value.approval_status]}
      title={EXPENSE_APPROVAL_OPTIONS[value.approval_status]}
    />
  ) : (
    <Description title="Status not defined" />
  );
};

const analyticsMonthlyDataFormat = (expenses) => {
  const daily = Object.values(expenses?.aggregated?.today) || [0.0, 0.0];
  const monthly = Object.values(expenses?.aggregated?.monthly) || [0.0, 0.0];
  const selected = Object.values(expenses?.aggregated?.selected) || [0.0, 0.0];
  return { daily, monthly, selected };
};

const expense_type = [
  { key: 1, text: "Pantry Bills", value: "Pantry Bills" },
  { key: 2, text: "Bills", value: "Bills" },
  { key: 3, text: "Stationery", value: "Stationery" },
  { key: 4, text: "Purchases of Products", value: "Purchases of Products" },
  { key: 5, text: "Salary expenses", value: "Salary expenses" },
  { key: 6, text: "Advance expenses", value: "Advance expenses" },
  { key: 7, text: "Rent", value: "Rent" },
  { key: 8, text: "Repair expenses", value: "Repair expenses" },
  { key: 9, text: "Promotional expenses", value: "Promotional expenses" },
  { key: 10, text: "Miscellaneous", value: "Miscellaneous" },
];

const getDates = () => {
  const dateNow = new Date();
  const startDate = Moment(new Date()).format("YYYY-MM-DD"); //to  get the current date
  const today = Moment(new Date()).format("YYYY-MM-DD"); //to  get the current date
  const endDate = Moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("YYYY-MM-DD"); //month last date

  return { startDate, today, endDate };
};

function Expense() {
  const dispatch = useDispatch();
  const { startDate, today } = getDates();
  const { isExpenseCSVFetching, downloadExpensesCSV } = useDownloadExpenseCSV();
  const { isExpensePDFFetching, downloadExpensesPDF } = useDownloadExpensePDF();

  const [addExpense, setAddExpense] = useState(false);
  const [expenseData, setExpenseData] = useState(expenseDataFormat);
  const [createCategory, setCreateCategory] = useState(false);
  const [categoryData, setCategoryData] = useState(expenseCategoryDataFormat);
  const [categoryToggle, setCategoryToggle] = useState(true);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [analytics, setAnalytics] = useState({
    daily: dailyExpenseAnalyticsData,
    monthly: monthlyExpenseAnalyticsData,
    selected: selectedExpenseAnalyticsData,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState(false);
  const [start_date, setStartDate] = useState(startDate);
  const [end_date, setEndDate] = useState(today);
  const [expenseSearch, setExpenseSearch] = useState("");
  const [deletedCategoriesUpdated, setDeletedCategoriesUpdated] = useState(false);
  const [image, setImage] = useState(null);
  const [orderBy, setOrderBy] = useState("");

  const user = useSelector((state) => state.user);
  const expenses = useSelector((state) => state.branchWallet);
  const { data: aclUserPermissions } = useSelector((state) => state.aclUserPermissions);
  const { activeCategories: expenseActiveCategories, deletedCategories: expenseDeletedCategories } = useSelector(
    (state) => state.expenseCategories
  );
  const { formatted_component_permissions } = aclUserPermissions;
  const expenseActiveCategoriesMap = {};
  expenseActiveCategories.forEach((category) => {
    expenseActiveCategoriesMap[category.name] = category;
  });

  const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

  const expenseCategoriesDropdownOptions = expenseActiveCategories.map((category, index) => {
    return {
      key: index,
      text: category.name,
      value: category.name,
      obj: category,
    };
  });

  // for removing the selected category
  expenseCategoriesDropdownOptions.push({
    key: -1,
    text: "Remove Category",
    value: null,
    obj: null,
  });

  useEffect(() => {
    dispatch(branchWalletFetchData({ start_date, end_date, search: expenseSearch, orderBy }));
    dispatch(expenseCategoriesFetchData(1)); //active expense categories
    dispatch(expenseCategoriesFetchData(0)); //deleted expense categories
  }, []);

  const orderByHeader = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          <Translate>Date</Translate>
        </div>
        <Icon
          style={{ color: orderBy === "date" ? "black" : "gray", fontSize: "16px" }}
          name="chevron up"
          title="latest to old"
          onClick={() => {
            if (orderBy === "date") {
              setOrderBy("");
            } else {
              setOrderBy("date");
            }
          }}
        />
        <Icon
          style={{ color: orderBy === "-date" ? "black" : "gray", fontSize: "16px" }}
          name="chevron down"
          title="old to latest"
          onClick={() => {
            if (orderBy === "-date") {
              setOrderBy("");
            } else {
              setOrderBy("-date");
            }
          }}
        />
      </div>
    );
  };

  const tableHeadersExpense = [
    "Expense Details",
    "Category",
    "Created By",
    "Transaction Type",
    "Amount",
    "Payment Mode",
    orderByHeader(),
    "Expense Approval",
    "Action",
  ];

  useEffect(() => {
    dispatch(branchWalletFetchData({ start_date, end_date, search: expenseSearch, orderBy }));
  }, [orderBy]);

  useEffect(() => {
    setError("");
    setAddExpense(false);

    let { daily, monthly, selected } = analyticsMonthlyDataFormat(expenses, today);
    dailyExpenseAnalyticsData.datasets[0].data = daily;
    daily = dailyExpenseAnalyticsData;
    monthlyExpenseAnalyticsData.datasets[0].data = monthly;
    monthly = monthlyExpenseAnalyticsData;
    selectedExpenseAnalyticsData.datasets[0].data = selected;
    selected = selectedExpenseAnalyticsData;

    setAnalytics({ daily, monthly, selected });
  }, [expenses, today, start_date, end_date]);

  useEffect(() => {
    if (!categoryToggle) {
      if (deletedCategoriesUpdated) {
        // if deleted categories is updated only then fetch new data
        dispatch(expenseCategoriesFetchData(0));
        setDeletedCategoriesUpdated(false);
      }
    }
  }, [categoryToggle]);

  const getImageId = async (image) => {
    setLoading(true);
    const image_data = new FormData();
    image_data.append("image", image);

    try {
      const res = await axios({
        url: API_BASE_URL + EXPENSE_IMAGE_UPLOAD_API,
        method: "post",
        data: image_data,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      return res.data.id;
    } catch (err) {
      return err;
    }
  };

  const isExpenseDataValid = (expenseData) => {
    if (
      expenseData["type"].length === 0 ||
      !expenseData["date"] ||
      expenseData["amount"] === "" ||
      ![0, 1].includes(expenseData["transaction_type"]) ||
      !expenseData["payment_mode"]
    ) {
      setError("Please enter all the required fields");
      return false;
    }
    //
    if (parseFloat(expenseData.amount) <= 0) {
      setError("Amount should not be negative or Zero");
      return false;
    }
    if ("category_qtn" in expenseData && expenseData.category_qtn !== "" && expenseData.category_qtn !== null) {
      if (parseFloat(expenseData.category_qtn) <= 0) {
        setError("Quantity should not be negative or Zero");
        return false;
      }
    }

    return true;
  };

  const addNewExpense = async () => {
    if (!isExpenseDataValid(expenseData)) {
      return false;
    }

    delete expenseData["id"];

    // get image_id form backend
    let image_id = null;
    if (image) {
      image_id = await getImageId(image);
      setImage(null);
    }
    const _category = expenseData.category ? expenseActiveCategoriesMap[expenseData.category].id : "";
    const _category_qtn = expenseData.category_qtn === "" ? null : expenseData.category_qtn;

    dispatch(
      branchWalletPostData(
        { ...expenseData, category: _category, category_qtn: _category_qtn, image: image_id },
        start_date,
        end_date
      )
    );
    setError("");
    setLoading(false);
    setExpenseData(expenseDataFormat);
  };

  const editExpense = async (id, status, expense) => {
    if (status && !isExpenseDataValid(expense)) {
      return false;
    }

    let image_id = null;
    if (image) {
      image_id = await getImageId(image);
      setImage(null);
    }

    const _category = expense.category_id;
    delete expense["category_id"]; //this is not required at backend side
    expense.active = status;
    const _category_qtn = expense.category_qtn === "" ? null : expense.category_qtn;

    dispatch(
      branchWalletPutData(
        id,
        { ...expense, category: _category, category_qtn: _category_qtn, image: image_id },
        start_date,
        end_date
      )
    );
    setError("");
    setLoading(false);
    setExpenseData(expenseDataFormat);
  };

  const expenseReport = (expense) => {
    if (expense) {
      return `${(expense.data[0] - expense.data[1]).toFixed(2)}/-`;
    } else {
      return "0.00/-";
    }
  };

  const createExpenseCategory = (category) => {
    const { name, cost, custom } = category;

    if (!name || !cost || cost < 0 || !custom) {
      setError("Cost should not be negative value");
      return false;
    }

    setError("");
    dispatch(expenseCategoriesPostData(category));
    setCategoryData(expenseCategoryDataFormat);
  };

  const editExpenseCategory = (id, category) => {
    const { name, cost, custom } = category;
    if (!name || !cost || cost < 0 || !custom) {
      setError("Cost should not be negative value");
      return false;
    }

    setError("");
    dispatch(expenseCategoriesPutData(id, category));
    setCategoryData(expenseCategoryDataFormat);
  };

  const startDateChange = (newStartDate) => {
    let editError = false;
    const difference = calcDateDifference(newStartDate, end_date);
    if (difference >= 0 && difference <= 31) {
      editError = false;
      dispatch(branchWalletAggregatedData(newStartDate, end_date, expenseSearch));
      dispatch(branchWalletFetchData({ start_date: newStartDate, end_date, search: expenseSearch, orderBy }));
    } else {
      editError = true;
    }
    setStartDate(newStartDate);
    setDateError(editError);
  };

  const endDateChange = (newEndDate) => {
    let editError = false;
    const difference = calcDateDifference(start_date, newEndDate);
    if (difference >= 0 && difference <= 31) {
      editError = false;
      dispatch(branchWalletAggregatedData(start_date, newEndDate, expenseSearch))
      dispatch(branchWalletFetchData({ start_date, end_date: newEndDate, search: expenseSearch, orderBy }));
    } else {
      editError = true;
    }
    setEndDate(newEndDate);
    setDateError(editError);
  };
  const ExpenseApproveReject = (value, option) => {
    const newExpense = {
      ...value,
      approval_status: option,
      category: value?.category?.id || null,
      image: value?.image?.id || null,
    };
    dispatch(branchWalletPutData(value.id, newExpense, start_date, end_date));
  };
  const renderSpecialExpense = [
    ({ type, sub_type }) => {
      return <Description title={type} description={<Ellipsis text={sub_type || ""} maxLength={20} />} />;
    },
    ({ category }) => {
      return <Description title={category.name} />;
    },
    ({ created_by }) => {
      return <Description title={created_by.name} />;
    },
    ({ transaction_type }) => {
      return <Description title={[<Translate>Credit</Translate>, <Translate>Debit</Translate>][transaction_type]} />;
    },
    ({ amount }) => {
      return <Description title={amount} />;
    },
    ({ payment_mode }) => {
      return <Description title={payment_mode || ""} />;
    },
    ({ date }) => {
      return <Description title={date} />;
    },
    (value) => {
      return ExpenseApprovalColumn(value, user, ExpenseApproveReject, aclUserPermissions);
    },
    (value, edit, remove) => {
      if (
        formatted_component_permissions.includes("owner_access") ||
        formatted_component_permissions.includes("admin_access")
      ) {
        return [
          <Popup
            disabled={!value?.image?.url}
            key={0}
            basic
            size="mini"
            content="Download expense image"
            trigger={
              value?.image?.url ? (
                <a href={`${value.image.url}`} target="_blank" download>
                  <Icon className="pointer" style={{ marginRight: "6px" }} name="download" />
                </a>
              ) : (
                <Icon name="dont" />
              )
            }
          />,
          <Icon key={1} className="pointer" name="edit" onClick={() => edit(value.id, value)} />,
          <Icon key={2} className="pointer trash" onClick={() => remove(value.id, value)} />,
        ];
      } else {
        return value.approval_status === 0 ? (
          [
            <Popup
              disabled={!value?.image?.url}
              key={0}
              basic
              size="mini"
              content="Download expense image"
              trigger={
                value?.image?.url ? (
                  <a href={`${value.image.url}`} target="_blank" download>
                    <Icon className="pointer" style={{ marginRight: "6px" }} name="download" />
                  </a>
                ) : (
                  <Icon name="dont" />
                )
              }
            />,
            <Icon key={0} className="pointer" name="edit" onClick={() => edit(value.id, value)} />,
            <Icon key={1} className="pointer trash" onClick={() => remove(value.id, value)} />,
          ]
        ) : (
          <Icon key={0} className="dont" />
        );
      }
    },
  ];

  const renderSpecialExpenseCategories = [
    ({ name }) => {
      return <Description title={<Ellipsis text={name} maxLength={15} />} />;
    },
    ({ custom }) => {
      return <Description title={<Ellipsis text={custom || ""} maxLength={25} />} />;
    },
    ({ cost }) => {
      return <Description title={cost} />;
    },
    (value, edit, remove) => {
      return value.status ? (
        [
          <Icon
            key={0}
            className="edit"
            onClick={() => {
              edit(value);
            }}
          />,
          <Icon
            key={1}
            className="pointer trash"
            onClick={() => {
              remove(value.id);
            }}
          />,
        ]
      ) : (
        <Icon key={0} className="dont" />
      );
    },
  ];

  const expenseSearchFunc = useCallback(
    debounce((searchValue) => {
      dispatch(branchWalletFetchData({ start_date, end_date, search: searchValue, orderBy }));
    }, 300),
    [start_date, end_date]
  );

  const expense_type_translated = expense_type.map((option) => {
    const { key, text, value } = option;

    return {
      key,
      value,
      normalText: text,
      text: (
        <span>
          <Translate>{text}</Translate>
        </span>
      ),
    };
  });

  const fuseOptions = {
    keys: ["normalText"],
    threshold: 0,
  };

  const caseSensitiveSearch = (options, query) => {
    const fuse = new Fuse(options, fuseOptions);
    return fuse.search(query).map(({ item }) => item);
  };

  return (
    <div className="expense">
      <div className="analytics-accordion flex row">
        <Accordion onClick={() => setShowAnalytics(!showAnalytics)} isOpen={showAnalytics} action_title="Report">
          <div className="doughnut">
            <Doughnut data={analytics.daily} legend={legendLeft} />
            <h5 style={{ textAlign: "center" }}>
              <Translate>Today's Expense</Translate>
            </h5>
            <h6>({expenseReport(dailyExpenseAnalyticsData.datasets[0])})</h6>
          </div>

          <div className="doughnut">
            <Doughnut data={analytics.monthly} legend={legendLeft} />
            <h5 style={{ textAlign: "center" }}>
              <Translate>This Months Expense</Translate>
            </h5>
            <h6>({expenseReport(monthlyExpenseAnalyticsData.datasets[0])})</h6>
          </div>

          <div className="doughnut">
            <Doughnut data={analytics.selected} legend={legendLeft} />
            <h5 style={{ textAlign: "center" }}>
              <Translate>Selected Dates Expense</Translate>
            </h5>
            <h6>({expenseReport(selectedExpenseAnalyticsData.datasets[0])})</h6>
          </div>
        </Accordion>
      </div>
      {/*  */}
      {createCategory && (
        <Modal
          close={() => {
            setCategoryData(expenseCategoryDataFormat);
            setCreateCategory(false);
            setError("");
            setCategoryToggle(true);
          }}
        >
          {/* category toggle */}
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "10px 0px" }}>
            <Toggler
              onChange={() => {
                setCategoryToggle((prev) => !prev);
              }}
              checked={categoryToggle}
              label={"Active Categories"}
              reversed={true}
            />
          </div>
          {/* list of categories */}
          <div>
            {(categoryToggle ? expenseActiveCategories.length : expenseDeletedCategories.length) ? (
              <HoverTable
                style={{ minHeight: "50px", maxHeight: "350px" }}
                header={tableHeadersCategories}
                src={categoryToggle ? expenseActiveCategories : expenseDeletedCategories}
                renderSpecial={renderSpecialExpenseCategories}
                edit={(value) => {
                  setCategoryData({ ...value });
                }}
                remove={(id) => {
                  setDeletedCategoriesUpdated(true);
                  dispatch(expenseCategoryEditStatus(id, { status: false }));
                }}
              />
            ) : (
              <div className="error">
                <Translate>No Category Found</Translate>
              </div>
            )}
          </div>
          {/* form */}
          <Forms style={{ display: "flex", flexDirection: "column", gap: "10px", width: "700px", marginTop: "30px" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Name</Translate>*
                </label>
                <input
                  type="text"
                  maxLength={30}
                  placeholder="Category Name"
                  onChange={(e) => setCategoryData({ ...categoryData, name: e.currentTarget.value })}
                  value={categoryData.name || ""}
                />
              </Forms.Field>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Cost</Translate>*
                </label>
                <input
                  type="number"
                  maxLength={10}
                  placeholder="Cost"
                  onChange={(e) => setCategoryData({ ...categoryData, cost: e.currentTarget.value })}
                  value={categoryData.cost || ""}
                />
              </Forms.Field>
            </div>
            <Forms.Field>
              <label>
                <Translate>Description</Translate>*
              </label>
              <input
                type="text"
                maxLength={100}
                placeholder="Description"
                onChange={(e) => setCategoryData({ ...categoryData, custom: e.currentTarget.value })}
                value={categoryData.custom || ""}
              />
            </Forms.Field>
          </Forms>
          {error.length > 0 && <span className="error">{error}</span>}
          <br />
          <div className="actions">
            {categoryData.id ? (
              <button className="ui button" onClick={() => editExpenseCategory(categoryData.id, categoryData)}>
                <Translate>Update</Translate>
              </button>
            ) : (
              <button className="ui button" onClick={() => createExpenseCategory(categoryData)}>
                <Translate>Create</Translate>
              </button>
            )}
            {/*  */}
            <button
              className="ui button"
              onClick={() => {
                setCategoryData(expenseCategoryDataFormat);
                setCreateCategory(false);
                setError("");
                setCategoryToggle(true);
              }}
            >
              <Translate>Cancel</Translate>
            </button>
          </div>
        </Modal>
      )}
      {/*  */}
      {addExpense && (
        <Modal
          close={() => {
            setExpenseData(expenseDataFormat);
            setAddExpense(false);
            setError("");
          }}
        >
          <Forms style={{ display: "flex", flexDirection: "column", gap: "10px", width: "600px", padding: "20px 0px" }}>
            {/* expense name and category  */}
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Expense Name</Translate>*
                </label>
                <Dropdown
                  placeholder={
                    <span>
                      <Translate>Expense Name</Translate>
                    </span>
                  }
                  options={expense_type_translated}
                  value={expenseData.type}
                  searchQuery={expenseData.type || ""}
                  onChange={(e, val) => {
                    setExpenseData({ ...expenseData, type: val.value });
                  }}
                  onSearchChange={(event, { searchQuery }) => setExpenseData({ ...expenseData, type: searchQuery })}
                  search={caseSensitiveSearch}
                  selection
                  fluid
                />
              </Forms.Field>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Expense Category</Translate>
                </label>
                <Dropdown
                  selection
                  search
                  fluid
                  options={expenseCategoriesDropdownOptions}
                  onChange={(e, val) => {
                    const category = val.value;
                    const category_cost = expenseActiveCategoriesMap[category]?.cost;
                    const description = expenseActiveCategoriesMap[category]?.custom || "";
                    const category_id = expenseActiveCategoriesMap[category]?.id;

                    setExpenseData({
                      ...expenseData,
                      category,
                      category_id,
                      amount: expenseData.category_qtn ? expenseData.category_qtn * category_cost : category_cost,
                      category_qtn: !expenseData.category_qtn ? 1 : expenseData.category_qtn,
                      sub_type: description,
                    });
                  }}
                  value={expenseData.category}
                  placeholder={<Translate>Category</Translate>}
                />
              </Forms.Field>
            </div>
            {/* description */}
            <Forms.Field>
              <label>
                <Translate>Description</Translate>
              </label>
              <input
                type="text"
                maxLength={100}
                placeholder="Enter Description"
                onChange={(e) => setExpenseData({ ...expenseData, sub_type: e.currentTarget.value })}
                value={expenseData.sub_type}
              />
            </Forms.Field>
            {/* quantity and amount */}
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Quantity</Translate>
                </label>
                <input
                  type="number"
                  placeholder="Quantity"
                  onChange={(e) => {
                    const quantity = e.currentTarget.value;

                    if (quantity === "") {
                      setExpenseData({
                        ...expenseData,
                        category_qtn: quantity,
                      });
                      return;
                    }

                    let totalAmount = expenseData.amount;

                    if (expenseData.category) {
                      totalAmount = expenseActiveCategoriesMap[expenseData.category].cost * quantity;
                    }

                    setExpenseData({
                      ...expenseData,
                      category_qtn: quantity,
                      amount: totalAmount,
                    });
                  }}
                  value={expenseData.category_qtn === null ? "" : expenseData.category_qtn}
                />
              </Forms.Field>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Amount</Translate>*
                </label>
                <input
                  type="number"
                  placeholder="Amount"
                  onChange={(e) => setExpenseData({ ...expenseData, amount: e.currentTarget.value })}
                  value={`${expenseData.amount}` || ""}
                />
              </Forms.Field>
            </div>
            {/* payment mode and type */}
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Payment Mode*</Translate>
                </label>
                <Dropdown
                  selection
                  search
                  fluid
                  options={PAYMENT_METHOD}
                  onChange={(e, { value }) => {
                    setExpenseData({
                      ...expenseData,
                      payment_mode: value,
                    });
                  }}
                  value={expenseData.payment_mode}
                  placeholder={<Translate>Payment Mode</Translate>}
                />
              </Forms.Field>
              <Form.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Expense Type</Translate>*
                </label>
                <Radio
                  label={translateFormLabel("Credit")}
                  name="radioGroup"
                  value={0}
                  checked={expenseData.transaction_type === 0}
                  onChange={() => {
                    setExpenseData({ ...expenseData, transaction_type: 0 });
                  }}
                />
                <Radio
                  label={translateFormLabel("Debit")}
                  name="radioGroup"
                  value={1}
                  checked={expenseData.transaction_type === 1}
                  onChange={() => {
                    setExpenseData({ ...expenseData, transaction_type: 1 });
                  }}
                />
              </Form.Field>
            </div>
            {/* data and expense image upload */}
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
              <Forms.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Date</Translate>*
                </label>
                <input
                  type="date"
                  onChange={(e) => setExpenseData({ ...expenseData, date: e.currentTarget.value })}
                  value={expenseData.date || ""}
                />
              </Forms.Field>
              <Form.Field style={{ flex: 1 }}>
                <label>
                  <Translate>Image Upload</Translate>
                </label>
                <FileUpload
                  limit={1}
                  onChange={(value) => {
                    setImage(value[0]);
                  }}
                  ALLOWED_TYPES={["image/png", "image/jpeg", "image/jpg"]}
                  value={image ? [image] : []}
                />
              </Form.Field>
            </div>
          </Forms>
          {error.length > 0 && <span className="error">{error}</span>}
          <div className="actions">
            {expenseData.id ? (
              <Button disabled={loading} loading={loading} onClick={() => editExpense(expenseData.id, 1, expenseData)}>
                <Translate>Update</Translate>
              </Button>
            ) : formatted_component_permissions.includes("staff_access") ||
              formatted_component_permissions.includes("employee_access") ? (
              <Button disabled={loading} loading={loading} onClick={() => addNewExpense()}>
                <Translate>Request Expense</Translate>
              </Button>
            ) : (
              <Button disabled={loading} loading={loading} onClick={() => addNewExpense()}>
                <Translate>Create</Translate>
              </Button>
            )}
            <button
              className="ui button"
              onClick={() => {
                setExpenseData(expenseDataFormat);
                setAddExpense(false);
                setError("");
              }}
            >
              <Translate>Cancel</Translate>
            </button>
          </div>
        </Modal>
      )}
      <div className="controller">
        <div className="date-range-container">
          <Forms className="expense-date-range">
            <Forms.Field className="expense-start-date">
              <input
                placeholder="Start Date"
                type="date"
                min={"2020-10-18"}
                value={start_date}
                onChange={(e) => startDateChange(e.currentTarget.value)}
              />
            </Forms.Field>
            <div>To</div>
            <Forms.Field className="expense-end-date">
              <input
                placeholder="End Date"
                type="date"
                value={end_date}
                onChange={(e) => endDateChange(e.currentTarget.value)}
              />
            </Forms.Field>
          </Forms>
          <p>
            <Translate>Input date range</Translate>
            <span style={{ color: dateError && "red" }}>
              <Translate>(maximum 31 days)</Translate>
            </span>
          </p>
        </div>
        <div>
          {isSubscriptionExpired && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <p style={{ fontSize: "16px", color: "red" }}>
                <Translate>Subscription is Expired</Translate>
              </p>
            </div>
          )}
          <div className="expense-search-add">
            <Search>
              <Search.GeneralField
                value={expenseSearch}
                onChange={(e) => {
                  const changedValue = e.target.value;
                  expenseSearchFunc(changedValue);
                  setExpenseSearch(changedValue);
                }}
                placeholder="Search expense"
              />
            </Search>
            <div className="add-download">
              {(formatted_component_permissions.includes("owner_access") ||
                formatted_component_permissions.includes("admin_access")) && (
                <Button style={{ minWidth: "130px" }} onClick={() => setCreateCategory(true)}>
                  <Icon className="add" /> <Translate>Category</Translate>
                </Button>
              )}
              <Button
                style={{ minWidth: "100px" }}
                onClick={() => {
                  setImage(null);
                  setAddExpense(true);
                }}
              >
                <Icon className="add" /> <Translate>Add</Translate>
              </Button>
              {isDownloadReportsAllowed && !!expenses.results.length && (
                <div className="download-button">
                  <Dropdown
                    disabled={isSubscriptionExpired}
                    text={isExpenseCSVFetching || isExpensePDFFetching ? "Fetching..." : "Download"}
                    icon={"download"}
                    floating
                    labeled
                    button
                    className="icon"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="PDF"
                        disabled={isExpensePDFFetching}
                        onClick={() => {
                          downloadExpensesPDF({ start_date, end_date, expenseSearch });
                        }}
                      />
                      <Dropdown.Item
                        text="CSV"
                        disabled={isExpenseCSVFetching}
                        onClick={() => {
                          downloadExpensesCSV({ start_date, end_date, expenseSearch });
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {expenses.results.length ? (
        <div className="expenses-table">
          <HoverTable
            header={tableHeadersExpense}
            showDeleted={false}
            src={expenses}
            renderSpecial={renderSpecialExpense}
            edit={(id, expense) => {
              const category = expense.category?.name || "";
              const category_id = expense.category?.id || null;

              setAddExpense(true);
              setExpenseData({ ...expense, category, category_id });
              setImage(expense.image);
            }}
            remove={(id, expense) => {
              const _category = expense.category.name;
              editExpense(id, 0, { ...expense, category: _category });
            }}
            toPaginate={true}
            changePage={(pageNum) => {
              dispatch(branchWalletFetchData({ start_date, end_date, search: expenseSearch, pageNum, orderBy }));
            }}
          />
        </div>
      ) : (
        <h1>
          <Translate>
            No Expenses Found Between {start_date} and {end_date}
          </Translate>
        </h1>
      )}
    </div>
  );
}

export default Expense;
