import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getSalary } from "../../store/actions/salaryBracket";
import { PopperContainer } from "../../components/DatePickerContainer/PopperContainer";
import { Translate } from "react-auto-translate";
import { useReactToPrint } from "react-to-print";
import "./index.scss";

function StaffSalary() {
  const todaysDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    return `${year}-${month}`;
  };

  const [staffId, updateStaff] = useState(null);
  const [selectedDate, setSelectedDate] = useState(todaysDate());
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch.data);

  const dispatch = useDispatch();
  const staffDetails = useSelector((state) => state.user);
  const staffList = useSelector((state) => state.staff.results);
  const staffBasicList = useSelector((state) => state.staffBasic.results);
  const staffSalary = useSelector((state) => state.staffSalary).data;
  useEffect(() => {
    if (staffId) {
      dispatch(getSalary(`${selectedDate}-01`, staffId));
    }
  }, [selectedDate, staffId, dispatch]);

  useEffect(() => {
    const staff = staffBasicList.filter((i) => i.user.id === staffDetails.id);
    if (staff.length) {
      updateStaff(staff[0].id);
    }
  }, [staffList, staffDetails]);

  const changeMonthHandler = (date) => {
    const monthYear = moment(date).format("YYYY-MM");
    setSelectedDate(monthYear);
  };

  let total = 0;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <DatePicker
        selected={moment(selectedDate, "YYYY-MM").toDate()}
        onChange={(date) => {
          changeMonthHandler(date);
        }}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        customInput={<Input disabled={true} icon="calendar" />}
        popperContainer={PopperContainer}
      />
      {!staffSalary ? (
        <p className="errors">
          <span>
            <Translate>No Salary found for</Translate> {selectedDate}.
          </span>
        </p>
      ) : (
        <>
          <div ref={componentRef} className="payslip">
            <div className="details">
              <div className="earning">
                <div className="payslip-header-details">
                  <p>
                    Name: <strong>{user.name}</strong>
                  </p>
                  <p>Username: {user.username}</p>
                  {user.email && <p>Email: {user.email}</p>}
                </div>
                <div className="heading">
                  <h4>Earnings</h4>
                  <h4>Rs.</h4>
                </div>
                {staffSalary.addition.map((item, idx) => {
                  total += item.value;
                  return (
                    <div key={idx} className="particular">
                      <span>{item.name}</span>
                      <span>&#x20B9; {item.value}</span>
                    </div>
                  );
                })}
              </div>
              <div className="deduction">
                <div className="payslip-header-details">
                  <p>
                    <strong>{branch.name}</strong> Code: {branch.code}
                  </p>
                  <p>
                    Address: {branch.address}, {branch.city}, {branch.pin_code}
                  </p>
                  <p>Contact Number {branch.contact}</p>
                </div>
                <div className="heading">
                  <h4>Deduction</h4>
                  <h4>Rs.</h4>
                </div>
                {staffSalary.deduction.map((item, idx) => {
                  total -= item.value;
                  return (
                    <div key={idx} className="particular">
                      <span>{item.name}</span>
                      <span>&#x20B9; {item.value}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="total">
              <h3>
                <Translate>Total:</Translate> &#x20B9; {total}
              </h3>
              <button
                onClick={() => {
                  handlePrint();
                }}
              >
                <Translate>Print</Translate>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default StaffSalary;
