const initSelectedCustomer = {
  data: {},
};

export function selectedCustomerAppointmentFormData(state = initSelectedCustomer, action) {
  switch (action.type) {
    case "SELECTED_CUSTOMER": {
      return {
        ...state,
        ...action.response,
      };
    }
    default:
      return state;
  }
}
