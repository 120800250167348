const initalState = {
  loading: false,
  error: "",
  result: [],
  invoices: [],
  totalPages: 1,
  count: 0,
};

export function appointments(state = initalState, action) {
  switch (action.type) {
    case "APPOINTMENTS_FETCH_DATA_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "APPOINTMENTS_FETCH_DATA_SUCCESS":
      return {
        ...state,
        error: "",
        result: action.appointments.results,
        totalPages: action.appointments.pages,
        count: action.appointments.count,
        loading: false
      };
    case "APPOINTMENTS_INVOICE_FETCH_DATA_SUCCESS":
      return {
        ...state,
        error: "",
        invoices: action.appointments,
        loading: false
      };
    case "APPOINTMENTS_FETCH_DATA_FAIL":
      return {
        ...state,
        error: "",
        loading: false
      };
    case "APPOINTMENTS_UPDATE_FAIL":
      return {
        ...state,
        error: action.error,
      };
    case "APPOINTMENTS_ERROR_RESET":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}

const basicInitialState = {
  results: [],
  loading: false,
  error: "",
  totalPages: 1,
  count: 0,
};

export function basicAppointments(state = basicInitialState, action) {
  switch (action.type) {
    case "APPOINTMENTS_BASIC_FETCH_DATA_SUCCESS":
      return {
        ...state,
        results: action.appointments.results,
        count:action.appointments.count,
        totalPages: action.appointments.pages,
        loading:false,
      };
    case "APPOINTMENTS_BASIC_FETCH_DATA_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export function appointmentInvoice(state = null, action) {
  switch (action.type) {
    case "APPOINTMENTS_SHOW_INVOICE":
      return action.appointmentInvoice;
    case "APPOINTMENTS_HIDE_INVOICE":
      return null;
    default:
      return state;
  }
}
