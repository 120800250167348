import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Popup } from "semantic-ui-react";
import "./index.scss";

import { fetchMebershipDataById } from "../../store/actions/membershipDataById";
import { formatDaysByUnit, combineDayAndUnit } from "../../utilities/CommonFunctions";

function MembershipPopUp({ membershipId, membershipData = null }) {
  const dispatch = useDispatch();
  const servicesMap = useSelector((state) => state.servicesMap);
  const productsMap = useSelector((state) => state.productsMap);
  const membershipDataById = useSelector((state) => state.membershipDataById);

  const [membership, setMembership] = useState(null);

  useEffect(() => {
    if (membershipData) {
      return;
    }

    if (!membershipDataById.fetching && membershipDataById?.data?.id && membershipDataById.data.id === membershipId) {
      setMembership(membershipDataById.data);
    }
  }, [membershipDataById]);

  useEffect(() => {
    if (membershipData) {
      setMembership(membershipData);
      return;
    }
    if (membershipId) {
      if (!membershipDataById.data || membershipDataById.data.id !== membershipId) {
        dispatch(fetchMebershipDataById(membershipId));
      }
    }
  }, [membershipId, membershipData]);

  const servicesValueFormat = (service_id) => {
    const serviceData = servicesMap[service_id];

    const name = serviceData?.name || "";
    const gender = serviceData?.gender || "";

    return `${name} || ${gender}`;
  };

  const productsValueFormat = (product_id) => {
    const productData = productsMap[product_id];
    const name = productData?.name || "";

    return `${name}`;
  };

  const serviceValueShowFunction = (header, value = []) => {
    return (
      <div className="membership-list-container">
        <span className="bold-margin"> {header}:</span>
        <div className="membership-list-style excluded-services">
          {value.map((service_id, index) => {
            return (
              <span key={index} style={{ border: "1px solid gray", padding: "2px 10px", borderRadius: "20px" }}>
                {servicesValueFormat(service_id)}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const productValueShowFunction = (header, value = []) => {
    return (
      <div className="membership-list-container">
        <span className="bold-margin"> {header}:</span>
        <div className="membership-list-style excluded-services">
          {value.map((product_id, index) => {
            return (
              <span key={index} style={{ border: "1px solid gray", padding: "2px 10px", borderRadius: "20px" }}>
                {productsValueFormat(product_id)}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const membershipValidityUnit = membership?.validity_unit;
  const membershipValidity = formatDaysByUnit(membership?.validity_in_days, membership?.validity_unit);

  return (
    <>
      <Popup on="click" trigger={<Icon name="eye" color="blue" title="Membership Details" />} position="bottom left">
        <div className="membership-popup-container">
          {membershipId && membershipDataById?.error && (
            <p className="error">Something went wrong. Could not load latest membership data</p>
          )}
          {membershipDataById?.fetching && <p>Loading latest data...</p>}
          <div className="membership-popup-row">
            <div>
              <span className="bold-margin">Name:</span>
              {membership?.name}
            </div>
            <div>
              <span className="bold-margin">description:</span>
              {membership?.description}
            </div>
          </div>
          <div className="membership-popup-row">
            <div>
              <span className="bold-margin">Validity:</span>
              {combineDayAndUnit(membershipValidity, membershipValidityUnit)}
            </div>
            <div>
              <span className="bold-margin">Minimum Bill:</span>&#8377;{membership?.min_bill_amount}
            </div>
          </div>
          <div className="membership-popup-row">
            <div>
              <span className="bold-margin">Cost:</span>&#8377;{membership?.cost}
            </div>
            <div>
              <span className="bold-margin">Wallet Balance:</span>&#8377;{membership?.wallet_credit_amount}
            </div>
          </div>
          {membership?.discount_type_value && (
            <div>
              <h5 className="membership-type">Discount On The Total Bill</h5>
              <div className="membership-popup-row">
                <div>
                  <span className="bold-margin">Discount Type:</span>
                  {membership?.discount_type == 0 ? "Percentage" : "Value"}
                </div>
                <div>
                  <span className="bold-margin">Discount:</span>
                  {membership?.discount_type_value}
                </div>
              </div>
            </div>
          )}
          {membership?.all_services_discount_type_value && (
            <div>
              <h5 className="membership-type">Discount Across All Services</h5>
              <div className="membership-popup-row">
                <div>
                  <span className="bold-margin">Discount Type:</span>
                  {membership?.all_services_discount_type == 0 ? "Percentage" : "Value"}
                </div>
                <div>
                  <span className="bold-margin">Discount:</span>
                  {membership?.all_services_discount_type_value}
                </div>
              </div>
              <div className="membership-popup-row">
                <div>
                  <span className="bold-margin">Maximum Count</span>
                  {membership?.all_services_discount_max_count}
                </div>
                <div>
                  <span className="bold-margin">Minimum Cost</span>&#8377;{membership?.minimum_service_cost}
                </div>
              </div>
              {membership?.all_services_except_discount?.length > 0 &&
                serviceValueShowFunction("Excluded Services", membership?.all_services_except_discount)}
              {membership?.all_services_include_discount?.length > 0 &&
                serviceValueShowFunction("Included Services", membership?.all_services_include_discount)}
            </div>
          )}
          {membership?.services?.length > 0 && (
            <div className="membership-list-container">
              <h5 className="membership-type">Discount on Specific Services</h5>
              <div className="membership-list-style">
                {membership?.services.map((service) => {
                  return (
                    <div className="membership-service">
                      <div className="membership-popup-row">
                        <div>
                          <span className="bold-margin">Service:</span>
                          {service?.service?.name}
                        </div>
                        <div>
                          <span className="bold-margin">Duration:</span>
                          {service?.duration_in_minutes || 0}
                        </div>
                        <div>
                          <span className="bold-margin">Count:</span>
                          {service?.allotted_count}
                        </div>
                      </div>
                      <div className="membership-popup-row">
                        <div>
                          <span className="bold-margin">Discount Type:</span>
                          {service?.discount_type == 0 ? "Percentage" : "Value"}
                        </div>
                        <div>
                          <span className="bold-margin">Discount Value:</span>
                          {service?.discount_type_value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {membership?.all_products_discount_type_value && (
            <div>
              <h5 className="membership-type">Discount Across All Products</h5>
              <div className="membership-popup-row">
                <div>
                  <span className="bold-margin">Discount Type:</span>
                  {membership?.all_products_discount_type == 0 ? "Percentage" : "Value"}
                </div>
                <div>
                  <span className="bold-margin">Discount:</span>
                  {membership?.all_products_discount_type_value}
                </div>
              </div>
              <div className="membership-popup-row">
                <div>
                  <span className="bold-margin">Maximum Count</span>
                  {membership?.all_products_discount_max_count}
                </div>
                <div>
                  <span className="bold-margin">Minimum Cost</span>&#8377;{membership?.minimum_product_cost}
                </div>
              </div>
              {membership?.all_products_except_discount?.length > 0 &&
                productValueShowFunction("Excluded Products", membership?.all_products_except_discount)}
              {membership?.all_products_include_discount?.length > 0 &&
                productValueShowFunction("Included Products", membership?.all_products_include_discount)}
            </div>
          )}
          {membership?.products?.length > 0 && (
            <div className="membership-list-container">
              <h5 className="membership-type">Discount on Specific Products</h5>
              <div className="membership-list-style" style={{}}>
                {membership?.products.map((product) => {
                  return (
                    <div className="membership-product">
                      <div className="membership-popup-row">
                        <div>
                          <span className="bold-margin">Product:</span>
                          {product?.product?.name}
                        </div>
                        <div>
                          <span className="bold-margin">Count:</span>
                          {product?.allotted_count}
                        </div>
                      </div>
                      <div className="membership-popup-row">
                        <div>
                          <span className="bold-margin">Discount Type:</span>
                          {product?.discount_type == 0 ? "Percentage" : "Value"}
                        </div>
                        <div>
                          <span className="bold-margin">Discount Value:</span>
                          {product?.discount_type_value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Popup>
    </>
  );
}

export default MembershipPopUp;
