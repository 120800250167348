import Axios from "axios";
import { API_BASE_URL, INVOICE_REMARKS } from "../../utilities";
import { authenticationLogout } from "./authentication";

export function getInvoiceRemarks(appointmentId) {
  const URL = API_BASE_URL + INVOICE_REMARKS + "?appointment_id=" + appointmentId;
  return (dispatch) => {
    dispatch({
      type: "INVOICE_REMARKS_LOADING",
    });
    Axios.get(URL, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "GET_INVOICE_REMARKS_SUCCESS",
            data: response.data.results,
          });
        } else {
          dispatch({
            type: "INVOICE_REMARKS_OPS_FAILURE",
            data: "failed to fetch invoice remarks",
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        } else {
          console.log({ error });
          dispatch({
            type: "INVOICE_REMARKS_OPS_FAILURE",
            data: "failed to fetch invoice remarks",
          });
        }
      });
  };
}

export function postInvoiceRemarks(payload) {
  const URL = API_BASE_URL + INVOICE_REMARKS;
  return (dispatch) => {
    dispatch({
      type: "INVOICE_REMARKS_LOADING",
    });
    Axios({
      method: "POST",
      url: URL,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "POST_INVOICE_REMARKS_SUCCESS",
            data: response.data.results,
          });
        } else {
          dispatch({
            type: "INVOICE_REMARKS_OPS_FAILURE",
            data: "failed to fetch invoice remarks",
          });
        }
        dispatch(getInvoiceRemarks(payload.appointment_id));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        } else {
          console.log({ error });
          dispatch({
            type: "INVOICE_REMARKS_OPS_FAILURE",
            data: "failed to fetch invoice remarks",
          });
        }
      });
  };
}
