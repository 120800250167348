import React, { useEffect, useState } from "react";
import "./index.scss";
import { Icon, Checkbox } from "semantic-ui-react";
// import CustomSearch from "CustomSearch";
import { Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import { authenticationLogout } from "../../store/actions/authentication";
import { getUser } from "../../store/actions/user";
import Notification from "../../components/Notification";
import BranchSelect from "./BranchSelect";
import SelectLanguage from "./SelectLanguage";
import { openReleaseNotes } from "../../store/actions/releaseNotes";
import { Festival } from "../../components/Festival";
import settingIcon from '../../cdn/g121.png'
import logoutIcon from '../../cdn/Group 4884.png'

function Header({ refresh, pathMap }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isDarkTheme, setDarkTheme] = useState(false);

  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch.data);
  const { base_permissions } = useSelector((state) => state.aclUserPermissions.data);
  const releaseNotes = useSelector((state) => state.releaseNotes);

  const settingPageAccessible =
    base_permissions.filter((permission) => permission.permission_name === "settings_page").length > 0;

  useEffect(() => {
    dispatch(getUser());
    const currentTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "light";
    if (currentTheme === "dark") {
      setDarkTheme(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(pathMap).length === 0) {
      return;
    }
    //for redirecting the user(especially admin) to the first router
    if (!("/" in pathMap)) {
      history.replace(Object.keys(pathMap)[0]);
    }
  }, [pathMap]);

  const { logo, name } = branch;

  const navigateTo = () => history.push("/settings");
  const logout = () => dispatch(authenticationLogout());

  const themeSwitch = ({ checked }) => {
    if (checked) {
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
    }
    setDarkTheme(checked);
  };

  return (
    <div className="header">
      <div className="left">
        <div className="hamburger">
          <Icon
            className="pointer hamburger"
            name="sidebar"
            size="large"
            onClick={() => {
              document.getElementById("side-bar").style.width = "200px";
              document.getElementById("mobile_quit").style.display = "block";
            }}
          />
        </div>
        <div className="only-web">
          <span>
            <Translate>Customer Care</Translate>:
          </span>
          <a className="cs-text" href="tel:+919686636655">
            +91-9686636655
          </a>{" "}
          /{" "}
          <a className="cs-text" href="mailto:cx@glamplus.in">
            cx@glamplus.in
          </a>
        </div>
      </div>
      {/*  */}
      <div className="center branch-details">
        {name && <h3 className="customer-care">{name}</h3>}
        {logo && <img className="partner-logo" src={logo} alt="" />}
      </div>
      {/*  */}
      <div className="right">
        <Festival />
        <div
          onClick={() => {
            dispatch(openReleaseNotes());
          }}
        >
          <span className="menu-icon">
            <Icon name={"file alternate outline"} size="large" />
            {!releaseNotes.read && <div className="blob blue"></div>}
          </span>
        </div>
        <BranchSelect />
        <Notification />
        <SelectLanguage />
        <Checkbox
          toggle
          checked={isDarkTheme}
          onClick={(_, data) => {
            themeSwitch(data);
          }}
        />
        <ul className="right-menu">
          <li>
            <div className="header-menu">
              <Dropdown text={user.first_name ? user.first_name : <Translate>Loading...</Translate>}>
              <Dropdown.Menu className="userSetting">
                  <Dropdown.Item key={0} text="" />
                  {settingPageAccessible && (
                    <Dropdown.Item key={1} value={1} text={<Translate>
                     <img src={settingIcon} alt="setting Icon"/> 
                     Settings</Translate>} onClick={navigateTo} />
                  )}
                  <Dropdown.Item key={2} value={2} text={<Translate>
                    <img src={logoutIcon} alt="logout Icon"/>
                    Logout</Translate>} onClick={logout} />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li></li>
          {/*<li><Icon name='setting' size='large' /></li>*/}
        </ul>
      </div>
    </div>
  );
}
export default Header;
