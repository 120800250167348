import React, { useState, useEffect } from "react";
import "./index.scss";

import HoverTable from "HoverTable";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import Description from "Description";
import Modal from "../../components/Modal";
import Accordion from "Accordion";
import Paytm from "Paytm";
import { Input } from "semantic-ui-react";

import moment from "moment";
import "moment-timezone";

import { useDispatch, useSelector } from "react-redux";
import { paymentsPostData, resetPayments } from "../../store/actions/payments";
import { getSubscription } from "../../store/actions/subscriptions";
import { getBranchSubscription } from "../../store/actions/branchSubscription";
// import {getBranchSubscriptionsServices} from '../../store/actions/branchSubscriptionsServices'
import { getSubscriptionDiscount, resetSubscriptionDiscount } from "../../store/actions/subscriptionDiscount";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { Translate } from "react-auto-translate";

const packageHeader = ["Amount", "Duration", "Inclusion", "Buy"];

const subscriptionMultiplierOptions = [
  { key: 1, text: "1 year", value: 1 },
  { key: 2, text: "2 year", value: 2 },
  { key: 3, text: "3 year", value: 3 },
  { key: 4, text: "4 year", value: 4 },
  { key: 5, text: "5 year", value: 5 },
];

const renderPackages = [
  ({ cost }) => {
    return <Description title={`${cost}*`} />;
  },
  ({ name, validity_days }, edit, remove, select) => {
    if (name !== "Reminder Notification + Own logo + Expense management + Bulk Promotion + 15000 whatsapp Promotion") {
      return <Description title={`${validity_days} days`} />;
    } else {
      return (
        <Dropdown
          clearable={select !== 1}
          selection
          value={select}
          options={subscriptionMultiplierOptions}
          onChange={(event, { value }) => {
            remove(value || 1);
          }}
        />
      );
    }
  },
  ({ name }) => {
    return <Description title={name} />;
  },
  ({ id, cost }, edit) => {
    return !!cost && <Button onClick={() => edit({ subscription: id })}>Buy Now</Button>;
  },
];

const tableHeaders = ["type", "start date", "validity", "transaction details", "amount", "status", "invoice"];

const legendLeft = {
  display: true,
  position: "left",
  fullWidth: true,
  reverse: false,
  labels: {
    padding: 10,
    boxWidth: 12,
    margin: 0,
  },
};

const subscriptionServiceSMSAnalytics = {
  labels: ["Used", "Remaining"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#F08080", "#20B2AA"],
      hoverBackgroundColor: ["#F08080", "#20B2AA"],
    },
  ],
};

const subscriptionServiceWhatsAppAnalytics = {
  labels: ["Used", "Remaining"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#F08080", "#20B2AA"],
      hoverBackgroundColor: ["#F08080", "#20B2AA"],
    },
  ],
};

const subscriptionServiceValidityAnalytics = {
  labels: ["Used", "Remaining"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#F08080", "#20B2AA"],
      hoverBackgroundColor: ["#F08080", "#20B2AA"],
    },
  ],
};

const renderSpecial = [
  ({ subscription }) => {
    return <Description title={subscription.name} />;
  },
  ({ start_date }) => {
    return <Description title={start_date} />;
  },
  ({ subscription, payment }) => {
    const validity_days =
      payment.subscription_multiplier > 1
        ? `${subscription.validity_days} days x ${payment.subscription_multiplier}`
        : `${subscription.validity_days} days`;
    return <Description title={validity_days} />;
  },
  ({ payment }) => {
    return <Description title={payment.payment_mode} />;
  },
  ({ cost }) => {
    return <Description title={`Rs. ${cost}/-`} />;
  },
  () => {
    return <span>Paid/Success</span>;
  },
  (data, edit) => {
    return (
      <button type="link" onClick={() => edit(data)}>
        <Translate> View Invoice</Translate>
      </button>
    );
  },
];

function Payments() {
  const [selectPackage, setSelectPackage] = useState(false);
  const [viewInvoice, setViewInvoice] = useState("");
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [analytics, setAnalytics] = useState({
    sms: subscriptionServiceSMSAnalytics,
    whatsApp: subscriptionServiceWhatsAppAnalytics,
    validity: subscriptionServiceValidityAnalytics,
  });
  const [discountCoupon, setDiscountCoupon] = useState("");
  const [isIndia, setIsIndia] = useState(true);
  const [subscriptionMultiplier, setSubscriptionMultiplier] = useState(1);

  const dispatch = useDispatch();
  const branch = useSelector((state) => state.branch.data);
  const payments = useSelector((state) => state.payments);
  const subscriptions = useSelector((state) => state.subscriptions);
  const branchSubscriptions = useSelector((state) => state.branchSubscriptions);
  const branchSubscriptionsServices = useSelector((state) => state.branchSubscriptionsServices);
  const subscriptionDiscount = useSelector((state) => state.subscriptionDiscount);
  const { error, data } = subscriptionDiscount;
  const paidSubcriptions = {
    ...branchSubscriptions,
    results: branchSubscriptions?.results?.filter((data) => data.payment !== null).sort((a, b) => a.cost - b.cost),
  };

  useEffect(() => {
    function init() {
      if (window.location.href.includes("?success=true") || window.location.href.includes("api/payment/paypal")) {
        window.location.replace("/payments");
        return;
      }
      dispatch(getSubscription());
      dispatch(getBranchSubscription());
      async function getUserCountry() {
        const userTimeZone = moment.tz.guess();
        const isMomentIndia = ["Asia/Calcutta", "Asia/Kolkata"].includes(userTimeZone);
        let ipCountryData = {};
        try {
          ipCountryData = await axios.get("http://ip-api.com/json");
        } catch (error) {
          console.log(error);
        }
        if (isMomentIndia || ipCountryData.data?.country === "India") {
          setIsIndia(true);
        } else {
          setIsIndia(false);
        }
      }
      getUserCountry();
    }
    init();
  }, [dispatch]);

  useEffect(() => {
    const { data = {} } = payments;
    if (data?.paypal_url) {
      window.open(data.paypal_url, "_self");
    }
  }, [payments]);

  useEffect(() => {
    if (branchSubscriptionsServices.data.length) {
      branchSubscriptionsServices.data.map(({ subscription_service, expires_on, remaining_count, used_count }) => {
        if (subscription_service.name === "SMS_PROMOTIONAL") {
          const sms = analytics.sms;
          sms.datasets[0].data = [used_count, remaining_count];
          setAnalytics({ ...analytics, sms });
        }
        if (subscription_service.name === "WHATSAPP_PROMOTIONAL") {
          const whatsApp = analytics.whatsApp;
          whatsApp.datasets[0].data = [used_count, remaining_count];
          setAnalytics({ ...analytics, whatsApp });
        }
        if (subscription_service.name === "RECEPTIONIST_APP") {
          const today = moment();
          const expiry_date = moment(expires_on);
          const validity_days = expiry_date.diff(today, "days") >= 0 ? expiry_date.diff(today, "days") + 1 : 0;

          const validity = analytics.validity;
          validity.datasets[0].data = [0, validity_days];
          setAnalytics({ ...analytics, validity });
        }
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSubscriptionsServices]);

  const editedSubscriptions = subscriptions.data.filter((subscription) => {
    return analytics.validity.datasets[0].data[1] > 0 ? true : !subscription.name.includes("Add On");
  });

  const paymentGatewayDecision = async (payment) => {
    if (isIndia) {
      payment.payment_mode = "PAYTM";
      payment.subscription_multiplier = subscriptionMultiplier;
      if (data.length) {
        payment.coupon = data[0].id;
        dispatch(paymentsPostData(payment));
      } else {
        payment.coupon = null;
        dispatch(paymentsPostData(payment));
      }
    } else {
      payment.payment_mode = "PAYPAL";
      payment.subscription_multiplier = subscriptionMultiplier;
      if (data.length) {
        payment.coupon = data[0].id;
        dispatch(paymentsPostData(payment));
      } else {
        payment.coupon = null;
        dispatch(paymentsPostData(payment));
      }
    }
  };

  const pricingPackages = editedSubscriptions
    .map((elements) => {
      let { cost, validity_days } = elements;
      if (validity_days === 365) {
        cost *= subscriptionMultiplier;
      }
      let discount = 0;
      if (data.length && cost) {
        if (!!data[0].discount_type) {
          discount = data[0].discount_type_value;
        } else {
          discount = (cost * data[0].discount_type_value) / 100;
        }
        if (data[0].max_discount_amount && discount > data[0].max_discount_amount) {
          discount = data[0].max_discount_amount;
        }
        cost -= discount;
      }
      return {
        ...elements,
        cost,
      };
    })
    .sort((a, b) => a.cost - b.cost);

  return (
    <div className="payments">
      <div className="analytics-accordion flex row">
        <Accordion onClick={() => setShowAnalytics(!showAnalytics)} isOpen={showAnalytics} action_title="Report">
          <div className="doughnut">
            <Doughnut data={analytics.sms} legend={legendLeft} />
            <h4 className="analytics-title">
              <Translate>SMS Promotions</Translate>
            </h4>
          </div>
          <div className="doughnut">
            <Doughnut data={analytics.whatsApp} legend={legendLeft} />
            <h4 className="analytics-title">
              <Translate>WhatsApp Promotions</Translate>
            </h4>
          </div>
          <div className="doughnut">
            <Doughnut data={analytics.validity} legend={legendLeft} />
            <h4 className="analytics-title">
              <Translate>Subscription Validity</Translate>({analytics.validity.datasets[0].data[1]} days)
            </h4>
          </div>
        </Accordion>
      </div>
      {payments.data && <Paytm params={payments.data} closed={() => dispatch(resetPayments())} />}
      <Button onClick={() => setSelectPackage(true)}>
        <Translate>View Packages</Translate>
      </Button>
      <div className="subscriptions-list-table">
        <HoverTable
          header={tableHeaders}
          src={paidSubcriptions}
          renderSpecial={renderSpecial}
          edit={(data) => setViewInvoice(data)}
          toPaginate={true}
          changePage={(pageNum) => dispatch(getBranchSubscription(pageNum))}
        />
      </div>
      {!branchSubscriptions.results.length && (
        <h2>
          <Translate>No subscriptions found</Translate>
        </h2>
      )}
      {selectPackage && (
        <Modal
          close={() => {
            setSelectPackage(false);
            setDiscountCoupon("");
            dispatch(resetSubscriptionDiscount());
          }}
        >
          <div className="apply-discount">
            <div className="flex column">
              <Input
                action={{
                  color: "teal",
                  labelPosition: "right",
                  icon: "tag",
                  content: "Apply",
                  onClick: () => {
                    dispatch(getSubscriptionDiscount(discountCoupon));
                  },
                }}
                className="discount"
                placeholder="discount coupon"
                value={discountCoupon}
                onChange={(e) => setDiscountCoupon(e.currentTarget.value)}
              />
              <span>{discountCoupon && !!error && <Translate>Not Found!</Translate>}</span>
              <span className="success">
                {data.length ? (
                  <>
                    {data[0].discount_type_value}
                    {!!data[0].discount_type ? "/-" : "%"}
                    &nbsp;<Translate>Discount Applied</Translate>
                    {data[0].max_discount_amount && `(Maximum discount - ${data[0].max_discount_amount}/-)`}
                  </>
                ) : (
                  <span />
                )}
              </span>
            </div>
          </div>
          <HoverTable
            header={packageHeader}
            src={pricingPackages}
            renderSpecial={renderPackages}
            edit={(payment) => {
              paymentGatewayDecision(payment);
            }}
            remove={(years) => setSubscriptionMultiplier(years)}
            select={subscriptionMultiplier}
          />
          <span className="description">
            <Translate>*additional 18% gst applicable</Translate>
          </span>
          <br />
          <span className="description">
            <Translate>**bulk promotion - can send upto 2000 sms promotions at once.</Translate>
          </span>
          <br />
          <span className="description">
            <Translate>***whatsApp promotion - can send upto 2000 whatsApp promotions at once.</Translate>
          </span>
        </Modal>
      )}
      {viewInvoice && (
        <Modal close={() => setViewInvoice("")}>
          <Button className="print-btn" onClick={() => window.print()}>
            <Translate>Print</Translate> <Icon className="print" />
          </Button>
          <div className="generate-invoice width-100 height-100 flex column">
            <div className="invoice">
              <table>
                <caption>
                  <Translate>Invoice</Translate>
                </caption>
                <thead>
                  <tr className="headers">
                    <th colSpan="3" className="from">
                      <div className="from-container">
                        <div className="logo">
                          {
                            <img
                              src={"https://glamplus-partner-logo.s3.ap-south-1.amazonaws.com/glamplus.jpg"}
                              alt=""
                            />
                          }
                        </div>
                        <div className="partner-img align-left">
                          <strong>
                            <Translate>Pay to:</Translate>
                          </strong>
                          <br />
                          <span>Glamplus</span>
                          <br />
                          <span>HSR Layout</span>
                          <br />
                          <span className="gst">GST: 29AAKCR2802C1ZL</span>
                          <br />
                        </div>
                      </div>
                    </th>
                    <th colSpan="2" className="to align-left">
                      <strong>Branch:</strong>
                      <br />
                      <span>{branch.name}</span>
                      <br />
                      <span>{branch.number}</span>
                      {branch.gst && <span className="gst">GST: {branch.gst}</span>}
                    </th>
                    <th colSpan="3" className="details align-left">
                      <strong>Invoice no:</strong> #{branch?.invoice_pre_text}-SUBS-{viewInvoice.id}
                      <br />
                      <strong>Invoice Date: </strong> {viewInvoice.start_date}
                    </th>
                  </tr>
                  <tr className="title">
                    <td colSpan="1" className="no-right">
                      <Translate> S. No.</Translate>
                    </td>
                    <td colSpan="5" className="no-left no-right">
                      <Translate>Subscription</Translate>
                    </td>
                    <td colSpan="1" className="no-left no-right">
                      <Translate>Qty</Translate>
                    </td>
                    <td colSpan="1" className="no-left">
                      <Translate>Rate</Translate>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="particulars">
                    <td colSpan="1">{1}</td>
                    <td colSpan="5">
                      {viewInvoice.subscription.name}({viewInvoice.subscription.validity_days} days{" "}
                      {viewInvoice.payment.subscription_multiplier > 1
                        ? `x ${viewInvoice.payment.subscription_multiplier}`
                        : ``}
                      )
                    </td>
                    <td colSpan="1">{1}</td>
                    <td colSpan="1">
                      {
                        // viewInvoice.subscription.status === 0?
                        ((viewInvoice.cost * 100) / 118).toFixed(2)
                        // (viewInvoice.cost)
                        // viewInvoice.cost
                      }
                      /-
                    </td>
                  </tr>
                  <tr className="particulars height-100 width-100">
                    <td colSpan="1" className="" />
                    <td colSpan="5" className="" />
                    <td colSpan="1" className="" />
                    <td colSpan="1" className="" />
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="6" className="align-right">
                      GST
                    </td>
                    <td colSpan="1">18%</td>
                    <td colSpan="1">
                      {
                        // viewInvoice.subscription.status === 0?
                        (viewInvoice.cost - (viewInvoice.cost * 100) / 118).toFixed(2)
                        // (viewInvoice.cost - viewInvoice.subscription.cost)
                      }
                      /-
                    </td>
                  </tr>
                  <tr className="footer">
                    <td colSpan="6" className="align-right">
                      <Translate>Total</Translate>
                    </td>
                    <td colSpan="1" />
                    <td colSpan="1">{viewInvoice.cost}/-</td>
                  </tr>
                </tfoot>
              </table>
              {/*<div style={{width: '100%', textAlign: 'center'}}>(Inclusive of GST)</div>*/}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Payments;
