import React, { useEffect, useState } from "react";
import { Form as Forms, Dropdown } from "semantic-ui-react";
import { LANGUAGES } from "../../../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/actions/language";

function SelectLanguage() {
  const dispatch = useDispatch();
  const { data: language } = useSelector((state) => state.language);
  const systemLanguage = language || localStorage.getItem("language", language) || "en";
  return (
    <div>
      <Forms.Field>
        <Dropdown
          placeholder="Select Language"
          fluid
          selection
          defaultValue="en"
          value={systemLanguage}
          options={LANGUAGES}
          onChange={(e, { value }) => {
            dispatch(setLanguage(value));
          }}
        />
      </Forms.Field>
    </div>
  );
}
export default SelectLanguage;
