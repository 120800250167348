import { API_BASE_URL, APPOINTMENT_HIDE_API } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";
import { appointmentsFetchData } from "./appointments";

export function appointmentHide(appointment_id, start_date = false, end_date = false, hide) {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${API_BASE_URL}${APPOINTMENT_HIDE_API}${appointment_id}/${hide}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(appointmentsFetchData(false, start_date, end_date));
        } else {
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}
