import { API_BASE_URL, BRANCH_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export function branchFetchDataSuccess(branch) {
  return {
    type: "BRANCH_FETCH_DATA_SUCCESS",
    branch,
  };
}

export function branchFetchData() {
  return (dispatch) => {
    dispatch({ type: "BRANCH_FETCH_DATA_FETCHING" });
    dispatch(startLoading("BRANCH_LOADER", "loading branch"));
    axios
      .get(API_BASE_URL + BRANCH_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(branchFetchDataSuccess(response.data.results[0]));
          dispatch(endLoading("BRANCH_LOADER"));
        } else {
          dispatch(errored("BRANCH_LOADER", true));
          dispatch(endLoading("BRANCH_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BRANCH_LOADER", true));
        dispatch(endLoading("BRANCH_LOADER"));
      });
  };
}

export function getBranchLoyaltyPoint() {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + "branch_loyalty_point_detail/", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "BRANCH_LOYALTY_POINT_FETCH_DATA_SUCCESS",
          data: response.data,
        });
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function postBranchLoyaltyPoint(payload) {
  return (dispatch) => {
    axios
      .post(API_BASE_URL + "branch_loyalty_point_detail/", payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function () {
        dispatch(getBranchLoyaltyPoint());
        ToastAlert({ message: "Successfully updated Loyalty Points", type: "success" });
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        console.log(error.response);
        ToastAlert({ message: "Something went wrong. Try again", type: "error" });
      });
  };
}
