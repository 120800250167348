import React, { useEffect } from "react";
import { Button, Modal, Loader, Dimmer, Pagination, Icon } from "semantic-ui-react";
import { openReleaseNotes, closeReleaseNotes } from "../../store/actions/releaseNotes";
import { useDispatch, useSelector } from "react-redux";
import { getReleaseNotes, readReleaseNotes } from "../../store/actions/releaseNotes";
import { Translate } from "react-auto-translate";
import { LoadingState } from "../../store/reducers/types";
import "./index.scss";

function ReleaseNotesModal() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.openReleaseNotes.open);
  const releaseNotes = useSelector((state) => state.releaseNotes);
  const loading = releaseNotes.loading === LoadingState.loading;
  const loaded = releaseNotes.loading === LoadingState.loaded;

  useEffect(() => {
    if (releaseNotes.loading !== LoadingState.loaded && open) {
      dispatch(getReleaseNotes({}));
    }
  }, [open, releaseNotes]);

  return (
    <Modal className="release-notes" onClose={() => dispatch(openReleaseNotes())} open={open}>
      <Modal.Header className="modal-header">
        <p>
          <Translate>Release Notes</Translate>
        </p>
      </Modal.Header>
      <Modal.Content>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted></Loader>
          </Dimmer>
        )}
        {loaded &&
          releaseNotes.data?.results?.map((ele, index) => {
            return (
              <div key={index}>
                <h4>
                  v{ele.release_version}
                  <p className="date-container">{ele.release_date}</p>
                </h4>
                <div className="note-container" dangerouslySetInnerHTML={{ __html: ele.release_note }} />
              </div>
            );
          })}
        <div className="pagination">
          <Pagination
            firstItem={false}
            lastItem={false}
            defaultActivePage={1}
            totalPages={releaseNotes.data?.pages}
            size="mini"
            onPageChange={(event, data) => {
              dispatch(getReleaseNotes({ pageNo: data.activePage }));
            }}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={<Translate>I've Read</Translate>}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            dispatch(closeReleaseNotes());
            dispatch(readReleaseNotes(true));
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ReleaseNotesModal;
