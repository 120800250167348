import React, { useState } from "react";
import "./SMS.scss";
import { Checkbox } from "semantic-ui-react";
import _ from "lodash";

const months = [
  "Select Month",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function SMS({ PROMOTIONAL_SMS, selectTemplate, selectedTemplate, updateSMSField, templateValues }) {
  const [checkbox, setCheckbox] = useState(selectedTemplate);
  const PROMOTIONAL_SMS_OBJECT_KEYS = PROMOTIONAL_SMS?.flatMap(Object.keys);
  return (
    <div className="SMSPromotionContainer">
      {PROMOTIONAL_SMS_OBJECT_KEYS?.map((key, index) => {
        const arr = _.values(PROMOTIONAL_SMS[index])[0]?.split(" ");
        const sms = arr?.map((x) => {
          if (x.startsWith("{1")) {
            return (
              <div className="ui input" key={x}>
                <input
                  type="text"
                  placeholder="Type..."
                  className="textInput"
                  maxLength={30}
                  value={checkbox !== key ? "" : templateValues[x[3]] || ""}
                  disabled={checkbox !== key}
                  onChange={(e) => updateSMSField(x[3], e.target.value)}
                />
              </div>
            );
          } else if (x.startsWith("{2")) {
            return (
              <div className="ui input" key={x}>
                <input
                  type="number"
                  placeholder="Type..."
                  className="textInput"
                  maxLength={30}
                  value={checkbox !== key ? "" : templateValues[x[3]] || ""}
                  disabled={checkbox !== key}
                  onChange={(e) => updateSMSField(x[3], e.target.value.slice(0, 30))}
                />
              </div>
            );
          } else if (x.startsWith("{3")) {
            return (
              <div className="ui input" key={x}>
                <input
                  type="date"
                  placeholder="Type..."
                  className="inputDate"
                  maxLength={30}
                  value={checkbox !== key ? "" : templateValues[x[3]] || ""}
                  disabled={checkbox !== key}
                  onChange={(e) => updateSMSField(x[3], e.target.value)}
                />
              </div>
            );
          } else if (x.startsWith("{4")) {
            return (
              <div className="ui mini input" key={x}>
                <select
                  className="textInput"
                  value={checkbox !== key ? "" : templateValues[x[3]] || ""}
                  disabled={checkbox !== key}
                  onChange={(e) => updateSMSField(x[3], e.target.value)}
                >
                  {months.map((month, key) => {
                    return (
                      <option key={key} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          }
          return x + " ";
        });
        return (
          <div className="smsContainer" key={key}>
            <Checkbox
              radio
              label={""}
              name="sms"
              value={checkbox}
              checked={selectedTemplate === key}
              onChange={() => {
                selectTemplate(key);
                setCheckbox(key);
                // updateSMSField(-1, "");
              }}
            />
            <div className="smsMessage">{sms}</div>
          </div>
        );
      })}
      {/* <div className="flex row" style={{ justifyContent: 'space-between' }}>
      <Input
        placeholder='Input 1'
        onChange={(event, { value }) => updateSMSField('1', value)}
        value={templateValues['1'] || ''}
      // type='number'
      />
      <Input
        placeholder='Input 2'
        onChange={(event, { value }) => updateSMSField('2', value)}
        value={templateValues['2'] || ''}
        type='number'
      />
      <Input
        placeholder='Input 3'
        onChange={(event, { value }) => updateSMSField('3', value)}
        value={templateValues['3'] || ''}
        type='date'
      />
    </div> */}
    </div>
  );
}

export default SMS;
