const defaultState = {
  loading: false,
  results: [],
  pages: 0,
  page: 0,
  error: false,
  totalPending: 0,
};

export function customerWallet(state = defaultState, action) {
  switch (action.type) {
    case "CUSTOMER_WALLET_FETCH_DATA_SUCCESS":
      return { ...state, loading: false, error: false, ...action.customerWallet };
    case "CUSTOMER_WALLET_FETCH_DATA_LOADING":
      return { ...state, ...action.customerWallet };
    case "CUSTOMER_WALLET_FETCH_DATA_ERROR":
      return { ...state, ...action.customerWallet };
    case "CUSTOMER_WALLET_PENDING_AMOUNT":
      return { ...state, totalPending: action.data };
    default:
      return state;
  }
}

export function customerWalletInvoice(state = "", action) {
  switch (action.type) {
    case "CUSTOMER_WALLET_INVOICE_SUCCESS":
      return action.data;
    case "CUSTOMER_WALLET_INVOICE_FAIL":
      return state;
    case "CUSTOMER_WALLET_INVOICE_RESET":
      return "";
    default:
      return state;
  }
}
