import React, { useRef, useEffect } from "react";
import "./index.scss";
import { Form as Forms, Checkbox, Form, Button, Dropdown } from "semantic-ui-react";
import _, { values } from "lodash";
import { SideNav, Form as NewComponentForm } from "../../new-components";
import { Formik } from "formik";
import { validateCampaignForm } from "../../utilities/validator";
import { campaignPostData } from "../../store/actions/campaign";
import { useDispatch, useSelector } from "react-redux";
import { CampaignDiscountAllServicesProducts } from "./discountAllServicesAndProducts";
import { CampaignSpecificServicesProducts } from "./discountSpecificServicesAndProducts";
import { AddRemoveButtons } from "./addRemoveButton";
import { cloneDeep } from "lodash";
import { Translate } from "react-auto-translate";
import { PRODUCTS_DATA } from "../../store/actions/products";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

const CampaignSideNav = ({ showForm, setShowForm, form = {}, inAppointment = false }) => {
  const dispatch = useDispatch();
  const inventoryServices = useSelector((state) => state.services.data).filter((service) => service.status);
  const productsList = useSelector((state) => PRODUCTS_DATA(state));
  const inventoryproducts = productsList.filter((product) => product.quantity > 0 && product.status);
  const formikRef = useRef();

  useEffect(() => {}, [showForm]);

  const initialServiceForm = {
    service_id: null,
    discount_type: 0,
    discount_type_value: null,
    max_discount_amount: null,
  };

  const initialProductForm = {
    product_id: null,
    discount_type: 0,
    discount_type_value: null,
    max_discount_amount: null,
  };

  const initialFormData = {
    name: "",
    description: "",
    total_bill_discount: true,
    all_services: false,
    specific_services: false,
    specificDates: false,

    discount_type: "PERCENTAGE",
    discount_type_value: 0,
    max_discount_amount: 0,
    min_bill_amount: 0,

    all_services_discount_type: 0,
    all_services_discount_type_value: null,
    all_products_discount_type: 0,
    all_products_discount_type_value: null,
    all_product_checked: false,
    all_service_checked: false,
    specific_service_checked: false,
    specific_product_checked: false,

    services: [
      {
        ...initialServiceForm,
      },
    ],
    products: [
      {
        ...initialProductForm,
      },
    ],
  };

  const totalBillDiscountFields = {
    total_bill_discount: false,
    discount_type: "PERCENTAGE",
    discount_type_value: 0,
    max_discount_amount: 0,
    min_bill_amount: 0,
  };

  const allServiceDicountField = {
    all_services_discount_type: 0,
    all_services_discount_type_value: null,
    all_service_checked: false,
  };
  const allProductDiscountField = {
    all_products_discount_type: 0,
    all_products_discount_type_value: null,
    all_product_checked: false,
  };
  const specificServiceDiscountField = {
    specific_service_checked: false,
    services: [
      {
        ...initialServiceForm,
      },
    ],
  };

  const specificProductDiscountField = {
    specific_product_checked: false,
    products: [
      {
        ...initialProductForm,
      },
    ],
  };

  const discountTypeObj = {
    PERCENTAGE: "%",
    VALUE: "value",
  };

  const setCheckboxDiscountType = (type, field, checkedSts) => {
    const { values, setValues } = formikRef.current;
    switch (type) {
      case "total_bill_discount":
        const value = cloneDeep({
          ...values,
          ...allServiceDicountField,
          ...allProductDiscountField,
          ...specificServiceDiscountField,
          ...specificProductDiscountField,
          total_bill_discount: true,
        });
        setValues(value);
        break;
      case "services":
        if (field === "all_service") {
          let obj = {};
          if (!checkedSts) {
            obj = { ...allServiceDicountField };
          }
          const value = cloneDeep({
            ...values,
            ...specificServiceDiscountField,
            ...totalBillDiscountFields,
            ...obj,
            all_service_checked: checkedSts,
          });
          setValues(value);
        }
        if (field === "specific_service") {
          let obj = {};
          if (!checkedSts) {
            obj = { ...specificServiceDiscountField };
          }
          const value = cloneDeep({
            ...values,
            ...allServiceDicountField,
            ...totalBillDiscountFields,
            ...obj,
            specific_service_checked: checkedSts,
          });
          setValues(value);
        }

        break;
      case "products":
        if (field === "all_product") {
          let obj = {};
          if (!checkedSts) {
            obj = { ...allProductDiscountField };
          }
          const value = cloneDeep({
            ...values,
            ...specificProductDiscountField,
            ...totalBillDiscountFields,
            ...obj,
            all_product_checked: checkedSts,
          });
          setValues(value);
        }
        if (field === "specific_product") {
          let obj = {};
          if (!checkedSts) {
            obj = { ...specificProductDiscountField };
          }
          const value = cloneDeep({
            ...values,
            ...allProductDiscountField,
            ...totalBillDiscountFields,
            ...obj,
            specific_product_checked: checkedSts,
          });
          setValues(value);
        }
        break;
    }
  };

  const servicesDataFormat = (services) => {
    let formattedServices = [];
    services.forEach((elements, key) => {
      const { name, gender, cost, id } = elements;
      formattedServices.push({
        value: key,
        key,
        text: `${name} | ${gender} | ${cost}/-`,
        org_value: { ...elements },
      });
    });
    return formattedServices;
  };

  const productsOptionFormat = (products) => {
    let formattedProducts = [];
    products.forEach((elements, key) => {
      const { id, name, company } = elements;
      formattedProducts.push({
        value: formattedProducts.length,
        key: formattedProducts.length,
        text: `${name}${company?.name ? `(${company?.name})` : ""}`,
        org_value: { ...elements },
      });
    });
    return formattedProducts;
  };

  const updateCampaign = (values) => {
    const form = values;
    const { setValues } = formikRef.current;
    form.services_list = form["services"];
    form.products_list = form["products"];
    if (!form.all_service_checked) {
      delete form["all_services_discount_type"];
      delete form["all_services_discount_type_value"];
    }
    if (!form.all_product_checked) {
      delete form["all_products_discount_type"];
      delete form["all_products_discount_type_value"];
    }
    if (!form.services_list[0].service_id) {
      form.services_list = [];
    }
    if (!form.products_list[0].product_id) {
      form.products_list = [];
    }
    if (form.total_bill_discount) {
      delete form["all_services_discount_type"];
      delete form["all_services_discount_type_value"];
      delete form["all_products_discount_type"];
      delete form["all_products_discount_type_value"];
      form.services_list = [];
      form.products_list = [];
    }
    if (
      form.all_service_checked ||
      form.all_product_checked ||
      form.specific_service_checked ||
      form.specific_product_checked
    ) {
      delete form["discount_type"];
      delete form["discount_type_value"];
      delete form["max_discount_amount"];
      delete form["min_bill_amount"];
    }
    delete form["services"];
    delete form["products"];
    delete form["all_product_checked"];
    delete form["all_service_checked"];
    delete form["specific_service_checked"];
    delete form["specific_product_checked"];
    dispatch(campaignPostData(form, inAppointment));
    setShowForm();
    setValues({
      ...initialFormData,
    });
  };

  const addRemove = (e, action, type) => {
    e.preventDefault();
    const { values, setValues } = formikRef.current;
    if (action === "ADD") {
      if (type === "service") {
        let services = values.services;
        services.push({ ...initialServiceForm });
        setValues({ ...values, ...services });
      }
      if (type === "product") {
        let products = values.products;
        products.push({ ...initialProductForm });
        setValues({ ...values, ...products });
      }
    } else {
      if (values.services.length > 1 || values.products.length > 1) {
        if (type === "service") {
          let services = values.services;
          services.pop();
          setValues({ ...values, ...services });
        }
        if (type === "product") {
          let products = values.products;
          products.pop();
          setValues({ ...values, ...products });
        }
      }
    }
  };

  const updateCampaignServicesForm = (index, key, value, field) => {
    const { values, setValues } = formikRef.current;
    let formatedValue = values[field];
    formatedValue[index][key] = value;
    setValues({
      ...values,
      [field]: formatedValue,
    });
  };

  const closeSideNav = () => {
    const { setValues } = formikRef.current;
    setShowForm();
    setValues({ ...initialFormData });
  };

  return (
    <SideNav className="campaign-side-nav" condition={showForm} onCross={closeSideNav}>
      <Formik
        innerRef={formikRef}
        initialValues={initialFormData}
        validate={validateCampaignForm}
        onSubmit={(values) => {
          updateCampaign(values);
        }}
      >
        {(props) => {
          return (
            <NewComponentForm>
              <NewComponentForm.Title>
                <Translate>Add Discount</Translate>
              </NewComponentForm.Title>
              <NewComponentForm.Divider> </NewComponentForm.Divider>
              <Form onSubmit={props.handleSubmit}>
                <div className="two fields">
                  <Forms.Field>
                    <Form.Input
                      id="name"
                      type="text"
                      label={translateFormLabel("Discount Name*")}
                      error={props.errors.name}
                      placeholder="Enter Discount Name"
                      onChange={props.handleChange}
                      value={props.values.name}
                    />
                  </Forms.Field>
                  <Forms.Field>
                    <Form.Input
                      id="description"
                      label={translateFormLabel("Discount Description*")}
                      type="text"
                      placeholder="Enter Discount Description"
                      onChange={props.handleChange}
                      error={props.errors.description}
                      value={props.values.description}
                    />
                  </Forms.Field>
                </div>
                <div className="discounts-container">
                  <Forms.Field className="full-width" style={{ marginTop: "40px" }}>
                    <Checkbox
                      label={translateFormLabel("Discount on the total bill?")}
                      onChange={() => {
                        setCheckboxDiscountType("total_bill_discount");
                      }}
                      checked={props.values.total_bill_discount}
                    />
                  </Forms.Field>
                  <div style={{ display: !props.values.total_bill_discount ? "none" : "" }}>
                    <Form.Field className="grouped-radio">
                      <label>
                        <Translate>Discount Type</Translate>
                      </label>
                      <div className="flex">
                        <Form.Radio
                          label={translateFormLabel("Percentage")}
                          checked={props.values.discount_type === "PERCENTAGE"}
                          onChange={() => props.setFieldValue("discount_type", "PERCENTAGE")}
                        />
                        <Form.Radio
                          label={translateFormLabel("Value")}
                          checked={props.values.discount_type === "VALUE"}
                          onChange={() => props.setFieldValue("discount_type", "VALUE")}
                        />
                      </div>
                    </Form.Field>
                    <Form.Group widths="equal" className="width-100">
                      <Forms.Field>
                        <Form.Input
                          id="discount_type_value"
                          type="number"
                          placeholder={"Enter Discount "}
                          onChange={props.handleChange}
                          label={translateFormLabel(
                            `Discount*  ${props.values.discount_type && discountTypeObj[props.values.discount_type]}`
                          )}
                          error={props.errors.discount_type_value}
                          value={props.values.discount_type_value}
                        />
                      </Forms.Field>
                      <Forms.Field>
                        <Form.Input
                          id="max_discount_amount"
                          type="number"
                          placeholder="Enter Maximum Discount"
                          label={translateFormLabel("Max Discount*")}
                          error={props.errors.max_discount_amount}
                          onChange={props.handleChange}
                          value={props.values.max_discount_amount}
                        />
                      </Forms.Field>
                      <Forms.Field>
                        <Form.Input
                          id="min_bill_amount"
                          type="number"
                          placeholder="Enter Minimum Bill Amount"
                          label={translateFormLabel("Minimum Bill Amount*")}
                          error={props.errors.min_bill_amount}
                          onChange={props.handleChange}
                          value={props.values.min_bill_amount}
                        />
                      </Forms.Field>
                    </Form.Group>
                  </div>
                </div>
                <div className="discounts-container">
                  <Form.Checkbox
                    label={translateFormLabel("Discount on all services?")}
                    onChange={() =>
                      setCheckboxDiscountType("services", "all_service", !props.values.all_service_checked)
                    }
                    checked={props.values.all_service_checked}
                    className="discount-all-checkbox"
                  />
                  <div
                    style={{
                      padding: "5px",
                      marginTop: "9px",
                      display: !props.values.all_service_checked ? "none" : "",
                    }}
                  >
                    <CampaignDiscountAllServicesProducts formik={props} type="services" />
                  </div>
                </div>
                <div className="discounts-container">
                  <Form.Checkbox
                    label={translateFormLabel("Discount on specific services?")}
                    onChange={() =>
                      setCheckboxDiscountType("services", "specific_service", !props.values.specific_service_checked)
                    }
                    checked={props.values.specific_service_checked}
                  />
                  <div
                    className="campaign-services-container"
                    style={{ marginTop: "4px", display: !props.values.specific_service_checked ? "none" : "" }}
                  >
                    {props.values?.services?.map((eachService, key) => {
                      return (
                        <div key={key}>
                          <CampaignSpecificServicesProducts
                            type="services"
                            formik={props}
                            dropDownOptions={servicesDataFormat(inventoryServices)}
                            dropDownValues={_.findIndex(inventoryServices, { id: eachService.service_id })}
                            onChange={updateCampaignServicesForm}
                            index={key}
                            dataObj={eachService}
                          />
                        </div>
                      );
                    })}
                    <AddRemoveButtons addRemove={addRemove} type="service" className="campaign-services-add-remove" />
                  </div>
                </div>
                <div className="discounts-container">
                  <Form.Checkbox
                    label={translateFormLabel("Discount on all products?")}
                    onChange={() =>
                      setCheckboxDiscountType("products", "all_product", !props.values.all_product_checked)
                    }
                    checked={props.values.all_product_checked}
                    className="discount-all-checkbox"
                  />
                  <div
                    style={{
                      padding: "5px",
                      marginTop: "9px",
                      display: !props.values.all_product_checked ? "none" : "",
                    }}
                  >
                    <CampaignDiscountAllServicesProducts formik={props} type="products" />
                  </div>
                </div>
                <div className="discounts-container">
                  <Form.Checkbox
                    label={translateFormLabel("Discount on specific products?")}
                    onChange={() =>
                      setCheckboxDiscountType("products", "specific_product", !props.values.specific_product_checked)
                    }
                    checked={props.values.specific_product_checked}
                  />
                  <div
                    className="campaign-services-container"
                    style={{ marginTop: "4px", display: !props.values.specific_product_checked ? "none" : "" }}
                  >
                    {props.values?.products?.map((eachProduct, key) => {
                      return (
                        <div key={key}>
                          <CampaignSpecificServicesProducts
                            type="products"
                            formik={props}
                            dropDownOptions={productsOptionFormat(inventoryproducts)}
                            dropDownValues={_.findIndex(inventoryproducts, { id: eachProduct.product_id })}
                            onChange={updateCampaignServicesForm}
                            index={key}
                            dataObj={eachProduct}
                          />
                        </div>
                      );
                    })}
                    <AddRemoveButtons addRemove={addRemove} type="product" className="campaign-services-add-remove" />
                  </div>
                </div>
                <div className="common-error-msg">{props.errors.all_discount_checked}</div>
                {!form?.id && (
                  <div className="actions">
                    <Button type="submit" className="ui button">
                      <Translate>Create</Translate>
                    </Button>
                    <button className="ui button" onClick={closeSideNav}>
                      <Translate>Cancel</Translate>
                    </button>
                  </div>
                )}
              </Form>
            </NewComponentForm>
          );
        }}
      </Formik>
    </SideNav>
  );
};

export default CampaignSideNav;
