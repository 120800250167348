import { API_BASE_URL } from "utilities";
import axios from "axios";

export const marketplaceStartTransaction = (data) => {
  let URL = API_BASE_URL + "/marketplace/transactions/transactions/";
  data = {
    order_transaction: data,
  };
  return async (dispatch) => {
    try {
      const res = await axios.post(URL, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "MARKETPLACE_START_TRANSACTION_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "MARKETPLACE_START_TRANSACTION_ERROR",
      });
    }
  };
};
