export function clientMemberships(state = [], action) {
  switch (action.type) {
    case "CLIENT_MEMBERSHIP_FETCH_DATA_SUCCESS":
      return action.clientMemberships;
    case "CLIENT_MEMBERSHIP_RESET":
      return action.clientMemberships;
    default:
      return state;
  }
}
