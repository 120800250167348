export function invoiceFilters(state = [], action) {
  switch (action.type) {
    case "INVOICE_FILTERS_SUCCESS":
      return action.data;
    case "INVOICE_FILTERS_FAILURE":
      return [];
    default:
      return state;
  }
}
