import { API_BASE_URL, SERVICE_PRODUCT_USAGE_API } from "utilities";
import axios from "axios";
import _ from "lodash";
import configureStore from "../store/configureStore";
import { authenticationLogout } from "../store/actions/authentication";
import ToastAlert from "../utilities/toastAlert/toastAlert";
import { errorMessage, productUsageActions } from "../utilities/actionMessages";
const store = configureStore();

export async function serviceProductUsageFetchData(service_id = null) {
  const url = API_BASE_URL + SERVICE_PRODUCT_USAGE_API + `?service_id=${service_id}`;
  const headers = {
    Authorization: localStorage.getItem("token"),
  };

  return axios
    .get(url, {
      headers,
    })
    .then((response) => {
      return { status: "success", response };
    })
    .catch(function (error) {
      if (error && error.response && error.response.status === 401) {
        store.dispatch(authenticationLogout());
      }
  
      const message = JSON.stringify(error?.response?.data) || errorMessage;
      ToastAlert({ message: message, type: "error" });
      return { status: "error", error };
    });
}

//
export const postServiceProductUsage = async (service_id = null, data) => {
  const url = API_BASE_URL + SERVICE_PRODUCT_USAGE_API + `?service_id=${service_id}`;
  const headers = {
    Authorization: localStorage.getItem("token"),
  };

  return axios
    .post(url, data, {
      headers,
    })
    .then(function (response) {
      ToastAlert({ message: productUsageActions.success_update, type: "success" });
      return { status: "success", response };
    })
    .catch(function (error) {
      if (error && error.response && error.response.status === 401) {
        store.dispatch(authenticationLogout());
      }

      const message = JSON.stringify(error?.response?.data) || errorMessage;
      ToastAlert({ message: message, type: "error" });
      return { status: "error", error };
    });
};
