import { API_BASE_URL, BRANCH_API } from "utilities";
import axios from "axios";

export function updateLocation(payload) {
  return (dispatch) => {
    axios({
      url: API_BASE_URL + BRANCH_API + "location/",
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(() => {
        dispatch(branchFetchLocation());
      })
      .catch((err) => {
        console.error({ err });
        dispatch(branchFetchLocation());
      });
  };
}

export function branchFetchLocation() {
  return (dispatch) => {
    axios({
      url: API_BASE_URL + BRANCH_API + "location/",
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        const data = response.data.results[0];
        dispatch({
          type: "LOCATION_RECEIVED",
          data,
        });
      })
      .catch((err) => {
        console.error({ err });
      });
  };
}
