import React, { useEffect, useState } from "react";
import "./index.scss";
import { Form as Forms, Dropdown, Header } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AsyncLocalStorage from "@createnextapp/async-local-storage";

function BranchSelect() {
  const history = useHistory();
  const location = useLocation();
  const defaultBranch = useSelector((state) => state.branch.data);
  const client = useSelector((state) => state.client.clients);
  const { data: userAccessLevel } = useSelector((state) => state.aclUserPermissions);
  const [selectedBranch, setSelectedBranch] = useState(defaultBranch);
  const [currentLocation, setCurrentLocation] = useState("");

  const getClientBranch = () => {
    let branches = [];
    let key = 0;
    if (client) {
      client.forEach((element) => {

        let address = element.address
        if (address.length > 10) {
          address = address.substring(0, 9) + "...";
        }

        branches.push({
          key: key++,
          text: element.name,
          value: element?.id,
          title: element.address,
          content: (
            <Header content={element.name} subheader={`${address}/${element.pin_code}`} />
          ),
        });
      });
    }
    return branches;
  };

  useEffect(() => {
    async function initialFunc() {
      let adminBranch = await AsyncLocalStorage.getItem("adminBranch");
      setSelectedBranch({ id: adminBranch });
    }
    initialFunc();
  }, []);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  const onBranchChange = async (value) => {
    try {
      await AsyncLocalStorage.setItem("adminBranch", value);
      setSelectedBranch({ id: value });
      history.replace("/pageload");
      setTimeout(() => {
        history.replace(currentLocation);
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="branch-selector">
      {userAccessLevel.formatted_component_permissions.includes("admin_access") ? (
        <Forms.Field>
          <Dropdown
            placeholder="Select Branch"
            fluid
            selection
            value={selectedBranch?.id}
            options={getClientBranch()}
            onChange={(e, { value }) => {
              onBranchChange(value);
            }}
          />
        </Forms.Field>
      ) : (
        ""
      )}
    </div>
  );
}
export default BranchSelect;
