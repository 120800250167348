const initialState = {
  count: 0,
  current_page: 1,
  pages: 1,
  data: [],
  loading: false,
  failed: false,
  balance: 0,
  id: null,
};

export function staffAdvanceReducer(state = initialState, action) {
  switch (action.type) {
    case "STAFF_ADVANCE_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }

    case "STAFF_ADVANCE_SUCCESS": {
      return {
        ...state,
        ...action.response,
        loading: false,
        failed: false,
      };
    }

    case "STAFF_ADVANCE_FAILED": {
      return {
        ...state,
        loading: false,
        failed: true,
      };
    }

    case "STAFF_ADVANCE_RESET": {
      return {
        ...initialState,
      };
    }

    case "STAFF_ADVANCE_BALANCE_SUCCESS": {
      return {
        ...state,
        ...action.response,
      };
    }

    case "STAFF_ADVANCE_BALANCE_FAILED": {
      return {
        ...state,
        ...action.response,
      };
    }

    default: {
      return state;
    }
  }
}
