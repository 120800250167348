import React from "react";
import { Form } from "semantic-ui-react";
import { ViewAllServicesProducts } from "./viewAllServicesProducts";
import { ViewSpecificServiceProducts } from "./viewSpecificServiceProducts";
import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};



function ViewCampaign({ campaign, inventoryServices, inventoryProducts }) {
  const {
    name,
    description,
    discount_type,
    discount_type_value,
    max_discount_amount,
    min_bill_amount,
    all_services_discount_type_value,
    all_products_discount_type_value,
    all_services_discount_type,
    services,
    products,
  } = campaign;
  return (
    <Form>
      <div className="two fields">
        <Form.Field>
          <label>
            <Translate>Discount Name</Translate>
          </label>
          <input type="text" placeholder="Enter Discount Name" value={name} />
        </Form.Field>
        <Form.Field>
          <label>
            <Translate>Discount Description</Translate>
          </label>
          <input type="text" placeholder="Enter Discount Description" value={description} />
        </Form.Field>
      </div>
      {discount_type && discount_type.length && (
        <>
          <h3>
            <Translate>Discount on the total bill</Translate>
          </h3>
          <Form.Field className="grouped-radio">
            <label>
              <Translate>Discount Type</Translate>
            </label>
            <div className="flex">
              <Form.Radio
                label={translateFormLabel("Percentage")}
                checked={discount_type === "PERCENTAGE"}
                onChange={() => this.setForm("discount_type", "PERCENTAGE")}
              />
              <Form.Radio
                label={translateFormLabel("Value")}
                checked={discount_type === "VALUE"}
                onChange={() => this.setForm("discount_type", "VALUE")}
              />
            </div>
          </Form.Field>
          <Form.Group widths="equal" className="width-100">
            <Form.Field>
              <label>
                <Translate>Discount {["Percentage", "Value"][discount_type]}</Translate>
              </label>
              <input type="number" placeholder={"Enter Discount "} value={discount_type_value || ""} />
            </Form.Field>
            <Form.Field>
              <label>
                <Translate>Max Discount</Translate>
              </label>
              <input type="number" placeholder="Enter Maximum Discount" value={max_discount_amount || ""} />
            </Form.Field>
            <Form.Field>
              <label>
                <Translate>Minimum Bill Amount</Translate>
              </label>
              <input type="number" placeholder="Enter Minimum Bill Amount" value={min_bill_amount || ""} />
            </Form.Field>
          </Form.Group>
        </>
      )}
      {all_services_discount_type_value !== null && (
        <>
          <h3>
            <Translate>Discount on all services</Translate>
          </h3>
          <div>
            {all_services_discount_type_value !== null && (
              <ViewAllServicesProducts type="services" campaign={campaign} />
            )}
          </div>
        </>
      )}

      {all_products_discount_type_value !== null && (
        <>
          <h3>
            <Translate>Discount on all products</Translate>
          </h3>
          <div>
            <ViewAllServicesProducts type="products" campaign={campaign} />
          </div>
        </>
      )}
      {services && services?.length !== 0 && (
        <>
          <h3>
            <Translate>Discount On Specific Services</Translate>
          </h3>
          <div className="campaign-services-container width-100" style={{ marginTop: "4px" }}>
            {services?.map((eachService, key) => {
              const filteredService = inventoryServices.filter((service) => service.id === eachService?.service_id)[0];
              return (
                <div key={key}>
                  {eachService.service_id !== null && eachService && (
                    <ViewSpecificServiceProducts
                      type="service"
                      dataObj={eachService}
                      filteredShowValue={filteredService}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      {products && products?.length !== 0 && (
        <>
          <h3>
            <Translate>Discount On Specific Products</Translate>
          </h3>
          <div className="campaign-services-container width-100" style={{ marginTop: "4px" }}>
            {products?.map((eachProduct, key) => {
              const filteredProducts = inventoryProducts.filter(
                (product_item) => product_item.id === eachProduct.product_id
              )[0];
              return (
                <div key={key}>
                  {eachProduct.product_id !== null && eachProduct && (
                    <ViewSpecificServiceProducts
                      type="product"
                      dataObj={eachProduct}
                      filteredShowValue={filteredProducts}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </Form>
  );
}

export default ViewCampaign;
