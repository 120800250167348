const initalState = {
  data: {},
  count: 0,
  loading: false,
  errored: false,
};

export const marketPlaceDueOrders = (state = { ...initalState }, action) => {
  switch (action.type) {
    case "DUE_ORDER_LIST_SUCCESS":
      return {
        ...initalState,
        data: action.payload,
        count: action.payload.count,
      };
    default:
      return state;
  }
};
