import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Input, Icon, Dropdown } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import { useServiceProductUsage } from "../../hooks/useServiceProductUsage";
import { useSelector } from "react-redux";
import { productsOptionsSelector } from "../../store/selectors/productOptionsSelector";

const initProductUsage = {
  service_id: "",
  product_id: "",
  consumption_value: "",
};

function ServiceProductUsage({ id, close }) {
  const {
    isLoading,
    isError,
    productUsage: productUsageState = [],
    getProductUsage,
    postProductUsage,
  } = useServiceProductUsage();
  const productsOptions = useSelector(productsOptionsSelector);
  const [productUsage, setProductUsage] = useState([]);
  const [fieldError, setFieldError] = useState("");

  useEffect(() => {
    getProductUsage(id);
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!productUsageState?.length) {
      setProductUsage([{ ...initProductUsage }]);
      return;
    }

    setProductUsage(productUsageState);
  }, [productUsageState, isLoading]);

  const updateClickFunc = () => {
    let productUsagePayload = {
      service_id: id,
      consumption: [],
    };

    for (let productItem of productUsage) {
      if (!productItem.product_id && !productItem.consumption_value) {
        continue;
      } else if (!productItem.product_id || !productItem.consumption_value) {
        setFieldError(true);
        return;
      } else {
        const consume = {
          product_id: productItem.product_id,
          consumption_value: productItem.consumption_value,
        };
        productUsagePayload.consumption.push(consume);
      }
    }

    if (productUsage && productUsage.length === 1 && productUsage[0].product_id === "") {
      productUsagePayload = {
        service_id: id,
        consumption: [],
      };
    }

    postProductUsage(productUsagePayload);
    close();
  };

  const popProductUsageRow = (index) => {
    let rows = [...productUsage];
    rows.splice(index, 1);
    rows = rows.length ? rows : [{ ...initProductUsage }];
    setProductUsage([...rows]);
  };

  const addProductUsageRow = (index) => {
    const rows = [...productUsage];
    rows.splice(index + 1, 0, { ...initProductUsage });
    setProductUsage([...rows]);
  };

  return (
    <div className="productusage-container">
      <h3 style={{ marginTop: "10px" }}>
        <Translate>Product Usage</Translate>
      </h3>
      {/*  */}
      {isLoading && <p className="info">Loading Latest Data...</p>}
      {isError && <p className="error">Could not load latest data, Please try again</p>}
      <div>
        {productUsage?.map((ele, index) => {
          return (
            <div key={index} className="form-wrapper">
              <div style={{ width: "200px" }}>
                <Input
                  className="consumed"
                  labelPosition="right corner"
                  placeholder="Product Consumption"
                  onChange={(event, value) => {
                    const data = [...productUsage];
                    data[index].consumption_value = value.value;
                    setFieldError(false);
                    setProductUsage(data);
                  }}
                  value={ele.consumption_value || ""}
                  type="number"
                />
              </div>
              <div className="dropdown-style">
                <Dropdown
                  style={{ width: "300px" }}
                  placeholder={"Select Product Used"}
                  options={productsOptions}
                  onChange={(event, value) => {
                    const data = [...productUsage];
                    data[index].product_id = value.value;
                    setFieldError(false);
                    setProductUsage(data);
                  }}
                  value={ele.product_id}
                  search
                  selection
                />
              </div>
              <div className="row-style">
                <Icon circular inverted color="grey" name="add" onClick={() => addProductUsageRow(index)} />
                <Icon circular inverted color="grey" name="minus" onClick={() => popProductUsageRow(index)} />
              </div>
            </div>
          );
        })}
        {fieldError && <p style={{ padding: "10px 0px", color: "red" }}>No field should be empty</p>}
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "100px",
          }}
        >
          <Button
            disabled={isLoading}
            onClick={() => {
              updateClickFunc();
            }}
          >
            <Translate>Update</Translate>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ServiceProductUsage;
