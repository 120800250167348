import React, { useState } from "react";
import axios from "axios";
import exportFromJSON from "export-from-json";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { API_BASE_URL, BRANCH_WALLET_API } from "../../utilities/constants";

import moment from "moment";
import { EXPENSE_APPROVAL_OPTIONS } from "../../utilities/constants";

const download = async ({ start_date, end_date, expenseSearch = "" }) => {
  try {
    let url =
      API_BASE_URL +
      BRANCH_WALLET_API +
      `?start_date=${start_date}&end_date=${end_date}&search=${expenseSearch}&no_page=true`;

    const res = await axios({
      url,
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    const fileName = "expenses";
    const exportType = "csv";
    const data = prepareDataForDownload(res.data);

    exportFromJSON({ data, fileName, exportType });
    return true;
  } catch (error) {
    ToastAlert({ message: "Unable to download expense. Please try again later.", type: "error" });
    return false;
  }
};

const prepareDataForDownload = (data = []) => {
  const preparedData = data.map((expense) => {
    const { type, category, sub_type, date, category_qtn, amount, transaction_type, payment_mode, approval_status } =
      expense;

    const formattedDate = moment(date).format("DD/MM/YYYY");

    return {
      "Expense Details": type,
      category: category?.name || "",
      date: formattedDate,
      quantity: category_qtn || "",
      amount,
      ["Transaction Type"]: ["Credit", "Debit"][transaction_type],
      ["Payment Mode"]: payment_mode || "",
      description: sub_type,
      "Expense Approval": EXPENSE_APPROVAL_OPTIONS[approval_status],
    };
  });

  return preparedData;
};

export const useDownloadExpenseCSV = () => {
  const [isFetching, setIsFetching] = useState(false);

  const downloadExpensesCSV = async (data) => {
    setIsFetching(true);
    await download(data);
    setIsFetching(false);
  };

  return {
    isExpenseCSVFetching: isFetching,
    downloadExpensesCSV,
  };
};
