import { API_BASE_URL, SHIFTS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert"
import { shiftActions, errorMessage } from "../../utilities/actionMessages";

export function shiftsFetchDataSuccess(shifts) {
  return {
    type: "SHIFTS_FETCH_DATA_SUCCESS",
    shifts,
  };
}

export function shiftsFetchData(shifts) {
  return (dispatch) => {
    dispatch(startLoading("SHIFTS_LOADER", "loading shifts"));
    axios
      .get(API_BASE_URL + SHIFTS_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(shiftsFetchDataSuccess(response.data.results));
          dispatch(endLoading("SHIFTS_LOADER"));
        } else {
          dispatch(errored("SHIFTS_LOADER", true));
          dispatch(endLoading("SHIFTS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SHIFTS_LOADER", true));
        dispatch(endLoading("SHIFTS_LOADER"));
      });
  };
}

export function shiftsPostData(shift) {
  return (dispatch) => {
    dispatch(startLoading("SHIFTS_LOADER", "loading positions"));
    axios({
      method: "post",
      url: API_BASE_URL + SHIFTS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: shift,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({message:shiftActions.success_create,type:"success"})
          dispatch(shiftsFetchData());
          dispatch(endLoading("SHIFTS_LOADER"));
        } else {
          dispatch(errored("SHIFTS_LOADER", true));
          dispatch(endLoading("SHIFTS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SHIFTS_LOADER", true));
        dispatch(endLoading("SHIFTS_LOADER"));
      });
  };
}

export function shiftsPutData(id, shift) {
  return (dispatch) => {
    dispatch(startLoading("SHIFTS_LOADER", "loading positions"));
    axios({
      method: "put",
      url: API_BASE_URL + SHIFTS_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: shift,
    })
      .then(function (response) {
        if (response.status === 200) {
          //since only deletion is happening no updation
          ToastAlert({message:shiftActions.success_delete,type:"success"})
          dispatch(shiftsFetchData());
          dispatch(endLoading("SHIFTS_LOADER"));
        } else {
          dispatch(errored("SHIFTS_LOADER", true));
          dispatch(endLoading("SHIFTS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SHIFTS_LOADER", true));
        dispatch(endLoading("SHIFTS_LOADER"));
      });
  };
}
