import { API_BASE_URL } from "utilities";
import { endLoading, errored } from "./withLoader";
import axios from "axios";

export const getDueOrders = (search, pageNum = 1) => {
  let URL = API_BASE_URL + "marketplace/orders/order/?payment_status=true";
  return async (dispatch) => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "DUE_ORDER_LIST_SUCCESS",
        payload: res.data,
      });
      dispatch(endLoading("ORDERS_LOADER"));
    } catch (error) {
      dispatch(errored("ORDERS_LOADER", true));
      dispatch(endLoading("ORDERS_LOADER"));
    }
  };
};
