import React from "react";
import { Translate } from "react-auto-translate";

export const AllServicesProducts = (props) => {
  const { type={}, campaign={} } = props;
  
  return (
    <>
      <h5 className="campaign-type">
        <Translate>Discount Across All </Translate> {type}
      </h5>
      <div className="campaign-popup-row">
        <div>
          <span className="bold-margin">
            <Translate>Discount Type:</Translate>
          </span>
          <Translate>{campaign[`all_${type}_discount_type`] == 0 ? "Percentage" : "Value"}</Translate>
        </div>
        <div>
          <span className="bold-margin">
            <Translate>Discount:</Translate>
          </span>
          {campaign[`all_${type}_discount_type_value`]}
        </div>
      </div>
    </>
  );
};
