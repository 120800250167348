export function payments(state = { error: false, loading: false, data: null }, action) {
  switch (action.type) {
    case "PAYMENTS_FETCH_DATA_SUCCESS":
      return {
        error: false,
        loading: false,
        data: action.payments,
      };
    case "PAYMENTS_RESET":
      return {
        error: false,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
}
