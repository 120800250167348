import { createSelector } from "reselect";

const clientLabelsSelector = (state) => state.clientLabels;
const serviceLabelsSelector = (state) => state.serviceLabels;
const productLabelsSelector = (state) => state.productLabels;

export const clientLabelOptionsSelector = createSelector([clientLabelsSelector], (labels) => {
  const labelOptions = labels.data?.map((label) => {
    return formatLabelOption(label);
  });

  return labelOptions;
});

export const serviceLabelOptionsSelector = createSelector([serviceLabelsSelector], (labels) => {
  const labelOptions = labels.data?.map((label) => {
    return formatLabelOption(label);
  });

  return labelOptions;
});

export const productLabelOptionsSelector = createSelector([productLabelsSelector], (labels) => {
  const labelOptions = labels.data?.map((label) => {
    return formatLabelOption(label);
  });

  return labelOptions;
});

//
const formatLabelOption = (label) => {
  const { short_name, name, id, properties } = label;

  return {
    value: id,
    text: short_name,
    description: name,
    label: { color: properties.color, empty: true, circular: true },
  };
};
