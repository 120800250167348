import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Description from "Description";
import HoverTable from "HoverTable";
import { Icon, Label, Popup } from "semantic-ui-react";
import { deleteRecurringPromotion } from "../../store/actions/promotion";
import Toggler from "../Toggler/index";

import "./index.scss";
import { getLocalTime } from "../../utilities/dateTime";
import { getRecurringPromotion } from "../../store/actions/bulkPromotionView";

const PROMOTION_TYPE = {
  0: "EMAIL",
  1: "SMS",
  2: "WHATSAPP",
};

const TYPELABELCOLOR = {
  0: "yellow",
  1: "orange",
  2: "green",
};

const WEEKLY = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const MessagePopup = ({ message }) => <Popup trigger={<span className="showmore">...</span>}>{message}</Popup>;

function ScheduledPromotion(props) {
  const dispatch = useDispatch();
  const customerFilters = useSelector((state) => state.customers.filters);
  const { show, scheduledPromotionHistoryData } = props;
  const [hideDeactivated, setHideDeactivated] = useState(false);

  if (!show) {
    return null;
  }

  const scheduledPromotionsData = [
    ({ type }) => (
      <Label size="tiny" as="a" color={TYPELABELCOLOR[type]}>
        {PROMOTION_TYPE[type]}
      </Label>
    ),
    ({ message }) => {
      return (
        <div className="flex message-description">
          <Description title={message?.slice(0, 30)} />
          {message?.length > 30 && <MessagePopup message={message} />}
        </div>
      );
    },
    ({ client_filter_id, customer_ids, send_to_all, ...data }) => {
      if (client_filter_id) {
        const filterUsed = customerFilters.filter((i) => i.key === client_filter_id);
        if (filterUsed?.length) {
          return <Description title={`Send to all clients with ${filterUsed[0].text} filter.`} />;
        }
      }
      if (customer_ids) {
        return <Description title={`Send to selected Clients`} />;
      }

      if (send_to_all) {
        return <Description title={`Send to all`} />;
      }

      return <Description title="Send to specific contacts." />;
    },
    ({ image1 }) => {
      if (image1) {
        return (
          <div className="flex info-content">
            <a href={image1?.url} target="_blank" className="promotion-external-link">
              Image <Icon name="external" link={true} size="small" />
            </a>
          </div>
        );
      }
      return (
        <Label color="black" size="tiny">
          N/A
        </Label>
      );
    },
    ({ recurring_frequency, schedule_day, schedule_day_number, schedule_time }) => {
      // convert schedule_time to local
      const localTime = getLocalTime(schedule_time);
      if (recurring_frequency === 0) {
        return <Description title={`daily at ${localTime}`} />;
      }
      if (recurring_frequency === 1) {
        return <Description title={`Weekly schedule ${WEEKLY[schedule_day]} at ${localTime}`} />;
      }

      if (recurring_frequency === 2) {
        return <Description title={`Every ${schedule_day_number} of the month at ${localTime}`} />;
      }
      return <Description title="invalid schedule" />;
    },

    ({ status }) => {
      if (status) {
        return (
          <Label size="tiny" as="a" color="green">
            Ongoing
          </Label>
        );
      }
      return (
        <Label size="tiny" as="a" color="red">
          Deactivated
        </Label>
      );
    },
    ({ id, status }, edit, remove) => {
      return [
        <Icon key={1} disabled={!status} className={`${status ? "pointer" : ""} trash`} onClick={() => remove(id)} />,
      ];
    },
  ];

  const toggleHideDeactivated = () => {
    setHideDeactivated(!hideDeactivated);
  };

  const data = JSON.parse(JSON.stringify(scheduledPromotionHistoryData));
  if (hideDeactivated) {
    delete data.results;
    data.results = scheduledPromotionHistoryData.results.filter((item) => item.status);
  }

  return (
    <>
      <Toggler
        toggle
        style={{
          maxWidth: "400px",
          minWidth: "200px",
          marginBottom: "20px",
        }}
        onChange={toggleHideDeactivated}
        checked={hideDeactivated}
        label="Hide deactivated Recurring Promotions"
      />
      <HoverTable
        header={["Type", "Message", "Receipient", "Info", "Schedule", "Status", "Action"]}
        src={data}
        renderSpecial={scheduledPromotionsData}
        remove={(id) => {
          dispatch(deleteRecurringPromotion(id));
        }}
        toPaginate={true}
        changePage={(pageNum) => dispatch(getRecurringPromotion(pageNum))}
      />
    </>
  );
}

export default ScheduledPromotion;
