const initState = {
  data: null,
  error: false,
  success: false,
  confirmedSalary: null,
};

export function staffSalary(state = initState, { type, data, ...action }) {
  switch (type) {
    case "GET_SALARY":
      const temp = action.payload.length === 0 ? null : action.payload[0];
      return {
        ...state,
        data: temp,
      };

    case "STAFF_SALARY_CONFIRM_SUCCESS":
      return {
        ...state,
        success: true,
        error: false,
      };

    case "STAFF_SALARY_CONFIRM_FAILED":
      return {
        ...state,
        success: false,
        error: data,
      };

    case "STAFF_SALARY_RESET_FLAG":
      return {
        ...state,
        success: false,
        error: false,
      };

    case "STAFF_SALARY_FETCH":
      return {
        ...state,
        data,
      };

    case "STAFF_SALARY_RESET":
      return {
        ...state,
        data: null,
        success: false,
        error: false,
        confirmedSalary: null,
      };

    case "FETCH_STAFF_CONFIRMED_SALARY":
      const salary = data.length === 1 ? data[0] : null;
      return {
        ...state,
        confirmedSalary: salary,
      };

    case "FETCH_STAFF_COMMISSION_SALARY":
      return {
        ...state,
        data: {
          ...state.data,
          commission: data,
        },
      };

    case "FETCH_STAFF_LEAVE_SALARY":
      return {
        ...state,
        data: {
          ...state.data,
          leaves: data.leaves,
        },
      };

    default:
      return state;
  }
}
