import { API_BASE_URL, WALLET_CATEGORY_API } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { expenseCategoriesActions, errorMessage } from "../../utilities/actionMessages";

export function expenseCategoriesFetchRequest() {
  return {
    type: "EXPENSE_CATEGORIES_FETCH_REQUEST",
  };
}

export function expenseCategoriesFetchSuccess(categories, status) {
  return {
    type: status?"EXPENSE_ACTIVE_CATEGORIES_FETCH_SUCCESS":"EXPENSE_DELETED_CATEGORIES_FETCH_SUCCESS",
    payload: categories,
  };
}

export function expenseCategoriesFetchFailure() {
  return {
    type: "EXPENSE_CATEGORIES_FETCH_FAILURE",
  };
}

export function expenseCategoriesFetchData(status) {
  return (dispatch) => {
    dispatch(expenseCategoriesFetchRequest());
    axios({
      url: API_BASE_URL + WALLET_CATEGORY_API + "?status=" + status, 
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(expenseCategoriesFetchSuccess(response.data.results, status));
        } else {
          dispatch(expenseCategoriesFetchFailure());
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(expenseCategoriesFetchFailure());
      });
  };
}

export function expenseCategoriesPostData(category) {
  return (dispatch) => {
    dispatch(expenseCategoriesFetchRequest());
    axios({
      method: "post",
      url: API_BASE_URL + WALLET_CATEGORY_API,
      data: category,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: expenseCategoriesActions.success_create, type: "success" });
          dispatch(expenseCategoriesFetchData(1));
        } else {
          dispatch(expenseCategoriesFetchFailure());
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(expenseCategoriesFetchFailure());
      });
  };
}

export function expenseCategoriesPutData(id, category) {
  return (dispatch) => {
    dispatch(expenseCategoriesFetchRequest());
    axios({
      method: "put",
      url: API_BASE_URL + WALLET_CATEGORY_API + id + "/",
      data: category,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({ message: expenseCategoriesActions.success_update, type: "success" });
          dispatch(expenseCategoriesFetchData(1));
        } else {
          dispatch(expenseCategoriesFetchFailure());
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(expenseCategoriesFetchFailure());
      });
  };
}

export function expenseCategoryEditStatus(id, status) {
  return (dispatch) => {
    dispatch(expenseCategoriesFetchRequest());
    axios({
      method: "put",
      url: API_BASE_URL + WALLET_CATEGORY_API + id + "/status/",
      data: status,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.status) {
            ToastAlert({ message: expenseCategoriesActions.success_delete, type: "success" });
          }
          else {
            ToastAlert({ message: expenseCategoriesActions.success_update, type: "success" });
          }
          // dispatch(expenseCategoriesFetchData(0)); onToggle Only
          dispatch(expenseCategoriesFetchData(1));
        } else {
          dispatch(expenseCategoriesFetchFailure());
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(expenseCategoriesFetchFailure());
      });
  };
}
