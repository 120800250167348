import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";

import GenerateInvoice from "GenerateInvoice";
import HoverTable from "HoverTable";
import Description from "Description";
import Modal from "Modal";
import { Icon } from "semantic-ui-react";
import { getsingularAppointment, singularAppointmentReset } from "../../store/actions/singularAppointment";
import { getPendingHistory } from "../../store/actions/pendings";
import { Translate } from "react-auto-translate";

function PendingHistory({ fetchAppointments = () => {} }) {
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState(false);
  const pendings = useSelector((state) => state.pendings);
  const singular = useSelector((state) => state.singularAppointment);

  useEffect(() => {
    if (invoice) {
      dispatch(getsingularAppointment(invoice));
    }
  }, [invoice]);

  const dateTime = (datetime) => {
    return new Date(datetime).toLocaleString().split("/").join("-").split(",").join("");
  };

  const renderData = [
    ({ appointment }) => <Description title={appointment} />,
    ({ balance_amount }) => <Description title={`Rs. ${parseFloat(balance_amount).toFixed(2) * -1}/-`} />,
    ({ modified_on }) => <Description title={dateTime(modified_on)} />,
    ({ appointment }) => (
      <Icon className="eye pointer" size="large" title="Invoice" onClick={() => setInvoice(appointment)} />
    ),
  ];
  return (
    <>
      <h3>
        <Translate>Pending History</Translate>
      </h3>
      <HoverTable
        header={["Appointment", "Due", "Last updated at", "Invoice"]}
        src={pendings}
        renderSpecial={renderData}
        toPaginate={true}
        changePage={(pageNum) => dispatch(getPendingHistory(pendings.customer_id, pageNum))}
      />
      {/* {totalPendingAmount > 0 && (
        <h3 style={{ textAlign: "right" }}>Total Pending Amount: &#8377; {totalPendingAmount}</h3>
      )} */}
      {invoice && singular && (
        <Modal
          zIndex={1}
          customStyle={{ position: "fixed" }}
          close={() => {
            setInvoice(false);
            dispatch(singularAppointmentReset());
          }}
        >
          <GenerateInvoice data={singular} fetchAppointments={fetchAppointments} />
        </Modal>
      )}
      {pendings.length === 0 && (
        <p className="info">
          <Icon name="info" />
          <span>
            <Translate>No pending history found</Translate>
          </span>
        </p>
      )}
    </>
  );
}

export default PendingHistory;
