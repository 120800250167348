import AsyncLocalStorage from "@createnextapp/async-local-storage";

export function authenticationHasErrored(state = false, action) {
  switch (action.type) {
    case "AUTHENTICATION_HAS_ERRORED":
      return action.hasErrored;
    default:
      return state;
  }
}
export function authenticationIsLoading(state = false, action) {
  switch (action.type) {
    case "AUTHENTICATION_IS_LOADING":
      return action.isLoading;
    default:
      return state;
  }
}
export function authentication(state = [], action) {
  switch (action.type) {
    case "AUTHENTICATION_LOGIN_SUCCESS":
      localStorage.setItem("token", "Token " + action.authentication);
      return action.authentication;
    case "AUTHENTICATION_LOGOUT_SUCCESS":
      window.history.pushState({}, document.title, "/");
      localStorage.removeItem("token");
      window.location.reload();
      try {
        AsyncLocalStorage.clearStorage();
      } catch (e) {
        console.log(e);
      }
      return state;
    default:
      return state;
  }
}
