import React, { useEffect, useState } from "react";
import "./index.scss";

import { Button, Pagination, TextArea } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

import { useDispatch, useSelector } from "react-redux";
import { customerGetData, customerPostData } from "../../store/actions/customerNotes";

function CustomerNotes({ customer_id }) {
  const dispatch = useDispatch();
  const customerNotes = useSelector((state) => state.customerNotes);
  const [note, setNote] = useState("");

  useEffect(() => {
    dispatch(customerGetData(customer_id));
    setNote("");
  }, [dispatch, customer_id]);

  return (
    <div className="customer-notes">
      <div className="add-note">
        <TextArea
          placeholder="start typing here(maximum 1000 characters)..."
          style={{ minHeight: 50, width: "100%",borderRadius: "4px" }}
          maxLength="1000"
          className="note-area"
          value={note}
          onChange={(e) => {
            setNote(e.currentTarget.value);
          }}
        />
        <Button
          size="mini"
          className="add-note-btn"
          onClick={() => {
            if (note) {
              dispatch(
                customerPostData(customer_id, {
                  customer: customer_id,
                  note,
                })
              );
              setNote("");
            }
          }}
        >
          <Translate>Add Note</Translate>
        </Button>
      </div>
      <div className="notes-wrapper">
        {customerNotes.data.length ? (
          customerNotes.data.map((note, key) => {
            return (
              <div key={key} className="notes">
                {note.note}
                <span className="note-date">{new Date(note.created_on).toDateString()}</span>
              </div>
            );
          })
        ) : (
          <span>
            <Translate>No notes found. Add one from the form above.</Translate>
          </span>
        )}
      </div>
      <div className="pagination">
        <Pagination
          firstItem={false}
          lastItem={false}
          defaultActivePage={1}
          totalPages={customerNotes.totalPages}
          size="mini"
          onPageChange={(event, data) => {
            dispatch(customerGetData(customer_id, data.activePage));
          }}
        />
      </div>
    </div>
  );
}

export default CustomerNotes;
