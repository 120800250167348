import React from "react";
import "./index.scss";
import { findObjectById } from "../../utilities";
import { Label, Icon } from "semantic-ui-react";

export const LabelGroup = ({ labels, options = [], is_active = true, addLabel = false, showAddLabel = true }) => {
  return (
    <Label.Group
      style={{
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        alignItems: "center",
      }}
    >
      {labels.map((ele, index) => {
        const obj = findObjectById(options, ele);

        if (obj) {
          switch (obj?.properties?.shape) {
            case "rectangle":
              return (
                <Label key={index} as="a" color={obj.properties.color}>
                  {obj.short_name}
                </Label>
              );
            case "oval":
              return (
                <Label key={index} as="a" className="client-label-oval" color={obj.properties.color}>
                  {obj.short_name}
                </Label>
              );
            // case "tilt":
            //   return (
            //     <Label key={index} as="a" className="client-label-parallelogram" color={obj.properties.color}>
            //       <div>{obj.short_name}</div>
            //     </Label>
            //   );
            default:
              return (
                <Label key={index} as="a" color={obj?.properties?.color}>
                  {obj?.short_name}
                </Label>
              );
          }
        } else {
          return <div key={index} />;
        }
      })}

      {showAddLabel && (
        <Label size="small" as="a">
          <Icon style={{ marginLeft: 7 }} name="add" title="add label" />
          {addLabel && "Label"}
        </Label>
      )}

      {!is_active && (
        <Label as="a" color="red">
          Deleted Customer
        </Label>
      )}
    </Label.Group>
  );
};
