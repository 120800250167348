import { API_BASE_URL, SELLERS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import lodash from "lodash";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { sellersActions, errorMessage } from "../../utilities/actionMessages";
import { LoadingState } from "../reducers/types";

export function sellersFetchDataSuccess(sellers) {
  return {
    type: "SELLERS_FETCH_DATA_SUCCESS",
    data: { sellers: sellers, loading: LoadingState.loaded },
  };
}

export function sellersFetchDataLoading() {
  return {
    type: "SELLERS_FETCH_DATA_LOADING",
    data: { sellers: [], loading: LoadingState.loading },
  };
}

export function sellersMapCreationSuccess(sellers) {
  const sellersMap = lodash.keyBy(sellers, "id");

  return {
    type: "SELLERS_MAP_CREATION_SUCCESS",
    data: sellersMap,
  };
}

export function sellersFetchData() {
  return (dispatch) => {
    dispatch(startLoading("SELLERS_LOADER", "loading sellers"));
    dispatch(sellersFetchDataLoading());
    axios
      .get(API_BASE_URL + SELLERS_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          dispatch(sellersMapCreationSuccess(response.data.results));
          dispatch(sellersFetchDataSuccess(response.data.results));
          dispatch(endLoading("SELLERS_LOADER"));
        } else {
          dispatch(errored("SELLERS_LOADER", true));
          dispatch(endLoading("SELLERS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SELLERS_LOADER", true));
        dispatch(endLoading("SELLERS_LOADER"));
      });
  };
}

export function sellersPostData(seller) {
  return (dispatch) => {
    dispatch(startLoading("SELLERS_LOADER", "loading sellers"));
    axios({
      method: "post",
      url: API_BASE_URL + SELLERS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: seller,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: sellersActions.success_create, type: "success" });
          dispatch(sellersFetchData());
          dispatch(endLoading("SELLERS_LOADER"));
        } else {
          dispatch(errored("SELLERS_LOADER", true));
          dispatch(endLoading("SELLERS_LOADER"));
        }
      })
      .catch(function (error) {
        const message = errorDetailsFormattor(error);

        ToastAlert({ message: message || errorMessage, type: "error" });

        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SELLERS_LOADER", true));
        dispatch(endLoading("SELLERS_LOADER"));
      });
  };
}

const errorDetailsFormattor = (error) => {
  let message = [];

  if (error?.response?.data) {
    Object.entries(error.response.data).map((err) => {
      message.push(err[0] + " : " + err[1][0]);
    });
  }
  return message.join(",    ");
};

export function sellersEditData(id, seller) {
  return (dispatch) => {
    dispatch(startLoading("SELLERS_LOADER", "loading products"));
    axios({
      method: "put",
      url: API_BASE_URL + SELLERS_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: seller,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.is_active) {
            ToastAlert({ message: sellersActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: sellersActions.success_update, type: "success" });
          }
          dispatch(sellersFetchData());
          dispatch(endLoading("SELLERS_LOADER"));
        } else {
          dispatch(errored("SELLERS_LOADER", true));
          dispatch(endLoading("SELLERS_LOADER"));
        }
      })
      .catch(function (error) {
        const error_message = errorDetailsFormattor(error);

        ToastAlert({ message: error_message || errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SELLERS_LOADER", true));
        dispatch(endLoading("SELLERS_LOADER"));
      });
  };
}
