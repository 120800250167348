import { API_BASE_URL, STAFF_REVENUE_ANALYTICS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

export function staffRevenueAnalyticsFetchDataSuccess(staffRevenueAnalytics) {
  return {
    type: "STAFF_REVENUE_ANALYTICS_FETCH_DATA_SUCCESS",
    staffRevenueAnalytics,
  };
}

export function staffRevenueAnalyticsFetchData(
  start_date = new Date().toISOString().split("T")[0],
  end_date = "",
  branch = ""
) {
  const urlParam = `?start_date=${start_date}&end_date=${end_date}`;
  return (dispatch) => {
    dispatch(startLoading("STAFF_REVENUE_ANALYTICS_LOADER", "loading revenue analytics"));
    axios
      .get(API_BASE_URL + STAFF_REVENUE_ANALYTICS_API + urlParam, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffRevenueAnalyticsFetchDataSuccess(response.data.results));
          dispatch(endLoading("STAFF_REVENUE_ANALYTICS_LOADER"));
        } else {
          dispatch(errored("STAFF_REVENUE_ANALYTICS_LOADER", true));
          dispatch(endLoading("STAFF_REVENUE_ANALYTICS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_REVENUE_ANALYTICS_LOADER", true));
        dispatch(endLoading("STAFF_REVENUE_ANALYTICS_LOADER"));
      });
  };
}
