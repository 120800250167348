import React from "react"

//if partner, change all the "appoitnments" to "bookings"
import {IS_PARTNER} from "../../utilities/constants"
const APPOINTMENT_LABEL = IS_PARTNER ? "Booking" : "Appointment";

function CircleCount({count}){
    if(count>99){
        count="99+"
    }
    return(
        <div style={style} title={`Total ${APPOINTMENT_LABEL}s`}>
            <span style={{fontWeight:"bold"}}>{count}</span>
        </div>
    )
}

const style={
    minWidth:"30px",
    minHeight:"30px",
    background:"#f59842",
    borderRadius:"50%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    padding:"5px 10px",
    cursor:"auto"
}

export default CircleCount