import React, { useState } from "react";
import "./index.scss";

import { Translate } from "react-auto-translate";
import { Icon, Input, Button, Form } from "semantic-ui-react";

import { calcDateDifference } from "../../utilities/CommonFunctions";

const initialDateRangeData = {
  start_date: null,
  end_date: null,
  errorMessage: "",
};

function DateRangeForm({
  heading = "",
  closeIcon = false,
  onClose = () => {},
  onSubmit = () => {},
  minRange = 0,
  maxRange = 0,
}) {
  const [dateRangeFormData, setDateRangeFormData] = useState(initialDateRangeData);
  //

  const validateForm = ({ start_date, end_date }) => {
    const dateDiference = calcDateDifference(start_date, end_date);
    let errorMessage = "";

    if (dateDiference < minRange) {
      errorMessage = "End Date should be greater or equals to Start Date";
    } else if (dateDiference > maxRange) {
      errorMessage = "Date range should not be more than 60 days";
    }

    // handle error
    if (errorMessage.length > 0) {
      setDateRangeFormData({
        ...dateRangeFormData,
        errorMessage,
      });
      return false;
    }

    return true;
  };

  const submitForm = () => {
    const { start_date, end_date } = dateRangeFormData;

    const isDataValid = validateForm({ start_date, end_date });

    if (!isDataValid) {
      return false;
    }

    const data = {
      start_date,
      end_date,
    };

    onSubmit(data);
    setDateRangeFormData(initialDateRangeData);
  };

  const closeForm = () => {
    onClose();
    setDateRangeFormData(initialDateRangeData);
  };

  return (
    <div className="date-range-form" style={{ padding: closeIcon ? "20px 40px" : "10px" }}>
      {closeIcon && <Icon className="close pointer closeIcon" onClick={closeForm} style={{ padding: "10px" }} />}
      {/*  */}
      <h2>
        <Translate>{heading}</Translate>
      </h2>
      <p className="info">
        <Translate>Maximum {maxRange} Days</Translate>
      </p>
      {/* form fields */}
      <div className="input-fields" style={{ marginTop: "40px" }}>
        <Form.Field className="input-field">
          <label>
            <Translate>Start Date*</Translate>
          </label>
          <Input
            className="input"
            type="date"
            placeholder="Start Date"
            value={dateRangeFormData.start_date}
            onChange={(e) => {
              setDateRangeFormData({
                ...dateRangeFormData,
                start_date: e.target.value,
              });
            }}
          />
        </Form.Field>
        <Form.Field className="input-field">
          <label>
            <Translate>End Date*</Translate>
          </label>
          <Input
            className="input"
            type="date"
            placeholder="End Date"
            value={dateRangeFormData.end_date}
            onChange={(e) => {
              setDateRangeFormData({
                ...dateRangeFormData,
                end_date: e.target.value,
              });
            }}
          />
        </Form.Field>
      </div>
      {/* error messge */}
      {dateRangeFormData.errorMessage.length > 0 && (
        <p style={{ color: "red", marginTop: "20px" }}>
          <Translate>{dateRangeFormData.errorMessage}</Translate>
        </p>
      )}
      {/* button */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button disabled={!dateRangeFormData.start_date || !dateRangeFormData.end_date} onClick={submitForm}>
          <Translate>Submit</Translate>
        </Button>
      </div>
    </div>
  );
}

export default DateRangeForm;
