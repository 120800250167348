import { API_BASE_URL, SUBSCRIPTIONS_API } from "utilities";
import axios from "axios";

export function getSubscriptionHasErrored() {
  return {
    type: "GET_SUBSCRIPTION_HAS_ERRORED",
    error: true,
    loading: false,
    data: [],
  };
}

export function getSubscriptionSuccess(data) {
  return {
    type: "GET_SUBSCRIPTION_SUCCESS",
    error: false,
    loading: false,
    data: data,
  };
}

export function getSubscription() {
  return (dispatch) => {
    axios({
      method: "get",
      url: API_BASE_URL + SUBSCRIPTIONS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(getSubscriptionSuccess(response.data.results));
        } else {
          dispatch(getSubscriptionHasErrored());
        }
      })
      .catch(function (error) {
        dispatch(getSubscriptionHasErrored());
      });
  };
}
