const initData = {
  status: false,
  error: "",
  loading: false,
};

export function register(state = initData, action) {
  switch (action.type) {
    case "BRANCH_SUCCESS":
      return { ...state, ...action.response };
    case "BRANCH_HAS_ERRORED":
      return { ...state, ...action.response };
    case "BRANCH_RESET":
      return { ...state, ...action.response };
    default:
      return state;
  }
}
