import { API_BASE_URL, NOTIFICATION_REGISTER } from "utilities";
import axios from "axios";

export function registerFCMToken(token) {
  const payload = {
    token: token,
    type: 2,
  };
  axios
    .post(API_BASE_URL + NOTIFICATION_REGISTER, payload, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      console.log("fcm_token registered");
    })
    .catch(function (error) {
      console.log("err", error);
    });
}
