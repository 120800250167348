const initialState = {
  refresh: false,
};

export const shouldRefreshClientSearchList = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH_CLIENT_SEARCH_LIST":
      return {
        ...state,
        refresh: action.refresh,
      };

    default:
      return state;
  }
};
