import React, { useEffect, useState } from "react";

import "../index.scss";

import { Form as Forms, Icon, Dropdown, Popup, Input, Button } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import { useSelector } from "react-redux";

const PAYMENT_METHOD_STAFF_ADVANCE = [
  { key: 0, text: "Cash", value: "CASH" },
  { key: 1, text: "Credit Card", value: "CREDIT_CARD" },
  { key: 2, text: "Debit Card", value: "DEBIT_CARD" },
  { key: 3, text: "Google Pay", value: "GOOGLE_PAY" },
  { key: 4, text: "Phone Pe", value: "PHONE_PE" },
  { key: 5, text: "Paytm", value: "PAYTM" },
];

const DEFAULT_PAYMENT_METHOD_VALUE = "CASH";

const formFormat = {
  date: null,
  amount_payable: 0,
  amount_paid: 0,
  payment_mode: "",
};

function StaffAdvanceForm({ onSubmit, isDeleted }) {
  const staffAdvance = useSelector((state) => state.staffAdvance);

  const [error, setError] = useState("");
  const [form, setForm] = useState(formFormat);
  const totalAdvance = staffAdvance?.balance || 0;

  useEffect(() => {
    setError(false);
    setForm(formFormat);
  }, []);

  const handleAddButton = () => {
    if (!form["amount_payable"] && !form["amount_paid"]) {
      setError("Either Staff Advance or Branch Advance field must be filled.");
      return;
    } else if (!form["date"] || !form["payment_mode"]) {
      setError("Please fill all the required fields.");
      return;
    }

    setError("");
    onSubmit(form);
    setForm({ ...formFormat });
  };

  const handleTotalAdvanceReset = () => {
    const today = new Date().toISOString().slice(0, 10); // yyyy-mm-dd
    let resteForm = {};

    if (totalAdvance > 0) {
      resteForm = {
        amount_paid: totalAdvance,
        amount_payable: 0,
      };
    } else if (totalAdvance < 0) {
      resteForm = {
        amount_payable: -1 * totalAdvance,
        amount_paid: 0,
      };
    }

    setForm({
      ...form,
      ...resteForm,
      date: today,
      payment_mode: DEFAULT_PAYMENT_METHOD_VALUE,
    });
  };

  return (
    <div className="staff-advance-form">
      <h3 style={{ marginBottom: "20px" }}>
        <Translate>Add Staff Advance:</Translate>
      </h3>
      <Forms>
        <Forms.Field>
          <label>
            <Translate>Staff Advance</Translate>
          </label>
          <input
            type="number"
            maxLength={20}
            placeholder="0"
            onChange={(e) =>
              setForm({
                ...form,
                amount_payable: parseInt(e.currentTarget.value) || 0,
              })
            }
            value={form.amount_payable || ""}
          />
        </Forms.Field>
        <Forms.Field>
          <label>
            <Translate>Branch Advance / Staff Returned</Translate>
            {"  "}
            <Popup
              content="Branch borrowed money from staff or Staff returned advance"
              trigger={<Icon name="info circle" />}
            />
          </label>
          <input
            type="number"
            maxLength={20}
            placeholder="0"
            onChange={(e) => setForm({ ...form, amount_paid: parseInt(e.currentTarget.value) || 0 })}
            value={form.amount_paid || ""}
          />
        </Forms.Field>
        <Forms.Field>
          <label>
            <Translate>Payment Mode*</Translate>
          </label>
          <Dropdown
            className="method-dropdown"
            placeholder="Mode"
            selection
            search
            value={form.payment_mode}
            options={PAYMENT_METHOD_STAFF_ADVANCE}
            onChange={(_, data) => setForm({ ...form, payment_mode: data.value })}
          />
        </Forms.Field>
        <Forms.Field>
          <label>
            <Translate>Date*</Translate>
          </label>
          <input
            type="date"
            onChange={(e) => setForm({ ...form, date: e.currentTarget.value })}
            value={form.date || ""}
          />
        </Forms.Field>
        <Forms.Field>
          <label>
            <Translate>Total Advance</Translate>
          </label>
          <Input
            type="text"
            maxLength={20}
            value={totalAdvance + form.amount_payable - form.amount_paid || 0}
            labelPosition="right"
          >
            <input disabled />
            <Button className="staff-advance-reset-button" onClick={handleTotalAdvanceReset}>
              Reset
            </Button>
          </Input>
        </Forms.Field>
      </Forms>
      {error.length > 0 && (
        <span className="error">
          <Translate>{error}</Translate>
        </span>
      )}
      <div className="actions">
        <button className="ui button" onClick={handleAddButton} disabled={isDeleted}>
          <Translate>Add</Translate>
        </button>
      </div>
    </div>
  );
}

export default StaffAdvanceForm;
