const defaultState = {
  data: {
    results: [],
    current_page: 0,
    pages: 0,
  },
  scheduled: {
    results: [],
    current_page: 0,
    pages: 0,
  },
};

export function bulkPromotionView(state = defaultState, action) {
  switch (action.type) {
    case "BULK_PROMOTION_VIEW_FETCH_DATA_SUCCESS":
      return {
        ...state,
        data: action.data,
      };
    case "SCHEDULED_BULK_PROMOTION_VIEW_FETCH_DATA_SUCCESS":
      return {
        ...state,
        scheduled: action.data,
      };
    case "BULK_PROMOTION_VIEW_FETCH_DATA_LOADING":
      return action.bulkPromotionView;
    case "BULK_PROMOTION_VIEW_FETCH_DATA_ERROR":
      return action.bulkPromotionView;

    default:
      return state;
  }
}
