import { API_BASE_URL, SERVICES_API, LABELS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import _ from "lodash";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { serviceActions, errorMessage, serviceLabelsActions } from "../../utilities/actionMessages";
import { LoadingState } from "../reducers/types";

export function servicesFetchDataLoading() {
  return {
    type: "SERVICES_FETCH_DATA_LOADING",
    data: { data: [], loading: LoadingState.loading },
  };
}

export function servicesFetchDataSuccess(services) {
  return {
    type: "SERVICES_FETCH_DATA_SUCCESS",
    data: { data: services, loading: LoadingState.loaded },
  };
}

export function servicesMapCreationSuccess(services) {
  // for better searching the service data by service id
  const servicesMap = _.keyBy(services, "id");

  return {
    type: "SERVICES_MAP_CREATION_SUCCESS",
    servicesMap,
  };
}

export function servicesFetchData() {
  return (dispatch) => {
    dispatch(startLoading("SERVICES_LOADER", "loading services"));
    dispatch(servicesFetchDataLoading());
    axios
      .get(API_BASE_URL + SERVICES_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(servicesFetchDataSuccess(response.data.results));
          dispatch(servicesMapCreationSuccess(response.data.results));
          dispatch(endLoading("SERVICES_LOADER"));
        } else {
          dispatch(errored("SERVICES_LOADER", true));
          dispatch(endLoading("SERVICES_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SERVICES_LOADER", true));
        dispatch(endLoading("SERVICES_LOADER"));
      });
  };
}

export function servicesPostData(service) {
  return (dispatch) => {
    dispatch(startLoading("SERVICES_LOADER", "loading services"));
    axios({
      method: "post",
      url: API_BASE_URL + SERVICES_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: service,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: serviceActions.success_create, type: "success" });
          dispatch(servicesFetchData());
          dispatch(endLoading("SERVICES_LOADER"));
        } else {
          dispatch(errored("SERVICES_LOADER", true));
          dispatch(endLoading("SERVICES_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SERVICES_LOADER", true));
        dispatch(endLoading("SERVICES_LOADER"));
      });
  };
}

export function servicesPutData(id, service) {
  return (dispatch) => {
    dispatch(startLoading("SERVICES_LOADER", "loading services"));
    axios({
      method: "put",
      url: API_BASE_URL + SERVICES_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: service,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.status) {
            ToastAlert({ message: serviceActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: serviceActions.success_update, type: "success" });
          }
          dispatch(servicesFetchData());
          dispatch(endLoading("SERVICES_LOADER"));
        } else {
          dispatch(errored("SERVICES_LOADER", true));
          dispatch(endLoading("SERVICES_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("SERVICES_LOADER", true));
        dispatch(endLoading("SERVICES_LOADER"));
      });
  };
}

//
export const postServiceLabels = (payload) => {
  let URL = API_BASE_URL + LABELS_API + "service/";

  return (dispatch) => {
    axios
      .post(URL, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        ToastAlert({ message: serviceLabelsActions.success_update, type: "success" });
        dispatch(servicesFetchData());
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
      });
  };
};
