import React, { useEffect, useCallback } from "react";

function Paytm({ params, closed }) {
  const invokePaytm = useCallback(() => {
    const { order_id, cost, token } = params;

    const config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId: order_id,
        token: token,
        tokenType: "TXN_TOKEN",
        amount: cost,
      },
      handler: {
        notifyMerchant: function (eventName, data) {
          // console.log("notifyMerchant handler function called");
          // console.log("eventName => ",eventName);
          // console.log("data => ",data);
          if (eventName === "APP_CLOSED") {
            closed();
          }
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            // after successfully update configuration invoke checkoutjs
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            // console.log("error => ",error);
          });
      });
    }
  }, [params, closed]);

  useEffect(() => {
    const { txnJsUrl } = params;
    const script = document.createElement("script");
    // console.log(txnJsUrl);
    script.src = txnJsUrl;
    document.body.appendChild(script);
    script.onload = () => {
      invokePaytm();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [params, invokePaytm]);

  return <div />;
}

export default Paytm;
