import React from "react";
import "./index.scss";
import { Icon } from "semantic-ui-react";
import closeIcon from '../../cdn/Group 3400.svg';

function Modal({ close, children, zIndex = 0, customStyle = {}, insideStyle = {} }) {
  const style = {
    ...customStyle,
    zIndex: 999 + zIndex,
  };
  return (
    <div
      id="modal-ui"
      className="custom-modal absolute width-100 height-100 flex"
      onClick={() => close && close()}
      style={style}
    >
      <div className="content custom-scroll" style={{ ...insideStyle }} onClick={(e) => e.stopPropagation()}>
        {close && 
        // <img src={closeIcon} className="close pointer" onClick={()=>close()}/>
        <Icon size="20" className="close pointer" onClick={() => close()} />
        }
        {children}
      </div>
    </div>
  );
}

export default Modal;
