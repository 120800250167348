import { API_BASE_URL, BRANCH_WALLET_API, BRANCH_EXPENSE_AGGREGATE_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { expenseActions, errorMessage, walletLabelsActions } from "../../utilities/actionMessages";

const defaultState = {
  loading: false,
  data: [],
  error: false,
  weeklyData: [],
};

export function branchWalletFetchDataSuccess(branchWallet) {
  return {
    type: "BRANCH_WALLET_FETCH_DATA_SUCCESS",
    branchWallet,
  };
}

function branchWalletAggregatedDataSuccess(raw) {
  const data = {
    today: {
      credit: raw.today.credits.amount__sum || 0,
      debit: raw.today.debits.amount__sum || 0,
    },
    monthly: {
      credit: raw.month.credits.amount__sum || 0,
      debit: raw.month.debits.amount__sum || 0,
    },
    selected: {
      credit: raw.selected.credits.amount__sum || 0,
      debit: raw.selected.debits.amount__sum || 0,
    },
  };
  return {
    type: "BRANCH_WALLET_AGGREGATED_DATA",
    data,
  };
}

export function branchWalletAggregatedData(start_date=null, end_date=null) {
  return (dispatch) => {
    let URL = API_BASE_URL + BRANCH_EXPENSE_AGGREGATE_API;

    if (start_date && end_date) {
      URL += `?start_date=${start_date}&end_date=${end_date}`;
    }

    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(branchWalletAggregatedDataSuccess(response.data));
        } else {
          ToastAlert({ message: walletLabelsActions.expense_aggregate_fail, type: "error" });
        }
      })
      .catch(function (error) {
        if (error && error?.response?.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BRANCH_WALLET_LOADER", true));
      });
  };
}

export function branchWalletFetchData({start_date = null, end_date = null, search = "", pageNum = 1, orderBy = ''}) {
  return (dispatch) => {
    dispatch(startLoading("BRANCH_WALLET_LOADER", "loading branchWallet"));
    dispatch(branchWalletAggregatedData(start_date, end_date));
    let url = API_BASE_URL + BRANCH_WALLET_API;
    if (start_date && end_date) {
      url += `?start_date=${start_date}&end_date=${end_date}`;
    } else if (start_date) {
      url += `?start_date=${start_date}`;
    }
    url += `&search=${search}&page=${pageNum}`;
    if (orderBy) {
      url += `&order_by=${orderBy}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(branchWalletFetchDataSuccess(response.data));
          dispatch(endLoading("BRANCH_WALLET_LOADER"));
        } else {
          dispatch(errored("BRANCH_WALLET_LOADER", true));
          dispatch(endLoading("BRANCH_WALLET_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BRANCH_WALLET_LOADER", true));
        dispatch(endLoading("BRANCH_WALLET_LOADER"));
      });
  };
}

export function branchWalletPostData(branchWallet, start_date, end_date) {
  return (dispatch) => {
    dispatch(startLoading("BRANCH_WALLET_LOADER", "loading wallet"));
    axios({
      method: "post",
      url: API_BASE_URL + BRANCH_WALLET_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: branchWallet,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: expenseActions.success_create, type: "success" });
          dispatch(branchWalletFetchData({start_date, end_date}));
          dispatch(endLoading("BRANCH_WALLET_LOADER"));
        } else {
          dispatch(errored("BRANCH_WALLET_LOADER", true));
          dispatch(endLoading("BRANCH_WALLET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BRANCH_WALLET_LOADER", true));
        dispatch(endLoading("BRANCH_WALLET_LOADER"));
      });
  };
}

export function branchWalletPutData(id, transaction, start_date, end_date) {
  return (dispatch) => {
    dispatch(startLoading("BRANCH_WALLET_LOADER", "loading wallet"));
    axios({
      method: "put",
      url: API_BASE_URL + BRANCH_WALLET_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: transaction,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.active) {
            ToastAlert({ message: expenseActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: expenseActions.success_update, type: "success" });
          }
          dispatch(branchWalletFetchData({start_date, end_date}));
          dispatch(endLoading("BRANCH_WALLET_LOADER"));
        } else {
          dispatch(errored("BRANCH_WALLET_LOADER", true));
          dispatch(endLoading("BRANCH_WALLET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BRANCH_WALLET_LOADER", true));
        dispatch(endLoading("BRANCH_WALLET_LOADER"));
      });
  };
}
