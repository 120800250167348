import React, { useEffect, useState } from "react";
import "./index.scss";
import Modal from "Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  customerWalletBalanceFetch,
  customerWalletFetchData,
  customerWalletPostData,
  getcustomerWalletInvoice,
} from "../../store/actions/customerWallet";
import { customersFetchData } from "../../store/actions/customer";

import { PAYMENT_METHOD } from "../../utilities";
import { customerWalletPostDataApi } from "../../api/customerWalletPostDataApi";

import CompletionOtpForm from "../../components/CompletionOtpForm";
import HoverTable from "HoverTable";
import Description from "Description";
import { Button, Form as Forms, Icon, Dropdown } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

// const tableHeadersServices = ["Payable", "Paid", "Pending", "Total ", "Payment Mode", "Date", "Invoice"];
const tableHeadersServices = ["Consumed", "Added", "Balance", "Total Balance ", "Payment Mode", "Date", "Invoice"];

const renderSpecialServices = [
  ({ amount_payable }) => {
    return <Description title={amount_payable} />;
  },
  ({ amount_paid }) => {
    return <Description title={amount_paid} />;
  },
  ({ amount_payable, amount_paid }) => {
    return <Description title={-(amount_payable - amount_paid)} />;
  },
  ({ balance}) => {
    return <Description title={-balance} />;
  },
  ({ payment_mode = "" }) => {
    return <Description title={payment_mode} />;
  },
  ({ date }) => {
    return <Description title={date} />;
  },
  ({ id, amount_payable, amount_paid }, edit) => {
    // if (amount_payable === 0 && amount_paid)
    return (
      <Icon
        disabled={sessionStorage.getItem("subscription_valid_status") < 0}
        className="download"
        onClick={() => edit(id)}
      />
    );
    return <Icon className="dont" />;
  },
];

const walletDataFormat = {
  id: null,
  customer: null,
  date: null,
  amount_payable: 0,
  amount_paid: 0,
  payment_mode: "",
  status: null,
  balance:0
};


function CustomerWallet({ customer, payable, appliedCustomerFilters = {} }) {
  const dispatch = useDispatch();

  const transactions = useSelector((state) => state.customerWallet);
  const invoice = useSelector((state) => state.customerWalletInvoice);
  const { data: branchData } = useSelector((state) => state.branch);

  const [error, setError] = useState(false);
  const [walletData, setWalletData] = useState(walletDataFormat);
  const [totalPending, setTotalPending] = useState(0);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [balance, setFinalBalance] = useState(0);
  const { wallet_otp } = branchData;

  useEffect(() => {
    setError(false);
    setTotalPending(transactions?.totalPending);
    setFinalBalance(transactions?.results);
    setWalletData(walletDataFormat);
  }, [transactions]);

  useEffect(() => {
    dispatch(customerWalletFetchData(customer.id));
    dispatch(customerWalletBalanceFetch({ customer_id: customer.id }));
  }, [dispatch, customer.id]);

  useEffect(() => {
    payable && setWalletData({ ...walletDataFormat, amount_payable: payable});
  }, [payable]);

  const addNewWallet = async ({ otp = "" }) => {
    delete walletData["id"];
    delete walletData["status"];
    walletData.customer = customer.id;

    if (!walletData["amount_payable"]) {
      walletData["amount_payable"] = 0;
    }
    if (!walletData["amount_paid"]) {
      walletData["amount_paid"] = 0;
    }

    const data = {
      ...walletData,
      ...(wallet_otp ? { customer_otp: otp } : {}),
    };

    const { status } = await customerWalletPostDataApi({ data });

    if (status === "success") {
      if (wallet_otp) {
        setShowOtpModal(false);
      }

      dispatch(customerWalletFetchData(customer.id));

      // to refresh the client list
      if (Object.keys(appliedCustomerFilters).length > 0) {
        const { search, clientFilter, currentPage } = appliedCustomerFilters;
        dispatch(customersFetchData(search, clientFilter, currentPage));
      }
    }
  };

  const handleAddButton = () => {
    if ((walletData["amount_payable"] || walletData["amount_paid"]) && walletData["date"]) {
      const subscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

      if (wallet_otp && !subscriptionExpired) {
        setShowOtpModal(true);
      } else {
        addNewWallet({});
      }

      setError(false);
    } else {
      setError(true);
    }
  };

  const handleOTPFormSubmit = ({ clickedButton = 0, data }) => {
    const { otp } = data;
    addNewWallet({ otp });
  };

  return (
    <div className="customer-wallet width-100">
      {invoice.length > 0 && (
        <Modal close={() => dispatch({ type: "CUSTOMER_WALLET_INVOICE_RESET" })} customStyle={{ position: "fixed" }}>
          <div className="modal-container">
            <Button className="pr-btn" onClick={() => window.print()}>
              <Translate>Print</Translate> <Icon className="print" />
            </Button>
            <div
              id="section-to-print"
              style={{ minHeight: "585px", overflow: "auto" }}
              className="print-invoice thermal"
              dangerouslySetInnerHTML={{ __html: invoice }}
            />
          </div>
        </Modal>
      )}
      {showOtpModal && (
        <Modal close={() => setShowOtpModal(false)}>
          <CompletionOtpForm
            customer_id={customer.id}
            otp_type="WALLET"
            primaryButtonText="Add"
            onComplete={(res) => handleOTPFormSubmit(res)}
            close={() => {
              setShowOtpModal(false);
            }}
          />
        </Modal>
      )}
      <div className="wallet-list-table">
        <HoverTable
          header={tableHeadersServices}
          showDeleted={false}
          src={transactions}
          renderSpecial={renderSpecialServices}
          edit={(id) => dispatch(getcustomerWalletInvoice(id))}
          remove={() => {}}
          toPaginate={true}
          changePage={(pageNum) => dispatch(customerWalletFetchData(customer.id, pageNum))}
        />
      </div>
      <div className="wallet-form">
        <h3 style={{ marginBottom: "20px" }}>
          <Translate>Add Customer Payment:</Translate>
        </h3>
        <Forms>
          <Forms.Field>
            <label>
              <Translate>Amount To Consume</Translate>
              {payable && (
                <span className="description">
                  <Translate>(Selected Invoice Amount)</Translate>
                </span>
              )}
              *
            </label>
            <input
              type="number"
              maxLength={20}
              placeholder="0"
              onChange={(e) =>
                setWalletData({
                  ...walletData,
                  amount_payable: parseInt(e.currentTarget.value) || 0,
                })
              }
              value={walletData.amount_payable || ""}
            />
          </Forms.Field>
          <Forms.Field>
            <label>
              <Translate>Amount To Add*</Translate>
            </label>
            <input
              type="number"
              maxLength={20}
              placeholder="0"
              onChange={(e) => setWalletData({ ...walletData, amount_paid: parseInt(e.currentTarget.value) || 0 })}
              value={walletData.amount_paid || ""}
            />
          </Forms.Field>
          <Forms.Field>
            <label>
              <Translate>Payment Mode</Translate>
            </label>
            <Dropdown
              className="method-dropdown"
              placeholder="Mode"
              selection
              search
              value={walletData.payment_mode}
              options={PAYMENT_METHOD}
              onChange={(_, data) => setWalletData({ ...walletData, payment_mode: data.value })}
            />
          </Forms.Field>
          <Forms.Field>
            <label>
              <Translate>Date*</Translate>
            </label>
            <input
              type="date"
              onChange={(e) => setWalletData({ ...walletData, date: e.currentTarget.value })}
              value={walletData.date || ""}
            />
          </Forms.Field>
          <Forms.Field>
            <label>
              <Translate>Total Balance</Translate>
            </label>
            <input
              type="text"
              maxLength={20}
              disabled={true}
              value={-(totalPending + walletData.amount_payable - walletData.amount_paid) || 0}
            />
          </Forms.Field>
        </Forms>
        {error && (
          <span className="error">
            <Translate>Please enter all the required field.</Translate>
          </span>
        )}
        <div className="actions">
          <button className="ui button" onClick={handleAddButton}>
            <Translate>Add</Translate>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomerWallet;
