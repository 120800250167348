const initialState = {
  data: [],
  totalPages: 1,
  currentPage: 1,
  getLoading: false,
  getError: false,
  putLoading: false,
  putError: false,
  postLoading: false,
  postError: false,
};

export function customerNotes(state = initialState, action) {
  switch (action.type) {
    case "CUSTOMER_GET_DATA_SUCCESS":
      return {
        ...initialState,
        data: action.data.results,
        totalPages: action.data.pages || 1,
        currentPage: action.data.current_page || 1,
      };
    case "CUSTOMER_GET_DATA_ERROR":
      return {
        ...initialState,
        getError: true,
      };
    default:
      return state;
  }
}
