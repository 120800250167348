import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { TIME_UNIT_OPTIONS } from "../../utilities/constants";
import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

export const SpecificServiceAndProductDiscount = (props) => {
  const { type, formik, onChangeFunction, dropdownOption, eachObjData, index, dropdownValue, helpTextLabel } = props;
  const {
    allotted_count,
    duration_in_minutes,
    duration_type,
    discount_type,
    discount_type_value: service_discount_type_value,
  } = eachObjData;
  const label = type.slice(0, -1);

  const TIME_UNIT_OPTIONS_TRANSLATED = TIME_UNIT_OPTIONS.map((option) => {
    const { key, text, value } = option;
    return {
      key,
      value,
      text: <Translate>{text}</Translate>,
    };
  });

  return (
    <Form.Group style={{ display: "flex", flexDirection: "column" }} widths="equal">
      <div className="specific-services">
        <Dropdown
          id={type}
          placeholder={label}
          fluid
          label={label}
          // disabled={!formik.values.specific_services_discount}
          search
          value={dropdownValue}
          selection
          onChange={(e, { options, value }) => {
            onChangeFunction(index, label, options[value].org_value, type);
          }}
          options={dropdownOption}
          error={!!formik.errors[type]?.[index]?.[label]}
          forceSelection={false}
          selectOnBlur={false}
        />
      </div>
      <div className="specific-services-inputs">
        <div style={{ flex: 1 }}>
          <Form.Input
            id={"allotted_count"}
            fluid
            label={helpTextLabel(
              label + " " + `Count*`,
              "Maximum times a discount can be availed for this particular service."
            )}
            placeholder="Count"
            type="number"
            value={allotted_count}
            onChange={(e) => onChangeFunction(index, "allotted_count", e.target.value, type)}
            error={formik.errors[type]?.[index]?.allotted_count}
          />
        </div>
        <div style={{ flex: 1, display: type === "products" ? "none" : "" }}>
          <Form.Input
            id={"duration_in_minutes"}
            fluid
            label={helpTextLabel(
              "Duration*",
              "Maximum duration a discount can be availed for this particular service in minutes."
            )}
            placeholder="Duration"
            type="number"
            value={duration_in_minutes}
            onChange={(e) => onChangeFunction(index, "duration_in_minutes", e.target.value, type)}
            error={formik.errors[type]?.[index]?.duration_in_minutes}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            display: type === "products" ? "none" : "",
          }}
        >
          <b>
            <Translate>Duration Type</Translate>
          </b>
          <Dropdown
            id={"duration_type"}
            placeholder="Duration Type"
            fluid
            label={translateFormLabel("Duration Type*")}
            text={duration_type || "MIN"}
            value={duration_type || "MIN"}
            selection
            onChange={(e, data) => {
              onChangeFunction(index, "duration_type", data.value, type);
            }}
            options={TIME_UNIT_OPTIONS_TRANSLATED}
            error={formik.errors[type]?.[index]?.duration_type}
            forceSelection={false}
            selectOnBlur={false}
          />
        </div>
      </div>
      <div className="special-services-discount">
        <Form.Field className="grouped-radio">
          <label>
            <Translate>{`${label} Discount Type`}</Translate>
          </label>
          <div className="flex">
            <Form.Radio
              label={translateFormLabel("Percentage")}
              value="sm"
              checked={discount_type === 0}
              onChange={() => onChangeFunction(index, "discount_type", 0, type)}
            />
            <Form.Radio
              label={translateFormLabel("Value")}
              value="md"
              checked={discount_type === 1}
              onChange={() => onChangeFunction(index, "discount_type", 1, type)}
            />
          </div>
        </Form.Field>
        <Form.Input
          id={"discount_type_value"}
          fluid
          style={{ width: "90px" }}
          label={translateFormLabel(`${label} Discount*  ${["%", "/-"][discount_type]}`)}
          placeholder={`Discount ${["%", "/-"][discount_type]}`}
          type="number"
          value={service_discount_type_value}
          onChange={(e) => onChangeFunction(index, "discount_type_value", e.target.value, type)}
          error={formik.errors[type]?.[index]?.discount_type_value}
        />
      </div>
    </Form.Group>
  );
};
