import React from "react";
import { useSelector } from "react-redux";
import { Divider, Icon } from "semantic-ui-react";
import { promotionalMessageString } from "../../utilities/CommonFunctions";
import PromotionActions from "./Actions";
import SMS from "./SMS";
import { getUTCTime } from "../../utilities/dateTime";
import { Translate } from "react-auto-translate";
import _ from "lodash";
import { KALIMZ_BRANCH_ID, COMMON_KALIMZ_MAP } from "../../utilities/constants";

let INPUT_COUNT = [];
const ALLOWED_MAX_FILE_SIZE = 1048576;
const ALLOWED_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xls",
  "application/xlsx",
  "text/csv",
];

export default function SMSPromotion({
  isBulk = false,
  data,
  update,
  clientFilter,
  send,
  show,
  selectedClients,
  labelFilter,
}) {
  const branch = useSelector((state) => state.branch.data);
  const branchShortName = useSelector((state) => state.branchShortName.data);

  let branchName = branch?.name || "";
  const branchId = branch?.id || "";
  const IS_KALIMZ = branchId == KALIMZ_BRANCH_ID;

  if (branchShortName.status && branchShortName.approval === 1) {
    branchName = branchShortName.short_name;
  }

  const templateString =
    data.selectedTemplate !== null
      ? promotionalSMSTemplate(null, branchName, data.selectedTemplate, null, branchId)
      : "";

  const promotionalMessageFilled = promotionalMessageString(templateString, data.templateValues);

  const validate = () => {
    let valid = true;
    const { selectedTemplate, templateValues } = data;
    const newSelectedTemplate = IS_KALIMZ ? COMMON_KALIMZ_MAP[selectedTemplate] : selectedTemplate;

    const conditionCheckFunction = (arr) => {
      const check = arr.map((key) => Object.keys(templateValues).includes(key));
      if (check.includes(false) || _.some(templateValues, _.isEmpty)) {
        update({ ...data, error: "Please enter all the corresponding values" });
        valid = false;
        return;
      } else {
        update({ ...data, error: "" });
        valid = true;
      }
    };

    //  const findFieldLength = _.find(INPUT_COUNT, (arr)=>arr[selectedTemplate]);

    if (["0", "2", "4", "6"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["1", "2"]);
      if (newSelectedTemplate == "6") {
        if (templateValues[1].length !== 10 || templateValues[1].length > 10) {
          update({ ...data, error: "Please enter a correct mobile number" });
          valid = false;
        }
      }
    }
    if (["1", "3", "5", "7", "11", "12"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["1", "2", "3"]);
    }
    if (["9", "10"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["1", "2", "3", "4", "5", "6", "7", "8", "9"]);
    }
    if (["8"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["1", "2", "3", "4", "5"]);
    }
    if (["13"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["1", "2", "3", "4", "5", "6"]);
    }
    if (["16", "17"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["2"]);
    }
    if (["15"].includes(newSelectedTemplate)) {
      conditionCheckFunction(["1"]);
    }
    // if (["6"].includes(selectedTemplate)) {
    //   if (!templateValues[2] || !templateValues[3]) {
    //     update({ ...data, error: "Please enter all the corresponding values" });
    //     valid = false;
    //   }
    // }

    if (!templateValues || newSelectedTemplate === null) {
      update({ ...data, error: "Please select a template and input the corresponding values" });
      valid = false;
    }
    // condition for bulk
    if (isBulk && !data.file) {
      update({ ...data, error: "Please select & upload bulk contact excel file" });
      valid = false;
    }

    // conditions for scheduled promotion
    const { scheduleDetails } = data;
    if (scheduleDetails.isActive) {
      if (!scheduleDetails.time) {
        valid = false;
        update({ ...data, error: "Ensure Schedule Time is entered" });
      }

      switch (scheduleDetails.scheduleType) {
        case 0: {
          if (scheduleDetails.day || scheduleDetails.singleDate) {
            valid = false;
            update({ ...data, error: "Schedule Day or Schedule Date should not be entered for daily promotion " });
          }
          break;
        }

        case 1: {
          if (scheduleDetails.day === "") {
            valid = false;
            update({ ...data, error: "Schedule week day should be selected for weekly promotion" });
          }
          break;
        }

        case 2: {
          if (!scheduleDetails.singleDate) {
            valid = false;
            update({ ...data, error: "Schedule date of month should be selected for monthly promotion" });
          }
          break;
        }
      }
    }

    // because dont require the client data if it is bulk promotion
    if (!isBulk && !selectedClients.allClientsSelected && !selectedClients.excluded_clients) {
      if (selectedClients.selected_clients && !selectedClients.selected_clients.ids?.length) {
        update({ ...data, error: "No Client is selected" });
        valid = false;
      }
    }

    if (valid) {
      const payload = {
        message: null,
        image: null,
        client_filter_id: null,
        send_to_all: !!(selectedClients.allClientsSelected || selectedClients.excluded_clients),
        file: data?.file || null,
        template: selectedTemplate,
        value: templateValues,
        excluded_customer_ids: selectedClients.excluded_clients ? selectedClients.excluded_clients?.ids : null,
        customer_ids: selectedClients.selected_clients ? selectedClients.selected_clients?.ids : null,
        call_back_number: null,
        label_ids: null,
      };

      if (!selectedClients.selected_clients && (clientFilter?.key || clientFilter?.value)) {
        payload.client_filter_id = clientFilter?.key || clientFilter?.value;
        // if filter_id is populated, we're sending promotion to everyone in that filter_id
      }

      if (!selectedClients.selected_clients && labelFilter.length) {
        payload.label_ids = labelFilter;
      }

      if (scheduleDetails.isActive) {
        payload.message = promotionalMessageFilled;

        payload["type"] = 1; // 1 being sms
        payload["recurring_frequency"] = 1;
        payload["schedule_time"] = null;
        payload["schedule_day"] = null;
        payload["schedule_day_number"] = null;
        if (scheduleDetails.day !== "") {
          // weekly
          payload["recurring_frequency"] = 1;
          payload["schedule_day"] = scheduleDetails.day;
        } else if (scheduleDetails.singleDate) {
          // monthly
          payload["recurring_frequency"] = 2;
          payload["schedule_day_number"] = scheduleDetails.singleDate;
        } else if (scheduleDetails.time) {
          // daily
          payload["recurring_frequency"] = 0;
        }
        // convert the following time to utc
        payload["schedule_time"] = getUTCTime(scheduleDetails.time);
      } else {
        payload["promotion_type"] = "SMS";
      }

      if (isBulk) {
        payload.send_to_all = false;
        payload.client_filter_id = null;
        payload.label_ids = null;
        payload.customer_ids = null;
        payload.excluded_customer_ids = null;
      }
      send(payload);
    }
  };

  const selectContactFile = (ev) => {
    if (ev) {
      const { name, size, type } = ev.target.files[0];
      if (size > ALLOWED_MAX_FILE_SIZE || !ALLOWED_FILE_TYPES.includes(type)) {
        update({ ...data, error: "Please check file type and size" });
      } else {
        const parts = name.split(".");
        const extension = parts[parts.length - 1];
        const file = ev.target.files[0];
        file["key"] = new Date().valueOf().toString() + "." + extension;
        update({ ...data, file, error: null });
      }
    } else {
      update({ ...data, file: null, error: null });
    }
  };

  if (!show) return null;

  return (
    <>
      <div className="sms-promotion-container">
        {isBulk && (
          <>
            <h4>
              <Translate>
                Upload contacts in excel/csv with maximum size of {parseInt(ALLOWED_MAX_FILE_SIZE / 1000000)}{" "}
                Mb(download format
              </Translate>{" "}
              &nbsp;&nbsp;{" "}
              <a href="https://s3.ap-south-1.amazonaws.com/app.downloadables/contacts_sample.xlsx" download>
                <Translate>here</Translate>
              </a>
              )
            </h4>
            <div className="flex column width-100" style={{ padding: "10px", justifyContent: "center" }}>
              <label>
                <Translate>Select an excel file:</Translate>
              </label>
              {data.file ? (
                <span>
                  {data.file.name} <Icon className="cancel pointer" onClick={() => selectContactFile()} />
                </span>
              ) : (
                <input type="file" onChange={(e) => selectContactFile(e)} />
              )}
            </div>
            {data?.error && <p style={{ color: "orange", fontSize: 12 }}>{data?.error}</p>}
            <Divider />
          </>
        )}

        <span>
          <Translate>Select SMS template to send</Translate>
        </span>
        <SMS
          PROMOTIONAL_SMS={promotionalSMSTemplate(data.templateValues, branchName, null, null, branchId)}
          selectTemplate={(key) => {
            update({ ...data, selectedTemplate: key, templateValues: { 0: "branch" } });
          }}
          selectedTemplate={data.selectedTemplate}
          updateSMSField={(num, value) => {
            if (num === -1) {
              update({ ...data, templateValues: { 0: "branch" } });
            } else {
              update({ ...data, templateValues: { ...data.templateValues, [num]: value } });
            }
          }}
          templateValues={data.templateValues}
        />
        <PromotionActions
          isBulk={isBulk}
          promotionalMessageFilled={promotionalMessageFilled}
          selected={selectedClients}
          clientFilter={clientFilter}
          labelFilter={labelFilter}
          promotionType="SMS"
          sendSMSError={data.error}
          recurring={data.scheduleDetails}
          updateRecurring={(payload) => update({ ...data, error: "", scheduleDetails: { ...payload } })}
          send={validate}
        />
      </div>
    </>
  );
}

const promotionalSMSTemplate = (templateValues, branchName, index = null, fieldSetFunction, branchId) => {
  const IS_KALIMZ = branchId == KALIMZ_BRANCH_ID;
  const COMMON_TEMPLATE = [
    // {0:`Dear Customer, Kindly visit ${branchName} and avail {1,1} % discount on all services and products. Offer valid till {3,2} powered by Glamplus.`},
    {
      1: `Dear Customer, Kindly visit ${branchName} and get flat discount of INR {1,1} on minimum billing of INR {2,2} . Offer valid till {3,3} , powered by Glamplus`,
    },
    // {2:`Dear Customer, Kindly visit ${branchName} and avail flat discount of INR {1,1} on all services. Offer valid till {3,2} , powered by Glamplus.`},
    // {3:`Dear Customer, Kindly visit ${branchName} and avail {1,1} % discount on minimum billing of INR {2,2} . Offer valid till {3,3} , powered by Glamplus.`},
    {
      4: `Dear Customer, Kindly visit ${branchName} and avail {1,1} % discount on all services. Offer valid till {3,2} , powered by Glamplus.`,
    },
    {
      5: `Dear Customer, Kindly visit ${branchName} and enjoy {1,1} services at just INR {2,2} . Offer valid till {3,3} , powered by Glamplus.`,
    },
    // `Dear Customer, Kindly call on {2} for appointments and visit ${branchName} to avail best service experience because we have started operations from {3}, powered by glamplus.`},
    {
      6: `Dear Customer, Kindly call on {2,1} for appointments and visit ${branchName} to avail best offers and promotions as we have restarted operations from {3,2} , powered by Glamplus.`,
    },
    {
      7: `Dear Customer, Kindly visit ${branchName} and enjoy {1,1} services at {2,2} % discount. Offer valid till {3,3} , powered by Glamplus.`,
    },
    {
      8: `Welcome to ${branchName}, {1,1} special offer- now enjoy {1,2} , {1,3} , {1,4} . Offer valid till {3,5} , powered by Glamplus. For appointments, kindly call {2,6} .`,
    },
    {
      10: `Welcome to ${branchName}, {4,1} special offer- now enjoy {1,2} at {2,3} % discount, {1,4} at {2,5} % discount, {1,6} at {2,7} % discount. Offer valid till {3,8} , powered by Glamplus. For appointments, kindly call {2,9} .`,
    },
    // {11:`Dear Customer, facing issues like {1,1} now call ${branchName} {2,2} for hassle free {1,3} services, powered by Glamplus`},
    {
      12: `Dear Customer, now visit ${branchName} and enjoy exclusive promotions & offers like {1,1}, {1,2}. Offer valid till {3,3}, powered by Glamplus. Thank you!`,
    },
    {
      14: `Dear Customer, We ${branchName} are closed today. we will be back from tomorrow to serve you. Sorry for the inconvenience caused, powered by glamplus.`,
    },
    {
      15: `Dear Customer, We ${branchName} will be closed on {3,1}. Sorry for the inconvenience caused, powered by glamplus.`,
    },
    {
      16: `Dear Customer, Today is a special day for you, It's your birthday and we will like to make it surprising for you. Kindly visit ${branchName} and enjoy exclusive offers/discounts upto {1,2}, powered by Glamplus.`,
    },
    {
      17: `Dear Customer, ${branchName} wishes you a very happy anniversary. We will like to make it special for you, Now visit along with your life partner and avail flat {1,2} discount and exclusive offers on this auspicious day, powered by Glamplus.`,
    },
  ];

  const KALIMZ_TEMPLATE = [
    {
      1: `Dear Customer, Kindly visit ${branchName} and get flat discount of {1,1} on minimum billing of INR {2,2}. Offer valid till {3,3}, powered by Kalimz.`,
    },
    // {2:`Dear Customer, Kindly visit ${branchName} and avail flat discount of INR {1,1} on all services. Offer valid till {3,2} , powered by Glamplus.`},
    // {3:`Dear Customer, Kindly visit ${branchName} and avail {1,1} % discount on minimum billing of INR {2,2} . Offer valid till {3,3} , powered by Glamplus.`},
    {
      2: `Dear Customer, Kindly visit ${branchName} and avail  {1,1} % discount on all services. Offer valid till {3,2}, powered by Kalimz.`,
    },
    {
      3: `Dear Customer, Kindly visit ${branchName} and enjoy {1,1} services at just INR {2,2}. Offer valid till {3,3}, powered by Kalimz.`,
    },
    // `Dear Customer, Kindly call on {2} for appointments and visit ${branchName} to avail best service experience because we have started operations from {3}, powered by glamplus.`},
    {
      4: `Dear Customer, Kindly call on {2,1} for appointments and visit ${branchName} to avail best offers and promotions as we have restarted operations from {3,2}, powered by Kalimz`,
    },
    {
      5: `Dear Customer, Kindly visit ${branchName} and enjoy {1,1} services at {2,2} % discount. Offer valid till {3,3}, powered by Kalimz.`,
    },
    {
      6: `Welcome to ${branchName}, {1,1} special offer- now enjoy {1,2} , {1,3} , {1,4} . Offer valid till {3,5} , powered by Kalimz. For appointments, kindly call {2,6} .`,
    },
    {
      8: `Welcome to ${branchName}, {4,1} special offer- now enjoy {1,2} at {2,3} % discount, {1,4} at {2,5} % discount, {1,6} at {2,7} % discount. Offer valid till {3,8}, powered by Kalimz. For appointments, kindly call {2,9}.`,
    },
    // {11:`Dear Customer, facing issues like {1,1} now call ${branchName} {2,2} for hassle free {1,3} services, powered by Glamplus`},
    {
      9: `Dear Customer, now visit ${branchName} and enjoy exclusive promotions & offers like {1,1}, {1,2} Offer valid till {3,3} powered by Kalimz. Thank you!`,
    },
    {
      11: `Dear Customer, We ${branchName} are closed today. we will be back from tomorrow to serve you. Sorry for the inconvenience caused, powered by Kalimz.`,
    },
    {
      12: `Dear Customer, We ${branchName} will be closed on {3,1} Sorry for the inconvenience caused, powered by Kalimz.`,
    },
    {
      13: `Dear Customer, Today is a special day for you, It's your birthday and we will like to make it surprising for you. Kindly visit ${branchName} and enjoy exclusive offers/discounts upto {1,2} powered by Kalimz.`,
    },
    {
      14: `Dear Customer, ${branchName} wishes you a very happy anniversary. We will like to make it special for you, Now visit along with your life partner and avail flat {1,1} discount and exclusive offers on this auspicious day, powered by Kalimz.`,
    },
    {
      0: `Dear Customer, Loreal Ki taraf se Special Flat {1,1} % off at all services. Grand Offer at ${branchName} till 31st July. Book your appointment now @ {2,2} .`,
    },
  ];

  let PROMOTIONAL_SMS = IS_KALIMZ ? KALIMZ_TEMPLATE : COMMON_TEMPLATE;

  for (let i = 0; i < PROMOTIONAL_SMS.length; i++) {
    let count = 1;
    _.values(PROMOTIONAL_SMS[i])[0]
      .split(" ")
      .map((x) => {
        if (x.startsWith("{")) {
          count++;
        }
      });
    let key = _.keys(PROMOTIONAL_SMS[i])[0];
    INPUT_COUNT[i] = { [key]: count };
  }
  if (index !== null) {
    return _.values(
      PROMOTIONAL_SMS.find((ele) => {
        if (index in ele) {
          return true;
        }
      })
    )[0];
  } else {
    return PROMOTIONAL_SMS;
  }
};
