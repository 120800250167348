import { API_BASE_URL } from "utilities";
import { errored, startLoading, endLoading } from "./withLoader";
import axios from "axios";


export const getStaffServiceRevenue = ({ id, start_date, end_date }) => {
  let URL = API_BASE_URL + "staff/service/discounted/revenue/";
  const params = { staff_id: id, start_date, end_date };

  return async (dispatch) => {
    dispatch(startLoading("STAFF_LOADER", "Loading Revenue"));
    try {
      const res = await axios(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params,
      });
      dispatch({
        type: "STAFF_SERVICE_REVENUE_SUCCESS",
        payload: res.data.results,
      });
      dispatch(endLoading("STAFF_LOADER"));
    } catch (e) {
      dispatch(errored("STAFF_LOADER", true));
      dispatch(endLoading("STAFF_LOADER"));
    }
  };
};