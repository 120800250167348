import React, { useEffect, useState } from "react";
import { Modal, Table, Card, Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import { ModalOpenCloseUpdates, getFestivalDays } from "../../store/actions/festival";
import "./index.scss";
import { LoadingState } from "../../store/reducers/types";

export const FestivalListModal = () => {
  const dispatch = useDispatch();
  const { modalOpen, data, loading } = useSelector((state) => state.festival);
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearsList, setYearsList] = useState([]);
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    let arr = [];
    for (let i = currentYear; i <= currentYear + 4; i++) {
      arr.push({ key: i, text: i, value: i });
    }
    setYearsList(arr);
  };

  useEffect(() => {
    getYears();
  }, []);

  useEffect(() => {
    if (loading !== LoadingState.loaded && modalOpen) {
      dispatch(getFestivalDays(new Date().getFullYear()));
    }
  }, [modalOpen]);

  const updateYear = (value) => {
    setYear(value);
    dispatch(getFestivalDays(value));
  };

  const modalClose = () => {
    dispatch(ModalOpenCloseUpdates(false));
    setYear(new Date().getFullYear());
  };

  return (
    <Modal className="release-notes" size="tiny" onClose={modalClose} open={modalOpen}>
      <Modal.Header className="modal-header custom-header">
        <div>
          <Translate>Festival Days</Translate>
        </div>
        <div className="cross-icon" onClick={modalClose}>
          X
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="filter-year">
          <span>
            <Translate>Filter by Year</Translate>
          </span>
          <Dropdown
            className="drop-down"
            id={data.length > 0 ? "drop" : "drop-no"}
            upward={false}
            selection
            value={year}
            options={yearsList}
            onChange={(e, val) => updateYear(val.value)}
          />
        </div>
        <Card className="card">
          {data.length > 0 ? (
            <Table className="table">
              <Table.Body>
                {data.map((festival, idx) => {
                  return (
                    <Table.Row key={idx}>
                      <Table.Cell>{festival.festival_date}</Table.Cell>
                      <Table.Cell>{festival.festival_name}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          ) : (
            <div>
              <Translate>No data found</Translate>
            </div>
          )}
        </Card>
      </Modal.Content>
    </Modal>
  );
};
