import { API_BASE_URL } from "utilities";
import axios from "axios";

import ToastAlert from "../../utilities/toastAlert/toastAlert";

export const sendInvoice = (url, type) => {
  const error_var = type === "email" ? "email" : "sms";
  const URL = API_BASE_URL + url;

  axios
    .get(URL, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        ToastAlert({ message: "sent", type: "success" });
      } else {
        const message = `%${error_var} not found!`;
        ToastAlert({ message, type: "error" });
      }
    })
    .catch(function (error) {
      const message = error.response?.data?.detail;
      ToastAlert({ message, type: "error" });
    });
};
