import { API_BASE_URL } from "utilities";
import _ from "lodash";
import axios from "axios";

export function fileUploadError(err) {
  return {
    type: "FILE_UPLOAD_ERROR",
    err,
  };
}

export function fileUploadLoading(loading) {
  return {
    type: "FILE_UPLOAD_LOADER",
    loading,
  };
}

export function fileUploadSucesss(success) {
  return {
    type: "FILE_UPLOAD_SUCCESS",
    success,
  };
}

export function fileUploadReset() {
  return {
    type: "FILE_UPLOAD_RESET",
  };
}

export function uploadDataFile(data) {
  return (dispatch) => {
    dispatch(fileUploadLoading(true));

    let files = [];
    const { name, type, key, size } = data[0];
    const fileExtension = "." + name.split(".").pop();
    files[0] = { name, type, key: key + fileExtension, size };

    const filteredFiles = files.filter((el) => el != null);
    axios({
      method: "POST",
      url: API_BASE_URL + "upload/presigned/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: filteredFiles,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(uploadFile(data, filteredFiles, response.data));
        }
      })
      .catch((err) => {
        dispatch(fileUploadLoading(false));
        console.log(`Error in upploadDataFile: `, { err });
      });
  };
}

function uploadFile(data, files, presignedLinks) {
  return (dispatch) => {
    let presigned_list_index = 0;
    let promiseArray = _.map(files, (file, index) => {
      const form_data = new FormData();
      for (let header in presignedLinks[presigned_list_index].url.fields) {
        form_data.append(header, presignedLinks[presigned_list_index].url.fields[header]);
      }
      form_data.append("file", data[presigned_list_index]);
      return axios.post(presignedLinks[presigned_list_index++].url.url, form_data);
    });

    Promise.all(promiseArray)
      .then((res) => {
        // once we get the file_id we call the `fileUploaded` with the file_id
        // to complete the file upload process
        if (res) {
          dispatch(fileUploaded(res, files));
        }
      })
      .catch((err) => {
        dispatch(fileUploadLoading(false));
        console.log(`promise all`, { err });
      });
  };
}

function fileUploaded(data, files) {
  const payload = files.map(({ type, ...rest }) => ({ ...rest }));
  return (dispatch) => {
    axios({
      method: "POST",
      url: API_BASE_URL + "customer/bulk-upload/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        file: payload[0],
      },
    })
      .then((res) => {
        dispatch(fileUploadSucesss(true));
      })
      .catch((err) => {
        dispatch(fileUploadError(err.response.data));
        dispatch(fileUploadLoading(false));
      });
  };
}
