import { API_BASE_URL, USER_PERMISSIONS_API } from "utilities";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { errorMessage } from "../../utilities/actionMessages";
import { authenticationLogout } from "./authentication";
import { IsJsonString } from "../../utilities/CommonFunctions";

export const getUserPermissions = () => {
  let URL = API_BASE_URL + USER_PERMISSIONS_API;
  return (dispatch) => {
    dispatch({
      type: "GET_ACL_FETCHING",
    });
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "GET_ACL_SUCCESS",
          data: response.data || {},
        });
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "GET_ACL_FAILURE",
          error: error.message,
        });
      });
  };
};
