import React from "react";

import { Icon, Popup } from "semantic-ui-react";

function Ellipsis({ text = "", maxLength = 30, isHtml }) {
  const ellipsis_required = text.length > 30;
  return (
    <div className="ellipsis flex center">
      {ellipsis_required ? (
        <div key={0}>
          {text.slice(0, maxLength)}...
          <Popup
            content={isHtml ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text}
            on="click"
            trigger={<Icon name="info circle" size="large" />}
          />
        </div>
      ) : (
        <div>{text.slice(0, maxLength)}</div>
      )}
    </div>
  );
}

export default Ellipsis;
