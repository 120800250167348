const initClients = {
  clients: [],
  clientsMap: {},
};

export function client(state = initClients, action) {
  switch (action.type) {
    case "CLIENT_FETCH_DATA_SUCCESS":
      return {
        ...state,
        clients: action.client,
      };
    case "CLIENTS_MAP_CREATION_SUCCESS":
      return {
        ...state,
        clientsMap: action.data,
      };
    default:
      return state;
  }
}
