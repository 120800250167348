import { API_BASE_URL, LABELS_API } from "utilities";
import axios from "axios";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { labelActions, errorMessage } from "../../utilities/actionMessages";

const labelTypes = {
  client: {
    id: 1,
    fetching: "FETCH_CLIENT_LABELS_FETCHING",
    success: "FETCH_CLIENT_LABELS_SUCCESS",
    failure: "FETCH_CLIENT_LABELS_FAILURE",
  },
  service: {
    id: 5,
    fetching: "FETCH_SERVICE_LABELS_FETCHING",
    success: "FETCH_SERVICE_LABELS_SUCCESS",
    failure: "FETCH_SERVICE_LABELS_FAILURE",
  },
  product: {
    id: 4,
    fetching: "FETCH_PRODUCT_LABELS_FETCHING",
    success: "FETCH_PRODUCT_LABELS_SUCCESS",
    failure: "FETCH_PRODUCT_LABELS_FAILURE",
  },
};

const getLabelsFetching = (type) => {
  return {
    type: labelTypes[type].fetching,
    isFetching: true,
  };
};

const getLabelsSuccess = (type, labels) => {
  return {
    type: labelTypes[type].success,
    data: labels,
  };
};

const getLabelsFailure = (type) => {
  return {
    type: labelTypes[type].failure,
  };
};

export const getLabels = (type = "client") => {
  let URL = API_BASE_URL + LABELS_API;

  const params = {
    type: labelTypes[type].id,
  };

  return (dispatch) => {
    dispatch(getLabelsFetching(type));

    axios
      .get(URL, {
        params,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch(getLabelsSuccess(type, response.data?.results || []));
      })
      .catch(function (error) {
        console.error(error);
        dispatch(getLabelsFailure(type));
      });
  };
};

export const postLabels = (type = "client", payload) => {
  let URL = API_BASE_URL + LABELS_API;

  payload.type = labelTypes[type].id;

  return (dispatch) => {
    axios
      .post(URL, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        ToastAlert({ message: labelActions.success_create, type: "success" });
        dispatch(getLabels(type));
      })
      .catch(function (error) {
        const toastErrorMessage = error.response?.data ? JSON.stringify(error.response.data) : errorMessage;
        ToastAlert({ message: toastErrorMessage, type: "error" });
      });
  };
};

export const putLabels = (type = "client", id, payload) => {
  let URL = API_BASE_URL + LABELS_API + id + "/";

  return (dispatch) => {
    axios
      .put(URL, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (!response.data.status) {
          ToastAlert({ message: labelActions.success_delete, type: "success" });
        } else {
          ToastAlert({ message: labelActions.success_update, type: "success" });
        }
        dispatch(getLabels(type));
      })
      .catch(function (error) {
        const toastErrorMessage = error.response?.data ? JSON.stringify(error.response.data) : errorMessage;
        ToastAlert({ message: toastErrorMessage, type: "error" });
      });
  };
};
