const init = {
  data: [],
  loading: false,
  error: false,
  close: false,
};

export function barcode(state = init, action) {
  const { type, response } = action;
  switch (type) {
    case "GET_PRODUCT_BARCODE_LIST":
      return { ...state, ...response };
    default:
      return state;
  }
}
