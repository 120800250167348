import { API_BASE_URL, STAFF_ABSENT_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import moment from "moment";
import { STAFF_ATTENDANCE_API } from "../../utilities";

import ToastAlert from "../../utilities/toastAlert/toastAlert"
import { absentActions, attendenceActions,errorMessage } from "../../utilities/actionMessages";

function DateConvertor(date) {
  return moment(date).format("YYYY-MM-DD");
}

function GetStartDate(givenDate = false) {
  var startDate;
  if (givenDate) {
    startDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
  } else {
    var date = new Date();
    startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  }

  return moment(startDate).format("YYYY-MM-DD");
}

function GetEndDate(givenDate = false) {
  var endDate;
  if (givenDate) {
    endDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);
  } else {
    var date = new Date();
    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  return moment(endDate).format("YYYY-MM-DD");
}

export function staffAttendanceFetchDataSuccess(staffAttendance) {
  return {
    type: "STAFF_ABSENT_FETCH_DATA_SUCCESS",
    staffAttendance,
  };
}

export function staffAttendanceFetchData(startDate, endDate, staffID = null) {
  let URL;
  if (staffID) {
    URL =
      API_BASE_URL +
      STAFF_ABSENT_API +
      `?staff_id=${staffID}&start_date=${DateConvertor(startDate)}&end_date=${DateConvertor(endDate)}`;
  } else {
    URL =
      API_BASE_URL + STAFF_ABSENT_API + `?start_date=${DateConvertor(startDate)}&end_date=${DateConvertor(endDate)}`;
  }

  return (dispatch) => {
    dispatch(startLoading("STAFF_ABSENT_LOADER", "loading staff"));
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffAttendanceFetchDataSuccess(response.data.results));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        } else {
          dispatch(errored("STAFF_ABSENT_LOADER", true));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_ABSENT_LOADER", true));
        dispatch(endLoading("STAFF_ABSENT_LOADER"));
      });
  };
}

export function staffAttendancePostData(payload, staffID) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_ABSENT_LOADER", "loading positions"));
    axios({
      method: "post",
      url: API_BASE_URL + STAFF_ABSENT_API,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({message:absentActions.success_create,type:"success"})
          dispatch(staffAttendanceFetchData(
            GetStartDate(new Date(payload.absent_date)),
            GetEndDate(new Date(payload.absent_date)),
            staffID));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        } else {
          dispatch(errored("STAFF_ABSENT_LOADER", true));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_ABSENT_LOADER", true));
        dispatch(endLoading("STAFF_ABSENT_LOADER"));
      });
  };
}

export function staffAttendancePatchData(id, payload, staffID) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_ABSENT_LOADER", "loading positions"));
    axios({
      method: "patch",
      url: API_BASE_URL + STAFF_ABSENT_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({message:absentActions.success_update,type:"success"})
          dispatch(staffAttendanceFetchData(
            GetStartDate(new Date(response.data.absent_date)),
            GetEndDate(new Date(response.data.absent_date)),
            staffID));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        } else {
          dispatch(errored("STAFF_ABSENT_LOADER", true));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_ABSENT_LOADER", true));
        dispatch(endLoading("STAFF_ABSENT_LOADER"));
      });
  };
}

export function staffAttendanceDeleteData(id, staffID, absentDate) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_ABSENT_LOADER", "loading positions"));
    axios({
      method: "delete",
      url: API_BASE_URL + STAFF_ABSENT_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 204) {
          ToastAlert({message:absentActions.success_delete,type:"success"})
          dispatch(staffAttendanceFetchData(
            GetStartDate(new Date(absentDate)),
            GetEndDate(new Date(absentDate)),
            staffID));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        } else {
          dispatch(errored("STAFF_ABSENT_LOADER", true));
          dispatch(endLoading("STAFF_ABSENT_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_ABSENT_LOADER", true));
        dispatch(endLoading("STAFF_ABSENT_LOADER"));
      });
  };
}

export function getReceptionistStaffAttendance(staffId, startDate, endDate) {
  return (dispatch) => {
    const URL = `${API_BASE_URL}${STAFF_ATTENDANCE_API}?staff_id=${staffId}&start_date=${startDate}&end_date=${endDate}`;
    axios({
      method: "GET",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch({
          type: "STAFF_ATTENDANCE_FETCHED",
          data: response.data.results,
        });
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        console.error({ error });
      });
  };
}

export function receptionistStaffAttendanceEntry(payload, range) {
  return (dispatch) => {
    const { startDate, endDate } = range;
    axios({
      method: "POST",
      url: API_BASE_URL + STAFF_ATTENDANCE_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function (response) {
        ToastAlert({message:attendenceActions.success_create,type:"success"})
        dispatch(getReceptionistStaffAttendance(payload.staff_id, startDate, endDate));
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        console.error({ error });
      });
  };
}

export function receptionistStaffAttendanceUpdate(staffId, payload, range, method = "PUT") {
  return (dispatch) => {
    const { startDate, endDate } = range;
    axios({
      method,
      url: API_BASE_URL + STAFF_ATTENDANCE_API + `${payload.id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function () {
        ToastAlert({message:attendenceActions.success_update,type:"success"})
        dispatch(getReceptionistStaffAttendance(staffId, startDate, endDate));
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        console.error(error);
      });
  };
}
