export function otp(state = false, action) {
  switch (action.type) {
    case "OTP_SUCCESS":
      return true;
    case "OTP_HAS_ERRORED":
      return false;
    default:
      return state;
  }
}
