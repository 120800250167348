import React, { createRef, useEffect, useState } from "react";
import "./index.scss";
import { Dropdown, Input, Checkbox, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Fuse from "fuse.js";

function SearchMultiDropdown({
  placeholder,
  options,
  allOptions = [], //only for services and products in appointment
  searchKeys = ["cost", "time", "name"],
  index,
  onChange,
  update,
  setRows,
  rows,
  selectedStaff,
}) {
  const dropdownRef = createRef();
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const freeTextSearchValue = useSelector((state) => state.freeTextSearch);
  let freeTextKey = "";
  const extraOption = []; //this is to retain the previously selected service/product when other labels are selcted in appointment

  useEffect(() => {
    //Dropdown value is empty, reset text state
    if (rows[index][placeholder + "_id"]) {
      setText("");
    }
  }, rows[index][placeholder + "_id"]);

  if (placeholder === "service") {
    freeTextKey = "services";
  } else if (placeholder === "product") {
    freeTextKey = "products";
  }

  const fuseOptions = {
    keys: searchKeys,
    threshold: freeTextSearchValue?.[freeTextKey] ? 0.4 : 0.1,
  };

  const caseSensitiveSearch = (options, query) => {
    if (query.length === 0) {
      return options;
    }
    const fuse = new Fuse(options, fuseOptions);
    let results = fuse.search(query).map(({ item }) => item);
    return results;
  };

  const handleItemClick = (_e, x) => {
    dropdownRef.current.handleItemClick(_e, x);
    setText("");
  };

  if (placeholder === "service" || placeholder === "product") {
    const optionId = rows[index][placeholder + "_id"];
    const isoptionIdInOptions = options.some((option) => option.value === optionId);

    if (optionId && !isoptionIdInOptions) {
      // this allOptions is filtered options so deleted data will not show
      const data = allOptions.find((option) => option.id === optionId);

      if (data) {
        extraOption.push(data);
      }
    }
  }

  const options1 = [
    { key: "page", text: "This Page", value: "page" },
    { key: "org", text: "This Organization", value: "org" },
    { key: "site", text: "Entire Site", value: "site" },
  ];

  return (
    <Dropdown
      ref={dropdownRef}
      className="search-dropdown"
      placeholder={"select " + placeholder}
      options={[...extraOption, ...options]}
      onChange={(event, data) => {
        // update(event, data, placeholder, onChange, index, setRows, rows, selectedStaff);
      }}
      value={rows[index][placeholder + "_id"]}
      search={true}
      onSearchChange={(e, { searchQuery }) => {
        setText(searchQuery);
      }}
      selectOnNavigation={true}
      closeOnChange={false}
      selection
      open={open}
      onClick={() => {
        setOpen((prevState) => !prevState);
      }}
      onBlur={() => {
        setOpen(false);
      }}
    >
      <Dropdown.Menu>
        {caseSensitiveSearch(options, text).map((option, itemIndex) => {
          const isSelected = option.id === rows[index][placeholder + "_id"];
          const isChecked =
            rows.filter((ele) => ele[placeholder + "_id"] === option.id).length && rows[index]?.checked === true;
          let newData = { value: option.id, options: options };

          return (
            <Dropdown.Item
              icon={option?.icon || null}
              style={{ opacity: isSelected ? 0.8 : 1 }}
              className={isSelected ? "greyBackground" : "whiteBackground"}
              text={option.text}
              onClick={handleItemClick}
              value={option.id}
            >
              <div
                className="dropdown-item-view"
                onClick={() => {
                  newData = { ...newData, checked: !isChecked };
                  update(null, newData, placeholder, onChange, itemIndex, setRows, rows, selectedStaff);
                }}
              >
                <Checkbox value={option.id} checked={isChecked} disabled={true} />
                <label>{option.productText || option.text}</label>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SearchMultiDropdown;
