import React, { useState } from "react";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { API_BASE_URL, EXPENSE_PDF_DOWNLOAD } from "../../utilities/constants";
import { generatePDFByData } from "../../utilities/CommonFunctions";

const download = async ({ start_date, end_date, expenseSearch = "" }) => {
  try {
    let url =
      API_BASE_URL + EXPENSE_PDF_DOWNLOAD + `?start_date=${start_date}&end_date=${end_date}&search=${expenseSearch}`;

    const res = await axios({
      url,
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    });

    generatePDFByData(res.data, "expense");
    return true;
  } catch (error) {
    ToastAlert({ message: "Unable to download expense. Please try again later.", type: "error" });
    return false;
  }
};

export const useDownloadExpensePDF = () => {
  const [isFetching, setIsFetching] = useState(false);

  const downloadExpensesPDF = async (data) => {
    setIsFetching(true);
    await download(data);
    setIsFetching(false);
  };

  return {
    isExpensePDFFetching: isFetching,
    downloadExpensesPDF,
  };
};
