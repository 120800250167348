import { createSelector } from "reselect";

const productsMapSelector = (state) => state.productsMap;
const sellersMapSelector = (state) => state.sellers.sellersMap;
const inventoryDetailsSelector = (state) => state.inventoryDetails.data;


export const inventoryDetailsListSelector = createSelector(
  [inventoryDetailsSelector, productsMapSelector, sellersMapSelector],
  (inventoryDetails, productsMap, sellersMap) => {
    // if all the values are present then only format
    if (
      inventoryDetails.length === 0 ||
      Object.keys(productsMap).length === 0 ||
      Object.keys(sellersMap).length === 0
    ) {
      return inventoryDetails;
    }

    const formattedInventoryDetails = getFormattedInventoryDetails({ inventoryDetails, productsMap, sellersMap });

    return formattedInventoryDetails;
  }
);

// getting seller_name and product_data by ids
const getFormattedInventoryDetails = ({ inventoryDetails, productsMap, sellersMap }) => {
  const formattedInventoryDetails = inventoryDetails.map((inventoryDetail) => {
    const { seller, product_details } = inventoryDetail;

    const sellerName = sellersMap[seller]?.name || "";
    let productSearchKeys = [];
    let product_names = [];

    product_details.forEach((product_detail) => {
      const { product } = product_detail;

      const productData = productsMap[product];

      const productSearchKey = productData?.name || "";
      const productName = `${productData?.name} | ${productData?.cost}/-` || "";

      productSearchKeys.push(productSearchKey);
      product_names.push(productName);
    });

    productSearchKeys = productSearchKeys.join(" ");

    const search_key = sellerName + " " + productSearchKeys;

    return {
      ...inventoryDetail,
      sellerName,
      search_key,
      product_names: product_names.join(", "),
    };
  });

  return formattedInventoryDetails;
};
