import { API_BASE_URL, BULK_SMS_PROMOTION_API, RECURRING_PROMOTION_API } from "utilities";
import axios from "axios";
import { endLoading, startLoading } from "./withLoader";
import _ from "lodash";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { promotionActions, errorMessage } from "../../utilities/actionMessages";
import { bulkPromotionViewFetchData, getRecurringPromotion } from "./bulkPromotionView";

export function smsPromotionHasErrored(error) {
  return {
    type: "SMS_PROMOTION_HAS_ERRORED",
    status: false,
    error,
  };
}

export function smsPromotionSuccess() {
  return {
    type: "SMS_PROMOTION_SUCCESS",
    status: true,
  };
}

export function smsPromotionLoading() {
  return {
    type: "SMS_PROMOTION_LOADING",
    status: true,
  };
}

export function resetSMSPromotion() {
  return {
    type: "SMS_PROMOTION_RESET",
  };
}

export function sendSmsPromotion(data) {
  return (dispatch) => {
    dispatch(startLoading("BULK_PROMOTION_LOADER", "updating promotion..."));
    dispatch(smsPromotionLoading());
    let files = [];
    if (data.file) {
      const { name, type, key, size } = data.file;
      files[0] = { name, type, key, size };
    }
    if (data.image) {
      const { name, type, key, size } = data.image;
      files[1] = { name, type, key, size };
    }
    if (files.length) {
      const filteredFiles = files.filter(function (el) {
        return el != null;
      });
      axios({
        method: "post",
        url: API_BASE_URL + "upload/presigned/",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: filteredFiles,
      })
        .then(function (response) {
          if (response.status === 200) {
            dispatch(uploadPromotionalFiles(data, filteredFiles, response.data));
            dispatch(endLoading("BULK_PROMOTION_LOADER"));
          } else {
            ToastAlert({ message: errorMessage, type: "error" });
            dispatch(smsPromotionHasErrored());
            dispatch(endLoading("BULK_PROMOTION_LOADER"));
          }
        })
        .catch(function (error) {
          ToastAlert({ message: errorMessage, type: "error" });
          dispatch(smsPromotionHasErrored());
          dispatch(endLoading("BULK_PROMOTION_LOADER"));
        });
    } else {
      dispatch(updateBulkSms(data));
    }
  };
}

export function uploadPromotionalFiles(data, files, presignedLinks) {
  return (dispatch) => {
    let presigned_list_index = 0;
    let promiseArray = _.map(files, (file, index) => {
      const form_data = new FormData();
      for (let header in presignedLinks[presigned_list_index].url.fields) {
        form_data.append(header, presignedLinks[presigned_list_index].url.fields[header]);
      }
      if (data.file && index === 0) {
        form_data.append("file", data.file);
      } else if (data.image) {
        form_data.append("file", data.image);
      }
      return axios.post(presignedLinks[presigned_list_index++].url.url, form_data);
    });
    Promise.all(promiseArray)
      .then((res) => {
        dispatch(updateBulkSms(data));
        dispatch(endLoading("BULK_PROMOTION_LOADER"));
      })
      .catch((error) => {
        dispatch(smsPromotionHasErrored());
        dispatch(endLoading("BULK_PROMOTION_LOADER"));
      });
  };
}

export function updateBulkSms(data) {
  if (data.file) {
    const { name, type, key, size } = data.file;
    data.file = { name, type, key, size };
  } else {
    data.file = null;
  }

  if (data.image) {
    const { name, type, key, size } = data.image;
    data.image = { name, type, key, size };
  } else {
    // delete data.image;
    data.image_id = null;
  }
  return (dispatch) => {
    if (data.hasOwnProperty("recurring_frequency")) {
      dispatch(sendRecurringPromotion(data));
    } else {
      axios({
        method: "POST",
        url: API_BASE_URL + BULK_SMS_PROMOTION_API,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: data,
      })
        .then(function (response) {
          if (response.status === 200) {
            ToastAlert({ message: promotionActions.success_update, type: "success" });
            dispatch(smsPromotionSuccess());
            dispatch(endLoading("BULK_PROMOTION_LOADER"));
          } else {
            ToastAlert({ message: errorMessage, type: "error" });
            dispatch(smsPromotionHasErrored());
            dispatch(endLoading("BULK_PROMOTION_LOADER"));
          }
          dispatch(bulkPromotionViewFetchData());
        })
        .catch(function (error) {
          ToastAlert({ message: errorMessage, type: "error" });
          if (error && error.response && error.response.status === 401) {
            dispatch(authenticationLogout());
          }
          dispatch(endLoading("BULK_PROMOTION_LOADER"));
          if (error?.response?.data?.detail) {
            dispatch(smsPromotionHasErrored(error.response.data.detail));
          } else {
            dispatch(smsPromotionHasErrored(JSON.stringify(error)));
          }
        });
    }
  };
}

export function sendRecurringPromotion(data) {
  return (dispatch) => {
    axios({
      method: "POST",
      url: API_BASE_URL + RECURRING_PROMOTION_API,
      data,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          ToastAlert({ message: promotionActions.success_create, type: "success" });
          dispatch(smsPromotionSuccess());
          dispatch(endLoading("BULK_PROMOTION_LOADER"));
        } else {
          ToastAlert({ message: errorMessage, type: "error" });
          dispatch(smsPromotionHasErrored());
          dispatch(endLoading("BULK_PROMOTION_LOADER"));
        }
        dispatch(getRecurringPromotion());
        dispatch(bulkPromotionViewFetchData());
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(endLoading("BULK_PROMOTION_LOADER"));
        if (error?.response?.data?.length !== 0) {
          const messages = Object.values(error.response.data).flat().join("\n");
          dispatch(smsPromotionHasErrored(messages));
        } else {
          dispatch(smsPromotionHasErrored(error));
        }
      });
  };
}
