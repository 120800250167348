import React, { useEffect, useState } from "react";
import "./index.scss";
import Modal from "../Modal";
import Description from "Description";
import { PAYMENT_METHOD } from "utilities";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HoverTable from "HoverTable";
import { AppointmentPaymentMethodUpdate } from "../../store/actions/appointmentPayment";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

const paymentModeHeaders = ["Payment Method", "Date", "Reference", "Amount", "Action"];

function PaymentMode({ close, bill, id, customerId, fetchAppointments = () => {}, pendingAmount = 0 }) {
  const dispatch = useDispatch();
  const prevData = useSelector((state) => state.appointmentPaymentMethod);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [references, setReferences] = useState("");
  const [amount, setAmount] = useState("");
  const [sum, setSum] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [isEdit, setIsEdit] = useState({
    edit: false,
    id: null,
  });

  function resetStates() {
    setPaymentMethod("");
    setDateTime(moment().format("YYYY-MM-DDTHH:mm"));
    setReferences("");
    setAmount("");
    setIsEdit({ edit: false, id: null });
  }

  function totalPaidAmount() {
    const total = prevData[0].payment_info.reduce((prev, curr) => prev + curr.amount, 0);
    return total;
  }

  function removeEdit() {
    resetStates();
    setSum(totalPaidAmount());
  }

  useEffect(() => {
    if (prevData.length) {
      setSum(totalPaidAmount());
    }
  }, [prevData]);

  function updateFields(index) {
    setIsEdit({ edit: true, id: index });
    const payments = [...prevData[0].payment_info];
    const { amount, datetime, reference_id, payment_mode } = payments[index];
    setSum(totalPaidAmount() - amount);

    setAmount(amount);
    setDateTime(moment(datetime).format("YYYY-MM-DDTHH:mm"));
    setPaymentMethod(payment_mode);
    setReferences(reference_id);
  }

  function updatePaymentHandler(i) {
    if (paymentMethod === "") {
      ToastAlert({ message: "Please select a payment method.", type: "error" });
      return;
    }
    if (amount === "" || amount == "0") {
      ToastAlert({ message: "Invalid payment amount.", type: "error" });
      return;
    }

    let payload = [],
      prevId;
    if (prevData.length && !isEdit.edit) {
      prevId = prevData[0].id;
      const payments = [
        ...prevData[0].payment_info,
        {
          reference_id: references,
          payment_mode: paymentMethod,
          datetime: moment(dateTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD HH:mm:ss"),
          amount: parseFloat(amount),
        },
      ];
      payload = {
        appointment: id,
        payment_info: payments,
      };
    } else if (isEdit.edit) {
      prevId = prevData[0].id;
      const payments = [...prevData[0].payment_info];
      payments[isEdit.id] = {
        reference_id: references,
        payment_mode: paymentMethod,
        datetime: moment(dateTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD HH:mm:ss"),
        amount: parseFloat(amount),
      };
      payload = {
        appointment: id,
        payment_info: payments,
      };
    }
    dispatch(AppointmentPaymentMethodUpdate(payload, !!prevData.length, prevId, customerId, fetchAppointments));
    resetStates();
    setUpdated(true);
    // close(false);
  }

  const deletePaymentMethod = (index) => {
    const payments = [...prevData[0].payment_info];
    payments.splice(index, 1);

    const payload = {
      appointment: id,
      payment_info: payments,
    };
    dispatch(AppointmentPaymentMethodUpdate(payload, !!prevData.length, prevData[0].id, customerId, fetchAppointments));
    setUpdated(true);
  };

  const paymentModeRenderSpecial = [
    ({ payment_mode }) => {
      return <Description title={payment_mode} />;
    },
    ({ datetime }) => {
      return <Description title={moment(datetime).format("DD-MM-YY hh:mm")} />;
    },
    ({ reference_id }) => {
      return <Description title={reference_id} />;
    },
    ({ amount }) => {
      return <Description title={<>&#8377; {amount}</>} />;
    },
    ({ payment_mode }, a, b, c, d, e, f, index) => {
      if (!["LOYALTY_POINTS", "CUSTOMER_WALLET"].includes(payment_mode))
        return (
          <div>
            <Icon className="edit" onClick={() => updateFields(index)} />
            <Icon className="pointer trash" onClick={() => deletePaymentMethod(index)} />
          </div>
        );
    },
  ];

  const BALANCE = bill - (sum + (parseInt(amount) || 0));

  return (
    <Modal close={() => close(false, updated)} zIndex={1} customStyle={{ position: "fixed", minHeight: "830px" }}>
      <div className="payment-mode flex column">
        <div className="ui form">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>
              <Translate>Add Payment Methods of Rs. {bill}</Translate>
            </h3>
            {isEdit.edit && (
              <Button onClick={removeEdit}>
                <Translate>Remove Edit</Translate>
              </Button>
            )}
          </div>
          <div className="payment-input-container">
            <Dropdown
              className="method-dropdown"
              placeholder="Method"
              selection
              search
              value={paymentMethod}
              options={PAYMENT_METHOD}
              onChange={(_, data) => setPaymentMethod(data.value)}
            />
            <input
              type="datetime-local"
              onChange={(e) => setDateTime(e.target.value)}
              value={dateTime}
              max={moment().format("YYYY-MM-DDTHH:mm")}
            />
            <input
              type="text"
              placeholder="Enter Reference ID"
              onChange={(e) => setReferences(e.target.value)}
              maxLength={20}
              value={references}
            />
            <input
              type="number"
              placeholder="Enter Amount"
              // disabled={!checkbox[index]}
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
          </div>
        </div>
        <div className="amount-container">
          <div>
            <Translate>Total Amount: Rs.</Translate> {sum + (parseInt(amount) || 0)}
          </div>
          {BALANCE !== 0 && (
            <div className="error">
              <Translate>*Total amount pending</Translate> - {BALANCE}
            </div>
          )}
        </div>
        <div className="actions">
          <button className="ui button" onClick={updatePaymentHandler} disabled={BALANCE < 0}>
            <Translate>{isEdit.edit ? "Edit" : "Add"}</Translate>
          </button>
        </div>

        <div className="payment-data">
          <HoverTable
            header={paymentModeHeaders}
            src={prevData[0]?.payment_info || []}
            renderSpecial={paymentModeRenderSpecial}
            noDataText="Please add payment Method."
          />
        </div>
      </div>
    </Modal>
  );
}

export default PaymentMode;
