const initialState = {
  loading: false,
  data: null,
  failed: false,
};

export const appointmentFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case "APPOINTMENT_FEEDBACK_FETCHING":
      return { ...state, loading: true };
    case "APPOINTMENT_FEEDBACK_SUCCESS":
      return { ...state, loading: false, ...action.response };
    case "APPOINTMENT_FEEDBACK_FAILED":
      return { ...state, loading: false, failed: true };
    case "APPOINTMENT_FEEDBACK_RESET":
      return { ...state, ...action.response };
    default:
      return state;
  }
};
