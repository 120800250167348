import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const Title = styled.h3`
  font-weight: 600;
  margin: 0 0 0 0;
  padding: 0;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-smooth: auto;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-bottom: 16px;
`;

export const TextField = styled.input`
  border: 0;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: auto 0;
  font-weight: 400;
`;

export const TextFieldContainer = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
  &:hover {
    border: ${(props) => (props.isActive ? null : "1px solid #808080")};
  }

  border: ${(props) => (props.isActive ? "1px solid #0bacf0" : "1px solid #DDDDDD")};
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  width: 256px;
  height: 50px;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const TextFieldSpan = styled.span`
  position: absolute;
  background-color: white;
  font-size: ${(props) => (props.isActive ? "12px" : "14px")};
  top: ${(props) => (props.isActive ? "-12px" : "10px")};
  z-index: 10;
  ${"" /* left: 10px; */}
  font-family: sans-serif;
  line-height: 1.85;
  pointer-events: none;
  padding: 0;
  margin: 0;
  ${"" /* opacity: ${(props) => (props.isActive ? "0" : "1")}; */}

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const Description = styled.div`
  width: 93%;
  border-radius: 10px;
  background-color: #648cff;
  display: flex;
  flex-direction: row;
  color: white;
  margin-top: 14px;
  margin-bottom: 30px;
`;
export const DescriptionText = styled.p`
  color: white;
  margin: 0;
  flex: 5 auto;
  padding: 10px 30px 10px 10px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
`;

export const FormDescriptionIcon = styled.div`
  margin-left: 20px;
  margin-top: 20px;
`;

export const DateContainer = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
  &:hover {
    border: ${(props) => (props.isActive ? null : "1px solid #808080")};
  }

  border: ${(props) => (props.isActive ? "1px solid #0bacf0" : "1px solid #DDDDDD")};
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const DateSpan = styled.span`
  position: absolute;
  background-color: white;
  font-size: ${(props) => (props.isActive ? "12px" : "14px")};
  top: ${(props) => (props.isActive ? "-12px" : "10px")};
  z-index: 10;
  ${"" /* left: 10px; */}
  font-family: sans-serif;
  line-height: 1.85;
  pointer-events: none;
  padding: 0;
  margin: 0;
  /* width : 20px; */
  ${"" /* opacity: ${(props) => (props.isActive ? "0" : "1")}; */}

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const Date = styled.input`
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  &::-moz-placeholder {
    color: ${(props) => (props.showDate ? "black" : "transparent")};
  }

  &::-moz-webkit-datetime-edit {
    color: ${(props) => (props.showDate ? "black" : "transparent")};
  }
  &::-webkit-datetime-edit {
    color: ${(props) => (props.showDate ? "black" : "transparent")};
  }
  border: 0;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: auto 0;
  font-weight: 400;
  color: ${(props) => (props.showDate ? "black" : "transparent")};
`;

export const SubmitButtonContainer = styled.button`
  border: none;
  background-color: ${(props) => (props.disabled ? "#dddddd" : "#2172b0")};
  border-radius: 4px;
  height: 51px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  user-select: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

export const SubmitButtonText = styled.div`
  color: white;
  font-family: "Montserrat", sans-serif;
  user-select: none;
  font-size: 16px;
`;

export const CurrentDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  margin-bottom: 24px;
`;

export const CurrentDate = styled.p`
  padding: 0;
  margin: 14px 0 24px 0;
  color: #242424;
  text-align: left;
  letter-spacing: 0.14px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
`;

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;
export const Divider = styled.div`
  border-bottom: 1px solid #dddddd;
  width: 100%;
`;

export const FormSubHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 16px 0;
  cursor: pointer;
  align-items: center;
`;

export const SubHeadingText = styled.p`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.14px;
  color: #242424;
  font-size: 14px;
  margin: 0;
  padding: 0;
  user-select: none;
  font-weight: bold;
`;

export const SubHeadingOpenClose = styled.img`
  height: 20px;
  width: 20px;
  user-select: none;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const RadioContainer = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
  display: flex;
  flex-direction: row;
  width: 256px;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const RadioLabel = styled.label`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.14px;
  color: #242424;
  margin-right: 16px;
  font-weight: bold;
`;

export const RadioButton = styled.div`
  border: ${(props) => (props.isActive ? "1px solid #0BACF0" : "1px solid #dddddd")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  padding: 10px 6px;
`;

export const RadioButtonText = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.14px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  user-select: none;
  font-weight: ${(props) => (props.isSelected ? "bold" : "default")};
`;

export const AsyncSelectContainerMain = styled.div`
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  user-select: none;
  position: relative;
  flex: 2;
  margin-right: 8px;
`;

export const AsyncSelectContainer = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
  &:hover {
    border: ${(props) => (props.isActive ? null : "1px solid #808080")};
  }
  display: flex;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 16px 17px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  justify-content: space-between;
  border: ${(props) => (props.isActive ? "1px solid #0bacf0" : "1px solid #DDDDDD")};
  border-radius: 5px;
`;

export const AsyncSelectOptionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  color: #242424;
  width: 100%;
  cursor: pointer;
  height: 300px;
  overflow: auto;
  z-index: 1000;
`;

export const AsyncSelectOptionSpan = styled.span`
  position: absolute;
  background: var(--theme-white);
  font-size: ${(props) => (props.isActive ? "12px" : "14px")};
  top: ${(props) => (props.isActive ? "-12px" : "11px")};
  z-index: 10;
  left: 12px;
  width: ${(props) => (props.isActive ? "" : "75%")};
  font-family: "Montserrat", sans-serif;
  line-height: 1.85;
  pointer-events: none;
  padding: 0;
  margin: 0;
  /* width : 20px; */
  ${"" /* opacity: ${(props) => (props.isActive ? "0" : "1")}; */}

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const AsyncSelectOption = styled.div`
  padding: 17px;
  cursor: pointer;
  background: #f4f4f4;
`;
export const SingleLineFieldsContainer = styled.div`
  @media (max-width: 800px) {
    flex-direction: column;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const PriceFieldContainer = styled(TextFieldContainer)`
  @media (max-width: 800px) {
    width: 100%;
  }
  width: 100px;
  margin: 0;
  padding: 10px;
`;

export const PriceFieldSpan = styled(TextFieldSpan)`
  left: 7px;
`;

export const PriceField = styled(TextField)``;

export const AddNewSelectField = styled.p`
  letter-spacing: 0.14px;
  color: #0bacf0;
  opacity: 1;
  text-align: left;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;
