import { LoadingState } from "./types";

const init = {
  data: [],
  loading: LoadingState.unset,
};

export function companies(state = init, action) {
  switch (action.type) {
    case "COMPANIES_FETCH_DATA_SUCCESS":
      return { ...state, ...action.data };
    case "COMPANIES_FETCH_DATA_LOADING":
      return { ...state, ...action.data };
    default:
      return state;
  }
}
