import { API_BASE_URL } from "utilities";
import { endLoading, errored, startLoading } from "./withLoader";
import axios from "axios";

export const listOrders = (search, pageNum = 1) => {
  let URL = API_BASE_URL + "marketplace/orders/order/?";
  URL = search ? URL + "&search=" + search : URL;
  URL = URL + "&page=" + pageNum;

  return async (dispatch) => {
    dispatch(startLoading("ORDERS_LOADER", "Loading Yours Orders"));
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "ORDER_LIST_SUCCESS",
        payload: res.data,
      });
      dispatch(endLoading("ORDERS_LOADER"));
    } catch (error) {
      dispatch(errored("ORDERS_LOADER", true));
      dispatch(endLoading("ORDERS_LOADER"));
    }
  };
};
