import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import axios from "axios";
import { Button, Icon, Pagination } from "semantic-ui-react";
import Modal from "Modal";

import { addCommaToNumber } from "../../components/MarketplaceCartDetail";
import CustomSearch from "../../components/CustomSearch";
import SideNav from "../../new-components/SideNav";
import MarketplaceOrderDetail from "../../components/MarketplaceOrderDetail";
import MarketplacePayments from "../MarketplacePayments";
import { listOrders } from "../../store/actions/marketplaceOrders";
import { Translate } from "react-auto-translate";
import "./index.scss";
import { MARKET_PLACE_INVOICE_API, API_BASE_URL, ORDER_STATUS, PAYMENT_STATUS } from "utilities";

const getInvoice = (orderId, cb) => {
  const URL = `${API_BASE_URL}${MARKET_PLACE_INVOICE_API}${orderId}/`;
  axios
    .get(URL, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((response) => {
      if (response.status === 200) {
        cb(response.data);
      } else {
        cb(<div />);
      }
    })
    .catch((err) => {
      console.error({ err });
      cb(<div />);
    });
};

function MarketplaceOrders() {
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);
  const [makePayment, setMakePayment] = useState(false);

  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.orderList);
  const { results, pages } = orderList;
  const [invoiceHtml, setInvoiceHtml] = useState(false);

  const handleSearch = debounce(
    (search) => {
      dispatch(listOrders(search));
    },
    [800]
  );

  useEffect(() => {
    dispatch(listOrders());
  }, [dispatch]);

  return (
    <>
      {invoiceHtml && (
        <Modal close={() => setInvoiceHtml(null)}>
          <div className="invoices width100 hide-printout">
            <Button.Group>
              <Button onClick={() => window.print()}>
                <Translate>Print</Translate> <Icon className="print" />
              </Button>
            </Button.Group>
            <div className="print-invoice" dangerouslySetInnerHTML={{ __html: invoiceHtml }} />
          </div>
        </Modal>
      )}
      <div className="orders-container">
        <div className="orders-title">
          <h1 className="title"><Translate>Your Orders</Translate></h1>
          <div className="order-actions">
            <CustomSearch
            style={{maxWidth:"400px",flex:1, minWidth:"250px"}}
              className="custom-search"
              placeholder="Search Product"
              onChange={(search) => handleSearch(search)}
            />
            <div className="due-orders" onClick={() => setMakePayment(true)}>
              <i className="payment icon" />
              <Translate>Due Orders</Translate>
            </div>
          </div>
        </div>
        <SideNav
          condition={showOrderDetail || makePayment}
          onCross={() => {
            showOrderDetail && setShowOrderDetail(false);
            makePayment && setMakePayment(false);
          }}
        >
          {showOrderDetail && <MarketplaceOrderDetail orderDetail={orderDetail} />}
          {makePayment && <MarketplacePayments current_order_id={null} />}
        </SideNav>
        <div className="order-box">
          {results.length === 0 ? (
            <h1><Translate>No Order Place Yet.</Translate></h1>
          ) : (
            results.map((order, index) => {
              const { id, total_cost, delivery_status, payment_status, products, created_at } = order;
              return (
                <div
                  onClick={() => {
                    setShowOrderDetail(true);
                    setOrderDetail(order);
                  }}
                  className="order"
                  key={index}
                >
                  <div className="order-detail">
                    <div className="order-title">
                      <h3>Order Id: {id}</h3>
                      <div>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            getInvoice(id, setInvoiceHtml);
                          }}
                          style={{ marginRight: 8 }}
                        >
                          <Translate>Invoice</Translate>
                        </a>
                        Order Date: {new Date(created_at).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="details">
                      <div>Total Price: Rs {addCommaToNumber(total_cost)}</div>
                      <div>Total Products: {products.length}</div>
                      <div>Payment Status: {PAYMENT_STATUS[payment_status]} </div>
                      <div className="status">
                        Delivery Status:
                        <div
                          className={`${
                            delivery_status === 0 || 4 ? "green" : delivery_status === 5 || 7 ? "red" : "orange"
                          }`}
                        ></div>
                        {ORDER_STATUS[delivery_status]}
                      </div>
                      {/*<button className="button ui">Invoice</button>*/}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <Pagination
          className="orders-pagination"
          size="mini"
          defaultActivePage={1}
          totalPages={pages}
          firstItem={false}
          lastItem={false}
          onPageChange={(event, data) => dispatch(listOrders("", data.activePage))}
        />
      </div>
    </>
  );
}

export default MarketplaceOrders;
