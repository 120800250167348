import React, { useEffect, useState } from "react";
import "./index.scss";
import { Popup, Dropdown, Button } from "semantic-ui-react";

const LabelPopUp = ({ trigger = "", name = "", labels = [], options = [], onSave, pageType = "" }) => {
  const [labelData, setLabelData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLabelData(labels);
  }, [labels]);

  const reset = () => {
    setLabelData(labels);
    close();
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <Popup
      className="label-popup-container"
      trigger={
        <div
          onClick={() => {
            setOpen(true);
          }}
        >
          {trigger}
        </div>
      }
      flowing
      on="click"
      open={open}
      onClose={reset}
    >
      <div>
        <h5>{name}</h5>
        <h3>Add/Update Labels/Categories</h3>
        <div className="label-popup-dropdown">
          <Dropdown
            style={{ minWidth: "200px" }}
            placeholder="Add Labels"
            fluid
            selection
            multiple
            onChange={(_, data) => {
              const { value } = data;
              setLabelData(value);
            }}
            options={options}
            value={labelData}
          />
        </div>
        <div className="label-popup-save">
          <Button onClick={reset}>Cancel</Button>
          <Button
            primary
            onClick={() => {
              onSave(labelData);
              close();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default LabelPopUp;
