import { API_BASE_URL, PROMOTION_API, RECURRING_PROMOTION_API } from "utilities";
import axios from "axios";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { promotionActions, errorMessage } from "../../utilities/actionMessages";
import { smsPromotionHasErrored } from "./bulkPromotion";
import { bulkPromotionViewFetchData, getRecurringPromotion } from "./bulkPromotionView";
import { BULK_PROMOTION_VIEW_API } from "../../utilities/constants";
import { smsSendingStatus } from "../../utilities/CommonFunctions";

export function promotionHasErrored() {
  return {
    type: "PROMOTION_HAS_ERRORED",
    status: false,
  };
}

export function promotionSuccess() {
  return {
    type: "PROMOTION_SUCCESS",
    status: true,
  };
}

export function promotionLoading() {
  return {
    type: "PROMOTION_LOADING",
    status: true,
  };
}

export function promotionReset() {
  return {
    type: "PROMOTION_RESET",
    status: null,
  };
}

export function sendPromotion(data) {
  return (dispatch) => {
    dispatch(promotionLoading());
    axios({
      method: "post",
      url: API_BASE_URL + PROMOTION_API,
      data: data,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({ message: promotionActions.success_create, type: "success" });
          smsSendingStatus(response?.data?.data?.subscription_details?.sms_status);
          dispatch(promotionSuccess());
        } else {
          dispatch(promotionHasErrored());
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        dispatch(promotionHasErrored());
      });
  };
}

export function deleteScheduledPromotion(id) {
  return (dispatch) => {
    if (!id) {
      return null;
    }
    axios({
      method: "DELETE",
      url: API_BASE_URL + BULK_PROMOTION_VIEW_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        ToastAlert({ message: "Scheduled Promotion removed", type: "success" });
        dispatch(bulkPromotionViewFetchData());
      })
      .catch(function (error) {
        console.log({ error });
        ToastAlert({ message: "Unable to remove Scheduled Promotion", type: "error" });
        dispatch(bulkPromotionViewFetchData());
        dispatch(promotionHasErrored());
      });
  };
}

export function deleteRecurringPromotion(id) {
  return (dispatch) => {
    if (!id) {
      return null;
    }
    axios({
      method: "DELETE",
      url: API_BASE_URL + RECURRING_PROMOTION_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        ToastAlert({ message: "Recurring Promotion removed", type: "success" });
        dispatch(getRecurringPromotion());
      })
      .catch(function (error) {
        console.log({ error });
        ToastAlert({ message: "Unable to remove recurring Promotion", type: "error" });
        dispatch(getRecurringPromotion());
        dispatch(promotionHasErrored());
        dispatch(smsPromotionHasErrored(error?.response?.data?.detail || error));
      });
  };
}
