import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "Modal";
import { Button, Checkbox } from "semantic-ui-react";
import moment from "moment";

import { bulkPromotionViewFetchData, getRecurringPromotion } from "../../store/actions/bulkPromotionView";
import SMSPromotion from "./SMSPromotion";
import WhatsAppPromotion from "./WhatsAppPromotion";
import { sendSmsPromotion, resetSMSPromotion } from "../../store/actions/bulkPromotion";
import PromotionManagement from "./PromotionManagement";
import { Translate } from "react-auto-translate";

function PromotionButtons({ canSendBulkPromo, updatePromotionType }) {
  return (
    <Button.Group>
      <Button onClick={() => updatePromotionType(0)}>
        <Translate>Send Promotion to Clients</Translate>
      </Button>
      <Button.Or />
      {canSendBulkPromo && [
        <Button key={0} onClick={() => updatePromotionType(1)}>
          <Translate>Send Bulk Promotion</Translate>
        </Button>,
        <Button.Or key={1} />,
      ]}
      <Button onClick={() => updatePromotionType(2)}>
        <Translate>Promotion History</Translate>
      </Button>
    </Button.Group>
  );
}

function ClientPromotion({
  isBulk,
  show,
  close,
  data,
  update,
  clientFilter,
  submit,
  selectedClients,
  resetStore,
  labelFilter,
}) {
  if (!show) return null;

  return (
    <Modal close={close}>
      <div className="flex width-100 center" style={{ padding: "10px", justifyContent: "center" }}>
        <Checkbox
          radio
          label="SMS Promotion"
          checked={data.type === "SMS"}
          onChange={() => {
            resetStore();
            update({
              ...data,
              type: "SMS",
              scheduleDetails: {
                ...data.scheduleDetails,
                scheduleType: 0,
              },
            });
          }}
        />
        <Checkbox
          radio
          label={"WhatsApp Promotion"}
          checked={data.type === "WHATSAPP"}
          style={{ marginLeft: "10px" }}
          onChange={() => {
            resetStore();
            update({
              ...data,
              type: "WHATSAPP",
              scheduleDetails: {
                ...data.scheduleDetails,
                scheduleType: 1,
              },
            });
          }}
        />
      </div>
      <SMSPromotion
        isBulk={isBulk}
        show={data.type === "SMS"}
        update={update}
        data={data}
        clientFilter={clientFilter}
        labelFilter={labelFilter}
        send={submit}
        selectedClients={selectedClients}
      />
      <WhatsAppPromotion
        isBulk={isBulk}
        show={data.type === "WHATSAPP"}
        update={update}
        data={data}
        clientFilter={clientFilter}
        labelFilter={labelFilter}
        send={submit}
        selectedClients={selectedClients}
      />
    </Modal>
  );
}

function Promotions({ selectedClientFilter, selectedClients, labelFilter }) {
  const dispatch = useDispatch();

  const branchSubscriptionsServices = useSelector((state) => state.branchSubscriptionsServices).data;
  const { data: promotionHistoryData, scheduled: scheduledPromotions } = useSelector(
    (state) => state.bulkPromotionView
  );
  const customerFilters = useSelector((state) => state.customers.filters);
  const bulkSmsPromotion = useSelector((state) => state.bulkSmsPromotion);

  const [promotionType, setPromotionType] = useState(-1);
  // -1: show none
  // 0: promotion to clients
  // 1: bulk promotions to clients
  // 2: show promotionHistory
  const [data, updateData] = useState({
    type: "SMS",
    file: null,
    image: null,
    message: null,
    schedule: false,
    selectedTemplate: null,
    templateValues: {
      0: "branch",
    },
    loading: false,
    error: "",
    status: "",
    scheduleDetails: {
      isActive: false,
      scheduleType: 0,
      date: moment().format("YYYY-MM-DD"),
      day: "",
      singleDate: "",
      time: "",
    },
    call_back_number: null,
  });

  useEffect(() => {
    if (bulkSmsPromotion.status) {
      dispatch(resetSMSPromotion());
      resetPromotionType();
    }
  }, [bulkSmsPromotion.status]);

  const resetPromotionType = () => {
    updateData({
      type: "SMS",
      file: null,
      image: null,
      message: null,
      schedule: false,
      selectedTemplate: null,
      templateValues: {
        0: "branch",
      },
      loading: false,
      error: "",
      status: "",
      scheduleDetails: {
        isActive: false,
        scheduleType: 0,
        date: moment().format("YYYY-MM-DD"),
        day: "",
        singleDate: "",
        time: "",
      },
      call_back_number: null,
    });
    setPromotionType(-1);
  };

  const submit = (payload) => {
    // validations for sms
    // validation for whatsapp
    // payload is expected to be validated
    dispatch(sendSmsPromotion(payload));
  };

  const resetStore = () => {
    dispatch(resetSMSPromotion());
  };

  const canSendBulkPromo =
    branchSubscriptionsServices?.length &&
    branchSubscriptionsServices.some(({ subscription_service }) => subscription_service.name === "BULK_PROMOTION");

  const clientFilter = selectedClientFilter
    ? customerFilters.filter((item) => item.key === selectedClientFilter)?.[0] || null
    : null;

  return (
    <>
      <PromotionButtons
        canSendBulkPromo={canSendBulkPromo}
        updatePromotionType={(type) => {
          if (type === 2) {
            dispatch(bulkPromotionViewFetchData());
            dispatch(getRecurringPromotion());
          }
          setPromotionType(type);
        }}
      />
      {/* Promotion History */}
      <PromotionManagement
        show={promotionType === 2}
        close={resetPromotionType}
        promotionHistoryData={promotionHistoryData}
        scheduledPromotions={scheduledPromotions}
      />
      {/* Promotion and Bulk Promotion */}
      <ClientPromotion
        isBulk={promotionType === 1}
        show={promotionType === 0 || promotionType === 1}
        close={resetPromotionType}
        data={data}
        update={updateData}
        clientFilter={clientFilter}
        labelFilter={labelFilter}
        submit={submit}
        selectedClients={selectedClients}
        resetStore={resetStore}
      />
    </>
  );
}

export default Promotions;
