export const fileUploaderTypes = {
  clients: {
    title: "Upload Clients",
    infoTitle: "Upload Your Clients Dataset",
    sampleFileLink: "https://s3.ap-south-1.amazonaws.com/app.downloadables/Client+Data+Upload+Sample.xlsx",
  },
  sellers: {
    title: "Upload Sellers",
    infoTitle: "Upload Your Sellers Dataset",
    sampleFileLink: "https://s3.ap-south-1.amazonaws.com/app.downloadables/Seller_sheet.xlsx",
  },
  inventoryDetails: {
    title: "Upload Inventory Details",
    infoTitle: "Upload Your Inventory Details Dataset",
    sampleFileLink: "https://s3.ap-south-1.amazonaws.com/app.downloadables/inventory_detail_sheet.xlsx",
  },
};
