import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

export const DiscountAllServicesProducts = (props) => {
  const { type, formik, dropDownOnchange, dropDownValues, dropDownOptions } = props;
  const label = type.slice(0, -1);
  return (
    <>
      <Form.Group widths="equal">
        <Form.Field className="grouped-radio">
          <label>
            <Translate>Discount Type</Translate>
          </label>
          <div className="flex">
            <Form.Radio
              label={translateFormLabel("Percentage")}
              checked={formik.values[`all_${type}_discount_type`] === 0}
              onChange={() => {
                formik.setFieldValue(`all_${type}_discount_type`, 0);
              }}
            />
            <Form.Radio
              label={translateFormLabel("Value")}
              checked={formik.values[`all_${type}_discount_type`] === 1}
              onChange={() => formik.setFieldValue(`all_${type}_discount_type`, 1)}
            />
          </div>
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal" className="width-100" style={{ marginTop: "12px" }}>
        <Form.Field>
          <label>
            <Translate>
              {label} Discount {["Percentage", "Value"][formik.values[`all_${type}_discount_type`]]}*
            </Translate>
          </label>
          <Form.Input
            id={`all_${type}_discount_type_value`}
            type="number"
            placeholder={"Enter Discount"}
            onChange={formik.handleChange}
            value={formik.values[`all_${type}_discount_type_value`] || ""}
            error={formik.errors[`all_${type}_discount_type_value`]}
          />
        </Form.Field>

        <Form.Field>
          <label>
            <Translate>{label} maximum count*</Translate>
          </label>
          <Form.Input
            id={`all_${type}_discount_max_count`}
            type="number"
            placeholder={`Maximum ${label} use count`}
            value={formik.values[`all_${type}_discount_max_count`] || ""}
            onChange={formik.handleChange}
            error={formik.errors[`all_${type}_discount_max_count`]}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            <Translate>{`${label} minimum cost*`}</Translate>
          </label>
          <Form.Input
            style={{ width: "48%" }}
            id={`minimum_${label}_cost`}
            type="number"
            placeholder={`minimum_${label}_cost`}
            value={formik.values[`minimum_${label}_cost`] || ""}
            onChange={formik.handleChange}
            error={formik.errors[`minimum_${label}_cost`]}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field className="flex">
          <Form.Radio
            label={translateFormLabel(`Include ${type}`)}
            checked={formik.values[`all_${type}_choice_type`] === 0}
            onChange={() => formik.setFieldValue(`all_${type}_choice_type`, 0)}
          />
          <Form.Radio
            label={translateFormLabel(`Exclude ${type}`)}
            checked={formik.values[`all_${type}_choice_type`] === 1}
            onChange={() => formik.setFieldValue(`all_${type}_choice_type`, 1)}
          />
          <Dropdown
            placeholder={
              formik.values[`all_${type}_choice_type`] === 0
                ? `Included ${type}`
                : formik.values[`all_${type}_choice_type`] === 1
                ? `Excluded ${type}`
                : ""
            }
            disabled={formik.values[`all_${type}_choice_type`] === ""}
            fluid
            multiple
            search
            selection
            onChange={(_, data) => {
              const { value, options } = data;
              const choice =
                formik.values[`all_${type}_choice_type`] === 1
                  ? `all_${type}_except_discount`
                  : `all_${type}_include_discount`;
              dropDownOnchange(value, options, choice);
            }}
            options={dropDownOptions}
            value={dropDownValues}
            text={dropDownValues || ""}
            forceSelection={false}
            selectOnBlur={false}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};
