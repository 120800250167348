export function customers(
  state = {
    data: [],
    filters: [],
  },
  action
) {
  switch (action.type) {
    case "CUSTOMERS_FETCH_DATA_SUCCESS": {
      return {
        ...state,
        data: action.customersDetails,
      };
    }
    case "CUSTOMERS_FILTER_FETCH_DATA_SUCCESS": {
      return {
        ...state,
        filters: action.data,
      };
    }
    default:
      return state;
  }
}

export function customerPutError(state = {}, action) {
  switch (action.type) {
    case "CUSTOMER_PUT_DATA_SUCCESS": {
      return action.error;
    }
    case "CUSTOMER_PUT_DATA_FAILURE": {
      return action.error;
    }
    case "CUSTOMER_PUT_DATA_RESET": {
      return action.error;
    }
    default:
      return state;
  }
}
