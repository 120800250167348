import { API_BASE_URL, GET_FESTIVALS } from "utilities";
import axios from "axios";
import { LoadingState } from "../reducers/types";

export const FestivalFetchSuccess = (data) => {
  return {
    type: "FESTIVALS_FETCH_SUCCESS",
    data: {
      data: data,
      loading: LoadingState.loaded,
    },
  };
};

export const FestivalFetchError = (error) => {
  return {
    type: "FESTIVALS_FETCH_ERROR",
    data: {
      error: error,
      loading: LoadingState.failed,
    },
  };
};

export const ModalOpenCloseUpdates = (status) => {
  return {
    type: "FESTIVAL_MODAL_OPEN_CLOSE",
    data: {
      modalOpen: status,
    },
  };
};

export const getFestivalDays = (year) => {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + GET_FESTIVALS + `?year=` + year, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(FestivalFetchSuccess(response.data.results));
        }
      })
      .catch((error) => {
        dispatch(FestivalFetchError(true));
      });
  };
};
