const initalState = {
  services: false,
  products: false,
};

export function freeTextSearch(state = initalState, action) {
  switch (action.type) {
    case "FREE_TEXT_SEARCH":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
