import React from "react";
import { Form } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

export const ViewAllServicesProducts = (props) => {
  const { type, campaign } = props;
  return (
    <>
      <div className="flex width-100" style={{ padding: "5px" }}>
        <Form.Field className="grouped-radio">
          <label>
            <Translate>Discount Type</Translate>
          </label>
          <div className="flex">
            <Form.Radio
              label={translateFormLabel("Percentage")}
              checked={campaign[`all_${type}_discount_type`] === 0}
            />
            <Form.Radio label={translateFormLabel("Value")} checked={campaign[`all_${type}_discount_type`] === 1} />
          </div>
        </Form.Field>
        <Form.Field>
          <label>
            <Translate>Discount {["Percentage", "Value"][campaign[`all_${type}_discount_type`]]}</Translate>
          </label>
          <input
            type="number"
            placeholder={"Enter Discount " + campaign.discount_type}
            value={campaign[`all_${type}_discount_type_value`] || ""}
            readOnly="readonly"
          />
        </Form.Field>
      </div>
    </>
  );
};
