import React, { useState, useEffect } from "react";
import { Button, Input, Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Translate } from "react-auto-translate";
import "./index.scss";

import { sendOtp } from "../../store/actions/otp";
import Timer from "../Timer";
import { RESEND_OTP } from "../../utilities/constants";

function CompletionOtpForm({
  customer_id,
  otp_type,
  close,
  description = "",
  primaryButtonText = "Complete",
  secondaryButtonText = "",
  onComplete,
}) {
  const dispatch = useDispatch();
  const [otp, setOTP] = useState("");
  const otpLength = 6;
  const [showResendOtp, setShowResendOtp] = useState(false);

  const sendCustomerOtp = () => {
    const data = {
      customer_id,
      otp_type,
    };

    dispatch(sendOtp({ data, receiver: "customer" }));
  };

  useEffect(() => {
    sendCustomerOtp();
  }, []);

  return (
    <div className="otpFormContainer">
      {/* content */}
      <div>
        <p>
          <Translate>OTP has been sent to your registered mobile number</Translate>
        </p>
        {description.length > 0 && (
          <p class="info">
            <Translate>{description}</Translate>
          </p>
        )}
        <Input
          style={{ width: "100%" }}
          placeholder={`Enter ${otpLength} Digits OTP`}
          type={"number"}
          maxLength={otpLength}
          autoComplete="off"
          value={otp}
          onChange={(e, { value }) => {
            if (value.length <= 6) setOTP(`${value}`);
          }}
        />
        {/* resend otp and timer */}
        <div style={{ padding: "10px 0px" }}>
          {showResendOtp && (
            <span
              style={{ color: "red", cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setShowResendOtp(false);
                sendCustomerOtp(); //resend otp
              }}
            >
              <Translate>RESEND OTP</Translate>
            </span>
          )}

          {!showResendOtp && (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <span>Resend OTP in</span>
              <Timer
                limit={RESEND_OTP}
                onTimeFinish={() => {
                  setShowResendOtp(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {/* action buttons */}
      <div className="buttonContainer">
        <Button onClick={close}>
          <Translate>Cancel</Translate>
        </Button>
        <div>
          {secondaryButtonText.length > 0 && (
            <Button style={{ marginRight: "10px" }} onClick={() => onComplete({ clickedButton: 1 })}>
              <Translate>{secondaryButtonText}</Translate>
            </Button>
          )}
          <Button
            disabled={otp.toString().length < otpLength}
            primary
            onClick={() => {
              const data = { otp };
              onComplete({ clickedButton: 0, data });
            }}
          >
            <Translate>{primaryButtonText}</Translate>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CompletionOtpForm;
