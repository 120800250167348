import axios from "axios";
import { API_BASE_URL } from "../../utilities";

export function getCustomerLoyaltyPointBalance(id) {
  let URL = API_BASE_URL + "loyalty_point/balance/customer/?customer_id=" + id;
  return (dispatch) => {
    dispatch({
      type: "LOYALTY_POINT_LOADING",
      response: { data: "", loading: true },
    });
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (res) {
        dispatch({
          type: "LOYALTY_POINT_BALANCE_SUCCESS",
          response: { data: res.data.balance, loading: false },
        });
      })
      .catch(function (error) {});
  };
}

export function getCustomerLoyaltyPointTransactions(id, pageNum = 1) {
  let URL = API_BASE_URL + "loyalty_point/?search=" + id + "&page=" + pageNum;

  return (dispatch) => {
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (res) {
        dispatch({
          type: "LOYALTY_POINTS_TRANSACTIONS_SUCCESS",
          data: res.data,
        });
      })
      .catch(function (error) {});
  };
}
