import React from "react";
import Card from "./Card";
import "./index.scss";

function Dashboard({ datas }) {
  return (
    <div className="dashboard">
      <div className="card-container">
        {datas.dashboard.map((data, index) => (
          <Card
            key={index}
            value={data.value}
            headLine={data.headLine}
            subheadLine={data?.subheadLine || null}
            name={data.name}
            hover={data?.hover || false}
          />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
