import React from "react";
import "./index.scss";
import { Loader as L } from "semantic-ui-react";

function Loader({ loadingText }) {
  return (
    <div className="loader absolute width-100 height-100 flex center">
      <div className="content">
        <L size="massive" />
        {loadingText}
      </div>
    </div>
  );
}

export default Loader;
