import React from "react";
import { connect } from "react-redux";

const WithLoader = function (ComposedComponent, Loader, loadingIdArray) {
  class LoadingComponentClass extends React.Component {
    uuidV4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const random = (Math.random() * 16) | 0;
        const r = random;
        const nfc = r & 0x3;
        const v = c === "x" ? r : nfc | 0x8;
        return v.toString(16);
      });
    }

    UNSAFE_componentWillMount() {
      const loadingId = this.uuidV4();
      this.loadingIdArray = loadingIdArray ? [loadingId, ...loadingIdArray] : [loadingId];
    }

    refresh() {
      window.location.reload();
    }

    render() {
      let { loading } = this.props;
      let { loadingIdArray } = this;

      let loadingObject;
      loadingIdArray.forEach((id) => {
        let loadObj = loading[id];
        if ((loadObj && loadObj.isLoading) || (loadObj && loadObj.hasErrored)) {
          loadingObject = loadObj;
        }
      });

      let isLoading = false;
      let hasErrored = false;
      if (loadingObject) {
        if (loadingObject.isLoading) {
          isLoading = true;
        }
        if (loadingObject.hasErrored) {
          hasErrored = true;
        }
      }

      return (
        <>
          {hasErrored && (
            <Loader
              loadingText={
                <>
                  An error has occurred. Please refresh the page.
                  <button className="btn-link" onClick={() => this.refresh()}>
                    {" "}
                    Or Click Here.
                  </button>
                </>
              }
            />
          )}
          {isLoading && <Loader loadingText={loadingObject ? loadingObject.text : "Loading..."} />}
          <ComposedComponent {...this.props} />
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return { loading: state.withLoader };
  }

  return connect(mapStateToProps)(LoadingComponentClass);
};

export default WithLoader;
