const initialBasicState = {
  results: [],
  staffMap: {},
};

export function staffBasic(state = initialBasicState, action) {
  switch (action.type) {
    case "STAFF_BASIC_FETCH_DATA_SUCCESS":
      return {
        ...state,
        results: action.staff,
        staffMap: action.staffMap,
      };
    default:
      return state;
  }
}

const initialState = {
  fetching: false,
  results: [],
};

export function staff(state = initialState, action) {
  switch (action.type) {
    case "STAFF_DATA_FETCHING":
      return {
        ...state,
        fetching: true,
      };

    case "STAFF_FETCH_DATA_SUCCESS":
      return {
        ...state,
        fetching: false,
        ...action.staff,
      };
    case "UPDATE_STAFF":
      return {
        ...state,
        update: true,
      };
    case "UPDATE_STAFF_RESET":
      return {
        ...state,
        update: false,
      };
    default:
      return state;
  }
}
