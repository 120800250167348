import React, { useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";

import "./datesDropdown.scss";
import moment from "moment";
import { calcDateDifference } from "../../utilities/CommonFunctions";
import { Translate } from "react-auto-translate/lib/commonjs";

const dateOptions = [
  {
    key: 0,
    text: "Daily",
    value: 0,
  },
  {
    key: 1,
    text: "Weekly",
    value: 1,
  },
  {
    key: 2,
    text: "Monthly",
    value: 2,
  },
  {
    key: 3,
    text: "Custom Dates",
    value: 3,
  },
];

function DatesDropdown({ dateType = 0, onChange, maxDays = 31, minDays = 1 }) {
  const [datesDropdownData, setDatesDrodownData] = useState({
    date_type: dateType, //default daily view
    selected_date: new Date().toISOString().slice(0, 10),
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    dateError: "",
  });

  const getFirstDateOfWeek = (w, y) => {
    let date = new Date(y, 0, 1 + w * 7);
    date.setDate(date.getDate() + (1 - date.getDay()) + 1);
    return date;
  };

  const getLastDateOfWeek = (w, y) => {
    let date = new Date(y, 0, 1 + w * 7);
    date.setDate(date.getDate() + (1 - date.getDay()) + 7);
    return date;
  };

  const dateChange = ({ selected_date, date_type = datesDropdownData.date_type }) => {
    let start_date = null;
    let end_date = null;

    if (date_type === 0) {
      start_date = selected_date;
      end_date = selected_date;
    }
    if (date_type === 1) {
      const split_date = selected_date.split("-");
      const week = split_date[1].split("W")[1] - 1;
      const year = split_date[0];
      start_date = getFirstDateOfWeek(week, year).toISOString().split("T")[0];
      end_date = getLastDateOfWeek(week, year).toISOString().split("T")[0];
    }
    if (date_type === 2) {
      const _moment = moment(selected_date);
      start_date = _moment.startOf("month").format("YYYY-MM-DD");
      end_date = _moment.endOf("month").format("YYYY-MM-DD");
    }
    if (date_type === 3) {
      let [type, date] = selected_date.split(",");
      if (type === "se") {
        start_date = date;
        end_date = date;
      }
      //on changing of only start date
      else if (type === "s") {
        start_date = date;
        end_date = datesDropdownData.end_date;
        setDatesDrodownData({
          ...datesDropdownData,
          start_date,
        });
      }
      //on changing of only end date
      else {
        start_date = datesDropdownData.start_date;
        end_date = date;
        setDatesDrodownData({
          ...datesDropdownData,
          end_date,
        });
      }
    }

    let dateError = "";
    if (date_type === 3) {
      if (calcDateDifference(start_date, end_date) > maxDays) {
        dateError = `maximum ${maxDays} days`;
      }
      if (calcDateDifference(start_date, end_date) < minDays - 1) {
        dateError = `minimum ${minDays} days`;
      }
    }

    if (dateError) {
      setDatesDrodownData({
        ...datesDropdownData,
        start_date,
        end_date,
        selected_date,
        dateError,
      });
      return false;
    }

    setDatesDrodownData({
      ...datesDropdownData,
      start_date,
      end_date,
      selected_date,
      dateError,
      date_type,
    });

    onChange(start_date, end_date);
  };

  const updateDateType = (date_type) => {
    let selected_date;
    const newDate = new Date();

    switch (date_type) {
      case 0: {
        selected_date = newDate.toISOString().slice(0, 10);
        break;
      }
      case 1: {
        let week = moment().week();
        let year = newDate.getFullYear();
        selected_date = `${year}-W${week}`;
        break;
      }
      case 2: {
        let month = newDate.getMonth() + 1;
        if (month < 10) {
          month = `0${month}`;
        }
        let year = newDate.getFullYear();
        selected_date = `${year}-${month}`;
        break;
      }
      case 3: {
        selected_date = newDate.toISOString().slice(0, 10);
        selected_date = "se," + selected_date;
        break;
      }
      default: {
      }
    }

    dateChange({ selected_date, date_type });
  };

  return (
    <div>
      <div className="datesDropdown">
        <Dropdown
          className="dateType-dropdown"
          placeholder="Select Type"
          fluid
          selection
          value={datesDropdownData.date_type || 0}
          options={dateOptions}
          onChange={(e, value) => updateDateType(parseInt(value.value))}
        />

        {/* date types */}
        {datesDropdownData.date_type < 3 && (
          <Form className="date-input">
            <Form.Field>
              <input
                type={["date", "week", "month"][datesDropdownData.date_type]}
                value={datesDropdownData.selected_date}
                onChange={(e) => dateChange({ selected_date: e.currentTarget.value })}
                className="date"
              />
            </Form.Field>
          </Form>
        )}

        {/* Custom Dates */}
        {datesDropdownData.date_type === 3 && (
          <Form className="date-pickers">
            <Form.Field className="pickers">
              <input
                type="date"
                className="dates"
                value={datesDropdownData.start_date}
                onChange={(e) => dateChange({ selected_date: `s,${e.currentTarget.value}` })}
              />
            </Form.Field>
            <div className="dates-divider"> To </div>
            <Form.Field className="pickers">
              <input
                type="date"
                className="dates"
                value={datesDropdownData.end_date}
                onChange={(e) => dateChange({ selected_date: `e,${e.currentTarget.value}` })}
              />
            </Form.Field>
          </Form>
        )}
      </div>
      {datesDropdownData.date_type === 3 && (
        <p style={{ marginTop: "5px" }}>
          <Translate>Input date range: </Translate>
          {datesDropdownData.dateError.length ? (
            <span style={{ color: "red" }}>({datesDropdownData.dateError})</span>
          ) : (
            <span>
              <Translate>
               (maximum {maxDays} days and minimum {minDays} days)
              </Translate>
            </span>
          )}
        </p>
      )}
    </div>
  );
}

export default DatesDropdown;
