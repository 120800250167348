import { API_BASE_URL, PAYMENTS_API } from "utilities";
import axios from "axios";
import { startLoading, endLoading, errored } from "./withLoader";
import { authenticationLogout } from "./authentication";

export function paymentsFetchDataSuccess(payments) {
  return {
    type: "PAYMENTS_FETCH_DATA_SUCCESS",
    payments,
  };
}

export function resetPayments() {
  return {
    type: "PAYMENTS_RESET",
  };
}

export function paymentsPostData(data) {
  return (dispatch) => {
    dispatch(startLoading("PAYMENTS_LOADER", "loading payments"));
    axios({
      method: "post",
      url: API_BASE_URL + PAYMENTS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    })
      .then(function (response) {
        if (response.status === 201) {
          dispatch(paymentsFetchDataSuccess(response.data));
          dispatch(endLoading("PAYMENTS_LOADER"));
        } else {
          dispatch(errored("PAYMENTS_LOADER", true));
          dispatch(endLoading("PAYMENTS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("PAYMENTS_LOADER", true));
        dispatch(endLoading("PAYMENTS_LOADER"));
      });
  };
}

export function paymentsPutData(id, position) {
  return (dispatch) => {
    dispatch(startLoading("PAYMENTS_LOADER", "loading payments"));
    axios({
      method: "put",
      url: API_BASE_URL + PAYMENTS_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: position,
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(paymentsFetchDataSuccess(response.data));
          dispatch(endLoading("PAYMENTS_LOADER"));
        } else {
          dispatch(errored("PAYMENTS_LOADER", true));
          dispatch(endLoading("PAYMENTS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("PAYMENTS_LOADER", true));
        dispatch(endLoading("PAYMENTS_LOADER"));
      });
  };
}
