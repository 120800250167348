import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Icon, Popup, Message } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import { SideNav, Form as NewComponentForm } from "../../new-components";
import _ from "lodash";
import { membershipsPostData, membershipsPutData } from "../../store/actions/memberships";
import { validateMembershipForm } from "../../utilities/validator";
import {
  formatTimeByUnit,
  combineTimeAndUnit,
  formatTimeIntoMin,
  formatTimeIntoDays,
  formatDaysByUnit,
} from "../../utilities/CommonFunctions";
import { cloneDeep } from "lodash";
import { DiscountAllServicesProducts } from "./DiscountAllServicesProducts";
import { SpecificServiceAndProductDiscount } from "./DiscountSpecificServicesProducts";
import { AddRemoveButtons } from "../CampaignSideNav/addRemoveButton";
import { fetchMebershipDataById } from "../../store/actions/membershipDataById";
import { PRODUCTS_DATA } from "../../store/actions/products";

const validityUnitOptions = [
  { key: 0, text: "Day", value: "DAY" },
  { key: 1, text: "Month", value: "MONTH" },
  { key: 2, text: "Year", value: "YEAR" },
];

const MembershipSideNav = ({
  createMembership,
  setCreateMembership,
  membershipForm = {},
  setMembershipForm = () => {},
  membership_id = null,
  inAppointment = false,
}) => {
  const initialServiceForm = {
    service: "",
    allotted_count: 0,
    discount_type: 0,
    discount_type_value: 0.0,
    duration_in_minutes: 1,
    duration_type: "MIN",
  };

  const initialProductFields = {
    product: "",
    allotted_count: 0,
    discount_type: 0,
    discount_type_value: 0.0,
  };

  const initialFormData = {
    name: "",
    description: "",
    validity_in_days: "",
    validity_unit: validityUnitOptions[0].value,
    cost: "",
    wallet_credit_amount: 0.0,
    min_bill_amount: 0.0,
    total_bill_discount: true,
    discount_type: 0,
    discount_type_value: null,
    all_service_discount: false,
    all_services_discount_type: 0,
    all_services_discount_type_value: null,
    all_services_discount_max_count: null,
    minimum_service_cost: null,
    all_services_except_discount: [],
    all_services_include_discount: [],
    all_services_choice_type: "",
    all_products_discount_type: 0,
    all_products_discount_type_value: null,
    all_products_discount_max_count: null,
    minimum_product_cost: null,
    all_products_except_discount: [],
    all_products_include_discount: [],
    all_products_choice_type: "",
    specific_services_fields_show: false,
    specific_products_fields_show: false,
    services: [
      {
        ...initialServiceForm,
      },
    ],
    products: [
      {
        ...initialProductFields,
      },
    ],
    all_services_fields_show: false,
    all_products_fields_show: false,
  };

  const totalBillInitialField = {
    total_bill_discount: false,
    discount_type: 0,
    discount_type_value: null,
  };

  const allServicesInitialField = {
    all_services_discount_type: 0,
    all_services_discount_type_value: null,
    all_services_discount_max_count: null,
    minimum_service_cost: null,
    all_services_except_discount: [],
    all_services_include_discount: [],
    all_services_choice_type: "",
    all_services_fields_show: false,
  };

  const allProductsInitialField = {
    all_products_discount_type: 0,
    all_products_discount_type_value: null,
    all_products_discount_max_count: null,
    minimum_product_cost: null,
    all_products_except_discount: null,
    all_products_include_discount: [],
    all_products_choice_type: "",
    all_products_fields_show: false,
  };

  const specificServiceInitialField = {
    specific_services_fields_show: false,
    services: [
      {
        ...initialServiceForm,
      },
    ],
  };

  const specificProductInitialField = {
    specific_products_fields_show: false,
    products: [
      {
        ...initialProductFields,
      },
    ],
  };

  const dispatch = useDispatch();
  const membershipDataById = useSelector((state) => state.membershipDataById);
  const inventoryServices = useSelector((state) => state.services.data).filter((service) => service.status);
  const products = useSelector((state) => PRODUCTS_DATA(state)).filter((product) => product.status);
  const product_company = products.map((product) => {
    return { ...product, company: product.company?.id };
  });
  const formik = useFormik({
    initialValues: initialFormData,
    validate: validateMembershipForm,
    onSubmit: (values) => {
      createUpdateMembership(values, inAppointment);
    },
  });

  const addRemove = (e, action, type) => {
    e.preventDefault();
    const { values, setValues } = formik;
    if (action === "ADD") {
      if (type === "service") {
        let services = values.services;
        services.push({ ...initialServiceForm });
        setValues({ ...values, ...services });
      }
      if (type === "product") {
        let products = values.products;
        products.push({ ...initialProductFields });
        setValues({ ...values, ...products });
      }
    } else {
      if (values.services.length > 1 || values.products.length > 1) {
        if (type === "service") {
          let services = values.services;
          services.pop();
          setValues({ ...values, ...services });
        }
        if (type === "product") {
          let products = values.products;
          products.pop();
          setValues({ ...values, ...products });
        }
      }
    }
  };

  useEffect(() => {
    if (membershipForm.id) {
      membershipActions(membershipForm);
    }
  }, [membershipForm]);

  useEffect(() => {
    if (membership_id && !membershipDataById.fetching && membershipDataById?.data?.id === membership_id) {
      membershipActions(membershipDataById.data);
    }
  }, [membershipDataById]);

  useEffect(() => {
    if (!membership_id) {
      return;
    }
    if (membershipDataById?.data?.id === membership_id) {
      membershipActions(membershipDataById.data);
    } else {
      dispatch(fetchMebershipDataById(membership_id));
    }
  }, [membership_id]);

  const setCheckboxDiscountType = (type, field, checkStatus) => {
    switch (type) {
      case "total_bill_discount":
        let obj = {};
        if (!checkStatus) obj = { ...totalBillInitialField };
        const updateValues = cloneDeep({
          ...formik.values,
          ...allServicesInitialField,
          ...allProductsInitialField,
          ...specificServiceInitialField,
          ...specificProductInitialField,
          ...obj,
          total_bill_discount: checkStatus,
        });
        formik.setValues(updateValues);
        break;
      case "all_discount":
        let values = {};
        if (field === "service") {
          let obj = {};
          if (!checkStatus) {
            obj = { ...allServicesInitialField };
          }
          values = cloneDeep({
            ...formik.values,
            all_services_fields_show: checkStatus,
            ...specificServiceInitialField,
            ...totalBillInitialField,
            ...obj,
          });
        }
        if (field === "product") {
          let obj = {};
          if (!checkStatus) {
            obj = { ...allProductsInitialField };
          }
          values = cloneDeep({
            ...formik.values,
            all_products_fields_show: checkStatus,
            ...obj,
            ...specificProductInitialField,
            ...totalBillInitialField,
          });
        }
        formik.setValues(values);
        break;
      case "specific_discount":
        let specificValues = {};
        if (field === "service") {
          let obj = {};
          if (!checkStatus) {
            obj = { ...specificServiceInitialField };
          }
          specificValues = cloneDeep({
            ...formik.values,
            specific_services_fields_show: checkStatus,
            ...allServicesInitialField,
            ...totalBillInitialField,
            ...obj,
          });
        }
        if (field === "product") {
          let obj = {};
          if (!checkStatus) {
            obj = { ...specificProductInitialField };
          }
          specificValues = cloneDeep({
            ...formik.values,
            specific_products_fields_show: checkStatus,
            ...obj,
            ...allProductsInitialField,
            ...totalBillInitialField,
          });
        }
        formik.setValues(specificValues);
        break;
    }
  };

  const servicesDataFormat = (services) => {
    let formattedServices = [];
    services.forEach((elements, key) => {
      const { name, gender, cost } = elements;
      formattedServices.push({
        key,
        text: `${name} | ${gender} | ${cost}/-`,
        org_value: { ...elements },
        value: `${name} | ${gender} | ${cost}/-`,
      });
    });
    return formattedServices;
  };

  const servicesDataFormatv2 = (services) => {
    let formattedServices = [];
    services.forEach((elements, key) => {
      const { name, gender, cost, time, time_unit } = elements;
      const formattedTimeByUnit = formatTimeByUnit(time, time_unit);
      const combinedTimeAndUnit = combineTimeAndUnit(formattedTimeByUnit, time_unit);

      formattedServices.push({
        value: key,
        key,
        text: `${name} | ${gender} | ${combinedTimeAndUnit} | ${cost}/-`,
        org_value: { ...elements },
      });
    });
    return formattedServices;
  };

  const productsOptionFormat = (products, type) => {
    let formattedProducts = [];
    products.forEach((elements, key) => {
      const { id, name, company } = elements;
      formattedProducts.push({
        value: type ? key : `${name}${company?.name ? `(${company?.name})` : ""}`,
        key,
        text: `${name}${company?.name ? `(${company?.name})` : ""}`,
        org_value: { ...elements, company: elements.company?.id },
      });
    });
    return formattedProducts;
  };

  const servicesValueFormat = (services, field) => {
    const serviceIds = formik.values[field] || [];
    let formattedServices = serviceIds.map((eleId) => {
      let index = services.findIndex((ele) => ele.id === eleId);
      return `${services[index]?.name} | ${services[index]?.gender} | ${services[index]?.cost}/-`;
    });
    return formattedServices;
  };

  const productValueFormat = (products, field) => {
    const productIds = formik.values[field] || [];
    let formattedServices = productIds.map((eleId) => {
      let index = products.findIndex((ele) => ele.id === eleId);
      return `${products[index]?.name}${products[index]?.company?.name ? `(${products[index]?.company?.name})` : ""}`;
    });

    return formattedServices;
  };

  const updateSelectedProducts = (selectedValues, allOptions, field) => {
    const _temp = [];
    selectedValues.forEach((serviceId) => {
      const id = allOptions.filter((option) => option.value === serviceId)[0].org_value.id;
      _temp.push(id);
    });
    if (field === "all_products_except_discount") {
      formik.setFieldValue(field, _temp);
      formik.setFieldValue("all_products_include_discount", []);
    }
    if (field === "all_products_include_discount") {
      formik.setFieldValue(field, _temp);
      formik.setFieldValue("all_products_except_discount", []);
    }
  };

  const updateMembershipServicesForm = (index, key, value, field) => {
    let formatted_value = formik.values;
    formatted_value[field][index][key] = value;
    formik.setValues({ ...formatted_value });
  };
  const updateExclusionServicesForm = (selectedValues, allOptions, field) => {
    const _temp = [];
    selectedValues.forEach((serviceId) => {
      const id = allOptions.filter((option) => option.value === serviceId)[0].org_value.id;
      _temp.push(id);
    });
    if (field === "all_services_except_discount") {
      formik.setFieldValue(field, _temp);
      formik.setFieldValue("all_services_include_discount", []);
    }
    if (field === "all_services_include_discount") {
      formik.setFieldValue(field, _temp);
      formik.setFieldValue("all_services_except_discount", []);
    }
  };

  const helpTextLabel = (labelText, helperText) => {
    return [
      <label key={0}>
        <Translate>{labelText}</Translate>
      </label>,
      <Popup
        key={1}
        position="top center"
        content={<Translate>{helperText}</Translate>}
        trigger={<Icon link name="help" />}
      />,
    ];
  };

  const translateFormLabel = (labelText) => {
    return [<Translate>{labelText}</Translate>];
  };

  const createUpdateMembership = (value, inAppointment) => {
    let fieldValues = value;
    if (!fieldValues.specific_services_fields_show || !fieldValues.services[0].service) fieldValues.services = [];

    if (!fieldValues.specific_products_fields_show || !fieldValues.products[0].product) fieldValues.products = [];
    if (!fieldValues.total_bill_discount) fieldValues.discount_type = null;

    if (!fieldValues.all_services_fields_show) fieldValues.all_services_discount_type = null;

    if (!fieldValues.all_products_fields_show) fieldValues.all_products_discount_type = null;

    delete fieldValues.all_services_fields_show;
    delete fieldValues.all_products_fields_show;
    delete fieldValues.specific_services_fields_show;
    delete fieldValues.specific_products_fields_show;
    delete fieldValues.all_services_choice_type;
    delete fieldValues.all_products_choice_type;
    // formatting each specific service discount duration into min
    const formattedDurationServices = [];
    for (let service of fieldValues.services) {
      const formattedDuration = formatTimeIntoMin(service.duration_in_minutes, service.duration_type);
      //we have already  checked the error scenarios in validator itself, so no need to check formattedDuration.error
      formattedDurationServices.push({
        ...service,
        duration_in_minutes: formattedDuration.res,
      });
    }

    fieldValues.services = formattedDurationServices;

    const validityDays = formatTimeIntoDays(fieldValues.validity_in_days, fieldValues.validity_unit);

    if (fieldValues.id) {
      dispatch(membershipsPutData(fieldValues.id, { ...fieldValues, validity_in_days: validityDays }, inAppointment));
    } else {
      dispatch(membershipsPostData({ ...fieldValues, validity_in_days: validityDays }, inAppointment));
    }

    setCreateMembership(false);
    formik.setValues({ ...initialFormData });
  };

  const membershipActions = (membershipData) => {
    const membership = cloneDeep(membershipData);
    let all_services_choice_type = "";
    let all_products_choice_type = "";
    if (!membership.discount_type_value) membership.total_bill_discount = false;

    if (membership.all_products_discount_type_value) {
      membership.all_products_fields_show = true;
      if (membership.all_products_include_discount?.length > 0) {
        all_products_choice_type = 0;
      }
      if (
        !membership.all_products_include_discount?.length > 0 &&
        membership.all_products_except_discount?.length > 0
      ) {
        all_products_choice_type = 1;
      }
    }

    if (membership.all_services_discount_type_value) {
      membership.all_services_fields_show = true;
      if (membership.all_services_include_discount?.length > 0) {
        all_services_choice_type = 0;
      }
      if (
        !membership.all_services_include_discount?.length > 0 &&
        membership.all_services_except_discount?.length > 0
      ) {
        all_services_choice_type = 1;
      }
    }
    if (membership.services?.length > 0 && membership.services[0].id) {
      //formatting each discount duration according to duration type
      membership.specific_services_fields_show = true;
      membership.services.forEach((service) => {
        service.duration_in_minutes = formatTimeByUnit(service.duration_in_minutes, service?.duration_type || "MIN");
      });
    } else {
      membership.services = [{ ...initialServiceForm }];
    }

    if (membership.products?.length > 0 && membership.products[0].id) {
      membership.specific_products_fields_show = true;
    } else {
      membership.products = [{ ...initialProductFields }];
    }

    const validity_in_days = formatDaysByUnit(membership.validity_in_days, membership.validity_unit);
    const updatedValue = cloneDeep({
      ...initialFormData,
      ...membership,
      validity_in_days,
      all_services_choice_type,
      all_products_choice_type,
    });
    formik.setValues(updatedValue);
    setCreateMembership(true);
  };

  const closeSideNav = () => {
    setCreateMembership(false);
    formik.setValues({ ...initialFormData });
    setMembershipForm({});
  };

  const { id } = membershipForm;
  return (
    <SideNav className="memberships-side-nav" condition={createMembership} onCross={closeSideNav}>
      {membership_id && membershipDataById?.error && (
        <p className="error">Something went wrong. Could not load latest membership data</p>
      )}
      {membershipDataById?.fetching && <p className="info">Loading latest data...</p>}
      <NewComponentForm>
        <NewComponentForm.Title>
          <Translate>{formik.values.id ? "Update Membership Plan" : "Add Membership Plan"}</Translate>
        </NewComponentForm.Title>
        <NewComponentForm.Divider> </NewComponentForm.Divider>
      </NewComponentForm>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            id="name"
            fluid
            label={<Translate>Name*</Translate>}
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <Form.Input
            fluid
            id="description"
            label={<Translate>Description*</Translate>}
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.errors.description}
          />
        </Form.Group>
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Form.Group widths="equal" style={{ flex: 1 }}>
            <Form.Input
              fluid
              id="validity_in_days"
              label={<Translate>Validity*</Translate>}
              placeholder="Validity"
              type="number"
              value={formik.values.validity_in_days}
              onChange={formik.handleChange}
              error={formik.errors.validity_in_days}
            />
            <Form.Select
              style={{ height: "38px" }}
              fluid
              id="validity_unit"
              label={helpTextLabel("Validity Unit", "1 Month = 30 Days")}
              options={validityUnitOptions}
              placeholder="Unit"
              onChange={(e, { value }) => formik.setFieldValue("validity_unit", value)}
              value={formik.values.validity_unit}
            />
          </Form.Group>
          <Form.Group widths="equal" style={{ flex: 1 }}>
            <Form.Input
              fluid
              id={"cost"}
              label={<Translate>Cost*</Translate>}
              placeholder="Cost"
              type="number"
              value={formik.values.cost}
              onChange={formik.handleChange}
              error={formik.errors.cost}
            />
          </Form.Group>
        </div>
        <Form.Group widths="equal">
          <Form.Input
            id="wallet_credit_amount"
            fluid
            label={helpTextLabel(
              "Wallet Balance",
              "Cashback deposited in clients wallet on purchase of this membership."
            )}
            placeholder="Wallet Balance"
            type="number"
            value={formik.values.wallet_credit_amount}
            onChange={formik.handleChange}
            error={formik.errors.wallet_credit_amount}
          />
          <Form.Input
            id="min_bill_amount"
            fluid
            label={helpTextLabel(
              "Minimum Bill",
              "Discount is only applied if bill amount is more or equal to this amount."
            )}
            placeholder="Minimum Bill"
            type="number"
            value={formik.values.min_bill_amount}
            onChange={formik.handleChange}
            error={formik.errors.min_bill_amount}
          />
        </Form.Group>
        {/* Discount on total bill start here */}
        <Form.Field className="full-width" style={{ marginTop: "30px" }}>
          <Form.Checkbox
            id={"total_bill_discount"}
            label={translateFormLabel("Discount on the total bill?")}
            checked={formik.values.total_bill_discount}
            onChange={() => {
              setCheckboxDiscountType("total_bill_discount", "", !formik.values.total_bill_discount);
            }}
          />
        </Form.Field>
        <Form.Group widths="equal" style={{ display: !formik?.values?.total_bill_discount ? "none" : "" }}>
          <Form.Field className="grouped-radio">
            <label>
              <Translate>Discount Type</Translate>
            </label>
            <Form.Radio
              label={translateFormLabel("Percentage")}
              checked={formik.values.discount_type === 0}
              onChange={() => formik.setFieldValue("discount_type", 0)}
            />
            <Form.Radio
              label={translateFormLabel("Value")}
              checked={formik.values.discount_type === 1}
              onChange={() => formik.setFieldValue("discount_type", 1)}
            />
          </Form.Field>
          <Form.Input
            step="any"
            id={"discount_type_value"}
            type="number"
            fluid
            label={helpTextLabel(
              `Discount*  ${["%", "/-"][formik.values.discount_type || 0]}`,
              "Overall discount. Can't add service level discounts if this is added."
            )}
            placeholder={`Discount ${["%", "-/"][formik.values.discount_type || 0]}`}
            value={formik.values.discount_type_value || ""}
            onChange={formik.handleChange("discount_type_value")}
            error={formik.errors.discount_type_value}
          />
        </Form.Group>
        {/* Discount on totalbill end here */}
        {/* Discount on all services start here */}
        <Form.Checkbox
          id={"all_service_discount"}
          style={{ marginTop: "40px" }}
          label={translateFormLabel("Apply discount across all services?")}
          checked={formik?.values?.all_services_fields_show}
          onChange={(e) => {
            setCheckboxDiscountType("all_discount", "service", e.target.checked);
          }}
        />
        <div style={{ display: !formik?.values?.all_services_fields_show ? "none" : "" }}>
          <DiscountAllServicesProducts
            formik={formik}
            type="services"
            dropDownOnchange={updateExclusionServicesForm}
            dropDownValues={servicesValueFormat(
              inventoryServices,
              formik.values.all_services_choice_type === 1
                ? "all_services_except_discount"
                : "all_services_include_discount"
            )}
            dropDownOptions={servicesDataFormat(inventoryServices)}
          />
        </div>
        {/* Discount on all services end here */}
        {/* Discount on specific services start here */}
        <Form.Field style={{ marginTop: "40px" }}>
          <Form.Checkbox
            label={translateFormLabel("Discount on specific services?")}
            checked={formik.values.specific_services_fields_show}
            onChange={(e) => {
              setCheckboxDiscountType("specific_discount", "service", !formik.values.specific_services_fields_show);
            }}
          />
        </Form.Field>

        <div
          className="membership-services-container"
          style={{ display: !formik?.values?.specific_services_fields_show ? "none" : "" }}
        >
          {formik.values.services?.map((eachService, index) => {
            return (
              <div key={index}>
                <SpecificServiceAndProductDiscount
                  type="services"
                  formik={formik}
                  onChangeFunction={updateMembershipServicesForm}
                  dropdownOption={servicesDataFormatv2(inventoryServices)}
                  dropdownValue={_.findIndex(inventoryServices, eachService.service)}
                  eachObjData={eachService}
                  index={index}
                  helpTextLabel={helpTextLabel}
                />
              </div>
            );
          })}
          <AddRemoveButtons addRemove={addRemove} type="service" className="membership-services-add-remove" />
        </div>
        {/* Discount on specific services end here */}
        {/* Discount on all products start here */}
        <Form.Checkbox
          id={"all_product_discount"}
          style={{ marginTop: "40px" }}
          label={translateFormLabel("Apply discount across all products?")}
          checked={formik?.values?.all_products_fields_show}
          onChange={(e) => {
            setCheckboxDiscountType("all_discount", "product", e.target.checked);
          }}
        />
        <div style={{ display: !formik?.values?.all_products_fields_show ? "none" : "" }}>
          <DiscountAllServicesProducts
            formik={formik}
            type="products"
            dropDownOnchange={updateSelectedProducts}
            dropDownValues={productValueFormat(
              products,
              formik.values.all_products_choice_type === 1
                ? "all_products_except_discount"
                : "all_products_include_discount"
            )}
            dropDownOptions={productsOptionFormat(products)}
          />
        </div>
        {/* Discount on all products end here */}
        {/* Discount on specific products start here */}
        <Form.Field style={{ marginTop: "40px" }}>
          <Form.Checkbox
            label={translateFormLabel("Discount on specific products?")}
            checked={formik.values.specific_products_fields_show}
            onChange={(e) => {
              setCheckboxDiscountType("specific_discount", "product", !formik.values.specific_products_fields_show);
            }}
          />
        </Form.Field>
        <div
          className="membership-services-container"
          style={{ display: !formik?.values?.specific_products_fields_show ? "none" : "" }}
        >
          {formik.values.products?.map((eachProduct, index) => {
            return (
              <div key={index}>
                <SpecificServiceAndProductDiscount
                  type="products"
                  formik={formik}
                  onChangeFunction={updateMembershipServicesForm}
                  dropdownOption={productsOptionFormat(products, "specific")}
                  dropdownValue={_.findIndex(product_company, function (ele) {
                    return ele.id == eachProduct?.product?.id;
                  })}
                  eachObjData={eachProduct}
                  index={index}
                  helpTextLabel={helpTextLabel}
                />
              </div>
            );
          })}
          <AddRemoveButtons addRemove={addRemove} type="product" className="membership-services-add-remove" />
        </div>
        {/* Discount on specific products end here */}
        <div className="common-error-msg">
          <Translate>{formik.errors.common_error || ""}</Translate>
        </div>
        <div className="membership-form-action">
          <Button
            className="btn-style"
            type="button"
            onClick={() => {
              // reset Membership Form
              formik.setValues({
                ...initialFormData,
                services: [
                  {
                    service: "",
                    allotted_count: "",
                    discount_type: 0,
                    discount_type_value: 0.0,
                  },
                ],
              });
            }}
          >
            <Translate>Reset</Translate>
          </Button>
          <Button type="submit" className="btn-style">
            <Translate>{id ? "Update" : "Submit"}</Translate>
          </Button>
          <Button onClick={closeSideNav} className="btn-style">
            <Translate>Cancel</Translate>
          </Button>
        </div>
        {formik.errors.services && (
          <Message
            error={formik.errors.services}
            header={
              <p>
                <strong>
                  <Translate>Service Error</Translate>
                </strong>
              </p>
            }
            content={
              <p>
                <Translate>Service is required</Translate>
              </p>
            }
          />
        )}
      </Form>
    </SideNav>
  );
};

export default MembershipSideNav;
