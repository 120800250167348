import React from "react";
import "./index.scss";
import { Form, Input, Button, Icon, Checkbox, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import { putUser } from "../../store/actions/user";
import { branchFetchData, postBranchLoyaltyPoint } from "../../store/actions/branch";
import { reviewLinkFetchData, reviewLinkPostData } from "../../store/actions/reviewLink";
import { branchShortNameFetchData, branchShortNamePostData } from "../../store/actions/branchShortName";

import { geolocated } from "react-geolocated";
import axios from "axios";

import { API_BASE_URL, REFERRAL_API, BRANCH_API } from "../../utilities";
import { isValidTime } from "../../utilities/CommonFunctions";
import Location from "../../components/Location";
import { validateEmailInput } from "../../utilities/validator";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { errorMessage } from "../../utilities/actionMessages";
import { Translate } from "react-auto-translate";
import { smsSendingStatus } from "../../utilities/CommonFunctions";

const REFERRAL_DEFAULT = {
  name: "",
  phone: "",
};

const reviewLinkStatus = ["pending", "approved", "rejected"];

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      new_password: "",
      re_new_password: "",
      start_time: "",
      end_time: "",
      upload_logo_error: "",
      referral: { ...REFERRAL_DEFAULT },
      referralError: null,
      referralSuccess: null,
      reminder_time: "",
      reminder_time_error: null,
      link: null,
      branchShort: "",
      passwordErrorMessage: "",
      passwordError: false,
      timing_message: "",
      userEmail: [""],
      validEmail: [],
      showCurrentPass: false,
      showNewPass: false,
      showConfirmPass: false,
      loyaltyPointDetails: {
        rupees_to_loyalty_point: "",
        loyalty_point_to_rupees: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
        include_services: false,
        include_products: false,
        include_membership: false,
      },
    };
  }

  static getDerivedStateFromProps(np, ps) {
    if (np.timings && !ps.start_time) {
      const { start_time, end_time } = np.timings;
      return {
        ...ps,
        start_time,
        end_time,
      };
    }
    return null;
  }

  componentDidMount() {
    const { branch, branchLoyaltyPoint, branchGetData, reviewLinkFetchData, branchShortNameFetchData } = this.props;
    if (branch.length === 0) {
      branchGetData();
    }
    branchShortNameFetchData();
    reviewLinkFetchData();
    this.setState({
      userEmail: branch.email?.split("/") || [""],
    });
    if (branchLoyaltyPoint.results.length) {
      this.setState((prevState) => ({
        loyaltyPointDetails: {
          ...prevState.loyaltyPointDetails,
          rupees_to_loyalty_point: branchLoyaltyPoint.results[0].rupees_to_loyalty_point.toString(),
          loyalty_point_to_rupees: branchLoyaltyPoint.results[0].loyalty_point_to_rupees.toString(),
          date: moment(new Date()).format("YYYY-MM-DD"),
          include_services: branchLoyaltyPoint.results[0].include_services,
          include_products: branchLoyaltyPoint.results[0].include_products,
          include_membership: branchLoyaltyPoint.results[0].include_membership,
        },
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { branch, branchLoyaltyPoint, branchDataFetching, branchShortName } = this.props;
    if (prevProps.branch.email !== branch.email) {
      this.setState({
        userEmail: branch.email?.split("/") || [""],
      });
    }
    if (prevProps.branchShortName !== branchShortName) {
      if (branchShortName.data.short_name) {
        this.setState({
          branchShort: branchShortName.data.short_name,
        });
      }
    }

    if (prevProps.branchDataFetching !== branchDataFetching && !branchDataFetching) {
      this.setState({
        start_time: branch.start_time,
        end_time: branch.end_time,
      });
    }

    if (branchLoyaltyPoint.results.length && prevProps.branchLoyaltyPoint !== branchLoyaltyPoint) {
      this.setState((prevState) => ({
        loyaltyPointDetails: {
          ...prevState.loyaltyPointDetails,
          rupees_to_loyalty_point: branchLoyaltyPoint.results[0].rupees_to_loyalty_point.toString(),
          loyalty_point_to_rupees: branchLoyaltyPoint.results[0].loyalty_point_to_rupees.toString(),
          date: moment(new Date()).format("YYYY-MM-DD"),
          include_services: branchLoyaltyPoint.results[0].include_services,
          include_products: branchLoyaltyPoint.results[0].include_products,
          include_membership: branchLoyaltyPoint.results[0].include_membership,
        },
      }));
    }
  }

  updateSalonTime(type, e) {
    const time = e.target.value;
    this.setState({
      [type]: time,
    });
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  updatePassword() {
    const { user } = this.props;
    const data = {
      ...user,
      ...this.state,
    };
    const { old_password, new_password, re_new_password } = this.state;
    //
    if (old_password === "" || new_password === "" || re_new_password === "") {
      this.setState({
        ...this.state,
        passwordError: true,
        passwordErrorMessage: "Fields should not be empty",
      });
      return;
    }
    if (user.name === new_password) {
      this.setState({
        ...this.state,
        passwordError: true,
        passwordErrorMessage: "Cannot be same as username",
      });
      return;
    }
    if (new_password === old_password) {
      this.setState({
        ...this.state,
        passwordError: true,
        passwordErrorMessage: "Same as old ",
      });
      return;
    }
    if (new_password !== re_new_password) {
      this.setState({
        ...this.state,
        passwordError: true,
        passwordErrorMessage: "Passwords are not same",
      });
      return;
    }
    if (new_password.length < 8) {
      this.setState({
        ...this.state,
        passwordError: true,
        passwordErrorMessage: "Password should be more than 8 characters",
      });
      return;
    }

    //checking strength of password
    var condition = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!new_password.match(condition)) {
      this.setState({
        ...this.state,
        passwordError: true,
        passwordErrorMessage: "Weak Password",
      });
      return;
    }

    this.setState({
      ...this.state,
      passwordError: false,
      passwordErrorMessage: "",
    });

    this.props.userPutData(user.id, data);
  }

  verifyLogo(e) {
    createImageBitmap(e.target.files[0])
      .then((bmp) => {
        if (bmp.width > 100 || bmp.height > 100) {
          this.setState({
            upload_logo_error: "Maximum height/width should be 100",
          });
        } else {
          this.setState({ upload_logo_error: "" });
        }
      })
      .catch(console.error);
  }

  updateReferral(key, event) {
    const value = event.target.value;
    const { referral } = this.state;

    this.setState({
      referral: {
        ...referral,
        [key]: value,
      },
    });
  }

  referralAction(action) {
    if (action === "ADD") {
      const { referral } = this.state;
      const { phone } = referral;

      if (phone.length !== 10) {
        this.setState({
          referralError: "Enter a valid number",
          referralSuccess: "Added successfully!",
        });
        return;
      }
      axios({
        method: "post",
        url: API_BASE_URL + REFERRAL_API,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: referral,
      })
        .then((response) => {
          ToastAlert({ message: "Added successfully!", type: "success" });
          smsSendingStatus(response?.data?.subscription_details?.sms_status);
          this.setState({
            referral: { ...REFERRAL_DEFAULT },
            referralSuccess: "Added successfully!",
            referralError: null,
          });
        })
        .catch(() => {
          ToastAlert({ message: errorMessage, type: "error" });
          this.setState({
            referralError: "An error has occurred. Please try again.",
            referralSuccess: null,
          });
        });
    } else {
      this.setState({
        referral: { ...REFERRAL_DEFAULT },
        referralError: null,
        referralSuccess: null,
      });
    }
  }

  updateBranchTiming(branch_id, payload) {
    axios({
      method: "PATCH",
      url: API_BASE_URL + BRANCH_API + branch_id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(() => {
        ToastAlert({ message: "Updated successfully!", type: "success" });
        this.setState({
          timing_message: "Updated Successfully",
        });
        this.props.branchGetData();
      })
      .catch((err) => {
        ToastAlert({ message: errorMessage, type: "error" });
        this.setState({
          timing_message: "Error whilst updating branch timing",
        });
      });
  }

  appointmentReminderAction(action, branch_id) {
    if (action === "UPDATE") {
      const { reminder_time } = this.state;
      axios({
        method: "patch",
        url: API_BASE_URL + BRANCH_API + branch_id + "/",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          appointment_reminder: reminder_time,
        },
      })
        .then(() => {
          this.props.branchGetData();
          ToastAlert({ message: "Added successfully!", type: "success" });
          this.setState({
            reminder_time_error: "Added successfully!",
            reminder_time: "",
          });
        })
        .catch(() => {
          ToastAlert({ message: errorMessage, type: "error" });
          this.setState({
            reminder_time_error: "An error has occurred. Please try again.",
          });
        });
    } else {
      this.setState({
        reminder_time_error: null,
      });
    }
  }

  updateReviewLink() {
    const { link } = this.state;
    this.props.reviewLinkPostData(link);
  }

  updateBranchShortName() {
    const { branchShort } = this.state;
    this.props.branchShortNamePostData(branchShort);
  }

  updateOutletTimings() {
    const { start_time, end_time } = this.state;
    const { branch } = this.props;

    const startTime = moment(start_time, "hh-mm-ss");
    const endTime = moment(end_time, "hh-mm-ss");
    const diff = moment.duration(endTime.diff(startTime))._milliseconds;

    //check validity of times
    if (!isValidTime(start_time) || !isValidTime(end_time) || diff <= 0) {
      this.setState({
        timing_message: "Invalid Timings",
      });
      return false;
    }

    const payload = { start_time, end_time };

    this.updateBranchTiming(branch.id, payload);
  }

  setIncludeLoyaltyPointOption(checked, type) {
    switch (type) {
      case "services":
        this.setState((prevState) => ({
          loyaltyPointDetails: {
            ...prevState.loyaltyPointDetails,
            include_services: checked,
          },
        }));
        break;
      case "products":
        this.setState((prevState) => ({
          loyaltyPointDetails: {
            ...prevState.loyaltyPointDetails,
            include_products: checked,
          },
        }));
        break;
      case "membership":
        this.setState((prevState) => ({
          loyaltyPointDetails: {
            ...prevState.loyaltyPointDetails,
            include_membership: checked,
          },
        }));
        break;
    }
  }

  addUserEmail() {
    if (this.state.userEmail.length < 4) this.setState((prevState) => ({ userEmail: [...prevState.userEmail, ""] }));
  }

  removeUserEmail(index) {
    const updatedUserEmail = this.state.userEmail;
    if (updatedUserEmail.length > 1) {
      updatedUserEmail.splice(index, 1);
      this.setState((prevState) => ({ userEmail: updatedUserEmail }));
    }
  }

  validateEmail(userEmail) {
    const { branch } = this.props;
    let error = false;
    let index = 0;
    userEmail.every((element, i) => {
      if (validateEmailInput(element)) {
        return true;
      } else {
        index = i;
        error = true;
        return false;
      }
    });
    if (!error) {
      const payload = {
        email: userEmail.join("/"),
      };
      this.updateBranchTiming(branch.id, payload);
      this.setState({ validEmail: [] });
    } else {
      let updateValidEmail = [...this.state.validEmail];
      updateValidEmail[index] = false;
      this.setState({ validEmail: updateValidEmail });
    }
  }

  render() {
    const {
      old_password,
      new_password,
      re_new_password,
      start_time,
      end_time,
      timing_message,
      upload_logo_error,
      referral,
      referralError,
      referralSuccess,
      reminder_time,
      reminder_time_error,
      link,
      branchShort,
      passwordError,
      passwordErrorMessage,
      userEmail,
      validEmail,
      loyaltyPointDetails,
    } = this.state;
    const {
      user,
      userHasErrored,
      branch,
      branchSubscriptionsServices,
      reviewLink,
      branchShortName,
      postBranchLoyaltyPoint,
      aclUserPermissions,
    } = this.props;

    const { formatted_component_permissions } = aclUserPermissions;
    const SUPPORTEMAIL = "cx@glamplus.in";

    let canUploadLogo = false;
    if (branchSubscriptionsServices.data.length) {
      canUploadLogo = branchSubscriptionsServices.data.some(({ subscription_service }) => {
        return subscription_service.name === "LOGO";
      });
    }

    return (
      <div key={0} className="settings scroll width100">
        {"id" in user ? (
          <div className="user-form flex">
            {(formatted_component_permissions.includes("admin_access") ||
              formatted_component_permissions.includes("owner_access")) && (
              <>
                <h2>
                  <Translate>Details</Translate>
                </h2>
                <span>Name: {user.first_name}</span>
                <span className="settings-email-container" style={{ marginTop: 10 }}>
                  <Form.Group>
                    <label>
                      <Translate>Email(Used to send consolidated reports)</Translate>
                    </label>
                    {userEmail &&
                      userEmail.map((ele, index) => {
                        return (
                          <div className="email-add-container">
                            <Form.Input
                              style={{ width: "190px" }}
                              fluid
                              type="text"
                              value={ele || ""}
                              onChange={(e) => {
                                let newUserEmail = [...this.state.userEmail];
                                newUserEmail[index] = e.target.value;
                                this.setState({ userEmail: newUserEmail });
                              }}
                              error={
                                validEmail.length
                                  ? validEmail[index] === false
                                    ? {
                                        content: "Please enter a valid email address.",
                                        pointing: "below",
                                      }
                                    : false
                                  : false
                              }
                            />
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <Icon
                                circular
                                inverted
                                color="grey"
                                name="add"
                                onClick={() => {
                                  this.addUserEmail();
                                }}
                              />
                              <Icon
                                circular
                                inverted
                                color="grey"
                                name="minus"
                                onClick={() => {
                                  this.removeUserEmail(index);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    <div style={{ marginTop: 10 }}>
                      <Button
                        onClick={() => {
                          this.validateEmail(userEmail);
                        }}
                      >
                        <Translate>Update</Translate>
                      </Button>
                    </div>
                  </Form.Group>
                </span>
                {branch.gst && <span style={{ marginTop: 10 }}>GST: {branch.gst}</span>}
                <div className="flex" style={{ marginTop: 10 }}>
                  <Location />
                </div>
                <div className="salon-timing" style={{ marginTop: 10 }}>
                  <Form.Group>
                    <label>
                      <Translate>Add your outlet timing</Translate>
                    </label>
                    <div style={{ color: "#4183c4", margin: "5px 0px 10px" }}>(Time in 24 HR Format)</div>
                    <Form.Input
                      fluid
                      label={<Translate>Opening Time</Translate>}
                      type="text"
                      value={start_time}
                      onChange={(e) => this.updateSalonTime("start_time", e)}
                    />
                    <Form.Input
                      fluid
                      label={<Translate>Closing Time</Translate>}
                      type="text"
                      value={end_time}
                      onChange={(e) => this.updateSalonTime("end_time", e)}
                    />
                  </Form.Group>
                  {timing_message && <span>{timing_message} </span>}
                  <div className="controller">
                    <Button onClick={() => this.updateOutletTimings()}>
                      <Translate>Update</Translate>
                    </Button>
                  </div>
                </div>

                <div
                  className="password-form flex"
                  style={{
                    border: "1px solid grey",
                    margin: 10,
                    marginLeft: 0,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    width: 250,
                    minHeight: 200,
                  }}
                >
                  <span>
                    <Translate>Branch Logo Upload</Translate>
                  </span>
                  <span className="description">
                    <Translate>(Max allowed height/width 100)</Translate>
                  </span>
                  <input type="file" onChange={(e) => this.verifyLogo(e)} />
                  <Icon style={{ margin: "auto" }} name="file image outline" size="massive" />
                  {!!upload_logo_error && <span className="error description">{upload_logo_error}</span>}
                </div>

                <div className="review-link">
                  <Form.Group>
                    <label>
                      <Translate>Review Link</Translate>
                    </label>
                    <Form.Input
                      fluid
                      label={
                        <Translate>
                          A review/feedback link can be sent to the clients after completion of an appointment. (Needs
                          to be approved by Glamplus)
                        </Translate>
                      }
                      placeholder="link"
                      type="text"
                      maxLength="400"
                      value={link !== null ? link : reviewLink.data[0]?.link}
                      onChange={(e) => this.setState({ link: e.target.value })}
                    />
                    {reviewLink.data[0]?.status && (
                      <span className={`link-status ${reviewLinkStatus[reviewLink.data[0]?.["approval"]]}`}>
                        {reviewLinkStatus[reviewLink.data[0]?.["approval"]]}
                      </span>
                    )}
                  </Form.Group>
                  <div className="controller">
                    <Button onClick={() => this.updateReviewLink()}>
                      <Translate>Change</Translate>
                    </Button>
                  </div>
                </div>

                {/* branch short name */}
                <div className="review-link">
                  <Form.Group>
                    {/* labels ans instructions */}
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
                      <label>
                        <Translate>Branch Short Name</Translate>
                      </label>
                      <label>
                        <Translate>(Used for campaigns)</Translate>
                      </label>
                      <div>
                        <strong style={{ float: "right" }}>
                          <Translate>{branchShort?.length || 0}/30 Characters</Translate>
                        </strong>
                      </div>
                    </div>

                    <Form>
                      <TextArea
                        placeholder="Short Name"
                        maxLength={30}
                        value={branchShort}
                        onChange={(e) => this.setState({ branchShort: e.target.value })}
                      />
                    </Form>
                    {branchShortName.data.status && (
                      <span className={`link-status ${reviewLinkStatus[branchShortName.data.approval]}`}>
                        {reviewLinkStatus[branchShortName.data.approval]}
                      </span>
                    )}
                    <div className="controller">
                      <Button onClick={() => this.updateBranchShortName()}>
                        <Translate>Change</Translate>
                      </Button>
                    </div>
                  </Form.Group>
                </div>

                <div className="appointment-reminder">
                  <Form.Group>
                    <label>
                      <Translate>Appointment Reminder</Translate>
                    </label>
                    <Form.Input
                      fluid
                      label={`(Message sent to clients before the appointments, currently ${
                        branch.appointment_reminder || 0
                      } hours.)`}
                      placeholder="In Hours"
                      type="number"
                      value={reminder_time}
                      onChange={(e) => this.setState({ reminder_time: e.target.value })}
                    />
                  </Form.Group>
                  {reminder_time_error && <span>{reminder_time_error}</span>}
                  <div className="appointment-reminder-action">
                    <Button onClick={() => this.appointmentReminderAction("UPDATE", branch.id)}>
                      <Translate>Change</Translate>
                    </Button>
                  </div>
                </div>
              </>
            )}

            <div className="password-form flex">
              <h2>
                <Translate>Update Password</Translate>
              </h2>
              <p>
                <Translate>
                  Use your recent OTP as current password if you have not set up the password or forgot the password.
                </Translate>
              </p>
              <Input
                placeholder="current password"
                type={this.state.showCurrentPass ? "text" : "password"}
                value={old_password}
                onChange={(e) => this.updateState("old_password", e.currentTarget.value)}
                icon={
                  <Icon
                    name={this.state.showCurrentPass ? "eye" : "eye slash"}
                    link
                    onClick={() => this.setState((prevState) => ({ showCurrentPass: !prevState.showCurrentPass }))}
                  />
                }
              />
              <Input
                placeholder="new password"
                type={this.state.showNewPass ? "text" : "password"}
                value={new_password}
                onChange={(e) => this.updateState("new_password", e.currentTarget.value)}
                icon={
                  <Icon
                    name={this.state.showNewPass ? "eye" : "eye slash"}
                    link
                    onClick={() => this.setState((prevState) => ({ showNewPass: !prevState.showNewPass }))}
                  />
                }
              />
              <Input
                placeholder="confirm new password"
                type={this.state.showConfirmPass ? "text" : "password"}
                value={re_new_password}
                onChange={(e) => this.updateState("re_new_password", e.currentTarget.value)}
                icon={
                  <Icon
                    name={this.state.showConfirmPass ? "eye" : "eye slash"}
                    link
                    onClick={() => this.setState((prevState) => ({ showConfirmPass: !prevState.showConfirmPass }))}
                  />
                }
              />
              {passwordError && <span className="error">{passwordErrorMessage}</span>}
              {userHasErrored && (
                <span className="error">
                  <Translate>Invalid details. Please try again.</Translate>
                </span>
              )}
              <Button onClick={() => this.updatePassword()}>
                <Translate>Update</Translate>
              </Button>
            </div>

            {(formatted_component_permissions.includes("admin_access") ||
              formatted_component_permissions.includes("owner_access")) && (
              <div>
                <div className="loyaltyPointsContainer">
                  <div>
                    <h2>Loyalty Points</h2>
                    <div className="includeloyaltyPointContainer">
                      <p>Include</p>
                      <Checkbox
                        label="Services?"
                        onChange={(e, value) => {
                          this.setIncludeLoyaltyPointOption(value.checked, "services");
                        }}
                        checked={loyaltyPointDetails.include_services}
                      />
                      <Checkbox
                        label="Products?"
                        onChange={(e, value) => {
                          this.setIncludeLoyaltyPointOption(value.checked, "products");
                        }}
                        checked={loyaltyPointDetails.include_products}
                      />
                      <Checkbox
                        label="Membership?"
                        onChange={(e, value) => {
                          this.setIncludeLoyaltyPointOption(value.checked, "membership");
                        }}
                        checked={loyaltyPointDetails.include_membership}
                      />
                    </div>
                    <div style={{ fontWeight: "bold", lineHeight: "30px" }}>
                      Customer spend
                      <input
                        type="number"
                        style={{
                          width: "100px",
                          textAlign: "center",
                          border: "none",
                          borderBottom: "1px solid black",
                          marginRight: "10px",
                          marginLeft: "10px",
                          padding: "1px",
                        }}
                        placeholder="Enter amount"
                        value={loyaltyPointDetails.rupees_to_loyalty_point}
                        onChange={(e) =>
                          this.setState({
                            loyaltyPointDetails: { ...loyaltyPointDetails, rupees_to_loyalty_point: e.target.value },
                          })
                        }
                      />
                      rs to Customer earn 1 loyalty point
                    </div>
                  </div>
                  <div style={{ fontWeight: "bold", lineHeight: "30px" }}>
                    {/* <var> point is equal to  1 rupee */}
                    1 Loyalty Points is equal to
                    <input
                      type="number"
                      style={{
                        width: "102px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        textAlign: "center",
                        border: "none",
                        borderBottom: "1px solid black",
                      }}
                      placeholder="Enter amount"
                      value={loyaltyPointDetails.loyalty_point_to_rupees}
                      onChange={(e) =>
                        this.setState({
                          loyaltyPointDetails: { ...loyaltyPointDetails, loyalty_point_to_rupees: e.target.value },
                        })
                      }
                    />
                    rupees
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Button
                      onClick={() => {
                        if (
                          !loyaltyPointDetails.loyalty_point_to_rupees.length ||
                          !loyaltyPointDetails.rupees_to_loyalty_point.length
                        ) {
                          ToastAlert({ message: "Fields Should Not Be Empty", type: "error" });
                          return;
                        }
                        postBranchLoyaltyPoint(loyaltyPointDetails);
                      }}
                    >
                      <Translate> Update</Translate>
                    </Button>
                  </div>
                </div>
                <div className="referrals">
                  <h3>
                    <Translate>Glamplus referrals program</Translate>
                  </h3>
                  <h4>
                    <Translate>
                      Refer a partner to us. If the partner registers with us and buys a subscription, get a PayTM gift
                      card of 200/-.
                    </Translate>
                  </h4>
                  <Form.Group>
                    <Form.Input
                      fluid
                      label={<Translate>Partner Name</Translate>}
                      placeholder="Partner Name"
                      value={referral.name}
                      onChange={(e) => this.updateReferral("name", e)}
                    />
                    <Form.Input
                      fluid
                      label={<Translate>Partner Contact</Translate>}
                      placeholder="Partner Contact"
                      maxLength={10}
                      type="number"
                      value={referral.phone}
                      onChange={(e) => this.updateReferral("phone", e)}
                    />
                    {referralError && <span className="error">{referralError}</span>}
                    {referralSuccess && <span className="success">{referralSuccess}</span>}
                  </Form.Group>
                  <div className="referral-action">
                    <Button onClick={() => this.referralAction("ADD")}>
                      <Translate>Refer</Translate>
                    </Button>
                    <Button onClick={() => this.referralAction("REFER")}>
                      <Translate>Cancel</Translate>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <span className="reach-us">
              <Translate>Having problems? Email Us! @</Translate>
              <a href={"mailto:" + SUPPORTEMAIL}>
                <b style={{ textTransform: "lowercase" }}>{SUPPORTEMAIL} </b>
              </a>
              <Translate>or call us @</Translate>
              <a href="tel:+919686636655"> +91-9686636655</a>
              {/* or call us @<a href="tel:+917022976408"> +91-8951939268</a>/
              <a href="tel:+917022976408"> +91-8861812906</a> */}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    aclUserPermissions: state.aclUserPermissions.data,
    userIsLoading: state.userIsLoading,
    userHasErrored: state.userHasErrored,

    branchSubscriptionsServices: state.branchSubscriptionsServices,

    reviewLink: state.reviewLink,
    branchShortName: state.branchShortName,
    timings: {
      start_time: state.branch.data?.start_time || "",
      end_time: state.branch.data?.end_time || "",
    },
    branch: state.branch.data,
    branchDataFetching: state.branch.isFetching,
    branchLoyaltyPoint: state.getBranchLoyaltyPoint,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userPutData: (id, user) => dispatch(putUser(id, user)),
    branchGetData: () => dispatch(branchFetchData()),
    reviewLinkFetchData: () => dispatch(reviewLinkFetchData()),
    reviewLinkPostData: (link) => dispatch(reviewLinkPostData(link)),
    branchShortNameFetchData: () => dispatch(branchShortNameFetchData()),
    branchShortNamePostData: (branchShort) => dispatch(branchShortNamePostData(branchShort)),
    postBranchLoyaltyPoint: (payload) => dispatch(postBranchLoyaltyPoint(payload)),
  };
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(Clients));
