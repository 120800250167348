import React, { createRef } from "react";
import "./index.scss";

import { connect } from "react-redux";
import { SideNav } from "../../new-components";
import {
  fetchAppointmentsBasicDetails,
  appointmentsPostData,
  appointmentUpdate,
  appointmentHideInvoice,
  appointmentShowInvoice,
  resetAppointmentError,
  fetchAppointmentById,
} from "../../store/actions/appointments";
import { membershipsBasicFetchData } from "../../store/actions/memberships";
import { campaignBasicFetchData } from "../../store/actions/campaign";
import { clientMembershipFetchData, clientMembershipReset } from "../../store/actions/clientMemberships";
import { servicesFetchData, servicesPostData } from "../../store/actions/services";
import { PRODUCTS_DATA, productsFetchData, productsPostData } from "../../store/actions/products";
import { staffBasicFetchData } from "../../store/actions/staff";
import { appointmentAnalyticsFetchData } from "../../store/actions/appointmentAnalytics";
import { serviceAnalyticsFetchData } from "../../store/actions/serviceAnalytics";
import { appointmentHide } from "../../store/actions/hideAppointments";
import { authenticationLogout } from "../../store/actions/authentication";
import { companiesFetchData } from "../../store/actions/companies";
import { selectedCustomerAppointmentForm } from "../../store/actions/selectedCustomerAppointmentform";

import FullCalendar from "@fullcalendar/react";
import timeGridDay from "@fullcalendar/timegrid";
import dayGridDay from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import ScrollGrid from "@fullcalendar/scrollgrid";
import mockData from "../../mockData";
import FilterSearchBar from "./filterSearchBar/filterSearchBar";
import CircleCount from "./circleCount";

import { APPOINTMENT_STATUS, SERVICE_STATUS } from "utilities";
import { debounce } from "lodash";
import Fuse from "fuse.js";
import Modal from "Modal";
import Form from "Form";
import Moment from "moment";
import PaymentMode from "PaymentMode";
import GenerateInvoice from "GenerateInvoice";
import Customer360 from "Customer360";
import CustomerNotes from "CustomerNotes";
import { Translate } from "react-auto-translate";

import { Icon, Dropdown, Button } from "semantic-ui-react";
import { promotionReset, sendPromotion } from "../../store/actions/promotion";
import FilterSidenav from "./filter-sidenav";

import { EVENT_COLOR } from "../../utilities/constants";
import Statistics from "./Statistics/Statistics";

import activeStaff from "../../images/active-staff.svg";
import GreenBag from "../../images/DashboardAnalytics/GreenBag.png";
import OrangeBag from "../../images/DashboardAnalytics/OrangeBag.png";
import RedBag from "../../images/DashboardAnalytics/RedBag.png";
import CalendarIcon from "../../images/DashboardAnalytics/Calendar.png";
import StaffIcon from "../../images/DashboardAnalytics/Staff.png";
import CardIcon from "../../images/DashboardAnalytics/Card.png";
import appointmentImg from "../../images/appointment.svg";
import { branchWalletAggregatedData } from "../../store/actions/branchWallet";
import { staffAttendanceFetchData } from "../../store/actions/staffAttendance";
import { customerWalletFetchData } from "../../store/actions/customerWallet";
import { getPendingHistory } from "../../store/actions/pendings";
import PendingHistory from "../PendingHistory";
import ClientMembershipHistory from "../../components/ClientMembershipHistory";
import { customerLabelReset } from "../../store/actions/customerLabels";
import MembershipSideNav from "../../components/MembershipSideNav";
import { FormInputType } from "../../utilities/constants";
import {
  formatTimeIntoMin,
  formatTimeByUnit,
  combineTimeAndUnit,
  calcDateDifference,
  handleMaskNumber,
} from "../../utilities/CommonFunctions";
import { getLabels } from "../../store/actions/labels";
import CampaignSideNav from "../../components/CampaignSideNav";
import AppointmentAnswerDetails from "../../components/AnswerDetails";
import { resetAppointmentAnswers } from "../../store/actions/getAppointmentAnswers";
import { productUsageReset, productUsageModal } from "../../store/actions/productUsage";
import { setIsQuickBook } from "../../store/actions/quickbook";
import ProductUsage from "../../components/ProductUsage";
import AppointmentDeleteModal from "../../components/AppointmentDeleteModal";
import { getAppoitmentDeleteData } from "../../api/getAppointmentDeleteData";

//if partner, change all the "appoitnments" to "bookings"
import { IS_PARTNER } from "../../utilities/constants";
import moment from "moment";
const APPOINTMENT_LABEL = IS_PARTNER ? "Booking" : "Appointment";

function dateUtility(date, minutes) {
  date = new Date(date);
  return new Date(date.getTime() + minutes * 60000).toISOString();
}

const findTotalUsage = (membership, findService) => {
  if (membership.services.length > 0) {
    for (var i = 0; i < membership.services.length; i++) {
      if (membership.services[i].service?.id === findService?.id) {
        return membership.services[i].allotted_count;
      }
    }
  }
  return "Total uses not available";
};

class Appointments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addAppointment: false,
      appointmentFormData: {},
      appointmentFieldError: {
        isError: false,
        message: "",
      },
      accessErrorMessage: "",
      isActionButtonsDisabled: false,
      staffDataForOrdering: [],
      edit: false,
      showAnalytics: true,
      generateInvoice: false,
      viewCustomerHistory: false,
      selectedCustomerData: null,
      sendMessage: false,
      sendMessageFormData: {},
      selectedStaff: null,
      filterSideNav: false,
      unselected_staff: JSON.parse(localStorage.getItem("UNSELECTED_STAFF")) || [],
      staff_order: JSON.parse(localStorage.getItem("STAFF_ORDER")) || [],
      showInterimInvoice: false,
      showInvoice: true,
      showOtpForm: { val: false },
      toCompleteForm: null,
      openMembershipHistory: false,
      paymentDueCustomerId: null,
      openPaymentDueHistory: false,
      selectedMembershipUsageHistory: {
        hasSelected: false,
        selection: [],
        membership: {},
      },
      fullCalendarTabView: {
        timeGridDayBtn: "",
        listWeekBtn: "",
        todayBtn: "button-active",
        timeGridWeekBtn: "",
      },
      filterSearchValue: "",
      enableDeleteBtn: true,
      dropdownAppointementFilterLocalValues: [0, 1, 2, 3],
      appointmentCreationErrorMsgs: [],
      createMembership: false,
      membershipForm: {},
      membership_id: null,
      showAddService: false,
      serviceFieldError: false,
      productFieldError: false,
      productDateError: false,
      serviceData: {},
      showAddProduct: false,
      productData: {},
      showCampaign: false,
      viewAnswerDetails: null,
      directDiscountAlert: false,
      extraDiscount: "",
      openAppointmentDeleteModal: false,
      deleteAppointmentsAffected: [],
      confirmDelete: false,
    };
    this.calendarRef = createRef();
  }

  showCalendarIcon() {
    if (this.state.fullCalendarTabView.timeGridDayBtn || this.state.fullCalendarTabView.todayBtn) {
      return "selectDateBtn,";
    }
    return "";
  }

  componentDidMount() {
    this.props.staffBasicFetchData();
    this.props.membershipsBasicFetchData();
    this.props.campaignBasicFetchData();
    this.props.fetchAppointmentAnalytics();
    this.props.fetchServiceAnalytics();
    const date = new Date().toISOString().slice(0, 10);
    this.props.branchWalletAggregatedData();
    this.props.fetchAbsentees(date, date);
    this.changeView("resourceTimeGridDay");
    this.props.fetchServices();
    this.props.fetchProducts();
    this.props.fetchClientLabels();
    this.props.fetchServiceLabels();
    this.props.fetchProductLabels();
    this.props.fetchCompanies();
    //data will come from localStorage in componentDidMount
    let local = JSON.parse(localStorage.getItem("dropdownAppointmentFilterLocalValues")) || [];
    if (local.length) {
      this.setState({ dropdownAppointementFilterLocalValues: local });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.staffDataFetching) {
      if (
        this.props.staffDataFetching !== prevProps.staffDataFetching ||
        (this.state.staffDataForOrdering.length === 0 && this.props.staff.length > 0) ||
        prevProps.staff.length !== this.props.staff.length
      ) {
        const initiallyFullOrganizedStaff = this.formatStaffListForOrder(this.props.staff);
        this.setState({ staffDataForOrdering: initiallyFullOrganizedStaff });
      }
    }

    if (prevState.addAppointment !== this.state.addAppointment) {
      if (!this.state.addAppointment) {
        this.props.setIsQuickBook(false);
      }
    }
  }

  // when the staff list updates or come here initially
  formatStaffListForOrder(staff) {
    const { formatted_component_permissions } = this.props.aclUserPermissions;
    let staff_order = JSON.parse(localStorage.getItem("STAFF_ORDER")) || [];

    // preprocessing for staff list in re-organize and calendar column
    let formattedStaff = staff.map((singleStaff, index) => {
      return {
        ...singleStaff,
        newId: index + 1,
        title: singleStaff.user.first_name,
        value: singleStaff.user.first_name,
      };
    });

    // if employee/staff then show only unassigned and employee/staff only
    const staff_or_employee =
      formatted_component_permissions.includes("employee_access") ||
      formatted_component_permissions.includes("staff_access");

    if (staff_or_employee) {
      formattedStaff = formattedStaff.filter((singleStaff) => {
        return singleStaff.user?.id === this.props.user?.id;
      });
    }

    formattedStaff = [{ newId: 0, id: -1, title: "Unassigned", value: "a" }, ...formattedStaff];

    formattedStaff = this.organizeStaffs(formattedStaff, staff_order);

    return formattedStaff;
  }

  activeFullCalendarTabView = (id) => {
    const fullCalendarTabView = {
      timeGridDayBtn: "",
      listWeekBtn: "",
      todayBtn: "",
      timeGridWeekBtn: "",
    };
    this.setState({
      fullCalendarTabView: {
        ...fullCalendarTabView,
        [id]: "button-active",
      },
    });
  };

  changeView = (view) => {
    const API = this.getApi();
    API && API.changeView(view);
  };

  getApi = () => {
    const { current: calendarDom } = this.calendarRef;
    return calendarDom ? calendarDom.getApi() : null;
  };

  showSelectedMembershipUsageHistory(usage, membership) {
    this.setState({
      selectedMembershipUsageHistory: {
        hasSelected: true,
        selection: usage,
        membership: membership,
      },
    });
  }

  showMembershipHistory = (customerID) => {
    this.props.clientMembershipFetchData(customerID);
    this.setState({
      openMembershipHistory: true,
    });
  };

  showModal = (value, form, type) => {
    switch (type) {
      case FormInputType.MEMBERHSHIP:
        if (form && form.customer?.membership) {
          const membership = value.options.find((data) => data.key === form.customer?.membership);
          this.setMembershipForm(membership.org_value || {});
        } else if (form && form.membership) {
          this.setState({ membership_id: form.membership });
        } else {
          this.setState({ membership_id: null });
        }
        this.setState({ createMembership: true });
        break;
      case FormInputType.SERVICES:
        this.showAddService();
        break;
      case FormInputType.PRODUCTS:
        this.showAddProduct();
        break;
      case FormInputType.DISCOUNT:
        this.setShowForm();
        break;

      default:
        break;
    }
  };

  selectedAppointmentDataFormat = (appointment, minimum_slot_duration) => {
    let tempServices = appointment.required_services.filter((service) => {
      return service.status !== 4;
    });
    appointment.required_services = tempServices;

    const backgroundColor = EVENT_COLOR[appointment.status];
    let newSingleAppointment = [];

    if (appointment.required_services.length === 0) {
      newSingleAppointment = [
        {
          ...appointment,
          resourceId: -1,
          start: appointment.datetime,
          end: dateUtility(appointment.datetime, minimum_slot_duration),
          servicesAssigned: [],
          backgroundColor,
        },
      ];
    } else {
      /**
       * here we loop through the required_services
       * and process if the `service` is active i.e. status is *NOT* 4
       */
      let newIndex = 0;
      appointment.required_services.forEach((service) => {
        if (service.status === 4) {
          return;
        }
        const serviceTime = service.duration || service.service.time;
        if (service.staffs.length === 0) {
          // service has unassigned resource
          newSingleAppointment = [
            ...newSingleAppointment,
            {
              ...appointment,
              resourceId: -1,
              title: service.service.name,
              start: appointment.datetime,
              end: dateUtility(appointment.datetime, serviceTime),
              servicesAssigned: [
                `${service.service.name} | ${service.service.gender} | ${serviceTime} mins | ${service.cost} /-`,
              ],
              backgroundColor,
            },
          ];
        } else {
          service.staffs.forEach((_staff) => {
            // staffs for a particular services
            const staffExistsInAppointment = newSingleAppointment.filter(
              (item) => item.resourceId === _staff?.staff?.id
            );
            if (staffExistsInAppointment.length !== 0) {
              // staff exists in the list
              // we find the index of the appoinment in the newSingleAppointment
              newSingleAppointment.forEach((_item, idx, thisArray) => {
                if (_item.resourceId === _staff?.staff?.id) {
                  thisArray[idx] = {
                    ..._item,
                    end: dateUtility(_item.end, serviceTime),
                    servicesAssigned: [
                      ..._item.servicesAssigned,
                      `${service.service.name} | ${service.service.gender} | ${serviceTime} mins | ${service.cost} /-`,
                    ],
                  };
                }
              });
            } else {
              if (newIndex === 0) {
                newSingleAppointment = [
                  ...newSingleAppointment,
                  {
                    ...appointment,
                    resourceId: _staff?.staff?.id || -1,
                    title: service.service.name,
                    start: appointment.datetime,
                    end: dateUtility(appointment.datetime, serviceTime),
                    servicesAssigned: [
                      `${service.service.name} | ${service.service.gender} | ${serviceTime} mins
                  | ${service.cost} /-`,
                    ],
                    backgroundColor,
                  },
                ];
              } else {
                newSingleAppointment = [
                  ...newSingleAppointment,
                  {
                    ...appointment,
                    resourceId: _staff?.staff?.id || -1,
                    title: service.service.name,
                    start: newSingleAppointment[newIndex - 1].start,
                    end: newSingleAppointment[newIndex - 1].end,
                    servicesAssigned: [
                      `${service.service.name} | ${service.service.gender} | ${serviceTime} mins | ${service.cost} /-`,
                    ],
                    backgroundColor,
                  },
                ];
                newIndex++;
              }
            }
          });
        }
      });
    }
    return newSingleAppointment[0];
  };

  appointmentsDataFormat = (appointments, minimum_slot_duration) => {
    const { servicesMap, staffMap, aclUserPermissions } = this.props;
    const { formatted_component_permissions } = aclUserPermissions;

    if (appointments.length === 0) {
      return [];
    }
    const tempAppointments = appointments.map((appointment) => {
      let tempServices = appointment.required_services.filter((service) => {
        return service.status !== 4;
      });

      // getting the service name by servise_id
      tempServices = tempServices.map((service) => {
        return {
          ...service,
          name: (service.service_id in servicesMap && servicesMap[service.service_id].name) || "",
        };
      });

      return { ...appointment, required_services: tempServices };
    });

    appointments = tempAppointments;

    let parsedAppointments = [];

    appointments.forEach((appointment) => {
      const backgroundColor = EVENT_COLOR[appointment.status];
      let newSingleAppointment = [];

      if (appointment.required_services.length === 0) {
        newSingleAppointment = [
          {
            ...appointment,
            resourceId: -1,
            start: appointment.datetime,
            end: dateUtility(appointment.datetime, minimum_slot_duration),
            backgroundColor,
          },
        ];
      } else {
        /**
         * here we loop through the required_services
         * and process if the `service` is active i.e. status is *NOT* 4
         */
        let newIndex = 0;
        appointment.required_services.forEach((service) => {
          if (service.status === 4) {
            return;
          }
          const serviceTime = service.duration;
          if (service?.staffs?.length === 0) {
            // service has unassigned resource
            newSingleAppointment = [
              ...newSingleAppointment,
              {
                ...appointment,
                resourceId: -1,
                start: appointment.datetime,
                end: dateUtility(appointment.datetime, serviceTime),
                backgroundColor,
              },
            ];
          } else {
            service.staffs.forEach((_staff) => {
              // staffs for a particular services
              const staffExistsInAppointment = newSingleAppointment.filter((item) => item.resourceId === _staff?.id);
              if (staffExistsInAppointment.length !== 0) {
                // staff exists in the list
                // we find the index of the appoinment in the newSingleAppointment
                newSingleAppointment.forEach((_item, idx, thisArray) => {
                  if (_item.resourceId === _staff?.id) {
                    thisArray[idx] = {
                      ..._item,
                      end: dateUtility(_item.end, serviceTime),
                    };
                  }
                });
              } else {
                if (newIndex === 0) {
                  newSingleAppointment = [
                    ...newSingleAppointment,
                    {
                      ...appointment,
                      resourceId: _staff?.id || -1,
                      start: appointment.datetime,
                      end: dateUtility(appointment.datetime, serviceTime),
                      backgroundColor,
                    },
                  ];
                } else {
                  newSingleAppointment = [
                    ...newSingleAppointment,
                    {
                      ...appointment,
                      resourceId: _staff?.id || -1,
                      start: newSingleAppointment[newIndex - 1].start,
                      end: newSingleAppointment[newIndex - 1].end,
                      backgroundColor,
                    },
                  ];
                  newIndex++;
                }
              }
            });
          }
        });
      }
      //need more info why we need ...parsedAppointments we can push
      parsedAppointments = [...parsedAppointments, ...newSingleAppointment];
    });

    let formattedAppointments = [];
    parsedAppointments.forEach((element) => {
      const maskedNumber = handleMaskNumber(element.customer.number, formatted_component_permissions);

      formattedAppointments.push({
        title: element.customer.name + " | " + maskedNumber,
        resourceId: element.resourceId,
        start: element.start,
        end: element.end,
        services: element["required_services"],
        className: "appointment-" + APPOINTMENT_STATUS[element.status],
        created_by: staffMap[element.created_by_id]?.user?.name,
        originalData: {
          id: element?.id,
        },
        backgroundColor: element.backgroundColor,
        status: element.status,
      });
    });

    //filtering formattedAppointments according to dropDown filter
    let newFilteredList = formattedAppointments.filter((appointment) => {
      let status = appointment.status;
      return this.state.dropdownAppointementFilterLocalValues.includes(status);
    });

    return newFilteredList;
  };

  servicesDataFormat = (services) => {
    let formattedServices = [];
    services.forEach((elements) => {
      const { id, name, gender, time, time_unit, cost } = elements;
      const formattedTimeByUnit = formatTimeByUnit(time, time_unit);
      const combinedTimeAndUnit = combineTimeAndUnit(formattedTimeByUnit, time_unit);

      formattedServices.push({
        value: id,
        key: id,
        text: `${name} | ${gender} | ${combinedTimeAndUnit} | ${cost}/-`,
        ...elements,
      });
    });
    return formattedServices;
  };

  companiesDataFormat = (companies) => {
    let formattedData = [];
    companies.forEach((elements) => {
      const { id, name, status } = elements;
      //filtering
      if (status) {
        formattedData.push({
          value: id,
          key: id,
          text: name,
        });
      }
    });
    return formattedData;
  };

  productsFormFormat = (productsForm, companies) => {
    const _companies = this.companiesDataFormat(companies);
    let formattedProductsForm = [];

    productsForm.forEach((elements) => {
      if (elements.label === "company*") {
        formattedProductsForm.push({
          ...elements,
          options: _companies,
        });
      } else {
        formattedProductsForm.push(elements);
      }
    });
    return formattedProductsForm;
  };

  productsDataFormat = (products) => {
    let formattedProducts = [];
    products.forEach((elements) => {
      const { id, name, company, cost, mfg_date, exp_date } = elements;
      if (elements.quantity > 0 || this.state.appointmentFormData.status === 3) {
        formattedProducts.push({
          value: id,
          key: id,
          productText: (
            <p>
              {name} ({company?.name})
              <p>
                ₹{cost} | Mfg Date: {mfg_date} | Exp Date: {exp_date}
              </p>
            </p>
          ),
          text: `${name}(${company?.name})`,
          ...elements,
        });
      }
    });
    return formattedProducts;
  };

  staffDataFormat = (staff) => {
    let formattedStaff = [];
    staff.forEach((elements) => {
      const { id, user } = elements;
      formattedStaff.push({
        value: id,
        key: id,
        icon: elements.gender !== "O" ? (elements.gender === "F" ? "female" : "male") : "",
        text: user.name,
        ...elements,
      });
    });
    return formattedStaff;
  };

  campaignDataFormat = (campaign) => {
    let formattedCampaign = [
      {
        value: null,
        key: 0,
        text: `Remove Discount`,
      },
    ];
    campaign.forEach((elements) => {
      const { id, name, description, status } = elements;
      if (status) {
        formattedCampaign.push({
          value: id,
          key: id,
          text: `${name} | ${description}`,
          // ...elements
        });
      }
    });
    return formattedCampaign;
  };

  membershipDataFormat = (memberships) => {
    let formattedMemberships = [
      {
        value: null,
        key: 0,
        text: `Remove Membership`,
      },
    ];
    if (memberships.results?.length)
      memberships.results.forEach((elements) => {
        const { id, name, description } = elements;
        formattedMemberships.push({
          value: id,
          key: id,
          text: `${name} | ${description}`,
          org_value: elements,
        });
      });
    return formattedMemberships;
  };

  appointmentFormFormat = (appointment_Form, appointmentFormData) => {
    let { services, products, staff, campaign, memberships, labels } = this.props;

    //if the appointment is completed/hidden then it must show all the services(deleted also) and products(deleted also) assigned to it in appointment form
    // if (status === 3 || status === 5) {
    //   services = appointmentFormData.required_services.map((required_service) => required_service.service);
    //   services = this.servicesDataFormat(services);
    //   products = appointmentFormData.required_products.map((required_product) => required_product.product);
    //   products = this.productsDataFormat(products);
    // } else {
    // }
    services = this.servicesDataFormat(services);
    products = this.productsDataFormat(products);
    staff = this.staffDataFormat(staff);
    campaign = this.campaignDataFormat(campaign);
    memberships = this.membershipDataFormat(memberships);
    let formattedAppointmentForm = [];
    if (appointment_Form.length)
      appointment_Form.forEach((elements) => {
        if (elements.type === "services") {
          formattedAppointmentForm.push({
            ...elements,
            options: {
              services,
              staff,
              products,
            },
          });
        } else if (elements.type === "products") {
          formattedAppointmentForm.push({
            ...elements,
            options: {
              products,
              staff,
            },
          });
        } else if (elements.type === "dropdown" && elements.label === "discount") {
          formattedAppointmentForm.push({
            ...elements,
            options: campaign,
          });
        } else if (elements.type === "dropdown" && elements.label === "membership") {
          formattedAppointmentForm.push({
            ...elements,
            options: memberships,
          });
        } else if (elements.type === "dropdown" && elements.field === "affiliated_staff") {
          formattedAppointmentForm.push({
            ...elements,
            options: staff,
          });
        } else {
          formattedAppointmentForm.push(elements);
        }
      });
    return formattedAppointmentForm;
  };

  renderEventContent(eventInfo) {
    // TODO add the service statuses here
    let { status, services } = eventInfo.event.extendedProps;

    if (status === 3 || status === 5) {
      services = services || [];
    } else {
      services = services.filter((service) => service.status);
    }

    return (
      <div className="event">
        <b className="title">{eventInfo.event.title}</b>
        <span>{eventInfo.timeText}</span>
        <div>
          {services.map((service, index) => {
            const formattedTimeByUnit = formatTimeByUnit(service.duration, service.time_unit);
            const combinedTimeAndUnit = combineTimeAndUnit(formattedTimeByUnit, service.time_unit);

            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "5px",
                  padding: "5px 0px",
                }}
              >
                <div style={{ flex: 10 }}>
                  <div style={{ wordBreak: "break-all" }}>{service.name}</div>
                  <div>{`${combinedTimeAndUnit} | ${service.cost}/-`}</div>
                </div>
                <Icon
                  circular
                  inverted
                  size={"small"}
                  color={service.status === 1 ? "blue" : service.status === 2 ? "orange" : "green"}
                  name={service.status === 1 ? "play" : service.status === 2 ? "clock" : "check"}
                  title={service.status === 1 ? "Start Service" : service.status === 2 ? "In Progress" : "Done"}
                />
                <br />
              </div>
            );
          })}
        </div>
        {eventInfo.view.type === "listWeek" && (
          <div style={{ display: "flex" }}>
            {eventInfo.event.extendedProps.services[0]?.staffs.map((staff, index) => {
              return (
                <div key={index} style={{ marginLeft: "3px" }}>
                  {staff.name},
                </div>
              );
            })}
          </div>
        )}
        <div className="created-by">
          <span>
            <Translate> Created By </Translate> {eventInfo.event.extendedProps.created_by}
          </span>
        </div>
      </div>
    );
  }

  eventClick = async (e) => {
    // fetch appointment data by id and format here
    if (e && e.event && e.event.extendedProps && e.event.extendedProps.originalData) {
      const { data: appointmentData, error } = await fetchAppointmentById(e.event.extendedProps.originalData?.id);

      if (error) {
        if (error.response && error.response.status === 401) {
          this.props.authenticationLogout();
        }
        return false;
      }

      // data formatting
      const minimum_slot_duration = this.props.branch?.minimum_slot_duration || "20";
      const appointmentFormData = this.selectedAppointmentDataFormat(appointmentData, minimum_slot_duration);

      if (
        appointmentFormData &&
        appointmentFormData["memberships"] &&
        Array.isArray(appointmentFormData["memberships"])
      ) {
        appointmentFormData["membership"] = appointmentFormData["memberships"][0];
      }
      const canDeleteAppointment = !(
        appointmentFormData.appointment_discount.length ||
        appointmentFormData.membership ||
        appointmentFormData.discount_id ||
        appointmentFormData.membership_info.length !== 0
      );
      this.setState({
        addAppointment: true,
        edit: true,
        appointmentFormData,
        selectedStaff:
          e.event?._def?.resourceIds?.length > 0 && e.event?._def?.resourceIds[0] !== "-1"
            ? parseInt(e.event?._def?.resourceIds[0])
            : null,
        enableDeleteBtn: true,
      });
    }
  };

  prepareAppointmentDataForAPI = (status, rawdata, checkedStaff = false) => {
    let data = JSON.parse(JSON.stringify(rawdata));
    let formattedAppointmentData = {};
    //Todo: Check if this functionality is required anymore
    // if (status === 3) {
    //   if (!checkedStaff) {
    //     function hasNull({ staffs }) {
    //       return staffs && staffs.length === 0;
    //     }
    //     if (data["required_services"] && data["required_services"].some(hasNull)) {
    //       this.setState({
    //         staffMissing: true,
    //         updateAppointmentPaymentModeData: data,
    //         updateAppointmentPaymentModeStatus: status,
    //       });
    //       return;
    //     }
    //   } else {
    //     this.setState({
    //       staffMissing: false,
    //     });
    //   }
    // }
    data["status"] = status;
    if (data["payment_mode"] === true) {
      data["payment_mode"] = null;
    }
    for (let d in data) {
      if (d.includes(".")) {
        const key = d.split(".");
        if (formattedAppointmentData.hasOwnProperty(key[0])) {
          formattedAppointmentData[key[0]][key[1]] = data[d];
        } else {
          formattedAppointmentData[key[0]] = {
            [key[1]]: data[d],
          };
        }
        if (!data[d]) {
          delete formattedAppointmentData[key[0]][key[1]];
        }
      } else {
        if (d === "required_services") {
          if (
            data[d] &&
            data[d].length === 1 &&
            (data[d][0].service_id === null || data[d][0].service_id === undefined)
          ) {
            formattedAppointmentData["services_list"] = [];
          } else if (data[d] && data[d].length) {
            formattedAppointmentData["services_list"] = [...Array.from(data[d])].map((elements) => {
              if ("quantity" in elements && elements["quantity"] === "") {
                elements["quantity"] = null;
              }
              if ("checked" in elements) {
                delete elements["checked"];
              }
              if ("original_cost" in elements) {
                delete elements["original_cost"];
              }
              if ("discount_type" in elements) {
                if (!elements["discount_type_value"]) {
                  elements["discount_type"] = null;
                  elements["discount_type_value"] = null;
                }
              }

              if ("staffs" in elements) {
                elements["staffs"] = [...Array.from(elements["staffs"])].map((ele) => ({
                  staff_id: ele.staff_id || ele?.id,
                  commission: ele.commission || null,
                  duration: ele.duration || null,
                  revenue_percentage: ele.revenue_percentage,
                }));
                elements["staffs"] = elements["staffs"].filter((ele) => ele.staff_id !== undefined);
              }

              return {
                status: 1,
                ...elements,
              };
            });
          }
        } else if (d === "required_products") {
          if (
            data[d] &&
            data[d].length === 1 &&
            (data[d][0].product_id === null || data[d][0].product_id === undefined)
          ) {
            formattedAppointmentData["products_list"] = [];
          } else {
            formattedAppointmentData["products_list"] = data[d];
            let formattedAppointmentProduct = [];
            formattedAppointmentData["products_list"].forEach((elements) => {
              if ("quantity" in elements && elements["quantity"] === "") {
                elements["quantity"] = null;
              }
              if ("checked" in elements) {
                delete elements["checked"];
              }
              if ("original_cost" in elements) {
                delete elements["original_cost"];
              }
              if ("discount_type" in elements) {
                if (!elements["discount_type_value"]) {
                  elements["discount_type"] = null;
                  elements["discount_type_value"] = null;
                }
              }
              formattedAppointmentProduct.push({
                status: 1,
                ...elements,
              });
            });
            formattedAppointmentData["products_list"] = formattedAppointmentProduct;
          }
        } else {
          formattedAppointmentData[d] = data[d];
        }
      }
    }
    if (!formattedAppointmentData.customer.email) {
      delete formattedAppointmentData.customer.email;
    }

    if ("membership" in formattedAppointmentData) {
      formattedAppointmentData.memberships = formattedAppointmentData.membership
        ? [formattedAppointmentData.membership]
        : [];

      delete formattedAppointmentData.membership;
    }
    if ("affiliated_staff" in formattedAppointmentData) {
      formattedAppointmentData.membership_staff = formattedAppointmentData.affiliated_staff;
    }
    if (!formattedAppointmentData.send_sms) {
      formattedAppointmentData.send_sms = false;
    }
    return formattedAppointmentData;
  };

  //formatting each service duration into min according  to time_unit
  formatRequiredServicesDurationIntoMin = (required_services) => {
    const _requiredServices = [];

    for (let service of required_services) {
      const formattedDuration = formatTimeIntoMin(service.duration, service.time_unit);

      if (formattedDuration.error) {
        return {
          error: true,
          res: formattedDuration.message,
        };
      }
      _requiredServices.push({
        ...service,
        duration: formattedDuration.res,
      });
    }

    return {
      error: false,
      res: _requiredServices,
    };
  };

  deleteAppointment = (formattedAppointmentData, startStr, endStr, nextStage) => {
    this.props
      .appointmentUpdate(formattedAppointmentData?.id, formattedAppointmentData, startStr, endStr, "DELETE", nextStage)
      .then(() => {
        this.setState({
          addAppointment: false,
          isActionButtonsDisabled: false,
          selectedStaff: null,
          appointmentFormData: {},
          appointmentCreationErrorMsgs: [],
          appointmentInvoice: null,
          confirmDelete: false,
        });
        this.props.appointmentHideInvoice();
      });
  };

  updateAppointment = async (formStatus, data, checkedStaff = false, nextStage = false, isComplete = false) => {
    //filtering blank rows
    if ("required_services" in data && data.required_services.length) {
      data.required_services = data.required_services.filter((service) => service.service_id);
    }
    if ("required_products" in data && data.required_products.length) {
      data.required_products = data.required_products.filter((product) => product.product_id);
    }

    let exitfunc = false;

    // validating quantity and time for service
    let isServiceTimeNotValid = false;
    let isServiceQuantityNotValid;
    let isProductQuantityNotValid = false;

    if (data.required_services?.length) {
      data.required_services.some((service) => {
        if (!service.duration || parseInt(service.duration) <= 0) {
          isServiceTimeNotValid = true;
          return true;
        }
        if (!service.quantity || parseInt(service.quantity) <= 0) {
          isServiceQuantityNotValid = true;
          return true;
        }
        return false;
      });
    }
    if (data.required_products?.length) {
      isProductQuantityNotValid = data.required_products.some((product) => {
        return !product.quantity || parseInt(product.quantity) <= 0;
      });
    }

    if (isServiceTimeNotValid) {
      this.setState({
        isActionButtonsDisabled: false,
        appointmentFieldError: {
          isError: true,
          message: "Service duration is not valid",
        },
      });
      exitfunc = true;
      return;
    }

    if (isServiceQuantityNotValid || isProductQuantityNotValid) {
      this.setState({
        isActionButtonsDisabled: false,
        appointmentFieldError: {
          isError: true,
          message: "Quantity can not be less than 1",
        },
      });
      exitfunc = true;
      return;
    }

    if (!isComplete) {
      if ("required_services" in data && data.required_services.length) {
        const _requiredServices = this.formatRequiredServicesDurationIntoMin(data.required_services);

        if (_requiredServices.error) {
          this.setState({
            isActionButtonsDisabled: false,
            appointmentFieldError: {
              isError: true,
              message: _requiredServices.res,
            },
          });
          exitfunc = true;
          return;
        }
        exitfunc = false;
        this.setState({
          appointmentFieldError: {
            isError: false,
            message: "",
          },
        });
        data.required_services = _requiredServices.res;
      }
      if (exitfunc) return;
    }

    let formattedAppointmentData = this.prepareAppointmentDataForAPI(formStatus, data, checkedStaff);

    if (formattedAppointmentData) {
      const { startStr, endStr } = this.state;
      if (formStatus === "delete") {
        this.setState({
          isActionButtonsDisabled: true,
        });
        const { response } = await getAppoitmentDeleteData(formattedAppointmentData?.id);

        if (response.data.data === null) {
          this.deleteAppointment(formattedAppointmentData, startStr, endStr, nextStage);
          return;
        } else {
          this.setState({
            openAppointmentDeleteModal: true,
            deleteAppointmentsAffected: response.data.data,
            isActionButtonsDisabled: false,
          });
        }
      } else if (formStatus === "delete_confirm") {
        this.deleteAppointment(formattedAppointmentData, startStr, endStr, nextStage);
      } else if (this.state.edit || formStatus === "edit") {
        
        this.props
          .appointmentUpdate(formattedAppointmentData?.id, formattedAppointmentData, startStr, endStr, "PUT", nextStage)
          .then((response) => {
            const { status, message } = response;
            // TODO : Refactor
            if (status === "error") {
              const isOtpError =
                typeof message === "string" &&
                message?.toLowerCase() === "invalid/expired otp, please generate new otp and try again";
              if (message) {
                this.setState((prevState) => ({
                  appointmentCreationErrorMsgs: message,
                  appointmentFormData: { ...prevState.appointmentFormData, ...data },
                }));
              }
              if (isOtpError) {
                this.setState({
                  showOtpForm: { val: true },
                });
              } else {
                if (this.state.showOtpForm) {
                  this.setState({
                    showOtpForm: { val: false },
                  });
                }
                if (isComplete) {
                  this.setState({
                    showInvoice: false,
                    showInterimInvoice: false,
                    addAppointment: true,
                  });
                }

                this.props.appointmentHideInvoice();
              }
              this.setState({
                isActionButtonsDisabled: false,
              });

              return;
            }

            if (status === "success") {
              if (isComplete) {
                this.setState({
                  showInvoice: true,
                  showInterimInvoice: false,
                  toCompleteForm: null,
                  isActionButtonsDisabled: false,
                  updateAppointmentPaymentMode: false,
                });
              }
              this.setState({
                addAppointment: false,
                selectedStaff: null,
                showOtpForm: { val: false },
                // appointmentFormData: {},
                isActionButtonsDisabled: false,
                appointmentCreationErrorMsgs: [],
              });
              return;
            }
          });
      } else {
        this.props
          .appointmentsPostData(formattedAppointmentData, startStr, endStr, nextStage)
          .then(({ status, message, info }) => {
            if (status === "error") {
              if (message) {
                this.setState((prevState) => ({
                  appointmentCreationErrorMsgs: message,
                  isActionButtonsDisabled: false,
                  appointmentFormData: { ...prevState.appointmentFormData, ...data, status: 10 }, // Status 10 so that it enters default in switch case
                }));
              }
              return;
            }
            if (status === "success") {
              if (nextStage) {
                this.setState({
                  toCompleteForm: {
                    ...this.state.toCompleteForm,
                    id: info?.id,
                    "customer.id": info?.customer?.id || null,
                  },
                  appointmentFormData: { ...info },
                });
              } else {
                this.setState((prevState) => ({
                  appointmentFormData: {
                    ...prevState.appointmentFormData,
                    ...data,
                    id: info?.id,
                    status: 1.1, //create status but need to show status 6 buttons
                    "customer.id": info?.customer?.id || null,
                  },
                }));
              }
              if (formStatus !== 0) {
                this.setState((prevState) => ({
                  updateAppointmentPaymentMode: false,
                  appointmentCreationErrorMsgs: [],
                  edit: true,
                  appointmentFormData: {
                    ...prevState.appointmentFormData,
                    ...data,
                    id: info?.id,
                    status: 1.1, //create status but need to show status 6 buttons
                    "customer.id": info?.customer?.id || null,
                  },
                }));
              } else {
                // an enquiry & we're closing the modal
                this.setState({
                  addAppointment: false,
                  selectedStaff: null,
                  appointmentFormData: {},
                  appointmentCreationErrorMsgs: [],
                });
              }
            } else {
              const formData = {
                resetStatus: true,
                _datetime: formattedAppointmentData.datetime,
                customerDetail: formattedAppointmentData.customer,
                serviceList: formattedAppointmentData.services_list,
                check_availability: formattedAppointmentData?.check_availability,
              };

              this.setState({
                appointmentCreationErrorMsgs: message,
                appointmentFormData: formData,
              });
            }

            this.setState({
              isActionButtonsDisabled: false,
            });
            return;
          });
      }
    }
  };

  appointmentAction(edit, form, appointmentFormData, formValidStatus) {
    const { formatted_component_permissions } = this.props.aclUserPermissions;
    const { isActionButtonsDisabled } = this.state;

    let valid = formValidStatus;

    if (
      !formatted_component_permissions.includes("backdated_bill") &&
      form?.datetime &&
      Moment(form?.datetime).isBefore(Moment(), "day")
    ) {
      valid = false;
      if (!this.state.accessErrorMessage)
        this.setState({
          accessErrorMessage: "You don't have access to create backdated bills",
        });
    } else if (this.state.accessErrorMessage) {
      this.setState({
        accessErrorMessage: "",
      });
    }

    const enableDelete =
      (formatted_component_permissions.includes("admin_access") ||
        formatted_component_permissions.includes("owner_access")) &&
      formatted_component_permissions.includes("backdated_bill");

    let status;
    if (appointmentFormData?.status || appointmentFormData?.status === 0) {
      status = appointmentFormData?.status;
    } else {
      status = form?.status;
    }

    // this is for past appointments (if condition becomes true)
    if (form.datetime && Moment(form.datetime).isBefore(Moment(), "day")) {
      if (!("status" in form)) {
        return (
          <div className="actions flex column end">
            <div>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form);
                }}
              >
                <Translate>Create</Translate>
              </button>
              <button
                className="ui button"
                onClick={() =>
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  })
                }
              >
                <Translate>Close</Translate>
              </button>
            </div>
          </div>
        );
      } else if (status && (status === 3 || status === 5)) {
        const { startStr, endStr } = this.state;
        return (
          <div className="actions">
            <button
              className="ui button"
              onClick={() => {
                this.setState({
                  addAppointment: false,
                  appointmentFormData: {},
                  appointmentCreationErrorMsgs: [],
                });
                this.props.appointmentHide(appointmentFormData?.id, startStr, endStr, status === 3);
              }}
              disabled={!valid}
            >
              <Translate>{status === 3 ? "Hide" : "Show"}</Translate>
            </button>
            <button
              className="ui button"
              onClick={() => {
                this.props.appointmentShowInvoice(appointmentFormData);
              }}
              disabled={isActionButtonsDisabled}
            >
              <Translate>Invoice</Translate>
            </button>
            <button
              className="ui button"
              onClick={() =>
                this.setState({
                  addAppointment: false,
                  appointmentFormData: {},
                  appointmentCreationErrorMsgs: [],
                })
              }
              disabled={!valid || isActionButtonsDisabled}
            >
              <Translate>Close</Translate>
            </button>
            <button
              className="ui button"
              onClick={() => {
                this.setState({
                  isActionButtonsDisabled: true,
                });
                edit(status, form);
              }}
              disabled={!enableDelete || isActionButtonsDisabled}
            >
              <Translate>Edit</Translate>
            </button>
            <button
              className="ui button"
              onClick={() => {
                this.setState({
                  confirmDelete: true,
                });
              }}
              disabled={!enableDelete || isActionButtonsDisabled}
            >
              <Translate>Delete</Translate>
            </button>
          </div>
        );
      } else if (status && status === 6) {
        return (
          <div className="actions">
            <button
              className="ui button"
              onClick={() =>
                this.setState({
                  addAppointment: false,
                  appointmentFormData: {},
                  appointmentCreationErrorMsgs: [],
                })
              }
            >
              <Translate>Close</Translate>
            </button>
          </div>
        );
      } else {
        return (
          <div className="actions">
            <button
              className="ui button"
              onClick={() =>
                this.setState({
                  addAppointment: false,
                  appointmentFormData: {},
                  appointmentCreationErrorMsgs: [],
                })
              }
            >
              <Translate>Close</Translate>
            </button>
            <button
              className="ui button"
              disabled={!valid || isActionButtonsDisabled}
              onClick={() => {
                this.setState({
                  showInvoice: true,
                  showInterimInvoice: true,
                  toCompleteForm: form,
                  isActionButtonsDisabled: true,
                });
                edit(1, form, true);
              }}
            >
              <Translate>Preview & Complete</Translate>
            </button>
          </div>
        );
      }
    } else {
      switch (status) {
        case 1: {
          return (
            <div className="actions">
              <button
                className="ui button"
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(4, form);
                }}
                disabled={isActionButtonsDisabled}
              >
                <Translate>Cancel</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(0, form);
                }}
              >
                <Translate>Enquiry?</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(status || 1, form);
                }}
              >
                <Translate>Update</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                    showInterimInvoice: true,
                    toCompleteForm: form,
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form, true);
                }}
              >
                <Translate>Preview & Complete</Translate>
              </button>
            </div>
          );
        }
        case 1.1: {
          return (
            <div className="actions">
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form);
                }}
              >
                <Translate>Update</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                    showInterimInvoice: true,
                    toCompleteForm: form,
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form, true);
                }}
              >
                <Translate>Preview & Complete</Translate>
              </button>
              <button
                className="ui button"
                onClick={() =>
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  })
                }
              >
                <Translate>Close</Translate>
              </button>
            </div>
          );
        }
        case 0: {
          return (
            <div className="actions">
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form);
                }}
              >
                Create
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                    showInterimInvoice: true,
                    toCompleteForm: form,
                    isActionButtonsDisabled: true,
                  });
                  edit(status ?? 1, form, true);
                }}
              >
                <Translate>Preview & Complete</Translate>
              </button>
            </div>
          );
        }
        case 4: {
          return (
            <div className="actions">
              <button
                className="ui button"
                onClick={() =>
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  })
                }
              >
                <Translate>Close</Translate>
              </button>
            </div>
          );
        }
        case 3:
        case 5: {
          const { startStr, endStr } = this.state;
          return (
            <div className="actions">
              <button
                className="ui button"
                onClick={() => {
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  });
                  this.props.appointmentHide(appointmentFormData?.id, startStr, endStr, status === 3);
                }}
              >
                <Translate>{status === 3 ? "Hide" : "Show"}</Translate>
              </button>
              <button
                className="ui button"
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                  });
                  this.props.appointmentShowInvoice(appointmentFormData);
                }}
              >
                <Translate>Invoice</Translate>
              </button>
              <button
                className="ui button"
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(status, form);
                }}
                disabled={!enableDelete || isActionButtonsDisabled}
              >
                <Translate>Edit</Translate>
              </button>
              <button
                className="ui button"
                onClick={() => {
                  this.setState({
                    confirmDelete: true,
                  });
                }}
                disabled={!enableDelete || isActionButtonsDisabled}
              >
                <Translate>Delete</Translate>
              </button>
              <button
                className="ui button"
                onClick={() =>
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  })
                }
              >
                <Translate>Close</Translate>
              </button>
            </div>
          );
        }
        case 2: {
          return (
            <div className="actions">
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                    showInterimInvoice: true,
                    toCompleteForm: form,
                    isActionButtonsDisabled: true,
                  });
                  edit(status || 1, form, true);
                }}
              >
                <Translate>Preview & Complete</Translate>
              </button>
            </div>
          );
        }
        case 6: {
          return (
            <div className="actions">
              {/* <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form);
                }}
              >
                <Translate>Update</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                    showInterimInvoice: true,
                    toCompleteForm: form,
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form, true);
                }}
              >
                <Translate>Preview & Complete</Translate>
              </button> */}
              <button
                className="ui button"
                onClick={() =>
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  })
                }
              >
                <Translate>Close</Translate>
              </button>
            </div>
          );
        }
        default: {
          return (
            <div className="actions">
              {form?.id && (
                <button
                  className="ui button"
                  disabled={!valid || isActionButtonsDisabled}
                  onClick={() => {
                    this.setState({
                      showInvoice: true,
                      showInterimInvoice: true,
                      toCompleteForm: form,
                      isActionButtonsDisabled: true,
                    });
                    this.props.appointmentShowInvoice(appointmentFormData);
                  }}
                >
                  <Translate>Preview Invoice</Translate>
                </button>
              )}

              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(0, form);
                }}
              >
                <Translate>Enquiry?</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form);
                }}
              >
                <Translate>Create</Translate>
              </button>
              <button
                className="ui button"
                disabled={!valid || isActionButtonsDisabled}
                onClick={() => {
                  this.setState({
                    showInvoice: true,
                    showInterimInvoice: true,
                    toCompleteForm: form,
                    isActionButtonsDisabled: true,
                  });
                  edit(1, form, true);
                }}
              >
                <Translate>Preview & complete</Translate>
              </button>
              <button
                className="ui button"
                onClick={() =>
                  this.setState({
                    addAppointment: false,
                    appointmentFormData: {},
                    appointmentCreationErrorMsgs: [],
                  })
                }
              >
                <Translate>Close</Translate>
              </button>
            </div>
          );
        }
      }
    }
  }

  updatePayment(appointmentFormData, status, paymentMode) {
    if (paymentMode) {
      appointmentFormData["payment_mode"] = paymentMode;
    }
    this.updateAppointment(status, appointmentFormData, true);
  }

  viewCustomerHistory(selectedCustomerData = null) {
    const { viewCustomerHistory } = this.state;

    this.setState({
      viewCustomerHistory: !viewCustomerHistory,
      selectedCustomerData,
    });
  }

  showPaymentDueHistory = (customerID = null) => {
    // if (customerID) {
    //   this.props.fetchPendingHistory(customerID);
    // }
    this.setState((prev) => ({
      paymentDueCustomerId: customerID,
      openPaymentDueHistory: !prev.openPaymentDueHistory,
    }));
  };

  sendMessageFormAction(edit, form, action) {
    return (
      <div>
        <button
          className="ui button"
          onClick={() => {
            const { date } = form;
            if (!date) {
              this.setState({
                sendMessageFormDataError: true,
              });
            } else {
              this.props.sendPromotion({
                template: "COMPLETE",
                value: { date: form.date },
                client: [
                  {
                    name: form["customer.name"],
                    number: form["customer.number"],
                  },
                ],
              });
            }
          }}
        >
          <Translate>Send</Translate>
        </button>
      </div>
    );
  }

  getMeDataForDashboard() {
    const { appointmentAnalytics, expenses, staffAbsents, staff } = this.props;
    let credits = 0,
      debits = 0;
    if (expenses?.aggregated?.today) {
      credits = expenses.aggregated.today.credit;
      debits = expenses.aggregated.today.debit;
    }
    const ABSENTTYPE = {
      0: "Quarter Day",
      1: "Half Day",
      2: "Full Day",
    };
    const absentees = staffAbsents.map((i) => {
      return (
        (staff.filter((s) => s?.id === i.staff_id).map((s) => s.user.name) || "NA") +
        " [" +
        ABSENTTYPE[i.absence_type] +
        "]"
      );
    });

    const temp = [];
    temp.push({
      headLine: "Today's Revenue",
      value: parseFloat(appointmentAnalytics?.[0]?.revenue || 0).toFixed(2),
      name: GreenBag,
    });
    temp.push({
      headLine: "Realised Revenue",
      value: parseFloat(appointmentAnalytics?.[0]?.realised_revenue || 0).toFixed(2),
      name: OrangeBag,
    });
    temp.push({
      headLine: "Net Revenue",
      subheadLine: "(Realised Revenue - Expenses)",
      value: parseFloat((appointmentAnalytics?.[0]?.realised_revenue || 0) - (debits - credits)).toFixed(2),
      name: RedBag,
    });
    temp.push({
      headLine: `Today's ${APPOINTMENT_LABEL}s`,
      value: appointmentAnalytics?.[0]?.total || 0,
      name: CalendarIcon,
    });
    temp.push({
      headLine: "Absent Staffs",
      value: absentees.length,
      name: StaffIcon,
      hover: absentees,
    });
    temp.push({
      headLine: "Credits/Debits",
      value: `${credits}/${debits}`,
      name: CardIcon,
    });

    // const progress
    const progressBar = [];
    progressBar.push({
      name: "Completed",
      value: (appointmentAnalytics && appointmentAnalytics[0] && appointmentAnalytics[0].completed) || 0,
      color: EVENT_COLOR[3],
    });
    progressBar.push({
      name: "Scheduled",
      value: (appointmentAnalytics && appointmentAnalytics[0] && appointmentAnalytics[0].scheduled) || 0,
      color: EVENT_COLOR[1],
    });
    progressBar.push({
      name: "Enquiry",
      value: (appointmentAnalytics && appointmentAnalytics[0] && appointmentAnalytics[0].enquiry) || 0,
      color: EVENT_COLOR[0],
    });
    progressBar.push({
      name: "Cancelled",
      value: (appointmentAnalytics && appointmentAnalytics[0] && appointmentAnalytics[0].cancelled) || 0,
      color: EVENT_COLOR[5],
    });

    const total = (appointmentAnalytics && appointmentAnalytics[0] && appointmentAnalytics[0].total) || 0;

    return { dashboard: temp, progressBar: progressBar, total: total };
  }

  getStylistDataForDashboard() {
    const { user, staff } = this.props;
    const appointments = this.props.appointments || [];

    let account = staff.filter((i) => i.user?.username === user.username);

    if (account.length === 0) {
      return { dashboard: [], progressBar: [], total: 0 };
    }
    account = account[0];
    let todayTotal = {
      services: 0,
      completed: 0,
      value: 0,
      commission: 0,
    };

    appointments.forEach((appointment) => {
      let completedAppointment = 0;

      if ([3, 5].includes(appointment.status)) {
        appointment.required_services.forEach((required_service) => {
          //
          if (required_service?.status !== SERVICE_STATUS["cancelled"]) {
            const { staffs, cost } = required_service;

            // check if this account/staff has served this service
            const idInStaffs = staffs.some((staff) => staff?.id === account?.id);

            if (idInStaffs) {
              completedAppointment = 1;
              //
              todayTotal.services += 1;
              todayTotal.commission += parseFloat(cost);
              todayTotal.value += parseFloat(cost);
            }
          }
        });
      }

      // for each appointment there will be multiple services but appointment count should be one only
      todayTotal.completed += completedAppointment;
    });

    // const staff_service_commission = parseFloat(account["service_commission"]) || 0;

    // if (staff_service_commission) {
    //   todayTotal.commission = (staff_service_commission * todayTotal.commission) / 100;
    //   todayTotal.commission = todayTotal.commission.toFixed(2);
    // } else {
    //   todayTotal.commission = "N/A";
    // }

    return {
      dashboard: [
        {
          headLine: "Total Services",
          value: todayTotal.services,
          name: activeStaff,
        },
        {
          headLine: "Completed Appointments",
          value: todayTotal.completed,
          name: appointmentImg,
        },
        // {
        //   headLine: "Revenue",
        //   value: todayTotal.value,
        //   name: revenue,
        // },
        // {
        //   headLine: "Services Commission",
        //   value: todayTotal.commission,
        //   name: "expenses",
        // },
      ],
      progressBar: [],
      total: 0,
    };
  }

  // for calendar column staff names
  formattedStaffList = (unselected_staff = []) => {
    // filtering according to checked staff
    let columnData = this.state.staffDataForOrdering.filter((singleStaff) => {
      return !unselected_staff.includes(singleStaff?.id);
    });
    columnData = columnData.map((singleStaff, index) => {
      return {
        id: singleStaff?.id,
        title: singleStaff.title,
        value: singleStaff.value,
        order1: index,
        extendedProps: {
          html:
            "gender" in singleStaff && singleStaff?.gender !== "O"
              ? `<i aria-hidden="true" class="${singleStaff.gender === "M" ? "male" : "female"} icon"></i>`
              : "</>",
        },
      };
    });

    return columnData;
  };

  staffListCheckChange = (staffId) => {
    let unselected_staff = JSON.parse(localStorage.getItem("UNSELECTED_STAFF")) || [];
    if (unselected_staff.includes(staffId)) {
      unselected_staff = unselected_staff.filter((item) => item !== staffId);
    } else {
      unselected_staff.push(staffId);
    }
    localStorage.setItem("UNSELECTED_STAFF", JSON.stringify(unselected_staff));
    this.setState({
      unselected_staff,
    });
  };

  staffListUpdate = (source, destination) => {
    // organizing staff list
    const staffList = this.state.staffDataForOrdering;
    const [removed] = staffList.splice(source, 1);
    staffList.splice(destination, 0, removed);

    // storing the staff_id so that can get correct order
    const staff_order = staffList.map((_staff) => _staff?.id);

    localStorage.setItem("STAFF_ORDER", JSON.stringify(staff_order));

    this.setState({
      staffDataForOrdering: staffList,
    });
  };

  setFilterAppointments = (values) => {
    this.setState({ dropdownAppointementFilterLocalValues: values }, () => {
      localStorage.setItem(
        "dropdownAppointmentFilterLocalValues",
        JSON.stringify(this.state.dropdownAppointementFilterLocalValues)
      );
    });
  };
  setCreateMembership = (value) => {
    this.setState({ createMembership: value });
  };

  setMembershipForm = (value) => {
    this.setState({ membershipForm: value });
  };

  showAddService() {
    this.setState({
      showAddService: !this.state.showAddService,
      serviceData: {},
      serviceFieldError: false,
    });
  }

  closeConfirmDeleteModal() {
    this.setState({
      confirmDelete: !this.state.confirmDelete,
    });
  }

  errorMessage = (message) => {
    return <div style={{ color: "red", padding: "10px 0px" }}>{message}</div>;
  };

  addService(status, service) {
    const { cost, gender, time, time_unit, name } = service;

    //is any field empty validation
    if (!cost || !gender || !time || !name) {
      this.setState({
        serviceFieldError: true,
        serviceFieldErrorMessage: "No Required Field Should Be Empty",
      });
      return false;
    }
    const formattedTimeIntoMin = formatTimeIntoMin(time, time_unit);

    if (formattedTimeIntoMin.error) {
      this.setState({
        serviceFieldError: true,
        serviceFieldErrorMessage: formattedTimeIntoMin.message,
        serviceData: { ...service },
      });
      return false;
    }

    service["status"] = 1;
    service["time"] = formattedTimeIntoMin.res;
    this.props.postServices(service);
    this.showAddService();
  }

  showAddProduct() {
    this.setState({
      showAddProduct: !this.state.showAddProduct,
      productData: {},
    });
  }

  addProduct(status, product) {
    let company_id = null;

    if (product["company.id"]) {
      company_id = product["company.id"];
    } else if (product["company"]) {
      company_id = product["company"]?.id;
    }
    //is any field empty validation
    const { cost, price, name, quantity, exp_date, mfg_date, volume, code } = product;

    if (!company_id || !price || !cost || !name || !quantity || !volume) {
      this.setState({
        productFieldError: true,
        productData: { ...product },
      });
      return false;
    }
    //validating exp_date and mfg_date difference
    if (calcDateDifference(mfg_date, exp_date) <= 0) {
      this.setState({
        productFieldError: false,
        productDateError: true,
        productData: { ...product },
      });
      return false;
    }

    delete product["company.id"];
    this.props.postProducts({
      ...product,
      company: company_id,
      status: 1,
      code: product.code === "" ? null : product.code,
    });
    this.showAddProduct();
  }

  setShowForm = () => {
    this.setState((prevState) => ({
      showCampaign: !prevState.showCampaign,
    }));
  };

  filterListView = (filter, appointments) => {
    const options = {
      keys: ["title"],
      threshold: 0.2,
    };
    const fuse = new Fuse(appointments, options);
    return fuse.search(filter).map(({ item }) => item); //filtering
  };

  updateFilterSearchValue = debounce((value) => {
    this.setState({ filterSearchValue: value });
  }, 300);

  DatePicker = () => {
    const { formatted_component_permissions } = this.props.aclUserPermissions;

    return (
      <Icon
        size="large"
        name="calendar alternate outline"
        className="AppointmentIconImage"
        style={{ marginTop: "4px" }}
      >
        <input
          type="date"
          className="datepicker-input"
          value={this.state.startStr || new Date()}
          required
          onChange={(event) => {
            const startDate = event.target.value;
            const endDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");

            this.setState(
              {
                startStr: startDate,
                endStr: endDate,
              },
              () => {
                this.props.fetchAppointmentsBasicDetails({ start_date: startDate, end_date: endDate });
                if (!formatted_component_permissions.includes("owner_access")) {
                  this.props.fetchAppointmentAnalytics(startDate);
                }
              }
            );

            const calendarApi = this.calendarRef.current.getApi();
            calendarApi.gotoDate(startDate); // call a method on the Calendar object
          }}
        />
      </Icon>
    );
  };

  organizeStaffs = (staff, staff_order) => {
    const staffIdMap = {};
    const reorderedStaff = [];

    staff.forEach((_staff) => {
      staffIdMap[_staff?.id] = _staff;
    });

    // inserting staffs from staff_order first if staff exists
    for (let id of staff_order) {
      if (id in staffIdMap) {
        reorderedStaff.push(staffIdMap[id]);
        staffIdMap[id] = false; // to prevent duplicacy
      }
    }

    // inserting remaining staff
    staff.forEach((_staff) => {
      if (staffIdMap[_staff?.id]) {
        reorderedStaff.push(_staff);
      }
    });

    return reorderedStaff;
  };

  render() {
    const {
      addAppointment,
      appointmentFormData,
      updateAppointmentPaymentMode,
      updateAppointmentPaymentModeData,
      updateAppointmentPaymentModeStatus,
      showAnalytics,
      staffMissing,
      viewCustomerHistory,
      sendMessage,
      sendMessageFormData,
      selectedStaff,
      filterSideNav,
      unselected_staff,
      staff_order,
      showInterimInvoice,
      showInvoice,
      showCustomerNotes,
      openMembershipHistory,
      selectedMembershipUsageHistory,
      openPaymentDueHistory,
      paymentDueCustomerId,
      appointmentCreationErrorMsgs,
      createMembership,
      membershipForm,
      serviceData,
      showAddService,
      showAddProduct,
      productData,
      showCampaign,
      viewAnswerDetails,
      directDiscountAlert,
      extraDiscount,
      staffDataForOrdering,
      isActionButtonsDisabled,
      openAppointmentDeleteModal,
      deleteAppointmentsAffected,
      confirmDelete,
    } = this.state;
    let {
      aclUserPermissions,
      appointments,
      appointmentInvoice,
      branch,
      clientMemberships,
      promotion,
      clientMembershipReset,
      companies,
      resetAppointmentAnswers,
      productUsageModalValue,
      productUsageReset,
      productUsageModalAction,
      directDiscount,
      isQuickBook,
    } = this.props;

    const { formatted_component_permissions } = aclUserPermissions;
    const branchStartTime = branch?.start_time || "08:00:00";
    const branchEndTime = branch?.end_time || "23:30:00";
    const minimum_slot_duration = branch?.minimum_slot_duration || "20";
    const appointmentError = appointments.error;

    //dropdown options
    const dropdownAppointmentFilterOptions = mockData.dropdownFilters;

    appointments = this.appointmentsDataFormat(appointments, minimum_slot_duration);
    let totalFound = appointments.length;
    //filtering appoitments according searched name or phone number
    if (this.state.filterSearchValue) {
      appointments = this.filterListView(this.state.filterSearchValue, appointments);
      totalFound = appointments.length;
    }

    const dashboard_dataset = formatted_component_permissions.includes("owner_access")
      ? this.getMeDataForDashboard()
      : this.getStylistDataForDashboard();

    const appointmentForm = isQuickBook ? mockData.quick_appointment_form : mockData.appointment_form;

    return (
      <div className="appointments">
        {showCustomerNotes && (
          <Modal
            zIndex={10}
            close={() => {
              this.setState({
                showCustomerNotes: null,
              });
            }}
          >
            <CustomerNotes customer_id={showCustomerNotes} />
          </Modal>
        )}
        {viewAnswerDetails && (
          <Modal
            zIndex={10}
            close={() => {
              this.setState({
                viewAnswerDetails: null,
              });
              resetAppointmentAnswers();
            }}
          >
            <AppointmentAnswerDetails appointment_id={viewAnswerDetails} />
          </Modal>
        )}
        {openAppointmentDeleteModal && (
          <Modal
            zIndex={10}
            close={() => {
              this.setState({
                openAppointmentDeleteModal: false,
                deleteAppointmentsAffected: [],
              });
            }}
          >
            <AppointmentDeleteModal
              data={deleteAppointmentsAffected}
              onConfirm={() => {
                this.setState(
                  {
                    openAppointmentDeleteModal: false,
                    deleteAppointmentsAffected: [],
                  },
                  () => {
                    this.updateAppointment("delete_confirm", appointmentFormData, false, null, false);
                  }
                );
              }}
              onSkip={() => {
                this.setState({
                  openAppointmentDeleteModal: false,
                  deleteAppointmentsAffected: [],
                });
              }}
              onViewInvoice={({ id, customer }) => {
                this.props.appointmentHideInvoice();
                this.setState(
                  {
                    openAppointmentDeleteModal: false,
                    deleteAppointmentsAffected: [],
                    showInvoice: true,
                  },
                  () => {
                    const payload = {
                      id: id,
                      customer: {
                        id: customer,
                      },
                    };
                    this.props.appointmentShowInvoice(payload);
                  }
                );
              }}
            />
          </Modal>
        )}
        {updateAppointmentPaymentMode && (
          <PaymentMode
            appointment={updateAppointmentPaymentModeData}
            status={updateAppointmentPaymentModeStatus}
            onChange={(updateAppointmentPaymentModeData, updateAppointmentPaymentModeStatus, paymentMode) =>
              this.updatePayment(updateAppointmentPaymentModeData, updateAppointmentPaymentModeStatus, paymentMode)
            }
          />
        )}
        {staffMissing && (
          <Modal zIndex={2}>
            <div className="flex column">
              <Translate>{`One or more services have yet to be assigned a staff. Staff assignment is only possible before the
              ${APPOINTMENT_LABEL.toLowerCase()} is completed.`}</Translate>
              <div className="width-100 flex" style={{ justifyContent: "flex-end" }}>
                <button
                  className="ui button"
                  onClick={() =>
                    this.updateAppointment(updateAppointmentPaymentModeStatus, updateAppointmentPaymentModeData, true)
                  }
                >
                  <Translate>Ignore</Translate>
                </button>
                <button
                  className="ui button warning"
                  onClick={() => {
                    this.setState({ staffMissing: false });
                  }}
                >
                  <Translate>Appoint now</Translate>
                </button>
              </div>
            </div>
          </Modal>
        )}
        <ClientMembershipHistory
          viewMembership={openMembershipHistory}
          clientMemberships={clientMemberships}
          closeMemberhship={() => {
            clientMembershipReset();
            this.setState({
              openMembershipHistory: false,
              selectedMembershipUsageHistory: {
                hasSelected: false,
                selection: [],
                membership: {},
              },
            });
          }}
        />
        {!!createMembership && (
          <MembershipSideNav
            createMembership={createMembership}
            setCreateMembership={this.setCreateMembership}
            membershipForm={membershipForm}
            membership_id={this.state.membership_id}
            inAppointment={true}
            setMembershipForm={this.setMembershipForm}
          />
        )}
        <CampaignSideNav showForm={showCampaign} setShowForm={this.setShowForm} inAppointment={true} />
        {appointmentInvoice &&
          showInvoice &&
          (showInterimInvoice ? (
            <Modal
              zIndex={1}
              close={() => {
                if (
                  (extraDiscount.length && !directDiscount.length) ||
                  (directDiscount.length && extraDiscount !== directDiscount[0].discount.toString())
                ) {
                  this.setState({ directDiscountAlert: true });
                  return;
                }
                this.props.resetAddedDirectDiscount();
                this.setState({ showInvoice: false, showInterimInvoice: false, toCompleteForm: null });
                this.props.appointmentHideInvoice();
              }}
            >
              <GenerateInvoice
                showOtpForm={this.state.showOtpForm}
                extraDiscount={extraDiscount}
                setExtraDiscount={(val) => this.setState({ extraDiscount: val })}
                isPreview
                data={appointmentInvoice}
                branch={branch}
                complete={({ customer_otp }) => {
                  const { toCompleteForm } = this.state;
                  if (customer_otp) {
                    this.updateAppointment(3, { ...toCompleteForm, customer_otp }, null, null, true);
                  } else {
                    this.updateAppointment(3, toCompleteForm, null, null, true);
                  }
                }}
              />
            </Modal>
          ) : (
            <Modal zIndex={1} close={() => this.props.appointmentHideInvoice()}>
              <GenerateInvoice
                data={appointmentInvoice}
                branch={branch}
                isPreview={false}
                fetchAppointments={() => {
                  const { startStr, endStr } = this.state;
                  this.props.fetchAppointmentsBasicDetails({
                    start_date: startStr.split("T")[0],
                    end_date: endStr.split("T")[0],
                  });
                }}
                isActionButtonsDisabled={isActionButtonsDisabled}
                showDeleteAppointment={true}
                deleteAppointment={(data) => {
                  this.setState({ confirmDelete: true });
                }}
              />
            </Modal>
          ))}
        {directDiscountAlert && (
          <Modal zIndex={1} close={() => this.setState({ directDiscountAlert: false })}>
            <div className="direct-discount-alert-container">
              <p>
                <Translate>
                  {directDiscount.length ? "Edited" : "Added"} additional discount will be discarded if you close the
                  appointment without click on apply.
                </Translate>
              </p>
              <div className="direct-discount-btn-container">
                <Button
                  onClick={() => {
                    this.setState({ showInterimInvoice: false, toCompleteForm: null, directDiscountAlert: false });
                    this.props.appointmentHideInvoice();
                    this.props.resetAddedDirectDiscount();
                  }}
                >
                  <Translate>Continue</Translate>
                </Button>
                <Button primary onClick={() => this.setState({ directDiscountAlert: false })}>
                  <Translate>Cancel</Translate>
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {viewCustomerHistory && (
          <Modal zIndex={1} close={() => this.viewCustomerHistory()}>
            <Customer360
              customerId={this.state.selectedCustomerData.id}
              customerData={this.state.selectedCustomerData}
            />
          </Modal>
        )}

        {openPaymentDueHistory && (
          <Modal zIndex={1} close={this.showPaymentDueHistory}>
            <PendingHistory
              fetchAppointments={() => {
                const { startStr, endStr } = this.state;
                this.props.fetchAppointmentsBasicDetails({
                  start_date: startStr.split("T")[0],
                  end_date: endStr.split("T")[0],
                });
              }}
            />
          </Modal>
        )}
        {showAddService && (
          <Modal zIndex={1} close={() => this.showAddService()}>
            <Form
              inputs={mockData.service_form}
              data={serviceData}
              edit={(status, data) => this.addService(status, data)}
              // update={!!serviceData}
              showModal={() => this.showModal}
            />
            {this.state.serviceFieldError && this.errorMessage(this.state.serviceFieldErrorMessage)}
          </Modal>
        )}
        {confirmDelete && (
          <Modal zIndex={1} onClose={() => this.closeConfirmDeleteModal()}>
            <div className="appointmentConfirmDeleteContainer">
              <div>
                <p>
                  <Translate>Are you sure you want to delete the Appointment ?</Translate>
                </p>
              </div>
              <div className="buttonContainer">
                <div>
                  <Button
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      this.closeConfirmDeleteModal();
                    }}
                  >
                    <Translate>No</Translate>
                  </Button>
                  <Button
                    primary
                    disabled={isActionButtonsDisabled}
                    onClick={() => {
                      this.setState({
                        isActionButtonsDisabled: true,
                      });
                      this.updateAppointment("delete", appointmentFormData);
                    }}
                  >
                    <Translate>Yes</Translate>
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {showAddProduct && (
          <Modal zIndex={1} close={() => this.showAddProduct()}>
            <Form
              inputs={this.productsFormFormat(mockData.product_form, companies)}
              data={productData}
              edit={(status, data) => this.addProduct(status, data)}
            />
            {this.state.productFieldError && this.errorMessage("No Required Field Should Be Empty Or Invalid")}
            {this.state.productDateError && this.errorMessage("Expiry Date Should Be Greater Than Manufacturing Date")}
          </Modal>
        )}
        {addAppointment && (
          <Modal
            close={() => {
              this.setState({
                addAppointment: false,
                selectedStaff: null,
                appointmentFormData: {},
                appointmentCreationErrorMsgs: [],
              });
              this.props.resetAppointmentError();
              const { startStr, endStr } = this.state;
              this.props.fetchAppointmentsBasicDetails({
                start_date: startStr,
                end_date: endStr,
              });
              this.props.customerLabelReset();
              this.props.selectedCustomerAppointmentForm();
            }}
          >
            <Form
              isAppointmentForm={true}
              inputs={this.appointmentFormFormat(appointmentForm, appointmentFormData)}
              data={appointmentFormData}
              edit={(status, data, nextStage = null) => this.updateAppointment(status, data, false, nextStage, false)}
              action={(edit, form, valid) => this.appointmentAction(edit, form, appointmentFormData, valid)}
              viewCustomerHistory={(selectedCustomerData) => this.viewCustomerHistory(selectedCustomerData)}
              error={appointmentError}
              selectedStaff={selectedStaff}
              viewExtraModal={this.showMembershipHistory}
              showModal={this.showModal}
              viewCustomerNotes={(customer_id) => {
                this.setState({
                  showCustomerNotes: customer_id,
                });
              }}
              viewPendingPayments={(customer_id) => {
                this.showPaymentDueHistory(customer_id);
              }}
              availabilityErrorMessages={appointmentCreationErrorMsgs}
              viewCustomerDetails={(appointment_id) => {
                this.setState({
                  viewAnswerDetails: appointment_id,
                });
              }}
            />
            <>
              {this.state.accessErrorMessage && (
                <p style={{ color: "red", fontSize: "18px" }}>{this.state.accessErrorMessage}</p>
              )}
              {this.state.appointmentFieldError.isError && (
                <p style={{ color: "red", fontSize: "18px" }}>{this.state.appointmentFieldError.message}</p>
              )}
            </>
          </Modal>
        )}
        {sendMessage && (
          <Modal
            close={() => {
              this.props.resetPromotion();
              this.setState({
                sendMessage: false,
                sendMessageFormData: {},
              });
            }}
          >
            <div className="send-direct-message">
              <div
                className="send-message-text"
                style={{
                  padding: "20px",
                  borderRadius: 5,
                  margin: "10px 0",
                  width: "600px",
                }}
              >
                <Translate>{`Dear Customer, Thanks for visiting ${branch.name} on ${
                  sendMessageFormData.date || "(Input 1)"
                }, 
            powered by ${branch.name}. Kindly revisit again and enjoy the best experience.`}</Translate>
              </div>
              <Form
                inputs={mockData.customer_message}
                data={sendMessageFormData}
                action={(edit, form, valid) => this.sendMessageFormAction(edit, form, valid)}
                viewCustomerHistory={(selectedCustomerData) => this.viewCustomerHistory(selectedCustomerData)}
                viewCustomerNotes={(customer_id) => {
                  this.setState({
                    showCustomerNotes: customer_id,
                  });
                }}
                viewPendingPayments={(customer_id) => {
                  this.showPaymentDueHistory(customer_id);
                }}
                // onChange={(formData) => this.setState({sendMessageFormData: formData})}
              />
              {promotion.status === false && (
                <span className="error">
                  <Translate>Error. Please try again.</Translate>
                </span>
              )}
              {promotion.status === true && <span className="success">Sent!</span>}
            </div>
          </Modal>
        )}
        <Statistics
          showAnalytics={showAnalytics}
          dashboard_dataset={dashboard_dataset}
          updateShowAnalytics={(showAnalyticsValue) => this.setState({ showAnalytics: showAnalyticsValue })}
        />

        <SideNav
          style={{ justifyContent: "space-between", height: "100%" }}
          usesDnd={true}
          condition={filterSideNav}
          onCross={() =>
            this.setState({
              filterSideNav: false,
            })
          }
        >
          <FilterSidenav
            staffListCheckChange={this.staffListCheckChange}
            staffListUpdate={this.staffListUpdate}
            unselectedStaff={unselected_staff}
            staffOrder={staff_order}
            resources={staffDataForOrdering}
          />

          <div>
            <h3>
              <Translate>{`${APPOINTMENT_LABEL} Filters`}</Translate>
            </h3>
            <Dropdown
              className="dropdown-theme"
              placeholder={`Filter ${APPOINTMENT_LABEL}s`}
              fluid
              multiple
              selection
              upward
              value={this.state.dropdownAppointementFilterLocalValues}
              options={dropdownAppointmentFilterOptions}
              onChange={(e, value) => this.setFilterAppointments(value.value)}
            />
          </div>
        </SideNav>
        {productUsageModalValue?.productUsageModal && (
          <Modal
            close={() => {
              productUsageReset();
              productUsageModalAction({ productUsageModal: false });
            }}
            zIndex={1}
          >
            <ProductUsage
              close={() => {
                productUsageModalAction({ productUsageModal: false });
              }}
              form={productUsageModalValue.form}
              rows={productUsageModalValue.rows}
              options={productUsageModalValue.options}
            />
          </Modal>
        )}
        <div className="calendar">
          <FullCalendar
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            resourceOrder="order1"
            ref={this.calendarRef}
            plugins={[
              timeGridDay,
              dayGridDay,
              listPlugin,
              interactionPlugin,
              resourceTimeGridPlugin,
              resourceDayGridPlugin,
              ScrollGrid,
            ]}
            initialView="resourceTimeGridDay"
            resources={this.formattedStaffList(unselected_staff)}
            allDaySlot={false}
            eventContent={this.renderEventContent}
            resourceLabelContent={function (arg) {
              return {
                html: arg.resource.title + " " + arg.resource.extendedProps.html,
              };
            }}
            datesSet={(range, b, c) => {
              const { formatted_component_permissions } = this.props.aclUserPermissions;
              let { startStr, endStr } = range;
              startStr = startStr.split("T")[0];
              endStr = endStr.split("T")[0];
              if (!(startStr === this.state.startStr && endStr === this.state.endStr)) {
                this.setState(
                  {
                    startStr: startStr.split("T")[0],
                    endStr: endStr.split("T")[0],
                  },
                  () => {
                    this.props.fetchAppointmentsBasicDetails({
                      start_date: startStr.split("T")[0],
                      end_date: endStr.split("T")[0],
                    });
                    if (!formatted_component_permissions.includes("owner_access")) {
                      this.props.fetchAppointmentAnalytics(startStr.split("T")[0]);
                    }
                  }
                );
              }
            }}
            selectable={true}
            dateClick={(e) => {
              this.setState({
                addAppointment: !formatted_component_permissions.includes("employee_access") ? true : false,
                edit: false,
                selectedStaff:
                  e.resource?._resource?.id && e.resource._resource?.id !== "-1"
                    ? parseInt(e.resource._resource?.id)
                    : null,
                appointmentFormData: {
                  datetime: e.date,
                },
              });
            }}
            slotDuration="00:10:00"
            slotMinTime={branchStartTime}
            slotMaxTime={branchEndTime}
            scrollTime={`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`}
            eventClick={(e) => this.eventClick(e)}
            events={appointments}
            customButtons={{
              staffRearrange: {
                text: <Icon size="large" name="bars" className="AppointmentIconImage" />,
                click: () => {
                  this.setState({
                    filterSideNav: true,
                  });
                },
              },
              sendMessage: {
                text: <Icon size="large" name="comment outline" className="AppointmentIconImage" />,
                click: () => {
                  this.setState({
                    sendMessage: true,
                  });
                },
              },
              addAppointment: !formatted_component_permissions.includes("employee_access") && {
                text: (
                  <Icon
                    size="large"
                    name="calendar plus outline"
                    className="AppointmentIconImage"
                    style={{ overflow: "hidden" }}
                  />
                ),
                click: () => {
                  this.setState({
                    addAppointment: true,
                    edit: false,
                    appointmentFormData: { _datetime: new Date() },
                    appointmentCreationErrorMsgs: [],
                  });
                },
              },
              timeGridDayBtn: {
                text: (
                  <div className={`today-button ${this.state.fullCalendarTabView.timeGridDayBtn}`}>
                    <p>
                      <Translate>day</Translate>
                    </p>
                  </div>
                ),
                click: () => {
                  this.changeView("timeGridDay");
                  this.activeFullCalendarTabView("timeGridDayBtn");
                },
              },
              listWeekBtn: {
                text: (
                  <div className={`today-button ${this.state.fullCalendarTabView.listWeekBtn}`}>
                    <p>
                      <Translate>list</Translate>
                    </p>
                  </div>
                ),
                click: () => {
                  this.changeView("listWeek");
                  this.activeFullCalendarTabView("listWeekBtn");
                },
              },
              todayBtn: {
                text: (
                  <div className={`today-button ${this.state.fullCalendarTabView.todayBtn}`}>
                    <p>
                      <Translate>today</Translate>
                    </p>
                  </div>
                ),
                click: () => {
                  this.changeView("resourceTimeGridDay");
                  this.activeFullCalendarTabView("todayBtn");
                },
              },
              filterMessage: {
                text: this.state.dropdownAppointementFilterLocalValues.length < 7 && (
                  <div className="filter-message">
                    <p>
                      <Translate>{`The ${APPOINTMENT_LABEL.toLowerCase()} list shown here is filtered`}</Translate>
                    </p>
                  </div>
                ),
              },
              circleCount: {
                text: <CircleCount count={totalFound} />,
              },
              filterSearchBar: {
                text: (
                  <FilterSearchBar
                    updateFilterSearchValue={this.updateFilterSearchValue}
                    changeView={() => {
                      this.changeView("listWeek");
                      this.activeFullCalendarTabView("listWeekBtn");
                    }}
                  />
                ),
              },
              timeGridWeekBtn: {
                text: (
                  <div className={`today-button ${this.state.fullCalendarTabView.timeGridWeekBtn}`}>
                    <p>
                      <Translate>week</Translate>
                    </p>
                  </div>
                ),
                click: () => {
                  this.changeView("resourceTimeGridWeek");
                  this.activeFullCalendarTabView("timeGridWeekBtn");
                },
              },
              selectDateBtn: {
                text: this.DatePicker(),
              },
            }}
            dayMinWidth={200}
            headerToolbar={{
            //   left: "title",
            //   center: "filterMessage",
              right: `circleCount,filterSearchBar,timeGridDayBtn,listWeekBtn,todayBtn,timeGridWeekBtn,prev,next,${this.showCalendarIcon()}staffRearrange,sendMessage,addAppointment`,
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    aclUserPermissions: state.aclUserPermissions.data,
    appointments: state.basicAppointments.results,
    appointmentInvoice: state.appointmentInvoice,
    services: Object.values(state.services.data).filter((e) => e.status === 1),
    servicesMap: state.servicesMap,
    products: Object.values(PRODUCTS_DATA(state)).filter((e) => e.status === 1),
    staff: Object.values(state.staffBasic.results).filter((e) => e.status === 1),
    staffMap: state.staffBasic.staffMap,
    staffAbsents: state.staffAbsents,
    staffDataFetching: state.staff.fetching,

    campaign: state.campaignBasic.data,
    memberships: state.membershipsBasic.data,

    customerHistory: state.customerHistory,

    appointmentAnalytics: state.appointmentAnalytics,
    serviceAnalytics: state.serviceAnalytics,

    branch: state.branch.data,

    promotion: state.promotion,

    user: state.user,
    expenses: state.branchWallet,
    clientMemberships: state.clientMemberships,
    labels: state.clientLabels.data,
    companies: state.companies.data,
    productUsageModalValue: state.productUsageModal,
    directDiscount: state.getAppointmentDirectDiscount.data,
    isQuickBook: state.isQuickBook.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAppointmentsBasicDetails: ({ start_date, end_date }) =>
      dispatch(fetchAppointmentsBasicDetails({ start_date, end_date })),
    appointmentsPostData: (appointment, startStr, endStr, isPreview) => {
      return dispatch(appointmentsPostData(appointment, startStr, endStr, isPreview));
    },
    membershipsBasicFetchData: () => dispatch(membershipsBasicFetchData()),
    campaignBasicFetchData: () => dispatch(campaignBasicFetchData()),

    appointmentUpdate: (id, appointment, startStr, endStr, method, toPreview) => {
      return dispatch(appointmentUpdate(id, appointment, startStr, endStr, method, toPreview));
    },
    appointmentHideInvoice: () => dispatch(appointmentHideInvoice()),
    appointmentShowInvoice: (appointment) => dispatch(appointmentShowInvoice(appointment)),
    appointmentHide: (appointment_id, start_date, end_date, hide) =>
      dispatch(appointmentHide(appointment_id, start_date, end_date, hide)),

    fetchAppointmentAnalytics: () => dispatch(appointmentAnalyticsFetchData()),
    fetchServiceAnalytics: () => dispatch(serviceAnalyticsFetchData()),

    fetchServices: () => dispatch(servicesFetchData()),
    fetchProducts: () => dispatch(productsFetchData()),

    staffBasicFetchData: () => dispatch(staffBasicFetchData()),
    fetchAbsentees: (startDate, endDate) => dispatch(staffAttendanceFetchData(startDate, endDate)),

    sendPromotion: (data) => dispatch(sendPromotion(data)),
    resetPromotion: () => dispatch(promotionReset()),
    branchWalletAggregatedData: () => dispatch(branchWalletAggregatedData()),
    clientMembershipFetchData: (id) => dispatch(clientMembershipFetchData(id)),
    clientMembershipReset: () => dispatch(clientMembershipReset()),
    fetchClientWallet: (id) => dispatch(customerWalletFetchData(id)),
    fetchPendingHistory: (id) => dispatch(getPendingHistory(id)),
    resetAppointmentError: () => dispatch(resetAppointmentError()),
    customerLabelReset: () => dispatch(customerLabelReset()),
    postServices: (service) => dispatch(servicesPostData(service)),
    postProducts: (product) => dispatch(productsPostData(product)),
    resetAppointmentAnswers: () => dispatch(resetAppointmentAnswers()),
    productUsageReset: () => dispatch(productUsageReset()),
    productUsageModalAction: (data) => dispatch(productUsageModal(data)),
    resetAddedDirectDiscount: () => dispatch({ type: "RESET_GET_APPOINTMENT_DIRECT_DISCOUNT" }),
    authenticationLogout: () => dispatch(authenticationLogout()),
    setIsQuickBook: () => dispatch(setIsQuickBook()),

    fetchClientLabels: () => dispatch(getLabels("client")),
    fetchServiceLabels: () => dispatch(getLabels("service")),
    fetchProductLabels: () => dispatch(getLabels("product")),
    fetchCompanies: () => dispatch(companiesFetchData()),
    selectedCustomerAppointmentForm: () => dispatch(selectedCustomerAppointmentForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
