import styled from "styled-components/macro";

export const Container = styled.div`
  width: 250px;
`;

export const GeneralFieldContainer = styled.div`
  width: 100%;
  // border-bottom: 1px solid #dddddd;
  border: 1px solid #dddddd;
  border-radius:8px;
  padding: 10px;
  display: flex;
  align-items: center;
`;

export const FieldIcon = styled.img`
  color: #808080;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;

export const GeneralFieldInput = styled.input`
  text-align: left;
  font: normal normal normal 12px/18px Montserrat;
  font-size: 18px;
  color: #808080;
  padding: 0;
  margin: 0;
  border: 0px;
  width: 100%;
`;
