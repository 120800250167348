import React, { useState } from "react";

import "./index.scss";

import Modal from "Modal";
import { Checkbox, Divider } from "semantic-ui-react";
import PromotionHistory from "./PromotionHistory";
import ScheduledPromotion from "./ScheduledPromotion";

function PromotionManagement(props) {
  const { show, close, promotionHistoryData, scheduledPromotions } = props;
  const [historyView, updateView] = useState(0);

  if (!show) return null;

  return (
    <Modal close={close}>
      <div className="promotion-management-container">
        <div className="flex center" style={{ padding: "10px", justifyContent: "center" }}>
          <Checkbox
            style={{ padding: "10px", borderRadius: "5px " }}
            radio
            label="All Promotions"
            checked={historyView === 0}
            onChange={() => updateView(0)}
          />
          <Checkbox
            radio
            label="Recurring Promotions"
            checked={historyView === 1}
            style={{ marginLeft: "10px", padding: "10px", borderRadius: "5px " }}
            onChange={() => updateView(1)}
          />
          <Divider />
        </div>
        <PromotionHistory show={historyView === 0} promotionHistoryData={promotionHistoryData} />
        <ScheduledPromotion show={historyView === 1} scheduledPromotionHistoryData={scheduledPromotions} />
      </div>
    </Modal>
  );
}

export default PromotionManagement;
