import { API_BASE_URL, POSITIONS_API, SERVICES_API } from "utilities";
import axios from "axios";
import { createSelector } from "reselect";
import _ from "lodash";
import { startLoading, endLoading, errored } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { positionActions, errorMessage } from "../../utilities/actionMessages";

export function positionsFetchDataSuccess(positions) {
  return {
    type: "POSITIONS_FETCH_DATA_SUCCESS",
    positions: positions,
  };
}

export function positionsFetchData(positions) {
  return (dispatch) => {
    dispatch(startLoading("POSITIONS_LOADER", "loading positions"));
    axios
      .get(API_BASE_URL + POSITIONS_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          dispatch(positionsFetchDataSuccess(response.data.results));
          dispatch(endLoading("POSITIONS_LOADER"));
        } else {
          dispatch(errored("POSITIONS_LOADER", true));
          dispatch(endLoading("POSITIONS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("POSITIONS_LOADER", true));
        dispatch(endLoading("POSITIONS_LOADER"));
      });
  };
}

export function positionsPostData(position) {
  return (dispatch) => {
    dispatch(startLoading("POSITIONS_LOADER", "loading positions"));
    axios({
      method: "post",
      url: API_BASE_URL + POSITIONS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: position,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: positionActions.success_create, type: "success" });
          dispatch(positionsFetchData());
          dispatch(endLoading("POSITIONS_LOADER"));
        } else {
          dispatch(errored("POSITIONS_LOADER", true));
          dispatch(endLoading("POSITIONS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("POSITIONS_LOADER", true));
        dispatch(endLoading("POSITIONS_LOADER"));
      });
  };
}

export function positionsPutData(id, position) {
  return (dispatch) => {
    dispatch(startLoading("POSITIONS_LOADER", "loading positions"));
    axios({
      method: "put",
      url: API_BASE_URL + POSITIONS_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: position,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.status) {
            ToastAlert({ message: positionActions.success_update, type: "success" });
          } else {
            ToastAlert({ message: positionActions.success_delete, type: "success" });
          }

          dispatch(positionsFetchData());
          dispatch(endLoading("POSITIONS_LOADER"));
        } else {
          dispatch(errored("POSITIONS_LOADER", true));
          dispatch(endLoading("POSITIONS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("POSITIONS_LOADER", true));
        dispatch(endLoading("POSITIONS_LOADER"));
      });
  };
}

const servicesSelector = (state) => state.services || [];
const positionsSelector = (state) => state.positions || [];

export const POSITION_DATA = createSelector([servicesSelector, positionsSelector], (services, positions) => {
  const servicesMap = _.keyBy(services.data, "id");
  const formattedPositions = positions.map((position) => {
    const formattedServices = [];

    position.services.forEach((id) => {
      if (id in servicesMap) {
        formattedServices.push(servicesMap[id]);
      }
    });

    return { ...position, services: formattedServices };
  });
  return formattedPositions;
});
