import React from "react";
import { Checkbox } from "semantic-ui-react";
import "./styles.scss";
import { Translate } from "react-auto-translate";

function Toggler({ style, toggle = true, reversed = false, onChange, checked, label }) {
  const backgroundColor = checked ? "toggleOn" : "toggleOff";

  return (
    <div className={`togglerContainer ${backgroundColor}`} style={{ ...style }}>
      {reversed && (
        <span className="toggleLabel">
          <Translate>{label}</Translate>
        </span>
      )}
      <Checkbox toggle={toggle} onChange={onChange} checked={checked} />
      {!reversed && (
        <span className="toggleLabel">
          <Translate>{label}</Translate>
        </span>
      )}
    </div>
  );
}

export default Toggler;
