import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { Dropdown, Search as SearchInput, Label } from "semantic-ui-react";
import { API_BASE_URL } from "utilities";
import axios from "axios";
import { debounce } from "lodash";
import LabelPopUp from "../LabelPopUp";
import { LabelGroup } from "../LabelGroup";
import { useDispatch, useSelector } from "react-redux";
import { selectedCustomerAppointmentForm } from "../../store/actions/selectedCustomerAppointmentform";
import { COUNTRY_DIAL_INFO } from "../../utilities/constants";
import { getLabels } from "../../store/actions/labels";
import { postCustomerLabels } from "../../store/actions/customerLabels";
import { clientLabelOptionsSelector } from "../../store/selectors/labelOptions";
import { useMaskNumber } from "../../hooks/useMaskNumber";

import { shouldRefreshClientSearchList } from "../../store/actions/shouldRefreshClientSearchList";

const fetchSearchResults = (search, setSearchResults, url, fields, field, onChange) => {
  axios
    .get(API_BASE_URL + url + search, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        const results = response.data.results.map((item) => {
          return {
            key: item.id,
            title: item[fields.title],
            description: item[fields.description],
            ...item,
          };
        });
        setSearchResults(results);
      } else {
        alert("error");
      }
    })
    .catch(function (error) {
      alert(error);
    });
};

function ResultRenderer({ result, field }) {
  const { maskNumber } = useMaskNumber();
  let title = result.title;
  let description = result.description;

  if (field === "customer.name") {
    description = maskNumber(result.description);
  } else if (field === "customer.number") {
    title = maskNumber(result.title);
  }

  return (
    <div className="result-dropdown">
      <div className="title">
        <span>{title}</span>
        <span className="number">{description}</span>
      </div>
      {/* <div>
        <LabelGroup labels={result.labels} addLabel={true} />
      </div> */}
      <div className="image">
        <img src={result.image || ""} alt={""} />
      </div>
    </div>
  );
}

function Search({
  url,
  onChange,
  fields,
  field,
  value,
  pastCustomer,
  pastCustomerRevenue = 0,
  onLabel0Click,
  onLabel1Click,
  onLabel2Click,
  onPaymentDueClick,
  setIsDuplicateNumber,
  form,
  isExistingAppointment = false,
  disabled = false,
}) {
  const dispatch = useDispatch();
  const customerLabels = useSelector((state) => state.customerLabels.data);
  const clientLabels = useSelector((state) => state.clientLabels.data);
  const clientLabelsOptions = useSelector(clientLabelOptionsSelector);
  const { refresh: refreshClientSearchList } = useSelector((state) => state.shouldRefreshClientSearchList);
  const selectedCustomer = useSelector((state) => state.selectedCustomerAppointmentFormData.data);
  const pendings = useSelector((state) => state.pendings);
  const { formatted_component_permissions } = useSelector((state) => state.aclUserPermissions.data);
  const shouldMaskNumber = !formatted_component_permissions.includes("view_masked_client_number");
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { maskNumber } = useMaskNumber();
  const shouldDisableNumber = field === "customer.number" && shouldMaskNumber && isExistingAppointment;

  // const [repeatedNum, setRepeatedNum] = useState(false);

  // useEffect(() => {
  //   if (field === 'customer.number') {
  //     const isRepeatedNum = searchResults.filter(({ number }) => number === searchValue).length !== 0;
  //     setRepeatedNum(isRepeatedNum);
  //     setIsDuplicateNumber(isRepeatedNum)
  //   }
  // }, [searchResults])

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const verify = useCallback(
    // it is required to get these url, fields, field arguments from calling function
    // otherwise it will use the previous arguments only ex. customer.number
    debounce((searchValue, setSearchResults, url, fields, field, onChange) => {
      fetchSearchResults(searchValue, setSearchResults, url, fields, field, onChange);
    }, 300),
    []
  );

  useEffect(() => {
    if (refreshClientSearchList && searchValue) {
      fetchSearchResults(searchValue, setSearchResults, url, fields, field, onChange);
      dispatch(shouldRefreshClientSearchList(false));
    }
  }, [refreshClientSearchList]);

  const clientLabel = customerLabels?.label_ids || selectedCustomer?.labels || [];

  const options = COUNTRY_DIAL_INFO.map(({ dial_code, flag, code, name }) => {
    return {
      key: code,
      text: `${dial_code} ${flag}`,
      value: dial_code,
      description: name,
    };
  });
  const labelDropdown = (
    <Dropdown
      disabled={shouldDisableNumber}
      placeholder="Dial code"
      value={form?.customer?.country_code || form["customer.country_code"]}
      search
      selection
      options={options}
      onChange={(_, data) => {
        if (form?.customer?.country_code) {
          onChange({
            ...form,
            ["customer"]: {
              ...form["customer"],
              country_code: data.value,
            },
          });
        } else {
          onChange({
            [field.split(".")[0] + ".country_code"]: data.value,
          });
        }
      }}
    />
  );

  const updateClientLabels = (customer_id, label_ids) => {
    const payload = { customer_id, label_ids };
    dispatch(postCustomerLabels(payload, {}, "appointmentForm"));
  };

  return (
    <div className="searchInput" id="poprender">
      <div className={field === "customer.number" ? "custom-suggestion" : ""}>
        {!!pastCustomer && field === "customer.name" && (
          <div className="labels">
            <Label key={0} pointing="below" className="customer-pending" onClick={onPaymentDueClick}>
              {pendings?.count > 0 ? <>Payment Due ( {pendings.count} Nos. ) </> : "No Payment Due"}
            </Label>
            <Label key={1} pointing="below" className="customer-value" onClick={onLabel0Click}>
              {pastCustomerRevenue > 5000 ? "H.V" : "L.V."}
            </Label>
            <Label className="customer-notes-btn" key={2} pointing="below" onClick={onLabel1Click}>
              Notes
            </Label>
            {!!form.id && (
              <Label className="customer-details-btn" key={3} pointing="below" onClick={onLabel2Click}>
                Details
              </Label>
            )}
          </div>
        )}
        <SearchInput
          disabled={shouldDisableNumber}
          input={field === "customer.number" ? { label: labelDropdown } : {}}
          key={3}
          disabled={disabled}
          className="search"
          resultRenderer={(result) => <ResultRenderer result={result} field={field} />}
          noResultsMessage={"Add New: " + searchValue}
          minCharacters={3}
          fluid
          results={searchResults}
          onResultSelect={(event, data) => {
            setSearchValue(data.result.title);
            dispatch(selectedCustomerAppointmentForm(data.result));
            const response = {
              [fields.name + ".id"]: data.result.id || null,
              [fields.name + "." + fields.title]: data.result.title || null,
              [fields.name + "." + fields.description]: data.result.description || null,
              [fields.name + "." + fields.extra1]: data.result[fields.extra1] || null,
              [fields.name + "." + fields.extra2]: data.result[fields.extra2] || null,
              [fields.name + "." + fields.extra3]:
                data.result[fields.extra3][0] && data.result[fields.extra3][0].id
                  ? data.result[fields.extra3][0].id
                  : null,
              [fields.name + "." + fields.extra4]: data.result[fields.extra4] || null,
              [fields.name + "." + fields.extra5]: data.result[fields.extra5] || null,
              [fields.name + "." + fields.extra6]:
                data.result[fields.extra3][0] && data.result[fields.extra3][0].staff_id
                  ? data.result[fields.extra3][0].staff_id
                  : null,
              [fields.name + "." + fields.extra7]: data.result[fields.extra7] || null,
              [fields.name + "." + fields.extra8]: data.result[fields.extra8] || null,
              [fields.name + "." + fields.extra8]: data.result[fields.extra8] || null,
              [fields.name + "." + fields.extra9]: data.result[fields.extra9] || null,
              [fields.name + "." + fields.extra10]: data.result[fields.extra10] || null,
              [fields.name + "." + fields.extra11]: data.result[fields.extra11] || null,
              [fields.name + "." + fields.extra12]: data.result[fields.extra12] || null,
              [fields.name + "." + fields.extra13]: data.result[fields.extra13] || null,
            };
            onChange(response);
          }}
          value={isExistingAppointment && field === "customer.number" ? maskNumber(value) : value}
          onSearchChange={(event, data) => {
            verify(data.value, setSearchResults, url, fields, field, onChange);
            setSearchValue(data.value);
            onChange({
              [field]: data.value,
              [field.split(".")[0] + ".id"]: null,
            });
          }}
        />
        {field === "customer.name" && value && selectedCustomer?.id && (
          <div>
            <LabelPopUp
              trigger={
                <LabelGroup
                  labels={clientLabel}
                  addLabel={true}
                  is_active={selectedCustomer?.is_active}
                  options={clientLabels}
                />
              }
              name={selectedCustomer?.name}
              labels={clientLabel}
              onSave={(labelsIds) => {
                updateClientLabels(selectedCustomer?.id, labelsIds);
              }}
              options={clientLabelsOptions}
              pageType="appointmentForm"
            />
          </div>
        )}
        {/* {repeatedNum && <small style={{ color: "red" }}>This Number already exist.</small>} */}
      </div>
    </div>
  );
}

export default Search;
