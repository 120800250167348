import { API_BASE_URL } from "utilities";
import axios from "axios";
import { errored } from "./withLoader";
import { getPendingHistory } from "./pendings";

export function AppointmentPaymentMethodUpdate(
  data,
  prevData,
  prevId,
  customerId = null,
  fetchAppointments = () => {}
) {
  let URL;
  if (prevData) {
    URL = API_BASE_URL + "appointment/payment-mode/" + prevId + "/";
  } else {
    URL = API_BASE_URL + "appointment/payment-mode/";
  }
  return async (dispatch) => {
    dispatch({
      type: "APPOINTMENT_PAYMENT_UPDATE_LOADING",
      response: { data: {}, loading: true },
    });
    try {
      let res;
      if (prevData) {
        res = await axios.put(URL, data, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
      } else {
        res = await axios.post(URL, data, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
      }
      dispatch({
        type: "APPOINTMENT_PAYMENT_UPDATE_SUCCESS",
        response: { data: res.data, loading: false },
      });
      dispatch(getPendingHistory(customerId));
      dispatch(GetAppointmentPaymentMethod(data.appointment));
      fetchAppointments();
    } catch (error) {
      dispatch(errored("APPOINTMENT_LOADER", true));
    }
  };
}

export function GetAppointmentPaymentMethod(id) {
  const URL = API_BASE_URL + "appointment/payment-mode/?&appointment_id=" + id;

  return async (dispatch) => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "APPOINTMENT_PAYMENT_SUCCESS",
        payload: res.data.results,
      });
    } catch (error) {
      dispatch(errored("APPOINTMENT_LOADER", true));
    }
  };
}
