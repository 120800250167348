import React from "react";
import { Translate } from "react-auto-translate";

export const SpecificServicesProducts = (props) => {
  const { type = {}, detail = {} } = props;

  return (
    <div className="campaign-service">
      <div className="campaign-popup-row">
        <div>
          <span className="bold-margin">
            <Translate>Name:</Translate>
          </span>
          {detail?.name}
        </div>
        <div>
          <span className="bold-margin">
            <Translate>Max Discount:</Translate>
          </span>
          {type.max_discount_amount}
        </div>
      </div>
      <div className="campaign-popup-row">
        <div>
          <span className="bold-margin">
            <Translate>Discount Type:</Translate>
          </span>
          {type.discount_type == 0 ? "Percentage" : "Value"}
        </div>
        <div>
          <span className="bold-margin">
            <Translate>Discount Value:</Translate>
          </span>
          {type.discount_type_value}
        </div>
      </div>
    </div>
  );
};
