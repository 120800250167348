const initialState = {
  data: {},
  loading: false,
  errored: false,
};

export function reviewLink(state = { ...initialState }, action) {
  switch (action.type) {
    case "REVIEW_LINK_FETCH_SUCCESS":
      return { ...initialState, data: action.data };
    case "REVIEW_LINK_FETCH_ERROR":
      return { ...initialState, errored: true };
    default:
      return state;
  }
}
