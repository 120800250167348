import { API_BASE_URL, SUBSCRIPTIONS_DISCOUNT_API } from "utilities";
import axios from "axios";

export function getSubscriptionDiscountHasErrored() {
  return {
    type: "SUBSCRIPTIONS_DISCOUNT_HAS_ERRORED",
    error: true,
    loading: false,
    data: [],
  };
}

export function getSubscriptionDiscountSuccess(data) {
  return {
    type: "SUBSCRIPTIONS_DISCOUNT_SUCCESS",
    error: false,
    loading: false,
    data: data,
  };
}

export function resetSubscriptionDiscount() {
  return {
    type: "SUBSCRIPTIONS_DISCOUNT_SUCCESS",
    error: false,
    loading: false,
    data: [],
  };
}

export function getSubscriptionDiscount(coupon) {
  return (dispatch) => {
    axios({
      method: "get",
      url: API_BASE_URL + SUBSCRIPTIONS_DISCOUNT_API + `?coupon=${coupon}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200 && response.data.results.length) {
          dispatch(getSubscriptionDiscountSuccess(response.data.results));
        } else {
          dispatch(getSubscriptionDiscountHasErrored());
        }
      })
      .catch(function (error) {
        dispatch(getSubscriptionDiscountHasErrored());
      });
  };
}
