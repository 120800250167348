import axios from "axios";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ToastAlert from "./toastAlert/toastAlert";

axios.interceptors.request.use(
  (request) => {
    async function newRequest(request) {
      let adminBranch;
      try {
        adminBranch = await AsyncLocalStorage.getItem("adminBranch");
        if (!request.url.includes("amazonaws"))
          if (adminBranch) {
            if (request.url.includes("?")) {
              request.url += `&branch_id=${adminBranch}`;
            } else {
              request.url += `?branch_id=${adminBranch}`;
            }
          }
      } catch (e) {
        console.log(e);
      }
      return request;
    }
    /*subscription valid based CUD api restricted condition */
    if (
      !localStorage.getItem("token") ||
      request.method === "get" ||
      request.url.includes("/payments") ||
      request.url.includes("/logout") ||
      sessionStorage.getItem("subscription_valid_status") >= 0
    ) {
      return newRequest(request);
    }
  },
  (error) => {
    return error;
  }
);

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (sessionStorage.getItem("subscription_valid_status") < 0 && localStorage.getItem("token")) {
      // ToastAlert({ message: "Your subscription has expired, please contact support", type: "error" });
    }
    return Promise.reject(err);
  }
);
