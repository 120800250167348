import { API_BASE_URL, WALLET_PAYMENT_ANALYTICS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

export function walletPaymentAnalyticsFetchDataSuccess(results) {
  return {
    type: "WALLET_PAYMENT_ANALYTICS_FETCH_DATA_SUCCESS",
    results,
  };
}

export function walletPaymentAnalyticsFetchData(start_date = new Date().toISOString().split("T")[0], end_date = "") {
  const urlParam = `?start_date=${start_date}&end_date=${end_date}`;
  return (dispatch) => {
    dispatch(startLoading("WALLET_PAYMENT_ANALYTICS_LOADER", "loading services"));
    axios
      .get(API_BASE_URL + WALLET_PAYMENT_ANALYTICS_API + urlParam, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(walletPaymentAnalyticsFetchDataSuccess(response.data));
          dispatch(endLoading("WALLET_PAYMENT_ANALYTICS_LOADER"));
        } else {
          dispatch(errored("WALLET_PAYMENT_ANALYTICS_LOADER", true));
          dispatch(endLoading("WALLET_PAYMENT_ANALYTICS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("WALLET_PAYMENT_ANALYTICS_LOADER", true));
        dispatch(endLoading("WALLET_PAYMENT_ANALYTICS_LOADER"));
      });
  };
}
