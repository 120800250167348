const initData = {
  status: false,
};

export function isQuickBook(state = initData, action) {
  switch (action.type) {
    case "SET_IS_QUICKBOOK":
      return { ...state, ...action.data };
    default:
      return state;
  }
}
