import React, { useEffect, useState } from "react";
import "./index.scss";
import _ from "lodash";
import { Icon, Pagination } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

function HoverTable({
  style,
  header,
  custom,
  src,
  customFunction1 = () => {},
  renderSpecial,
  edit,
  undo,
  remove,
  select = false,
  isSelected = {},
  showDeleted = false,
  toPaginate = false,
  changePage = () => {},
  noDataText = "Data Not Found.",
}) {
  const [info, setInfo] = useState({
    page: 0,
    pages: 0,
    limit: 10,
  });
  const [data, updateData] = useState([]);

  useEffect(() => {
    // safe to update the hoverTable's pagination
    if (toPaginate && src) {
      updateData(src.results);
      setPageContent(src.current_page, src.pages);
    }

    if (!toPaginate && src) {
      updateData(src);
    }
  }, [src]);

  const setPageContent = (currentPage, totalPage) => {
    setInfo({
      ...info,
      page: currentPage,
      pages: totalPage,
    });
  };

  const handlePointerClick = (value) => {
    if (isNaN(value) || value === info.page || value > info.pages) return;
    changePage(value);
  };

  return (
    <div className="hover-table scroll custom-scroll" style={style}>
      <table className="table">
        <tbody>
          <tr>
            {_.map(header, (item, key) => {
              return <th key={key}>{typeof item === "string" ? <Translate>{item}</Translate> : item}</th>;
            })}
          </tr>
          {_.map(data, (value, key) => {
            return (
              <tr key={key} className={showDeleted ? "deleted" : ""}>
                {_.map(renderSpecial, (item, index) => {
                  return (
                    <td key={index} className={index === 2 ? "list-td" : ""}>
                      {item(value, edit, remove, select, isSelected, custom, undo, key, customFunction1)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && <div className="no-data">{noDataText} </div>}
      {toPaginate && info.pages !== 0 && (
        <div className="pagination">
          <Pagination
            defaultActivePage={1}
            activePage={info.page}
            totalPages={info.pages}
            size="mini"
            onPageChange={(event, data) => {
              handlePointerClick(data.activePage);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default HoverTable;
