import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Popup } from "semantic-ui-react";
import { findObjectById } from "../../utilities";
import { SpecificServicesProducts } from "./showSpecificProductServices";
import { AllServicesProducts } from "./showAllServicesProducts";
import { Translate } from "react-auto-translate";
import "./index.scss";

import { fetchCampaignDataById } from "../../store/actions/campaignDataById";

function CampaignPopUp({ campaignId }) {
  const dispatch = useDispatch();
  const servicesMap = useSelector((state) => state.servicesMap);
  const productsMap = useSelector((state) => state.productsMap);
  const campaignDataById = useSelector((state) => state.campaignDataById);
  const [campaign, setCampaign] = useState();

  useEffect(() => {
    if (!campaignDataById?.fetching && campaignDataById?.data?.id === campaignId) {
      setCampaign(campaignDataById.data);
    }
  }, [campaignDataById]);

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchCampaignDataById(campaignId));
    }
  }, [campaignId]);

  return (
    <>
      <Popup trigger={<Icon name="eye" color="blue" />}>
        <div className="campaign-popup-container">
          {campaignId && campaignDataById?.error && (
            <p className="error">Something went wrong. Could not load latest campaign data</p>
          )}
          {campaignDataById?.fetching && <p>Loading latest data...</p>}
          <div className="campaign-popup-row">
            <div>
              <span className="bold-margin">
                <Translate>Name:</Translate>
              </span>
              {campaign?.name}
            </div>
            <div>
              <span className="bold-margin">
                <Translate>description:</Translate>
              </span>
              {campaign?.description}
            </div>
          </div>
          {campaign?.discount_type_value && (
            <div>
              <h5 className="campaign-type">
                <Translate>Discount On The Total Bill</Translate>
              </h5>
              <div className="campaign-popup-row">
                <div>
                  <span className="bold-margin">
                    <Translate>Discount Type:</Translate>
                  </span>
                  {campaign?.discount_type.slice(0, 1) + campaign?.discount_type.slice(1).toLowerCase()}
                </div>
                <div>
                  <span className="bold-margin">
                    <Translate>Discount:</Translate>
                  </span>
                  {campaign?.discount_type_value}
                </div>
              </div>
              <div className="campaign-popup-row">
                <div>
                  <span className="bold-margin">
                    <Translate>Max Discount Amount:</Translate>
                  </span>
                  {campaign?.max_discount_amount}
                </div>
                <div>
                  <span className="bold-margin">
                    <Translate>Min Bill Amount:</Translate>
                  </span>
                  {campaign?.min_bill_amount}
                </div>
              </div>
            </div>
          )}
          {(campaign?.all_services_discount_type_value || campaign?.all_services_discount_type_value === 0) && (
            <AllServicesProducts type="services" campaign={campaign} />
          )}
          {campaign?.services?.length > 0 && (
            <div>
              <h5 className="campaign-type ">
                <Translate>Discount on Specific Services</Translate>
              </h5>
              {campaign.services.map((service) => {
                const serviceDetail = servicesMap[service.service_id] || {};
                return <SpecificServicesProducts detail={serviceDetail} type={service} />;
              })}
            </div>
          )}
          {(campaign?.all_products_discount_type_value || campaign?.all_products_discount_type_value === 0) && (
            <AllServicesProducts type="products" campaign={campaign} />
          )}
          {campaign?.products?.length > 0 && (
            <div>
              <h5 className="campaign-type">
                <Translate>Discount on Specific Products</Translate>
              </h5>
              {campaign?.products.map((product) => {
                const productDetail = productsMap[product.product_id] || {};
                return <SpecificServicesProducts detail={productDetail} type={product} />;
              })}
            </div>
          )}
        </div>
      </Popup>
    </>
  );
}

export default CampaignPopUp;
