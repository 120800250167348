import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};


export const CampaignSpecificServicesProducts = (props) => {
  const { formik, type, dropDownOptions, dropDownValues, index, onChange, dataObj } = props;
  const label = type.slice(0, -1);
  const { discount_type, discount_type_value, max_discount_amount } = dataObj;
  return (
    <>
      <Form.Group key={index} widths="equal">
        <Form.Field
          control={Dropdown}
          search
          selection
          fluid
          className="specific-dropdown"
          label={<Translate>{label}</Translate>}
          options={dropDownOptions}
          text={dropDownValues === -1 ? label : ""}
          placeholder={label}
          error={formik.errors?.[type]?.[index]?.[label]}
          value={dropDownValues}
          onChange={(e, { options, value }) => onChange(index, `${label}_id`, options[value].org_value.id, type)}
          forceSelection={false}
          selectOnBlur={false}
        />
        <Form.Field className="grouped-radio">
          <label>
            <Translate>Discount Type</Translate>
          </label>
          <Form.Radio
            label={translateFormLabel("Percentage")}
            checked={discount_type === 0}
            onChange={() => onChange(index, "discount_type", 0, type)}
          />
          <Form.Radio
            label={translateFormLabel("Value")}
            checked={discount_type === 1}
            onChange={() => onChange(index, "discount_type", 1, type)}
          />
        </Form.Field>
        <Form.Input
          style={{ maxWidth: "120px" }}
          type="number"
          placeholder={"Enter Discount " + ["%", "Value"][discount_type]}
          value={discount_type_value || ""}
          label={<Translate>{`Discount* ${["%", "Value"][discount_type]}`}</Translate>}
          error={formik.errors?.[type]?.[index]?.discount_type_value}
          onChange={(e) => onChange(index, "discount_type_value", e.target.value, type)}
        />
        <Form.Input
          style={{ maxWidth: "120px" }}
          type="number"
          placeholder="Enter Maximum Discount"
          value={max_discount_amount || ""}
          label={<Translate>Max Discount*</Translate>}
          error={formik.errors?.[type]?.[index]?.max_discount_amount}
          onChange={(e) => onChange(index, "max_discount_amount", e.target.value, type)}
        />
      </Form.Group>
    </>
  );
};
