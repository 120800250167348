const init = {
  results: [],
  pages: 0,
  current_page: 0,
  customer_id: null,
};

export function pendings(state = init, action) {
  switch (action.type) {
    case "PENDING_HISTORY_SUCCESS": {
      return { ...action.data, customer_id: action.id };
    }
    case "PENDING_HISTORY_FAILED": {
      return init;
    }
    default:
      return state;
  }
}
