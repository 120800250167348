import React, { useEffect, useState } from "react";
import { Form } from "../../new-components";
import "./index.scss";
import { clone } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getReceptionistStaffAttendance,
  receptionistStaffAttendanceEntry,
  receptionistStaffAttendanceUpdate,
} from "../../store/actions/staffAttendance";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import "moment-timezone";
import { AiOutlineCloudDownload } from "react-icons/ai";
import exportFromJSON from "export-from-json";
import { Translate } from "react-auto-translate";

const parseAttendance = (data) => {
  return data.map((item) => {
    const date = new Date(0);
    date.setSeconds(item.duration_in_seconds);
    const time = date.toISOString().substr(11, 8);
    return {
      id: item.id,
      date: item.punch_in_datetime,
      punchIn: item.punch_in_datetime,
      punchOut: item.punch_out_datetime,
      duration: time || 0,
    };
  });
};

const toggleTimeFormat = (time, toFormat) => {
  if (!time) {
    return "";
  }
  if (toFormat === "init") {
    return moment(time).format("HH:mm:ss");
  }
  if (toFormat === "24") {
    return moment(time, "h:mm:ss A").format("HH:mm:ss");
  }
};

const PUNCH = {
  id: null,
  date: new Date().toISOString().split("T")[0],
  punchIn: null,
  punchOut: null,
  duration: 0,
};

const EditableAttendance = ({ data, action, reset, isActive }) => {
  const onlyDate = moment().format("YYYY-MM-DD");
  const [error, updateError] = useState(false);
  const [form, update] = useState({
    staffId: data?.staffId || null,
    id: data?.id || null,
    date: data?.date || onlyDate,
    punchIn: data?.punchIn || "",
    punchOut: data?.punchOut || "",
  });
  useEffect(() => {
    update({
      staffId: data?.staffId || null,
      id: data?.id || null,
      date: moment(data?.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      punchIn: toggleTimeFormat(data.punchIn, "init") || "",
      punchOut: toggleTimeFormat(data.punchOut, "init") || "",
    });
  }, [data]);

  const btnClicked = () => {
    if (!form.punchIn.length) {
      updateError(true);
      return;
    }
    const payload = {
      punch_in_datetime: moment.tz([form.date, form.punchIn].join(" "), moment.tz.guess()).utc(),
    };
    if (form.punchOut) {
      payload.punch_out_datetime = moment.tz([form.date, form.punchOut].join(" "), moment.tz.guess()).utc();
    }
    if (form?.id) {
      payload.id = form.id;
    }
    // this caters to scenario where only punchIn value is available
    // and we want that to be pushed to the server as well
    const validated = form.punchOut
      ? moment.duration(payload.punch_out_datetime.diff(payload.punch_in_datetime))?._milliseconds || 0 > 0
      : true;
    if (validated) {
      action(payload);
      updateError(false);
    } else {
      updateError(true);
    }
  };

  return (
    <div className="edit-fields">
      <div className="entries">
        <div className="fields">
          <label><Translate>Date</Translate></label>
          <input type="date" value={form.date} onChange={(e) => update({ ...form, date: e.target.value })} />
        </div>
        <div className="fields">
          <label><Translate>Punch In Time</Translate></label>
          <input
            type="time"
            className={`${!form.punchIn && error ? "errored" : ""}`}
            value={form.punchIn}
            placeholder="Enter Punch In"
            onChange={(e) => update({ ...form, punchIn: e.target.value })}
          />
        </div>
        <div className="fields">
          <label><Translate>Punch Out Time</Translate></label>
          <input
            type="time"
            // className={`${!form.punchOut && error && Edit? "errored" : ""}`}
            value={form.punchOut}
            placeholder="Enter Punch Out"
            onChange={(e) => update({ ...form, punchOut: e.target.value })}
          />
        </div>
      </div>
      <div className="actions">
        {error && !form.punchIn ? <span className="validation-error"><Translate>Please check the punch in time.</Translate></span> : ""}
        <button disabled={!isActive ? true : false} className={`ui button primary`} onClick={btnClicked}>
          <Translate>Update</Translate>
        </button>
        <button
          disabled={!isActive ? true : false}
          className="ui button"
          onClick={() => {
            updateError(false);
            reset();
          }}
        >
         <Translate> Reset</Translate>
        </button>
      </div>
    </div>
  );
};

const Attendance = ({ data, isActive }) => {
  const dispatch = useDispatch();
  const staffAttendance = useSelector((state) => state.staffAttendance);
  const [field, update] = useState(clone(PUNCH));
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const staffId = data?.selectedStaff?.id;
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

  const action = (payload) => {
    payload.staff_id = staffId;
    if (payload?.id) {
      dispatch(receptionistStaffAttendanceUpdate(staffId, payload, dateRange));
    } else {
      dispatch(receptionistStaffAttendanceEntry(payload, dateRange));
    }
    update(clone(PUNCH));
  };

  const downloadAttendanceData = () => {
    const header = ["Date", "Punch In", "Punch Out", "Duration"];

    const attendanceFields = parseAttendance(staffAttendance).map((entry) => {
      const attendanceDate = moment(entry.date).local(true).format("DD-MM-YYYY");
      const punchIn = entry.punchIn ? moment(entry.punchIn).local(true).format("h:mm:ss A") : "---";
      const punchOut = entry.punchOut ? moment(entry.punchOut).local(true).format("h:mm:ss A") : "---";
      return [attendanceDate, punchIn, punchOut, entry.duration];
    });

    const data = [[...header], ...attendanceFields];

    const fileName = "attendance_information";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <Form>
      <Form.Divider />
      <div id="attendance" className="container">
        <div className="attendance">
          <div className="details">
            <div className="entry">
              <EditableAttendance data={field} isActive={isActive} action={action} reset={() => update(clone(PUNCH))} />
            </div>
            <Form.Divider />
            {staffAttendance?.length > 0 && (
              <>
                <div className="download-icon">
                  <button
                    disabled={isSubscriptionExpired}
                    className="down-btn"
                    onClick={() => {
                      downloadAttendanceData();
                    }}
                  >
                    <AiOutlineCloudDownload size="20" title="Download Attendance Data" />
                    &nbsp;
                  </button>
                </div>
                <div className="info">
                  <div className="underline">
                    <span><Translate>Date</Translate></span>
                    <span><Translate>Punch In</Translate></span>
                    <span><Translate>Punch Out</Translate></span>
                    <span><Translate>Duration</Translate></span>
                    <span><Translate>Action</Translate></span>
                  </div>

                  {parseAttendance(staffAttendance).map((entry) => {
                    const attendanceDate = moment(entry.date).local(true).format("DD-MM-YYYY");
                    const punchIn = entry.punchIn ? moment(entry.punchIn).local(true).format("h:mm:ss A") : "---";
                    const punchOut = entry.punchOut ? moment(entry.punchOut).local(true).format("h:mm:ss A") : "---";
                    return (
                      <div key={entry.id}>
                        <span className="field">{attendanceDate}</span>
                        <span className="field">{punchIn}</span>
                        <span className="field">{punchOut}</span>
                        <span className="field">{entry.duration}</span>
                        <span className="field">
                          <Icon
                            className="pointer"
                            name="pencil"
                            onClick={() => {
                              update({
                                ...field,
                                id: entry.id,
                                date: moment(attendanceDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                                punchIn: entry.punchIn ? moment(entry.punchIn).local(true) : "",
                                punchOut: entry.punchOut ? moment(entry.punchOut).local(true) : "",
                              });
                            }}
                          />
                          <Icon
                            className="pointer"
                            name="trash"
                            onClick={() => {
                              const payload = {
                                id: entry.id,
                                punch_in_datetime: entry.punchIn,
                                punch_out_datetime: entry.punchOut,
                                staff_id: staffId,
                              };
                              dispatch(receptionistStaffAttendanceUpdate(staffId, payload, dateRange, "DELETE"));
                              update(clone(PUNCH));
                            }}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Attendance;
