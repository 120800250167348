const initalState = {
  error: "",
  order: {},
};

export const PlaceOrderReducer = (state = initalState, action) => {
  switch (action.type) {
    case "ORDER_PLACED_SUCCESS":
      return {
        ...state,
        order: action.payload,
      };
    case "ORDER_PLACED_FAIL":
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
