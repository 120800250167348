import { LoadingState } from "./types";

const initialData = {
  data: "",
  loading: LoadingState.unset,
};

export function customerLoyaltyPointBalance(state = initialData, action) {
  switch (action.type) {
    case "LOYALTY_POINT_BALANCE_SUCCESS": {
      return { ...state, ...action.response, loading: LoadingState.loaded };
    }
    case "LOYALTY_POINT_LOADING": {
      return { ...state, ...action.response, loading: LoadingState.loading };
    }
    case "LOYALTY_POINT_UNSET_LOADING":
      return {
        ...state,
        loading: LoadingState.unset,
      };

    default:
      return state;
  }
}

const initalState = {
  pages: 1,
  currentPage: 1,
  results: [],
};

export function customerLoyaltyPointTransactions(state = initalState, action) {
  switch (action.type) {
    case "LOYALTY_POINTS_TRANSACTIONS_SUCCESS": {
      return action.data;
    }

    default:
      return state;
  }
}
