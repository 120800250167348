import React from "react";
import { Translate } from "react-auto-translate";

function ProgressBar(props) {
  let values = props.data.progressBar.map((appointment, index) => {
    let width = (appointment.value * 100) / props.data.total;
    return (
      <div
        key={index}
        style={{
          backgroundColor: appointment.color,
          width: `${width}%`,
          height: "100%",
        }}
      />
    );
  });
  let list = props.data.progressBar.map((appointment, index) => (
    <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }} key={index}>
      <span
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: appointment.color,
        }}
      />
      <p style={{ marginLeft: "5px" }}>
        <Translate>{appointment.name}</Translate>({appointment.value})
      </p>
    </div>
  ));

  return (
    <div className="dashboard-progressbar">
      <div className="progressbar">{values}</div>
      <div className="progressbar-list">{list}</div>
    </div>
  );
}

export default ProgressBar;
