import axios from "axios";
import { API_BASE_URL, CUSTOMER_WALLET_API } from "../utilities";
import ToastAlert from "../utilities/toastAlert/toastAlert";

import configureStore from "../store/configureStore";
import { authenticationLogout } from "../store/actions/authentication";
import { errorMessage } from "../utilities/actionMessages";

const store = configureStore();

export async function customerWalletBalanceDeductManuallyApi({ data }) {
  const url = API_BASE_URL + CUSTOMER_WALLET_API;
  const headers = {
    Authorization: localStorage.getItem("token"),
  };

  try {
    const response = await axios({
      method: "post",
      url,
      data,
      headers,
    });

    ToastAlert({ message: "Added successfully!", type: "success" });
    return { status: "success", response };
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      store.dispatch(authenticationLogout());
    }
    const message = error?.response?.data ? JSON.stringify(error.response.data) : errorMessage;

    ToastAlert({ message, type: "error" });
    return { status: "error", error };
  }
}
