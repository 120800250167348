import React, { useState } from "react";
import HoverTable from "HoverTable";
import Description from "Description";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Icon } from "semantic-ui-react";
import { BRACKET } from "../../containers/Staff/data";
import { Form as NewComponentForm, SideNav } from "../../new-components";
import { deactivateStaffSalaryBracket, staffSalaryPostData } from "../../store/actions/salaryBracket";
import { Translate } from "react-auto-translate";

export const generateBracketOptions = (type, optionState) => {
  const entries = [];
  BRACKET[type].forEach((entry) => {
    let flag = true;
    optionState.forEach((earning) => {
      if (earning.option.value === entry.value) {
        flag = false;
      }
    });
    if (flag) {
      entries.push({
        ...entry,
        include_in_leave_calc: true,
      });
    }
  });
  return entries;
};

function isAnyFieldEmpty(name, additionArr, deductionArr) {
  if (name === "") {
    return true;
  }
  for (let val of additionArr) {
    if (val.value === "") {
      return true;
    }
  }
  for (let val of deductionArr) {
    if (val.value === "") {
      return true;
    }
  }
}

const bracketHeaders = ["Name", "Salary"];
const salaryBracketRenderSpecial = [
  ({ name }) => <Description title={name} />,
  ({ addition, deduction }) => {
    let total = addition.reduce((acc, inc) => acc + inc.value, 0) - deduction.reduce((acc, inc) => acc + inc.value, 0);
    if (total % 1 != 0) total = total.toFixed(2);
    return <Description title={total < 0 ? 0 : total} />;
  },
  (value, edit, remove) => {
    return !value.active ? (
      <Icon key={0} className="dont" />
    ) : (
      [
        <Icon key={0} className="pointer eye" name="edit" onClick={() => edit(value.id, value)} />,
        <Icon key={1} className="pointer trash" onClick={() => remove(value.id, value)} />,
      ]
    );
  },
];

const initAddition = {
  value: 0,
  option: {
    id: -1,
    label: "Select Earning Option",
    value: "none",
    include_in_leave_calc: true,
  },
};

const initDeduction = {
  value: 0,
  option: {
    id: -1,
    label: "Select Deduction Option",
    value: "none",
    include_in_leave_calc: true,
  },
};

function CreateStaffSalaryBrackets({ addStaffPackage, close }) {
  const dispatch = useDispatch();
  const brackets = useSelector((state) => state.staffBracket.activeBrackets);
  const [viewOnlySalaryBrackets, setViewOnlySalaryBrackets] = useState(false);
  const [showSalaryBrackets, setShowSalaryBrackets] = useState(true);
  const [salaryBracketFieldError, setSalaryBracketFieldError] = useState(false);
  const [staffPackageForm, setStaffPackageForm] = useState({
    name: "",
    showBrackets: true,
    earningsSectionOpen: true,
    addition: [initAddition],
    deductionsSectionOpen: true,
    deduction: [initDeduction],
  });

  function removeStaffPackageDetailRow(type, id) {
    if (type === "addition") {
      setStaffPackageForm({
        ...staffPackageForm,
        addition:
          id === 0 && staffPackageForm.addition.length === 1
            ? [initAddition]
            : staffPackageForm.addition.filter((_, ind) => ind !== id),
      });
    } else if (type === "deduction") {
      setStaffPackageForm({
        ...staffPackageForm,
        deduction:
          id === 0 && staffPackageForm.deduction.length === 1
            ? [initDeduction]
            : staffPackageForm.deduction.filter((i, ind) => ind !== id),
      });
    }
  }

  function viewSalaryBracket(data) {
    const { name, id, addition, deduction } = data;
    const additions = addition.map(({ name, value, include_in_leave_calc }) => {
      const entry = BRACKET.addition.filter((i) => i.value === name);
      if (entry.length === 1) {
        return {
          value,
          option: {
            ...entry[0],
            include_in_leave_calc: include_in_leave_calc,
          },
        };
      } else {
        return {
          value,
          option: {
            id: -1,
            value: name,
            label: name,
          },
        };
      }
    });

    const deductions = deduction.map(({ name, value, include_in_leave_calc }) => {
      const entry = BRACKET.deduction.filter((i) => i.value === name);
      if (entry.length === 1) {
        return {
          value,
          option: {
            ...entry[0],
            include_in_leave_calc: include_in_leave_calc,
          },
        };
      } else {
        return {
          value,
          option: {
            id: -1,
            value: name,
            label: name,
          },
        };
      }
    });
    const temp = {
      id,
      name,
      showBrackets: true,
      earningsSectionOpen: true,
      addition: additions,
      deductionsSectionOpen: true,
      deduction: deductions,
    };
    setStaffPackageForm({ ...temp });
    setViewOnlySalaryBrackets(true);
  }

  function resetStaffPackageForm(isResetOnly = false) {
    setSalaryBracketFieldError(false);
    setStaffPackageForm({
      name: "",
      showBrackets: true,
      earningsSectionOpen: true,
      addition: [initAddition],
      deductionsSectionOpen: true,
      deduction: [initDeduction],
    });
    setViewOnlySalaryBrackets(false);
    !isResetOnly && close();
  }

  return (
    <SideNav condition={addStaffPackage} onCross={() => resetStaffPackageForm()}>
      <NewComponentForm>
        <NewComponentForm.Title><Translate>Salary Brackets</Translate></NewComponentForm.Title>
        <NewComponentForm.Divider> </NewComponentForm.Divider>
        <NewComponentForm.SubHeading
          isOpen={showSalaryBrackets}
          onClick={() => {
            setShowSalaryBrackets(!showSalaryBrackets);
          }}
        >
         <Translate> List of Salary Brackets</Translate>
        </NewComponentForm.SubHeading>
        {showSalaryBrackets && brackets.length > 0 ? (
          <div style={{ maxHeight: "400px", overflow: "auto", marginTop: "10px", marginBottom: "30px" }}>
            <div style={{ width: "100%" }}>
              <HoverTable
                header={bracketHeaders}
                src={brackets}
                renderSpecial={salaryBracketRenderSpecial}
                edit={(valueId, value) => {
                  viewSalaryBracket(value);
                }}
                remove={(valueId, value) => {
                  dispatch(deactivateStaffSalaryBracket(valueId));
                }}
              />
            </div>
          </div>
        ) : (
          <>{brackets.length <= 0 && <div style={{ marginBottom: "20px" }}>No Salary Detail created yet.</div>}</>
        )}
        <NewComponentForm.Title><Translate>Staff Bracket</Translate></NewComponentForm.Title>
        <NewComponentForm.Divider> </NewComponentForm.Divider>
        <NewComponentForm.TextFieldContainer>
          <NewComponentForm.TextField
            disabled={viewOnlySalaryBrackets}
            value={staffPackageForm.name}
            onChange={(e) => {
              if (e.target.value) {
                setSalaryBracketFieldError(false);
              }
              setStaffPackageForm({ ...staffPackageForm, name: e.target.value });
            }}
            type="text"
            placeholder="Package Name*"
          />
        </NewComponentForm.TextFieldContainer>
        <p className="info">
          <Icon name="info" />
          <span><Translate>Once created salary brackets can not be changed.</Translate></span>
        </p>
        <p className="info">
          <Icon name="info" />
          <span><Translate>Only checked ones are included in unpaid leaves calculation.</Translate></span>
        </p>
        <NewComponentForm.SubHeading
          isOpen={staffPackageForm.earningsSectionOpen}
          onClick={() =>
            setStaffPackageForm({ ...staffPackageForm, earningsSectionOpen: !staffPackageForm.earningsSectionOpen })
          }
        >
          <Translate>Earnings</Translate>
        </NewComponentForm.SubHeading>
        {staffPackageForm.earningsSectionOpen && (
          <NewComponentForm.FieldContainer>
            {staffPackageForm.addition.map((earning, index) => {
              return (
                <NewComponentForm.SingleLineFieldsContainer key={index}>
                  <Checkbox
                    disabled={viewOnlySalaryBrackets}
                    style={{ marginRight: "15px" }}
                    onChange={() => {
                      let earnings = staffPackageForm.addition;
                      earnings[index].option.include_in_leave_calc = !earning.option.include_in_leave_calc;
                      setStaffPackageForm({
                        ...staffPackageForm,
                        addition: [...earnings],
                      });
                    }}
                    checked={earning.option.include_in_leave_calc}
                  />
                  <NewComponentForm.AsyncSelect
                    disabled={viewOnlySalaryBrackets}
                    placeholder="Select Earning Option"
                    value={earning.option}
                    toDisplay={earning.option.label}
                  >
                    {generateBracketOptions("addition", staffPackageForm.addition).map((earningOption, optionIndex) => {
                      return (
                        <NewComponentForm.AsyncSelectOption
                          key={optionIndex}
                          onClick={() => {
                            const newEarnings = staffPackageForm.addition;
                            newEarnings[index] = {
                              ...newEarnings[index],
                              option: earningOption,
                            };
                            setStaffPackageForm({ ...staffPackageForm, addition: [...newEarnings] });
                          }}
                          value={earningOption}
                        >
                          {earningOption.label}
                        </NewComponentForm.AsyncSelectOption>
                      );
                    })}
                  </NewComponentForm.AsyncSelect>
                  <NewComponentForm.PriceFieldContainer>
                    <NewComponentForm.PriceField
                      disabled={viewOnlySalaryBrackets}
                      value={earning.value}
                      onChange={(e) => {
                        if (e.target.value) {
                          setSalaryBracketFieldError(false);
                        }
                        const newEarnings = staffPackageForm.addition;
                        newEarnings[index] = {
                          ...newEarnings[index],
                          value: e.target.value,
                        };
                        setStaffPackageForm({ ...staffPackageForm, addition: [...newEarnings] });
                      }}
                      type="text"
                      placeholder="Value*"
                    />
                  </NewComponentForm.PriceFieldContainer>
                  <NewComponentForm.Icon
                    disabled={viewOnlySalaryBrackets}
                    onClick={() => {
                      if (!staffPackageForm.hasOwnProperty("id")) {
                        removeStaffPackageDetailRow("addition", index);
                      }
                    }}
                  >
                    <Icon name="close" />
                  </NewComponentForm.Icon>
                </NewComponentForm.SingleLineFieldsContainer>
              );
            })}
            {!staffPackageForm.hasOwnProperty("id") && (
              <NewComponentForm.AddNewSelectField
                disabled={viewOnlySalaryBrackets}
                onClick={() => {
                  setStaffPackageForm({
                    ...staffPackageForm,
                    addition: [...staffPackageForm.addition, initAddition],
                  });
                }}
              >
                <Translate>Add Earning Option</Translate>
              </NewComponentForm.AddNewSelectField>
            )}
          </NewComponentForm.FieldContainer>
        )}
        <NewComponentForm.Divider> </NewComponentForm.Divider>
        <NewComponentForm.SubHeading
          isOpen={staffPackageForm.deductionsSectionOpen}
          onClick={() => {
            setStaffPackageForm({
              ...staffPackageForm,
              deductionsSectionOpen: !staffPackageForm.deductionsSectionOpen,
            });
          }}
        >
          <Translate>Deductions</Translate>
        </NewComponentForm.SubHeading>
        {staffPackageForm.deductionsSectionOpen && (
          <NewComponentForm.FieldContainer>
            {staffPackageForm.deduction.map((deduction, index) => {
              return (
                <NewComponentForm.SingleLineFieldsContainer key={index}>
                  <Checkbox
                    disabled={viewOnlySalaryBrackets}
                    style={{ marginRight: "15px" }}
                    onChange={() => {
                      let deductions = staffPackageForm.deduction;
                      deductions[index].option.include_in_leave_calc = !deduction.option.include_in_leave_calc;
                      setStaffPackageForm({
                        ...staffPackageForm,
                        deduction: [...deductions],
                      });
                    }}
                    checked={deduction.option.include_in_leave_calc}
                  />
                  <NewComponentForm.AsyncSelect
                    disabled={viewOnlySalaryBrackets}
                    placeholder="Select Deduction Option"
                    value={deduction.option}
                    toDisplay={deduction.option.label}
                  >
                    {generateBracketOptions("deduction", staffPackageForm.deduction).map(
                      (deductionOption, optionIndex) => {
                        return (
                          <NewComponentForm.AsyncSelectOption
                            key={optionIndex}
                            onClick={() => {
                              const newDeductions = staffPackageForm.deduction;
                              newDeductions[index] = {
                                ...newDeductions[index],
                                option: deductionOption,
                              };
                              setStaffPackageForm({ ...staffPackageForm, deduction: [...newDeductions] });
                            }}
                            value={deductionOption}
                          >
                            {deductionOption.label}
                          </NewComponentForm.AsyncSelectOption>
                        );
                      }
                    )}
                  </NewComponentForm.AsyncSelect>
                  <NewComponentForm.PriceFieldContainer>
                    <NewComponentForm.PriceField
                      value={deduction.value}
                      onChange={(e) => {
                        if (e.target.value) {
                          setSalaryBracketFieldError(false);
                        }
                        const newDeductions = staffPackageForm.deduction;
                        newDeductions[index] = {
                          ...newDeductions[index],
                          value: e.target.value,
                        };
                        setStaffPackageForm({ ...staffPackageForm, deduction: [...newDeductions] });
                      }}
                      type="text"
                      placeholder="Value*"
                    />
                  </NewComponentForm.PriceFieldContainer>
                  <NewComponentForm.Icon
                    onClick={() => {
                      if (!staffPackageForm.hasOwnProperty("id")) {
                        removeStaffPackageDetailRow("deduction", index);
                      }
                    }}
                  >
                    <Icon name="close" />
                  </NewComponentForm.Icon>
                </NewComponentForm.SingleLineFieldsContainer>
              );
            })}
            {!staffPackageForm.hasOwnProperty("id") && (
              <NewComponentForm.AddNewSelectField
                disabled={staffPackageForm.hasOwnProperty("id") || viewOnlySalaryBrackets}
                onClick={() => {
                  setStaffPackageForm({
                    ...staffPackageForm,
                    deduction: [...staffPackageForm.deduction, initDeduction],
                  });
                }}
              >
                <Translate>Add Deduction Option</Translate>
              </NewComponentForm.AddNewSelectField>
            )}
          </NewComponentForm.FieldContainer>
        )}
        {/* error showing */}
        {salaryBracketFieldError ? (
          <p style={{ color: "red", width: "100%", textAlign: "right", paddingRight: "10px" }}>
            <Translate>No Field Should Be Empty</Translate>
          </p>
        ) : null}
        <NewComponentForm.SingleLineFieldsContainer>
          {staffPackageForm.hasOwnProperty("id") && (
            <NewComponentForm.SubmitButton style={{ marginLeft: 0 }} onClick={() => resetStaffPackageForm(true)}>
              <Icon name="refresh" /> <Translate>Reset</Translate>
            </NewComponentForm.SubmitButton>
          )}
          <NewComponentForm.SubmitButton
            disabled={staffPackageForm.hasOwnProperty("id")}
            onClick={() => {
              if (isAnyFieldEmpty(staffPackageForm.name, staffPackageForm.addition, staffPackageForm.deduction)) {
                setSalaryBracketFieldError(true);
                return;
              }
              const payload = {
                name: staffPackageForm.name,
                addition: staffPackageForm.addition
                  .filter((item) => item.value !== 0 && item.value !== "")
                  .map((item) => ({
                    name: item.option.value,
                    value: parseFloat(item.value.split(",").join("")),
                    include_in_leave_calc: item.option.include_in_leave_calc,
                  })),
                deduction: staffPackageForm.deduction
                  .filter((item) => item.value !== 0 && item.value !== "")
                  .map((item) => ({
                    name: item.option.value,
                    value: parseFloat(item.value.split(",").join("")),
                    include_in_leave_calc: item.option.include_in_leave_calc,
                  })),
              };
              dispatch(staffSalaryPostData(payload));
              resetStaffPackageForm();
            }}
          >
            <Translate>Create Salary Bracket</Translate>
          </NewComponentForm.SubmitButton>
        </NewComponentForm.SingleLineFieldsContainer>
      </NewComponentForm>
    </SideNav>
  );
}

export default CreateStaffSalaryBrackets;
