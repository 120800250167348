export function getPayments(state = null, action) {
  switch (action.type) {
    case "GET_PAYMENT_SUCCESS":
      return {
        status: true,
        data: action.data,
      };
    case "GET_PAYMENT_RESET":
      return null;
    case "GET_PAYMENT_HAS_ERRORED":
      return false;
    default:
      return state;
  }
}
