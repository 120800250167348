import React, { useEffect } from "react";
import Description from "Description";
import { Translate } from "react-auto-translate";
import { Search } from "../../../new-components";
import { AiOutlineCloudDownload, AiOutlineFileAdd } from "react-icons/ai";
import Toggler from "../../../components/Toggler/index";
import { Icon } from "semantic-ui-react";
import HoverTable from "HoverTable";
import { useSelector } from "react-redux";
import LabelPopUp from "../../../components/LabelPopUp";
import { LabelGroup } from "../../../components/LabelGroup";
import { LoadingState } from "../../../store/reducers/types";
import { productLabelOptionsSelector } from "../../../store/selectors/labelOptions";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import {
  productsPutData,
  postProductLabels,
  PRODUCTS_TABLE_DATA,
  productActiveToggleAction,
  productDataSearch,
  productsFetchData,
} from "../../../store/actions/products";
import exportFromJSON from "export-from-json";
import { productBarcodeMap } from "../../../store/actions/barcode";
import { findObjectById } from "../../../utilities";
import "./index.scss";

const tableHeadersProducts = [
  "Name",
  "Labels/Categories",
  "Quantity",
  "Mfd/Exp Date",
  "Code/Batch/ Serial No.",
  "Action",
];

const renderSpecialProducts = ({ productLabels, productLabelOptions, updateProductLabels }) => {
  return [
    ({ name, company, hsn_sac }) => {
      const companyLabel = company?.name ? `Company:${company?.name}` : "";
      const finalLabel = hsn_sac ? companyLabel.concat(` \n HSN/SAC:${hsn_sac}`) : companyLabel;
      return <Description bold title={name} description={finalLabel} />;
    },
    ({ name, id, labels }) => {
      return (
        <div style={{ maxWidth: "200px", overflow: "auto" }}>
          <LabelPopUp
            trigger={
              <div style={{ maxWidth: "150px" }}>
                <LabelGroup labels={labels?.label_ids || []} options={productLabels} />
              </div>
            }
            name={name}
            options={productLabelOptions}
            onSave={(labelsIds) => {
              updateProductLabels(id, labelsIds);
            }}
            labels={labels?.label_ids || []}
          />
        </div>
      );
    },

    ({ quantity, cost, status }) => {
      return <Description title={quantity} description={cost + "/-"} status={status} />;
    },
    ({ mfg_date, exp_date }) => {
      return <Description title={mfg_date + "/" + exp_date} />;
    },
    ({ code_1 }) => {
      return <Description title={code_1} />;
    },

    (value, edit, remove, select, isSelected, custom, undo) => {
      return value.status === 0 ? (
        <Icon key={0} className="pointer undo" size="large" onClick={() => undo(value.id, value)} />
      ) : (
        [
          <Icon key={1} className="pointer edit" onClick={() => edit(value.id, value)} />,
          <Icon key={2} className="pointer trash" onClick={() => remove(value.id, value)} />,
        ]
      );
    },
  ];
};

function Products({ editProduct, showAddProduct, showAddProductLabels }) {
  const { data: products, loading: productLoadingState } = useSelector((state) => PRODUCTS_TABLE_DATA(state));
  const productLabelOptions = useSelector((state) => productLabelOptionsSelector(state));
  const productLabels = useSelector((state) => state.productLabels.data);
  const productActiveToggle = useSelector((state) => state.productActiveToggle);
  const productBarcodeMapState = useSelector((state) => productBarcodeMap(state));
  const aclUserPermissions = useSelector((state) => state.aclUserPermissions.data);
  const productDataSearchState = useSelector((state) => state.productDataSearch);
  const productloaded = LoadingState.loaded === productLoadingState;
  const dispatch = useDispatch();
  const { formatted_component_permissions } = aclUserPermissions;
  const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

  function undoProduct(id, product) {
    if (product["company"]) {
      product["company"] = product["company"].id;
    }
    dispatch(productsPutData(id, { ...product, status: 1 }));
  }

  function deleteProduct(id, product) {
    let deleteProduct = cloneDeep(product);
    if (deleteProduct["company"]) {
      deleteProduct["company"] = deleteProduct["company"].id;
    }
    dispatch(productsPutData(id, { ...deleteProduct, status: 0 }));
  }

  const updateProductLabels = (product_id, label_ids) => {
    const payload = { product_id, label_ids };
    dispatch(postProductLabels(payload));
  };

  useEffect(() => {
    if (!productloaded) dispatch(productsFetchData());
  }, []);

  function downloadProducts(productLabels) {
    const header = [
      [
        "ID",
        "Code/Batch/Serial No.",
        "Name",
        "Company",
        "Cost",
        "Qty Remaining",
        "Mfd Date",
        "Exp Date",
        "Price(Procurement Price)",
        "Volume(In Ml/Gm)",
        "Barcode",
        "Labels/Categories",
        "HSN/SAC",
        "Status",
      ],
    ];
    const data = [
      ...header,
      ...productBarcodeMapState.map((item) => [
        item.id,
        item.code_1,
        item.name,
        item?.company?.name || "N/A",
        item.cost,
        item.quantity,
        item.mfg_date,
        item.exp_date,
        item.price,
        item.volume,
        item.barcode ? item.barcode.map((ele) => ele.bar_code).join(", ") : "",
        item.labels?.label_ids?.length
          ? item.labels.label_ids
              .map((ele) => {
                const obj = findObjectById(productLabels, ele);
                return obj?.short_name || "";
              })
              .filter((ele) => ele != "")
              .join(", ")
          : "",
        item.hsn_sac || "",
        item.status === 1 ? "Active" : "Inactive",
      ]),
    ];
    const fileName = "all_products";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  }

  return (
    <>
      <h2 className="productHeading">
        <Translate>Products</Translate>
      </h2>
      <div className="controller flex row">
        {/* <h2>
          <Translate>Products</Translate>
        </h2> */}
        <Search>
          <Search.GeneralField
            value={productDataSearchState}
            onChange={(e) => {
              const changedValue = e.target.value;
              dispatch(productDataSearch(changedValue));
            }}
            placeholder="Search product"
          />
        </Search>
        <div className="action-button-group">
          {isDownloadReportsAllowed && (
            <button className="downloadBtn" disabled={isSubscriptionExpired} onClick={() => downloadProducts(productLabels)}>
              <AiOutlineCloudDownload size="20" title="download products" />
            </button>
          )}
          <button className="productBtn" onClick={() => showAddProduct()}>
            <AiOutlineFileAdd size="20" title="add Product" />
            &nbsp;<Translate>Product</Translate>
          </button>
          <button className="labelBtn" onClick={() => showAddProductLabels()}>
            <AiOutlineFileAdd size="20" title="add" />
            &nbsp;<Translate>Label/Category</Translate>
          </button>
        </div>
      </div>
      <Toggler
        toggle
        style={{
          maxWidth: "400px",
          minWidth: "200px",
          marginBottom: "20px",
        }}
        onChange={() => {
          dispatch(productActiveToggleAction(!productActiveToggle));
        }}
        checked={productActiveToggle}
        label={"Active Products"}
      />
      <div className="product-tab-table">
        {productloaded ? (
          <HoverTable
            header={tableHeadersProducts}
            src={products}
            renderSpecial={renderSpecialProducts({
              productLabelOptions,
              productLabels,
              updateProductLabels: updateProductLabels,
            })}
            edit={(id, product) => {
              editProduct(id, product);
            }}
            undo={(id, product) => undoProduct(id, product)}
            remove={(id, product) => deleteProduct(id, product)}
            showDeleted={!productActiveToggle}
          />
        ) : (
          <div className="no-data-error">
            <Translate>No matching products found</Translate>
          </div>
        )}
      </div>
    </>
  );
}

export default Products;
