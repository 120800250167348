import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";
import HoverTable from "HoverTable";
import Description from "Description";
import { Button, Icon, Pagination } from "semantic-ui-react";
import { Search } from "../../new-components";
import _, { debounce } from "lodash";
import { membershipsPutData, membershipsFetchData } from "../../store/actions/memberships";
import MembershipSideNav from "../../components/MembershipSideNav";
import { formatDaysByUnit, combineDayAndUnit } from "../../utilities/CommonFunctions";
import { Translate } from "react-auto-translate";
import Toggler from "../../components/Toggler";
import { servicesFetchData } from "../../store/actions/services";
import { productsFetchData } from "../../store/actions/products";
import { LoadingState } from "../../store/reducers/types";

const tableHeadersMemberships = ["Name", "Validity", "Cost", "Wallet Balance", "Actions"];

const renderSpecialMemberships = [
  ({ name, description, status }) => {
    return <Description style={{color:"#4568D2"}} title={name} description={description} status={status} />;
  },
  ({ validity_in_days, validity_unit }) => {
    const validity = formatDaysByUnit(validity_in_days, validity_unit);
    return <Description title={combineDayAndUnit(validity, validity_unit)} />;
  },
  ({ cost }) => {
    return <Description style={{color:"#4568D2"}} title={cost} />;
  },
  ({ wallet_credit_amount }) => {
    return <Description style={{color:"#77AD65"}} title={`Rs. ${wallet_credit_amount}/-`} />;
  },
  (value, edit, remove) => {
    return value.status === false ? (
      <Icon key={0} className="dont" />
    ) : (
      [
        <Icon key={0} className="pointer" name="edit" size="large" onClick={() => edit(value)} />,
        <Icon key={1} className="pointer" name="trash" size="large" onClick={() => remove(value.id, value)} />,
      ]
    );
  },
];
{console.log(renderSpecialMemberships,"check here memeber")};


const Membership = () => {
  const dispatch = useDispatch();
  const memberships = useSelector((state) => state.memberships);
  const services = useSelector((state) => state.services);
  const products = useSelector((state) => state.products);

  const [createMembership, setCreateMembership] = useState(false);
  const [membershipForm, setMembershipForm] = useState({});
  const [activeMembershipOnly, setActiveMembershipOnly] = useState(1);
  const [search, setSearch] = useState("");

  const services_loaded = services.loading === LoadingState.loaded;
  const products_loaded = products.loading === LoadingState.loaded;

  useEffect(() => {
    dispatch(membershipsFetchData({}));
    if (!services_loaded) {
      dispatch(servicesFetchData());
    }
    if (!products_loaded) {
      dispatch(productsFetchData());
    }
  }, []);

  const deleteMembership = (id, membership) => {
    membership = { ...membership, status: 0 };
    dispatch(membershipsPutData(id, membership));
  };

  const handleSearchDebounce = useCallback(
    // it is required to get these url, fields, field arguments from calling function
    // otherwise it will use the previous arguments only ex. customer.number
    debounce(({ status, search }) => {
      dispatch(
        membershipsFetchData({
          status,
          search,
        })
      );
    }, 300),
    []
  );

  const handleSearch = (e) => {
    const changedValue = e.target.value;
    handleSearchDebounce({
      status: activeMembershipOnly,
      search: changedValue,
    });
    setSearch(changedValue);
  };

  const handleToggler = () => {
    const status = activeMembershipOnly ? 0 : 1;

    dispatch(
      membershipsFetchData({
        status,
        search,
      })
    );
    setActiveMembershipOnly(status);
  };

  return (
    <div className="memberships">
      <MembershipSideNav
        createMembership={createMembership}
        setCreateMembership={setCreateMembership}
        membershipForm={membershipForm}
        setMembershipForm={setMembershipForm}
      />
      <div className="create-btn">
        <div>
          <Search className="search-field">
            <Search.GeneralField value={search} onChange={handleSearch} placeholder="Search Membership" />
          </Search>
        </div>
        <Button style={{ marginLeft: "16px" }} onClick={() => setCreateMembership(true)}>
          <Translate>Create</Translate>
        </Button>
      </div>
      <HoverTable
        header={tableHeadersMemberships}
        src={memberships.results}
        renderSpecial={renderSpecialMemberships}
        remove={(id, membership) => deleteMembership(id, membership)}
        edit={(membership) => setMembershipForm(membership)}
        showDeleted={!activeMembershipOnly}
      />
      <div className="pages flex width100">
        <Pagination
          firstItem={false}
          lastItem={false}
          activePage={memberships?.current_page || 1}
          totalPages={memberships?.pages || 1}
          onPageChange={(event, val) => {
            dispatch(
              membershipsFetchData({
                page: val.activePage,
                status: activeMembershipOnly,
                search,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default Membership;
