import { LoadingState } from "./types";

const initialState = {
  data: [],
  loading: LoadingState.unset,
};

export function inventoryDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case "INVENTORY_DETAILS_FETCH_DATA_LOADING":
      return {
        ...state,
        ...action.data,
      };
    case "INVENTORY_DETAILS_FETCH_DATA_SUCCESS":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
