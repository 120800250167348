import { API_BASE_URL, GET_COUNTRIES, GET_STATES, GET_CITIES } from "utilities";
import axios from "axios";
export const getCountries = () => {
  let URL = API_BASE_URL + GET_COUNTRIES;

  return (dispatch) => {
    dispatch({
      type: "order/addresses/countries",
      data: [],
      isFetching: true,
    });
    axios
      .get(URL)
      .then(function (response) {
        dispatch({
          type: "order/addresses/countries",
          data: response.data?.results || [],
          isFetching: false,
        });
      })
      .catch(function (error) {});
  };
};

export const getStates = () => {
  let URL = API_BASE_URL + GET_STATES;

  return (dispatch) => {
    dispatch({
      type: "order/addresses/states",
      data: [],
      isFetching: true,
    });
    axios
      .get(URL)
      .then(function (response) {
        dispatch({
          type: "order/addresses/states",
          data: response.data?.results || [],
          isFetching: false,
        });
      })
      .catch(function (error) {});
  };
};

export const getCities = (id) => {
  let URL = API_BASE_URL + GET_CITIES + "?";

  if (id) {
    URL += `search=${id}&`;
  }

  return (dispatch) => {
    dispatch({
      type: "order/addresses/cities",
      data: [],
      isFetching: true,
    });
    axios
      .get(URL)
      .then(function (response) {
        dispatch({
          type: "order/addresses/cities",
          data: response.data?.results || [],
          isFetching: false,
        });
      })
      .catch(function (error) {});
  };
};
