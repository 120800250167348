const initialState = {
  loading: false,
  activeCategories: [],
  deletedCategories: [],
  error: false,
};

export function expenseCategories(state = initialState, action) {
  switch (action.type) {
    case "EXPENSE_CATEGORIES_FETCH_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "EXPENSE_ACTIVE_CATEGORIES_FETCH_SUCCESS":
      return {
        ...state,
        activeCategories: action.payload,
      };
    case "EXPENSE_DELETED_CATEGORIES_FETCH_SUCCESS":
      return {
        ...state,
        deletedCategories: action.payload,
      };
    case "EXPENSE_CATEGORIES_FETCH_FAILURE":
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
}
