import React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import ui from "../../mockData";
import { connect } from "react-redux";
import _ from "lodash";

class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      width: 60,
    };
  }
  timer = "";

  //Calculate & Update state of new dimensions
  updateDimensions() {
    //debouncing
    clearTimeout(this.timer);
    this.timer = null;

    this.timer = setTimeout(() => {
      if (window.innerWidth <= 420) {
        this.setState({ width: 0 }); //mobile view
      } else {
        this.setState({ width: 60 }); //except mobile view
      }
    }, 100);
  }

  // Add event listener
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  // Remove event listener
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  handleMobileQuitClick = () => {
    document.getElementById("side-bar").style.width = 0;
    document.getElementById("mobile_quit").style.display = "none";
  };

  render() {
    const { pathMap } = this.props;
    const { sidebarLinksMap } = ui;
    const routes = [];

    if (!pathMap) {
      return null;
    }

    sidebarLinksMap.forEach((linkMap, index) => {
      const { link, name, icon } = linkMap;

      if (link in pathMap && pathMap[link]) {
        routes.push(
          <li key={index}>
            <NavLink exact to={link}>
              <span className="menu-icon">
                <Icon color='grey' name={icon} />
              </span>

              <span className="menu-item">
                <Translate>{name}</Translate>
              </span>
            </NavLink>
          </li>
        );
      }
    });

    return [
      <div key={1} id={"mobile_quit"} className="mobile_quit" onClick={this.handleMobileQuitClick}>
        &nbsp;
      </div>,
      <div
        key={2}
        id="side-bar"
        style={{ width: this.state.width }}
        className="side-bar"
        onMouseEnter={() => this.setState({ width: 200 })}
        onMouseLeave={() => this.setState({ width: 60 })}
      >
        <div className="logo" />
        <ul>{routes}</ul>
        <div className="customer-care only-mobile">
          <span>Customer Care:</span>
          <br />
          <a href="tel:+919686636655"> +91-9686636655</a> /<a href="mailto:cx@glamplus.in"> cx@glamplus.in </a>
        </div>
      </div>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    branchSubscriptionsServices: state.branchSubscriptionsServices,
  };
};

export default connect(mapStateToProps)(Sidebar);
