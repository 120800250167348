import axios from "axios";
import { API_BASE_URL } from "../utilities";
import ToastAlert from "../utilities/toastAlert/toastAlert";

import configureStore from "../store/configureStore";
import { authenticationLogout } from "../store/actions/authentication";

const store = configureStore();

export async function updateCustomerLoyaltyPointsApi({ data, adding = false }) {
  const url = API_BASE_URL + "loyalty_point/";
  const headers = {
    Authorization: localStorage.getItem("token"),
  };

  try {
    const response = await axios({
      method: "post",
      url,
      data,
      headers,
    });

    let toastMessage = "";
    if (adding) {
      toastMessage = "Loyalty Point Added Successfully";
    } else {
      toastMessage = "Loyalty Point Redeemed Successfully";
    }

    ToastAlert({ message: toastMessage, type: "success" });
    return { status: "success", response };
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 400) {
        ToastAlert({ message: JSON.stringify(error.response?.data), type: "error" });
      } else if (error.response.status === 401) {
        store.dispatch(authenticationLogout());
      }
    }
    const message = error.response?.data?.non_field_errors[0];

    ToastAlert({ message, type: "error" });
    return { status: "error", error };
  }
}
