import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { Input, Button, Form, Dropdown, Icon } from "semantic-ui-react";
import moment from "moment";
import { Translate } from "react-auto-translate";

import { calcDateDifference } from "../../../../utilities/CommonFunctions";
import ProductDetailForm from "./ProductDetailForm";
import { useSelector } from "react-redux";

const initProductDetail = {
  product: null,
  quantity: "",
  total_price_calculated: 0,
  discount: 0,
  discount_type: 0,
  mrp: "",
  cost_price: "",
  mfg_date: null,
  exp_date: null,
  gst_bracket: null,
};

const CalculationInfo = () => {
  return (
    <div style={{ width: "80%" }}>
      <p className="info" style={{ fontSize: "16px", lineHeight: "30px" }}>
        <Translate>
          If transaction type is <b>IN</b> then calculation will happen on (Procurement Price)
        </Translate>
        <br></br>
        <Translate>
          If transaction type is <b>OUT</b> then calculation will happen on Cost Price(MRP)
        </Translate>
      </p>
      <p className="info" style={{ fontSize: "16px" }}>
        <Translate>
          <b>Total Calculated Transaction Amount =</b> Sum of all the Calculated Total Price in Products.
        </Translate>
      </p>
    </div>
  );
};

function InventoryDetailsForm({ data = null, onAddUpdate, onClose }) {
  const { sellers, sellersMap } = useSelector((state) => state.sellers);
  const productsMap = useSelector((state) => state.productsMap);

  const [form, setForm] = useState({
    seller: null,
    date_time: "",
    in_out: 1, //1 = in and 0 = out
    total_calculated_transaction_amount: "",
    product_details: [{ ...initProductDetail }],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sellersOptions, setSellersOptions] = useState([]);

  useEffect(() => {
    const _sellersOptions = getSellersOptions(sellers);
    setSellersOptions(_sellersOptions);
  }, [sellers]);

  const getSellersOptions = (sellers) => {
    const sellersOptions = sellers
      .filter((seller) => seller.is_active)
      .map((seller) => {
        const { id, name = "", contact } = seller;

        const slicingPoint = 15;
        const slicedName = name.length > slicingPoint ? `${name.slice(0, slicingPoint)}...` : name;

        // formatting to make it uniquely identified
        const text = `${slicedName}   |   ${contact}`;

        return {
          key: id,
          value: id,
          text,
        };
      });

    return sellersOptions;
  };

  useEffect(() => {
    if (data) {
      const date_time = moment(data.date_time).utc().format("YYYY-MM-DDTHH:mm");

      // for checking if user is entering more than available + prevQuanity for OUT status
      const productDetailsWithPrevData = data.product_details.map((detail) => {
        const prevProduct = {
          product: detail.product,
          quantity: detail.quantity,
        };

        return {
          ...detail,
          prevProduct,
        };
      });

      setForm({
        ...data,
        date_time,
        product_details: productDetailsWithPrevData,
      });

      setIsEditing(true);
    }
  }, [data]);

  const addProductDetailRow = () => {
    setForm({
      ...form,
      product_details: [...form.product_details, { ...initProductDetail }],
    });
  };

  const removeProductDetailRow = (index) => {
    const filteredRow = form.product_details.filter((detail, i) => i !== index);

    setForm({
      ...form,
      product_details: [...filteredRow],
    });
  };

  const handleProductDetailUpdate = (index, data) => {
    // for auto calculating the total_calculated_transaction_amount
    let total_calculated_transaction_amount = 0;

    //calculating total_calculated_transaction_amount also
    const updatedProductDetails = form.product_details.map((detail, i) => {
      let responseData = detail;
      if (i === index) {
        responseData = data;
      }

      total_calculated_transaction_amount += Number(responseData.total_price_calculated);

      return responseData;
    });

    total_calculated_transaction_amount = total_calculated_transaction_amount.toFixed(2);

    setForm({
      ...form,
      total_calculated_transaction_amount,
      product_details: [...updatedProductDetails],
    });
  };

  const handleInputFieldChange = (value, key) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const isAnyMandatoryFieldEmpty = (data) => {
    const { seller, in_out, total_calculated_transaction_amount, date_time, product_details } = data;
    let errorMessage = "";

    if (seller === null || in_out === -1 || !parseFloat(total_calculated_transaction_amount) || !date_time) {
      errorMessage = "No Required Field Should Be Empty Or Invalid";
      return errorMessage;
    }

    if (product_details.length > 0) {
      for (const detail of product_details) {
        const {
          id,
          product,
          quantity,
          total_price_calculated,
          discount,
          mrp,
          cost_price,
          mfg_date,
          exp_date,
          prevProduct,
        } = detail;

        if (
          product === null ||
          !parseInt(quantity) ||
          parseFloat(discount) < 0 ||
          !parseFloat(mrp) ||
          !parseFloat(cost_price) ||
          !total_price_calculated ||
          !mfg_date ||
          !exp_date
        ) {
          errorMessage = "No Required Field Should Be Empty Or Invalid";
          return errorMessage;
        }
        //
        const productData = productsMap[product];

        if (isEditing && form.in_out === 0) {
          let totalProductQuantity = productData.quantity;

          if (id && product === prevProduct.product) {
            totalProductQuantity += prevProduct.quantity;
          }

          if (totalProductQuantity <= 0) {
            errorMessage = "Can not select Product that is out of stock for OUT status";
            return errorMessage;
          }

          if (Number(quantity) > Number(totalProductQuantity)) {
            errorMessage = "Product Quantity can not be more than available product quantity";
            return errorMessage;
          }
        }
        //
        if (calcDateDifference(mfg_date, exp_date) <= 0) {
          errorMessage = "Expiry date should be greater than Manufacturing date";
          return errorMessage;
        }
      }
    }

    return false;
  };

  const addUpdateHandler = (type, data) => {
    // if not product detail is provided
    if (data.product_details.length === 0) {
      const errorMessage = "Please provide atleast one product detail";
      setErrorMessage(errorMessage);
      return false;
    }

    const errorMessage = isAnyMandatoryFieldEmpty(data);

    if (errorMessage.length > 0) {
      setErrorMessage(errorMessage);
      return false;
    }

    const formData = {
      ...data,
      product_details: data.product_details.map((detail) => {
        return {
          ...detail,
          discount: detail.discount || 0,
        };
      }),
    };

    setErrorMessage("");
    onAddUpdate(type, formData);
  };

  const getDeletedSellerInfo = (detail) => {
    const { name = "", contact } = detail;

    const slicingPoint = 15;
    const slicedName = name.length > slicingPoint ? `${name.slice(0, slicingPoint)}...` : name;

    // formatting to make it uniquely identified
    const text = `${slicedName}   |   ${contact}`;

    return text;
  };

  const isSellerDeleted = form.seller && !sellersMap[form.seller].is_active;
  const deletedSellerInfo = isSellerDeleted && getDeletedSellerInfo(sellersMap[form.seller]);
  const labelClassname = isSellerDeleted ? "deleted-label" : "text-theme-black";

  return (
    <div className="inventory-details-form">
      <Icon className="close pointer closeIcon" style={{ padding: "10px" }} onClick={onClose} />
      {/*  */}
      <h2 style={{ padding: "20px" ,fontWeight:"600"}}>
        <Translate>Inventory Details Form</Translate>
      </h2>
      {/*  */}
      <div style={{ padding: "20px" }}>
        <div className="input-fields">
          <Form.Field className="radio-field" style={{ marginRight: "20px" }}>
            <strong className={labelClassname}>
              <Translate>IN</Translate>/<Translate>OUT</Translate>*
            </strong>
            <div style={{ display: "flex", gap: "10px", alignItems: "center", height: "40px" }}>
              <Form.Radio
                disabled={isEditing}
                label={"IN"}
                checked={form.in_out === 1}
                onChange={() => {
                  handleInputFieldChange(1, "in_out");
                }}
              />
              <Form.Radio
                disabled={isEditing}
                label={"OUT"}
                checked={form.in_out === 0}
                onChange={() => {
                  handleInputFieldChange(0, "in_out");
                }}
              />
            </div>
          </Form.Field>
          {/*  */}
          <Form.Field className="dropdown-field">
            {!isSellerDeleted && (
              <>
                <strong className={labelClassname}>
                  <Translate>Seller/Branch/Third Party*</Translate>
                </strong>
                <Dropdown
                  className="dropdown"
                  placeholder="Select Seller/Branch/Third Party"
                  value={form.seller}
                  onChange={(e, { value }) => {
                    handleInputFieldChange(value, "seller");
                  }}
                  options={sellersOptions}
                  selection
                  fluid
                  search
                />
              </>
            )}
            {isSellerDeleted && (
              <>
                <strong className={labelClassname}>
                  <Translate>Seller/Branch/Third Party*</Translate>{" "}
                  <span style={{ color: "red" }}>
                    <Translate>(Deleted)</Translate>
                  </span>
                </strong>
                <Input
                  disabled={isSellerDeleted}
                  className="input"
                  type="text"
                  placeholder="Select Seller/Branch/Third Party"
                  value={deletedSellerInfo}
                />
              </>
            )}
          </Form.Field>
          {/*  */}
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Date & Time*</Translate>
            </strong>
            <Input
              className="input"
              type="datetime-local"
              placeholder="Date & Time"
              value={form.date_time}
              onChange={(e) => {
                handleInputFieldChange(e.target.value, "date_time");
              }}
            />
          </Form.Field>
          {/*  */}
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Total Transaction</Translate>
            </strong>
            <Input
              disabled={true}
              className="input"
              type="number"
              placeholder="Total Transaction"
              value={form.total_calculated_transaction_amount}
              onChange={(e) => {
                handleInputFieldChange(e.target.value, "total_calculated_transaction_amount");
              }}
            />
          </Form.Field>
          {/*  */}
        </div>
        <hr></hr>
        {/* product details form */}
        {form.product_details?.length > 0 && (
          <div>
            <h3 style={{ marginTop: "20px" }}>
              <label className="text-theme-black">
                <Translate>Product Details</Translate>
              </label>
            </h3>
            <div style={{ maxHeight: "400px", overflow: "auto", padding: "0px 15px" }}>
              {form.product_details.map((detail, index) => {
                return (
                  <ProductDetailForm
                    key={index}
                    index={index}
                    isEditing={isEditing}
                    in_out={form.in_out}
                    formData={{ ...detail }}
                    onChange={handleProductDetailUpdate}
                    onRemove={removeProductDetailRow}
                  />
                );
              })}
            </div>
          </div>
        )}
        {/* add product detail button */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={addProductDetailRow}>
            <Icon name="add" />
            <Translate>Product Detail</Translate>
          </Button>
        </div>
      </div>
      {/* action buttons */}
      {errorMessage.length > 0 && (
        <p style={{ color: "red", padding: "20px", fontSize: "16px" }}>
          <Translate>{errorMessage}</Translate>
        </p>
      )}
      {isSellerDeleted && (
        <p style={{ color: "red", padding: "20px", fontSize: "16px" }}>
          <Translate>Can not update the details, Seller/Branch/Third Party has been deleted</Translate>
        </p>
      )}
      {/* calculation info */}
      <CalculationInfo />
      {/*  */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
        {isEditing && (
          <Button
            disabled={isSellerDeleted}
            onClick={() => {
              addUpdateHandler(1, form);
            }}
          >
            <Translate>Update</Translate>
          </Button>
        )}
        {!isEditing && (
          <Button
            onClick={() => {
              addUpdateHandler(0, form);
            }}
          >
            <Translate>Add</Translate>
          </Button>
        )}
      </div>
    </div>
  );
}

export default InventoryDetailsForm;
