export const BRACKET = {
  addition: [
    {
      id: 0,
      value: "basic",
      label: "Basic",
    },
    {
      id: 1,
      value: "da",
      label: "Dearness Allowance",
    },
    {
      id: 2,
      value: "hra",
      label: "House Rent Allowance",
    },
    {
      id: 3,
      value: "conveyance",
      label: "Conveyance",
    },
    {
      id: 4,
      value: "special_allowance",
      label: "Special Allowance",
    },
    {
      id: 5,
      value: "incentive",
      label: "Incentive",
    },
    {
      id: 6,
      value: "bonus",
      label: "Bonus",
    },
    {
      id: 7,
      value: "overtime",
      label: "Overtime",
    },
    {
      id: 8,
      value: "other",
      label: "Other",
    },
  ],
  deduction: [
    {
      id: -1,
      value: "null",
      label: "Advance",
    },
    {
      id: 0,
      value: "pf",
      label: "Provident Fund",
    },
    {
      id: 1,
      value: "esi",
      label: "Employee State Insurance",
    },
    {
      id: 2,
      value: "vpa",
      label: "Variable Personal Allowance",
    },
    {
      id: 3,
      value: "income_tax",
      label: "Income Tax",
    },
    {
      id: 4,
      value: "professional_tax",
      label: "Professional Tax",
    },
    {
      id: 5,
      value: "other_loan",
      label: "Other Loan",
    },
    {
      id: 6,
      value: "other_deduction",
      label: "Other Deduction",
    },
    {
      id: 7,
      value: "labour_welfare",
      label: "Labour Welfare Fund",
    },
    {
      id: 8,
      value: "food_coupon",
      label: "Food Coupon",
    },
    {
      id: 9,
      value: "loan",
      label: "Loan",
    },
    {
      id: 10,
      value: "notice_recovery",
      label: "Notice Recovery",
    },
    {
      id: 11,
      value: "others",
      label: "Others",
    },
  ],
};
