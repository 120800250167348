import React from "react";
import { Popup, Icon } from "semantic-ui-react";

function ClientSelectionPreview({ excludedClientsPreview, selectedClientsPreview }) {
  let content = "";
  let title = "";
  let count = 0;

  if (excludedClientsPreview) {
    content = excludedClientsPreview.join(", ");
    title = "Excluded Clients ";
    count = excludedClientsPreview.length;
  } else if (selectedClientsPreview) {
    content = selectedClientsPreview.join(", ");
    title = "Selected Clients ";
    count = selectedClientsPreview.length;
  } else {
    return null;
  }

  return (
    <div>
      <strong>
        {count} {title}
      </strong>
      {count > 0 && <Popup  trigger={<Icon name="info circle" size="large" />} content={content} />}
    </div>
  );
}

export default ClientSelectionPreview;
