import React, { useState, useEffect } from "react";
import "./index.scss";
import HoverTable from "HoverTable";
import Description from "Description";
import { Icon, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Form as NewComponentForm } from "../../new-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  staffOvertimeFetchData,
  staffOvertimePatchData,
  staffOvertimePostData,
  staffOvertimeDeleteData,
} from "../../store/actions/overtime";
import { Translate } from "react-auto-translate";

const OvertimeDOM = ({ overtimeStaffDetails }) => {
  const dispatch = useDispatch();
  const [overtimeFormData, setOvertimeFormData] = useState({
    selectedMonth: new Date().toISOString().slice(0, 7),
    selectedStaff: { user: { name: "Staff" } },
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    endDate: moment().format("YYYY-MM-DDTHH:mm"),
    overtimeEditMode: false,
    overtimeEditID: 0,
    overtimeDetailSection: false,
    duration_in_hours: 0,
    overtimeId: null,
  });
  const overtimeHeaders = ["Start Date", "End Date", "Duration (in hrs)", "Staff", "Action"];
  const overtimeList = useSelector((state) => state.staffOvertime);

  const staffOvertimeFormat = () => {
    const formattedOvertimeAttendance = overtimeList.map((overtime) => {
      return {
        ...overtime,
        staff: overtimeFormData.selectedStaff,
      };
    });
    return formattedOvertimeAttendance;
  };

  useEffect(() => {
    if (overtimeStaffDetails) {
      setOvertimeFormData({
        ...overtimeFormData,
        selectedStaff: overtimeStaffDetails,
      });
    }
    if (overtimeFormData.overtimeEditMode) {
      setOvertimeFormData({
        selectedMonth: new Date().toISOString().slice(0, 7),
        selectedStaff: { user: { name: "Staff" } },
        startDate: moment().format("YYYY-MM-DDTHH:mm"),
        endDate: moment().format("YYYY-MM-DDTHH:mm"),
        overtimeEditMode: false,
        overtimeEditID: 0,
        overtimeDetailSection: false,
        duration_in_hours: 0,
        overtimeId: null,
      });
    }
  }, [overtimeStaffDetails]);

  const dateChangeOvertime = (selected_month) => {
    const monthYear = moment(selected_month).format("YYYY-MM");
    setOvertimeFormData({
      ...overtimeFormData,
      selectedMonth: monthYear,
    });
    const selectedDate = new Date(monthYear);
    dispatch(
      staffOvertimeFetchData(GetStartDate(selectedDate), GetEndDate(selectedDate), overtimeFormData.selectedStaff.id)
    );
  };

  const overtimeEdit = (editData) => {
    setOvertimeFormData({
      ...overtimeFormData,
      overtimeEditMode: true,
      startDate: moment(editData.start_datetime).utc().format("YYYY-MM-DDTHH:mm"),
      endDate: moment(editData.end_datetime).utc().format("YYYY-MM-DDTHH:mm"),
      duration_in_hours: editData.duration_in_hours,
      overtimeId: editData.id,
    });
  };

  const removeOvertime = (id, staff) => {
    const selectedDate = new Date(overtimeFormData.selectedMonth);
    dispatch(staffOvertimeDeleteData(id, staff.id, selectedDate));
  };

  function GetStartDate(givenDate = false) {
    var startDate;
    if (givenDate) {
      startDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
    } else {
      var date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    }

    return moment(startDate).format("YYYY-MM-DD");
  }

  function GetEndDate(givenDate = false) {
    var endDate;
    if (givenDate) {
      endDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);
    } else {
      var date = new Date();
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    return moment(endDate).format("YYYY-MM-DD");
  }

  const overtimeRenderSpecial = [
    ({ start_datetime }) => {
      return <Description title={moment(start_datetime).utc().format("DD/MM/YY LT")} />;
    },
    ({ end_datetime }) => {
      return <Description title={moment(end_datetime).utc().format("DD/MM/YY LT")} />;
    },
    ({ duration_in_hours }) => {
      return <Description title={duration_in_hours} />;
    },
    ({ staff }) => {
      return <Description title={staff.user.name} />;
    },
    (value, edit, remove) => {
      return value.status === 0 ? (
        <Icon key={0} className="dont" />
      ) : (
        [
          <Icon key={0} className="pointer" name="edit" onClick={() => edit(value.id, value)} />,
          <Icon key={1} className="pointer trash" onClick={() => remove(value.id)} />,
        ]
      );
    },
  ];

  const resetOvertime = () => {
    setOvertimeFormData({
      ...overtimeFormData,
      overtimeEditMode: false,
      startDate: moment().format("YYYY-MM-DDTHH:mm"),
      endDate: moment().format("YYYY-MM-DDTHH:mm"),
      duration_in_hours: 0,
    });
  };

  const patchOvertime = () => {
    const payload = {
      id: overtimeFormData.overtimeId,
      staff_id: overtimeFormData.selectedStaff.id,
      start_datetime: overtimeFormData.startDate,
      end_datetime: overtimeFormData.endDate,
      duration_in_hours: overtimeFormData.duration_in_hours || 0,
    };
    const selectedDate = new Date(overtimeFormData.selectedMonth);
    dispatch(
      staffOvertimePatchData(overtimeFormData.overtimeId, payload, overtimeFormData.selectedStaff.id, selectedDate)
    );
    resetOvertime();
  };

  const postOvertime = () => {
    const selectedDate = new Date(overtimeFormData.selectedMonth);
    dispatch(
      staffOvertimePostData(
        {
          staff_id: overtimeFormData.selectedStaff.id,
          start_datetime: overtimeFormData.startDate,
          end_datetime: overtimeFormData.endDate,
          duration_in_hours: overtimeFormData.duration_in_hours || 0,
        },
        overtimeFormData.selectedStaff.id,
        selectedDate
      )
    );
    resetOvertime();
  };
  return (
    <NewComponentForm>
      <NewComponentForm.Title>
        <Translate>Overtime Details for</Translate> {overtimeFormData?.selectedStaff?.user?.name}
      </NewComponentForm.Title>
      <NewComponentForm.Divider> </NewComponentForm.Divider>
      <div className="StaffAttendanceChangeMonth">
        <h4 className="StaffAttendanceSubText">
          <Translate>Show Overtime by month</Translate>
        </h4>
        <DatePicker
          selected={moment(overtimeFormData.selectedMonth, "YYYY-MM").toDate()}
          onChange={(date) => {
            dateChangeOvertime(date);
          }}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          customInput={<Input disabled={true} icon="calendar" />}
        />
      </div>
      <NewComponentForm.Divider> </NewComponentForm.Divider>
      <NewComponentForm.SubHeading
        isOpen={overtimeFormData.overtimeDetailSection}
        onClick={() => {
          setOvertimeFormData({
            ...overtimeFormData,
            overtimeDetailSection: !overtimeFormData.overtimeDetailSection,
          });
        }}
      >
        <Translate>Overtime Details</Translate>
      </NewComponentForm.SubHeading>
      <p>
        *<Translate> Start date and End date is for tracking purposes only</Translate>
      </p>
      {overtimeList.length > 0 && (
        <HoverTable
          header={overtimeHeaders}
          src={staffOvertimeFormat()}
          renderSpecial={overtimeRenderSpecial}
          edit={(valueId, value) => {
            overtimeEdit(value);
          }}
          remove={(valueId) => {
            removeOvertime(valueId, overtimeFormData.selectedStaff);
          }}
        />
      )}
      <div style={{ margin: "10px" }}></div>
      <h4 className={overtimeFormData.overtimeEditMode ? "StaffEditModeON" : "StaffEditModeOFF"}>
        <Translate>Edit mode :</Translate> {overtimeFormData.overtimeEditMode ? "ON" : "OFF"}
      </h4>
      <h4 style={{ marginTop: "5px" }}>
        <Translate>Update Overtime</Translate>
      </h4>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <NewComponentForm.DateContainer>
          <NewComponentForm.Date
            value={overtimeFormData.startDate}
            onChange={(e) => {
              const value = e.target.value;
              setOvertimeFormData({
                ...overtimeFormData,
                startDate: value,
              });
            }}
            placeholder="Start Date"
            type="datetime-local"
          />
        </NewComponentForm.DateContainer>
        <NewComponentForm.DateContainer>
          <NewComponentForm.Date
            value={overtimeFormData.endDate}
            onChange={(e) => {
              const value = e.target.value;
              setOvertimeFormData({
                ...overtimeFormData,
                endDate: value,
              });
            }}
            placeholder="End Date"
            type="datetime-local"
          />
        </NewComponentForm.DateContainer>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <NewComponentForm.PriceFieldContainer>
          <NewComponentForm.PriceField
            value={overtimeFormData.duration_in_hours}
            onChange={(e) => {
              setOvertimeFormData({
                ...overtimeFormData,
                duration_in_hours: e.target.value,
              });
            }}
            type="number"
            placeholder="Hours"
          />
        </NewComponentForm.PriceFieldContainer>
        <NewComponentForm.SubmitButton
          disabled={!overtimeStaffDetails?.status ? true : false}
          onClick={() => {
            if (overtimeFormData.overtimeEditMode) {
              patchOvertime();
            } else {
              postOvertime();
            }
          }}
        >
          <Translate>{overtimeFormData.overtimeEditMode ? "Update Overtime" : "Add Overtime"}</Translate>
        </NewComponentForm.SubmitButton>
      </div>
    </NewComponentForm>
  );
};

export default OvertimeDOM;
