import axios from "axios";
import { API_BASE_URL } from "../../utilities";
import { GET_RELEASE_NOTES } from "../../utilities";
import moment from "moment";
import { LoadingState } from "../reducers/types";

export function getReleaseNotesLoading() {
  return {
    type: "RELEASE_NOTES_FETCH_LOADING",
    data: {
      loading: LoadingState.loading,
      data: [],
    },
  };
}

export function getReleaseNotesSuccess(data) {
  return {
    type: "RELEASE_NOTES_FETCH_SUCCESS",
    data: {
      loading: LoadingState.loaded,
      data: data,
    },
  };
}

export function getReleaseNotesFail() {
  return {
    type: "RELEASE_NOTES_FETCH_ERROR",
    data: {
      loading: LoadingState.failed,
      data: [],
    },
  };
}

export function openReleaseNotes() {
  return {
    type: "OPEN_RELEASE_NOTES",
    data: {
      open: true,
    },
  };
}

export function closeReleaseNotes() {
  return {
    type: "CLOSE_RELEASE_NOTES",
    data: {
      open: false,
    },
  };
}

export function readReleaseNotes(status) {
  return {
    type: "READ_RELEASE_NOTES",
    data: {
      read: status,
    },
  };
}

export function getReleaseNotes({ pageNo = 1 }) {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + GET_RELEASE_NOTES + `?page=${pageNo}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(getReleaseNotesSuccess(response.data));
          if (
            response.data.results?.length > 0 &&
            moment(response.data.results?.[0]?.release_date, "YYYY-MM-DD").isAfter(moment(), "hour")
          ) {
            dispatch(readReleaseNotes(false));
          } else {
            dispatch(readReleaseNotes(true));
          }
        } else {
          dispatch({
            type: "RELEASE_NOTES_FETCH_ERROR",
          });
        }
      })
      .catch((error) => {});
  };
}
