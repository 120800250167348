import React, { useEffect, useState } from "react";
import Description from "Description";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import HoverTable from "HoverTable";
import { Translate } from "react-auto-translate";
import { Form as NewComponentForm } from "../../../new-components";
import { PRODUCTS_USAGE_DATA, productUsageFetchData } from "../../../store/actions/productUsage";
import ToastAlert from "../../../utilities/toastAlert/toastAlert";
import exportFromJSON from "export-from-json";
import moment from "moment";
import "./index.scss";
import { LoadingState } from "../../../store/reducers/types";

const productUsageHeader = [
  "Appointment Info",
  "Service Name",
  "Product Name",
  "Product Usage(in ml/gm)",
  "Quantity(in ml/gm)",
  "Action",
];

const renderProductUsage = ({ invoice_pre_text = "GMPS" }) => {
  return [
    ({ appointment_id }) => {
      return <Description bold title={"#" + invoice_pre_text + appointment_id} />;
    },
    ({ service_name }) => {
      return <Description bold title={service_name} />;
    },
    ({ product_name }) => {
      return <Description bold title={product_name} />;
    },
    ({ consumption_value }) => {
      return <Description title={consumption_value} />;
    },
    ({ product_remaining_quantity }) => {
      return <Description title={product_remaining_quantity} />;
    },
    (value, edit, remove) => {
      return value.status === 0 ? (
        <Icon key={0} className="dont" size="large" />
      ) : (
        [
          <Icon key={1} className="pointer edit" onClick={() => edit(value.id, value)} />,
          <Icon key={2} className="pointer trash" onClick={() => remove(value.id, value)} />,
        ]
      );
    },
  ];
};

function ProductUsage({ editProductUsage, deleteProductUsage }) {
  const [productUsageDate, setProductUsageDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const dispatch = useDispatch();
  const productUsageLoading = useSelector((state) => state.productUsage.loading);
  const PRODUCTS_USAGE = useSelector((state) => PRODUCTS_USAGE_DATA(state));
  const branchData = useSelector((state) => state.branch.data);
  const aclUserPermissions = useSelector((state) => state.aclUserPermissions.data);
  const { formatted_component_permissions } = aclUserPermissions;
  const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

  useEffect(() => {
    if (productUsageLoading !== LoadingState.loaded) {
      const start_date = moment().format("YYYY-MM-DD");
      const end_date = start_date;
      dispatch(productUsageFetchData(start_date, end_date, false, false, true));
    }
  }, []);

  function downloadUsageProducts(input) {
    if (input.length > 0) {
      const data = input.map((item) => {
        return {
          "Appointment Info": `#${branchData?.invoice_pre_text}${item.appointment_id}`,
          "Service Name": item.service_name,
          "Product Name": item.product_name,
          "Product Usage(In Ml/Gm)": item.consumption_value,
          "Quantity(In Ml/Gm)": item.product_remaining_quantity,
        };
      });
      const fileName = "usage_products";
      const exportType = "csv";
      exportFromJSON({ data, fileName, exportType });
    } else {
      ToastAlert({ message: "There is no data for download", type: "error" });
    }
  }

  return (
    <>
     <h2 className="prdouctUsageHeading">
          <Translate>Product Usage</Translate>
        </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* <h2>
          <Translate>Product Usage</Translate>
        </h2> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div>
            <NewComponentForm.DateContainer>
              <NewComponentForm.Date
                value={productUsageDate.startDate}
                onChange={(e) => {
                  const value = e.target.value;
                  setProductUsageDate((state) => ({
                    ...state,
                    startDate: value,
                  }));
                }}
                placeholder="Start Date"
                type="date"
              />
            </NewComponentForm.DateContainer>
          </div>
          <div style={{ marginLeft: "8px" }}>
            <NewComponentForm.DateContainer>
              <NewComponentForm.Date
                value={productUsageDate.endDate}
                onChange={(e) => {
                  const value = e.target.value;
                  setProductUsageDate((state) => ({
                    ...state,
                    endDate: value,
                  }));
                }}
                placeholder="End Date"
                type="date"
              />
            </NewComponentForm.DateContainer>
          </div>
          <div className="action-button-group" style={{ marginBottom: "20px", marginLeft: "8px" }}>
            <Button
              onClick={() => {
                dispatch(
                  productUsageFetchData(productUsageDate.startDate, productUsageDate.endDate, false, false, true)
                );
              }}
            >
              <Translate>Go</Translate>
            </Button>
            {isDownloadReportsAllowed && (
              <Button disabled={isSubscriptionExpired} onClick={() => downloadUsageProducts(PRODUCTS_USAGE)}>
                <AiOutlineCloudDownload size="20" title="download services" />
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="product-tab-table">
        {PRODUCTS_USAGE.length > 0 ? (
          <HoverTable
            header={productUsageHeader}
            src={PRODUCTS_USAGE}
            renderSpecial={renderProductUsage({ invoice_pre_text: branchData.invoice_pre_text })}
            edit={(id, product) => {
              editProductUsage(id, product);
            }}
            remove={(id, product) => deleteProductUsage(id, product)}
          />
        ) : (
          <div className="no-data-error">
            <Translate>No matching products found</Translate>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductUsage;
