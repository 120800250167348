import React from "react";
import "./index.scss";

import HoverTable from "HoverTable";
import Modal from "Modal";
import Form from "Form";
import Ellipsis from "Ellipsis";
import Fuse from "fuse.js";
// import CustomSearch from 'CustomSearch';
import Description from "Description";
import { AiOutlineCloudDownload, AiOutlineCloudUpload, AiOutlineFileAdd, AiOutlineFolderAdd } from "react-icons/ai";
import mockData from "../../mockData";
import { Button, Icon, Tab, Popup } from "semantic-ui-react";

import { servicesFetchData, servicesPostData, servicesPutData } from "../../store/actions/services";
import { PRODUCTS_DATA, productsFetchData, productsPostData, productsPutData } from "../../store/actions/products";
import { productUsageFetchData, productUsagePostData } from "../../store/actions/productUsage";
import { companiesFetchData, companiesPostData, companiesEditData } from "../../store/actions/companies";
import { sellersFetchData, sellersPostData, sellersEditData } from "../../store/actions/sellers";
import { fileUploadReset } from "../../store/actions/uploadDataFile";

import Uploader from "Uploader";
import { downloadReportFromServer } from "../../store/actions/downloadReportsFromServer";

import { connect } from "react-redux";
import { Search } from "../../new-components";
import { getLabels } from "../../store/actions/labels";
import { postLabels, putLabels } from "../../store/actions/labels";
import { postProductLabels } from "../../store/actions/products";
import { postServiceLabels } from "../../store/actions/services";
import moment from "moment";
import { Form as NewComponentForm } from "../../new-components";
import exportFromJSON from "export-from-json";
import {
  formatTimeIntoMin,
  formatTimeByUnit,
  combineTimeAndUnit,
  calcDateDifference,
  validateEmail,
} from "../../utilities/CommonFunctions";
import { ReactComponent as Consumption } from "../../images/consumption.svg";
import Toggler from "../../components/Toggler/index";
import { Translate } from "react-auto-translate";
import { cloneDeep } from "lodash";
import InventoryDetails from "./InventoryDetails";
import { getProductsBarcode, productBarcodeMap } from "../../store/actions/barcode";
import LabelsForm from "../../components/LabelsForm";
import { LabelGroup } from "../../components/LabelGroup";
import LabelPopUp from "../../components/LabelPopUp";
import { findObjectById } from "../../utilities";

import { serviceLabelOptionsSelector, productLabelOptionsSelector } from "../../store/selectors/labelOptions";
import ServiceProductUsage from "./ServiceProductUsage";
import Products from "./Products";
import ProductUsage from "./ProductsUsage";
import { LoadingState } from "../../store/reducers/types";

const tableHeadersServices = ["Name", "Labels/Categories", "Gender", "Cost", "Time", "Product Usage", "Action"];

const tableHeadersProducts = [
  "Name",
  "Labels/Categories",
  "Quantity",
  "Mfd/Exp Date",
  "Code/Batch/ Serial No.",
  "Action",
];

const productUsageHeader = [
  "Appointment Info",
  "Service Name",
  "Product Name",
  "Product Usage(in ml/gm)",
  "Quantity(in ml/gm)",
  "Action",
];

const companiesHeaders = ["Name", "Address", "Start Date", "Action"];

const sellersHeaders = ["Name", "Email", "Contact", "GST Number", "Code", "Action"];

const companyFormInitData = {
  address: "",
  code: "",
  contact: "",
  name: "",
  start_date: "",
};

const sellerFormInitData = {
  name: "",
  email: "",
  contact: "",
  gst_number: "",
  branch: "",
  code: "",
};

const MessagePopup = ({ message }) => <Popup trigger={<span className="showmore">...</span>}>{message}</Popup>;

const renderSpecialServices = ({ serviceLabels, serviceLabelOptions, updateServiceLabels }) => {
  return [
    ({ name, code, custom_field_one }) => {
      return (
        <Description
          bold
          title={name}
          add_on={code && !code?.toLowerCase().includes("none") && `Code: ${code}`}
          description={<Ellipsis text={custom_field_one?.slice(4)} isHtml maxLength={0} />}
        />
      );
    },
    ({ name, id, labels }) => {
      return (
        <div style={{ maxWidth: "200px", overflow: "auto" }}>
          <LabelPopUp
            trigger={
              <div style={{ maxWidth: "150px" }}>
                <LabelGroup labels={labels.label_ids} options={serviceLabels} />
              </div>
            }
            name={name}
            options={serviceLabelOptions}
            onSave={(labelsIds) => {
              updateServiceLabels(id, labelsIds);
            }}
            labels={labels.label_ids}
          />
        </div>
      );
    },
    ({ gender }) => {
      return <Description title={gender} />;
    },
    ({ cost }) => {
      return <Description title={cost + "/-"} />;
    },
    ({ time, time_unit }) => {
      const combinedTimeAndUnit = combineTimeAndUnit(time, time_unit);
      return <Description title={combinedTimeAndUnit} />;
    },
    (value, edit, remove, select, isSelected, custom, undo, key, customFunction1) => {
      return (
        <Consumption
          class="product-consumption-action"
          width="30"
          height="30"
          alt="product usage icon"
          onClick={() => customFunction1(value.id)}
        />
      );
    },
    (value, edit, remove, select, isSelected, custom, undo) => {
      return value.status === 0 ? (
        <Icon
          key={0}
          className="pointer undo"
          size="large"
          onClick={() => {
            // formatting because time is in seconds/min/hr and backend only works in min
            const formattedTimeIntoMin = formatTimeIntoMin(value.time, value.time_unit);
            value.time = formattedTimeIntoMin.res;
            undo(value.id, value);
          }}
        />
      ) : (
        [
          <Icon
            key={0}
            className="pointer edit"
            onClick={() => {
              edit(value.id, value);
            }}
          />,
          <Icon
            key={1}
            className="pointer trash"
            onClick={() => {
              // formatting because time is in seconds/min/hr and backend only works in min
              const formattedTimeIntoMin = formatTimeIntoMin(value.time, value.time_unit);
              value.time = formattedTimeIntoMin.res;
              remove(value.id, value);
            }}
          />,
        ]
      );
    },
  ];
};

const renderProductUsage = ({ invoice_pre_text = "GMPS" }) => {
  return [
    ({ appointment_id }) => {
      return <Description bold title={"#" + invoice_pre_text + appointment_id} />;
    },
    ({ service_name }) => {
      return <Description bold title={service_name} />;
    },
    ({ product_name }) => {
      return <Description bold title={product_name} />;
    },
    ({ consumption_value }) => {
      return <Description title={consumption_value} />;
    },
    ({ product_remaining_quantity }) => {
      return <Description title={product_remaining_quantity} />;
    },
    (value, edit, remove) => {
      return value.status === 0 ? (
        <Icon key={0} className="dont" size="large" />
      ) : (
        [
          <Icon key={1} className="pointer edit" onClick={() => edit(value.id, value)} />,
          <Icon key={2} className="pointer trash" onClick={() => remove(value.id, value)} />,
        ]
      );
    },
  ];
};

const companiesRenderSpecial = [
  ({ name, contact, code, status }) => {
    return <Description title={name} description={contact} add_on={code} status={status} />;
  },
  ({ address }) => {
    return <Description title={address} />;
  },
  ({ start_date }) => {
    return <Description title={start_date} />;
  },
  (value, edit, remove, select, isSelected, custom, undo) => {
    return value.status === 0 ? (
      <Icon key={0} className="pointer undo" size="large" onClick={() => undo(value.id, value)} />
    ) : (
      [
        <Icon key={1} className="pointer edit" onClick={() => edit(value.id, value)} />,
        <Icon key={2} className="pointer trash" onClick={() => remove(value.id, value)} />,
      ]
    );
  },
];

const sellersRenderSpecial = [
  ({ name = "" }) => {
    const slicingPoint = 15;
    const slicedName = name.length > slicingPoint ? `${name.slice(0, slicingPoint)}...` : name;

    return (
      <div className="flex">
        <Description title={slicedName} />
        {name.length > slicingPoint && <MessagePopup message={name} />}
      </div>
    );
  },
  ({ email }) => {
    return <Description title={email} />;
  },
  ({ contact }) => {
    return <Description title={contact} />;
  },
  ({ gst_number }) => {
    return <Description title={gst_number} />;
  },
  ({ code }) => {
    return <Description title={code} />;
  },
  (value, edit, remove, select, isSelected, custom, undo) => {
    return value.is_active === false ? (
      <Icon key={0} className="pointer undo" size="large" onClick={() => undo(value.id, value)} />
    ) : (
      [
        <Icon key={1} className="pointer edit" onClick={() => edit(value.id, value)} />,
        <Icon key={2} className="pointer trash" onClick={() => remove(value.id, value)} />,
      ]
    );
  },
];

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showServiceProductUsage: false,
      serviceProductUsageId: null,
      showAddService: false,
      showAddServiceLabels: false,
      showAddProductLabels: false,
      serviceData: null,
      serviceForm: mockData.service_form,
      showAddProduct: false,
      productForm: mockData.product_form,
      productData: null,
      addCompany: false,
      addCompanyForm: mockData.company_form,
      addSeller: false,
      uploadForm: false,
      addSellerForm: mockData.seller_form,
      searchServices: "",
      searchProducts: "",
      searchCompanies: "",
      searchSellers: "",
      productUsageForm: mockData.product_usage_form,
      showProductUsage: false,
      productUsageData: null,
      productUsageDate: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      companyFormData: companyFormInitData,
      sellerFormData: sellerFormInitData,

      serviceFieldError: false,
      serviceFieldErrorMessage: "",
      productFieldError: false,
      productDateError: false,
      companyFieldError: "",
      sellerFieldError: "",

      activeServicesOnly: true,
      activeProductsOnly: true,
      activeCompaniesOnly: true,
      activeSellersOnly: true,
    };
  }

  GetStartDate(givenDate = false) {
    var startDate;
    if (givenDate) {
      startDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
    } else {
      var date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    }

    return moment(startDate).format("YYYY-MM-DD");
  }

  GetEndDate(givenDate = false) {
    var endDate;
    if (givenDate) {
      endDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);
    } else {
      var date = new Date();
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    return moment(endDate).format("YYYY-MM-DD");
  }

  componentDidMount() {
    this.props.fetchServices();
    this.props.fetchProducts();
    this.props.fetchCompanies();
    this.props.getProductsBarcode();
    this.props.fetchLabels("service");
    this.props.fetchLabels("product");
  }

  showAddService() {
    this.setState({
      showAddService: !this.state.showAddService,
      serviceData: null,
      serviceFieldError: false,
    });
  }

  showAddServiceLabels() {
    this.setState({
      showAddServiceLabels: !this.state.showAddServiceLabels,
    });
  }

  updateServiceLabels = (service_id, label_ids) => {
    const payload = { service_id, label_ids };

    this.props.postServiceLabels(payload);
  };

  downloadServices(services, options) {
    // this services data is already filtered and formatted
    const header = ["ID", "Name", "Gender", "Cost", "Duration", "Labels/Categories", "Status"];

    const serviceFields = services.map((item) => {
      const combinedTimeAndUnit = combineTimeAndUnit(item.time, item.time_unit);

      const labels = item.labels.label_ids
        .map((ele) => {
          const obj = findObjectById(options, ele);
          return obj?.short_name || "";
        })
        .filter((ele) => ele != "")
        .join(", ");

      return [
        item.id,
        item.name,
        item.gender,
        item.cost,
        combinedTimeAndUnit,
        labels,
        item.status === 1 ? "Active" : "Inactive",
      ];
    });

    const data = [[...header], ...serviceFields];

    const fileName = "all_services";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  }

  downloadProducts(productLabels) {
    const { products, productBarcodeMap } = this.props;

    const header = [
      [
        "ID",
        "Code/Batch/Serial No.",
        "Name",
        "Company",
        "Cost",
        "Qty Remaining",
        "Mfd Date",
        "Exp Date",
        "Price(Procurement Price)",
        "Volume(In Ml/Gm)",
        "Barcode",
        "Labels/Categories",
        "HSN/SAC",
        "Status",
      ],
    ];
    const data = [
      ...header,
      ...productBarcodeMap.map((item) => [
        item.id,
        item.code_1,
        item.name,
        item?.company?.name || "N/A",
        item.cost,
        item.quantity,
        item.mfg_date,
        item.exp_date,
        item.price,
        item.volume,
        item.barcode ? item.barcode.map((ele) => ele.bar_code).join(", ") : "",
        item.labels?.label_ids?.length
          ? item.labels.label_ids
              .map((ele) => {
                const obj = findObjectById(productLabels, ele);
                return obj?.short_name || "";
              })
              .filter((ele) => ele != "")
              .join(", ")
          : "",
        item.hsn_sac || "",
        item.status === 1 ? "Active" : "Inactive",
      ]),
    ];
    const fileName = "all_products";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  }

  downloadCompanies() {
    const { companies } = this.props;

    const header = [["ID", "Code No.", "Name", "Contact", "Address", "Start Date", "Status"]];

    const data = [
      ...header,
      ...companies.data.map((item) => [
        item.id,
        item.code || "",
        item.name || "",
        item.contact || "",
        item.address || "",
        item.start_date || "",
        item.status === 1 ? "Active" : "Inactive",
      ]),
    ];

    const fileName = "all_companies";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  }

  addService(status, service) {
    const { cost, gender, time, time_unit, name } = service;

    //is any field empty validation
    if (!cost || !gender || !time || !name) {
      this.setState({
        serviceFieldError: true,
        serviceFieldErrorMessage: "No Required Field Should Be Empty",
        serviceData: { ...service },
      });
      return false;
    }
    const formattedTimeIntoMin = formatTimeIntoMin(time, time_unit);

    if (formattedTimeIntoMin.error) {
      this.setState({
        serviceFieldError: true,
        serviceFieldErrorMessage: formattedTimeIntoMin.message,
        serviceData: { ...service },
      });
      return false;
    }
    service["status"] = 1;
    service["time"] = formattedTimeIntoMin.res;
    const temp = service.custom_field_one?.length && service.custom_field_one.replaceAll(/\n/g, "<br>");
    service.custom_field_one = temp?.length && "<br>" + temp;

    if (!!this.state.serviceData && "id" in this.state.serviceData) {
      this.props.putService(service.id, { ...service, code: service.code === "" ? null : service.code });
    } else {
      this.props.postServices({ ...service, code: service.code === "" ? null : service.code });
    }
    this.showAddService();
  }

  editService(status, service) {
    this.setState({
      showAddService: true,
      serviceData: service,
    });
  }

  undoService(id, service) {
    service["status"] = 1;
    this.props.putService(id, service);
  }

  deleteService(id, service) {
    service["status"] = 0;
    this.props.putService(id, service);
  }

  showAddProduct = function () {
    this.setState({
      showAddProduct: !this.state.showAddProduct,
      productData: null,
      productFieldError: false,
      productDateError: false,
    });
  };

  showAddProductLabels = function () {
    this.setState({
      showAddProductLabels: !this.state.showAddProductLabels,
    });
  };

  updateProductLabels = (product_id, label_ids) => {
    const payload = { product_id, label_ids };

    this.props.postProductLabels(payload);
  };

  addProduct(status, product) {
    let company_id = null;

    if (product["company.id"]) {
      company_id = product["company.id"];
    } else if (product["company"]) {
      company_id = product["company"].id;
    }

    //is any field empty validation
    const { cost, price, name, quantity, exp_date, mfg_date, volume } = product;

    if (!company_id || !price || !cost || !name || !quantity || !volume || !mfg_date || !exp_date) {
      this.setState({
        productFieldError: true,
        productData: { ...product },
      });
      return false;
    }
    //validating exp_date and mfg_date difference
    if (calcDateDifference(mfg_date, exp_date) <= 0) {
      this.setState({
        productFieldError: false,
        productDateError: true,
        productData: { ...product },
      });
      return false;
    }

    product["status"] = 1;
    delete product["company.id"];
    if (!!this.state.productData && "id" in this.state.productData) {
      this.props.putProducts(product.id, {
        ...product,
        company: company_id,
        code_1: product.code_1 === "" ? null : product.code_1,
      });
    } else {
      this.props.postProducts({
        ...product,
        company: company_id,
        code_1: product.code_1 === "" ? null : product.code_1,
      });
    }
    this.showAddProduct();
  }

  editProduct = function (status, product) {
    this.setState({
      showAddProduct: true,
      productData: product,
    });
  };

  undoProduct(id, product) {
    if (product["company"]) {
      product["company"] = product["company"].id;
    }
    this.props.putProducts(id, { ...product, status: 1 });
  }

  deleteProduct(id, product) {
    let deleteProduct = cloneDeep(product);
    if (deleteProduct["company"]) {
      deleteProduct["company"] = deleteProduct["company"].id;
    }
    this.props.putProducts(id, { ...deleteProduct, status: 0 });
  }

  showProductUsageFunc() {
    this.setState({
      showProductUsage: !this.state.showProductUsage,
      productUsageData: null,
    });
  }

  deleteProductUsage(id, data) {
    const { productUsage } = this.props;
    const { productUsageDate } = this.state;
    const filterProductUsageByRequiredID = productUsage.data.filter(
      (ele) => ele.required_service_id === data.required_service_id
    );
    const findProductUsageIndex = filterProductUsageByRequiredID.findIndex(
      (ele) => ele.required_service_id === data.required_service_id && ele.product_id === data.product_id
    );
    filterProductUsageByRequiredID.splice(findProductUsageIndex, 1);
    const productUsageUpdatePayload = {
      required_service_id: data.required_service_id,
      consumption: filterProductUsageByRequiredID.map((ele) => {
        return {
          product_id: ele.product_id,
          consumption_value: ele.consumption_value,
        };
      }),
    };
    this.props.productUsagePostData(
      productUsageUpdatePayload,
      productUsageDate.startDate,
      productUsageDate.endDate,
      true
    );
  }

  updateProductUsage(status, data) {
    const { productUsage } = this.props;
    const { productUsageDate } = this.state;
    const filterProductUsageByRequiredID = productUsage.data.filter(
      (ele) => ele.required_service_id === data.required_service_id
    );
    const findProductUsageIndex = filterProductUsageByRequiredID.findIndex(
      (ele) => ele.required_service_id === data.required_service_id && ele.product_id === data.product_id
    );
    filterProductUsageByRequiredID[findProductUsageIndex].consumption_value = data.consumption_value;
    const productUsageUpdatePayload = {
      required_service_id: data.required_service_id,
      consumption: filterProductUsageByRequiredID.map((ele) => {
        return {
          product_id: ele.product_id,
          consumption_value: ele.consumption_value || 0,
        };
      }),
    };
    this.props.productUsagePostData(
      productUsageUpdatePayload,
      productUsageDate.startDate,
      productUsageDate.endDate,
      true
    );
    this.showProductUsageFunc();
  }

  editProductUsage(status, product) {
    this.setState({
      showProductUsage: !this.state.showProductUsage,
      productUsageData: product,
    });
  }

  showAddCompany() {
    this.setState({
      addCompany: !this.state.addCompany,
      companyFormData: { ...companyFormInitData },
      companyFieldError: "",
    });
  }

  editCompany(id, company) {
    this.setState({
      addCompany: !this.state.addCompany,
      companyFormData: { ...this.state.companyFormData, ...company },
    });
  }

  addCompany(status, company) {
    //is any field empty validation
    const { name, contact, start_date } = company;

    if (!name || name.length < 3) {
      this.setState({
        companyFieldError: "Name must be atleast 3 characters long",
      });
      return false;
    } else if (contact && contact.length !== 10) {
      this.setState({
        companyFieldError: "Invalid Contact Number",
      });
      return false;
    }

    this.setState({ addCompany: !this.state.addCompany, companyFieldError: "" });

    let _date = start_date;
    if (start_date === "") {
      _date = null;
    }
    this.props.postCompanies({ ...company, status: 1, start_date: _date });
  }

  editCompanyRequest(id, data, method) {
    //is any field empty validation
    const { name, contact, start_date } = data;

    if (!name || name.length < 3) {
      this.setState({
        companyFieldError: "Name must be atleast 3 characters long",
      });
      return false;
    } else if (contact && contact.length !== 10) {
      this.setState({
        companyFieldError: "Invalid Contact Number",
      });
      return false;
    }

    let _date = start_date;
    if (start_date === "") {
      _date = null;
    }

    this.setState({ addCompany: !this.state.addCompany, companyFieldError: "" });
    this.props.editCompanies(id, { ...data, start_date: _date }, method);
  }

  companiesDataFormat = (companies) => {
    let formattedData = [];

    companies.forEach((elements) => {
      const { id, name, status } = elements;
      //filtering
      if (status) {
        formattedData.push({
          value: id,
          key: id,
          text: name,
        });
      }
    });
    return formattedData;
  };

  showAddSeller() {
    this.setState({
      addSeller: !this.state.addSeller,
      sellerFormData: { ...sellerFormInitData },
      sellerFieldError: "",
    });
  }

  showUploadForm() {
    this.setState({
      uploadForm: true,
    });
  }

  closeUploadForm() {
    this.setState({ uploadForm: false });
    this.props.fileUploadReset();
  }

  downloadSellers() {
    const { branchData, downloadReportFromServer } = this.props;
    const type = "SellersReport";

    const data = {
      type,
      params: {
        branch_id: branchData.id,
      },
    };

    downloadReportFromServer(data);
  }

  editSeller(id, seller) {
    this.setState({
      addSeller: !this.state.addSeller,
      sellerFormData: { ...this.state.sellerFormData, ...seller },
    });
  }

  addSeller(status, seller) {
    //is any field empty validation
    const { name, email, contact } = seller;
    const { branchData } = this.props;

    const isEmailValid = validateEmail(email);

    if (!name || !email || !contact || contact.length !== 10) {
      this.setState({
        sellerFieldError: "No Required Field Should Be Empty Or Invalid",
      });
      return false;
    }
    if (!isEmailValid) {
      this.setState({
        sellerFieldError: "Please enter a valid email",
      });
      return false;
    }

    const formData = { ...seller, branch: branchData.id, is_active: 1 };

    this.setState({ addSeller: !this.state.addSeller });
    this.props.postSellers(formData);
  }

  editSellerRequest(id, data, method) {
    //is any field empty validation
    const { name, email, contact } = data;
    const isEmailValid = validateEmail(email);

    if (!name || !email || !contact || contact.length !== 10) {
      this.setState({
        sellerFieldError: "No Required Field Should Be Empty Or Invalid",
      });
      return false;
    }
    if (!isEmailValid) {
      this.setState({
        sellerFieldError: "Please enter a valid email",
      });
      return false;
    }

    this.setState({ addSeller: !this.state.addSeller });
    this.props.updateSellers(id, data, method);
  }

  productsFormFormat = (productsForm) => {
    let { companies } = this.props;
    const _companies = this.companiesDataFormat(companies.data);

    let formattedProductsForm = [];

    productsForm.forEach((elements) => {
      if (elements.label === "company*") {
        formattedProductsForm.push({
          ...elements,
          options: _companies,
        });
      } else {
        formattedProductsForm.push(elements);
      }
    });

    return formattedProductsForm;
  };

  servicesData(search, services) {
    const fuse = new Fuse(services, { keys: ["name"], threshold: 0.2 });
    const results = fuse.search(search).map(({ item }) => item);

    if (services.length > 0 && search.length > 0) {
      return results;
    } else {
      return services;
    }
  }

  productsData(search, products) {
    const fuse = new Fuse(products, { keys: ["name"], threshold: 0.2 });
    const results = fuse.search(search).map(({ item }) => item);

    if (products.length > 0 && search.length > 0) {
      return results;
    } else {
      return products;
    }
  }

  companiesData(search, companies = []) {
    const fuse = new Fuse(companies, { keys: ["name"], threshold: 0.2 });
    const results = fuse.search(search).map(({ item }) => item);

    if (companies.length > 0 && search.length > 0) {
      return results;
    } else {
      return companies;
    }
  }

  sellersData(search, sellers) {
    const fuse = new Fuse(sellers, { keys: ["name"], threshold: 0.2 });
    const results = fuse.search(search).map(({ item }) => item);

    if (sellers.length > 0 && search.length > 0) {
      return results;
    } else {
      return sellers;
    }
  }

  productUsageData(productUsage, products, services) {
    const productUsageDataFormat = productUsage.map((ele) => {
      const findProductIndex = products.findIndex((productEle) => productEle.id === ele.product_id);
      const findServiceIndex = services.findIndex((servicesEle) => servicesEle.id === ele.service_id);
      return {
        ...ele,
        product_name: (products && products[findProductIndex] && products[findProductIndex]?.name) || "",
        product_remaining_quantity:
          (products[findProductIndex]?.quantity || 0) * (products[findProductIndex]?.volume || 0),
        service_name: (services && services[findServiceIndex] && services[findServiceIndex]?.name) || "",
        consumption_value: `${ele.consumption_value}`,
      };
    });
    return productUsageDataFormat;
  }

  errorMessage(message) {
    return <div style={{ color: "red", padding: "10px 0px" }}>{message}</div>;
  }

  filterDataFunction = (data, isActive, key = "status") => {
    if (!isActive) {
      return data.filter((row) => {
        return !row[key] || row[key] === "0";
      });
    }
    return data.filter((row) => row[key]);
  };

  onAddLabel = (type, label) => {
    this.props.postLabels(type, label);
  };
  onUpdateLabel = (type, id, label) => {
    this.props.putLabels(type, id, label);
  };

  showServiceProductUsageModal = (service_id) => {
    this.setState({ showServiceProductUsage: true, serviceProductUsageId: service_id });
  };
  closeServiceProductUsageModal = () => {
    this.setState({ showServiceProductUsage: false, serviceProductUsageId: null });
  };

  fetchTabData = (data) => {
    const menuItem = data.panes[data.activeIndex].menuItem;
    switch (menuItem) {
      case "Products Usage":
        break;
      case "Company":
        const { companies } = this.props;
        const COMPANIES_LOADED = companies.loading === LoadingState.loaded;
        if (!COMPANIES_LOADED) {
          this.props.fetchCompanies();
        }
        break;
      case "Sellers":
        const { sellers } = this.props;
        const SELLERS_LOADED = sellers.loading === LoadingState.loaded;
        if (!SELLERS_LOADED) {
          this.props.fetchSellers();
        }
        break;
      case "Inventory Details":
        break;

      default:
        break;
    }
  };

  render() {
    const {
      services,
      products,
      companies,
      productUsage,
      sellers,
      updateSellers,
      editCompanies,
      aclUserPermissions,
      serviceLabelOptions,
      serviceLabels,
    } = this.props;
    const {
      showAddService,
      showAddServiceLabels,
      showAddProductLabels,
      serviceForm,
      serviceData,
      showAddProduct,
      productForm,
      productData,
      addCompany,
      addCompanyForm,
      addSeller,
      uploadForm,
      addSellerForm,
      searchServices,
      searchProducts,
      searchCompanies,
      searchSellers,
      showProductUsage,
      productUsageForm,
      productUsageDate,
      productUsageData,
      companyFormData,
      sellerFormData,
      showServiceProductUsage,
      serviceProductUsageId,
    } = this.state;

    let SERVICE_DATA = this.servicesData(searchServices, services);
    let PRODUCT_DATA = this.productsData(searchProducts, products);
    let COMPANIES_DATA = this.companiesData(searchCompanies, companies.data);
    let SELLERS_DATA = this.sellersData(searchSellers, sellers.sellers);

    const { formatted_component_permissions } = aclUserPermissions;

    const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");
    const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

    //formatTimeByUnit
    SERVICE_DATA = SERVICE_DATA.map((service) => {
      return {
        ...service,
        time: formatTimeByUnit(service.time, service.time_unit),
        code: !service?.code?.toLowerCase().includes("none") ? service.code : "",
      };
    });

    //filtering on the basis of status
    SERVICE_DATA = this.filterDataFunction(SERVICE_DATA, this.state.activeServicesOnly);
    PRODUCT_DATA = this.filterDataFunction(PRODUCT_DATA, this.state.activeProductsOnly);
    COMPANIES_DATA = this.filterDataFunction(COMPANIES_DATA, this.state.activeCompaniesOnly);
    SELLERS_DATA = this.filterDataFunction(SELLERS_DATA, this.state.activeSellersOnly, "is_active");

    const panes = [
      {
        menuItem: "Products",
        render: () => (
          <Tab.Pane className="products-height">
            <Products
              showAddProductLabels={() => this.showAddProductLabels()}
              editProduct={(id, product) => this.editProduct(id, product)}
              undoProduct={(id, product) => this.undoProduct(id, product)}
              showAddProduct={() => this.showAddProduct()}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Products Usage",
        render: () => (
          <Tab.Pane className="products-height">
            <ProductUsage
              editProductUsage={(status, product) => this.editProductUsage(status, product)}
              deleteProductUsage={(id, data) => this.deleteProductUsage(id, data)}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Company",
        render: () => (
          <Tab.Pane className="products-height">
             <h2 className="companyHeading">
                <Translate>Company</Translate>
              </h2>
            <div className="controller flex row">
              {/* <h2>
                <Translate>Company</Translate>
              </h2> */}
              <Search>
                <Search.GeneralField
                  value={searchCompanies}
                  onChange={(e) => {
                    const changedValue = e.target.value;
                    this.setState({ searchCompanies: changedValue });
                  }}
                  placeholder="Search Companies"
                />
              </Search>
              <div className="action-button-group">
                {isDownloadReportsAllowed && (
                  <button className="downloadBtn" disabled={isSubscriptionExpired} onClick={() => this.downloadCompanies()}>
                    <AiOutlineCloudDownload size="20" title="download companies" />
                  </button>
                )}
                <button
                  style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 5 }}
                  onClick={() => this.showAddCompany()}
                >
                  <AiOutlineFolderAdd size="20" title="add Company" />
                  &nbsp;<Translate>Company</Translate>
                </button>
              </div>
            </div>
            <Toggler
              toggle
              style={{
                maxWidth: "400px",
                minWidth: "200px",
                marginBottom: "20px",
              }}
              onChange={() => {
                this.setState((prevState) => ({
                  activeCompaniesOnly: !prevState.activeCompaniesOnly,
                }));
              }}
              checked={this.state.activeCompaniesOnly}
              label={"Active Companies"}
            />
            <div className="product-tab-table">
              {COMPANIES_DATA.length > 0 ? (
                <HoverTable
                  header={companiesHeaders}
                  src={COMPANIES_DATA}
                  renderSpecial={companiesRenderSpecial}
                  edit={(id, company) => {
                    this.editCompany(id, company);
                  }}
                  undo={(id, company) => {
                    editCompanies(id, { ...company, status: 1 }, "PUT");
                  }}
                  remove={(id, company) => {
                    editCompanies(id, { ...company, status: 0 }, "PUT");
                  }}
                  showDeleted={!this.state.activeCompaniesOnly}
                />
              ) : (
                <div className="no-data-error">
                  <Translate>No matching Companies found</Translate>
                </div>
              )}
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Sellers",
        render: () => (
          <Tab.Pane className="products-height">
            <h2 className="companyHeading">
                <Translate>Sellers</Translate>
              </h2>
            <div className="controller flex row">
              {/* <h2>
                <Translate>Sellers</Translate>
              </h2> */}
              <Search>
                <Search.GeneralField
                  value={searchSellers}
                  onChange={(e) => {
                    const changedValue = e.target.value;
                    this.setState({ searchSellers: changedValue });
                  }}
                  placeholder="Search Sellers"
                />
              </Search>
              <div className="action-button-group">
                {isDownloadReportsAllowed && (
                  <button className="downloadBtn" disabled={isSubscriptionExpired} onClick={() => this.downloadSellers()}>
                    <AiOutlineCloudDownload size="20" title="Download Sellers" />
                  </button>
                )}
                {isDownloadReportsAllowed && (
                  <button className="uploadbtn" disabled={isSubscriptionExpired} onClick={() => this.showUploadForm()}>
                    <AiOutlineCloudUpload size="20" title="Upload Sellers" />
                  </button>
                )}
                <button
                  style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 5 }}
                  onClick={() => this.showAddSeller()}
                >
                  <AiOutlineFolderAdd size="20" title="add Seller" />
                  <p>
                    &nbsp;<Translate>Sellers</Translate>
                  </p>
                </button>
              </div>
            </div>
            <Toggler
              toggle
              style={{
                maxWidth: "400px",
                minWidth: "200px",
                marginBottom: "20px",
              }}
              onChange={() => {
                this.setState((prevState) => ({
                  activeSellersOnly: !prevState.activeSellersOnly,
                }));
              }}
              checked={this.state.activeSellersOnly}
              label={"Active Sellers"}
            />
            <div className="product-tab-table">
              {SELLERS_DATA?.length > 0 ? (
                <HoverTable
                  header={sellersHeaders}
                  src={SELLERS_DATA}
                  renderSpecial={sellersRenderSpecial}
                  edit={(id, seller) => {
                    this.editSeller(id, seller);
                  }}
                  undo={(id, seller) => {
                    updateSellers(id, { ...seller, is_active: true }, "PUT");
                  }}
                  remove={(id, seller) => {
                    updateSellers(id, { ...seller, is_active: false }, "PUT");
                  }}
                  showDeleted={!this.state.activeSellersOnly}
                />
              ) : (
                <div className="no-data-error">
                  <Translate>No matching Sellers found</Translate>
                </div>
              )}
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Inventory Details",
        render: () => (
          <Tab.Pane className="products-height">
            <InventoryDetails />
          </Tab.Pane>
        ),
      },
    ];

    return [
      <>
        {isSubscriptionExpired && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p style={{ color: "red", fontSize: "16px" }}>
              <Translate>Subscription is Expired</Translate>
            </p>
          </div>
        )}
      </>,
      <div key={0} className="inventory flex width100 row">
        {showAddService && (
          <Modal close={() => this.showAddService()}>
            <Form
              inputs={serviceForm}
              data={serviceData || {}}
              edit={(status, data) => this.addService(status, data)}
              update={!!serviceData}
            />
            {this.state.serviceFieldError && this.errorMessage(this.state.serviceFieldErrorMessage)}
          </Modal>
        )}
        {showAddServiceLabels && (
          <Modal close={() => this.showAddServiceLabels()}>
            <LabelsForm
              type="service"
              name="Service"
              onAddLabel={(label) => this.onAddLabel("service", label)}
              onUpdateLabel={(id, label) => this.onUpdateLabel("service", id, label)}
            />
          </Modal>
        )}
        {showProductUsage && (
          <Modal close={() => this.showProductUsageFunc()}>
            <Form
              inputs={productUsageForm}
              data={productUsageData}
              edit={(status, data) => this.updateProductUsage(status, data)}
              update={true}
            />
          </Modal>
        )}
        {showAddProduct && (
          <Modal close={() => this.showAddProduct()}>
            <Form
              inputs={this.productsFormFormat(productForm)}
              data={productData || {}}
              edit={(status, data) => this.addProduct(status, data)}
              update={!!productData}
            />
            {this.state.productFieldError && this.errorMessage("No Required Field Should Be Empty Or Invalid")}
            {this.state.productDateError && this.errorMessage("Expiry Date Should Be Greater Than Manufacturing Date")}
          </Modal>
        )}
        {showAddProductLabels && (
          <Modal close={() => this.showAddProductLabels()}>
            <LabelsForm
              type="product"
              name="Product"
              onAddLabel={(label) => this.onAddLabel("product", label)}
              onUpdateLabel={(id, label) => this.onUpdateLabel("product", id, label)}
            />
          </Modal>
        )}
        {addCompany && (
          <Modal close={() => this.showAddCompany()}>
            <Form
              inputs={addCompanyForm}
              data={companyFormData}
              edit={(status, data) => {
                // Existing data
                if (companyFormData.id !== undefined) {
                  this.editCompanyRequest(data.id, data, "PUT");
                } else {
                  this.addCompany(status, data);
                }
              }}
              update={companyFormData.id !== undefined}
            />
            {this.state.companyFieldError.length > 0 && this.errorMessage(this.state.companyFieldError)}
          </Modal>
        )}
        {addSeller && (
          <Modal close={() => this.showAddSeller()}>
            <Form
              inputs={addSellerForm}
              data={sellerFormData}
              edit={(status, data) => {
                // Existing data
                if (sellerFormData.id !== undefined) {
                  this.editSellerRequest(data.id, data, "PUT");
                } else {
                  this.addSeller(status, data);
                }
              }}
              update={sellerFormData.id !== undefined}
            />
            {this.state.sellerFieldError && this.errorMessage(this.state.sellerFieldError)}
          </Modal>
        )}
        {uploadForm && (
          <Modal close={() => this.closeUploadForm()}>
            <Uploader type="sellers" />
          </Modal>
        )}
        {showServiceProductUsage && (
          <Modal close={() => this.closeServiceProductUsageModal()}>
            <ServiceProductUsage id={serviceProductUsageId} close={() => this.closeServiceProductUsageModal()} />
          </Modal>
        )}

        {/*  */}
        <div className="services width-50">
          <h2 className="servicesHeading">
            <Translate>Services</Translate>
          </h2>
          <div className="controller flex row">
            {/* <h2>
              <Translate>Services</Translate>
            </h2> */}
            <Search>
              <Search.GeneralField
                value={searchServices}
                onChange={(e) => {
                  const changedValue = e.target.value;
                  this.setState({ searchServices: changedValue });
                }}
                placeholder="Search service"
              />
            </Search>
            <div className="action-button-group">
              {isDownloadReportsAllowed && (
                <button
                className="downloadBtn"
                  disabled={isSubscriptionExpired}
                  onClick={() => this.downloadServices(SERVICE_DATA, serviceLabels)}
                >
                  <AiOutlineCloudDownload size="20" title="download services" />
                </button>
              )}
              <button className="addBtn" onClick={() => this.showAddService()}>
                <AiOutlineFileAdd size="20" title="add" />
                &nbsp;<Translate>Add</Translate>
              </button>
              <button className="labelBtn" onClick={() => this.showAddServiceLabels()}>
                <AiOutlineFileAdd size="20" title="add" />
                &nbsp;<Translate>Label/Category</Translate>
              </button>
            </div>
          </div>
          <Toggler
            toggle
            style={{
              maxWidth: "400px",
              minWidth: "200px",
            }}
            onChange={() => {
              this.setState((prevState) => ({
                activeServicesOnly: !prevState.activeServicesOnly,
              }));
            }}
            checked={this.state.activeServicesOnly}
            label={"Active Services"}
          />
          {SERVICE_DATA.length > 0 ? (
            <HoverTable
              header={tableHeadersServices}
              src={SERVICE_DATA}
              renderSpecial={renderSpecialServices({
                serviceLabelOptions,
                serviceLabels,
                updateServiceLabels: this.updateServiceLabels,
              })}
              customFunction1={(service_id) => this.showServiceProductUsageModal(service_id)}
              edit={(id, service) => this.editService(id, service)}
              undo={(id, service) => this.undoService(id, service)}
              remove={(id, service) => this.deleteService(id, service)}
              showDeleted={!this.state.activeServicesOnly}
            />
          ) : (
            <div className="no-data-error">
              <Translate>No matching services found</Translate>
            </div>
          )}
        </div>
        <div className="products width-50">
          <Tab
            panes={panes}
            onTabChange={(_, data) => {
              this.fetchTabData(data);
            }}
          />
        </div>
      </div>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    aclUserPermissions: state.aclUserPermissions.data,
    services: state.services.data,
    products: PRODUCTS_DATA(state),
    companies: state.companies,
    productUsage: state.productUsage,
    sellers: state.sellers,
    branchData: state.branch.data,
    productBarcodeMap: productBarcodeMap(state),

    serviceLabels: state.serviceLabels.data,
    productLabels: state.productLabels.data,
    serviceLabelOptions: serviceLabelOptionsSelector(state),
    productLabelOptions: productLabelOptionsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchServices: () => dispatch(servicesFetchData()),
    postServices: (service) => dispatch(servicesPostData(service)),
    putService: (id, service) => dispatch(servicesPutData(id, service)),
    productUsageFetchData: (start_date, end_date, appointment_id, required_service_id, appointment_completed) =>
      dispatch(productUsageFetchData(start_date, end_date, appointment_id, required_service_id, appointment_completed)),
    productUsagePostData: (productUsagePayload, start_date, end_date, appointment_completed) =>
      dispatch(productUsagePostData(productUsagePayload, start_date, end_date, appointment_completed)),
    fetchProducts: () => dispatch(productsFetchData()),
    postProducts: (product) => dispatch(productsPostData(product)),
    putProducts: (id, product) => dispatch(productsPutData(id, product)),

    fetchCompanies: () => dispatch(companiesFetchData()),
    postCompanies: (product) => dispatch(companiesPostData(product)),
    editCompanies: (id, companies, method) => dispatch(companiesEditData(id, companies, method)),

    fetchSellers: () => dispatch(sellersFetchData()),
    postSellers: (seller) => dispatch(sellersPostData(seller)),
    updateSellers: (id, sellers, method) => dispatch(sellersEditData(id, sellers, method)),

    downloadReportFromServer: (data) => dispatch(downloadReportFromServer(data)),
    fileUploadReset: () => dispatch(fileUploadReset()),
    getProductsBarcode: () => dispatch(getProductsBarcode()),

    fetchLabels: (type) => dispatch(getLabels(type)),
    postLabels: (type, label) => dispatch(postLabels(type, label)),
    putLabels: (type, id, label) => dispatch(putLabels(type, id, label)),
    postProductLabels: (payload) => dispatch(postProductLabels(payload)),
    postServiceLabels: (payload) => dispatch(postServiceLabels(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
