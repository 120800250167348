import { API_BASE_URL, MEMBERSHIP_API } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";
import { errorMessage } from "../../utilities/actionMessages";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export const membershipFetchSuccess = (data) => {
  return {
    type: "MEMBERSHIP_BY_ID_SUCCESS",
    data,
  };
};

export const membershipFetching = () => {
  return {
    type: "MEMBERSHIP_BY_ID_FETCHING",
  };
};

export const membershipFetchFailed = () => {
  return {
    type: "MEMBERSHIP_BY_ID_ERROR",
  };
};

export const fetchMebershipDataById = (id) => {
  return (dispatch) => {
    dispatch(membershipFetching());

    axios
      .get(API_BASE_URL + MEMBERSHIP_API + id + "/", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(membershipFetchSuccess(response.data));
        }
      })
      .catch((error) => {
        ToastAlert({ message: errorMessage, type: "error" });
        dispatch(membershipFetchFailed());
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
};
