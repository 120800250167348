import React, { useEffect, useMemo, useState } from "react";
import { getToken, onMessageListener } from "./firebase";

import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Translator, Translate } from "react-auto-translate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "Header";
import Sidebar from "Sidebar";
import Container from "Container";
import Appointments from "Appointments";
import Campaign from "Campaign";
import Staff from "Staff";
import Clients from "Clients";
import Inventory from "Inventory";
import Expense from "Expense";
import Invoices from "Invoices";
import Analytics from "Analytics";
import Settings from "Settings";
import Payments from "Payments";
import Memberships from "Memberships";
import Login from "Login";
import MarketPlace from "MarketPlace";
import MarketplaceOrders from "MarketplaceOrders";
import PageDenied from "./components/PageDenied";
import PageLoad from "./containers/PageLoad";
import ReleaseNotesModal from "./components/ReleaseNotes";

import WithLoader from "./hoc/WithLoader";
import Loader from "./components/Loader";

import { branchFetchData } from "store/actions/branch";
import { clientFetchData } from "store/actions/client";
import { shiftsFetchData } from "store/actions/shifts";
import { positionsFetchData } from "store/actions/positions";
import { servicesFetchData } from "store/actions/services";
import { productsFetchData } from "store/actions/products";
import { partnerType } from "store/actions/partnerType";
import { getUserPermissions } from "./store/actions/aclUserPermissions";
import { getBranchSubscriptionsServices } from "store/actions/branchSubscriptionsServices";
import StaffSalary from "./containers/StaffSalary/index";
import { getBranchLoyaltyPoint } from "./store/actions/branch";
import { getReleaseNotes } from "./store/actions/releaseNotes";
import { CLOUD_TRANSALATE_API_KEY } from "./utilities/constants";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.authentication);
  const { data: language } = useSelector((state) => state.language);
  const branchData = useSelector((state) => state.branch.data);
  const branchSubscriptionsServices = useSelector((state) => state.branchSubscriptionsServices);
  const { data: userAccessLevel, isFetching: userAccessLevelFetching } = useSelector(
    (state) => state.aclUserPermissions
  );
  const user = useSelector((state) => state.user);
  const [login, setLogin] = useState(localStorage.getItem("token") || false);
  const [refreshCount, setRefresh] = useState(0);
  const [routes, setRoutes] = useState([]);
  const [routesPathMap, setRoutesPathMap] = useState({});
  const [marketplacePageAccess, setMarketplacePageAccess] = useState(false);

  const userLanguage = useMemo(() => {
    try {
      const systemLanguage = localStorage.getItem("language", language);
      if (language) {
        localStorage.setItem("language", language);
        return language;
      } else {
        return systemLanguage || "en";
      }
    } catch (error) {
      console.log(error);
    }
  }, [language]);

  useEffect(() => {
    isLogin.length && setLogin(true);
  }, [isLogin]);

  useEffect(() => {
    dispatch(partnerType());
    if (login) {
      dispatch(getUserPermissions());
    }
  }, [dispatch, login]);

  useEffect(() => {
    if (Object.keys(branchData).length === 0) {
      return;
    }
    if ("enable_marketplace" in branchData) {
      setMarketplacePageAccess(branchData.enable_marketplace);
    }
  }, [branchData]);

  useEffect(() => {
    if (!userAccessLevel || userAccessLevel.formatted_component_permissions.length === 0) {
      return;
    }

    const isAdmin = userAccessLevel.formatted_component_permissions.includes("admin_access");
    dispatch(branchFetchData());
    dispatch(getBranchLoyaltyPoint());
    dispatch(clientFetchData());
    dispatch(getBranchSubscriptionsServices());
  }, [userAccessLevel]);
  // if(window.location.search === "?payments") {
  //   window.location = "https://app.glamplus.in/payments/";
  // }

  useEffect(() => {
    getToken();
    onMessageListener()
      .then((payload) => {
        toast.info(payload.notification.body, {
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.error({ err });
        toast.error("Notification setup incomplete. Contact Support.", {
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    const currentTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "light";
    document.documentElement.setAttribute("data-theme", currentTheme);
  }, []);

  const decideRoutesDependenciesMemo = useMemo(() => {
    if (
      !"id" in user ||
      !"isFetched" in branchSubscriptionsServices ||
      !branchSubscriptionsServices.isFetched ||
      userAccessLevelFetching ||
      !"base_permissions" in userAccessLevel ||
      userAccessLevel.base_permissions.length === 0
    ) {
      return false;
    }

    return true;
  }, [user, userAccessLevel, userAccessLevelFetching, branchSubscriptionsServices.data, marketplacePageAccess]);

  useEffect(() => {
    if (!decideRoutesDependenciesMemo) {
      return;
    }

    let canAddExpenses = false;
    if (branchSubscriptionsServices.data.length) {
      canAddExpenses = branchSubscriptionsServices.data.some(({ subscription_service }) => {
        return subscription_service.name === "EXPENSE_MANAGEMENT" && subscription_service.status;
      });
    }
    const AppointmentsWithLoader = WithLoader(Appointments, Loader, [
      "APPOINTMENT_LOADER",
      "STAFF_LOADER",
      "SERVICES_LOADER",
    ]);
    const ExpenseWithLoader = WithLoader(Expense, Loader, ["EXPENSE_LOADER"]);
    const StaffWithLoader = WithLoader(Staff, Loader, [
      "STAFF_LOADER",
      "POSITIONS_LOADER",
      "SHIFTS_LOADER",
      "STAFF_ABSENT_LOADER",
      "STAFF_BRACKET_LOADER",
      "STAFF_OVERTIME_LOADER",
    ]);
    const CampaignWithLoader = WithLoader(Campaign, Loader, ["SERVICES_LOADER", "PRODUCTS_LOADER"]);
    const ClientsWithLoader = WithLoader(Clients, Loader, [
      "CLIENTS_LOADER",
      "APPOINTMENT_LOADER",
      "CLIENT_MEMBERSHIP_LOADER",
    ]);

    const InventoryWithLoader = WithLoader(Inventory, Loader, ["SERVICES_LOADER", "PRODUCTS_LOADER"]);
    const MarketPlaceWithLoader = WithLoader(MarketPlace, Loader, ["PRODUCTS_LOADER"]);
    const MarketplaceOrdersWithLoader = WithLoader(MarketplaceOrders, Loader, ["ORDERS_LOADER"]);
    const InvoicesWithLoader = WithLoader(Invoices, Loader, ["APPOINTMENT_LOADER"]);

    const routesMap = {
      appointments_page: {
        path: "/",
        component: AppointmentsWithLoader,
      },
      staff_page: {
        path: "/staff",
        component: StaffWithLoader,
      },
      expenses_page: {
        path: "/expense",
        component: ExpenseWithLoader,
      },
      client_page: {
        path: "/clients",
        component: ClientsWithLoader,
      },
      inventory_page: {
        path: "/inventory",
        component: InventoryWithLoader,
      },
      invoices_page: {
        path: "/invoices",
        component: InvoicesWithLoader,
      },
      campaign_page: {
        path: "/discount",
        component: CampaignWithLoader,
      },
      subscriptions_page: {
        path: "/payments",
        component: Payments,
      },
      salary_page: {
        path: "/salary",
        component: StaffSalary,
      },
      memberships_page: {
        path: "/memberships",
        component: Memberships,
      },
      marketplace_page: {
        path: "/marketplace",
        component: MarketPlaceWithLoader,
      },
      orders_page: {
        path: "/orders",
        component: MarketplaceOrdersWithLoader,
      },
      analytics_page: {
        path: "/analytics",
        component: Analytics,
      },
      settings_page: {
        path: "/settings",
        component: Settings,
      },
    };

    let _routes = [];
    const _routesPathMap = {};

    userAccessLevel.base_permissions.forEach((permission) => {
      const { permission_name, id } = permission;

      if (
        !(permission_name in routesMap) ||
        (permission_name === "expenses_page" && !canAddExpenses) ||
        (permission_name === "marketplace_page" && !marketplacePageAccess) ||
        (permission_name === "orders_page" && !marketplacePageAccess)
      ) {
        return;
      }

      const route = <Route exact key={id} {...routesMap[permission_name]} />;
      _routes.push(route);
      _routesPathMap[routesMap[permission_name].path] = true;
    });

    _routes.push(
      <Route key={-2} exact path="/pageload" component={PageLoad} />,
      <Route key={-1} path="*" component={PageDenied} />
    );
    setRoutes(_routes);
    setRoutesPathMap(_routesPathMap);
  }, [decideRoutesDependenciesMemo]);

  const cacheProvider = {
    get: (language, key) => ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[language],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  return (
    <div className="App">
      <Translator
        cacheProvider={cacheProvider}
        from="en"
        to={userLanguage || "en"}
        googleApiKey={CLOUD_TRANSALATE_API_KEY}
      >
        {!login ? (
          <Router>
            <Route
              exact
              path="/:reset/:username?/:token?"
              render={() => <Login onClick={(state) => setLogin(state)} />}
            />
            <Route exact path="/" render={() => <Login onClick={(state) => setLogin(state)} />} />
          </Router>
        ) : (
          <Router>
            <Sidebar key={0} user={user.username} pathMap={routesPathMap} />
            <div key={1} className="main-content">
              <Header refresh={() => setRefresh(refreshCount + 1)} pathMap={routesPathMap} />
              <Container>
                <Switch>{routes}</Switch>
              </Container>
            </div>
            <ReleaseNotesModal />
          </Router>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Translator>
    </div>
  );
}

export default App;
