export function bulkSmsPromotion(state = { status: null, loading: false }, action) {
  switch (action.type) {
    case "SMS_PROMOTION_SUCCESS":
      return { status: true, loading: false, error: false };
    case "SMS_PROMOTION_HAS_ERRORED":
      return { status: false, loading: false, error: action.error };
    case "SMS_PROMOTION_RESET":
      return { status: false, loading: false, error: false };
    case "SMS_PROMOTION_LOADING":
      return { status: null, loading: true, error: false };
    default:
      return state;
  }
}
