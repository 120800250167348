import { LoadingState } from "./types";

export function productUsage(state = { data: [], loading: LoadingState.unset }, action) {
  switch (action.type) {
    case "PRODUCT_USAGE_FETCH_DATA_LOADING":
      return { ...state, ...action.data };
    case "PRODUCT_USAGE_FETCH_DATA_SUCCESS":
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function productUsageModal(state = {}, action) {
  switch (action.type) {
    case "PRODUCT_USAGE_MODAL":
      return { ...state, ...action.data };
    default:
      return state;
  }
}
