const initialState = {
  data: {},
  isFetching: false,
};

export function aclGroupPermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_GROUP_PERMISSIONS_FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "GET_GROUP_PERMISSIONS_SUCCESS":
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };

    case "GET_GROUP_PERMISSIONS_FAILURE":
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}


const initialGroupsState = {
  data: [],
  isFetching: false,
};

export function aclAllGroupsPermissionsReducer(state = initialGroupsState, action) {
  switch (action.type) {
    case "GET_ALL_GROUPS_PERMISSIONS_FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "GET_ALL_GROUPS_PERMISSIONS_SUCCESS":
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };

    case "GET_ALL_GROUPS_PERMISSIONS_FAILURE":
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
