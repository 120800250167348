import axios from "axios";
import { API_BASE_URL, SALARY_BRACKET_API, STAFF_SALARY_BRACKET_API, STAFF_API, SALARYBRACKETS } from "utilities";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { salaryBracketActions, attendenceActions,errorMessage } from "../../utilities/actionMessages";

export const getSalary = (data, id) => {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + STAFF_API + `${id}/` + SALARYBRACKETS, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          month: data,
        },
      })
      .then((response) => {
        //TODO Integrate Loaders
        dispatch({
          type: "GET_SALARY",
          payload: response.data.results,
        });
      });
  };
};

export function activeSalaryBracketFetchSuccess(staffBrackets) {
  return {
    type: "STAFF_BRACKET_FETCH_DATA_SUCCESS",
    staffBrackets,
  };
}

export function activeSalaryBracketFetch() {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading staff"));
    axios({
      method: "get",
      url: API_BASE_URL + STAFF_API + SALARY_BRACKET_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(activeSalaryBracketFetchSuccess(response.data.results));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}

export function staffSalaryPostData(payload) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading staff"));
    axios({
      method: "post",
      url: API_BASE_URL + STAFF_API + SALARY_BRACKET_API,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({message:salaryBracketActions.success_create,type:"success"})
          dispatch(activeSalaryBracketFetch());
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}

export function deactivateStaffSalaryBracket(id) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading staff"));
    const payload = {
      active: false,
    };
    axios({
      method: "patch",
      url: API_BASE_URL + STAFF_API + SALARY_BRACKET_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({message:salaryBracketActions.success_delete,type:"success"})
          dispatch(activeSalaryBracketFetch());
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}

export function updateStaffSalaryBracket(id, payload) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading staff salary"));
    axios({
      method: "post",
      url: API_BASE_URL + STAFF_API + `${id}/` + STAFF_SALARY_BRACKET_API,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-TYpe": "application/json",
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 201) {
          if("active" in response.data && !response.data.active){
            ToastAlert({message:salaryBracketActions.success_delete,type:"success"})
          }else{
            ToastAlert({message:salaryBracketActions.success_create,type:"success"})
          }
          dispatch(staffSalaryBracketFetch(id));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}

export function staffSalaryBracketFetchSuccess(staffBrackets) {
  return {
    type: "STAFF_SALARY_BRACKET_FETCH_SUCCESS",
    staffBrackets,
  };
}

export function staffSalaryBracketFetch(id) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading staff"));
    axios({
      method: "get",
      url: API_BASE_URL + STAFF_API + `${id}/` + STAFF_SALARY_BRACKET_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffSalaryBracketFetchSuccess(response.data.results));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}

export function staffAttendancePatchData(id, payload) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading positions"));
    axios({
      method: "patch",
      url: API_BASE_URL + SALARY_BRACKET_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({message:attendenceActions.success_update,type:"success"})
          dispatch(activeSalaryBracketFetch());
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}

export function staffAttendanceDeleteData(id) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_BRACKET_LOADER", "loading positions"));
    axios({
      method: "delete",
      url: API_BASE_URL + SALARY_BRACKET_API + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 204) {
          ToastAlert({message:attendenceActions.success_delete,type:"success"})
          dispatch(activeSalaryBracketFetch());
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        } else {
          dispatch(errored("STAFF_BRACKET_LOADER", true));
          dispatch(endLoading("STAFF_BRACKET_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_BRACKET_LOADER", true));
        dispatch(endLoading("STAFF_BRACKET_LOADER"));
      });
  };
}
