import { API_BASE_URL, CLIENT_MEMBERSHIP_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

export function clientMembershipFetchDataSuccess(clientMemberships) {
  return {
    type: "CLIENT_MEMBERSHIP_FETCH_DATA_SUCCESS",
    clientMemberships,
  };
}

export function clientMembershipReset() {
  return {
    type: "CLIENT_MEMBERSHIP_RESET",
    clientMemberships: [],
  };
}

export function clientMembershipFetchData(clientID) {
  const url = API_BASE_URL + CLIENT_MEMBERSHIP_API + clientID + "/membership/";
  return (dispatch) => {
    dispatch(startLoading("CLIENT_MEMBERSHIP_LOADER", "loading client"));
    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(clientMembershipFetchDataSuccess(response.data.results));
          dispatch(endLoading("CLIENT_MEMBERSHIP_LOADER"));
        } else {
          dispatch(errored("CLIENT_MEMBERSHIP_LOADER", true));
          dispatch(endLoading("CLIENT_MEMBERSHIP_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CLIENT_MEMBERSHIP_LOADER", true));
        dispatch(endLoading("CLIENT_MEMBERSHIP_LOADER"));
      });
  };
}
