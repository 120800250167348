import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetail } from "../../store/actions/marketPlace";
import { Dropdown, Input } from "semantic-ui-react";
import MarketplaceCartButton from "../MarketplaceCartButton";
import "./index.scss";

import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

function ProductDetails({ productId }) {
  const [imageNum, setImageNum] = useState(0);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [variant, setVariant] = useState(0);
  const dispatch = useDispatch();

  const productDetail = useSelector((state) => state.productDetail);
  const { images, name, /*description,*/ variants } = productDetail;
  const variantImages = variants && variants[variant].inventory.product.images[imageNum];

  function handleRightArrow() {
    setImageNum((imageNum + 1) % images.length);
  }

  function handleLeftArrow() {
    if (imageNum === 0) {
      setImageNum(images.length - 1);
    } else {
      setImageNum(imageNum - 1);
    }
  }

  useEffect(() => {
    setImageNum(0);
    dispatch(ProductDetail(productId));
  }, [productId, dispatch]);
  return (
    <div className="product-detail-container">
      <h1>
        <Translate>Product Detail</Translate>
      </h1>
      <div className="img-container">
        <i className={`${images && images.length <= 1 && "none"} angle left icon`} onClick={handleLeftArrow}></i>
        <img
          className="product-img"
          src={variantImages ? variantImages.url : images && images.length ? images[imageNum] : "/no-image.png"}
          alt={name}
        />
        <i className={`${images && images.length <= 1 && "none"} angle right icon`} onClick={handleRightArrow}></i>
      </div>
      {variants && variants.length > 1 && (
        <div className="product-size">
          <h3>Select Size/Color :</h3>
          <Dropdown
            width={50}
            placeholder={translateFormLabel("Product Sizes")}
            selection
            // defaultValue={1}
            value={variant}
            onChange={(event, { value }) => {
              setVariant(value);
            }}
            options={variants
              .filter((i) => i.size)
              .map((variant, index) => {
                return {
                  key: index,
                  text: `${variant.size} ${variant.unit}`,
                  value: index,
                };
              })}
          />
        </div>
      )}
      <div className="select-quantity">
        <h3>
          <Translate>Select Quantity :</Translate>
        </h3>
        <Input
          className="select-input"
          value={itemQuantity}
          style={{ width: "196px" }}
          onChange={(event, { value }) => {
            if (/^\d+$/.test(value)) {
              setItemQuantity(value);
            }
          }}
        />
      </div>
      <h3>
        <Translate>Product Name: </Translate>
        <span>
          {name} {variants && variants[variant].size} {variants && variants[variant].unit}
        </span>
      </h3>
      <h3>
        <Translate>Price: </Translate>
        <span>Rs. {variants && variants[variant].list_cost}</span>
      </h3>
      <h3>
        <Translate>MRP: </Translate>
        <span>Rs. {variants && variants[variant].mrp}</span>
      </h3>
      {productDetail.name && variants[variant].inventory.quantity === 0 && (
        <div className="out-of-stock">
          <span>
            <Translate>Product out of stock</Translate>
          </span>
        </div>
      )}
      {productDetail.name && variants[variant].inventory.quantity !== 0 && (
        <div className="cart-btn">
          <MarketplaceCartButton itemData={productDetail.variants[variant]} itemQuantity={itemQuantity} />
        </div>
      )}
      {/*<h3>Description: <p>{description}</p></h3>*/}
    </div>
  );
}

export default ProductDetails;
