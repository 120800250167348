import { API_BASE_URL, GROUP_PERMISSIONS_API } from "utilities";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { aclGrouptActions, errorMessage } from "../../utilities/actionMessages";
import { authenticationLogout } from "./authentication";
import { IsJsonString } from "../../utilities/CommonFunctions";
import { getUserPermissions } from "./aclUserPermissions";

export const getAllGroupsPermissions = () => {
  let URL = API_BASE_URL + GROUP_PERMISSIONS_API;
  return (dispatch) => {
    dispatch({
      type: "GET_ALL_GROUPS_PERMISSIONS_FETCHING",
    });
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "GET_ALL_GROUPS_PERMISSIONS_SUCCESS",
          data: response.data.results || [],
        });
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "GET_ALL_GROUPS_PERMISSIONS_FAILURE",
        });
      });
  };
};

export const getGroupPermissions = (groupId) => {
  let URL = API_BASE_URL + GROUP_PERMISSIONS_API + groupId + "/";
  return (dispatch) => {
    dispatch({
      type: "GET_GROUP_PERMISSIONS_FETCHING",
    });
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "GET_GROUP_PERMISSIONS_SUCCESS",
          data: response.data || {},
        });
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "GET_GROUP_PERMISSIONS_FAILURE",
        });
      });
  };
};

export const createGroupPermissions = (data) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: API_BASE_URL + GROUP_PERMISSIONS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
      .then(function () {
        ToastAlert({ message: aclGrouptActions.success_create, type: "success" });
        dispatch(getAllGroupsPermissions());
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
      });
  };
};

export const updateGroupPermissions = (groupId, data, status) => {
  return (dispatch) => {
    axios({
      method: status === 1 ? "patch" : "delete",
      url: API_BASE_URL + GROUP_PERMISSIONS_API + groupId + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
      .then(function (response) {
        ToastAlert({ message: aclGrouptActions.success_update, type: "success" });
        dispatch(getUserPermissions());
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
      });
  };
};
