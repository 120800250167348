const initState = {
  activeBrackets: [],
  staffBrackets: [],
};

export function staffBracket(state = initState, action) {
  switch (action.type) {
    case "STAFF_BRACKET_FETCH_DATA_SUCCESS":
      return {
        ...state,
        activeBrackets: action.staffBrackets,
      };

    case "STAFF_SALARY_BRACKET_FETCH_SUCCESS":
      return {
        ...state,
        staffBrackets: action.staffBrackets,
      };
    default:
      return state;
  }
}
