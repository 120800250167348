import { createSelector } from "reselect";
import { PRODUCTS_DATA } from "../actions/products";
const productsSelector = (state) => PRODUCTS_DATA(state);

export const productsOptionsSelector = createSelector([productsSelector], (products) => {
  const options = products
    .filter((product) => product.status)
    .map((product) => {
      return {
        text: product.name,
        value: product.id,
        product,
      };
    });

  return options;
});
