export var startLoading = (id, text) => {
  return {
    type: "START_LOADING",
    id,
    text,
  };
};

export var endLoading = (id) => {
  return {
    type: "END_LOADING",
    id,
  };
};

export var errored = (id, status) => {
  return {
    type: "ERRORED",
    id,
    status,
  };
};
