const initalState = {
  data: null,
  loading: false,
  errored: false,
};

export const marketPlaceOrderTransaction = (state = initalState, action) => {
  switch (action.type) {
    case "MARKETPLACE_START_TRANSACTION_SUCCESS":
      return {
        ...initalState,
        data: action.payload,
      };
    case "MARKETPLACE_START_TRANSACTION_ERROR":
      return {
        ...initalState,
        errored: true,
      };
    case "MARKETPLACE_START_TRANSACTION_RESET":
      return {
        ...initalState,
      };
    default:
      return state;
  }
};
