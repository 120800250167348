import React, { useEffect, useState } from "react";
import { Form as NewComponentForm } from "../../new-components";
import { useFormik } from "formik";
import "./index.scss";
import { useSelector } from "react-redux";
import HoverTable from "HoverTable";
import { Button, Dropdown, Form, Icon } from "semantic-ui-react";
import Description from "Description";
import { colors, shape } from "../../utilities/constants";
import { labelsSchema } from "../../utilities/validator";
import { Translate } from "react-auto-translate/lib/commonjs";

const LabelHeader = ["Name", "Short Name", "properties"];

const initialFormData = {
  name: "",
  description: "",
  short_name: "",
  category1: "",
  category2: "",
  properties: { color: "", shape: "" },
};

const renderLabels = [
  ({ name, description }) => {
    return <Description title={name} description={description} />;
  },
  ({ short_name }) => {
    return <Description title={short_name} />;
  },
  ({ properties }) => {
    return <Description title={properties.color} />;
  },
  (value, edit, remove) => {
    return value.status === 0 ? (
      <Icon key={0} className="user delete" name="edit" />
    ) : (
      [
        <Icon key={0} className="pointer" name="edit" onClick={() => edit(value)} />,
        <Icon key={1} className="pointer" name="trash" onClick={() => remove(value)} />,
      ]
    );
  },
];

const LabelsForm = ({ type = "service", name = "", onAddLabel, onUpdateLabel }) => {
  const [labelListState, setLabelListState] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const labelsList = useSelector((state) => {
    if (type === "service") {
      return state.serviceLabels;
    }
    if (type === "product") {
      return state.productLabels;
    }
    if (type === "client") {
      return state.clientLabels;
    }
  });

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: labelsSchema,
    onSubmit: (values) => {
      if (!isEdit) {
        onAddLabel(values);
      } else {
        onUpdateLabel(values.id, values);
      }
      resetForm();
    },
  });

  useEffect(() => {
    if (labelsList.data.length > 0) {
      setLabelListState(labelsList.data);
    }
  }, [labelsList]);

  const resetForm = () => {
    formik.setValues(initialFormData);
    setIsEdit(false);
  };

  const TooltipError = ({ message }) => {
    return <div className="tooltip-error">{message}</div>;
  };

  return (
    <div className="label-container">
      <div>
        <NewComponentForm>
          <NewComponentForm.Title>
            {name}
            <Translate> Labels/Categories</Translate>
          </NewComponentForm.Title>
          <NewComponentForm.Divider> </NewComponentForm.Divider>
        </NewComponentForm>
      </div>
      <div>
        <NewComponentForm>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                id="name"
                fluid
                label="Name *"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name}
              />
              <Form.Input
                fluid
                id="short_name"
                label="Short Name *"
                placeholder="Short Name"
                value={formik.values.short_name}
                onChange={formik.handleChange}
                error={formik.errors.short_name}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                id="description"
                fluid
                label="Description *"
                placeholder="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.errors.description}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Field>
                  <label>
                    <Translate>Colors *</Translate>
                  </label>
                </Form.Field>
                <Dropdown
                  placeholder="Colors"
                  fluid
                  selection
                  onChange={(_, data) => {
                    const { value } = data;
                    formik.setValues({
                      ...formik.values,
                      properties: {
                        ...formik.values.properties,
                        color: value,
                      },
                    });
                  }}
                  options={colors}
                  value={formik.values.properties.color}
                />
                {formik.errors.properties?.color && <TooltipError message={formik.errors.properties?.color} />}
              </Form.Field>
              <Form.Field>
                <Form.Field>
                  <label>
                    <Translate>Shape *</Translate>
                  </label>
                </Form.Field>
                <Dropdown
                  placeholder="Shape"
                  fluid
                  search
                  selection
                  onChange={(_, data) => {
                    const { value } = data;
                    formik.setValues({
                      ...formik.values,
                      properties: {
                        ...formik.values.properties,
                        shape: value,
                      },
                    });
                  }}
                  options={shape}
                  value={formik.values.properties.shape}
                />
                {formik.errors.properties?.shape && <TooltipError message={formik.errors.properties?.shape} />}
              </Form.Field>
            </Form.Group>
            <div className="label-submit-btn-container">
              <Button
                onClick={() => {
                  resetForm();
                }}
              >
                <Translate>Reset</Translate>
              </Button>
              <Button primary type="submit">
                {isEdit ? "Update" : "Create"}
              </Button>
            </div>
          </Form>
        </NewComponentForm>
        {/*  */}
        <div>
          <NewComponentForm>
            <HoverTable
              header={LabelHeader}
              src={labelListState}
              renderSpecial={renderLabels}
              remove={(value) => onUpdateLabel(value.id, { ...value, status: false })}
              edit={(label) => {
                setIsEdit(true);
                formik.setValues({ ...formik.values, ...label });
              }}
            />
          </NewComponentForm>
        </div>
      </div>
    </div>
  );
};

export default LabelsForm;
