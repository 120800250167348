import React, { useEffect, useState } from "react";
import "./index.scss";
import { Dropdown } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import _ from "lodash";

const fileUpload = React.createRef();

const ALLOWED_FILE_TYPES = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

function FileUpload({ limit, onChange, value, ALLOWED_TYPES = ALLOWED_FILE_TYPES }) {
  const [files, setFiles] = useState(value);
  const [error, setError] = useState(false);
  const ALLOWED_MAX_FILE_SIZE = 1048576;

  useEffect(() => {
    setFiles(value);
  }, [value]);

  const fileSelected = (e) => {
    if (e) {
      const { size, type } = e.target.files[0];
      // console.log({ size, type }, !ALLOWED_TYPES.includes(type));
      if (size > ALLOWED_MAX_FILE_SIZE || !ALLOWED_TYPES.includes(type)) {
        setError(true);
      } else {
        setError(false);
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        file["url"] = url;
        file["key"] = new Date().valueOf().toString();
        const new_files = [...files, file];
        setFiles(new_files);
        onChange(new_files);
      }
    }
  };

  return [
    <Dropdown
      key={0}
      className={"file-upload"}
      open={false}
      placeholder={translateFormLabel("Upload Documents")}
      icon={"upload"}
      onClick={() => fileUpload.current.click()}
      options={_.map(files, ({ name }, index) => ({ key: index, value: name, text: name }))}
      value={[...new Set(_.map(files, ({ name }) => name))]}
      onChange={(event, { value }) => {
        const new_files = _.filter(files, (file) => _.includes(value, file.name));
        setFiles(new_files);
        onChange(new_files);
      }}
      onLabelClick={(event, { value }) => {
        const { url } = _.filter(files, { name: value })[0];
        const win = window.open(url, "_blank");
        win.focus();
      }}
      fluid
      multiple
      selection
    />,
    error && (
      <span key={1} className="error">
        <Translate>
          Selected file was more than the specified limit of {parseInt(ALLOWED_MAX_FILE_SIZE / 1000000)} Mb or not of
          type image or pdf.
        </Translate>
      </span>
    ),
    <input
      disabled={value.length >= limit}
      key={2}
      type="file"
      ref={fileUpload}
      className="file-upload-input"
      onChange={(e) => fileSelected(e)}
      onClick={(event) => {
        event.target.value = null;
      }}
    />,
  ];
}

export default FileUpload;
