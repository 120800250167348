import { API_BASE_URL } from "utilities";
import axios from "axios";
import { STAFF_OVERTIME } from "../../utilities";
import { authenticationLogout } from "./authentication";
import { endLoading, errored, startLoading } from "./withLoader";
import moment from "moment";

import ToastAlert from "../../utilities/toastAlert/toastAlert"
import { overtimeActions, errorMessage } from "../../utilities/actionMessages";

function DateConvertor(date) {
  return moment(date).format("YYYY-MM-DD");
}

function GetStartDate(givenDate = false) {
  var startDate;
  if (givenDate) {
    startDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
  } else {
    var date = new Date();
    startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  }

  return moment(startDate).format("YYYY-MM-DD");
}

function GetEndDate(givenDate = false) {
  var endDate;
  if (givenDate) {
    endDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);
  } else {
    var date = new Date();
    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  return moment(endDate).format("YYYY-MM-DD");
}

export function staffOvertimePostData(payload, staffID, selectedDate = false) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_OVERTIME_LOADER", "loading overtime"));
    axios({
      method: "post",
      url: API_BASE_URL + STAFF_OVERTIME,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({message:overtimeActions.success_create,type:"success"})
          dispatch(staffOvertimeFetchData(GetStartDate(selectedDate), GetEndDate(selectedDate), staffID));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        } else {
          dispatch(errored("STAFF_OVERTIME_LOADER", true));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_OVERTIME_LOADER", true));
        dispatch(endLoading("STAFF_OVERTIME_LOADER"));
      });
  };
}

export function staffOvertimeFetchDataSuccess(staffOvertime) {
  return {
    type: "STAFF_OVERTIME_FETCH_DATA_SUCCESS",
    staffOvertime,
  };
}

export function staffOvertimePatchData(id, payload, staffID, selectedDate = false) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_OVERTIME_LOADER", "loading overtime"));
    axios({
      method: "patch",
      url: API_BASE_URL + STAFF_OVERTIME + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({message:overtimeActions.success_update,type:"success"})
          dispatch(staffOvertimeFetchData(GetStartDate(selectedDate), GetEndDate(selectedDate), staffID));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        } else {
          dispatch(errored("STAFF_OVERTIME_LOADER", true));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_OVERTIME_LOADER", true));
        dispatch(endLoading("STAFF_OVERTIME_LOADER"));
      });
  };
}

export function staffOvertimeFetchData(startDate, endDate, staffID = null) {
  let URL;
  if (staffID) {
    URL =
      API_BASE_URL +
      STAFF_OVERTIME +
      `?staff_id=${staffID}&start_date=${DateConvertor(startDate)}&end_date=${DateConvertor(endDate)}`;
  } else {
    URL = API_BASE_URL + STAFF_OVERTIME + `?start_date=${DateConvertor(startDate)}&end_date=${DateConvertor(endDate)}`;
  }

  return (dispatch) => {
    dispatch(startLoading("STAFF_OVERTIME_LOADER", "loading overtime"));
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffOvertimeFetchDataSuccess(response.data.results));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        } else {
          dispatch(errored("STAFF_OVERTIME_LOADER", true));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_OVERTIME_LOADER", true));
        dispatch(endLoading("STAFF_OVERTIME_LOADER"));
      });
  };
}

export function staffOvertimeDeleteData(id, staffID, selectedDate = false) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_OVERTIME_LOADER", "loading overtime"));
    axios({
      method: "delete",
      url: API_BASE_URL + STAFF_OVERTIME + `${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 204) {
          ToastAlert({message:overtimeActions.success_delete,type:"success"})
          dispatch(staffOvertimeFetchData(GetStartDate(selectedDate), GetEndDate(selectedDate), staffID));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        } else {
          dispatch(errored("STAFF_OVERTIME_LOADER", true));
          dispatch(endLoading("STAFF_OVERTIME_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({message:errorMessage,type:"error"})
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_OVERTIME_LOADER", true));
        dispatch(endLoading("STAFF_OVERTIME_LOADER"));
      });
  };
}
