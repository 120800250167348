import { API_BASE_URL, BARCODE_API } from "utilities";
import axios from "axios";
import { createSelector } from "reselect";
import _ from "lodash";
import { PRODUCTS_DATA } from "./products";

export function productBarcodeList(results) {
  return {
    type: "GET_PRODUCT_BARCODE_LIST",
    response: {
      data: results,
    },
  };
}

export function getProductsBarcode() {
  return (dispatch) => {
    try {
      axios
        .get(API_BASE_URL + BARCODE_API, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(({ data }) => {
          dispatch(productBarcodeList(data.results));
        });
    } catch (error) {}
  };
}

const barcodesSelector = (state) => state.barcode?.data || [];
const productsSelector = (state) => PRODUCTS_DATA(state) || [];

export const productBarcodeMap = createSelector([barcodesSelector, productsSelector], (barcode, products) => {
  const productsBarcode = _.groupBy(barcode, "product");

  if (products?.length) {
    const productList = products.map((ele) => {
      return {
        ...ele,
        barcode: productsBarcode[ele.id] || null,
      };
    });
    return productList;
  }
  return [];
});
