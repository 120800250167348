import { validateRegex, STANDARD_MENU } from "./utilities";

//if partner, change all the "appoitnments" to "bookings"
import { IS_PARTNER } from "./utilities/constants";
const APPOINTMENT_LABEL = IS_PARTNER ? "Booking" : "Appointment";

const APPOINTMENT_NAME_NAVIGATION = IS_PARTNER ? "Bookings" : "Dashboard";

const mockData = {
  sidebarLinksMap: [
    { link: "/", name: `${APPOINTMENT_NAME_NAVIGATION}`, icon: "calendar outline" },
    { link: "/staff", name: "Staff", icon: "user plus" },
    { link: "/clients", name: "Clients", icon: "user circle" },
    { link: "/inventory", name: "Inventory", icon: "sitemap" },
    { link: "/expense", name: "Expenses", icon: "monero" },
    { link: "/discount", name: "Discount", icon: "mix" },
    { link: "/analytics", name: "Analytics", icon: "chart bar outline" },
    { link: "/invoices", name: "Invoices", icon: "ils" },
    { link: "/salary", name: "Salary", icon: "university" },
    { link: "/memberships", name: "Memberships", icon: "sliders" },
    { link: "/marketplace", name: "Marketplace", icon: "shopping bag" },
    { link: "/orders", name: "Your Orders", icon: "address book" },
    { link: "/payments", name: "Subscriptions", icon: "payment" },
  ],
  appointment_form: [
    {
      type: "search",
      label: "name",
      field: "customer.name",
      search: "customer/?page_size=5&search=",
      fields: {
        title: "name",
        description: "number",
        extra1: "gender",
        extra2: "email",
        extra3: "memberships",
        extra4: "revenue",
        extra5: "pending_payments",
        extra6: "affiliated_staff",
        extra7: "date_of_birth",
        extra8: "date_of_anniversary",
        extra9: "country_code",
        extra10: "custom_field_one",
        extra11: "custom_field_two",
        extra12: "customer_gst_number",
        extra13: "is_active",
        name: "customer",
      },
      validation: {
        required: true,
        validate: (value) => {
          return !value || value.length < 3;
        },
        error: "Minimum 3 letters",
      },
    },
    {
      // appointment-number
      type: "search",
      label: "number",
      field: "customer.number",
      search: "customer/?page_size=5&search=",
      fields: {
        title: "number",
        description: "name",
        extra1: "gender",
        extra2: "email",
        extra3: "memberships",
        extra4: "revenue",
        extra5: "pending",
        extra6: "affiliated_staff",
        extra7: "date_of_birth",
        extra8: "date_of_anniversary",
        extra9: "country_code",
        extra10: "custom_field_one",
        extra11: "custom_field_two",
        extra12: "customer_gst_number",
        extra13: "is_active",
        name: "customer",
      },
      validation: {
        required: true,
        validate: (value, isDuplicateNumber, form) => {
          if (isDuplicateNumber) return true;
          else if (form["customer.country_code"] === "+91") {
            return validateRegex(value, "^[0-9]{10}$");
          }
          return validateRegex(value, "^[0-9]{8,12}$");
        },
        error: "Valid mobile number",
      },
    },
    {
      type: "radio",
      label: "gender",
      field: "customer.gender",
      options: {
        M: "Male",
        F: "Female",
        O: "Others",
      },
      validation: {
        required: true,
        validate: (value) => {
          return !value;
        },
        error: "*R",
      },
      className: "gender",
    },
    {
      type: "text",
      label: "email",
      field: "customer.email",
      length: 30,
      // "validate": "^[a-zA-Z\\. ]+$",
      validate: (value) => {
        return validateRegex(value, "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/");
      },
      required: false,
      className: "email",
    },
    {
      type: "datetime",
      label: "date & time",
      field: "datetime",
      validation: {
        required: true,
        validate: (value) => {
          return !value;
        },
        error: "Valid date & time",
      },
      className: "datetime",
    },
    {
      type: "date",
      label: "Date of Birth",
      field: "customer.date_of_birth",
      className: "datetime",
    },
    {
      type: "date",
      label: "Date of Anniversary",
      field: "customer.date_of_anniversary",
      className: "datetime",
    },
    {
      type: "dropdown",
      label: "discount",
      field: "discount_id",
      multiple: false,
      options: [],
      required: false,
      className: "same-line-1",
    },
    {
      type: "dropdown",
      label: "membership",
      field: "membership",
      extraModal: {
        present: true,
        text: "(Show history)",
      },
      multiple: false,
      options: [],
      required: false,
      className: "same-line-1",
    },
    {
      type: "dropdown",
      label: "affiliated staff",
      field: "affiliated_staff",
      multiple: false,
      options: [],
      required: false,
      className: "same-line-1",
    },
    {
      type: "services",
      label: "Required Services",
      field: "required_services",
      options: {
        services: [],
        providers: [],
      },
    },
    {
      type: "products",
      label: "Required Products",
      field: "required_products",
      options: {
        products: [],
        providers: [],
      },
    },
    {
      type: "text",
      label: "customer GST field",
      field: "customer.customer_gst_number",
      // length: 30,
      required: false,
      className: "custom-gst-field",
    },
    {
      type: "text",
      label: "custom field one",
      field: "customer.custom_field_one",
      // length: 30,
      required: false,
      className: "custom-gst-field",
    },
    {
      type: "text",
      label: "custom field two",
      field: "customer.custom_field_two",
      // length: 30,
      required: false,
      className: "custom-gst-field",
    },
    {
      type: "checkbox",
      label: `Send ${APPOINTMENT_LABEL.toLowerCase()} sms`,
      field: "send_sms",
      value: true,
      className: "same-line-checkbox",
    },
    // {
    //   type: "checkbox",
    //   label: `Send ${APPOINTMENT_LABEL.toLowerCase()} feedback`,
    //   field: "send_feedback",
    //   value: true,
    //   className: "same-line-checkbox",
    // },
    {
      type: "checkbox",
      label: "Outdoor service",
      field: "outdoor_service",
      value: false,
      className: "same-line-checkbox",
    },
    {
      type: "checkbox",
      label: "Check Availability",
      field: "check_availability",
      value: false,
      className: "same-line-checkbox",
    },
    {
      type: "checkbox",
      label: "Apply Tax",
      field: "is_tax_applied",
      value: false,
      className: "same-line-checkbox",
    },
    {
      type: "checkbox",
      label: "Send payment link",
      field: "send_payment_link",
      value: false,
      className: "same-line-checkbox",
    },
  ],
  quick_appointment_form: [
    {
      // appointment-number
      type: "search",
      label: "number",
      field: "customer.number",
      search: "customer/?page_size=5&search=",
      fields: {
        title: "number",
        description: "name",
        extra1: "gender",
        extra2: "email",
        extra3: "memberships",
        extra4: "revenue",
        extra5: "pending",
        extra6: "affiliated_staff",
        extra7: "date_of_birth",
        extra8: "date_of_anniversary",
        extra9: "country_code",
        extra10: "custom_field_one",
        extra11: "custom_field_two",
        extra12: "customer_gst_number",
        extra13: "is_active",
        name: "customer",
      },
      validation: {
        required: true,
        validate: (value, isDuplicateNumber, form) => {
          if (isDuplicateNumber) return true;
          else if (form["customer.country_code"] === "+91") {
            return validateRegex(value, "^[0-9]{10}$");
          }
          return validateRegex(value, "^[0-9]{8,12}$");
        },
        error: "Valid mobile number",
      },
    },
    {
      type: "search",
      label: "name",
      field: "customer.name",
      search: "customer/?page_size=5&search=",
      fields: {
        title: "name",
        description: "number",
        extra1: "gender",
        extra2: "email",
        extra3: "memberships",
        extra4: "revenue",
        extra5: "pending_payments",
        extra6: "affiliated_staff",
        extra7: "date_of_birth",
        extra8: "date_of_anniversary",
        extra9: "country_code",
        extra10: "custom_field_one",
        extra11: "custom_field_two",
        extra12: "customer_gst_number",
        extra13: "is_active",
        name: "customer",
      },

      validation: {
        required: false,
        validate: (value) => {
          if (value?.length > 0) {
            return value.length < 3;
          }
          return false;
        },
        error: "0 letters or Minimum 3 letters",
      },
    },
    {
      type: "radio",
      label: "gender",
      field: "customer.gender",
      options: {
        M: "Male",
        F: "Female",
        O: "Others",
      },
      className: "gender",
    },
    {
      type: "services",
      label: "Required Services",
      field: "required_services",
      options: {
        services: [],
        providers: [],
      },
    },
    {
      type: "products",
      label: "Required Products",
      field: "required_products",
      options: {
        products: [],
        providers: [],
      },
    },
    {
      type: "checkbox",
      label: "Apply Tax",
      field: "is_tax_applied",
      value: false,
      className: "same-line-checkbox",
    },
    {
      type: "checkbox",
      label: `Send ${APPOINTMENT_LABEL.toLowerCase()} sms`,
      field: "send_sms",
      value: true,
      className: "same-line-checkbox",
    },
  ],
  staff_form: [
    {
      type: "text",
      label: "name*",
      field: "user.first_name",
      length: 30,
      validate: "^[a-zA-Z\\. ]+$",
      required: true,
    },
    {
      type: "text",
      label: "number*",
      field: "phone_number",
      length: 10,
      validate: "^[1-9][0-9]{9}$",
      required: true,
    },
    {
      type: "radio",
      label: "gender*",
      field: "gender",
      options: {
        M: "Male",
        F: "Female",
        O: "Others",
      },
      required: true,
    },
    {
      type: "dropdown",
      label: "designation",
      field: "designation.id",
      multiple: false,
      options: [],
      required: true,
    },
    {
      type: "date",
      label: "date of joining*",
      field: "doj",
    },
    {
      type: "dropdown",
      label: "shift",
      field: "shift.id",
      multiple: false,
      options: [],
    },
    {
      type: "commission",
      label: "product commission",
      field: "product_commission",
    },
    {
      type: "commission",
      label: "service commission",
      field: "service_commission",
    },
    {
      type: "dropdown",
      label: "Access Type*",
      field: "user_access.user_group",
      multiple: false,
      options: [],
      required: true,
      disabled: false,
    },
    {
      type: "file-upload",
      label: "upload documents",
      sub_label: "(jpg, png or pdf. 1 mb or less)",
      field: "files",
      options: 5,
    },
  ],
  client_form: [
    {
      type: "text",
      label: "name",
      field: "user.first_name",
      length: 30,
      validate: "^[a-zA-Z\\. ]+$",
      required: true,
    },
    {
      type: "text",
      label: "number",
      field: "phone_number",
      length: 10,
      validate: "^[1-9][0-9]{9}$",
      required: true,
    },
  ],
  register: [
    {
      type: "text",
      label: "outlet name",
      field: "name",
      length: 30,
      validation: {
        required: true,
        validate: (value) => {
          return !(value && value.length > 4);
        },
        error: "Minimum 5 characters",
      },
    },
    {
      type: "text",
      label: "number",
      field: "contact",
      length: 10,
      validation: {
        required: true,
        validate: (value) => {
          return validateRegex(value, "^[0-9]{10}$");
        },
        error: "Valid mobile number",
      },
    },
    {
      type: "text",
      label: "outlet address",
      field: "address",
      length: 100,
      validation: {
        required: true,
      },
    },
    {
      type: "dropdown",
      label: "country",
      field: "country",
      multiple: false,
      options: [],
      required: true,
    },
    {
      type: "dropdown",
      label: "state",
      field: "state",
      multiple: false,
      options: [],
      required: true,
    },
    {
      type: "dropdown",
      label: "city",
      field: "city1",
      multiple: false,
      options: [],
      required: true,
    },
    {
      type: "text",
      label: "Pin code",
      field: "pin_code",
      length: 6,
      validation: {
        required: true,
        validate: (value) => {
          return validateRegex(value, "^[0-9]{6}$");
        },
      },
    },
    {
      type: "text",
      label: "GST Number",
      field: "gst",
      length: 50,
    },
  ],
  service_form: [
    {
      type: "search-input",
      label: "name*",
      placeholder: "name",
      sub_label: "(Enter or search from a predefined list)",
      field: "name",
      length: 30,
      required: true,
      options: STANDARD_MENU.map((item, index) => ({ key: index, text: item, value: item })),
    },
    {
      type: "radio",
      label: "gender*",
      field: "gender",
      options: {
        M: "Male",
        F: "Female",
        U: "Unisex",
        O: "Others",
      },
      required: true,
    },
    {
      type: "decimal",
      label: "cost*",
      field: "cost",
      length: 7,
      required: true,
    },
    {
      type: "duration",
      label: "time*",
      field: "time",
      length: 3,
      required: true,
    },
    {
      type: "text",
      label: "code",
      field: "code",
      length: 10,
      required: false,
    },
    {
      type: "textarea",
      label: "custom field one",
      field: "custom_field_one",
      length: 600,
      required: false,
    },
  ],
  product_form: [
    {
      type: "text",
      label: "name*",
      field: "name",
      length: 100,
      required: true,
    },
    {
      type: "dropdown",
      label: "company*",
      field: "company.id",
      multiple: false,
      options: [],
      required: true,
    },
    {
      type: "decimal",
      label: "cost price(MRP)*",
      field: "cost",
      length: 7,
      required: true,
    },
    {
      type: "decimal",
      label: "price(procurement price)*",
      field: "price",
      length: 7,
      required: true,
    },
    {
      type: "number",
      label: "quantity*",
      field: "quantity",
      length: 7,
      required: true,
    },
    {
      type: "number",
      label: "volume(in ml/gm)*",
      field: "volume",
      length: 7,
      required: true,
    },
    {
      type: "text",
      label: "code",
      field: "code_1",
      length: 10,
      required: true,
    },
    {
      type: "date",
      label: "mfg date*",
      field: "mfg_date",
      required: true,
    },
    {
      type: "date",
      label: "exp date*",
      field: "exp_date",
      required: true,
    },
    {
      type: "text",
      label: "HSN/SAC",
      field: "hsn_sac",
      required: false,
    },
  ],
  product_usage_form: [
    {
      type: "number",
      label: "Product Usage",
      field: "consumption_value",
      length: 100,
      required: true,
    },
  ],
  company_form: [
    {
      type: "text",
      label: "name*",
      field: "name",
      length: 30,
      required: true,
    },
    {
      type: "text",
      label: "address",
      field: "address",
      length: 30,
    },
    {
      type: "text",
      label: "code",
      field: "code",
      length: 10,
    },
    {
      type: "number",
      label: "contact",
      field: "contact",
      length: 10,
    },
    {
      type: "date",
      label: "start date",
      field: "start_date",
    },
  ],
  seller_form: [
    {
      type: "text",
      label: "name*",
      field: "name",
      length: 50,
      required: true,
    },
    {
      type: "text",
      label: "email*",
      field: "email",
      length: 50,
      required: true,
    },
    {
      type: "number",
      label: "contact*",
      field: "contact",
      length: 10,
      required: true,
    },
    {
      type: "text",
      label: "GST Number",
      field: "gst_number",
      length: 15,
      required: false,
    },
    {
      type: "text",
      label: "code",
      field: "code",
      length: 20,
      required: false,
    },
  ],
  shift_form: [
    {
      type: "time",
      label: "Start Time*",
      field: "start_time",
      required: true,
    },
    {
      type: "time",
      label: "End Time*",
      field: "end_time",
      required: true,
    },
    {
      type: "dropdown",
      label: "Weekly Offs",
      field: "weekly_offs",
      options: [
        {
          key: 0,
          value: "mon",
          text: "Monday",
        },
        {
          key: 1,
          value: "tues",
          text: "Tuesday",
        },
        {
          key: 2,
          value: "wed",
          text: "Wednesday",
        },
        {
          key: 3,
          value: "thur",
          text: "Thursday",
        },
        {
          key: 4,
          value: "fri",
          text: "Friday",
        },
        {
          key: 5,
          value: "sat",
          text: "Saturday",
        },
        {
          key: 6,
          value: "sun",
          text: "Sunday",
        },
      ],
      multiple: true,
      className: "multiple",
      required: true,
    },
  ],
  position_form: [
    {
      type: "text",
      label: "Designation*",
      field: "name",
      length: 30,
      className: "left-align",
      required: true,
    },
    {
      type: "dropdown",
      label: "Services Allowed*",
      field: "services_list",
      multiple: true,
      className: "multiple",
      required: true,
    },
  ],
  commission_form: [
    {
      type: "dropdown",
      label: "Bracket",
      field: "bracket",
      multiple: false,
      required: true,
    },
    {
      type: "datetime",
      label: "start date",
      field: "start_date",
      required: true,
      validation: {
        required: true,
        validate: (value) => {
          return !value;
        },
      },
    },
  ],
  staff_salary_bracket_form: [
    {
      type: "dropdown",
      label: "Bracket",
      field: "bracket",
      multiple: false,
      required: true,
    },
    {
      type: "date",
      label: "start date",
      field: "start_date",
      required: true,
      validation: {
        required: true,
        validate: (value) => {
          return !value;
        },
      },
    },
  ],
  customer_message: [
    {
      type: "search",
      label: "name",
      field: "customer.name",
      search: "customer/?search=",
      fields: {
        title: "name",
        description: "number",
        extra1: "gender",
        extra2: "email",
        extra3: "memberships",
        extra4: "revenue",
        name: "customer",
      },
      validation: {
        required: true,
        validate: (value) => {
          return !value || value.length < 3;
        },
        error: "Minimum 3 letters",
      },
    },
    {
      type: "search",
      label: "number",
      field: "customer.number",
      search: "customer/?search=",
      fields: {
        title: "number",
        description: "name",
        extra1: "gender",
        extra2: "email",
        extra3: "campaign",
        name: "customer",
      },
      validation: {
        required: true,
        validate: (value) => {
          return validateRegex(value, "^[0-9]{8,11}$");
        },
        error: "Valid mobile number",
      },
    },
    {
      type: "text",
      label: "Bill Amount",
      field: "bill_amount",
      length: 30,
      validate: "^[a-zA-Z\\. ]+$",
      required: true,
      validation: {
        required: true,
        validate: (value) => {
          return validateRegex(value, "^[0-9]+$");
        },
        error: "Valid bill amount",
      },
    },
    {
      type: "date",
      label: "Date",
      field: "date",
      length: 10,
      validate: "^[1-9][0-9]{9}$",
      required: true,
    },
  ],
  dropdownFilters: [
    { key: 0, text: "Enquiry", value: 0 },
    { key: 1, text: "Scheduled", value: 1 },
    { key: 2, text: "Ongoing", value: 2 },
    { key: 3, text: "Completed", value: 3 },
    { key: 4, text: "Cancelled", value: 4 },
    { key: 5, text: "Hidden", value: 5 },
    { key: 6, text: "Deleted", value: 6 },
  ],
};

export default mockData;
