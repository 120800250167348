import { combineReducers } from "redux";
import { withLoader } from "./withLoader";
import { authentication, authenticationHasErrored, authenticationIsLoading } from "./authentication";
import { user, userHasErrored, userIsLoading } from "./user";
import { appointments, appointmentInvoice, basicAppointments } from "./appointments";
import { shouldRefreshClientSearchList } from "./shouldRefreshClientSearchList";
import { staff, staffBasic } from "./staff";
import { staffAdvanceReducer } from "./staffAdvance";
import { positions } from "./positions";
import { services, servicesMap } from "./services";
import { shifts } from "./shifts";
import { customers, customerPutError } from "./customers";
import { appointmentFeedbackReducer } from "./appointmentFeedback";
import { products, productsMap, productActiveToggle, productDataSearch } from "./products";
import { companies } from "./companies";
import { sellersReducer } from "./sellers";
import { inventoryDetailsReducer } from "./inventoryDetails";
import { branch, getBranchLoyaltyPoint } from "./branch";
import { client } from "./client";
import { uploadDataFileReducer } from "./uploadDataFile";
import { campaign, campaignBasic } from "./campaign";
import { appointmentAnalytics } from "./appointmentAnalytics";
import { serviceAnalytics } from "./serviceAnalytics";
import { staffAnalytics } from "./staffAnalytics";
import { paymentsAnalytics } from "./paymentAnalytics";
import { staffRevenueAnalytics } from "./staffRevenueAnalytics";
import { staffServiceAnalytics } from "./staffServiceAnalytics";
import { appointmentOutdoorAnaltyics } from "./appointmentOutdoorAnalytics";
import { otp } from "./otp";
import { register } from "./register";
import { promotion } from "./promotion";
import { bulkSmsPromotion } from "./bulkPromotion";
import { bulkPromotionView } from "./bulkPromotionView";
import { payments } from "./payments";
import { getPayments } from "./getPayments";
import { subscriptions } from "./subscriptions";
import { branchSubscriptions } from "./branchSubscriptions";
import { branchSubscriptionsServices } from "./branchSubscriptionsServices";
import { subscriptionDiscount } from "./subscriptionDiscount";
import { branchWallet } from "./branchWallet";
import { expenseCategories } from "./expenseCategories";
import { customerWallet, customerWalletInvoice } from "./customerWallet";
import { memberships, membershipsBasic, membershipUpdate } from "./memberships";
import { membershipDataByIdReducer } from "./membershipDataById";
import { campaignDataByIdReducer } from "./campaignById";
import { customerNotes } from "./customerNotes";
import { invoiceRemarks } from "./invoiceremarks";
import { reviewLink } from "./reviewLink";
import { branchShortName } from "./branchShortName";
import { commission } from "./commission";
import { staffCommission } from "./staffCommissions";
import { staffBracket } from "./staffBracket";
import { notifications } from "./notifications";
import { ProductDetailReducer, productListReducer } from "./marketPlace";
import { pendings } from "./pendings";
import { singularAppointment } from "./singularAppointment";
import {
  CartDetailReducer,
  DeteteCartItemReducer,
  updateCartProductQuantityReducer,
  addCartItemReducer,
} from "./marketplaceCart";
import { PlaceOrderReducer } from "./PlaceOrder";
import { orderListReducer } from "./marketplaceOrders";
import { marketPlaceDueOrders } from "./marketpalceOrderPayments";
import { marketPlaceOrderTransaction } from "./marketpalceOrderTransactions";
import { addAppointmentPaymentMethod, appointmentPaymentMethod } from "./appointmentPayment";
import { staffServiceRevenueReducer, staffProductRevenueReducer, staffMembershipReducer } from "./staffRevenue";

import { staffAttendance } from "./staffAttendance";
import { staffAbsents } from "./staffAbsents";
import { staffOvertime } from "./staffOvertime";
import { staffSalary } from "./staffSalary";
import { clientMemberships } from "./clientMembership";
import { clientMembershipUsageHistory } from "./clientMembershipUsageHistory";
import { membershipsRevenue } from "./membershipRevenueAnalytics";
import { location } from "./location";

import { productUsage, productUsageModal } from "./productUsage";

import { addressForm } from "./address";

import { partnerType } from "./partnerType";

import { customerLabels } from "./customerLabels";
import { clientLabels, serviceLabels, productLabels } from "./labels";
import { selectedCustomerAppointmentFormData } from "./selectedCustomerAppointmentform";
import { appointmentAnswers } from "./getAppointmentAnswers";
import { addAppointmentDirectDiscount, getAppointmentDirectDiscount } from "./appointmentDirectDiscount";
import { freeTextSearch } from "./freeTextSearch";
import { invoiceFilters } from "./invoices";
import { aclUserPermissionsReducer } from "./aclUserPermissions";
import { aclGroupPermissionsReducer } from "./aclGroupPermissions";
import { aclAllGroupsPermissionsReducer } from "./aclGroupPermissions";
import { aclBasePermissionsReducer } from "./aclPermissionsOptions";
import { aclComponentsPermissionsReducer } from "./aclPermissionsOptions";
import { customerLoyaltyPointBalance, customerLoyaltyPointTransactions } from "./loyaltyPoints";
import { releaseNotes, openReleaseNotes } from "./releaseNotes";
import { festival } from "./festival";
import { isQuickBook } from "./quickbook";
import { analytics } from "./analytics";
import { barcode } from "./barcode";

import { language } from "./language";

export default combineReducers({
  withLoader,
  staffBracket,
  staffAttendance,
  staffAbsents,
  authentication,
  authenticationHasErrored,
  authenticationIsLoading,
  staffOvertime,
  staffAdvance: staffAdvanceReducer,

  otp,
  register,
  promotion,
  bulkSmsPromotion,
  bulkPromotionView,

  branchWallet,
  customerWallet,
  expenseCategories,

  memberships,
  membershipsBasic,
  membershipUpdate,
  membershipDataById: membershipDataByIdReducer,

  shouldRefreshClientSearchList,

  payments,
  getPayments,
  subscriptions,
  branchSubscriptions,
  branchSubscriptionsServices,

  subscriptionDiscount,

  user,
  userHasErrored,
  userIsLoading,

  aclUserPermissions: aclUserPermissionsReducer,
  aclGroupPermissions: aclGroupPermissionsReducer,
  aclAllGroupsPermissions: aclAllGroupsPermissionsReducer,
  aclBasePermissionsOptions: aclBasePermissionsReducer,
  aclComponentsPermissionsOptions: aclComponentsPermissionsReducer,

  appointments,
  basicAppointments,
  appointmentInvoice,
  staff,
  staffBasic,
  positions,
  shifts,
  services,
  servicesMap,
  reviewLink,
  branchShortName,
  appointmentFeedback: appointmentFeedbackReducer,

  uploadDataFile: uploadDataFileReducer,

  customers,
  customerNotes,
  customerPutError,
  invoiceRemarks,

  products,
  productsMap,
  companies,
  branch,
  client,
  clientMemberships,
  clientMembershipUsageHistory,
  campaign,
  campaignDataById: campaignDataByIdReducer,
  campaignBasic,
  sellers: sellersReducer,
  inventoryDetails: inventoryDetailsReducer,

  appointmentAnalytics,
  serviceAnalytics,
  staffAnalytics,
  appointmentOutdoorAnaltyics,
  paymentsAnalytics,
  staffRevenueAnalytics,
  staffServiceAnalytics,
  membershipsRevenue,

  productList: productListReducer,
  cart: CartDetailReducer,
  updateCartItem: updateCartProductQuantityReducer,
  deleteCartItem: DeteteCartItemReducer,
  productDetail: ProductDetailReducer,
  addCartItem: addCartItemReducer,
  placeOrder: PlaceOrderReducer,

  orderList: orderListReducer,

  marketPlaceDueOrders,
  marketPlaceOrderTransaction,
  addAppointmentPaymentMethod,
  appointmentPaymentMethod,
  commission,
  staffCommission,

  staffServicesRevenue: staffServiceRevenueReducer,
  staffProductRevenue: staffProductRevenueReducer,
  staffMembershipRevenue: staffMembershipReducer,

  staffSalary,
  pendings,
  singularAppointment,

  notifications,
  location,
  productUsage,
  addressForm,

  partnerType,

  customerLabels,
  clientLabels,
  serviceLabels,
  productLabels,

  selectedCustomerAppointmentFormData,
  appointmentAnswers,
  productUsageModal,
  addAppointmentDirectDiscount,
  getAppointmentDirectDiscount,
  freeTextSearch,
  invoiceFilters,
  customerWalletInvoice,
  getBranchLoyaltyPoint,
  customerLoyaltyPointBalance,
  customerLoyaltyPointTransactions,
  releaseNotes,
  openReleaseNotes,
  festival,
  language,
  isQuickBook,
  analytics,
  barcode,
  productActiveToggle,
  productDataSearch,
});
