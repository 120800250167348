import axios from "axios";
import { API_BASE_URL } from "../../utilities";
import { authenticationLogout } from "./authentication";

export function getInvoiceFilters() {
  const url = API_BASE_URL + "invoice/filters/all/";
  return (dispatch) => {
    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "INVOICE_FILTERS_SUCCESS",
          data: response.data.results,
        });
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        } else {
          console.log({ error });
          dispatch({
            type: "INVOICE_FILTERS_FAILURE",
          });
        }
      });
  };
}

export async function getWhatsAppLink(data, customer, branch) {
  const url = API_BASE_URL + "invoice/link/" + data.id + "/";
  const total = data?.total;
  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    const mobile_no = customer.country_code.concat(customer.number).replace("+", "");
    window.open(
      `https://wa.me/${mobile_no}?text=Dear Customer, Thanks for visiting ${encodeURIComponent(branch.name)}. ${
        total ? `Your total billed value is ${total}.` : ""
      } Invoice link here ${data.link} . Kindly view within 24hrs, powered by Glamplus.`
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
}
