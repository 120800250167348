import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import "./Actions.scss";
import { Button, Checkbox, Dropdown, Input } from "semantic-ui-react";
import { LabelGroup } from "../LabelGroup";

const SelectedClientList = ({ data, clientFilter, labelFilter, clientLabels }) => {
  let info = null;
  let warn = false;
  let label = null;
  let clients_category_filter = null;
  let message = "";

  const filterName = clientFilter ? clientFilter.text : null;

  if (data?.allClientsSelected) {
    message = "Sending to All Clients";
  } else if (data.excluded_clients) {
    if (!data.excluded_clients?.names.length) {
      message = "Sending to all Clients since no client is excluded";
      warn = true;
    } else {
      let clientNames = "";
      clientNames = data.excluded_clients?.names?.join(", ");
      message = (
        <span>
          <b>Exluded Clients: </b> {clientNames}
        </span>
      );
    }
  } else {
    if (!data.selected_clients?.names.length) {
      message = "Sending to no Clients";
      warn = true;
    } else {
      let clientNames = "";
      clientNames = data.selected_clients?.names?.join(", ");
      message = (
        <span>
          <b>Sending to Clients: </b>
          {clientNames}
        </span>
      );
    }
  }

  info = (
    <p>
      <Translate>{message}</Translate>
    </p>
  );

  if ((data?.allClientsSelected || data?.excluded_clients) && filterName) {
    clients_category_filter = (
      <span>
        <b>Clients Category Filter: </b>
        {filterName}
      </span>
    );
  }

  if ((data?.allClientsSelected || data?.excluded_clients) && labelFilter.length) {
    label = (
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <b>Label Filters:</b>
        <LabelGroup labels={labelFilter} options={clientLabels} addLabel={false} showAddLabel={false} />
      </div>
    );
  }

  return (
    <div className={`client-info ${warn ? "warn" : ""}`}>
      {info}
      {clients_category_filter}
      {label}
    </div>
  );
};

const Recepients = ({
  promotionType,
  isBulk,
  selected,
  clientFilter,
  promotionalMessageFilled,
  labelFilter,
  clientLabels,
}) => {
  const getSMSCount = (message) => {
    const count = Math.ceil(message.length / 160);
    return count;
  };

  return (
    <>
      {!isBulk && (
        <SelectedClientList
          data={selected}
          clientFilter={clientFilter}
          labelFilter={labelFilter}
          clientLabels={clientLabels}
        />
      )}
      {promotionType === "SMS" ? (
        promotionalMessageFilled.length > 1 ? (
          <div className={`client-info`} style={{ marginTop: "8px", gap: "0px" }}>
            <h5>
              <Translate>Following message will be sent ({promotionalMessageFilled.length} characters) :</Translate>
            </h5>
            <p>{promotionalMessageFilled}</p>
            <h5 style={{ color: "red" }}>
              <span>
                <Translate>Note:</Translate>
              </span>
              <br></br>
              <br></br>
              <span>
                <Translate>1 SMS count = 160 Characters of Message.</Translate>
              </span>
              <br></br>
              <span>
                <Translate>Hence, {getSMSCount(promotionalMessageFilled)} SMS count will be deducted.</Translate>
              </span>
            </h5>
          </div>
        ) : (
          <div className="client-info warn" style={{ marginTop: "8px" }}>
            <h5>
              <Translate>Select one of the templates to preview</Translate>
            </h5>
          </div>
        )
      ) : null}
    </>
  );
};

export default function PromotionActions({
  isBulk,
  promotionalMessageFilled = "",
  selected,
  clientFilter,
  labelFilter,
  sendSMSError,
  recurring,
  updateRecurring,
  send,
  promotionType,
}) {
  const bulkSmsPromotion = useSelector((state) => state.bulkSmsPromotion);
  const clientLabels = useSelector((state) => state.clientLabels.data);
  return (
    <div className="promotion-action-container">
      <Recepients
        promotionType={promotionType}
        isBulk={isBulk}
        selected={selected}
        clientFilter={clientFilter}
        promotionalMessageFilled={promotionalMessageFilled}
        labelFilter={labelFilter}
        clientLabels={clientLabels}
      />
      <div className="promotion-info-action flex row">
        <div className="flex row promotion-info-container">
          <div className="recurring-container">
            <Checkbox
              toggle
              label="Recurring"
              onChange={() => {
                const payload = {
                  ...recurring,
                  isActive: !recurring.isActive,
                };
                updateRecurring(payload);
              }}
              checked={recurring.isActive}
            />
            {recurring.isActive && (
              <div className="recurring-container">
                <h4>
                  <Translate>Promotion Types:</Translate>
                </h4>
                <div className="flex row recurring-options">
                  {promotionType === "SMS" && (
                    <Checkbox
                      label="Send Daily"
                      checked={recurring.scheduleType === 0}
                      onChange={() => updateRecurring({ ...recurring, scheduleType: 0 })}
                    />
                  )}
                  <Checkbox
                    label="Send Weekly"
                    checked={recurring.scheduleType === 1}
                    onChange={() => updateRecurring({ ...recurring, scheduleType: 1 })}
                  />
                  <Checkbox
                    label="Send Monthly"
                    checked={recurring.scheduleType === 2}
                    onChange={() => updateRecurring({ ...recurring, scheduleType: 2 })}
                  />
                </div>
                <div className="flex row recurring-fields">
                  <label>
                    <Translate>Enter Time</Translate>
                    <Dropdown
                      style={{ marginBottom: "10px" }}
                      placeholder="Enter Time"
                      clearable
                      selection
                      value={recurring.time}
                      onChange={(_, { value }) => {
                        const payload = {
                          ...recurring,
                          time: value,
                        };
                        updateRecurring(payload);
                      }}
                      options={Array(24)
                        .fill()
                        .map((_, idx) => {
                          if (idx >= 9 && idx < 19) {
                            const number = idx < 9 ? `0${idx + 1}` : `${idx + 1}`;
                            return { text: `${number}:00`, value: number };
                          }
                          return null;
                        })
                        .filter((i) => i)}
                    />
                  </label>
                  <div className="seperator"></div>
                  <label>
                    <Translate> Select Day</Translate>
                    <Dropdown
                      style={{ marginBottom: "10px" }}
                      placeholder="Day"
                      disabled={recurring.scheduleType !== 1}
                      clearable
                      selection
                      value={recurring.day}
                      onChange={(ev, { value }) => {
                        const payload = {
                          ...recurring,
                          day: value,
                          singleDate: null,
                        };
                        updateRecurring(payload);
                      }}
                      options={[
                        {
                          text: "Monday",
                          value: 0,
                        },
                        {
                          text: "Tuesday",
                          value: 1,
                        },
                        {
                          text: "Wednesday",
                          value: 2,
                        },
                        {
                          text: "Thursday",
                          value: 3,
                        },
                        {
                          text: "Friday",
                          value: 4,
                        },
                        {
                          text: "Saturday",
                          value: 5,
                        },
                        {
                          text: "Sunday",
                          value: 6,
                        },
                      ]}
                    />
                  </label>
                  <div className="seperator"></div>
                  <label>
                    <Translate>Select Date</Translate>
                    <Dropdown
                      style={{ marginBottom: "10px" }}
                      placeholder="Date"
                      clearable
                      disabled={recurring.scheduleType !== 2}
                      selection
                      value={recurring.singleDate}
                      onChange={(ev, { value }) => {
                        const payload = {
                          ...recurring,
                          day: "",
                          singleDate: value,
                        };
                        updateRecurring(payload);
                      }}
                      options={Array(27)
                        .fill()
                        .map((_, idx) => ({ text: idx + 1, value: idx + 1 }))}
                    />
                  </label>
                </div>
                <p className="promotional-info info">
                  {recurring.scheduleType === 0 && (
                    <span className="description">
                      <Translate>Only</Translate>
                      <strong>
                        <Translate>Schedule Time</Translate>
                      </strong>
                      <Translate>is required for daily promotion</Translate>
                    </span>
                  )}
                  {recurring.scheduleType === 1 && (
                    <span className="description">
                      <strong>
                        <Translate>Schedule Time</Translate>
                      </strong>
                      &
                      <strong>
                        <Translate>Day</Translate>
                      </strong>
                      <Translate>is required for weekly recurring promotion.</Translate>
                    </span>
                  )}
                  {recurring.scheduleType === 2 && (
                    <span className="description">
                      <Translate>Only </Translate>
                      <strong>
                        <Translate>Schedule Time</Translate>
                      </strong>
                      &
                      <strong>
                        <Translate>Date of month</Translate>
                      </strong>
                      <Translate>is required for monthly recurring promotion.</Translate>
                    </span>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="promotion-action-buttons">
        <Button onClick={send} disabled={bulkSmsPromotion.loading}>
          {bulkSmsPromotion.loading ? <Translate>Sending...</Translate> : <Translate>Send</Translate>}
        </Button>
        {sendSMSError && <span className="error">{sendSMSError}</span>}
        {bulkSmsPromotion.error && <span className="error">{bulkSmsPromotion.error} </span>}
      </div>
      <p className="promotional-info info">
        <span className="description">
          <Translate>Please enter upto 30 characters for each input in SMS Promotions.</Translate>
        </span>
        <span className="description">
          *
          <strong>
            <Translate>Bulk Promotion</Translate>
          </strong>
          <Translate> can send upto 2000 sms promotions at once.</Translate>
        </span>
        <span className="description">
          **
          <strong>
            <Translate>WhatsApp Promotion</Translate>
          </strong>
          <Translate>- can send upto 2000 whatsApp promotions at once.</Translate>
        </span>
        <span className="description">
          **
          <strong>
            <Translate>WhatsApp Promotions</Translate>
          </strong>
          <Translate>are executed within </Translate>
          <strong>
            <Translate>24 working hrs</Translate>
          </strong>
        </span>
      </p>
    </div>
  );
}
