import React from "react";
import "./index.scss";

class Container extends React.Component {
  render() {
    return (
      <div className="container">
        {/*<div className="breadcrumbs">*/}
        {/*  You are here > Staff Member*/}
        {/*</div>*/}
        <div className="children">{this.props.children}</div>
      </div>
    );
  }
}

export default Container;
