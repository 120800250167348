import { API_BASE_URL, GET_APPOINTMENT_ANSWERS } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";

export function resetAppointmentAnswers() {
  return {
    type: "FETCH_APPOINTMENT_RESET",
    response: {
      data: [],
      isFetching: false,
      error: false,
    },
  };
}

export function getAppointmentAnswers(appointment_id) {
  const urlParam = `?appointment_id=${appointment_id}`;
  return (dispatch) => {
    dispatch({
      type: "FETCH_APPOINTMENT_ANSWERS",
      response: {
        isFetching: true,
      },
    });
    axios
      .get(API_BASE_URL + GET_APPOINTMENT_ANSWERS + urlParam, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "FETCH_APPOINTMENT_ANSWERS",
            response: {
              data: response.data,
              isFetching: false,
              error: false,
            },
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch({
          type: "FETCH_APPOINTMENT_ANSWERS",
          response: {
            data: [],
            isFetching: false,
            error: true,
          },
        });
      });
  };
}
