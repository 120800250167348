import { API_BASE_URL, BRANCH_SUBSCRIPTIONS_API } from "utilities";
import axios from "axios";

export function getBranchSubscriptionHasErrored() {
  return {
    type: "GET_BRANCH_SUBSCRIPTION_HAS_ERRORED",
    error: true,
    loading: false,
    data: [],
  };
}

export function getBranchSubscriptionSuccess(data) {
  return {
    type: "GET_BRANCH_SUBSCRIPTION_SUCCESS",
    data,
  };
}

export function getBranchSubscription(pageNum = 1) {
  return (dispatch) => {
    axios({
      method: "get",
      url: API_BASE_URL + BRANCH_SUBSCRIPTIONS_API + "?&page=" + pageNum,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(getBranchSubscriptionSuccess(response.data));
        } else {
          dispatch(getBranchSubscriptionHasErrored());
        }
      })
      .catch(function (error) {
        dispatch(getBranchSubscriptionHasErrored());
      });
  };
}
