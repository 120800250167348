import { LoadingState } from "./types";

const init = {
  data: [],
  loading: LoadingState.unset,
  error: false,
  isFetching: false,
  modalOpen: false,
};

export const festival = (state = init, action) => {
  switch (action.type) {
    case "FESTIVALS_FETCH_SUCCESS":
      return { ...state, ...action.data };
    case "FESTIVALS_FETCH_ERROR":
      return { ...state, ...action.data };
    case "FESTIVAL_MODAL_OPEN_CLOSE":
      return { ...state, ...action.data };
    default:
      return state;
  }
};
