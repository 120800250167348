import { API_BASE_URL } from "utilities";
import axios from "axios";

export const PlaceOrder = (coupon = {}) => {
  let URL = API_BASE_URL + "marketplace/orders/order/";

  return async (dispatch) => {
    try {
      const res = await axios.post(
        URL,
        { ...coupon },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: "ORDER_PLACED_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_PLACED_FAIL",
        payload: error.response.data.detail || "Something went wrong. Please try again later",
      });
    }
  };
};
