//common functions
export const timeConvert = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? "AM" : "PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const getNumberOfStaffs = (billed_item) => {
  let numberOfStaffs = 0;

  // calculating number of staffs
  if (Array.isArray(billed_item.staffs) && billed_item.staffs.length > 0) {
    numberOfStaffs = billed_item.staffs.length;
  } else if (typeof billed_item.staff === "string") {
    numberOfStaffs = billed_item["staff"].split(",").length;
  }

  numberOfStaffs = numberOfStaffs;

  return numberOfStaffs;
};

export const getTotalRevenueCost = (billed_item) => {
  let totalRevenueCost = billed_item["cost"] * billed_item["quantity"];

  totalRevenueCost = Number(totalRevenueCost.toFixed(2));
  return totalRevenueCost;
};

export const getDirectDiscount = (billed_item) => {
  let directDiscountValue = 0;
  let directDiscountPercentage = 0;

  // if discount data is already in the data, not need to calculate manually
  if (billed_item.discount?.length > 0) {
    const discountSplitted = billed_item.discount.split(" ");

    if (discountSplitted.length === 1) {
      directDiscountValue = Number(discountSplitted[0]);
    } else if (discountSplitted.length === 2) {
      directDiscountValue = Number(discountSplitted[0]);
      directDiscountPercentage = discountSplitted[1].replace(/[{()}%]/g, "");
      directDiscountPercentage = Number(directDiscountPercentage);
    }

    return { directDiscountValue, directDiscountPercentage };
  }

  // for old backend data, we dont have billed_item.discount, so calculating manually
  let { totalCampaignDiscountValue } = getCampaignDiscount(billed_item);
  let { totalMembershipDiscountValue } = getMembershipDiscount(billed_item);

  const totalCost = parseFloat(billed_item.cost) * parseFloat(billed_item.quantity);
  const finalPrice = parseFloat(billed_item.final_price);

  directDiscountValue = parseFloat(
    totalCost - (finalPrice + totalCampaignDiscountValue + totalMembershipDiscountValue)
  );

  directDiscountValue = Number(directDiscountValue.toFixed(2));
  return { directDiscountValue, directDiscountPercentage };
};

export const getMembershipDiscount = (billed_item) => {
  let totalMembershipDiscountValue = 0;
  let totalMembershipDiscountPercentage = 0;

  if (Array.isArray(billed_item.memberships)) {
    billed_item.memberships.forEach((membership) => {
      totalMembershipDiscountValue += Number(membership.discount);
      totalMembershipDiscountPercentage += Number(membership.percentage);
    });
  }

  totalMembershipDiscountValue = Number(totalMembershipDiscountValue.toFixed(2));
  totalMembershipDiscountPercentage = Number(totalMembershipDiscountPercentage.toFixed(2));

  return { totalMembershipDiscountValue, totalMembershipDiscountPercentage };
};

export const getCampaignDiscount = (billed_item) => {
  let totalCampaignDiscountValue = 0;
  let totalCampaignDiscountPercentage = 0;

  if (Array.isArray(billed_item.campaigns)) {
    billed_item.campaigns.forEach((campaign) => {
      totalCampaignDiscountValue += Number(campaign.discount);
      totalCampaignDiscountPercentage += Number(campaign.percentage);
    });
  }

  totalCampaignDiscountValue = Number(totalCampaignDiscountValue.toFixed(2));
  totalCampaignDiscountPercentage = Number(totalCampaignDiscountPercentage.toFixed(2));

  return { totalCampaignDiscountValue, totalCampaignDiscountPercentage };
};

// service revenue functions
export const getRevenuePostDirectDiscount = (billed_item) => {
  const totalRevenueCost = getTotalRevenueCost(billed_item);
  const { directDiscountValue } = getDirectDiscount(billed_item);

  let revenuePostDirectDiscount = totalRevenueCost - directDiscountValue;

  revenuePostDirectDiscount = Number(revenuePostDirectDiscount.toFixed(2));
  return revenuePostDirectDiscount;
};

export const getRevenuePostMembershipDiscount = (billed_item) => {
  const totalRevenueCost = getTotalRevenueCost(billed_item);
  const { directDiscountValue } = getDirectDiscount(billed_item);
  const { totalMembershipDiscountValue } = getMembershipDiscount(billed_item);

  let revenuePostMembershipDiscount = totalRevenueCost - directDiscountValue - totalMembershipDiscountValue;

  revenuePostMembershipDiscount = Number(revenuePostMembershipDiscount.toFixed(2));

  return revenuePostMembershipDiscount;
};

export const getRevenuePostCampaignDiscount = (billed_item) => {
  const totalRevenueCost = getTotalRevenueCost(billed_item);
  const { directDiscountValue } = getDirectDiscount(billed_item);
  const { totalCampaignDiscountValue } = getCampaignDiscount(billed_item);
  const { totalMembershipDiscountValue } = getMembershipDiscount(billed_item);

  let revenuePostCampaignDiscount =
    totalRevenueCost - directDiscountValue - totalMembershipDiscountValue - totalCampaignDiscountValue;

  revenuePostCampaignDiscount = Number(revenuePostCampaignDiscount.toFixed(2));
  return revenuePostCampaignDiscount;
};

// for staff service revenue functions
export const calculateServiceRevenueCost = (billed_item, thisStaff) => {
  let total_staff_revenue = 0;
  const totalRevenueCost = getTotalRevenueCost(billed_item);
  const numberOfStaffs = getNumberOfStaffs(billed_item);

  if (thisStaff[0]?.revenue_percentage) {
    const totalStaffRevenuePercentage = thisStaff.reduce((total, staff) => {
      return total + Number(staff.revenue_percentage);
    }, 0);

    total_staff_revenue = (totalRevenueCost * totalStaffRevenuePercentage) / 100;
  } else {
    total_staff_revenue = totalRevenueCost / numberOfStaffs;
  }

  total_staff_revenue = Number(total_staff_revenue.toFixed(2));

  return total_staff_revenue;
};

export const calculateRevenuePostDirectDiscount = (billed_item, thisStaff) => {
  const numberOfStaffs = getNumberOfStaffs(billed_item);
  const { directDiscountValue, directDiscountPercentage } = getDirectDiscount(billed_item);
  const revenuePostDirectDiscount = getRevenuePostDirectDiscount(billed_item); //direct discoutn on total cost

  let revenue_post_direct_discount = 0;
  let staff_direct_discount = "";

  if (directDiscountPercentage) {
    staff_direct_discount = `${directDiscountPercentage}%`;
  }

  if (thisStaff[0]?.revenue_percentage) {
    const totalStaffRevenuePercentage = thisStaff.reduce((total, staff) => {
      return total + Number(staff.revenue_percentage);
    }, 0);

    if (!directDiscountPercentage) {
      staff_direct_discount = (directDiscountValue * totalStaffRevenuePercentage) / 100;
      staff_direct_discount = `${staff_direct_discount}/-`;
    }
    revenue_post_direct_discount = (revenuePostDirectDiscount * totalStaffRevenuePercentage) / 100;
  } else {
    if (!directDiscountPercentage) {
      staff_direct_discount = directDiscountValue / numberOfStaffs;
      staff_direct_discount = `${staff_direct_discount}/-`;
    }
    revenue_post_direct_discount = revenuePostDirectDiscount / numberOfStaffs;
  }

  revenue_post_direct_discount = Number(revenue_post_direct_discount.toFixed(2));

  return [revenue_post_direct_discount, staff_direct_discount];
};

export const calculateRevenuePostMembershipDiscount = (billed_item, thisStaff) => {
  const numberOfStaffs = getNumberOfStaffs(billed_item);
  const { totalMembershipDiscountValue, totalMembershipDiscountPercentage } = getMembershipDiscount(billed_item);
  const revenuePostMembershipDiscount = getRevenuePostMembershipDiscount(billed_item);

  let revenue_post_membership_discount = 0;
  let staff_membership_discount = "";

  if (totalMembershipDiscountPercentage) {
    staff_membership_discount = `${totalMembershipDiscountPercentage}%`;
  }

  if (thisStaff[0]?.revenue_percentage) {
    const totalStaffRevenuePercentage = thisStaff.reduce((total, staff) => {
      return total + Number(staff.revenue_percentage);
    }, 0);

    if (!totalMembershipDiscountPercentage) {
      staff_membership_discount = (totalMembershipDiscountValue * totalStaffRevenuePercentage) / 100;
      staff_membership_discount = `${staff_membership_discount}/-`;
    }
    revenue_post_membership_discount = (revenuePostMembershipDiscount * totalStaffRevenuePercentage) / 100;
  } else {
    if (!totalMembershipDiscountPercentage) {
      staff_membership_discount = totalMembershipDiscountValue / numberOfStaffs;
      staff_membership_discount = `${staff_membership_discount}/-`;
    }
    revenue_post_membership_discount = revenuePostMembershipDiscount / numberOfStaffs;
  }

  revenue_post_membership_discount = Number(revenue_post_membership_discount.toFixed(2));

  return [revenue_post_membership_discount, staff_membership_discount];
};

export const calculateRevenuePostCampaign = (billed_item, thisStaff) => {
  const numberOfStaffs = getNumberOfStaffs(billed_item);
  const { totalCampaignDiscountValue, totalCampaignDiscountPercentage } = getCampaignDiscount(billed_item);
  const revenuePostCampaignDiscount = getRevenuePostCampaignDiscount(billed_item);

  let revenue_post_campaign = 0;
  let staff_campaign_discount = "";

  if (totalCampaignDiscountPercentage) {
    staff_campaign_discount = `${totalCampaignDiscountPercentage}%`;
  }

  if (thisStaff[0]?.revenue_percentage) {
    const totalStaffRevenuePercentage = thisStaff.reduce((total, staff) => {
      return total + Number(staff.revenue_percentage);
    }, 0);

    if (!totalCampaignDiscountPercentage) {
      staff_campaign_discount = (totalCampaignDiscountValue * totalStaffRevenuePercentage) / 100;
      staff_campaign_discount = `${staff_campaign_discount}/-`;
    }
    revenue_post_campaign = (revenuePostCampaignDiscount * totalStaffRevenuePercentage) / 100;
  } else {
    if (!totalCampaignDiscountPercentage) {
      staff_campaign_discount = totalCampaignDiscountValue / numberOfStaffs;
      staff_campaign_discount = `${staff_campaign_discount}/-`;
    }
    revenue_post_campaign = revenuePostCampaignDiscount / numberOfStaffs;
  }

  revenue_post_campaign = Number(revenue_post_campaign.toFixed(2));

  return [revenue_post_campaign, staff_campaign_discount];
};

// product revenue functions
export const calculateProductRevenueCost = (billed_item) => {
  let cost = 0;
  const numberOfStaffs = getNumberOfStaffs(billed_item);
  const totalCost = getTotalRevenueCost(billed_item);

  cost = totalCost / numberOfStaffs;
  cost = Number(cost.toFixed(2));

  return cost;
};

export const calculateProductRevenuePostDirectDiscount = (billed_item) => {
  const revenueCost = calculateProductRevenueCost(billed_item);
  const { directDiscountValue, directDiscountPercentage } = getDirectDiscount(billed_item);

  let staff_direct_discount = "";

  if (directDiscountPercentage) {
    staff_direct_discount = `${directDiscountPercentage}%`;
  } else if (directDiscountValue) {
    staff_direct_discount = `${directDiscountValue}/-`;
  }

  let revenue_post_direct_discount = revenueCost - directDiscountValue;
  revenue_post_direct_discount = Number(revenue_post_direct_discount.toFixed(2));

  return [revenue_post_direct_discount, staff_direct_discount];
};

export const calculateProductRevenuePostMembershipDiscount = (billed_item) => {
  const revenueCost = calculateProductRevenueCost(billed_item);
  const { directDiscountValue } = getDirectDiscount(billed_item);
  const { totalMembershipDiscountValue, totalMembershipDiscountPercentage } = getMembershipDiscount(billed_item);

  let revenue_post_membership_discount = 0;
  let staff_membership_discount = "";

  if (totalMembershipDiscountPercentage) {
    staff_membership_discount = `${totalMembershipDiscountPercentage}%`;
  } else if (totalMembershipDiscountValue) {
    staff_membership_discount = `${totalMembershipDiscountValue}/-`;
  }

  if (totalMembershipDiscountValue) {
    revenue_post_membership_discount = revenueCost - directDiscountValue - totalMembershipDiscountValue;
  } else {
    revenue_post_membership_discount = revenueCost - directDiscountValue;
  }

  revenue_post_membership_discount = Number(revenue_post_membership_discount.toFixed(2));

  return [revenue_post_membership_discount, staff_membership_discount];
};

export const calculateProductRevenuePostCampaign = (billed_item) => {
  const revenueCost = calculateProductRevenueCost(billed_item);
  const { directDiscountValue } = getDirectDiscount(billed_item);
  const { totalMembershipDiscountValue } = getMembershipDiscount(billed_item);
  const { totalCampaignDiscountValue, totalCampaignDiscountPercentage } = getCampaignDiscount(billed_item);

  let revenue_post_campaign = 0;
  let staff_campaign_discount = "";

  if (totalCampaignDiscountPercentage) {
    staff_campaign_discount = `${totalCampaignDiscountPercentage}%`;
  } else if (totalCampaignDiscountValue) {
    staff_campaign_discount = `${totalCampaignDiscountValue}/-`;
  }

  if (totalCampaignDiscountValue) {
    revenue_post_campaign =
      revenueCost - directDiscountValue - totalMembershipDiscountValue - totalCampaignDiscountValue;
  } else {
    revenue_post_campaign = revenueCost - directDiscountValue - totalMembershipDiscountValue;
  }

  revenue_post_campaign = Number(revenue_post_campaign.toFixed(2));

  return [revenue_post_campaign, staff_campaign_discount];
};
