const initialState = {
  data: {
    base_permissions: [],
    component_permissions: [],
    formatted_component_permissions: [],
  },
  isFetching: false,
};

export function aclUserPermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ACL_FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "GET_ACL_SUCCESS":
      return {
        ...state,
        data: {
          ...action.data,
          formatted_component_permissions: action.data.component_permissions.map(
            (permission) => permission.component_name
          ),
        },
        isFetching: false,
      };

    case "GET_ACL_FAILURE":
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
