import moment from "moment";
import ToastAlert from "./toastAlert/toastAlert";

export function validateEmail(email) {
  var regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(String(email).toLowerCase());
}

// validate 10 digit phone number
export function validatePhone(phone) {
  // Check that the phone number is a valid phone number
  const phone_regex = /^[0-9]{10}$/;
  const isNumberValid = phone_regex.test(phone);

  return isNumberValid;
}

export function calcDateDifference(startDate, endDate) {
  startDate = moment(startDate);
  endDate = moment(endDate);
  return endDate.diff(startDate, "days");
}

export const hexToRgba = (hex, opacity = 0.2) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + opacity + ")";
  }
  throw new Error("Bad Hex");
};

export function IsJsonString(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

export function GetStartDate(givenDate = false) {
  let startDate;
  if (givenDate) {
    startDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
  } else {
    let date = new Date();
    startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  }

  return moment(startDate).format("YYYY-MM-DD");
}

export function GetEndDate(givenDate = false) {
  let endDate;
  if (givenDate) {
    endDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);
  } else {
    let date = new Date();
    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  return moment(endDate).format("YYYY-MM-DD");
}

export const promotionalMessageString = (message, values) => {
  return message
    .split(" ")
    .map((x) => {
      if (x.startsWith("{")) {
        const index = parseInt(x.split(",")[1]);
        return values[index];
      }
      return x;
    })
    .join(" ");
};

export const formatTimeIntoMin = (time, timeUnit) => {
  time = Number(time);
  const isInteger = Number.isInteger(time);

  if (time <= 0 || !isInteger) {
    return { error: true, message: "Not Valid Value" };
  }

  if (timeUnit === "SEC") {
    if (time < 60 || time % 60 !== 0) {
      return { error: true, message: "Seconds value must be multiple of 60" };
    }
    return { error: false, res: parseInt(time / 60) };
  } else if (timeUnit === "HR") {
    if (time > 24) {
      return { error: true, message: "Invalid duration value." };
    }
    return { error: false, res: time * 60 };
  } else {
    return { error: false, res: time };
  }
};

export const formatTimeByUnit = (time = 0, time_unit) => {
  time = Number(time);
  //durationValue will always come in MIN format
  if (time_unit === "SEC") {
    return 60 * time;
  }
  if (time_unit === "HR") {
    return parseInt(time / 60);
  } else {
    return time;
  }
};

export const formatTimeIntoDays = (value, unit) => {
  if (!value) {
    return value;
  }

  const month = 30; //days
  const year = 30 * 12;

  if (unit === "MONTH") {
    return value * month;
  }
  if (unit === "YEAR") {
    return value * year;
  }

  // default days
  return value;
};

export const formatDaysByUnit = (days, unit) => {
  if (!days) {
    return days;
  }

  const month = 30; //days
  const year = 30 * 12;

  if (unit === "MONTH") {
    return parseInt(days / month);
  }
  if (unit === "YEAR") {
    return parseInt(days / year);
  }

  // default days
  return days;
};

export const combineTimeAndUnit = (time, unit) => {
  return `${time} ${unit}`;
};

export const combineDayAndUnit = (day, unit) => {
  let res = `${day} ${unit}`;
  res += day > 1 ? "S" : "";

  return res;
};

export const isValidTime = (time) => {
  let [h, m, s] = time.split(":");

  if (!h || !m || !s) {
    return false;
  }

  if (h.length > 2 || m.length > 2 || s.length > 2) {
    return false;
  }

  h = parseInt(h);
  m = parseInt(m);
  s = parseInt(s);

  if (h > 24 || h < 0 || m > 60 || m < 0 || s > 60 || s < 0) {
    return false;
  }

  return true;
};

export const validatePercentage = (percentage) => {
  return percentage < 0 || percentage > 100;
};

export const toFixed = (n, fixed = 2) => `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))[0];

export const smsSendingStatus = (input) => {
  if (typeof input === "boolean" && !input)
    ToastAlert({ message: "SMS limit exceeded, please contact support", type: "error" });
};

export const generatePDFByData = (pdfData, filename = "file") => {
  try {
    const isValidInput = typeof pdfData === "object" && pdfData instanceof ArrayBuffer;

    if (!isValidInput) {
      console.log("Not a valid data to perform blob to pdf operation");
      return false;
    }

    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    // Create a URL for the PDF blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create an anchor element and click it programmatically to download the PDF
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = `${filename}.pdf`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error(error);
  }
};

export const maskNumber = (phone) => {
  try {
    if (typeof phone !== "number" && typeof phone !== "string") {
      return phone;
    }

    phone = phone.toString();
    const numberArr = phone.split("");

    const n = numberArr.length;
    let start = 3; //leave first 3 digits
    let end = n - 3; //leave last 3 digits

    while (start < end) {
      numberArr[start] = "X";
      start += 1;
    }
    const maskedNumber = numberArr.join("");

    return maskedNumber;
  } catch (err) {
    console.error(err);
  }
};

// when we can not use useNumberMask hook in class component then use this function
export const handleMaskNumber = (number, aclUserPermissions) => {
  const shouldMaskNumber = !aclUserPermissions.includes("view_masked_client_number");

  if (shouldMaskNumber) {
    return maskNumber(number);
  }

  return number;
};
