import React from "react";
import "./index.scss";

function Description({ title, description, img, add_on, bold, className, status, isHtml = false, style }) {
  const isDeleted = status === 0 || !status;

  return (
    <div className="description-main flex center">
      {img && (
        <div className="flex">
          <img height={35} width={35} src={img} alt="" />
        </div>
      )}
      <div className="flex column">
        <span className={`title capitalize ${className ? className : ""} ${bold ? "bold" : ""}`}
        style={style}
        >
          {title}
          {add_on && isHtml ? <div dangerouslySetInnerHTML={{ __html: add_on }} /> : add_on && <span> ({add_on})</span>}
        </span>
        <span className="description" style={{ color: isDeleted ? "#7a7c7d" : "" }}>
          {description}
        </span>
      </div>
    </div>
  );
}

export default Description;
