import { API_BASE_URL, CLIENT_MEMBERSHIP_USAGE_API } from "utilities";
import axios from "axios";

export function clientMembershipUsageHistory(client_membership_id) {
  return (dispatch) => {
    dispatch({
      type: "CLIENT_MEMBERSHIP_USAGE_LOADING",
    });
    axios({
      method: "get",
      url: API_BASE_URL + CLIENT_MEMBERSHIP_USAGE_API + "?client_membership_id=" + client_membership_id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "CLIENT_MEMBERSHIP_USAGE_SUCCESS",
            data: response.data.results,
          });
        } else {
          dispatch({
            type: "CLIENT_MEMBERSHIP_USAGE_ERROR",
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: "CLIENT_MEMBERSHIP_USAGE_ERROR",
        });
      });
  };
}
