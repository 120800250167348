import moment from "moment";
function dayOfWeekAsString(dayIndex) {
  return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][dayIndex] || "";
}

function dayOfWeekAsStringShort(dayIndex) {
  return ["Mon", "Tues", "Wed", "Thur", "Fri", "Sat", "Sun"][dayIndex] || "";
}

/**
 * returns datetime in a readable format
 * @param {string} datetimestring contains timezone
 * @param onlyDate
 * @param onlyTime
 * @returns {string} datetime in DD-MM-YYYY HH:MM:SS
 */
const getReadableDateTime = (datetimestring, onlyDate = false, onlyTime = false) => {
  const newDate = new Date(datetimestring);
  const _date = [newDate.getDate(), newDate.getMonth() + 1, newDate.getFullYear()].join("-");
  const _time = [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()].join(":");
  if (onlyDate) {
    return _date;
  }
  if (onlyTime) {
    return _time;
  }
  return [_date, _time].join(" ");
};

const serverDateTime = (date) => {
  const dateObj = typeof date === "string" ? new Date(date) : date;
  return dateObj.toISOString().split("T").join(" ");
};

const serverDate = (date) => {
  const dateObj = typeof date === "string" ? new Date(date) : date;
  return dateObj.toISOString().split("T")[0];
};

const convertUtcToLocal = (date, format = "YYYY-MM-DD hh:mm A") => {
  const _utc = moment.utc(date);
  const _stillUtc = moment(_utc).toDate();

  return moment(_stillUtc).local().format(format);
};

const getUTCTime = (time) => {
  const MINUTES = 60;
  return moment()
    .startOf("day")
    .add(time * MINUTES, "m")
    .utc()
    .format("HH:mm:ss");
};

const getLocalTime = (time) => {
  // moment.utc("2022-04-08 05:30:00").local().format("HH:mm:ss)
  const date = moment().format("YYYY-MM-DD");
  const dateTime = `${date} ${time}`;
  return moment.utc(dateTime).local().format("hh:mm A");
};

export {
  dayOfWeekAsString,
  dayOfWeekAsStringShort,
  getReadableDateTime,
  serverDateTime,
  serverDate,
  convertUtcToLocal,
  getUTCTime,
  getLocalTime,
};
