import { API_BASE_URL, STAFF_SERVICE_ANALYTICS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

export function staffServiceAnalyticsFetchDataSuccess(staffServiceAnalytics) {
  return {
    type: "STAFF_SERVICE_ANALYTICS_FETCH_DATA_SUCCESS",
    staffServiceAnalytics,
  };
}

export function staffServiceAnalyticsFetchData(
  start_date = new Date().toISOString().split("T")[0],
  end_date = "",
  branch = ""
) {
  const urlParam = `?start_date=${start_date}&end_date=${end_date}`;
  return (dispatch) => {
    dispatch(startLoading("STAFF_Service_ANALYTICS_LOADER", "loading Service analytics"));
    axios
      .get(API_BASE_URL + STAFF_SERVICE_ANALYTICS_API + urlParam, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffServiceAnalyticsFetchDataSuccess(response.data.results));
          dispatch(endLoading("STAFF_SERVICE_ANALYTICS_LOADER"));
        } else {
          dispatch(errored("STAFF_SERVICE_ANALYTICS_LOADER", true));
          dispatch(endLoading("STAFF_SERVICE_ANALYTICS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_SERVICE_ANALYTICS_LOADER", true));
        dispatch(endLoading("STAFF_SERVICE_ANALYTICS_LOADER"));
      });
  };
}
