import React from "react";
import "./index.scss";
import pageDeniedSVG from "../../images/pageDenied.svg";

import { NavLink } from "react-router-dom";

function PageDenied() {
  return (
    <div className="page_denied_style">
      <img src={pageDeniedSVG} alt="page is denied" />
      <div className="page_denied_container">
        <div className="page_denied_text">The page you are trying to access has restricted access</div>
        <div className="page_denied_text">Please refer to your system administrator</div>
      </div>
      <NavLink exact to="/">
        <button className="page_denied_button">Go Back</button>
      </NavLink>
    </div>
  );
}

export default PageDenied;
