import React, { useState } from "react";
import "./filterSearchBar.scss";
import { Icon } from "semantic-ui-react";

function FilterSearchBar({ updateFilterSearchValue, changeView }) {
  const [value, setValue] = useState("");

  const handleChange = (value) => {
    setValue(value);
    updateFilterSearchValue(value.trim());
  };

  return (
    <div className="search-container">
      <input
        placeholder="Search..."
        value={value}
        name="filter-search"
        className="filter-search"
        type="text"
        onChange={(e) => handleChange(e.target.value)}
        title="Search by name or number"
        onFocus={changeView}
      />
      {!value ? (
        <Icon name="search" className="search-input-icon" style={{ fontSize: "18px" }} />
      ) : (
        <div onClick={() => handleChange("")} title="clear">
          <Icon name="cancel" className="search-input-icon" style={{ fontSize: "18px" }} />
        </div>
      )}
    </div>
  );
}

export default FilterSearchBar;
