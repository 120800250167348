import { LoadingState } from "../reducers/types";

const initData = {
  data: {},
  loading: LoadingState.unset,
  read: true,
};

export function releaseNotes(state = initData, action) {
  switch (action.type) {
    case "RELEASE_NOTES_FETCH_LOADING":
      return { ...state, ...action.data };
    case "RELEASE_NOTES_FETCH_SUCCESS":
      return { ...state, ...action.data };
    case "RELEASE_NOTES_FETCH_ERROR":
      return { ...state, ...action.data };
    case "READ_RELEASE_NOTES":
      return { ...state, ...action.data };
    default:
      return state;
  }
}

const initOpenReleaseNotes = {
  open: false,
};

export function openReleaseNotes(state = initOpenReleaseNotes, action) {
  switch (action.type) {
    case "OPEN_RELEASE_NOTES":
      return { ...state, ...action.data };
    case "CLOSE_RELEASE_NOTES":
      return { ...state, ...action.data };
    default:
      return state;
  }
}
