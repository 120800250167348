import React, { useState, useEffect } from "react";
import "./index.scss";
import { BsCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { authenticationLogin } from "../../store/actions/authentication";
import { sendOtp } from "../../store/actions/otp";
import { resetRegister } from "../../store/actions/register";

import { useParams } from "react-router-dom";
import Modal from "../../components/Modal";

import { Button, Input, Icon, Dropdown, Checkbox } from "semantic-ui-react";
import RegisterForm from "./RegisterForm";
import { getCountries, getStates, getCities } from "../../store/actions/address";
import { COUNTRY_DIAL_INFO } from "../../utilities/constants";
import Fuse from "fuse.js";
import { Translate } from "react-auto-translate";
import SelectLanguage from "../Header/SelectLanguage";
import loginImage from "../../cdn/Group 5062.png";
import loginImageTop from "../../cdn/Group.png";

function Login() {
  const dispatch = useDispatch();
  const partnerType = useSelector((state) => state.partnerType);
  const otpAPISent = useSelector((state) => state.otp);
  const loginError = useSelector((state) => state.authenticationHasErrored);
  const registerStatus = useSelector((state) => state.register);

  const [showLoginPass, setShowLoginPass] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);

  const [state, setState] = useState({
    username: "",
    password: "",
    otp: "",
  });
  const [registerForm, setRegisterForm] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [register, setRegister] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [loginWithMobile, setLoginWithMobile] = useState(true);
  let { reset /*username, token*/ } = useParams();

  const fuseOptions = {
    keys: ["value", "description"],
    threshold: 0.1,
  };

  const countryOptions = COUNTRY_DIAL_INFO.map(({ dial_code, flag, code, name }) => {
    return {
      key: code,
      text: `${dial_code} ${flag}`,
      value: dial_code,
      description: name,
    };
  });

  const countryCodeSearch = (options, query) => {
    const fuse = new Fuse(options, fuseOptions);
    return fuse.search(query).map(({ item }) => item);
  };

  if (reset !== "reset") {
    reset = false;
  }

  const handleChange = (e) => {
    let { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
    // dispatch(getCities(1));
  }, []);

  useEffect(() => {
    if (registerForm?.state) {
      dispatch(getCities(registerForm?.state));
    }
  }, [registerForm.state]);

  const loginUser = () => {
    setOtpError(false);
    dispatch(authenticationLogin(state));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginUser();
    }
  };

  var countryCodeDropdownProps = {
    ...(loginWithMobile && {
      label: <Dropdown disabled defaultValue="+91" search={countryCodeSearch} options={countryOptions} />,
    }),
  };

  return (
    <div className={"login " + partnerType}>
      <div className="loginMain">
        <div className="loginImage">
          <div className="loginTop">
            <img src={loginImageTop} alt="login Image top" />
          </div>
          <div className="lockImage">
            <img src={loginImage} alt="login page" />
          </div>
        </div>

        <div className="login-form">
          <div className="form-container">
            <div>
              <div className="logo-container flex row">
                {/* <BsCircle size="15" title="Edit" color={"#5F8FB6"} /> */}
                <div className="logo" />
                {/* <BsCircle size="15" title="Edit" color={"#FE671B"} /> */}
              </div>
              {/* <div className="select-language-container">
                <SelectLanguage />
              </div> */}
              {reset ? (
                <div>
                  <h3>
                    <Translate>Reset your password here</Translate>
                  </h3>
                  <Input
                    id="new_password"
                    className="input"
                    placeholder="New password"
                    value={state.username}
                    onChange={handleChange}
                  />
                  <Input
                    className="input"
                    id="re_new_password"
                    placeholder="Confirm new password"
                    type="password"
                    value={state.password}
                    onChange={handleChange}
                  />
                  <button className="btn-link">
                    <Translate>Login?</Translate>
                  </button>
                  <button onClick={loginUser}>
                    <Translate>Reset</Translate>
                  </button>
                </div>
              ) : (
                <div className="login-form-input">
                  <div className="loginHeading">
                    <h3 >
                      <Translate >Login to your partner account </Translate>
                    </h3>
                    <div className="select-language-container">
                      <SelectLanguage  className="select-lang"/>
                    </div>
                  </div>

                  <h5>
                    <Translate>Use your username/mobile number to sign in</Translate>
                  </h5>
                  {registerStatus.status !== null && registerStatus.status && (
                    <h5>
                      <Translate>Otp sent to your registered contact</Translate>
                    </h5>
                  )}
                  {loginError && (
                    <h5 className="error">
                      <Translate>Please enter valid information for phone number and password</Translate>
                    </h5>
                  )}
                  {(otpError || (forgotPassword && !otpAPISent)) && (
                    <h5 className="error">
                      <Translate>
                        {otpError || !otpAPISent
                          ? "Your mobile number is not registered with us, please contact support@glamplus.in"
                          : "Invalid username and/or password"}
                      </Translate>
                    </h5>
                  )}
                  <div className="mobile-number-checkbox">
                    <Checkbox
                      checked={loginWithMobile}
                      slider
                      className="input"
                      label="Mobile Number"
                      onChange={(e, data) => {
                        setLoginWithMobile(data.checked);
                      }}
                    />
                  </div>
                  <label>{loginWithMobile ? "Phone Number" : "Username"}</label>
                  <Input
                    {...countryCodeDropdownProps}
                    className="input"
                    id="username"
                    placeholder={loginWithMobile ? "Mobile Number" : "Username"}
                    value={state.username}
                    onChange={handleChange}
                  />
                  <Input
                    className="input"
                    id="password"
                    placeholder="Password"
                    type={showLoginPass ? "text" : "password"}
                    value={state.password}
                    onChange={handleChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    icon={
                      <Icon
                        name={showLoginPass ? "eye" : "eye slash"}
                        link
                        onClick={() => setShowLoginPass((prevState) => !prevState)}
                      />
                    }
                  />
                  <span className="flex row" style={{ justifyContent: "space-between" }}>
                    <button
                      className="btn-link"
                      onClick={() => {
                        setRegister(true);
                      }}
                    >
                      <Translate>New User? Register</Translate>
                    </button>
                    <button
                      className="btn-link"
                      onClick={() => {
                        // setOtpError(true)
                        if (state.username !== "") {
                          setOtpError(false);
                          setForgotPassword(true);

                          const data = {
                            phone: state.username,
                          };

                          dispatch(sendOtp({ data, receiver: "stylist" }));
                        } else {
                          setOtpError(true);
                        }
                      }}
                    >
                      <Translate>Forgot Password?</Translate>
                    </button>
                  </span>
                  <p style={{ marginBottom: "20px" }}>
                    <Translate>
                      Use your recent OTP as password if you have not set up the password in settings.
                    </Translate>
                  </p>
                  <button className="btn-login" onClick={loginUser}>
                    <Translate>Login</Translate>
                  </button>
                </div>
              )}

              {forgotPassword && otpAPISent && (
                <Modal close={() => setForgotPassword(false)}>
                  <Translate>OTP has been sent to your registered mobile number</Translate>
                  <div className="flex column">
                    <Input
                      className="input"
                      id="password"
                      placeholder="OTP"
                      type={showForgotPass ? "text" : "password"}
                      value={state.password}
                      onChange={handleChange}
                      onKeyDown={(e) => handleKeyDown(e)}
                      icon={
                        <Icon
                          name={showForgotPass ? "eye" : "eye slash"}
                          link
                          onClick={() => setShowForgotPass((prevState) => !prevState)}
                        />
                      }
                    />
                    <Button onClick={() => loginUser(true)}>
                      <Translate>Login</Translate>
                    </Button>
                  </div>
                  <div className="otp-modal">
                    <button
                      className="btn-link"
                      onClick={() => {
                        const data = {
                          phone: state.username,
                        };
                        dispatch(sendOtp({ data, receiver: "stylist" }));
                        setResendOTP(true);
                      }}
                    >
                      <Translate>Resend OTP</Translate>
                    </button>
                    <button
                      className="btn-link"
                      onClick={() => {
                        setForgotPassword(false);
                      }}
                    >
                      <Translate>Re Enter Username</Translate>
                    </button>
                  </div>
                  {resendOTP && (
                    <div className="resend-otp">
                      <Translate>OTP was resent Successfully.</Translate>
                    </div>
                  )}
                </Modal>
              )}
              {register && (
                <Modal
                  close={() => {
                    dispatch(resetRegister());
                    setRegister(false);
                  }}
                >
                  <Translate>Please enter your details here:</Translate>
                  <RegisterForm setRegister={setRegister} />
                  <div className="flex row" style={{ justifyContent: "space-between" }}>
                    <div className="flex column">
                      {registerStatus.status !== null && !registerStatus.status && (
                        <span className="error">
                          <Translate>Please enter valid information for the fields</Translate>
                        </span>
                      )}
                      <span className="description">
                        **
                        <Translate>
                          If you run a franchise and want to register multiple accounts, please contact us at
                        </Translate>
                        <a href="mailto:support@glamplus.in"> support@glamplus.in</a>
                      </span>
                      <span className="description">
                        *<Translate>You consent to receive messages and calls from us or our agents</Translate>
                      </span>
                    </div>
                    <div className="flex end">
                      <a
                        style={{ textDecoration: "underline" }}
                        href="https://s3.ap-south-1.amazonaws.com/website.attachment/Terms_and_Conditions.pdf"
                        target="_blank"
                      >
                        <Translate>Terms and Conditions</Translate>
                      </a>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">2020 © RDV Design Technologies Pvt. Ltd.</div>
    </div>
  );
}

export default Login;
