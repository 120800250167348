import { LoadingState } from "./types";

const init = {
  data: [],
  loading: LoadingState.unset,
};

export function services(state = init, action) {
  switch (action.type) {
    case "SERVICES_FETCH_DATA_LOADING":
      return { ...state, ...action.data };
    case "SERVICES_FETCH_DATA_SUCCESS":
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function servicesMap(state = {}, action) {
  switch (action.type) {
    case "SERVICES_MAP_CREATION_SUCCESS":
      return action.servicesMap;
    default:
      return state;
  }
}
