import { API_BASE_URL, MARKET_PLACE_API } from "utilities";
import { endLoading, errored, startLoading } from "./withLoader";
import axios from "axios";

export const listProducts = (search, pageNum = 1) => {
  let URL = API_BASE_URL + MARKET_PLACE_API + "?";
  URL = search ? URL + "&search=" + search : URL;
  URL = URL + "&page=" + pageNum;

  return async (dispatch) => {
    dispatch(startLoading("PRODUCTS_LOADER", "Loading Products"));
    dispatch({ type: "PRODUCT_LIST_REQUEST" });
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "PRODUCT_LIST_SUCCESS",
        payload: res.data,
      });
      dispatch(endLoading("PRODUCTS_LOADER"));
    } catch (error) {
      dispatch(errored("PRODUCTS_LOADER", true));
      dispatch(endLoading("PRODUCTS_LOADER"));
    }
  };
};

export const ProductDetail = (id) => {
  let URL = `${API_BASE_URL}marketplace/product/product/detail/${id}/`;

  return async (dispatch) => {
    dispatch(startLoading("PRODUCTS_LOADER", "Loading Product Details"));
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "PRODUCT_DETAIL_SUCCESS",
        payload: res.data,
      });
      dispatch(endLoading("PRODUCTS_LOADER"));
    } catch (error) {
      dispatch(errored("PRODUCTS_LOADER", true));
      dispatch(endLoading("PRODUCTS_LOADER"));
    }
  };
};
