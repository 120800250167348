import { API_BASE_URL, CAMPAIGN_API } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";
import { errorMessage } from "../../utilities/actionMessages";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export const campaignFetchSuccess = (data) => {
  return {
    type: "CAMPAIGN_BY_ID_SUCCESS",
    data,
  };
};

export const campaignFetching = () => {
  return {
    type: "CAMPAIGN_BY_ID_FETCHING",
  };
};

export const campaignFetchFailed = () => {
  return {
    type: "CAMPAIGN_BY_ID_ERROR",
  };
};

export const fetchCampaignDataById = (id) => {
  return (dispatch) => {
    dispatch(campaignFetching());

    axios
      .get(API_BASE_URL + CAMPAIGN_API + id + "/", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(campaignFetchSuccess(response.data));
        }
      })
      .catch((error) => {
        ToastAlert({ message: errorMessage, type: "error" });
        dispatch(campaignFetchFailed());
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
};
