import React from "react";
import "./index.scss";
import { Dropdown } from "semantic-ui-react";

function SearchInput({ placeholder, options, onChange, value }) {
  return (
    <Dropdown
      placeholder={"enter " + placeholder}
      options={options}
      onChange={(event, { searchQuery, value }) => onChange(value)}
      onSearchChange={(event, { searchQuery }) => onChange(searchQuery)}
      value={value}
      searchQuery={value}
      search
      selection
    />
  );
}

export default SearchInput;
