import axios from "axios";
import {
  API_BASE_URL,
  SALARYBRACKETS,
  STAFF_API,
  STAFF_COMMISSION_API,
  STAFF_LEAVES_API,
  STAFF_SALARY_API,
  STAFF_SALARY_BRACKET_API,
} from "../../utilities";
import { errorMessage, staffSalaryActions } from "../../utilities/actionMessages";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { authenticationLogout } from "./authentication";
import { endLoading, errored, startLoading } from "./withLoader";

function success() {
  return {
    type: "STAFF_SALARY_CONFIRM_SUCCESS",
  };
}

function failed(msg) {
  return {
    type: "STAFF_SALARY_CONFIRM_FAILED",
    data: msg,
  };
}

export function confirmStaffSalary(id, payload) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_SALARY_LOADER", "confirming salary"));
    axios({
      method: "POST",
      url: API_BASE_URL + STAFF_API + `${id}/` + STAFF_SALARY_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function (response) {
        ToastAlert({ message: staffSalaryActions.success_update, type: "success" });
        if (response.status === 200 || response.state === 201) {
          dispatch(success());
        } else {
          dispatch(failed("error while confirming salary"));
        }
        dispatch(endLoading("STAFF_SALARY_LOADER"));
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_SALARY_LOADER", true));
        dispatch(endLoading("STAFF_SALARY_LOADER"));
      });
  };
}

export function resetStaffSalaryFlags() {
  return {
    type: "STAFF_SALARY_RESET_FLAG",
  };
}

function fetchStaffSalarySuccess(data) {
  return {
    type: "STAFF_SALARY_FETCH",
    data,
  };
}

export function fetchStaffSalary(id, month = null) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_SALARY_LOADER", "confirming salary"));
    axios({
      method: "get",
      url: API_BASE_URL + STAFF_API + `${id}/` + STAFF_SALARY_BRACKET_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.state === 201) {
          const data = response.data.results.length === 1 ? response.data.results[0] : [];
          dispatch(fetchStaffSalarySuccess(data));
        } else {
          dispatch(failed("error while confirming salary"));
        }
        dispatch(endLoading("STAFF_SALARY_LOADER"));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_SALARY_LOADER", true));
        dispatch(endLoading("STAFF_SALARY_LOADER"));
      });
  };
}

export function resetStaffSalary() {
  return {
    type: "STAFF_SALARY_RESET",
  };
}

function fetchConfirmedSalary(staffId, date, endDate) {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + STAFF_API + `${staffId}/` + SALARYBRACKETS, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          month: date,
        },
      })
      .then((response) => {
        dispatch(fetchCommissionSalary(staffId, date, endDate));
        dispatch(fetchLeaveSalary(staffId, date, endDate));
        dispatch({
          type: "FETCH_STAFF_CONFIRMED_SALARY",
          data: response.data.results,
        });
      })
      .catch((err) => {
        console.error({ err });
      });
  };
}

function fetchCommissionSalary(staffId, startDate, endDate) {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + STAFF_COMMISSION_API + `/${staffId}/?start_date=${startDate}&end_date=${endDate}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch({
          type: "FETCH_STAFF_COMMISSION_SALARY",
          data: response.data,
        });
      })
      .catch((err) => {
        // TODO good to have a notification toaster to inform users of a failed api call
        // console.log({ err });
      });
  };
}

function fetchLeaveSalary(staffId, startDate, endDate) {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + STAFF_LEAVES_API + `${staffId}/?start_date=${startDate}&end_date=${endDate}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch({
          type: "FETCH_STAFF_LEAVE_SALARY",
          data: response.data,
        });
      })
      .catch((err) => {
        // TODO good to have a notification toaster to inform users of a failed api call
        // console.log({ err });
      });
  };
}

export function fetchConfirmedStaffSalary(id, yearMonth) {
  return (dispatch) => {
    const date = `${yearMonth}-01`;
    const _temp = new Date(date);
    // because .`toISOString()` negates one day
    // we add +1 to the getMonth()
    const endDate = new Date(_temp.getFullYear(), _temp.getMonth() + 1).toISOString().slice(0, 10);
    dispatch(fetchConfirmedSalary(id, date, endDate));
  };
}
