import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Input, Icon, Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import { productUsageFetchData, productUsagePostData, productUsageReset } from "../../store/actions/productUsage";

import { useServiceProductUsage } from "../../hooks/useServiceProductUsage";
import { productsOptionsSelector } from "../../store/selectors/productOptionsSelector";

const initProductUsage = {
  required_service_id: "",
  product_id: "",
  consumption_value: "",
};

function ProductUsage({ options = [], form, rows, close }) {
  const dispatch = useDispatch();
  const productUsage = useSelector((state) => state.productUsage);
  const [productUsageState, setProductUsage] = useState([{ ...initProductUsage }]);
  const [defaultProductUsageState, setDefaultProductUsage] = useState([]);
  const [fieldError, setFieldError] = useState(false);

  const {
    isLoading,
    isError,
    productUsage: defaultProductUsages = [],
    getProductUsage: getDefaultProductUsage,
  } = useServiceProductUsage();
  console.log("productUsageState", productUsageState);

  const productsOptions = useSelector(productsOptionsSelector);

  useEffect(() => {
    getDefaultProductUsage(rows.service_id);
  }, []);

  useEffect(() => {
    if (form && form.id && form.required_services?.length > 0 && rows) {
      dispatch(productUsageFetchData(false, false, false, rows.id, false));
    }
  }, []);

  useEffect(() => {
    if (productUsage && productUsage.data.length > 0) {
      setProductUsage([...productUsage.data]);
    } else {
      setProductUsage([{ ...initProductUsage }]);
    }
  }, [productUsage]);

  useEffect(() => {
    if (defaultProductUsages && defaultProductUsages.length > 0) {
      setDefaultProductUsage([...defaultProductUsages]);
    }
  }, [defaultProductUsages])

  const updateClickFunc = () => {
    let productUsagePayload = {
      required_service_id: rows.id,
      consumption: [],
    };

    for (let ele of productUsageState) {
      if (!ele.product_id && !ele.consumption_value) {
        continue;
      } else if (!ele.product_id || !ele.consumption_value) {
        setFieldError(true);
        return;
      } else {
        const consume = {
          product_id: ele.product_id,
          consumption_value: ele.consumption_value,
        };
        productUsagePayload.consumption.push(consume);
      }
    }

    if (productUsageState && productUsageState.length === 1 && productUsageState[0].product_id === "") {
      productUsagePayload = {
        required_service_id: rows.id,
        consumption: [],
      };
    }
    dispatch(productUsagePostData(productUsagePayload));
    dispatch(productUsageReset());
    close();
  };

  const popProductUsageRow = (index) => {
    let rows = [...productUsageState];
    rows.splice(index, 1);
    rows = rows.length ? rows : [{ ...initProductUsage }];
    setProductUsage([...rows]);
  };

  const addProductUsageRow = (index) => {
    const rows = [...productUsageState];
    rows.splice(index + 1, 0, { ...initProductUsage });
    setProductUsage([...rows]);
  };

  // hideDefaultProductUsage if appointment is completed, hidden or deleted
  const hideDefaultProductUsage = [3, 5, 6].includes(form.status);

  return (
    <div className="productusage-container">
      <h3 style={{ margin: "10px 0px 20px" }}>
        <Translate>Product Usage</Translate>
      </h3>

      {/* default product usages */}
      {!hideDefaultProductUsage && (
        <>
          {isLoading && <p className="info">Loading default product uages...</p>}
          {isError && <p className="error">Could not load default product usages</p>}
          {!isLoading && !isError && (
            <div>
              <h4>Default product usages (Edit after completing appointment)</h4>
              {defaultProductUsageState?.map((ele, index) => {
                return (
                  <div key={index} className="form-wrapper">
                    <div style={{ width: "200px" }}>
                      <Input
                        disabled={true}
                        className="consumed"
                        labelPosition="right corner"
                        placeholder="Product Consumption"
                        value={ele.consumption_value || ""}
                        type="number"
                      />
                    </div>
                    <div className="dropdown-style">
                      <Dropdown
                        disabled={true}
                        style={{ width: "300px" }}
                        placeholder={"Select Product Used"}
                        options={productsOptions}
                        value={ele.product_id}
                        search
                        selection
                      />
                    </div>
                    <div className="row-style">
                      <Icon
                        disabled={true}
                        circular
                        inverted
                        color="grey"
                        name="add"
                        onClick={() => addProductUsageRow(index)}
                      />
                      <Icon
                        disabled={true}
                        circular
                        inverted
                        color="grey"
                        name="minus"
                        onClick={() => popProductUsageRow(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}

      {/* Extra product usages */}
      {!hideDefaultProductUsage && <h4 style={{ marginTop: "20px" }}>Add extra/other product usages here</h4>}
      {productUsageState.map((ele, index) => {
        return (
          <div key={index} className="form-wrapper">
            <div style={{ width: "200px" }}>
              <Input
                className="consumed"
                labelPosition="right corner"
                placeholder="Product Consumption"
                onChange={(event, value) => {
                  const data = [...productUsageState];
                  data[index].consumption_value = value.value;
                  setFieldError(false);
                  setProductUsage(data);
                }}
                value={ele.consumption_value || ""}
                type="number"
              />
            </div>
            <div className="dropdown-style">
              <Dropdown
                style={{ width: "300px" }}
                placeholder={"Select Product Used"}
                options={options.products}
                onChange={(event, value) => {
                  const data = [...productUsageState];
                  data[index].product_id = value.value;
                  setFieldError(false);
                  setProductUsage(data);
                }}
                value={ele.product_id}
                search
                selection
              />
            </div>
            <div className="row-style">
              <Icon circular inverted color="grey" name="add" onClick={() => addProductUsageRow(index)} />
              <Icon circular inverted color="grey" name="minus" onClick={() => popProductUsageRow(index)} />
            </div>
          </div>
        );
      })}
      {fieldError && <p style={{ padding: "10px 0px", color: "red" }}>No field should be empty</p>}
      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "50px",
        }}
      >
        <Button
          onClick={() => {
            updateClickFunc();
          }}
        >
          <Translate>Update</Translate>
        </Button>
      </div>
    </div>
  );
}

export default ProductUsage;
