import { LoadingState } from "./types";

const init = {
  data: [],
  loading: LoadingState.unset,
};

export function products(state = init, action) {
  switch (action.type) {
    case "PRODUCTS_FETCH_DATA_SUCCESS":
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function productsMap(state = {}, action) {
  switch (action.type) {
    case "PRODUCTS_MAP_CREATION_SUCCESS":
      return action.productsMap;
    default:
      return state;
  }
}

export function productActiveToggle(state = true, action) {
  switch (action.type) {
    case "PRODUCTS_ACTIVE_TOGGLE_SUCCESS":
      return action.toggle;
    default:
      return state;
  }
}

export function productDataSearch(state = "", action) {
  switch (action.type) {
    case "PRODUCTS_DATA_SEARCH":
      return action.search;
    default:
      return state;
  }
}
