import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { getAppointmentFeedback } from "../../store/actions/appointmentFeedback";
import { Rating } from "semantic-ui-react";

function CustomerFeedback({ id }) {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.appointmentFeedback);
  const { feedback = [], rating_received = 0, rating_requested = 0 } = data?.length > 0 ? data[0] : {};

  useEffect(() => {
    if (id) {
      dispatch(getAppointmentFeedback(id));
    }
  }, [id]);

  return (
    <div className="feedback-container">
      <h3>Appointment Feedback</h3>
      {loading && <div>Appointment feedbacks are loading...</div>}
      <br />
      {!loading && data?.length > 0 && (
        <div>
          <b>
            Total Rating: {rating_received}/{rating_requested}
          </b>
          <hr />
          {/*  */}
          {
            <ol className="feedback-list">
              {feedback.map((feedback, index) => {
                return (
                  <li key={feedback.question_id} className="feedback-item">
                    <div style={{ fontWeight: "bold" }}>{index + 1}.</div>
                    <div className="feedback-item-content">
                      <span>{feedback.question}</span>
                      <span>
                        <Rating
                          defaultRating={parseInt(feedback.rating)}
                          maxRating={5}
                          disabled
                          icon="star"
                          size="large"
                        />
                      </span>
                    </div>
                  </li>
                );
              })}
            </ol>
          }
        </div>
      )}
      {!loading && data?.length === 0 && <div>No feedback found for this appointment</div>}
    </div>
  );
}

export default CustomerFeedback;
