import React from "react";
import { Translate } from "react-auto-translate";
import ProgressBar from "../../../components/Dashboard/ProgressBar";
import Dashboard from "../../../components/Dashboard";

import Accordion from "Accordion";
import { Icon } from "semantic-ui-react";

import "./statistics.scss";

function Statistics({ showAnalytics, dashboard_dataset, updateShowAnalytics }) {
  return (
    <div className="analytics-accordion">
      <div className="Stats-Container">
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "5px" }}>
          <text className="Stats-text gradient-text">
            <Translate>Statistics</Translate>
          </text>
          {!showAnalytics ? (
            <Icon className="chevron-icon" onClick={() => updateShowAnalytics(!showAnalytics)} name="angle down" />
          ) : (
            <Icon onClick={() => updateShowAnalytics(!showAnalytics)} className="chevron-icon" name="angle up" />
          )}
        </div>
        <ProgressBar data={dashboard_dataset} />
      </div>
      <Accordion onClick={() => updateShowAnalytics(!showAnalytics)} isOpen={showAnalytics}>
        <Dashboard datas={dashboard_dataset} />
      </Accordion>
    </div>
  );
}

export default Statistics;
