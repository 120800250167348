const init = {
  data: [],
  isFetching: false,
  error: false,
};
export function appointmentAnswers(state = init, action) {
  switch (action.type) {
    case "FETCH_APPOINTMENT_ANSWERS":
      return {
        ...state,
        ...action.response,
      };
    case "FETCH_APPOINTMENT_RESET":
      return {
        ...state,
        ...action.response,
      };
    default:
      return state;
  }
}
