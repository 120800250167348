import { API_BASE_URL, BRANCH_SUBSCRIPTIONS_SERVICES_API } from "utilities";
import axios from "axios";
import moment from "moment";

export function getBranchSubscriptionsServicesHasErrored() {
  return {
    type: "GET_BRANCH_SUBSCRIPTIONS_SERVICES_HAS_ERRORED",
    error: true,
    loading: false,
    data: [],
    isFetched: true,
  };
}

export function getBranchSubscriptionsServicesSuccess(data) {
  return {
    type: "GET_BRANCH_SUBSCRIPTIONS_SERVICES_SUCCESS",
    error: false,
    loading: false,
    data: data,
    isFetched: true,
  };
}

export function getBranchSubscriptionsServices(data) {
  sessionStorage.setItem("subscription_valid_status", -1);
  return (dispatch) => {
    axios({
      method: "get",
      url: API_BASE_URL + BRANCH_SUBSCRIPTIONS_SERVICES_API,
      data: { cost: data },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(getBranchSubscriptionsServicesSuccess(response.data.results));
          if (response.data.results.length > 0) {
            response.data.results.map(({ subscription_service, expires_on }) => {
              if (subscription_service.name === "RECEPTIONIST_APP") {
                const today = moment();
                const expiry_date = moment(expires_on);
                const validity_days = expiry_date.diff(today, "days") >= 0 ? expiry_date.diff(today, "days") + 1 : -1;
                sessionStorage.setItem("subscription_valid_status", validity_days);
              }
            });
          }
        } else {
          dispatch(getBranchSubscriptionsServicesHasErrored());
        }
      })
      .catch(function (error) {
        dispatch(getBranchSubscriptionsServicesHasErrored());
      });
  };
}
