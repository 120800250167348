import React from "react";
import { Translate } from "react-auto-translate";
import { toast } from "react-toastify";
import { errorMessage } from "../actionMessages";

function ToastAlert({ message, type, position = "TOP_RIGHT", autoClose = 2000 }) {
  if (sessionStorage.getItem("subscription_valid_status") < 0 && message === errorMessage) {
    return;
  }

  const message_translated = typeof message === "string" && <Translate>{message}</Translate>;

  toast[type](message_translated, {
    position: toast.POSITION[position],
    autoClose,
    hideProgressBar: false,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
  });
}

export default ToastAlert;
