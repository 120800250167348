import { API_BASE_URL, CAMPAIGN_API, CAMPAIGN_BASIC_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { campaignActions, errorMessage } from "../../utilities/actionMessages";

export function campaignFetchDataSuccess(campaign) {
  return {
    type: "CAMPAIGN_FETCH_DATA_SUCCESS",
    campaign,
  };
}

export function campaignBasicSuccess(campaign) {
  return {
    type: "CAMPAIGN_BASIC_FETCH_DATA_SUCCESS",
    data: campaign,
  };
}

export function campaignBasicFetchData() {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + CAMPAIGN_BASIC_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(campaignBasicSuccess(response.data.results));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function campaignFetchData({ page = 1, search = "", status = 1 }) {
  const params = { search, page, status };

  return (dispatch) => {
    dispatch(startLoading("CAMPAIGN_LOADER", "loading campaign"));
    axios
      .get(API_BASE_URL + CAMPAIGN_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params,
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(campaignFetchDataSuccess(response.data));
          dispatch(endLoading("CAMPAIGN_LOADER"));
        } else {
          dispatch(errored("CAMPAIGN_LOADER", true));
          dispatch(endLoading("CAMPAIGN_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CAMPAIGN_LOADER", true));
        dispatch(endLoading("CAMPAIGN_LOADER"));
      });
  };
}

export function campaignPostData(campaign, inAppointment) {
  return (dispatch) => {
    dispatch(startLoading("CAMPAIGN_LOADER", "loading campaign"));
    axios({
      method: "post",
      url: API_BASE_URL + CAMPAIGN_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: campaign,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: campaignActions.success_create, type: "success" });
          if (inAppointment) {
            // because in appointment we just need basic campaign details
            dispatch(campaignBasicFetchData());
          } else {
            dispatch(campaignFetchData({}));
          }
          dispatch(endLoading("CAMPAIGN_LOADER"));
        } else {
          dispatch(errored("CAMPAIGN_LOADER", true));
          dispatch(endLoading("CAMPAIGN_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CAMPAIGN_LOADER", true));
        dispatch(endLoading("CAMPAIGN_LOADER"));
      });
  };
}

export function campaignPutData(id, campaign) {
  return (dispatch) => {
    dispatch(startLoading("CAMPAIGN_LOADER", "loading campaign"));
    axios({
      method: "put",
      url: API_BASE_URL + CAMPAIGN_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: campaign,
    })
      .then(function (response) {
        if (response.status === 200) {
          //since only deletion is happening no updation
          ToastAlert({ message: campaignActions.success_delete, type: "success" });
          dispatch(campaignFetchData({}));
          dispatch(endLoading("CAMPAIGN_LOADER"));
        } else {
          dispatch(errored("CAMPAIGN_LOADER", true));
          dispatch(endLoading("CAMPAIGN_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CAMPAIGN_LOADER", true));
        dispatch(endLoading("CAMPAIGN_LOADER"));
      });
  };
}
