import React from "react";
import { Translate } from "react-auto-translate";
import { Icon, Popup } from "semantic-ui-react";

function Card({ value, headLine, name, hover, subheadLine }) {
  const packedClass = subheadLine ? "packed" : "";
  return (
    <div className="card">
      <div>
        <h2 className={packedClass}>{value}</h2>
        <p className={packedClass}>
          <Translate>{headLine}</Translate>
        </p>
        {subheadLine && (
          <span>
            <Translate>{subheadLine}</Translate>
          </span>
        )}
      </div>
      <div>
        {name === "expenses" ? (
          <Icon name="money bill alternate outline" style={{ fontSize: "19px", color: "#2271b0" }} />
        ) : hover ? (
          <Popup trigger={<img src={name} alt="" />} position="top left">
            <Popup.Content>
              {hover.map((staff) => (
                <p key={staff}>{staff}</p>
              ))}
            </Popup.Content>
          </Popup>
        ) : (
          <img height={30} src={name} alt="" />
        )}
      </div>
    </div>
  );
}

export default Card;
