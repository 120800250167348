const defaultState = {
  loading: false,
  results: [],
  current_page: 1,
  pages: 1,
  count: 0,
  error: false,
};

export function memberships(state = defaultState, action) {
  switch (action.type) {
    case "MEMBERSHIPS_FETCH_SUCCESS":
      return action.memberships;
    case "MEMBERSHIPS_LOADING":
      return action.memberships;
    case "MEMBERSHIPS_ERRORED":
      return action.memberships;
    default:
      return state;
  }
}

const basicInitialState = {
  data: [],
};

export function membershipsBasic(state = basicInitialState, action) {
  switch (action.type) {
    case "MEMBERSHIPS_BASIC_FETCH_SUCCESS":
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}

const initMemberhshipUpdate = {
  data: {},
};

export function membershipUpdate(state = initMemberhshipUpdate, action) {
  switch (action.type) {
    case "MEMBERSHIP_UPDATE":
      return { ...state, ...action.response };
    default:
      return state;
  }
}
