import React, { useState, useEffect } from "react";
import "./index.scss";

import { Button, Dropdown, Input, Form } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Translate } from "react-auto-translate";

import { GST_BRACKET_OPTIONS } from "../../../../../utilities/constants";
import { PRODUCTS_DATA } from "../../../../../store/actions/products";

const discountTypes = [
  { key: 0, text: "%", value: 0 },
  { key: 1, text: "/-", value: 1 },
];

const gst_brackets = [{ key: -1, text: "Remove GST", value: null }, ...GST_BRACKET_OPTIONS];

const PrevProductQuantityInfo = ({ prevProductQuantity, availableQuantity }) => {
  const totalProductQuantity = availableQuantity + prevProductQuantity || 0;

  return (
    <p className="info" style={{ width: "80%", marginBottom: "-5px" }}>
      <Translate>
        Total Quantity = Previous Quantity({prevProductQuantity}) + Available Quantity({availableQuantity}) ={" "}
        {totalProductQuantity}
      </Translate>
    </p>
  );
};

function ProductDetailForm({ index = -1, in_out = 1, formData = {}, onChange = false, onRemove = false }) {
  const products = useSelector((state) => PRODUCTS_DATA(state));
  const productsMap = useSelector((state) => state.productsMap);

  const [productOptions, setProductOptions] = useState([]);
  const prevProduct = (in_out === 0 && formData.prevProduct) || { product: null, quantity: 0 };

  useEffect(() => {
    const _productOptions = getProductOptions(products);
    setProductOptions(_productOptions);
  }, [products]);

  const getProductOptions = (products) => {
    const productOptions = products
      .filter((product) => product.status)
      .map((product) => {
        const { id, cost, name = "", quantity, company } = product;

        const slicingPointName = 30;
        const slicingPointCompany = 15;

        const companyName = company?.name || "N/A";
        const slicedName = name.length > slicingPointName ? `${name.slice(0, slicingPointName)}...` : name;
        const slicedCompanyName =
          companyName.length > slicingPointCompany ? `${companyName.slice(0, slicingPointCompany)}...` : companyName;

        // formatting to make it uniquely identified
        const text = `${slicedName}  |  ${cost}/-  |  ${slicedCompanyName}  |  Qty. ${quantity}`;

        return {
          key: id,
          value: id,
          text,
        };
      });

    return productOptions;
  };

  const getCalculatedTotalPrice = (formData) => {
    // in_out === 1 means in and 0 means out
    // if in_out === 1 then take procurement price else take cost price(mrp)
    const price = in_out === 1 ? formData.cost_price : formData.mrp;

    const _price = parseFloat(price) || 0;
    const _discount = parseFloat(formData.discount) || 0;
    const _gst_bracket = parseFloat(formData.gst_bracket) || 0;
    const _quantity = parseFloat(formData.quantity) || 0;

    const totalCostPrice = _price * _quantity;

    let calculatedDiscount = 0;
    if (formData.discount_type === 1) {
      calculatedDiscount = _discount;
    } else {
      calculatedDiscount = parseFloat(totalCostPrice * _discount) / 100;
    }

    const discountedCostPrice = totalCostPrice - calculatedDiscount;
    const calculatedGST = parseFloat(discountedCostPrice * _gst_bracket) / 100;
    const calculatedTotalPrice = discountedCostPrice + calculatedGST;
    const formattedTotalPrice = calculatedTotalPrice.toFixed(2); //2 digits precision

    return formattedTotalPrice;
  };

  useEffect(() => {
    const calculatedTotalPrice = getCalculatedTotalPrice(formData);
    handleChange(calculatedTotalPrice || 0, "total_price_calculated");
  }, [
    in_out,
    formData.mrp,
    formData.cost_price,
    formData.quantity,
    formData.discount,
    formData.discount_type,
    formData.gst_bracket,
  ]);

  const handleChange = (value, key) => {
    let updatedForm = {
      ...formData,
      [key]: value,
    };

    // autofill the values on selcting product
    if (key === "product") {
      const productData = productsMap[value];
      const { cost: mrp, price: cost_price, mfg_date, exp_date } = productData;

      // auto filling the values
      updatedForm = {
        ...updatedForm,
        cost_price,
        mrp,
        mfg_date,
        exp_date,
      };
    }

    if (onChange) {
      onChange(index, updatedForm);
    }
  };

  const getDeletedProductInfo = (detail = {}) => {
    const { cost, name = "", quantity } = detail;

    const slicingPoint = 30;
    const slicedName = name.length > slicingPoint ? `${name.slice(0, slicingPoint)}...` : name;

    // formatting to make it uniquely identified
    const text = `${slicedName}  |  ${cost}/-  |  Qty. ${quantity}`;

    return text;
  };

  const getQuantityNotSufficientError = () => {
    const productData = productsMap[formData.product];
    let totalProductQuantity = productData.quantity;

    if (formData.product === prevProduct.product) {
      totalProductQuantity += prevProduct.quantity;
    }

    if (totalProductQuantity <= 0) {
      return "Can not select this product in OUT status, Product is out of stock";
    }
    if (Number(formData.quantity) > totalProductQuantity) {
      return "Product Quantity can not be more than available product stock for OUT status";
    }

    return "";
  };

  const isProductDeleted = formData.product && !productsMap[formData.product]?.status;
  const deletedProductInfo = isProductDeleted && getDeletedProductInfo(productsMap[formData.product]);

  const quantityNotSufficientError = (in_out === 0 && formData.product && getQuantityNotSufficientError()) || "";

  // for OUT status only
  const showProductQuantityInfo = in_out === 0 && prevProduct.product && prevProduct.product === formData.product;
  const availableQuantity = in_out === 0 && formData.product && productsMap[formData.product]?.quantity;
  const prevProductQuantity = in_out === 0 && prevProduct.quantity;

  // styles
  const labelClassname = isProductDeleted ? "deleted-label" : "text-theme-black";

  return (
    <div>
      {showProductQuantityInfo && (
        <PrevProductQuantityInfo availableQuantity={availableQuantity} prevProductQuantity={prevProductQuantity} />
      )}

      {/*  */}
      <div className="product-detail-form">
        <div className="input-fields">
          <Form.Field className="dropdown-field">
            {!isProductDeleted && (
              <>
                <strong className={labelClassname}>
                  <Translate>Select Product*</Translate>
                </strong>
                <Dropdown
                  disabled={!onChange || isProductDeleted}
                  className="dropdown"
                  placeholder="Select Product"
                  value={formData.product}
                  onChange={(e, { value }) => handleChange(value, "product")}
                  options={productOptions}
                  selection
                  fluid
                  search
                />
              </>
            )}
            {isProductDeleted && (
              <>
                <strong className={labelClassname}>
                  <Translate>Select Product*</Translate>{" "}
                  <span style={{ color: "red" }}>
                    <Translate>(Deleted)</Translate>
                  </span>
                </strong>
                <Input
                  disabled={!onChange || isProductDeleted}
                  className="input"
                  type="text"
                  placeholder="Select Product"
                  value={deletedProductInfo || ""}
                />
              </>
            )}
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Quantity*</Translate>
            </strong>
            <Input
              disabled={!onChange || isProductDeleted}
              className="input"
              type="number"
              placeholder="Quantity"
              value={formData.quantity}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                handleChange(e.target.value, "quantity");
              }}
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Cost Price(MRP)*</Translate>
            </strong>
            <Input
              disabled={!onChange || isProductDeleted}
              className="input"
              type="number"
              placeholder="Cost Price(MRP)"
              value={formData.mrp}
              onChange={(e) => {
                handleChange(e.target.value, "mrp");
              }}
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Price(Procurement Price)*</Translate>
            </strong>
            <Input
              disabled={!onChange || isProductDeleted}
              className="input"
              type="number"
              placeholder="Price(Procurement Price)"
              value={formData.cost_price}
              onChange={(e) => {
                handleChange(e.target.value, "cost_price");
              }}
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Discount (On Cost Price)</Translate>
            </strong>
            <Input
              disabled={!onChange || isProductDeleted}
              className="input"
              label={
                <Dropdown
                  disabled={!onChange || isProductDeleted}
                  options={discountTypes}
                  value={formData.discount_type}
                  onChange={(e, { value }) => {
                    handleChange(value, "discount_type");
                  }}
                />
              }
              fluid
              labelPosition="right"
              placeholder="Discount"
              value={formData.discount}
              onChange={(e) => {
                handleChange(e.target.value, "discount");
              }}
              type="number"
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>GST</Translate>
            </strong>
            <Dropdown
              disabled={!onChange || isProductDeleted}
              className="dropdown"
              placeholder="GST"
              options={gst_brackets}
              value={formData.gst_bracket}
              onChange={(e, { value }) => {
                handleChange(value, "gst_bracket");
              }}
              selection
              fluid
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Calculated Total Price</Translate>
            </strong>
            <Input
              disabled={true}
              className="input"
              type="number"
              placeholder="Calculated Total Price"
              value={formData.total_price_calculated}
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>MFG Date*</Translate>
            </strong>
            <Input
              disabled={!onChange || isProductDeleted}
              className="input"
              type="date"
              placeholder="MFG Date"
              value={formData.mfg_date || ""}
              onChange={(e) => {
                handleChange(e.target.value, "mfg_date");
              }}
            />
          </Form.Field>
          <Form.Field className="input-field">
            <strong className={labelClassname}>
              <Translate>Expiry Date*</Translate>
            </strong>
            <Input
              disabled={!onChange || isProductDeleted}
              className="input"
              type="date"
              placeholder="Expiry Date"
              value={formData.exp_date || ""}
              onChange={(e) => {
                handleChange(e.target.value, "exp_date");
              }}
            />
          </Form.Field>
        </div>
        <div className="action-buttons">
          {onRemove && <Button disabled={isProductDeleted} color="red" icon="minus" onClick={() => onRemove(index)} />}
        </div>
      </div>
      {isProductDeleted && (
        <p style={{ color: "red", marginBottom: "20px" }}>
          <Translate>Can not update, Product has been deleted</Translate>
        </p>
      )}
      {quantityNotSufficientError.length > 0 && (
        <p style={{ color: "red", marginBottom: "20px" }}>
          <Translate>{quantityNotSufficientError}</Translate>
        </p>
      )}
    </div>
  );
}

export default ProductDetailForm;
