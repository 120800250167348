import React, { useState, useEffect, useRef } from "react";

function Timer({ limit, onTimeFinish }) {
  const [time, setTime] = useState({
    limit: limit,
    minutes: parseInt(limit / 60),
    seconds: limit - parseInt(limit / 60) * 60,
  });
  const timerRef = useRef(null);

  useEffect(() => {
    if (time.limit === -1) {
      clearInterval(timerRef.current);
      timerRef.current = null;

      onTimeFinish();
    }
  }, [time]);

  const calculate = () => {
    setTime((prev) => {
      const _limit = prev.limit - 1;

      const minutes = parseInt(_limit / 60);
      const seconds = _limit - minutes * 60;

      return {
        limit: _limit,
        minutes: minutes,
        seconds: seconds,
      };
    });
  };

  useEffect(() => {
    timerRef.current = setInterval(calculate, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <div style={{ color: "green" }}>
      <span>{time.minutes < 10 ? `0${time.minutes}` : time.minutes}</span>
      <span> : </span>
      <span>{time.seconds < 10 ? `0${time.seconds}` : time.seconds}</span>
    </div>
  );
}

export default Timer;
