import React, { useEffect, useState } from "react";
import "./index.scss";
import { Checkbox, Dropdown, Radio, Form as Forms, Icon, Label, Input } from "semantic-ui-react";
import Search from "Search";
import FileUpload from "FileUpload";
import _ from "lodash";
import AddRemove from "AddRemove";
import SearchDropdown from "SearchDropdown";
import SearchInput from "SearchInput";
import ServiceDetails from "ServiceDetails";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Translate } from "react-auto-translate";
import { selectedCustomerAppointmentForm } from "../../store/actions/selectedCustomerAppointmentform";
import { FormInputType } from "../../utilities/constants";
import MembershipPopUp from "../MembershipPopUp/MembershipPopUp";
import CampaignPopUp from "../CampaignPopUp/CampaignPopUp";
import { getPendingHistory } from "../../store/actions/pendings";
import { freeTextSearch } from "../../store/actions/freeTextSearch";
import { setIsQuickBook } from "../../store/actions/quickbook";
import { serviceLabelOptionsSelector, productLabelOptionsSelector } from "../../store/selectors/labelOptions";

//if partner, change all the "appoitnments" to "bookings"
import { IS_PARTNER, TIME_UNIT_OPTIONS, GST_BRACKET_OPTIONS } from "../../utilities/constants";
import { customerWalletFetchData } from "../../store/actions/customerWallet";
import { formatTimeByUnit } from "../../utilities/CommonFunctions";
import Toggler from "../Toggler";
const APPOINTMENT_LABEL = IS_PARTNER ? "Booking" : "Appointment";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

const inputType = (
  { type, label, field, fields, options, validation, required, className, length, multiple, search, disabled = false },
  setForm,
  form,
  field_value,
  pastCustomer,
  pastCustomerRevenue,
  viewCustomerHistory,
  selectedStaff,
  viewCustomerNotes,
  viewPendingPayments,
  error,
  edit,
  setIsDuplicateNumber,
  viewCustomerDetails,
  selectedServiceLabels,
  selectedProductLabels,
  isExistingAppointment = false
) => {
  const ellipsifyOptions = (strings, ELLIPSIFY_LENGTH) => {
    return strings.map((string) => {
      const textArr = string.text.split(" | ");
      textArr[0] =
        textArr[0].length > ELLIPSIFY_LENGTH ? textArr[0].slice(0, ELLIPSIFY_LENGTH - 10) + "..." : textArr[0];

      const _text = textArr.join(" | ");

      return {
        ...string,
        text: _text,
      };
    });
  };

  let serviceOptions = [];
  let productOptions = [];

  const TIME_UNIT_OPTIONS_TRANSLATED = TIME_UNIT_OPTIONS.map((option) => {
    const { key, text, value } = option;
    return {
      key,
      value,
      text: <Translate>{text}</Translate>,
    };
  });

  if (type === "services") {
    if (field_value && field_value[0] && !("service_id" in field_value[0])) {
      let formattedFieldValue = [];
      for (let required_service of field_value) {
        if (required_service.status !== 4) {
          formattedFieldValue.push({
            id: required_service.id,
            service_id: required_service["service"] ? required_service["service"].id : null,
            cost:
              required_service.cost || required_service.cost === 0
                ? required_service.cost
                : required_service["service"].cost,
            original_cost: required_service["service"]?.cost || 0,
            staff_id: required_service["staff"] ? required_service["staff"].id : null,
            staffs:
              required_service["staffs"].length > 0
                ? required_service["staffs"].map(({ staff, revenue_percentage }) => ({
                    ...staff,
                    revenue_percentage,
                  }))
                : [],
            discount_type:
              required_service["discount_type"] || required_service["discount_type"] === 0
                ? required_service["discount_type"]
                : null,
            discount_type_value: required_service["discount_type_value"]
              ? required_service["discount_type_value"]
              : null,
            status: required_service.status,
            seat_number: required_service.seat_number,
            duration: required_service.duration,
            time_unit: required_service.time_unit || "MIN",
            quantity: required_service.quantity,
            checked: true,
          });
        }
      }
      setForm({ ...form, [field]: formattedFieldValue });
      field_value = formattedFieldValue;
    }
    if (field_value && field_value.length === 0) {
      field_value = [{ staff_id: null, service_id: null, cost: undefined }];
    }

    if (selectedServiceLabels.length) {
      serviceOptions = options.services.filter((service) => {
        const serviceLabels = service.labels.label_ids;

        return selectedServiceLabels.some((selectedLabel) => {
          return serviceLabels.includes(selectedLabel);
        });
      });
    } else {
      serviceOptions = options.services;
    }
  }

  if (type === "products") {
    if (field_value && field_value[0] && !("product_id" in field_value[0])) {
      let formattedFieldValue = [];
      for (let required_product of field_value) {
        if (required_product.status !== 4) {
          formattedFieldValue.push({
            id: required_product.id,
            product_id: required_product["product"] ? required_product["product"].id : null,
            quantity: required_product.quantity,
            cost:
              required_product.cost || required_product.cost === 0
                ? required_product?.cost || 0
                : required_product["product"]?.cost || 0,
            original_cost: required_product["product"]?.cost || 0,
            discount_type:
              required_product["discount_type"] || required_product["discount_type"] === 0
                ? required_product["discount_type"]
                : null,
            discount_type_value: required_product["discount_type_value"]
              ? required_product["discount_type_value"]
              : null,
            staff_id: required_product["staff"] ? required_product["staff"].id : null,
            checked: true,
          });
        }
      }
      setForm({ ...form, [field]: formattedFieldValue });
      field_value = formattedFieldValue;
    }
    if (field_value && field_value.length === 0) {
      field_value = [{ staff_id: null, service_id: null, cost: undefined }];
    }

    if (selectedProductLabels.length) {
      productOptions = options.products.filter((product) => {
        const productLabels = product.labels.label_ids;

        return selectedProductLabels.some((selectedLabel) => {
          return productLabels.includes(selectedLabel);
        });
      });
    } else {
      productOptions = options.products;
    }
  }

  switch (type) {
    case "datetime": {
      const datetime = field_value ? Moment(field_value).format("YYYY-MM-DDTHH:mm") : "";
      return (
        <input
          placeholder={"Enter " + label}
          type="datetime-local"
          onChange={(e) => setForm({ ...form, [field]: Moment.utc(Moment(e.target.value)).format() })}
          value={datetime}
        />
      );
    }
    case "textarea": {
      return (
        <textarea
          maxLength={length}
          placeholder={"Enter " + label}
          onChange={(e) => setForm({ ...form, [field]: e.target.value })}
          value={field_value || ""}
          disabled={form.disabled}
        />
      );
    }
    case "date": {
      const date = field_value ? Moment(field_value).format("YYYY-MM-DD") : "";
      return (
        <input
          placeholder={"Enter " + label}
          type="date"
          onChange={(e) => setForm({ ...form, [field]: e.target.value })}
          value={date}
        />
      );
    }
    case "time": {
      // const time = field_value? Moment(field_value).format('YYYY-MM-DD'): '';
      return (
        <input
          placeholder={"Enter " + label}
          type="time"
          onChange={(e) => setForm({ ...form, [field]: e.target.value })}
          value={field_value || ""}
        />
      );
    }
    case "text": {
      return (
        <input
          maxLength={length}
          placeholder={"Enter " + label}
          onChange={(e) => setForm({ ...form, [field]: e.target.value })}
          value={field_value || ""}
          disabled={field === "gst" && !form.disabled}
        />
      );
    }
    case "number": {
      return (
        <input
          type="tel"
          maxLength={length}
          placeholder={"Enter " + label}
          onChange={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
            setForm({ ...form, [field]: e.target.value });
          }}
          value={field_value || ""}
        />
      );
    }
    case "decimal": {
      return (
        <input
          type="number"
          maxLength={length}
          placeholder={"Enter " + label}
          step=".01"
          onChange={(e) => {
            const value = e.target.value;
            const formattedValue = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value;
            setForm({ ...form, [field]: formattedValue });
          }}
          value={field_value || ""}
        />
      );
    }
    case "duration": {
      return (
        <Input
          type="number"
          maxLength={length}
          placeholder={"Enter " + label}
          onChange={(e) => setForm({ ...form, [field]: e.target.value })}
          value={field_value || ""}
          label={
            <Dropdown
              options={TIME_UNIT_OPTIONS_TRANSLATED}
              value={form?.time_unit || "MIN"}
              onChange={(e, val) => {
                setForm({ ...form, time_unit: val.value });
              }}
            />
          }
          labelPosition="right"
        />
      );
    }
    case "checkbox": {
      const isGSTCheckbox = label === "Apply Tax";
      if (label === `Send ${APPOINTMENT_LABEL.toLowerCase()} sms`) {
        field_value = field_value === "false" || !field_value ? false : true;
      }
      return (
        <>
          <Checkbox
            defaultChecked={field_value}
            label={translateFormLabel(`${label}?`)}
            onChange={(e, value) => {
              if (label == `Send ${APPOINTMENT_LABEL.toLowerCase()} sms`) {
                localStorage.setItem("sendAppointmentSMS", value.checked);
              }
              setForm({ ...form, [field]: value.checked });
            }}
          />
          {isGSTCheckbox && (
            <Dropdown
              style={{ marginLeft: "10px", width: "100px" }}
              selection
              options={GST_BRACKET_OPTIONS}
              value={form?.gst_bracket}
              upward
              onChange={(e, val) => {
                setForm({ ...form, gst_bracket: val.value });
              }}
            />
          )}
        </>
      );
    }
    case "radio": {
      return _.map(options, (value, key) => {
        return (
          <Radio
            key={key}
            label={translateFormLabel(value)}
            name={label}
            value={key}
            checked={field_value === key}
            onChange={(e, { value }) => setForm({ ...form, [field]: value })}
          />
        );
      });
    }
    case "dropdown": {
      const search = (options, query) => {
        return options.filter((option) => option.text.toLowerCase().startsWith(query.toLowerCase()));
      };
      //because we want field_value in string and array format according to our need
      if (multiple && typeof field_value === "string") {
        field_value = field_value.split(", ");
      }
      return (
        <Dropdown
          placeholder={translateFormLabel("Select " + label)}
          fluid
          selection
          disabled={disabled}
          multiple={multiple}
          options={options}
          selectOnBlur={false}
          onChange={(e, { value }) => {
            if (label === "membership" && value === null) {
              setForm({ ...form, [field]: value, affiliated_staff: null });
            } else {
              setForm({ ...form, [field]: value });
            }
          }}
          value={multiple ? field_value || [] : field_value}
          search={search}
        />
      );
    }
    case "file-upload": {
      return (
        <FileUpload
          limit={options}
          onChange={(value) => setForm({ ...form, [field]: value })}
          value={field_value || []}
        />
      );
    }
    case "commission": {
      return (
        <input
          type="number"
          min="0"
          max="10"
          step=".5"
          onChange={(e) => setForm({ ...form, [field]: e.target.value })}
          value={field_value || 0}
        />
      );
    }
    case "search": {
      const disableInput = form?.status === 3;
      return (
        <Search
          isExistingAppointment={isExistingAppointment}
          url={search}
          fields={fields}
          field={field}
          onChange={(data) => {
            setForm({ ...form, ...data });
          }}
          disabled={disableInput}
          value={field_value}
          pastCustomer={pastCustomer}
          pastCustomerRevenue={pastCustomerRevenue}
          onLabel0Click={() => {
            const selectedCustomerData = {
              id: form["customer.id"] || form.customer.id,
              name: form["customer.name"] || form.customer.name,
              number: form["customer.number"] || form.customer.number,
              gender: form["customer.gender"] || form.customer.gender,
              revenue: form["customer.revenue"] || form.customer.revenue,
            };

            viewCustomerHistory(selectedCustomerData);
          }}
          onLabel1Click={() => viewCustomerNotes(pastCustomer)}
          onLabel2Click={() => viewCustomerDetails(form?.id)}
          onPaymentDueClick={() => viewPendingPayments(pastCustomer)}
          setIsDuplicateNumber={setIsDuplicateNumber}
          clientData={form?.customer}
          form={form}
        />
      );
    }
    case "search-dropdown": {
      return <SearchDropdown />;
    }
    case "search-input": {
      return (
        <SearchInput
          options={options}
          onChange={(response) => setForm({ ...form, [field]: response })}
          value={field_value || ""}
          placeholder={label}
        />
      );
    }
    case "services": {
      return (
        <AddRemove
          onChange={(data) => {
            setForm({ ...form, [field]: data });
          }}
          value={field_value}
          appointment={form}
        >
          <ServiceDetails
            placeholder={["service", "staff", "staffs"]}
            services={ellipsifyOptions(serviceOptions, 55)}
            allServices={options.services}
            providers={options.staff}
            selectedStaff={selectedStaff}
            options={options}
            form={form}
          />
        </AddRemove>
      );
    }
    case "products": {
      return (
        <AddRemove
          onChange={(data) => {
            setForm({ ...form, [field]: data });
          }}
          value={field_value}
          error={error}
        >
          <ServiceDetails
            placeholder={["product", "staff", "staffs"]}
            services={productOptions}
            allServices={options.products}
            providers={options.staff}
            selectedStaff={selectedStaff}
          />
        </AddRemove>
      );
    }
    default:
  }
};

const Form = ({
  isAppointmentForm = false,
  inputs,
  data,
  edit,
  action,
  update,
  cancel = false,
  completed = false,
  enquiry = false,
  viewCustomerHistory = () => {},
  viewExtraModal,
  error,
  selectedStaff = null,
  viewCustomerNotes = () => {},
  viewPendingPayments = () => {},
  handleChange = () => {},
  showModal = () => {},
  availabilityErrorMessages = null,
  viewCustomerDetails = () => {},
}) => {
  const dispatch = useDispatch();

  const services = useSelector((state) => state.services.data);
  const staffBasicList = useSelector((state) => state.staffBasic.results);
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch.data);
  const { data: aclUserPermissions } = useSelector((state) => state.aclUserPermissions);
  const membershipUpdate = useSelector((state) => state.membershipUpdate.data);
  const freeTextSearchValue = useSelector((state) => state.freeTextSearch);
  const serviceLabelOptions = useSelector(serviceLabelOptionsSelector);
  const productLabelOptions = useSelector(productLabelOptionsSelector);
  const isQuickBook = useSelector((state) => state.isQuickBook.status);

  const [form, setForm] = useState({});
  const [valid, setValid] = useState(true);
  const [isDuplicateNumber, setIsDuplicateNumber] = useState(false);
  const [itemValidation, setItemValidation] = useState({});
  const [pastCustomer, setPastCustomer] = useState(0);
  const [pastCustomerRevenue, setPastCustomerRevenue] = useState(0);
  const [selectedServiceLabels, setSelectedServiceLabels] = useState([]);
  const [selectedProductLabels, setSelectedProductLabels] = useState([]);

  const { formatted_component_permissions } = aclUserPermissions;
  const isExistingAppointment = isAppointmentForm && "id" in data;

  useEffect(() => {
    if (membershipUpdate.id != undefined) {
      setForm({ ...form, membership: membershipUpdate.id });
    }
  }, [membershipUpdate]);

  useEffect(() => {
    if (data.customer) {
      dispatch(selectedCustomerAppointmentForm(data.customer));
    }
    if (data?.resetStatus) {
      delete data?.status;
      delete data.resetStatus;
    }

    if (data?._datetime) {
      data.datetime = data._datetime;
      delete data._datetime;
    }
    if (data?.customerDetail) {
      if (!data.hasOwnProperty("customer")) {
        data.customer = {};
      }
      data["customer"].date_of_anniversary = data.customerDetail?.date_of_anniversary;
      data["customer"].date_of_birth = data.customerDetail?.date_of_birth;
      data["customer"].email = data.customerDetail?.email;
      data["customer"].gender = data.customerDetail?.gender;
      data["customer"].id = data.customerDetail?.id;
      data["customer"].name = data.customerDetail?.name;
      data["customer"].number = data.customerDetail?.number;
      data["customer"].pending_payments = data.customerDetail?.pending_payments;
      data["customer"].revenue = data.customerDetail?.revenue;
      data["customer"].country_code = data.customerDetail?.country_code;
      data["customer"].custom_field_one = data.customerDetail?.custom_field_one;
      data["customer"].custom_field_two = data.customerDetail?.custom_field_two;
      data["customer"].customer_gst_number = data.customerDetail?.customer_gst_number;
      data["customer"].is_active = data.customerDetail?.is_active;
      delete data.customerDetail;
    }

    if (data?.custom_field_one) {
      let temp = data.custom_field_one.slice(4);
      temp = temp.replaceAll(/<br>/g, "\n");
      data.custom_field_one = temp;
    }

    if (data?.serviceList) {
      data["services_list"] = data.serviceList;
      delete data.serviceList;
    }
    let temp,
      flag = false;
    if (data?.required_services?.length) {
      flag = true;
      temp = JSON.parse(JSON.stringify(data.required_services));
      temp.map((service) => {
        service.duration = formatTimeByUnit(service.duration, service.time_unit);
      });
    }
    if (flag) setForm({ ...data, required_services: temp });
    else setForm(data);
  }, [data]);

  useEffect(() => {
    const customer_id = form["customer"]?.id || form?.["customer.id"];
    const customer_revenue = form["customer"]?.revenue || form?.["customer.revenue"];
    const customer_pending = form["customer"]?.pending_payments || form?.["customer.pending_payments"];

    if (pastCustomer !== customer_id && !!customer_id) {
      setPastCustomer(customer_id);
      dispatch(getPendingHistory(customer_id));
      dispatch(customerWalletFetchData(customer_id));
    }
    setPastCustomerRevenue(customer_revenue);
    handleChange(form);
  }, [form]);

  const formValidationStatus = (field, status) => {
    if (itemValidation[field] !== status) {
      const newItemValidation = {
        ...itemValidation,
        [field]: status,
      };
      setItemValidation(newItemValidation);

      const totalValid = Object.values(newItemValidation).reduce((a, item) => a + (item === false ? 1 : 0), 0);
      setValid(totalValid === Object.keys(newItemValidation).length);
    }
    return status;
  };

  const getAvailabilityErrorMessage = (errorMessages) => {
    if (errorMessages.length === 0) return null;
    else if (typeof errorMessages === "string") {
      return (
        <div className="availability-error">
          <div className="details">
            <Translate>{errorMessages}</Translate>
          </div>
        </div>
      );
    }
    const messages = errorMessages.map((msg) => {
      const service = services.filter((item) => item.id === msg.service_id);
      const selectedStaff = staffBasicList.filter((item) => item.id === msg.staff_id);
      const serviceName = service?.[0]?.name || "";
      const staffName = selectedStaff?.[0]?.user?.name || "";
      const startDT = moment(msg.service_start).format("YYYY-mm-DD hh:mm:ss");
      const endDT = moment(msg.service_end).format("YYYY-mm-DD hh:mm:ss");

      return (
        <p>
          <Translate>
            * {staffName} is occupied with {serviceName} of {APPOINTMENT_LABEL.toLowerCase()} #
            {branch?.invoice_pre_text}
            {msg.appointment_id} from {startDT}-{endDT}
          </Translate>
        </p>
      );
    });

    return (
      <>
        <div className="availability-error">
          <p>
            <Translate>Conflict with the following staff/{APPOINTMENT_LABEL.toLowerCase()}:</Translate>
          </p>
          <div className="details">
            <Translate>{messages}</Translate>
          </div>
        </div>
        <p className="info">
          <Translate>If you want to continue with the booking by ignore the availability, uncheck</Translate>{" "}
          <strong>
            <Translate>Check Availability</Translate>
          </strong>
        </p>
      </>
    );
  };

  const getSendAppointmentSMSValue = () => {
    const value = localStorage.getItem("sendAppointmentSMS");

    return value !== null ? value : true;
  };

  const isQuickBookToggle = isAppointmentForm;

  return (
    <>
      <div className="form">
        {isQuickBookToggle && (
          <div className="quick-book-container">
            <Toggler
              checked={isQuickBook}
              label={"Quick Appointment?"}
              onChange={(e, { checked }) => {
                dispatch(setIsQuickBook(!isQuickBook));
                setItemValidation({});
                const root = document.documentElement;
                root.style.setProperty("--service-details-width", checked ? "auto" : "120px");
              }}
            />
          </div>
        )}
        <Forms>
          {_.map(inputs, (value, key) => {
            let field = value.field;
            let field_value = form[field];
            if (form && field.includes(".") && !(field in form)) {
              const field_list = field.split(".");
              if (form[field_list[0]]) {
                field_value = form[field_list[0]];
                field_value = field_value[field_list[1]];
              }
              // delete form[field_list[0]]
            }
            if (form && form.hasOwnProperty("customer.memberships")) {
              form["membership"] = form["customer.memberships"];
              form["affiliated_staff"] = form["customer.membership_staff"];
              delete form["customer.memberships"];
              delete form["customer.membership_staff"];
            }
            if (form && form.hasOwnProperty("membership_info") && form["membership_info"].length) {
              if (!form.hasOwnProperty("membership")) {
                form["membership"] = form["membership_info"][0]["membership_id"];
              }
              if (!form.hasOwnProperty("affiliated_staff")) {
                form["affiliated_staff"] = form["membership_info"][0]["staff_id"];
              }
            }

            if (
              value.label === "discount" &&
              form &&
              form.hasOwnProperty("discount_id") &&
              form["discount_id"] === null
            ) {
              delete form["discount_id"];
              if (form.hasOwnProperty("appointment_discount")) {
                delete form["appointment_discount"];
              }
            }
            if (
              value.label === "discount" &&
              form &&
              form.hasOwnProperty("appointment_discount") &&
              form["appointment_discount"].length &&
              !form.hasOwnProperty("discount_id")
            ) {
              form["discount_id"] = form["appointment_discount"][0].campaign.id;
              field_value = form["appointment_discount"][0].campaign.id;
            }

            if (value.field === "send_sms") {
              if (!form.hasOwnProperty("send_sms")) {
                field_value = getSendAppointmentSMSValue();
                form["send_sms"] = getSendAppointmentSMSValue();
              }
            }

            if (form && form.hasOwnProperty("customer.id") && !form.hasOwnProperty("customer.country_code")) {
              form["customer.country_code"] = "+91";
            }
            if (field === "is_tax_applied") {
              if (branch.gst_applicable === "NOT_APPLICABLE" || !branch.gst?.length) {
                return;
              }

              if (!form.hasOwnProperty("is_tax_applied")) {
                field_value = true;
                form["is_tax_applied"] = true;
              }
              if (!form.hasOwnProperty("gst_bracket")) {
                form["gst_bracket"] = branch.gst_bracket || 18;
              }
            }

            if (!branch?.automatically_send_payment_link && field === "send_payment_link") {
              return;
            } else if (field === "send_payment_link") {
              if (!form.hasOwnProperty("send_payment_link")) {
                field_value = true;
                form["send_payment_link"] = true;
              }
            }

            return (
              <Forms.Field key={key} className={value.type + " " + value.className}>
                {/* label */}
                <div className="form-field-label">
                  <b>
                    <Translate>{value.field !== "gst" && value.label + " "}</Translate>
                  </b>
                  {value.field === "gst" && (
                    <Checkbox
                      style={{ fontWeight: "bold" }}
                      checked={value?.disabled}
                      label={translateFormLabel("Gst number?(Not mandatory)")}
                      onChange={(event, data) => {
                        setForm({ ...form, ["disabled"]: data.checked });
                      }}
                    />
                  )}
                  {pastCustomer && pastCustomer !== 0 && value.extraModal && value.extraModal.present ? (
                    <p
                      onClick={() => {
                        viewExtraModal(pastCustomer);
                      }}
                      className="extra-modal-text"
                      style={{ fontWeight: "bold" }}
                    >
                      <Translate>{value.extraModal.text}</Translate>
                    </p>
                  ) : (
                    ""
                  )}
                  {value.extraModal && value.extraModal.present ? (
                    <Label
                      style={{ marginTop: "3px" }}
                      size="small"
                      onClick={() => {
                        showModal(value, form, FormInputType.MEMBERHSHIP);
                      }}
                      as="a"
                    >
                      <Icon
                        style={{ marginLeft: "6px" }}
                        name={form?.membership || form?.customer?.membership ? "pencil" : "add"}
                      />
                    </Label>
                  ) : (
                    ""
                  )}

                  {value.type === "services" || value.type === "products" || value.label === "discount" ? (
                    <Label
                      size="small"
                      onClick={() => {
                        showModal(
                          value,
                          form,
                          value.label === "discount"
                            ? FormInputType[value.label.toUpperCase()]
                            : FormInputType[value.type.toUpperCase()]
                        );
                      }}
                      as="a"
                    >
                      <Icon style={{ marginLeft: "6px" }} name={"add"} />
                    </Label>
                  ) : (
                    ""
                  )}
                  {(value.type === "services" || value.type === "products") && (
                    <Checkbox
                      checked={freeTextSearchValue[value.type]}
                      onChange={(e, data) => {
                        dispatch(freeTextSearch({ [value.type]: data.checked }));
                      }}
                      style={{ marginTop: "10px", fontWeight: "bold" }}
                      label={translateFormLabel("Free text search")}
                    />
                  )}
                  {value.type === "services" && (
                    <div
                      className="filter-labels-dropdown"
                      style={{ minWidth: selectedServiceLabels.length === 0 ? "320px" : "150px" }}
                    >
                      <Dropdown
                        text="Filter Services By Labels/Categories"
                        fluid
                        selection
                        multiple
                        onChange={(_, data) => {
                          const { value } = data;
                          setSelectedServiceLabels(value);
                        }}
                        value={selectedServiceLabels}
                        options={serviceLabelOptions}
                        search
                      />
                    </div>
                  )}
                  {value.type === "products" && (
                    <div
                      className="filter-labels-dropdown"
                      style={{ minWidth: selectedProductLabels.length === 0 ? "320px" : "150px" }}
                    >
                      <Dropdown
                        text="Filter Products By Labels/Categories"
                        fluid
                        selection
                        multiple
                        onChange={(_, data) => {
                          const { value } = data;
                          setSelectedProductLabels(value);
                        }}
                        value={selectedProductLabels}
                        options={productLabelOptions}
                        search
                      />
                    </div>
                  )}
                  {value["sub_label"] && (
                    <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                      <Translate>{value["sub_label"]}</Translate>
                    </span>
                  )}
                  {value.validation && value.validation.required && "*"}
                  {value.validation &&
                    value.validation.validate &&
                    formValidationStatus(
                      value.field,
                      value.validation.validate(field_value, isDuplicateNumber, form)
                    ) && (
                      <span className="error" style={{ fontWeight: "bold" }}>
                        <Translate>
                          {" "}
                          (
                          {value.field === "customer.number" && !field_value
                            ? "Enter Mobile Number"
                            : value.validation.error || "Required"}
                          )
                        </Translate>
                      </span>
                    )}
                  {/* commented temporary */}
                  {value.label === "membership" && form?.membership && (
                    <MembershipPopUp membershipId={form.membership} />
                  )}
                  {value.label === "discount" && form?.discount_id && <CampaignPopUp campaignId={form.discount_id} />}
                </div>

                {(!isAppointmentForm || form.datetime) &&
                  inputType(
                    value,
                    setForm,
                    form,
                    field_value,
                    pastCustomer,
                    pastCustomerRevenue,
                    viewCustomerHistory,
                    selectedStaff,
                    viewCustomerNotes,
                    viewPendingPayments,
                    error,
                    edit,
                    setIsDuplicateNumber,
                    viewCustomerDetails,
                    selectedServiceLabels,
                    selectedProductLabels,
                    isExistingAppointment
                  )}
              </Forms.Field>
            );
          })}
        </Forms>
        {availabilityErrorMessages && getAvailabilityErrorMessage(availabilityErrorMessages)}
        <div className="actions-container">
          {action ? (
            <div
              className={`${
                formatted_component_permissions.includes("employee_access") ? "actions-container-btn" : ""
              }`}
            >
              {action(edit, form, valid)}
            </div>
          ) : (
            <div className="actions">
              {cancel && (
                <button className="ui negative button" onClick={() => edit(4, form)}>
                  <Translate>Cancel</Translate>
                </button>
              )}
              {completed && (
                <button className="ui button" onClick={() => edit(3, form)}>
                  <Translate>Completed!</Translate>
                </button>
              )}
              {enquiry && (
                <button className="ui button" onClick={() => edit(0, form)}>
                  <Translate>Enquiry?</Translate>
                </button>
              )}
              <button className="ui positive button" onClick={() => edit(form.status || 1, form)}>
                <Translate>{update ? "Update" : "Create"}</Translate>
              </button>
            </div>
          )}
        </div>
        {[0, 1, 2, 3, 4, 5].includes(form?.status) && isAppointmentForm && (
          <p className={["created-by-text", "text-theme-black"]}>
            <Translate>Created by</Translate> {form?.created_by?.name}
          </p>
        )}
      </div>
    </>
  );
};

export default Form;
