import React from "react";
import "./index.scss";
import Fuse from "fuse.js";
import HoverTable from "HoverTable";
import Description from "Description";
import { Icon, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { campaignFetchData, campaignPostData, campaignPutData } from "../../store/actions/campaign";
import _, { debounce } from "lodash";
import { Search, SideNav, Form as NewComponentForm } from "../../new-components";
import { servicesFetchData } from "../../store/actions/services";
import { productsFetchData } from "../../store/actions/products";
import ViewCampaign from "../../components/Campaign/ViewCampaign";
import CampaignSideNav from "../../components/CampaignSideNav";
import { Translate } from "react-auto-translate";
import { LoadingState } from "../../store/reducers/types";
import Toggler from "../../components/Toggler";
import { PRODUCTS_DATA } from "../../store/actions/products";

const tableHeaders = [
  "Name",
  "Discount on total bill?",
  "Discount on all services?",
  "Discount on all products?",
  "Discount on specific services?",
  "Discount on specific products?",
  "Action",
];

const renderSpecial = [
  ({ name, description, type }) => {
    return <Description bold title={name} description={description} add_on={type} />;
  },
  ({ discount_type }) => {
    return <Description title={discount_type ? "Yes" : "No"} />;
  },
  ({ all_services_discount_type_value }) => {
    return (
      <Description title={all_services_discount_type_value || all_services_discount_type_value === 0 ? "Yes" : "No"} />
    );
  },
  ({ all_products_discount_type_value }) => {
    return (
      <Description title={all_products_discount_type_value || all_products_discount_type_value === 0 ? "Yes" : "No"} />
    );
  },
  ({ services }) => {
    return <Description title={services.length ? "Yes" : "No"} />;
  },
  ({ products }) => {
    return <Description title={products.length ? "Yes" : "No"} />;
  },
  (value, edit, remove) => {
    return value.status === 0 || value.status === false ? (
      <Icon key={0} className="dont" size="large" />
    ) : (
      [
        <Icon key={0} className="pointer" name="eye" size="large" onClick={() => edit(value)} />,
        <Icon key={1} className="pointer" name="trash" size="large" onClick={() => remove(value.id, value)} />,
      ]
    );
  },
];

const initialServiceForm = {
  service_id: "",
  discount_type: 0,
  discount_type_value: 0.0,
  max_discount_amount: 0.0,
};
class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      viewForm: false,
      form: {
        total_bill_discount: true,
        all_services: false,
        specific_services: false,
        specificDates: false,
        services: [
          {
            ...initialServiceForm,
          },
        ],
        products: [],
      },
      searchValue: "",
      activeCampaignOnly: 1,
    };
    this.formikRef = React.createRef();
  }

  componentDidMount() {
    const { services, productsData } = this.props;
    const services_loaded = services.loading === LoadingState.loaded;
    const products_loaded = productsData.loading === LoadingState.loaded;
    this.props.fetchCampaign({});
    if (!services_loaded) {
      this.props.fetchServices();
    }
    if (!products_loaded) {
      this.props.productsFetchData();
    }
  }

  deleteCampaign(id, campaign) {
    campaign = { ...campaign, status: false };
    this.props.putCampaign(id, campaign);
  }

  viewCampaign(campaign) {
    this.setState({
      viewForm: true,
      form: campaign,
    });
  }

  setShowForm = () => {
    this.setState((prevState) => ({
      showForm: !prevState.showForm,
    }));
  };

  //  it is required to get these url, fields, field arguments from calling function
  // otherwise it will use the previous arguments only ex. customer.number

  handleSearchDebounce = debounce(({ status, search }) => {
    this.props.fetchCampaign({ status, search });
  }, 300);

  handleSearch = (e) => {
    const changedValue = e.target.value;
    this.handleSearchDebounce({ status: this.state.activeCampaignOnly, search: changedValue });
    this.setState({ searchValue: changedValue });
  };

  handleToggler = () => {
    const status = this.state.activeCampaignOnly ? 0 : 1;
    this.props.fetchCampaign({ status, search: this.state.searchValue });
    this.setState({
      activeCampaignOnly: status,
    });
  };

  render() {
    const { showForm, form, searchValue, viewForm, activeCampaignOnly } = this.state;
    const { campaign, services: inventoryServices, products: inventoryProducts } = this.props;

    return [
      <div key={0} className="campaign width-100 height-100">
        <SideNav
          condition={viewForm}
          onCross={() =>
            this.setState({
              viewForm: false,
              form: {
                total_bill_discount: true,
                all_services: false,
                specific_services: false,
                specificDates: false,
                services: [
                  {
                    ...initialServiceForm,
                  },
                ],
                products: [],
              },
            })
          }
        >
          <NewComponentForm>
            <NewComponentForm.Title>
              <Translate>Discount</Translate>
            </NewComponentForm.Title>
            <NewComponentForm.Divider> </NewComponentForm.Divider>
            <ViewCampaign
              campaign={form}
              inventoryServices={inventoryServices.data}
              inventoryProducts={inventoryProducts}
            />
          </NewComponentForm>
        </SideNav>
        <CampaignSideNav showForm={showForm} setShowForm={this.setShowForm} form={form} />
        <div className="controller">
          <h2 className="title">
            <Translate>Discounts</Translate>
          </h2>
          <div className="search-add">
            <Search>
              <Search.GeneralField value={searchValue} onChange={this.handleSearch} placeholder="Search discount" />
            </Search>
            <button className="addBtn" onClick={() => this.setState({ showForm: true })}>
              <Icon className="add" /> <Translate>Add</Translate>
            </button>
          </div>
        </div>
        <HoverTable
          header={tableHeaders}
          src={campaign?.results || []}
          renderSpecial={renderSpecial}
          edit={(campaign) => this.viewCampaign(campaign)}
          remove={(id, campaign) => this.deleteCampaign(id, campaign)}
          showDeleted={!activeCampaignOnly}
        />
        <div className="pages flex width100">
          <Pagination
            defaultActivePage={1}
            firstItem={false}
            lastItem={false}
            activePage={campaign?.current_page || 1}
            totalPages={campaign?.pages || 1}
            onPageChange={(event, val) => {
              this.props.fetchCampaign({
                page: val.activePage,
                status: activeCampaignOnly,
                search: this.state.searchValue,
              });
            }}
          />
        </div>
      </div>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.services,
    productsData: state.products,
    products: PRODUCTS_DATA(state),
    campaign: state.campaign,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaign: (params) => dispatch(campaignFetchData(params)),
    postCampaign: (campaign) => dispatch(campaignPostData(campaign)),
    putCampaign: (id, campaign) => dispatch(campaignPutData(id, campaign)),
    fetchServices: () => dispatch(servicesFetchData()),
    productsFetchData: () => dispatch(productsFetchData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
