const init = {
  countries: {
    data: [],
    isFetching: false,
  },
  states: {
    data: [],
    isFetching: false,
  },
  cities: {
    data: [],
    isFetching: false,
  },
};

export function addressForm(state = init, action) {
  switch (action.type) {
    case "order/addresses/countries": {
      return {
        ...state,
        countries: { ...state.countries, data: action.data, isFetching: action.isFetching },
      };
    }

    case "order/addresses/states": {
      return {
        ...state,
        states: { ...state.states, data: action.data, isFetching: action.isFetching },
      };
    }

    case "order/addresses/cities": {
      return {
        ...state,
        cities: { ...state.cities, data: action.data, isFetching: action.isFetching },
      };
    }

    default:
      return state;
  }
}
