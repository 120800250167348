import { API_BASE_URL, PRODUCTS_API, COMPANIES_API, LABELS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import lodash from "lodash";
import Fuse from "fuse.js";
import { createSelector } from "reselect";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { productActions, errorMessage, productLabelsActions } from "../../utilities/actionMessages";
import { LoadingState } from "../reducers/types";

export function productsFetchDataSuccess(products) {
  return {
    type: "PRODUCTS_FETCH_DATA_SUCCESS",
    data: { data: products, loading: LoadingState.loaded },
  };
}

export function productsMapCreationSuccess(products) {
  // for better searching the products data by product id
  const productsMap = lodash.keyBy(products, "id");

  return {
    type: "PRODUCTS_MAP_CREATION_SUCCESS",
    productsMap,
  };
}

export function productsFetchData() {
  return (dispatch) => {
    dispatch(startLoading("PRODUCTS_LOADER", "loading products"));
    axios
      .get(API_BASE_URL + PRODUCTS_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          const products = response.data.results;

          dispatch(productsFetchDataSuccess(products));
          dispatch(productsMapCreationSuccess(products));

          dispatch(endLoading("PRODUCTS_LOADER"));
        } else {
          dispatch(errored("PRODUCTS_LOADER", true));
          dispatch(endLoading("PRODUCTS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("PRODUCTS_LOADER", true));
        dispatch(endLoading("PRODUCTS_LOADER"));
      });
  };
}

export function productsPostData(products) {
  return (dispatch) => {
    dispatch(startLoading("PRODUCTS_LOADER", "loading products"));
    axios({
      method: "post",
      url: API_BASE_URL + PRODUCTS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: products,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: productActions.success_create, type: "success" });
          dispatch(productsFetchData());
          dispatch(endLoading("PRODUCTS_LOADER"));
        } else {
          dispatch(errored("PRODUCTS_LOADER", true));
          dispatch(endLoading("PRODUCTS_LOADER"));
        }
      })
      .catch(function (error) {
        const errorString = error?.response?.data ? JSON.stringify(error?.response?.data) : errorMessage;
        ToastAlert({ message: errorString, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("PRODUCTS_LOADER", true));
        dispatch(endLoading("PRODUCTS_LOADER"));
      });
  };
}

const errorDetailsFormattor = (error) => {
  let message = [];

  if (error?.response?.data) {
    Object.entries(error.response.data).map((err) => {
      message.push(err[0] + " : " + err[1][0]);
    });
  }
  return message.join(",    ");
};

export function productsPutData(id, product) {
  return (dispatch) => {
    dispatch(startLoading("PRODUCTS_LOADER", "loading products"));
    axios({
      method: "put",
      url: API_BASE_URL + PRODUCTS_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: product,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.status) {
            ToastAlert({ message: productActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: productActions.success_update, type: "success" });
          }
          dispatch(productsFetchData());
          dispatch(endLoading("PRODUCTS_LOADER"));
        } else {
          dispatch(errored("PRODUCTS_LOADER", true));
          dispatch(endLoading("PRODUCTS_LOADER"));
        }
      })
      .catch(function (error) {
        const error_message = errorDetailsFormattor(error);

        ToastAlert({ message: error_message || errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("PRODUCTS_LOADER", true));
        dispatch(endLoading("PRODUCTS_LOADER"));
      });
  };
}

//
export const postProductLabels = (payload) => {
  let URL = API_BASE_URL + LABELS_API + "product/";

  return (dispatch) => {
    axios
      .post(URL, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        ToastAlert({ message: productLabelsActions.success_update, type: "success" });
        dispatch(productsFetchData());
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
      });
  };
};

const productsSelector = (state) => state.products;
const companiesSelector = (state) => state.companies.data || [];
const productActiveToggleSelector = (state) => state.productActiveToggle ?? true;
const productDataSearchSelector = (state) => state.productDataSearch ?? "";

export const PRODUCTS_DATA = createSelector([productsSelector, companiesSelector], (products, companies) => {
  const companiesMap = lodash.keyBy(companies, "id");
  const formattedProducts = products.data.map((product) => {
    return {
      ...product,
      company: product.company in companiesMap ? companiesMap[product.company] : null,
    };
  });
  return formattedProducts;
});

export const PRODUCTS_TABLE_DATA = createSelector(
  [productsSelector, companiesSelector, productActiveToggleSelector, productDataSearchSelector],
  (products, companies, productActiveToggle, productSearch) => {
    const companiesMap = lodash.keyBy(companies, "id");
    const formattedProducts = products.data
      .filter((product) => (productActiveToggle ? product.status : product.status === "0" || !product.status))
      .map((product) => {
        return {
          ...product,
          company: product.company in companiesMap ? companiesMap[product.company] : null,
        };
      });

    const fuse = new Fuse(formattedProducts, { keys: ["name"], threshold: 0.2 });
    const results = fuse.search(productSearch).map(({ item }) => item);

    if (formattedProducts.length > 0 && productSearch.length > 0) {
      return { data: results, loading: products.loading };
    } else {
      return { data: formattedProducts, loading: products.loading };
    }
  }
);

export function productActiveToggleAction(state) {
  return {
    type: "PRODUCTS_ACTIVE_TOGGLE_SUCCESS",
    toggle: state,
  };
}

export function productDataSearch(state) {
  return {
    type: "PRODUCTS_DATA_SEARCH",
    search: state,
  };
}
