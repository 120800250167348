import { API_BASE_URL, LABELS_API } from "utilities";
import { customersFetchData } from "./customer";
import axios from "axios";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { labelActions, customerLabelsActions, errorMessage } from "../../utilities/actionMessages";

export function appointmentsFetchDataSuccess(appointments) {
  return {
    type: "LABELS_FETCH_DATA_SUCCESS",
    appointments: appointments,
  };
}

export const customerLabelReset = () => {
  return {
    type: "CUSTOMER_LABELS_RESET",
    response: {
      data: [],
      isFetching: false,
    },
  };
};

export const postCustomerLabels = (payload, getPagePayload, pageType) => {
  let URL = API_BASE_URL + LABELS_API + "customer/";
  return (dispatch) => {
    axios
      .post(URL, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        ToastAlert({ message: customerLabelsActions.success_update, type: "success" });
        const { search = "", clientFilter = "", currentPage = 1, selectedLabels = [] } = getPagePayload;
        switch (pageType) {
          case "appointmentForm":
            dispatch({
              type: "CUSTOMER_LABELS_FETCH_DATA_SUCCESS",
              response: {
                data: response.data || [],
                isFetching: false,
              },
            });
            break;

          default:
            dispatch(customersFetchData(search, clientFilter, currentPage, selectedLabels));
            break;
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
      });
  };
};

// export const getLabels = () => {
//   let URL = API_BASE_URL + LABELS_API;

//   return (dispatch) => {
//     dispatch({
//       type: "LABELS_FETCH_DATA",
//       response: { isFetching: true },
//     });
//     axios
//       .get(URL, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       })
//       .then(function (response) {
//         dispatch({
//           type: "LABELS_FETCH_DATA_SUCCESS",
//           response: {
//             data: response.data?.results || [],
//             isFetching: false,
//           },
//         });
//       })
//       .catch(function (error) {
//         dispatch({
//           type: "LABELS_FETCH_DATA_FAIL",
//           response: { data: [], isFetching: false, error: true },
//         });
//       });
//   };
// };

// export const postLabels = (payload) => {
//   let URL = API_BASE_URL + LABELS_API;
//   return (dispatch) => {
//     axios
//       .post(URL, payload, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       })
//       .then(function (response) {
//         ToastAlert({ message: labelActions.success_create, type: "success" });
//         dispatch(getLabels());
//       })
//       .catch(function (error) {
//         ToastAlert({ message: errorMessage, type: "error" });
//       });
//   };
// };

// export const putLabels = (id, payload) => {
//   let URL = API_BASE_URL + LABELS_API + id + "/";
//   return (dispatch) => {
//     axios
//       .put(URL, payload, {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       })
//       .then(function (response) {
//         if (!response.data.status) {
//           ToastAlert({ message: labelActions.success_delete, type: "success" });
//         } else {
//           ToastAlert({ message: labelActions.success_update, type: "success" });
//         }
//         dispatch(getLabels());
//       })
//       .catch(function (error) {
//         ToastAlert({ message: errorMessage, type: "error" });
//       });
//   };
// };
