export const staffServiceRevenueReducer = (state = [], action) => {
  switch (action.type) {
    case "STAFF_SERVICE_REVENUE_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};

export const staffProductRevenueReducer = (state = [], action) => {
  switch (action.type) {
    case "STAFF_PRODUCT_REVENUE_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};

export const staffMembershipReducer = (state = [], action) => {
  switch (action.type) {
    case "STAFF_MEMBERSHIP_REVENUE_SUCESS":
      return action.payload;
    default:
      return state;
  }
};
