const initalState = {
  loading: false,
  errorMessage: "",
  data: [],
  pages: 1,
  current_page: 1,
};

export const productListReducer = (state = initalState, action) => {
  switch (action.type) {
    case "PRODUCT_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "PRODUCT_LIST_SUCCESS": {
      return {
        ...state,
        data: action.payload.results,
        pages: action.payload.pages,
      };
    }
    default:
      return state;
  }
};

export const ProductDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case "PRODUCT_DETAIL_SUCCESS":
      return action.payload;

    default:
      return state;
  }
};
