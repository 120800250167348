import axios from "axios";
import { API_BASE_URL, STAFF_ADVANCE_API, STAFF_ADVANCE_BALANCE_API } from "../../utilities";
import { authenticationLogout } from "./authentication";

function staffAdavanceSuccess(staffAdvance, id) {
  const { count, current_page, pages, results } = staffAdvance;

  return {
    type: "STAFF_ADVANCE_SUCCESS",
    response: {
      count,
      current_page,
      pages,
      data: results,
      id,
    },
  };
}

function staffAdavanceBalanceSuccess(balance) {
  return {
    type: "STAFF_ADVANCE_BALANCE_SUCCESS",
    response: {
      balance,
    },
  };
}

function staffAdvanceLoading() {
  return {
    type: "STAFF_ADVANCE_LOADING",
  };
}

function staffAdvanceFailed() {
  return {
    type: "STAFF_ADVANCE_FAILED",
  };
}

function staffAdavanceBalanceFailed() {
  return {
    type: "STAFF_ADVANCE_BALANCE_FAILED",
    response: {
      balance: 0,
    },
  };
}

export function resetStaffAdvanceStore() {
  return {
    type: "STAFF_ADVANCE_RESET",
  };
}

export function getStaffAdvance({ id, page = 1, start_date, end_date }) {
  const params = {
    search: id,
    page,
    start_date,
    end_date,
  };

  const url = `${API_BASE_URL}${STAFF_ADVANCE_API}`;

  return (dispatch) => {
    dispatch(staffAdvanceLoading());

    axios({
      method: "GET",
      url: url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params,
    })
      .then((response) => {
        dispatch(staffAdavanceSuccess(response.data, id));
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(staffAdvanceFailed());
      });
  };
}

export function postStaffAdvance(data, filters) {
  const url = `${API_BASE_URL}${STAFF_ADVANCE_API}`;

  return (dispatch) => {
    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
      .then(() => {
        dispatch(getStaffAdvance({ id: data.staff, ...filters }));
        dispatch(getStaffAdvanceBalance(data.staff));
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function getStaffAdvanceBalance(staff_id) {
  const params = {
    staff_id,
  };

  const url = `${API_BASE_URL}${STAFF_ADVANCE_BALANCE_API}?`;

  return (dispatch) => {
    axios({
      method: "GET",
      url: url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params,
    })
      .then((response) => {
        dispatch(staffAdavanceBalanceSuccess(response.data.balance));
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(staffAdavanceBalanceFailed());
      });
  };
}
