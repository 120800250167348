const initialBaseState = {
  data: [],
  isFetching: false,
};

export function aclBasePermissionsReducer(state = initialBaseState, action) {
  switch (action.type) {
    case "GET_BASE_PERMISSIONS_API_FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "GET_BASE_PERMISSIONS_API_SUCCESS":
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };

    case "GET_BASE_PERMISSIONS_API_FAILURE":
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}

const initialComponentsState = {
  data: [],
  isFetching: false,
};

export function aclComponentsPermissionsReducer(state = initialComponentsState, action) {
  switch (action.type) {
    case "GET_COMPONENTS_PERMISSIONS_API_FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "GET_COMPONENTS_PERMISSIONS_API_SUCCESS":
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };

    case "GET_COMPONENTS_PERMISSIONS_API_FAILURE":
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
