import { API_BASE_URL } from "utilities";
import { endLoading, errored } from "./withLoader";
import axios from "axios";

export const cartData = () => {
  let URL = API_BASE_URL + "marketplace/orders/cart/";

  return async (dispatch) => {
    // dispatch(startLoading("PRODUCTS_LOADER", "Loading Cart..."));
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "CART_LIST_SUCCESS",
        payload: res.data,
      });
      dispatch(endLoading("PRODUCTS_LOADER"));
    } catch (error) {
      dispatch(errored("PRODUCTS_LOADER", true));
      dispatch(endLoading("PRODUCTS_LOADER"));
    }
  };
};

export const updateCartProductQuantity = (data, id, num, quantity, unit_cost) => {
  data = {
    variant: {
      ...data,
      size: 50,
      unit: "ml",
    },
    quantity: quantity + num,
    unit_cost,
  };

  let URL = `${API_BASE_URL}marketplace/orders/cart/${id}/`;

  return async (dispatch) => {
    try {
      const res = await axios.put(URL, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "CART_UPDATE_SUCCESS",
        payload: {
          status: "success",
          data: res.data,
        },
      });
    } catch (error) {
      dispatch(errored("PRODUCTS_LOADER", true));
    }
  };
};

export const deleteCartProduct = (id) => {
  let URL = `${API_BASE_URL}marketplace/orders/cart/${id}/`;

  return async (dispatch) => {
    try {
      const res = await axios.delete(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({
        type: "CART_ITEM_DELETE_SUCCESS",
        payload: {
          status: "success",
          data: res.data,
        },
      });
    } catch (error) {
      dispatch(errored("PRODUCTS_LOADER", true));
    }
  };
};

export const addCartItem = (item) => {
  let URL = `${API_BASE_URL}marketplace/orders/cart/`;

  return async (dispatch) => {
    try {
      const res = await axios.post(URL, item, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({
        type: "CART_ITEM_ADDED_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch(errored("PRODUCTS_LOADER", true));
    }
  };
};
