const init = {
  data: [],
  isFetching: false,
  error: false,
};

export function labels(state = init, action) {
  switch (action.type) {
    case "LABELS_FETCH_DATA": {
      return {
        ...state,
        ...action.response,
      };
    }
    case "LABELS_FETCH_DATA_SUCCESS": {
      return {
        ...state,
        ...action.response,
      };
    }
    case "LABELS_FETCH_DATA_FAIL": {
      return {
        ...state,
        ...action.response,
      };
    }
    default:
      return state;
  }
}

const initCustomerLabels = {
  data: [],
  isFetching: false,
  error: false,
};

export function customerLabels(state = initCustomerLabels, action) {
  switch (action.type) {
    case "CUSTOMER_LABELS_FETCH_DATA_SUCCESS": {
      return {
        ...state,
        ...action.response,
      };
    }
    case "CUSTOMER_LABELS_RESET": {
      return {
        ...state,
        ...action.response,
      };
    }
    default:
      return state;
  }
}
