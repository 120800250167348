import { API_BASE_URL } from "utilities";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export function postAppointmentDirectDiscount(payload) {
  const url = API_BASE_URL + "appointment/direct/discount/";
  return async (dispatch) => {
    dispatch({
      type: "APPOINTMENT_DIRECT_DISCOUNT_LOADING",
    });
    try {
      await axios.post(url, payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      ToastAlert({ message: "Successfully added Discount.", type: "success" });
      dispatch({
        type: "POST_APPOINTMENT_DIRECT_DISCOUNT_SUCCESS",
      });
      dispatch(getAppointmentDirectDiscount(payload.appointment));
    } catch (error) {
      const errorMsg = error?.response?.data?.discount[0] || "Something went wrong.Please try again later";
      ToastAlert({ message: errorMsg, type: "error" });
      dispatch({
        type: "POST_APPOINTMENT_DIRECT_DISCOUNT_FAIL",
      });
    }
  };
}

export function getAppointmentDirectDiscount(id) {
  const url = API_BASE_URL + "appointment/direct/discount/?appointment_id=" + id;
  return async (dispatch) => {
    dispatch({
      type: "GET_APPOINTMENT_DIRECT_DISCOUNT_LOADING",
      response: { data: [] },
    });
    try {
      const res = await axios(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: "GET_APPOINTMENT_DIRECT_DISCOUNT_SUCCESS",
        response: { data: res.data.results },
      });
    } catch (error) {
      ToastAlert({ message: "Something went wrong with direct discount.Please try again later.", type: "error" });
      dispatch({
        type: "GET_APPOINTMENT_DIRECT_DISCOUNT_FAIL",
        response: { data: [] },
      });
    }
  };
}
