import React from "react";
import { useDispatch } from "react-redux";
import festival from "../../cdn/festival.png";
import { FestivalListModal } from "../FestivalListModal";
import { ModalOpenCloseUpdates } from "../../store/actions/festival";
import { Icon } from "semantic-ui-react";

export const Festival = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Icon name="checked calendar" size="large" onClick={() => dispatch(ModalOpenCloseUpdates(true))} />
      <FestivalListModal />
    </>
  );
};
