const init = {
  data: "",
};

export function language(state = init, action) {
  switch (action.type) {
    case "SET_LANGUAGE": {
      return {
        ...state,
        ...action.response,
      };
    }
    default:
      return state;
  }
}
