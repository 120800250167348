const defaultState = {
  loading: false,
  results: [],
  pages: 0,
  current_page: 0,
  error: false,
  weeklyData: [],
  aggregated: {
    today: {
      credit: 0,
      debit: 0,
    },
    monthly: {
      credit: 0,
      debit: 0,
    },
    selected: {
      credit: 0,
      debit: 0,
    },
  },
};

export function branchWallet(state = defaultState, action) {
  switch (action.type) {
    case "BRANCH_WALLET_FETCH_DATA_SUCCESS":
      return { ...state, ...action.branchWallet, weeklyData: action.branchWallet.results };
    case "BRANCH_WALLET_FETCH_DATA_LOADING":
      return action.branchWallet;
    case "BRANCH_WALLET_FETCH_DATA_ERROR":
      return action.branchWallet;
    case "BRANCH_WALLET_AGGREGATED_DATA":
      return {
        ...state,
        aggregated: action.data,
      };

    default:
      return state;
  }
}
