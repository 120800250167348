import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { branchFetchLocation, updateLocation } from "../../store/actions/location";

import "./index.scss";
import Map from "./Map";

const Location = () => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const [field, update] = useState({
    address: "",
    lat: "",
    lng: "",
    reset: false,
  });
  const [message, setMessage] = useState(false);

  useEffect(() => {
    dispatch(branchFetchLocation());
  }, []);

  useEffect(() => {
    update({
      address: location.address || "",
      lat: location.lat || "",
      lng: location.long || "",
      gAddress: location.google_address_string || "",
    });
  }, [location]);

  const timedMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <>
      <div className="location">
        <div className="geo">
          <Form.Group>
            <label className="label">Location &nbsp;</label>
            <address>
              <Form.Input
                fluid
                type="text"
                value={field.address}
                placeholder="Enter address"
                onChange={(e) => update({ ...field, address: e.target.value })}
              />
              <Form.Input
                fluid
                type="text"
                placeholder="Google fetched Address"
                value={field.gAddress || ""}
                disabled
              />
              <Form.Input fluid type="text" placeholder="GPS Location" value={`${field.lat}, ${field.lng}`} disabled />
            </address>

            {field.reset ? (
              <div className="btn_controller">
                <Button
                  color="primary"
                  onClick={() => {
                    const payload = {
                      address: field.address,
                      google_address_string: field.gAddress,
                      lat: field.lat,
                      long: field.lng,
                    };
                    dispatch(updateLocation(payload));
                  }}
                  disabled={field.lat === "" || field.lng === ""}
                >
                  Update Location
                </Button>
                {message && <p className="info">{message}</p>}
              </div>
            ) : (
              <div className="btn_controller">
                <Button color="primary" onClick={() => update({ ...field, reset: !field.reset })}>
                  Reset Location
                </Button>
              </div>
            )}
          </Form.Group>
        </div>
        <div className="map-container">
          {field.reset && (
            <Map
              update={(position, address) => {
                update({
                  ...field,
                  lat: parseFloat(position.lat()).toFixed(4),
                  lng: parseFloat(position.lng()).toFixed(4),
                  gAddress: address ? address : field.gAddress,
                });
                timedMessage("Geolocation updated");
              }}
              position={{
                lat: field.lat,
                lng: field.lng,
                address: field.gAddress,
              }}
            />
          )}
        </div>
      </div>
       ̰
    </>
  );
};

export default Location;
