import React, { useState } from "react";
import "./index.scss";

import HoverTable from "HoverTable";
import Modal from "Modal";
import Form from "Form";
import CustomSearch from "CustomSearch";
import Description from "Description";
import Ellipsis from "Ellipsis";

import mockData from "../../mockData";
import {
  Checkbox,
  Icon,
  Form as Forms,
  Select,
  Button,
  Tab,
  Radio,
  Dropdown,
  Input,
  Pagination,
  Confirm,
} from "semantic-ui-react";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  commissionFetchData,
  commissionPatchData,
  commissionPostData,
  staffCommission,
  staffFetchData,
  staffPostData,
  staffPutData,
  updateStaffStatusReset,
} from "../../store/actions/staff";
import { shiftsFetchData, shiftsPostData, shiftsPutData } from "../../store/actions/shifts";
import { POSITION_DATA, positionsFetchData, positionsPostData, positionsPutData } from "../../store/actions/positions";
import { servicesFetchData } from "../../store/actions/services";
import { connect } from "react-redux";
import { cloneDeep, debounce } from "lodash";

import { SideNav, Form as NewComponentForm, Search } from "../../new-components";
import StaffRevenue from "../../components/StaffRevenue/StaffRevenue";
import {
  getReceptionistStaffAttendance,
  staffAttendanceDeleteData,
  staffAttendanceFetchData,
  staffAttendancePatchData,
  staffAttendancePostData,
} from "../../store/actions/staffAttendance";
import {
  deactivateStaffSalaryBracket,
  activeSalaryBracketFetch,
  staffSalaryPostData,
  updateStaffSalaryBracket,
  staffSalaryBracketFetch,
} from "../../store/actions/salaryBracket";
import {
  confirmStaffSalary,
  fetchConfirmedStaffSalary,
  fetchStaffSalary,
  resetStaffSalary,
} from "../../store/actions/staffSalary";
import {
  staffOvertimeFetchData,
  staffOvertimePatchData,
  staffOvertimePostData,
  staffOvertimeDeleteData,
} from "../../store/actions/overtime";
import { getAllGroupsPermissions, getGroupPermissions } from "../../store/actions/aclGroupPermissions";

import { getBasePermissions, getComponentsPermissions } from "../../store/actions/aclPermissionsOptions";

import Attendance from "../../components/Roster/Attendance";
import OvertimeDOM from "./overtimeDOM";
import { findObjectById } from "../../utilities";
import CreateStaffSalaryBrackets from "../../components/Staff/CreateStaffSalaryBrackets";
import AssignStaffSalary from "../../components/Staff/AssignStaffSalary";
import {
  GetEndDate,
  GetStartDate,
  formatTimeByUnit,
  combineTimeAndUnit,
  validatePercentage,
} from "../../utilities/CommonFunctions";
import PrintStaffSalary from "../../components/Staff/PrintStaffSalary";

import Toggler from "../../components/Toggler/index";
import AclSidebarView from "./aclSidebarView";
import { AiOutlineCloudDownload } from "react-icons/ai";
import exportFromJSON from "export-from-json";
import { Translate } from "react-auto-translate";
import StaffAdvanceView from "./StaffAdvanceView/StaffAdvanceView";
import { resetStaffAdvanceStore } from "../../store/actions/staffAdvance";

const staffHeaders = [
  "Details",
  "Position/Services",
  "Date Of Joining",
  "Shift Timings/Weekly Offs",
  "Commission Product/Service",
  "Advance",
  "Overtime",
  "Salary",
  "Revenue",
  "Attendance",
  "Action",
];

const attendanceTypes = [
  {
    value: 0,
    text: "Quarter Day Absent",
  },
  {
    value: 1,
    text: "Half day Absent",
  },
  {
    value: 2,
    text: "Full Day Absent",
  },
];

const shiftHeaders = ["start time", "end time", "weekly offs", "Action"];
const positionHeaders = ["designation", "services", "Action"];
const attendanceHeaders = ["Date", "Staff", "Leave Type", "Paid/Unpaid", "Action"];

const shiftRenderSpecial = [
  ({ start_time }) => {
    return <Description title={start_time} />;
  },
  ({ end_time }) => {
    return <Description title={end_time} />;
  },
  ({ weekly_offs }) => {
    return <Description title={weekly_offs} />;
  },
  (value, edit, remove) => {
    return value.status === 0 ? (
      <Icon key={0} className="dont" />
    ) : (
      [<Icon key={0} className="pointer trash" onClick={() => remove(value.id, value)} />]
    );
  },
];

const positionRenderSpecial = [
  ({ name }) => {
    return <Description title={name} />;
  },
  ({ all_services, services, status }) => {
    let position_services = "";
    if (all_services) {
      position_services = "All Services";
    } else {
      if (status) {
        position_services = services.reduce((prev, curr) => [...prev, curr.name], []).join(", ");
      } else {
        //no filteration on past/deleted position
        position_services = services.reduce((prev, curr) => [...prev, curr.name], []).join(", ");
      }
    }
    return <Ellipsis text={position_services} />;
  },
  (value, edit, remove) => {
    return value.status === 0 ? (
      <Icon key={0} className="dont" />
    ) : (
      [
        <Icon key={0} className="pointer" name="edit" onClick={() => edit(value.id, value)} />,
        <Icon key={1} className="pointer trash" onClick={() => remove(value.id, value)} />,
      ]
    );
  },
];

const attendanceRenderSpecial = [
  ({ absent_date }) => {
    return <Description title={absent_date} />;
  },
  ({ staff }) => {
    return <Description title={staff.user.name} />;
  },
  ({ absence_type }) => {
    if (absence_type === 0) return <Description title={<Translate>Quarter Day Absent</Translate>} />;
    else if (absence_type === 1) return <Description title={<Translate>Half day Absent</Translate>} />;
    else if (absence_type === 2) return <Description title={<Translate>Full Day Absent</Translate>} />;
  },
  ({ paid }) => {
    return paid ? <Description title="Paid" /> : <Description title="Un-Paid" />;
  },
  (value, edit, remove) => {
    return value.status === 0 ? (
      <Icon key={0} className="dont" />
    ) : (
      [
        <Icon key={0} className="pointer" name="edit" onClick={() => edit(value.id, value)} />,
        <Icon key={1} className="pointer trash" onClick={() => remove(value.id, value.absent_date)} />,
      ]
    );
  },
];

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

const pageSize = 15;

class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isStaffAddFieldError: false,
      isShiftAddFieldError: false,
      isPositionFieldError: false,
      salaryBracketFieldError: false,
      branch: null,
      addStaff: false,
      addStaffForm: mockData.staff_form,
      addShift: false,
      addCommission: false,
      addShiftForm: mockData.shift_form,
      staffCommissionForm: mockData.commission_form,
      staffFormData: {},
      edit: false,
      addPosition: false,
      accessControlSidebar: false,
      rawStaffSalary: {},
      positionShowBracket: true,
      salaryShowBracket: true,
      positionFormData: {
        editPosition: false,
        editPositionID: -1,
        positionName: "",
        selectedPositions: [],
        positionSelectAllServices: false,
      },
      shiftData: {},

      activeStaffOnly: true,

      addAttendance: false,
      attendanceFormData: {
        selectedMonth: new Date().toISOString().slice(0, 7),
        selectedStaff: { user: { name: "Staff" } },
        selectedDate: new Date().toISOString().slice(0, 10),
        attendanceEditMode: false,
        selectedAbsentType: 0,
        attendanceEditID: 0,
        paid: true,
      },
      showRevenue: false,
      staffDetail: {},
      showStaffAdvanceModal: false,
      staffAdvanceId: null,
      showSalary: false,
      showOvertime: false,
      overtimeStaffDetails: null,
      paySlipShowStaff: {},
      staffSalaryForm: {
        fetched: false,
        name: "",
        selectedMonth: "",
        showExisting: true,
        selectedStaff: null,
        earningsSectionOpen: true,
        staffSalaryError: false,
      },
      addStaffPackage: false,
      assignNewCommission: {
        bracket: "",
        start_date: null,
      },
      assignNewSalary: {
        bracket: "",
        start_date: null,
      },
      leavesEarningComponent: [],
      leavesDuductionComponent: [],
      openPaySlip: false,
      selectedPage: 1,
      search: "",
    };
  }

  componentDidMount() {
    this.updateDomData();
  }

  updateDomData() {
    const { formatted_component_permissions } = this.props.aclUserPermissions;
    const { search, selectedPage, activeStaffOnly } = this.state;
    const status = activeStaffOnly ? 1 : 0;
    if (
      formatted_component_permissions.includes("admin_access") ||
      formatted_component_permissions.includes("owner_access")
    ) {
      const branch_id = this.state?.branch ? this.state?.branch : this.props?.branch.id;
      this.props.history.push({
        search: branch_id ? "branch_id=" + branch_id : null,
      });
      this.props.fetchShifts();
      this.props.fetchPositions();
    }

    this.props.fetchStaff(search, pageSize, selectedPage, status);
    this.props.getAllGroupsPermissions();
    this.props.getBasePermissions();
    this.props.getComponentsPermissions();
    this.props.fetchCommissions();
    this.props.fetchSalaryBrackets();
  }

  showSalarySidenav() {
    this.setState({
      addStaffPackage: true,
      salaryShowBracket: true,
    });
  }

  closeSalarySidenav() {
    this.setState({
      showSalary: false,
      rawStaffSalary: {},
      staffSalaryForm: {
        fetched: false,
        selectedStaff: null,
      },
    });
    this.props.resetStaffSalary();
  }

  addCommissionModal() {
    this.setState({
      addCommission: !this.state.addCommission,
    });
  }

  addAttendanceSideNav() {
    /** This function is no longer used - to be deprecated  */
    const today = new Date();
    this.setState({
      addAttendance: true,
      attendanceFormData: {
        selectedMonth: today.toISOString().slice(0, 7),
        selectedStaff: { user: { name: "Staff" } },
        selectedDate: today.toISOString().slice(0, 10),
        attendanceEditMode: false,
        selectedAbsentType: 0,
        attendanceEditID: 0,
        paid: true,
      },
    });
    this.props.fetchAttendance(GetStartDate(today), GetEndDate(today), this.state.attendanceFormData.selectedStaff.id);
  }

  addStaffModal() {
    this.setState({
      addStaff: !this.state.addStaff,
      edit: false,
      staffFormData: {},
    });
  }

  addShiftModal() {
    if (this.state.addShift) {
      //shift form field data will be cleared after closing the form
      this.setState({ shiftData: {} });
    }
    this.setState({
      addShift: !this.state.addShift,
    });
  }

  addPositionModal() {
    this.resetPosition();
    this.setState({
      addPosition: !this.state.addPosition,
      positionShowBracket: true,
    });
    this.props.fetchServices();
  }

  resetPosition() {
    this.setState({
      addPosition: false,
      positionFormData: {
        editPosition: false,
        editPositionID: -1,
        positionName: "",
        selectedPositions: [],
        positionSelectAllServices: false,
      },
    });
  }

  closePackageSideNav() {
    this.setState({
      addStaffPackage: false,
    });
  }

  formatEditStaff = (staff) => {
    return staff;
  };

  editStaff = (staff) => {
    this.props.staffCommission(staff.id);
    this.props.fetchStaffSalaryBrackets(staff.id);
    this.setState({
      staffFormData: this.formatEditStaff(staff),
      edit: true,
      addStaff: true,
    });
  };

  undoStaff = (id, staff) => {
    staff.status = 1;
    this.props.staffPutData(id, { ...staff, status: 1 });

    this.setState({
      addStaff: false,
      staffFormData: {},
    });
  };

  deleteStaff = (id, staff) => {
    staff.status = 0;
    this.props.staffPutData(id, staff);

    this.setState({
      addStaff: false,
      staffFormData: {},
    });
  };

  staffAttendanceFormat() {
    const formattedStaffAttendance = this.props.staffAbsents.map((attendance) => {
      return {
        ...attendance,
        staff: this.state.attendanceFormData.selectedStaff,
      };
    });

    return formattedStaffAttendance;
  }

  shiftsDataFormat(shifts) {
    let formattedData = [];
    shifts.forEach((elements) => {
      const { id, start_time, end_time, status } = elements;
      if (status === 1) {
        formattedData.push({
          value: id,
          key: id,
          text: `${start_time} - ${end_time}`,
          ...elements,
        });
      }
    });
    return formattedData;
  }

  bracketsDataFormat() {
    const { brackets } = this.props;
    return brackets.map(({ id, name }) => {
      return {
        value: id,
        key: id,
        text: name,
      };
    });
  }

  positionsDataFormat(positions) {
    let formattedData = [];
    positions.forEach((elements) => {
      const { id, name, status } = elements;
      if (status === 1) {
        formattedData.push({
          value: id,
          key: id,
          text: name,
          // ...elements,
        });
      }
    });
    return formattedData;
  }

  servicesDataFormat(services) {
    let formattedServices = [];
    services.forEach((elements) => {
      const { id, name, gender, time, time_unit, cost } = elements;
      const formattedTimeByUnit = formatTimeByUnit(time, time_unit);
      const combinedTimeAndUnit = combineTimeAndUnit(formattedTimeByUnit, time_unit);
      formattedServices.push({
        value: id,
        key: id,
        text: `${name} | ${gender} | ${combinedTimeAndUnit} | ${cost}/-`,
        ...elements,
      });
    });
    return formattedServices;
  }

  commissionsDataFormat(commissions) {
    return commissions
      .filter((i) => i.active)
      .map((ele) => ({
        value: ele.id,
        key: ele.id,
        text: `${ele.name}` + `${ele.duration_type === 0 ? "-weekly" : "-monthly"}`,
        ...ele,
      }));
  }

  staffSalaryBracketFormat = () => {
    const { staff_salary_bracket_form } = mockData;
    const brackets = this.bracketsDataFormat();

    return staff_salary_bracket_form.map((ele) => {
      if (ele.label === "Bracket") {
        return {
          ...ele,
          options: brackets,
        };
      } else {
        return ele;
      }
    });
  };

  staffCommissionFormFormat = (form) => {
    let { commissions } = this.props;
    commissions = this.commissionsDataFormat(commissions.data.results);

    return form.map((ele) => {
      if (ele.label === "Bracket") {
        return {
          ...ele,
          options: commissions,
          label: "Commission",
        };
      } else {
        return ele;
      }
    });
  };

  staffFormFormat = (staffForm, formData) => {
    let { shifts, positions, aclAllGroupsPermissions } = this.props;
    shifts = this.shiftsDataFormat(shifts);
    positions = this.positionsDataFormat(positions);
    let formattedStaffForm = [];

    const filteredPermissions = aclAllGroupsPermissions.filter((permission) => permission.is_active);
    const accessTypes = this.formatAccessTypeDropdownOptions(filteredPermissions);

    staffForm.forEach((elements) => {
      if (elements.label === "shift") {
        formattedStaffForm.push({
          ...elements,
          options: shifts,
        });
      } else if (elements.label === "designation") {
        formattedStaffForm.push({
          ...elements,
          options: positions,
        });
      } else if (elements.label === "Access Type*") {
        formattedStaffForm.push({
          ...elements,
          options: accessTypes,
          disabled: formData.is_branch_owner,
        });
      } else {
        formattedStaffForm.push(elements);
      }
    });
    return formattedStaffForm;
  };

  parseStaffFormData = (data) => {
    return data;
  };

  isAnyAddStaffFieldEmpty(fields) {
    // required fields are name(user.first_name), number(phone_number), gender(gender), date of joinig(doj), access type(user_group_id)
    if ("user" in fields && fields.user.first_name.length < 3) {
      return true;
    } else if ("user.first_name" in fields && fields["user.first_name"].length < 3) {
      return true;
    }

    if (!"user_access" in fields && !"user_access.user_group" in fields) {
      return true;
    }

    if ("doj" in fields && "phone_number" in fields && "gender" in fields) {
      if (fields.phone_number.toString().length < 10) {
        return true;
      }
      return false;
    }
    return true;
  }

  updateStaff = (status, data) => {
    if (this.isAnyAddStaffFieldEmpty(data)) {
      //showing error below staffAdd form
      this.setState({ isStaffAddFieldError: true });
      return false;
    }
    if (this.state.isStaffAddFieldError) {
      this.setState({ isStaffAddFieldError: false });
    }
    let formattedStaffData = {};
    for (let d in data) {
      if (d.includes(".")) {
        const key = d.split(".");
        delete data[key[0]];

        if (key[0] === "user") {
          formattedStaffData["user"] = {
            first_name: data[d],
          };
        } else if (key[0] === "user_access") {
          const user_group_id = data[d];
          formattedStaffData["user_group_id"] = user_group_id;
        } else {
          formattedStaffData[key[0] + "_id"] = data[d];
        }
      } else if (d === "doj") {
        formattedStaffData["doj"] = new Date(data[d]).toISOString().slice(0, 10);
      } else if (d === "files") {
        formattedStaffData["files_list"] = data[d];
      } else if (d === "user_access") {
        const user_access = data[d];
        formattedStaffData["user_group_id"] = user_access.user_group;
      } else {
        formattedStaffData[d] = data[d];
      }
    }

    if (!("service_commission" in formattedStaffData) || !data.service_commission) {
      formattedStaffData["service_commission"] = 0;
    }
    if (!("product_commission" in formattedStaffData) || !data.product_commission) {
      formattedStaffData["product_commission"] = 0;
    }
    if (this.state.edit) {
      let shouldFetchUserPermissions = false;

      const prevAccessType = this.state.staffFormData?.user_access?.user_group || "false";
      const currentAccessType = formattedStaffData?.user_group_id || "false";
      const isAccessTypeUpdated = prevAccessType !== currentAccessType;

      //if access type changed and that too of current loggedIn user then only refetch the userPermisisons
      shouldFetchUserPermissions = isAccessTypeUpdated && formattedStaffData.user.id === this.props.user.id;

      this.props.staffPutData(formattedStaffData.id, formattedStaffData, shouldFetchUserPermissions);
    } else {
      formattedStaffData["status"] = 1;
      this.props.staffPostData(formattedStaffData);
    }

    this.setState({
      addStaff: false,
      staffFormData: {},
    });
  };

  isAnyAddShiftFieldEmpty(fields) {
    if (!("start_time" in fields) || !("end_time" in fields)) {
      return true;
    }
    return false;
  }

  updateShift(status, data) {
    let newData = JSON.stringify(data);

    if (this.isAnyAddShiftFieldEmpty(data)) {
      //showing error below addSift form
      this.setState({ isShiftAddFieldError: true });
      return false;
    }

    if (data["weekly_offs"]) {
      if (!data["weekly_offs"].length) {
        delete data["weekly_offs"];
      } else {
        data["weekly_offs"] = data["weekly_offs"].reduce((prev, curr) => [...prev, curr], []).join(", ");
      }
    }

    this.props.shiftPostData(data);

    newData = JSON.parse(newData);
    this.setState({ shiftData: newData });
  }

  deleteShift(id, shift) {
    this.props.shiftPutData(id, shift);
  }

  positionsCreateUpdate() {
    if (this.state.positionFormData.positionName === "") {
      this.setState({ isPositionFieldError: true });
      return false;
    }

    let allServices = false;
    if (
      this.state.positionFormData.selectedPositions.length === this.props.services.length ||
      this.state.positionFormData.positionSelectAllServices
    ) {
      allServices = true;
    }

    var _services = [];
    if (this.state.positionFormData.positionSelectAllServices) {
      _services = this.props.services.map((service) => {
        return service.id;
      });
    } else {
      _services = this.state.positionFormData.selectedPositions;
    }

    if (this.state.positionFormData.editPosition) {
      const payload = {
        all_services: allServices,
        status: 1,
        services: _services,
        name: this.state.positionFormData.positionName,
        id: this.state.positionFormData.editPositionID,
      };
      this.props.positionPutData(payload.id, payload);
    } else {
      const payload = {
        all_services: allServices,
        status: 1,
        services: _services,
        name: this.state.positionFormData.positionName,
      };
      this.props.positionPostData(payload);
    }
    this.resetPosition();
  }

  editPosition(id, position) {
    const serviceList = position.services.map((service, index) => {
      return service.id;
    });
    this.setState((prevState) => ({
      positionFormData: {
        ...prevState.positionFormData,
        editPosition: true,
        editPositionID: id,

        positionName: position.name,
        selectedPositions: serviceList,
      },
    }));
  }

  accessControlSidebarToggle() {
    this.setState({
      accessControlSidebar: !this.state.accessControlSidebar,
    });
  }

  // ellipsify = (str) => {
  //   if (str.length > 10) {
  //     return str.substring(0, 10) + "...";
  //   } else {
  //     return str;
  //   }
  // };

  positionServicesShow() {
    const showServices = this.props.services.map((service) => {
      return {
        key: service.id,
        text: service.name,
        value: service.id,
        // content: service.name,
      };
    });
    return showServices;
  }

  postAttendance(selectedStaff, selectedDate, selectedAbsentType, paid) {
    const payload = {
      staff_id: selectedStaff.id,
      absence_type: selectedAbsentType,
      absent_date: selectedDate,
      paid: paid,
    };
    this.props.postAttendance(payload, selectedStaff.id);
  }

  attendanceEdit(editData) {
    this.setState((prevState) => ({
      attendanceFormData: {
        ...prevState.attendanceFormData,
        attendanceEditMode: true,
        selectedDate: editData.absent_date,
        selectedAbsentType: editData.absence_type,
        attendanceEditID: editData.id,
        paid: editData.paid,
      },
    }));
  }

  patchAttendance(selectedStaff, selectedDate, selectedAbsentType, attendanceEditID, paid) {
    const payload = {
      absence_type: selectedAbsentType,
      paid: paid,
    };
    this.setState((prevState) => ({
      attendanceFormData: {
        ...prevState.attendanceFormData,
        attendanceEditMode: false,
      },
    }));
    this.props.patchAttendance(attendanceEditID, payload, selectedStaff.id);
  }

  removeAttendance(id, staff, absentDate) {
    this.props.deleteAttendance(id, staff.id, absentDate);
  }

  deletePosition(id, position) {
    let deletedPosition = cloneDeep(position);
    deletedPosition["services"] = [];
    deletedPosition = { ...deletedPosition, status: 0 };
    this.props.positionPutData(id, deletedPosition);
  }

  dateChange(selected_month) {
    const monthYear = moment(selected_month).format("YYYY-MM");
    this.setState((prevState) => ({
      attendanceFormData: {
        ...prevState.attendanceFormData,
        selectedMonth: monthYear,
      },
    }));
    const selectedDate = new Date(monthYear);
    // fetch this month's attendances on change of month and year selector
    this.props.fetchAttendance(
      GetStartDate(selectedDate),
      GetEndDate(selectedDate),
      this.state.attendanceFormData.selectedStaff.id
    );
    this.props.fetchStaffAttendance(
      this.state.attendanceFormData.selectedStaff.id,
      GetStartDate(selectedDate),
      GetEndDate(selectedDate)
    );
  }

  getClientBranch() {
    const { client } = this.props;
    let branches = [];
    let key = 0;
    if (client) {
      client.forEach((element) => {
        branches.push({
          key: key++,
          text: element.name,
          value: element.id,
        });
      });
    }
    return branches;
  }

  searchStaff = debounce(
    (search) => {
      const { activeStaffOnly } = this.state;
      const status = activeStaffOnly ? 1 : 0;
      this.setState({ search });
      this.props.fetchStaff(search, pageSize, 1, status);
    },
    [500]
  );

  getInvoicePage = (selectedPage) => {
    const { search, activeStaffOnly } = this.state;
    const status = activeStaffOnly ? 1 : 0;
    this.setState({
      selectedPage,
    });
    this.props.fetchStaff(search, pageSize, selectedPage, status);
  };

  handleStaffRowBtnClick = (action, staff) => {
    if (action === "attendance") {
      if (!this.props.attendancePunchIn) {
        this.props.fetchAttendance(GetStartDate(), GetEndDate(), staff.id);
      }
      this.setState((prevState) => ({
        addAttendance: true,
        attendanceFormData: {
          ...prevState.attendanceFormData,
          selectedStaff: staff,
        },
      }));
      // fetch this month's attendances
      const today = new Date();
      this.props.fetchAttendance(GetStartDate(today), GetEndDate(today), staff.id);
      this.props.fetchStaffAttendance(staff.id, GetStartDate(today), GetEndDate(today));
    }
    if (action === "revenue") {
      this.setState({
        ...this.state,
        showRevenue: true,
        staffDetail: staff,
      });
    }
    if (action === "salary") {
      this.setState({
        showSalary: true,
        staffSalaryForm: {
          ...this.state.staffSalaryForm,
          selectedStaff: staff.id,
          isActive: staff.status,
        },
      });
    }
    if (action === "overtime") {
      this.setState({
        showOvertime: true,
        overtimeStaffDetails: staff,
      });
      // fetch this month's overtime details
      const today = new Date();
      this.props.fetchOvertime(GetStartDate(today), GetEndDate(today), staff.id);
    }
  };

  staffDataFunc = (staff, positions, shifts, defaultBranch) => {
    if (!Array.isArray(staff)) {
      return [];
    }

    return staff.map((ele) => {
      return {
        ...ele,
        branch: defaultBranch,
        designation: findObjectById(positions, ele.designation_id) || null,
        shift: findObjectById(shifts, ele.shift_id) || null,
      };
    });
  };

  filterDataFunction = (data, isActive) => {
    if (!isActive) {
      return data?.filter((row) => row.status === 0);
    }
    return data?.filter((row) => row.status);
  };

  formatAccessTypeDropdownOptions = (aclAllGroupsPermissions) => {
    return aclAllGroupsPermissions.map((permission) => {
      return {
        key: permission.id,
        text: permission.name,
        value: permission.id,
      };
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.staff.update !== this.props.staff.update && this.props.staff.update) {
      const { search, activeStaffOnly, selectedPage } = this.state;
      const status = activeStaffOnly ? 1 : 0;
      this.props.updateStaffStatusReset();
      this.props.fetchStaff(search, pageSize, selectedPage, status);
    }
  }

  openAdvanceModal(id) {
    this.setState({
      showStaffAdvanceModal: true,
      staffAdvanceId: id,
    });
  }

  closeStaffAdvanceModal() {
    this.setState({ showStaffAdvanceModal: false, staffAdvanceId: null });
    this.props.resetStaffAdvanceStore();
  }

  staffRenderSpecial({ openAdvanceModal }) {
    return [
      ({ img, user, gender, phone_number, status }) => {
        return (
          <Description
            style={{ color: "#4568D2" }}
            title={user.name}
            description={phone_number}
            add_on={gender}
            img={img}
            status={status}
          />
        );
      },
      ({ designation, status }) => {
        if (!designation) {
          return "";
        }
        const services = designation["services"].reduce((prev, curr) => [...prev, curr.name], []).join(", ");
        return <Description title={designation.name} description={<Ellipsis text={services} />} status={status} />;
      },
      ({ doj }) => {
        return doj;
      },
      ({ shift, status }) => {
        if (!shift) {
          return "";
        }
        const { start_time, end_time, weekly_offs } = shift;
        const shit_text = `${start_time} - ${end_time}`;
        return <Description title={shit_text} description={<span>({weekly_offs})</span>} status={status} />;
      },
      ({ product_commission, service_commission }) => {
        return (
          <>
            {product_commission}% / {service_commission}%
          </>
        );
      },
      ({ id, wallet_balance }) => {
        return (
          <span className="staff-advance-link" onClick={() => openAdvanceModal(id)}>
            {" "}
            Rs. {parseFloat(wallet_balance).toFixed(2)}/-
          </span>
        );
      },
      (value, edit, remove, a, b, custom) => {
        return (
          <Button onClick={() => custom("overtime", value)}>
            <Translate>Overtime</Translate>
          </Button>
        );
      },
      (value, edit, remove, a, b, custom) => {
        return (
          <Button onClick={() => custom("salary", value)}>
            <Translate>Salary</Translate>
          </Button>
        );
      },
      (value, edit, remove, a, b, custom) => {
        return (
          <Button onClick={() => custom("revenue", value)}>
            <Translate>Revenue</Translate>
          </Button>
        );
      },
      (value, edit, remove, a, b, custom) => {
        return (
          <Button onClick={() => custom("attendance", value)}>
            <Translate>Attendance</Translate>
          </Button>
        );
      },
      // (value, edit, remove, select, isSelected, custom, undo) => {
      //   const disableDeleteButton = value.is_branch_owner;

      //   return value.status === 0 ? (
      //     <Icon
      //       key={0}
      //       className="pointer"
      //       name="undo"
      //       size="large"
      //       onClick={() => {
      //         undo(value.id, value);
      //       }}
      //     />
      //   ) : (
      //     [
      //       <Icon key={0} className="pointer editbtn" name="edit" size="large" onClick={() => edit(value)} />,
      //       <Icon
      //         disabled={disableDeleteButton}
      //         key={1}
      //         className="pointer removeBtn"
      //         name="trash"
      //         size="large"
      //         onClick={() => remove(value.id, value)}
      //       />,
      //       <Confirm open={this.state.open} onCancel={this.handleCancel} onConfirm={this.handleConfirm} />,
      //     ]
      //   );
      // },

      (value, edit, remove, select, isSelected, custom, undo) => {
        const disableDeleteButton = value.is_branch_owner;

        const handleDelete = () => {
          this.setState({ open: true, deleteItemId: value.id });
        };

        const handleCancel = () => {
          this.setState({ open: false, deleteItemId: null });
        };

        const handleConfirm = () => {
          remove(this.state.deleteItemId, value);
          this.setState({ open: false, deleteItemId: null });
        };

        return value.status === 0 ? (
          <Icon
            key={0}
            className="pointer"
            name="undo"
            size="large"
            onClick={() => {
              undo(value.id, value);
            }}
          />
        ) : (
          <>
            <Icon key={0} className="pointer editbtn" name="edit" size="large" onClick={() => edit(value)} />
            <Icon
              disabled={disableDeleteButton}
              key={1}
              className="pointer removeBtn"
              name="trash"
              size="large"
              onClick={handleDelete}
            />
            <Confirm className="" open={this.state.open} onCancel={handleCancel} onConfirm={handleConfirm} />
          </>
        );
      },
    ];
  }

  refreshStaffData() {
    const { search, activeStaffOnly, selectedPage } = this.state;
    const status = activeStaffOnly ? 1 : 0;

    this.props.fetchStaff(search, pageSize, selectedPage, status);
  }

  render() {
    const {
      addStaff,
      addStaffForm,
      staffFormData,
      addShift,
      addShiftForm,
      addPosition,
      accessControlSidebar,
      edit,
      activeStaffOnly,
      addAttendance,
      addCommission,
      staffCommissionForm,
      showStaffAdvanceModal,
      staffAdvanceId,
      showRevenue,
      staffDetail,
      showSalary,
      addStaffPackage,
      positionFormData,
      attendanceFormData,
      showOvertime,
      overtimeStaffDetails,
      branch,
      openPaySlip,
      activeAccessGroupsOnly,
      paySlipShowStaff,
      selectedPage,
    } = this.state;

    const {
      aclAllGroupsPermissions,
      aclUserPermissions,
      brackets,
      staff,
      shifts,
      positions,
      commissions,
      staffBrackets,
      user,
      branch: defaultBranch,
      confirmedSalary,
    } = this.props;

    const activeStaff = staff.results.filter((staff) => staff.status);
    const { formatted_component_permissions } = aclUserPermissions;

    const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

    let staffData = this.staffDataFunc(staff.results, positions, shifts, defaultBranch);
    staffData = this.filterDataFunction(staffData, this.state.activeStaffOnly);

    //active acl groups only
    const filteredAclAllGroupsPermissions = aclAllGroupsPermissions.filter((permission) => permission.is_active);

    const attendanceTypes_translated = attendanceTypes.map((type) => {
      const { value, text } = type;

      return {
        value,
        text: <Translate>{text}</Translate>,
      };
    });

    const downloadAbsenseData = () => {
      const header = ["Date", "Staff Name", "Leave Type", "Paid/Unpaid"];

      const absenseFields = this.staffAttendanceFormat().map((item) => {
        return [
          moment(item.absent_date).format("DD-MM-YYYY"),
          item.staff.user.name,
          item.absence_type === 0
            ? "Quarter Day Absent"
            : item.absence_type === 1
            ? "Half day Absent"
            : item.absence_type === 2
            ? "Full Day Absent"
            : "",
          item.paid ? "Paid" : "Un-Paid",
        ];
      });

      const data = [[...header], ...absenseFields];

      const fileName = "absense_information";
      const exportType = "csv";
      exportFromJSON({ data, fileName, exportType });
    };
    const panes = [
      {
        menuItem: translateFormLabel("Mark Absence"),
        render: () => (
          <Tab.Pane>
            {this.props.staffAbsents.length > 0 && (
              <>
                <div className="download-icon">
                  <button disabled={isSubscriptionExpired} className="down-btn" onClick={() => downloadAbsenseData()}>
                    <AiOutlineCloudDownload size="25" title="Download Absence Data" />
                  </button>
                </div>
                <HoverTable
                  header={attendanceHeaders}
                  src={this.staffAttendanceFormat()}
                  renderSpecial={attendanceRenderSpecial}
                  edit={(valueId, value) => {
                    this.attendanceEdit(value);
                  }}
                  remove={(valueId, absentDate) => {
                    this.removeAttendance(valueId, attendanceFormData.selectedStaff, absentDate);
                  }}
                />
              </>
            )}

            <div style={{ margin: "10px" }} />
            <h4 className={attendanceFormData.attendanceEditMode ? "StaffEditModeON" : "StaffEditModeOFF"}>
              <Translate>Edit mode :</Translate> {attendanceFormData.attendanceEditMode ? "ON" : "OFF"}
            </h4>

            <div style={{ margin: "5px" }} />

            <h4 style={{ marginTop: "5px" }}>
              <Translate>Update staff leaves</Translate>
            </h4>
            <div className="radio-group">
              <Radio
                label={translateFormLabel("Paid")}
                name="radioGroup"
                value={true}
                checked={attendanceFormData.paid}
                onChange={(e, { value }) => {
                  this.setState((prevState) => ({
                    attendanceFormData: {
                      ...prevState.attendanceFormData,
                      paid: value,
                    },
                  }));
                }}
              />
              <Radio
                label={translateFormLabel("Un-Paid")}
                name="radioGroup"
                value={false}
                checked={!attendanceFormData.paid}
                onChange={(e, { value }) => {
                  this.setState((prevState) => ({
                    attendanceFormData: {
                      ...prevState.attendanceFormData,
                      paid: value,
                    },
                  }));
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <NewComponentForm.DateContainer>
                <NewComponentForm.Date
                  value={attendanceFormData.selectedDate}
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState((prevState) => ({
                      attendanceFormData: {
                        ...prevState.attendanceFormData,
                        selectedDate: value,
                      },
                    }));
                  }}
                  placeholder="Date"
                  type="date"
                />
              </NewComponentForm.DateContainer>

              <Select
                style={{ padding: "16px 10px" }}
                className="StaffAttendanceSelect"
                value={attendanceFormData.selectedAbsentType}
                onChange={(e, { value }) => {
                  this.setState((prevState) => ({
                    attendanceFormData: {
                      ...prevState.attendanceFormData,
                      selectedAbsentType: value,
                    },
                  }));
                }}
                placeholder="Select attendance type"
                options={attendanceTypes_translated}
              />
            </div>

            <NewComponentForm.SubmitButton
              disabled={!attendanceFormData?.selectedStaff.status ? true : false}
              onClick={() => {
                if (attendanceFormData.attendanceEditMode) {
                  this.patchAttendance(
                    attendanceFormData.selectedStaff,
                    attendanceFormData.selectedDate,
                    attendanceFormData.selectedAbsentType,
                    attendanceFormData.attendanceEditID,
                    attendanceFormData.paid
                  );
                } else {
                  this.postAttendance(
                    attendanceFormData.selectedStaff,
                    attendanceFormData.selectedDate,
                    attendanceFormData.selectedAbsentType,
                    attendanceFormData.paid
                  );
                }
              }}
            >
              <Translate>{attendanceFormData.attendanceEditMode ? "Update Absence" : "Mark Absence"}</Translate>
            </NewComponentForm.SubmitButton>
          </Tab.Pane>
        ),
      },
      {
        menuItem: translateFormLabel("Punch Attendance"),
        render: () => (
          <Tab.Pane>
            <Attendance data={attendanceFormData} isActive={attendanceFormData?.selectedStaff.status} />
          </Tab.Pane>
        ),
      },
    ];

    return [
      <div key={0} className="staff width100">
        {addCommission && (
          <Modal close={() => this.addCommissionModal()}>
            <Commission
              commissions={this.props.commissions}
              submit={(data) => {
                const payload = {
                  name: data.name,
                  duration_type: data.duration_type,
                  brackets: data.brackets.map((item) => ({
                    revenue_from: item.revenue_from,
                    revenue_to: item.revenue_to === -1 ? null : item.revenue_to,
                    type: data.type,
                    value: item.value,
                  })),
                };
                this.props.postCommission(payload);
              }}
              remove={(id, payload) => this.props.deleteCommission(id, payload)}
            />
          </Modal>
        )}
        {openPaySlip && (
          <Modal
            zIndex={10}
            close={() => {
              this.setState({ openPaySlip: false });
            }}
          >
            <PrintStaffSalary branch={defaultBranch} staffSalary={confirmedSalary} staff={paySlipShowStaff} />
          </Modal>
        )}
        {addStaff && (
          <Modal
            close={() => {
              this.setState({
                isStaffAddFieldError: false,
              });
              this.addStaffModal();
            }}
          >
            <div className="grey-background-theme" style={{ padding: "15px", borderRadius: "5px" }}>
              <Form
                inputs={this.staffFormFormat(addStaffForm, staffFormData)}
                data={this.parseStaffFormData(staffFormData)}
                edit={(status, data) => this.updateStaff(status, data)}
                update={edit}
              />
            </div>
            {this.state.isStaffAddFieldError ? (
              <span style={{ color: "red", padding: 10 }}>
                <Translate>No Required Field Should Be Empty</Translate>
              </span>
            ) : null}
            {staffFormData?.id && (
              <div className="grey_background_theme" style={{ marginTop: "20px" }}>
                <Tab
                  panes={[
                    {
                      menuItem: "Commission",
                      render: () => (
                        <Tab.Pane attached={false}>
                          <>
                            {this.props.staffCommissions.length ? (
                              <HoverTable
                                style={{ borderRadius: "5px", padding: "10px" }}
                                header={["Commission Name", "Start Date", "Action"]}
                                src={this.props.staffCommissions}
                                renderSpecial={[
                                  ({ bracket }) => {
                                    const name = commissions.data.results.filter((i) => i.id === bracket)[0]?.name;
                                    return <Description title={name} />;
                                  },
                                  ({ start_date }) => <Description title={start_date.split("T")[0]} />,
                                  (bracket) => {
                                    return (
                                      <Icon
                                        key={1}
                                        className="pointer trash"
                                        onClick={() =>
                                          this.props.staffCommission(bracket.staff, { ...bracket, active: false })
                                        }
                                      />
                                    );
                                  },
                                ]}
                              />
                            ) : (
                              <span>
                                <Translate>No Commission found</Translate>
                              </span>
                            )}
                            <div
                              className="grey-background-theme"
                              style={{ borderRadius: "5px", marginTop: "10px", padding: "15px" }}
                            >
                              <h3 className="text-theme-black" style={{ marginLeft: "-1px", marginBottom: "20px" }}>
                                <Translate>Add/Update Commission</Translate>
                              </h3>
                              <Form
                                inputs={this.staffCommissionFormFormat(staffCommissionForm)}
                                data={this.state.assignNewCommission}
                                edit={(status, data) => {
                                  const { id } = staffFormData;
                                  const payload = {
                                    staff: id,
                                    bracket: data.bracket,
                                    start_date: data.start_date,
                                    type: 1,
                                  };
                                  this.props.staffCommission(id, payload);
                                  this.setState(() => ({
                                    assignNewCommission: {
                                      bracket: "",
                                      start_date: null,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </>
                        </Tab.Pane>
                      ),
                    },
                    {
                      menuItem: "Salary Bracket",
                      render: () => (
                        <Tab.Pane attached={false}>
                          <>
                            {staffBrackets.length ? (
                              <HoverTable
                                style={{ borderRadius: "5px", padding: "10px" }}
                                header={["Salary Bracket Name", "Start Date", "End Date", "Action"]}
                                src={this.props.staffBrackets}
                                renderSpecial={[
                                  ({ bracket }) => {
                                    const name = this.props.brackets.filter((i) => i.id === bracket)[0]?.name || "N/A";
                                    return <Description title={name} />;
                                  },
                                  ({ start_date }) => <Description title={start_date.split("T")[0]} />,
                                  ({ end_date }) => {
                                    if (end_date) {
                                      return <Description title={end_date.split("T")[0]} />;
                                    }
                                    return <Description title={"current"} />;
                                  },
                                  (data) => {
                                    const { id } = staffFormData;
                                    const payload = {
                                      staff_ids: [id],
                                      bracket: data.bracket,
                                      start_date: data.start_date,
                                      type: 1,
                                      active: false,
                                    };
                                    return (
                                      <Icon
                                        key={1}
                                        className="pointer trash"
                                        onClick={() => this.props.updateStaffSalaryBracket(id, payload)}
                                      />
                                    );
                                  },
                                ]}
                              />
                            ) : (
                              <span>
                                <Translate>No Salary brackets found</Translate>
                              </span>
                            )}
                            <div
                              className="grey-background-theme"
                              style={{ borderRadius: "5px", marginTop: "10px", padding: "15px" }}
                            >
                              <h3 className="text-theme-black" style={{ marginLeft: "-1px", marginBottom: "20px" }}>
                                <Translate>Add Salary brackets</Translate>
                              </h3>
                              <Form
                                inputs={this.staffSalaryBracketFormat()}
                                data={this.state.assignNewSalary}
                                edit={(_, { start_date, bracket }) => {
                                  const { id } = staffFormData;
                                  const temp = new Date(start_date);
                                  const date = new Date(temp.getFullYear(), temp.getMonth(), 1)
                                    .toISOString()
                                    .slice(0, 10);
                                  const payload = {
                                    staff_ids: [id],
                                    bracket: bracket,
                                    start_date: start_date,
                                    end_date: null,
                                  };
                                  this.props.updateStaffSalaryBracket(id, payload);
                                  this.setState(() => ({
                                    assignNewSalary: {
                                      bracket: "",
                                      start_date: null,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </>
                        </Tab.Pane>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </Modal>
        )}
        {addShift && (
          <Modal
            close={() => {
              this.setState({
                isShiftAddFieldError: false,
              });
              this.addShiftModal();
            }}
          >
            <HoverTable
              header={shiftHeaders}
              src={shifts}
              renderSpecial={shiftRenderSpecial}
              edit={() => {}}
              remove={(id, shift) => this.deleteShift(id, shift)}
            />
            <h3>
              <Translate>Add new shift:</Translate>
            </h3>
            <Form
              inputs={addShiftForm}
              data={this.state.shiftData}
              edit={(status, data) => this.updateShift(status, data)}
              update={edit}
            />
            {this.state.isShiftAddFieldError ? (
              <span style={{ color: "red" }}>
                <Translate>No Required Field Should Be Empty</Translate>
              </span>
            ) : null}
          </Modal>
        )}
        {showRevenue && (
          <Modal close={() => this.setState({ ...this.state, showRevenue: false })}>
            <StaffRevenue staffDetail={staffDetail} />
          </Modal>
        )}
        {showStaffAdvanceModal && staffAdvanceId && (
          <Modal close={() => this.closeStaffAdvanceModal()}>
            <StaffAdvanceView
              id={staffAdvanceId}
              refreshStaffData={() => this.refreshStaffData()}
              isDeleted={!activeStaffOnly}
            />
          </Modal>
        )}

        {/* Add Positions SideNav */}
        <SideNav
          condition={addPosition}
          onCross={() => {
            this.setState({
              isPositionFieldError: false,
            });
            this.resetPosition();
          }}
        >
          <NewComponentForm>
            <NewComponentForm.Title>
              <Translate>Add Positions</Translate>
            </NewComponentForm.Title>
            <NewComponentForm.Divider> </NewComponentForm.Divider>
            <NewComponentForm.SubHeading
              isOpen={this.state.positionShowBracket}
              onClick={() => {
                this.setState((prevState) => ({
                  positionShowBracket: !prevState.positionShowBracket,
                }));
              }}
            >
              <Translate>List of Positions</Translate>
            </NewComponentForm.SubHeading>

            {this.state.positionShowBracket && positions.length > 0 ? (
              <div
                style={{
                  maxHeight: "400px",
                  overflow: "auto",
                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <HoverTable
                    header={positionHeaders}
                    src={positions}
                    renderSpecial={positionRenderSpecial}
                    edit={(id, position, services) => this.editPosition(id, position, services)}
                    remove={(id, position) => this.deletePosition(id, position)}
                  />
                </div>
              </div>
            ) : (
              <>
                {positions.length <= 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    <Translate>No Position created yet.</Translate>
                  </div>
                )}
              </>
            )}
          </NewComponentForm>
          <h4 className={this.state.positionFormData.editPosition ? "StaffEditModeON" : "StaffEditModeOFF"}>
            <Translate>Edit mode :</Translate> {this.state.positionFormData.editPosition ? "ON" : "OFF"}
          </h4>
          <div style={{ margin: "5px" }}></div>
          <h4 style={{ marginTop: "5px" }}>
            <Translate>Create/Update Positions</Translate>
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <NewComponentForm.FieldContainer>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={this.state.positionFormData.positionName}
                  onChange={(e) => {
                    if (e.target.value) {
                      this.setState({ isPositionFieldError: false });
                    }
                    const value = e.target.value;
                    this.setState((prevState) => ({
                      positionFormData: {
                        ...prevState.positionFormData,
                        positionName: value,
                      },
                    }));
                  }}
                  type="text"
                  placeholder="Position Name*"
                />
              </NewComponentForm.TextFieldContainer>
            </NewComponentForm.FieldContainer>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                label={translateFormLabel("Select All Services")}
                onChange={() => {
                  this.setState((prevState) => ({
                    positionFormData: {
                      ...prevState.positionFormData,
                      positionSelectAllServices: !prevState.positionFormData.positionSelectAllServices,
                    },
                  }));
                }}
                style={{ marginBottom: "15px" }}
                checked={this.state.positionFormData.positionSelectAllServices}
              />

              <Button
                onClick={() => {
                  this.setState((prevState) => ({
                    positionFormData: {
                      ...prevState.positionFormData,
                      positionSelectAllServices: false,
                      selectedPositions: [],
                    },
                  }));
                }}
                style={{ marginBottom: "15px" }}
              >
                <Translate>Reset</Translate>
              </Button>
            </div>
            {/* service selector in AddPosition sidenav */}
            <Dropdown
              style={{ marginBottom: "15px" }}
              disabled={this.state.positionFormData.positionSelectAllServices}
              placeholder={translateFormLabel("Select All Services")}
              fluid
              multiple
              search
              selection
              value={this.state.positionFormData.selectedPositions}
              options={this.positionServicesShow()}
              onChange={(e, value) => {
                this.setState((prevState) => ({
                  positionFormData: {
                    ...prevState.positionFormData,
                    selectedPositions: value.value,
                  },
                }));
              }}
            />
          </div>
          {/* showing error */}
          {this.state.isPositionFieldError ? <span style={{ color: "red" }}>No Name Provided</span> : null}

          <NewComponentForm.SubmitButton
            onClick={() => {
              this.positionsCreateUpdate();
            }}
          >
            {this.state.positionFormData.editPosition ? "Update Position" : "Create Position"}
          </NewComponentForm.SubmitButton>
        </SideNav>

        {/* show overtime */}
        <SideNav
          condition={showOvertime}
          onCross={() => {
            this.setState((prevState) => ({
              showOvertime: false,
              overtimeStaffDetails: null,
            }));
          }}
        >
          <OvertimeDOM overtimeStaffDetails={overtimeStaffDetails} />
        </SideNav>

        {/* access control sidebar */}
        <SideNav
          condition={accessControlSidebar}
          onCross={() => {
            this.accessControlSidebarToggle();
          }}
        >
          <AclSidebarView
            isClosed={!accessControlSidebar}
            filteredAclAllGroupsPermissions={filteredAclAllGroupsPermissions}
          />
        </SideNav>

        {/*  */}
        <AssignStaffSalary
          openPaySlip={(selectedStaff) => {
            const getStaff = staff.results.filter((data) => data.id === selectedStaff);
            this.setState({ paySlipShowStaff: getStaff[0], openPaySlip: true });
          }}
          condition={showSalary}
          staffId={this.state.staffSalaryForm.selectedStaff}
          close={() => this.closeSalarySidenav()}
          activeStaff={activeStaff}
        />

        <CreateStaffSalaryBrackets
          addStaffPackage={addStaffPackage}
          close={() => {
            this.setState({ salaryBracketFieldError: false });
            this.closePackageSideNav();
          }}
        />

        {addAttendance && (
          <SideNav
            condition={addAttendance}
            onCross={() => {
              this.setState((prevState) => ({
                addAttendance: false,
                attendanceFormData: {
                  ...prevState.attendanceFormData,
                  attendanceEditMode: false,
                },
              }));
              this.setState({
                addAttendance: false,
                attendanceEditMode: false,
              });
            }}
          >
            <NewComponentForm>
              <NewComponentForm.Title>
                <Translate>Attendance for</Translate> {attendanceFormData.selectedStaff.user.name}
              </NewComponentForm.Title>
              <NewComponentForm.Divider> </NewComponentForm.Divider>

              <div className="StaffAttendanceChangeMonth">
                <h4 className="StaffAttendanceSubText">
                  <Translate>Show Attendance by month</Translate>
                </h4>
                <DatePicker
                  selected={moment(attendanceFormData.selectedMonth, "YYYY-MM").toDate()}
                  onChange={(date) => {
                    this.dateChange(date);
                  }}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  customInput={<Input disabled={true} icon="calendar" />}
                />
              </div>
              <NewComponentForm.Divider> </NewComponentForm.Divider>
              {isSubscriptionExpired && (
                <p style={{ color: "red" }}>
                  <Translate>Subscription is Expired</Translate>
                </p>
              )}
              <Tab panes={panes} />
            </NewComponentForm>
          </SideNav>
        )}
        <div className="controller flex" style={{ justifyContent: "space-between" }}>
          <div className="search-checkbox">
            <CustomSearch
              style={{ maxWidth: "400px", flex: 1, minWidth: "300px" }}
              onChange={(search) => this.searchStaff(search)}
              placeholder="search by staff name or number"
            />
          
            <Toggler
              style={{
                maxWidth: "400px",
                minWidth: "200px",
                flex: 1,
                padding: "8px 10px",
              }}
              onChange={() => {
                this.setState(
                  (prevState) => ({
                    activeStaffOnly: !prevState.activeStaffOnly,
                  }),
                  () => {
                    const { search, activeStaffOnly } = this.state;
                    const status = activeStaffOnly ? 1 : 0;
                    this.props.fetchStaff(search, pageSize, 1, status);
                  }
                );
              }}
              checked={activeStaffOnly}
              label={"Active Staffs"}
            />
          </div>
          <div className="action-buttons">
            <button className="actionbtn" onClick={() => this.showSalarySidenav()}>
              <Icon name="money bill alternate outline" />
              &nbsp;<Translate>Salary Brackets</Translate>
            </button>
            <button className="actionbtn" onClick={() => this.addCommissionModal()}>
              <Icon name="dollar" />
              &nbsp;<Translate>Add Commission</Translate>
            </button>
            <button className="actionbtn" onClick={() => this.addStaffModal()}>
              <Icon name="add user" />
              &nbsp;<Translate>Add Staff</Translate>
            </button>
            <button className="actionbtn" onClick={() => this.addShiftModal()}>
              <Icon name="clock" />
              &nbsp;<Translate>Add Shifts</Translate>
            </button>
            <button className="actionbtn" onClick={() => this.addPositionModal()}>
              <Icon name="cut" />
              &nbsp;<Translate>Add Positions</Translate>
            </button>
            <button className="actionbtn" onClick={() => this.accessControlSidebarToggle()}>
              <Icon name="protect" />
              &nbsp;<Translate>Access Control</Translate>
            </button>
          </div>
        </div>
        <HoverTable
          header={staffHeaders}
          src={staffData}
          renderSpecial={this.staffRenderSpecial({ openAdvanceModal: (id) => this.openAdvanceModal(id) })}
          edit={(staff) => {
            this.editStaff(staff);
          }}
          undo={(id, staff) => {
            this.undoStaff(id, staff);
          }}
          remove={(id, staff) => {
            this.deleteStaff(id, staff);
          }}
          showDeleted={!activeStaffOnly}
          custom={this.handleStaffRowBtnClick}
        />
      </div>,
      <div key={1} className="pages flex" style={{ position: "absolute", bottom: "10px", width: "100%" }}>
        <Pagination
          firstItem={false}
          lastItem={false}
          activePage={selectedPage}
          size="mini"
          totalPages={staff?.pages || 1}
          onPageChange={(event, data) => {
            this.getInvoicePage(data.activePage);
          }}
        />
      </div>,
    ];
  }
}

const Commission = ({ commissions, submit, remove }) => {
  const { results } = commissions.data;
  const template = {
    revenue_from: "",
    revenue_to: "",
    value: "",
    type: "",
  };

  const [form, update] = useState({
    type: 0,
    errored: false,
    name: "",
    duration_type: 1,
    brackets: [
      {
        revenue_from: "0",
        revenue_to: "-1",
        value: "",
        type: "",
      },
    ],
  });
  const [error, setError] = useState([]);

  // const [open, setOpen] = useState(false);

  //   const handleDeleteConfirmation = () => {
  //     setOpen(true);
  //   };

  //   const handleDelete = () => {
  //     remove(value.id, value);
  //     setOpen(false);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const resetForm = () =>
    update({
      type: 0,
      errored: false,
      name: "",
      duration_type: 1,
      brackets: [
        {
          revenue_from: "0",
          revenue_to: "-1",
          value: "",
          type: "",
        },
      ],
    });

  const validate = () => {
    setError([]);
    const { brackets, name, type } = form;
    let messages = [];
    let errored = false;

    if (name.length === 0) {
      messages.push("No name provided");
      errored = true;
    }

    brackets.forEach((itr) => {
      if (errored) {
        return;
      }
      if (isNaN(itr.revenue_from) || isNaN(itr.revenue_to) || isNaN(parseInt(itr.value))) {
        messages.push(`Make sure numbers are entered`);
        errored = true;
      }

      if (itr.revenue_to <= itr.revenue_from && parseInt(itr.revenue_to) !== -1) {
        messages.push(`"Revenue to" should be larger than "Revenue from".`);
        errored = true;
      }

      if (type === 0) {
        if (validatePercentage(itr.value)) {
          messages.push(`Discount must be higher than 0 and equal to or lower than 100`);
          errored = true;
        }
      }
    });

    setError(messages);
    update({ ...form, errored: errored });

    if (!errored) {
      submit(form);
      resetForm();
    }
  };

  const commissionRenderSpecial = [
    ({ name }) => <Description title={name} />,
    ({ duration_type }) => <Description title={duration_type === 0 ? "Weekly" : "Monthly"} />,
    (value, edit, remove) => {
      return !value.active ? (
        <Icon key={0} className="dont" />
      ) : (
        [
          <Icon key={0} className="pointer" name="eye" onClick={() => edit(value)} />,
          <Icon key={1} className="pointer trash" onClick={() => remove(value.id)} />,
        ]
      );
    },
  ];

  const revenueToUpdate = (value, idx) => {
    const dup = Array.from(form.brackets);
    if (parseInt(dup[idx]["revenue_from"]) >= parseInt(value)) {
      setError([`"Revenue To" ${value} should be larger than "Revenue From" ${parseInt(dup[idx]["revenue_from"])}`]);
    } else {
      setError([]);
    }
    dup[idx]["revenue_to"] = value;
    dup[idx + 1]["revenue_from"] = parseInt(value) + 1;
    update({
      ...form,
      brackets: dup,
    });
  };

  const bracketsLength = form.brackets.length;
  const bracketRowUpdate = (add = false) => {
    const _temp = Array.from(form.brackets);
    if (!add) {
      // adds another bracket row
      if (bracketsLength === 1) {
        // do nothing
        return;
      }
      _temp.pop();
      _temp[_temp.length - 1]["revenue_to"] = -1;
    } else {
      // removes a bracket row
      const temp = { ...template };
      if (!isNaN(form.brackets[form.brackets.length - 1].revenue_to)) {
        temp.revenue_from = parseInt(form.brackets[form.brackets.length - 1].revenue_to) + 1;
      }
      temp.revenue_to = "-1";
      _temp.push(temp);
    }
    update({
      ...form,
      brackets: _temp,
    });
  };

  return (
    <>
      <Translate>
        <div className="headingComission">Add commission</div>
      </Translate>
      <HoverTable
        header={["commission", "Bracket type", "Action"]}
        src={results}
        renderSpecial={commissionRenderSpecial}
        edit={(data) => {
          update({
            duration_type: data.duration_type,
            type: data.brackets[0].type,
            brackets: data.brackets,
            errored: false,
            name: data.name,
            id: data.id,
          });
        }}
        remove={(id) => remove(id, { active: false })}
      />
      <hr />
      <Forms>
        <Forms.Field>
          <label>
            <Translate>Commission name*</Translate>
          </label>
          <input
            type="text"
            placeholder="Commission Name"
            value={form.name}
            onChange={(e) => update({ ...form, name: e.target.value })}
          />
        </Forms.Field>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "12px",
            gap: "5px",
          }}
        >
          <Forms.Group>
            <Forms.Field>
              <label>
                <Translate>Commission Type</Translate>
              </label>
            </Forms.Field>
            <Forms.Field>
              <button
                onClick={() => update({ ...form, type: 0 })}
                className={form.type === 0 ? `button ui active` : `button ui`}
              >
                <Translate>Percentage</Translate>
              </button>
              <button
                onClick={() => update({ ...form, type: 1 })}
                className={form.type === 1 ? `button ui active` : `button ui`}
              >
                <Translate>Value</Translate>
              </button>
            </Forms.Field>
          </Forms.Group>
          <Forms.Group>
            <Forms.Field>
              <label>
                <Translate>Commission Bracket</Translate>
              </label>
            </Forms.Field>
            <Forms.Field>
              <button
                onClick={() => update({ ...form, duration_type: 0 })}
                className={form.duration_type === 0 ? `button ui active` : `button ui`}
              >
                <Translate>Weekly</Translate>
              </button>
              <button
                onClick={() => update({ ...form, duration_type: 1 })}
                className={form.duration_type === 1 ? `button ui active` : `button ui`}
              >
                <Translate>Monthly</Translate>
              </button>
            </Forms.Field>
          </Forms.Group>
        </div>

        {form.brackets.map((item, idx) => {
          const disableTo = idx === bracketsLength - 1;
          return (
            <Forms.Group key={idx} widths="equal">
              <Forms.Field>
                <label>
                  <Translate>From</Translate>
                </label>
                <input type="number" value={item.revenue_from} disabled={true} />
              </Forms.Field>
              <Forms.Field>
                <label>
                  <Translate>To</Translate>
                </label>
                {parseInt(item.revenue_to) === -1 ? (
                  <input
                    type="text"
                    value="&#8734;"
                    disabled={disableTo}
                    onChange={(e) => revenueToUpdate(e.target.value, idx)}
                  />
                ) : (
                  <input type="number" value={item.revenue_to} onChange={(e) => revenueToUpdate(e.target.value, idx)} />
                )}
              </Forms.Field>
              <Forms.Field>
                <label>
                  <Translate>Commission*</Translate>
                </label>
                <input
                  type="number"
                  value={item.value}
                  onChange={(e) => {
                    const dup = Array.from(form.brackets);
                    dup[idx]["value"] = e.target.value;
                    update({
                      ...form,
                      brackets: dup,
                    });
                  }}
                />
              </Forms.Field>
            </Forms.Group>
          );
        })}
        <Forms.Field>
          <Button circular icon="plus" disabled={form.hasOwnProperty("id")} onClick={() => bracketRowUpdate(true)} />

          <Button circular icon="minus" disabled={form.hasOwnProperty("id")} onClick={() => bracketRowUpdate(false)} />
          <button className="ui button" disabled={form.hasOwnProperty("id")} onClick={validate}>
            <Translate>Create</Translate>
          </button>
          <button className="ui button" onClick={resetForm}>
            <Translate>Reset</Translate>
          </button>
          <p className="errors">
            {error.map((item, idx) => (
              <span key={idx}>{item}</span>
            ))}
          </p>
        </Forms.Field>
      </Forms>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    aclAllGroupsPermissions: state.aclAllGroupsPermissions.data,
    aclUserPermissions: state.aclUserPermissions.data,

    staff: state.staff,
    shifts: state.shifts,
    positions: POSITION_DATA(state),
    commissions: state.commission,
    staffCommissions: state.staffCommission,
    staffAbsents: state.staffAbsents,
    services: Object.values(state.services.data).filter((e) => e.status === 1),
    brackets: state.staffBracket.activeBrackets || [],
    staffBrackets: state.staffBracket.staffBrackets || [],
    staffSalary: state.staffSalary.data || null,

    staffSalaryOthers: state.staffSalary.other || null,
    confirmedSalary: state.staffSalary.confirmedSalary || null,
    attendancePunchIn: state.user?.staff_attendance_punch || false,
    staffAttendance: state.staffAttendance,
    staffOvertime: state.staffOvertime,

    user: state.user,
    client: state.client.clients,
    branch: state.branch.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStaff: (search, pageSize, selectedPage, status) =>
      dispatch(staffFetchData(search, pageSize, selectedPage, status)),
    staffPostData: (appointment) => dispatch(staffPostData(appointment)),
    staffPutData: (id, appointment, shouldFetchUserPermissions) =>
      dispatch(staffPutData(id, appointment, shouldFetchUserPermissions)),

    getAllGroupsPermissions: () => dispatch(getAllGroupsPermissions()),
    getGroupPermissions: () => dispatch(getGroupPermissions()),
    getBasePermissions: () => dispatch(getBasePermissions()),
    getComponentsPermissions: () => dispatch(getComponentsPermissions()),

    fetchShifts: (url) => dispatch(shiftsFetchData(url)),
    shiftPostData: (shift) => dispatch(shiftsPostData(shift)),
    shiftPutData: (id, shift) => dispatch(shiftsPutData(id, shift)),

    fetchPositions: (url) => dispatch(positionsFetchData(url)),
    positionPostData: (position) => dispatch(positionsPostData(position)),
    positionPutData: (id, position) => dispatch(positionsPutData(id, position)),

    fetchServices: () => dispatch(servicesFetchData()),
    fetchCommissions: () => dispatch(commissionFetchData()),
    postCommission: (payload) => dispatch(commissionPostData(payload)),
    deleteCommission: (id, payload) => dispatch(commissionPatchData(id, payload)),
    staffCommission: (id, payload) => dispatch(staffCommission(id, payload)),

    fetchAttendance: (startDate, endDate, id) => dispatch(staffAttendanceFetchData(startDate, endDate, id)),
    postAttendance: (data, staffID) => dispatch(staffAttendancePostData(data, staffID)),
    patchAttendance: (id, data, staffID) => dispatch(staffAttendancePatchData(id, data, staffID)),
    deleteAttendance: (id, staffID, absentDate) => dispatch(staffAttendanceDeleteData(id, staffID, absentDate)),

    fetchOvertime: (startDate, endDate, id) => dispatch(staffOvertimeFetchData(startDate, endDate, id)),
    postOvertime: (data, staffID) => dispatch(staffOvertimePostData(data, staffID)),
    patchOvertime: (id, data, staffID) => dispatch(staffOvertimePatchData(id, data, staffID)),
    deleteOvertime: (id, staffID) => dispatch(staffOvertimeDeleteData(id, staffID)),

    fetchSalaryBrackets: () => dispatch(activeSalaryBracketFetch()),
    postSalaryBrackets: (payload) => dispatch(staffSalaryPostData(payload)),
    updateStaffSalaryBracket: (id, payload) => dispatch(updateStaffSalaryBracket(id, payload)),
    fetchStaffSalaryBrackets: (id) => dispatch(staffSalaryBracketFetch(id)),

    fetchStaffSalary: (id, month) => dispatch(fetchStaffSalary(id, month)),
    confirmSalary: (id, payload) => dispatch(confirmStaffSalary(id, payload)),
    resetStaffSalary: () => dispatch(resetStaffSalary()),

    fetchConfirmedStaffSalary: (id, month) => dispatch(fetchConfirmedStaffSalary(id, month)),

    fetchStaffAttendance: (staffId, startDate, endDate) =>
      dispatch(getReceptionistStaffAttendance(staffId, startDate, endDate)),
    updateStaffStatusReset: () => dispatch(updateStaffStatusReset()),

    resetStaffAdvanceStore: () => dispatch(resetStaffAdvanceStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
