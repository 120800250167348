import React, { useRef } from "react";
import { Button } from "semantic-ui-react";
import "./index.scss";
import { Translate } from "react-auto-translate";
import { useReactToPrint } from "react-to-print";

const monthsMap = [
  "",
  "January",
  "Februaru",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "Novemnber",
  "December",
];

const PrintStaffSalary = ({ branch, staffSalary, staff }) => {
  let netSalary = 0;
  const dateSplit = staffSalary.month.split("-");
  const year = dateSplit[0];
  const month = parseInt(dateSplit[1]);

  // adding earning
  const totalEarning = staffSalary.addition.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.value;
  }, 0);

  // deduction
  const totalDeduction = staffSalary.deduction.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.value;
  }, 0);

  netSalary = totalEarning - totalDeduction;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="payslip-hidden">
      <div ref={componentRef} className="details" style={{ padding: 10 }}>
        <div className="payslipHeading">
          <Translate>Payslip</Translate>
        </div>
        {/* Staff and company details */}
        <div className="detailsContainer">
          {/* staff info */}
          <div className="payslip-header-details" style={{ flex: 1 }}>
            <div>
              <Translate>Name:</Translate> <strong>{staff?.user?.name}</strong>
            </div>
            <div>
              <Translate>Phone Number:</Translate> {staff?.phone_number}
            </div>
            {staff?.user?.email && (
              <div>
                <Translate>Email:</Translate> {staff?.user?.email}
              </div>
            )}
          </div>

          {/* company info */}
          <div className="payslip-header-details" style={{ flex: 1 }}>
            <div>
              <Translate>Branch:</Translate> <strong>{branch.name}</strong>
            </div>
            <div>
              <Translate>Code:</Translate> {branch.code}
            </div>
            <div>
              <Translate>Address:</Translate> {branch.address}, {branch.city}, {branch.pin_code}
            </div>
            <div>
              <Translate>Contact Number:</Translate> {branch.contact}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            borderBottomWidth: "1px",
            bordeBottomColor: "gray",
            borderBottomStyle: "solid",
          }}
        >
          <strong>
            {monthsMap[month]} {year}
          </strong>
        </div>

        {/* earning and deduction list */}
        <div className="detailsContainer">
          <div className="earning" style={{ flex: 1 }}>
            <div className="heading">
              <h4>
                <Translate>Earnings</Translate>
              </h4>
              <h4>Rs.</h4>
            </div>
            {staffSalary.addition.map((item, idx) => {
              return (
                <div key={idx} className="particular">
                  <span>{item.name}</span>
                  <span>&#x20B9; {item.value}</span>
                </div>
              );
            })}
            {/* total earning */}
            <div className="particular" style={{ borderWidth: "0px" }}>
              <strong>
                <Translate>Total</Translate>
              </strong>
              <strong>&#x20B9; {totalEarning}</strong>
            </div>
          </div>

          <div className="deduction" style={{ flex: 1 }}>
            <div className="heading">
              <h4>
                <Translate>Deduction</Translate>
              </h4>
              <h4>Rs.</h4>
            </div>
            {staffSalary.deduction.map((item, idx) => {
              return (
                <div key={idx} className="particular">
                  <span>{item.name}</span>
                  <span>&#x20B9; {item.value}</span>
                </div>
              );
            })}
            {/* total deduction */}
            <div className="particular" style={{ borderWidth: "0px" }}>
              <strong>
                <Translate>Total</Translate>
              </strong>
              <strong>&#x20B9; {totalDeduction}</strong>
            </div>
          </div>
        </div>

        {/* net salary */}
        <div style={{ textAlign: "right", padding: "10px" }}>
          <Translate>Net Salary:</Translate> <strong>&#x20B9; {netSalary}</strong>
        </div>
      </div>
      <div className="print-btn">
        <Button
          content="Print"
          onClick={() => {
            handlePrint();
          }}
        />
      </div>
    </div>
  );
};

export default PrintStaffSalary;
