import React, { useEffect, useState } from "react";
import "./index.scss";
import { Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { cartData, deleteCartProduct, updateCartProductQuantity } from "../../store/actions/marketplaceCart";
import { ITEM_MAX_QUANTITY } from "../../utilities/constants";
import { PlaceOrder } from "../../store/actions/marketplacePlaceOrder";
import { getSubscriptionDiscount, resetSubscriptionDiscount } from "../../store/actions/subscriptionDiscount";

export function addCommaToNumber(num) {
  num = num.toString();
  let lastThree = num.substring(num.length - 3);
  let otherNum = num.substring(0, num.length - 3);
  if (otherNum !== "") lastThree = "," + lastThree;

  return otherNum.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
}

function CartDetail() {
  const [discountCoupon, setDiscountCoupon] = useState("");

  const cart = useSelector((state) => state.cart);
  const updateCartItem = useSelector((state) => state.updateCartItem);
  const deleteCartItem = useSelector((state) => state.deleteCartItem);
  const { data } = useSelector((state) => state.subscriptionDiscount);

  const dispatch = useDispatch();

  function changeQuantity(variant, id, num, quantity, unit_cost) {
    dispatch(updateCartProductQuantity(variant, id, num, quantity, unit_cost));
  }

  function handleDeleteItem(id) {
    dispatch(deleteCartProduct(id));
  }

  const placeOrder = useSelector((state) => state.placeOrder);
  const { error } = placeOrder;

  function handlePlaceOrder() {
    data.length
      ? dispatch(
          PlaceOrder({
            coupon: discountCoupon,
          })
        )
      : dispatch(PlaceOrder());
  }

  const totalCost = cart.length ? cart.reduce((acc, num) => num.quantity * num.unit_cost + acc, 0) : 0;
  let discount = 0,
    cost = totalCost;

  if (data.length && cost) {
    if (!!data[0].discount_type) {
      discount = data[0].discount_type_value;
    } else {
      discount = (cost * data[0].discount_type_value) / 100;
    }
    if (data[0].max_discount_amount && discount > data[0].max_discount_amount) {
      discount = data[0].max_discount_amount;
    }
    cost -= discount;
  }

  useEffect(() => {
    dispatch(cartData());
    dispatch(resetSubscriptionDiscount());
  }, [dispatch, updateCartItem, deleteCartItem, placeOrder]);

  return (
    <div className="cartDetail-container">
      <h1 className="cartDetail-title">Shopping Cart</h1>
      <div className="cart-products">
        {cart.length === 0 ? (
          <h2>Your Glamplus Cart is empty.</h2>
        ) : (
          cart.map((cartProduct, index) => {
            const { variant, unit_cost, quantity } = cartProduct;
            const { product } = variant;
            return (
              <div className="cart-product" key={index}>
                <img src={product.images.length ? product.images[0].url : "/no-image.png"} alt={product.title} />
                <div>{product.name}</div>
                <div>Rs. {unit_cost}</div>
                <div className="quantity-container">
                  <button
                    className="ui compact icon button"
                    disabled={quantity === 1 ? true : false}
                    onClick={() => changeQuantity(variant, cartProduct.id, -1, quantity, unit_cost)}
                  >
                    <i className="minus icon"></i>
                  </button>
                  <div>{quantity}</div>
                  <button
                    className="ui compact icon button"
                    disabled={quantity === ITEM_MAX_QUANTITY ? true : false}
                    onClick={() => changeQuantity(variant, cartProduct.id, 1, quantity, unit_cost)}
                  >
                    <i className="plus icon"></i>
                  </button>
                </div>
                <button className="ui compact icon button" onClick={() => handleDeleteItem(cartProduct.id)}>
                  <i className="close icon"></i>
                </button>
              </div>
            );
          })
        )}
      </div>
      <div className="discount-coupon">
        <Input
          action={{
            color: "teal",
            labelPosition: "right",
            icon: "tag",
            content: "Apply",
            onClick: () => {
              dispatch(getSubscriptionDiscount(discountCoupon));
            },
          }}
          className="discount"
          placeholder="discount coupon"
          value={discountCoupon}
          onChange={(e) => setDiscountCoupon(e.currentTarget.value)}
        />
      </div>
      <h3 className="total-price">
        Total Price: &#x20B9; {addCommaToNumber(cost)} {totalCost !== cost && <s>{totalCost}</s>}
      </h3>
      {error.length !== 0 && <div className="error">{error}</div>}
      <button disabled={!cart.length} className="ui place-order-btn button" onClick={handlePlaceOrder}>
        Place Order
      </button>
    </div>
  );
}

export default CartDetail;
