import { API_BASE_URL, APPOINTMENT_FEEDBACK_API } from "utilities";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { errorMessage } from "../../utilities/actionMessages";
import { authenticationLogout } from "./authentication";

const appointmentFeedbackFetching = () => {
  return {
    type: "APPOINTMENT_FEEDBACK_FETCHING",
  };
};

const appointmentFeedbackSuccess = (data) => {
  return {
    type: "APPOINTMENT_FEEDBACK_SUCCESS",
    response: { data },
  };
};

const appointmentFeedbackFailed = () => {
  return {
    type: "APPOINTMENT_FEEDBACK_FAILED",
  };
};

export const appointmentFeedbackReset = () => {
  return {
    type: "APPOINTMENT_FEEDBACK_RESET",
    response: {
      data: null,
    },
  };
};

export const getAppointmentFeedback = (appointment_id) => {
  const params = { appointment_id };
  let URL = API_BASE_URL + APPOINTMENT_FEEDBACK_API;

  return (dispatch) => {
    dispatch(appointmentFeedbackFetching());
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params,
      })
      .then(function (response) {
        dispatch(appointmentFeedbackSuccess(response?.data?.results));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }

        const errorMsg = error?.response?.data || errorMessage;
        ToastAlert({ message: errorMsg, type: "error" });

        dispatch(appointmentFeedbackFailed());
      });
  };
};
