import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../index.scss";

import HoverTable from "HoverTable";
import Description from "Description";
import { Form as Forms, Icon, Pagination } from "semantic-ui-react";
import { getStaffAdvance, getStaffAdvanceBalance, postStaffAdvance } from "../../../store/actions/staffAdvance";
import { Translate } from "react-auto-translate";
import DatesDropdown from "../../../components/DatesDropdown/datesDropdown";
import StaffAdvanceForm from "./StaffAdvanceForm";
import moment from "moment";

const formatAmount = (amount) => {
  if (parseInt(amount) == amount) {
    return parseInt(amount);
  }
  return parseFloat(amount).toFixed(2);
};

const renderData = [
  ({ date }) => <Description title={date} />,
  ({ amount_payable }) => <Description title={`Rs. ${formatAmount(amount_payable)}/-`} />,
  ({ amount_paid }) => <Description title={`Rs. ${formatAmount(amount_paid)}/-`} />,
  ({ payment_mode }) => <Description title={payment_mode} />,
];

function StaffAdvanceView({ id = null, refreshStaffData = () => {}, isDeleted = false }) {
  const dispatch = useDispatch();
  const staffAdvance = useSelector((state) => state.staffAdvance);
  const [dates, setDates] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (id) {
      const { start_date, end_date } = dates;
      dispatch(getStaffAdvance({ id, page: 1, start_date, end_date }));
      dispatch(getStaffAdvanceBalance(id));
    }
  }, [id]);

  const addNewWallet = async (form) => {
    if (!form["amount_payable"]) {
      form["amount_payable"] = 0;
    }
    if (!form["amount_paid"]) {
      form["amount_paid"] = 0;
    }

    const data = {
      ...form,
      staff: id,
    };

    const filters = { start_date: dates.start_date, end_date: dates.end_date };

    dispatch(postStaffAdvance(data, filters));
    refreshStaffData();
  };

  const onDateChange = (start_date, end_date) => {
    setDates({ start_date, end_date });
    dispatch(getStaffAdvance({ id, page: 1, start_date, end_date }));
  };

  return (
    <div style={{ minWidth: "900px" }}>
      <h3>
        <Translate>Staff Advance</Translate>
      </h3>
      <div style={{ padding: 10, marginBottom: 20 }}>
        <DatesDropdown onChange={onDateChange} />
      </div>
      <div>
        <HoverTable
          style={{ minHeight: "300px", maxHeight: "300px" }}
          header={["Date", "Staff Advance", "Branch Advance / Staff Returned", "Payment Mode"]}
          src={staffAdvance?.data || []}
          renderSpecial={renderData}
        />
        {staffAdvance?.data?.length > 0 && (
          <div className="staff-advance-pagination">
            <Pagination
              defaultActivePage={1}
              activePage={staffAdvance.current_page}
              totalPages={staffAdvance.pages}
              size="mini"
              onPageChange={(event, { activePage }) => {
                dispatch(getStaffAdvance({ id, page: activePage }));
              }}
            />
          </div>
        )}

        {staffAdvance?.data?.length === 0 && (
          <p className="info">
            <Icon name="info" />
            <span>
              <Translate>No advance history found</Translate>
            </span>
          </p>
        )}
      </div>
      <hr />
      <StaffAdvanceForm isDeleted={isDeleted} onSubmit={addNewWallet} />
    </div>
  );
}

export default StaffAdvanceView;
