import React, { useState, useEffect } from "react";
import "./index.scss";
import _ from "lodash";
import { Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { updateAppointmentServiceState } from "../../store/actions/staff";
import { formatTimeByUnit, toFixed } from "../../utilities/CommonFunctions";
import { Translate } from "react-auto-translate";

const initialData = {
  service_id: null,
  product_id: null,
  staff_id: null,
  quantity: 0,
  original_cost: null,
  cost: undefined,
  discount_type: 0,
  discount_type_value: "",
  duration: "",
  time_unit: "MIN",
};

function manageAlter(action, index, rows, setRows, onChange) {
  if (action === "add") {
    rows.splice(index + 1, 0, { ...initialData });
  } else {
    rows.splice(index, 1);
  }
  rows = rows.length ? rows : [{ ...initialData }];
  onChange([...rows]);
  setRows([...rows]);
}

function update(event, data, placeholder, onChange, key, setRows, rows, selectedStaff, action) {
  let newKey = key > rows.length - 1 ? rows.length - 1 : key;
  if (data.checked === true) {
    if (rows.length > 1 && !rows[newKey][placeholder + "_id"]) {
    } else if (rows[0][placeholder + "_id"]) rows.splice(newKey, 0, { ...initialData }); // if first initData has id then add push new init data
  } else if (data.checked === false) {
    rows = rows.filter((ele) => ele[placeholder + "_id"] !== data.value);
    rows = rows.length ? rows : [{ ...initialData }];
    onChange([...rows]);
    setRows([...rows]);
    return;
  }
  rows[newKey]["staff_id"] = rows[newKey]?.["staff_id"] || selectedStaff;

  if (placeholder === "service" || placeholder === "product") {
    const item = _.find(data.options, { value: data.value });

    rows[newKey][placeholder + "_id"] = data.value;
    rows[newKey]["cost"] = item?.cost;
    rows[newKey]["original_cost"] = item?.cost;
    rows[newKey]["quantity"] = 1;
    rows[newKey]["checked"] = data.checked;
    rows[newKey]["duration"] = formatTimeByUnit(item?.time, item?.time_unit);
    rows[newKey]["time_unit"] = item?.time_unit;
    if (placeholder === "service") {
      if (selectedStaff)
        rows[newKey]["staffs"] = [{ id: selectedStaff, commission: null, duration: null, time_unit: "MIN" }];
      delete rows[newKey]["product_id"];
    } else {
      delete rows[newKey]["duration"];
      delete rows[newKey]["time_unit"];
      delete rows[newKey]["service_id"];
    }
    rows = rows.filter((ele) => ele[placeholder + "_id"]);
  } else if (placeholder === "duration") {
    rows[newKey][placeholder] = data.value;
  } else if (placeholder === "time_unit") {
    rows[newKey][placeholder] = data.value;
  } else if (placeholder === "quantity") {
    rows[newKey][placeholder] = data.value;
  } else if (placeholder === "cost") {
    rows[newKey][placeholder] = data.value || 0;
  } else if (placeholder === "staff_id") {
    rows[newKey][placeholder] = data.value || selectedStaff;
  } else if (placeholder === "staffs") {
    rows[newKey][placeholder] = data.value?.map((id) => {
      return {
        id,
        commission: null,
        duration: null,
        time_unit: "MIN",
        revenue_percentage: toFixed(100 / data.value.length), // assigning equal share of revnue percentage
      };
    });
  } else {
    rows[newKey][placeholder + "_id"] = data.value;
  }

  onChange([...rows]);
  setRows([...rows]);
}

const costCalculator = (data) => {
  const cost = Number(data.cost);
  if (
    data.hasOwnProperty("discount_type") &&
    data.hasOwnProperty("discount_type_value") &&
    data.discount_type_value !== ""
  ) {
    let toNegate = 0;
    if (data.discount_type === 0) {
      toNegate = (cost * Number(data.discount_type_value)) / 100;
    } else {
      toNegate = Number(data.discount_type_value) || 0;
    }
    return cost - toNegate;
  }
  return cost;
};

function AddRemove({ children, onChange, appointment, value = [{ ...initialData }], error = null }) {
  const user = useSelector((state) => state.user);
  const { data: aclUserPermissions } = useSelector((state) => state.aclUserPermissions);
  const { formatted_component_permissions } = aclUserPermissions;
  const isStylist =
    formatted_component_permissions.includes("employee_access") ||
    formatted_component_permissions.includes("staff_access");
  const [rows, setRows] = useState(value);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const cost = _.sumBy(rows, (data) => (data.cost && costCalculator(data)) || 0);
    setTotal(parseFloat(cost));
    onChange([...rows]);
  }, [rows]);

  return (
    <div className="add-remove">
      {_.map(rows, (item, key) => {
        // here we allow service's status to be updated by
        // if user logged in is not a stylist or
        // if user is stylist and is part of the staffs list of the particular service
        const validAppoinment = [1, 2].indexOf(appointment?.status) !== -1 || false;
        const canUserStartService =
          validAppoinment &&
          (!isStylist ||
            (isStylist && item.hasOwnProperty("staffs") && item.staffs.filter((_i) => _i.id === user.id).length !== 0));
        return (
          <>
            <div key={key} className="child-container">
              {React.cloneElement(children, {
                index: key,
                onChange,
                update,
                setRows,
                rows,
              })}
              <div className="icons">
                <Icon
                  circular
                  inverted
                  color="grey"
                  name="add"
                  onClick={() => manageAlter("add", key, rows, setRows, onChange)}
                />
                <Icon
                  circular
                  inverted
                  color="grey"
                  name="minus"
                  onClick={() => manageAlter("remove", key, rows, setRows, onChange, total, setTotal)}
                />
                {canUserStartService && [1, 2, 3].indexOf(item.status) !== -1 && (
                  <StaffServiceManagement service={item} appointment={appointment} rows={rows} setRows={setRows} />
                )}
              </div>
            </div>
            {item.discount_type_error && <div className="error-msg">** {item.discount_type_error}**</div>}
          </>
        );
      })}
      <div className="total-services">
        <div>
          <Translate>Total Cost: Rs.</Translate>
          {parseFloat(total).toFixed(2)}/- <Translate>(Without taxes or discounts)</Translate>
        </div>

        {error?.data?.detail && <div className="error-msg">** {error?.data?.detail} **</div>}
      </div>
    </div>
  );
}

const StaffServiceManagement = ({ service, appointment, rows, setRows }) => {
  const dispatch = useDispatch();
  const [status, update] = useState(service.status);

  return (
    <Icon
      circular
      inverted
      color={status === 1 ? "blue" : status === 2 ? "orange" : "green"}
      name={status === 1 ? "play" : status === 2 ? "clock" : "check"}
      onClick={() => {
        const newStatus = status === 1 ? 2 : status === 2 ? 3 : null;
        rows.forEach((row) => {
          if (row.id === service.id) row.status = newStatus;
        });
        setRows([...rows]);
        if (newStatus) {
          dispatch(updateAppointmentServiceState(service.id, newStatus, appointment.id));
        }
        update(newStatus);
      }}
      title={status === 1 ? "Start Service" : status === 2 ? "In Progress" : "Done"}
    />
  );
};
export default AddRemove;
