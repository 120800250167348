import React from "react";
import { SideNav } from "../../new-components";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { postLabels, putLabels } from "../../store/actions/labels";
import LabelsForm from "../../components/LabelsForm";

const LabelSideNav = ({ openLabelSideNav = true, closeLabelSideNav = () => {} }) => {
  const dispatch = useDispatch();

  const onAddLabel = (labels) => {
    dispatch(postLabels("client", labels));
  };

  const onUpdateLabel = (id, labels) => {
    dispatch(putLabels("client", id, labels));
  };

  return (
    <SideNav
      condition={openLabelSideNav}
      onCross={() => {
        closeLabelSideNav(false);
      }}
    >
      <LabelsForm type="client" name="Client" onAddLabel={onAddLabel} onUpdateLabel={onUpdateLabel} />
    </SideNav>
  );
};

export default LabelSideNav;
