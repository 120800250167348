import React from "react";
import { Form } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

const translateFormLabel = (labelText) => {
  return [<Translate>{labelText}</Translate>];
};

export const CampaignDiscountAllServicesProducts = (props) => {
  const { formik, type } = props;
  const label = type.slice(0, -1);
  return (
    <>
      <div className="flex width-100">
        <Form.Field className="grouped-radio">
          <label>
            {label} <Translate>Discount Type</Translate>
          </label>
          <div className="flex">
            <Form.Radio
              label={translateFormLabel("Percentage")}
              checked={formik.values[`all_${type}_discount_type`] === 0}
              onChange={() => formik.setFieldValue(`all_${type}_discount_type`, 0)}
            />
            <Form.Radio
              label={translateFormLabel("Value")}
              checked={formik.values[`all_${type}_discount_type`] === 1}
              onChange={() => formik.setFieldValue(`all_${type}_discount_type`, 1)}
            />
          </div>
        </Form.Field>
        <Form.Field>
          <Form.Input
            id={`all_${type}_discount_type_value`}
            type="number"
            placeholder={`Enter ${label} Discount ` + ["%", "Value"][formik.values[`all_${type}_discount_type`]]}
            label={<Translate>{`Discount* ${["%", "Value"][formik.values[`all_${type}_discount_type`]]}`}</Translate>}
            error={formik.errors[`all_${type}_discount_type_value`]}
            onChange={formik.handleChange}
            value={formik.values[`all_${type}_discount_type_value`] || ""}
          />
        </Form.Field>
      </div>
    </>
  );
};
