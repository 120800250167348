import React, { useEffect, useState } from "react";
import Modal from "Modal";
import Description from "Description";
import HoverTable from "HoverTable";
import { Icon, Popup } from "semantic-ui-react";
import GenerateInvoice from "GenerateInvoice";
import ClientMembershipUsageHistory from "../../components/ClientMembershipUsageHistory";
import MembershipPopUp from "../MembershipPopUp/MembershipPopUp";
import { Translate } from "react-auto-translate";

import "./index.scss";

function ClientMembershipHistory({ viewMembership, clientMemberships, closeMemberhship }) {
  const [selectedMembershipUsageHistory, setSelectedMembershipUsageHistory] = useState({
    id: null,
    show: false,
    membership: null,
  });
  const clientMembershipsHeaders = ["Name", "Cost", "Status", "Usage"];
  const [showInvoice, setShowInvoice] = useState({
    id: null,
    customer: {
      id: null,
    },
    show: false,
  });
  const clientMembershipsRenderSpecial = [
    ({ membership }) => {
      console.log(membership,"what is this")
      return (
        <div className="flex row">
          <Description title={membership.name} />
          {membership?.id && <MembershipPopUp membershipId={membership?.id} membershipData={membership} />}
        </div>
      );
    },
    ({ membership }) => {
      return <Description title={membership.cost} />;
    },
    ({ status, expiry_date, purchased_at }) => {
      console.log(status,"check here ")
      console.log(expiry_date, purchased_at,"2nd check ")
      if (status === "EXPIRED" && expiry_date) {
        if (purchased_at) {
          return (
            <Description
              title={status}
              description={`${new Date(purchased_at).toLocaleDateString()} - ${new Date(
                expiry_date
              ).toLocaleDateString()}`}
            />
          );
        } else {
          return <Description title={status} description={expiry_date} />;
        }
      }
      return <Description title={status} />;
    },
    (data) => {
      return (
        <Popup
          trigger={
            <Icon
              className="icon info"
              size="large"
              onClick={() => {
                setSelectedMembershipUsageHistory({ id: data.id, show: true, membership: data });
              }}
            />
          }
          position="top right"
        >
          <Translate>Usage History</Translate>
        </Popup>
      );
    },
  ];

  return [
    viewMembership && (
      <Modal
        zIndex={1}
        key={1}
        close={() => {
          closeMemberhship(true);
          setSelectedMembershipUsageHistory({ id: null, show: false, membership: null });
        }}
      >
        {clientMemberships.length > 0 ? (
          <>
            <h3>
              <Translate>Client membership history</Translate>
            </h3>
            <div className="membership-history">
              <HoverTable
                header={clientMembershipsHeaders}
                src={clientMemberships}
                renderSpecial={clientMembershipsRenderSpecial}
              />
            </div>
          </>
        ) : (
          <div className="ClientMembership-Container">
            <Translate>Client has no membership history</Translate>
          </div>
        )}
      </Modal>
    ),
    selectedMembershipUsageHistory.show && (
      <Modal
        zIndex={2}
        key={2}
        close={() => {
          setSelectedMembershipUsageHistory({ id: null, show: false, membership: null });
        }}
      >
        <ClientMembershipUsageHistory
          client_membership_id={selectedMembershipUsageHistory.id}
          showInvoice={(data) => setShowInvoice(data)}
          membership={selectedMembershipUsageHistory.membership}
        />
      </Modal>
    ),
    showInvoice.show && (
      <Modal zIndex={3} key={3} close={() => setShowInvoice({ ...showInvoice, show: false })}>
        <GenerateInvoice data={showInvoice} />
      </Modal>
    ),
  ];
}

export default ClientMembershipHistory;
