import React from "react";
import { SideNavContainer, SideNavHide, SideNavMain } from "./styles/styles";
import { Icon } from "semantic-ui-react";
import "./index.scss";

export default function SideNav({ condition, onCross, children, style, ...restOfTheProps }) {
  return (
    <>
      <SideNavMain condition={condition} {...restOfTheProps}>
        <Icon onClick={onCross} className="close sideNav-close" />
        <SideNavContainer
          style={
            {
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              ...style
            }
          }
        >
          {children}
        </SideNavContainer>
      </SideNavMain>
      <SideNavHide onClick={onCross} condition={condition} />
    </>
  );
}
