const init = {
  data: [],
  success: false,
  error: false,
};
export function commission(state = init, action) {
  switch (action.type) {
    case "COMMISSION_FETCH_SUCCESS":
      return {
        ...state,
        data: action.brackets,
        success: false,
        error: false,
      };

    case "COMMISSION_FETCH_FAILED":
      return {
        ...state,
        success: false,
        error: true,
      };

    case "COMMISSION_FETCH_RESET":
      return {
        ...state,
        success: false,
        error: false,
      };

    case "COMMISSION_OP_SUCCESS":
      return {
        ...state,
        success: true,
        error: false,
      };
    case "COMMISSION_OP_FAILED":
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
}
