export var withLoader = (state = {}, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        [action.id]: {
          isLoading: true,
          text: action.text,
        },
      };

    case "END_LOADING":
      return {
        ...state,
        [action.id]: {
          isLoading: false,
        },
      };

    case "ERRORED":
      return {
        ...state,
        [action.id]: {
          hasErrored: action.status,
        },
      };

    default:
      return state;
  }
};
