import React, { useEffect, useState } from "react";
import HoverTable from "HoverTable";
import Description from "Description";
import Modal from "Modal";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerLoyaltyPointTransactions } from "../../store/actions/loyaltyPoints";
import { Button, Form as Forms, Icon } from "semantic-ui-react";
import moment from "moment";
import { Translate } from "react-auto-translate";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { customersFetchData } from "../../store/actions/customer";
import "../../containers/CustomerWallet/index.scss";
import { updateCustomerLoyaltyPointsApi } from "../../api/updateCustomerLoyaltyPointsApi";
import CompletionOtpForm from "../CompletionOtpForm";

const tableHeader = ["Credit", "Debit", "Date"];

const renderSpecial = [
  ({ credit }) => {
    return <Description title={credit} />;
  },
  ({ debit }) => {
    return <Description title={debit} />;
  },
  ({ date }) => {
    return <Description title={date} />;
  },
];

function CustomerLoyaltyPointsDetails({ customer, appliedCustomerFilters }) {
  const dispatch = useDispatch();

  const loyaltyPointsTransactions = useSelector((state) => state.customerLoyaltyPointTransactions);
  const { data: branchData } = useSelector((state) => state.branch);

  const [points, setPoints] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);

  const { loyalty_point_otp } = branchData;

  function updatePointsSuccess() {
    dispatch(getCustomerLoyaltyPointTransactions(customer.id));
    // to refresh the client list
    const { search, clientFilter, currentPage } = appliedCustomerFilters;
    dispatch(customersFetchData(search, clientFilter, currentPage));
    setPoints("");
  }

  const updateCustomerLoyaltyPointsHandler = async ({ otp = "" }) => {
    const data = {
      customer: customer.id,
      credit: points,
      debit: 0,
      date: moment(new Date()).format("YYYY-MM-DD"),
      ...(loyalty_point_otp ? { customer_otp: otp } : {}),
    };

    const res = await updateCustomerLoyaltyPointsApi({ data, adding: true });
    if (res.status === "success") {
      updatePointsSuccess();
      setShowOtpModal(false);
    }
    return res;
  };

  function addPointsHandler() {
    if (!points.length || parseInt(points) < 0) {
      ToastAlert({ message: "Invalid Number", type: "error" });
      return;
    }
    const subscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

    if (loyalty_point_otp && !subscriptionExpired) {
      setShowOtpModal(true);
    } else {
      updateCustomerLoyaltyPointsHandler({});
    }
  }

  const handleOTPFormSubmit = ({ clickedButton = 0, data }) => {
    const { otp } = data;
    updateCustomerLoyaltyPointsHandler({ otp });
  };

  useEffect(() => {
    dispatch(getCustomerLoyaltyPointTransactions(customer.id));
  }, []);

  return (
    <div className="customer-wallet">
      {showOtpModal && (
        <Modal close={() => setShowOtpModal(false)}>
          <CompletionOtpForm
            customer_id={customer.id}
            otp_type="LOYALTY_POINT"
            primaryButtonText="Add"
            onComplete={(res) => handleOTPFormSubmit(res)}
            close={() => {
              setShowOtpModal(false);
            }}
          />
        </Modal>
      )}
      <div className="wallet-list-table">
        <HoverTable
          header={tableHeader}
          src={loyaltyPointsTransactions}
          renderSpecial={renderSpecial}
          toPaginate={true}
          changePage={(pageNum) => dispatch(getCustomerLoyaltyPointTransactions(customer.id, pageNum))}
        />
      </div>
      <div className="wallet-form">
        <h3>
          <Translate>Add Loyalty Points:</Translate>
        </h3>
        <Forms>
          <Forms.Field>
            <label>
              <Translate>Add Points</Translate>
            </label>
            <input
              type="number"
              maxLength={20}
              placeholder="0"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
          </Forms.Field>
        </Forms>
        <div className="actions">
          <button className="ui button" onClick={addPointsHandler}>
            <Translate>Add</Translate>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomerLoyaltyPointsDetails;
