import React, { useEffect, useState } from "react";
import "./index.scss";

import { Checkbox, Divider, Label, Loader } from "semantic-ui-react";

import HoverTable from "HoverTable";
import Description from "Description";

import { getReadableDateTime } from "../../utilities/dateTime";
import { findObjectById } from "../../utilities";

import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import { clientMembershipUsageHistory } from "../../store/actions/clientMembershipUsageHistory";

function ClientMembershipUsageHistory({ client_membership_id, showInvoice, membership }) {
  const dispatch = useDispatch();
  const client_membership_usage = useSelector((state) => state.clientMembershipUsageHistory);
  const branch = useSelector((state) => state.branch.data);
  const branch_services = useSelector((state) => state.services.data);
  const [showServiceHistory, SetShowServiseHisory] = useState(false);
  const [showProductHistory, SetShowProductHisory] = useState(false);
  const clientMembershipsUsageHistoryHeaders = ["Appointment", "Service", "Quantity", "Date"];
  const clientMembershipsUsageHistoryRenderSpecial = [
    ({ appointment_id, customer_id }) => {
      return (
        <a
          onClick={() => {
            showInvoice({
              id: appointment_id,
              customer: {
                id: customer_id,
              },
              show: true,
            });
          }}
        >
          #{branch?.invoice_pre_text}
          {appointment_id}
        </a>
      );
    },
    ({ service_id }) => {
      const service_details = findObjectById(branch_services, service_id);
      if (service_details) {
        return <Description title={service_details.name || "--"} />;
      }
      return <Description title={service_id || "--"} />;
    },
    ({ quantity }) => <Description title={quantity || "--"} />,
    ({ appointment_date }) => {
      return <Description title={getReadableDateTime(appointment_date)} />;
    },
  ];

  const clientMembershipsUsageHeaders = [
    "Service Name",
    "Usage Count",
    "Usage Count remaining",
    "Duration (mins)",
    "Duration remaining (mins)",
  ];

  const clientProductUsageHeaders = ["Product Name", "Usage Count", "Usage Count remaining"];

  const clientServiceUsageRenderSpecial = [
    ({ service }) => <Description title={service?.name || "--"} />,
    ({ used_count }) => <Description title={used_count || "--"} />,
    ({ remaining_count }) => <Description title={remaining_count ?? "--"} />,
    ({ used_duration_in_minutes }) => <Description title={`${used_duration_in_minutes || 0} mins`} />,
    ({ remaining_duration_in_minutes }) => (
      <Description
        title={
          remaining_duration_in_minutes || remaining_duration_in_minutes === 0
            ? `${remaining_duration_in_minutes} mins left`
            : ""
        }
      />
    ),
  ];

  const clientProductRenderSpecial = [
    ({ product }) => <Description title={product?.name || "--"} />,
    ({ used_count }) => <Description title={used_count || "--"} />,
    ({ remaining_count }) => <Description title={remaining_count ?? "--"} />,
  ];

  useEffect(() => {
    dispatch(clientMembershipUsageHistory(client_membership_id));
  }, [dispatch, client_membership_id]);

  const hasUsageInfo = membership?.usage?.length !== 0;

  return [
    <div key={1} className="membership_usage">
      {client_membership_usage.loading && <Loader />}
      {client_membership_usage.error && (
        <span>
          <Translate>An error has occurred. Please try again!</Translate>
        </span>
      )}
      {!client_membership_usage.error &&
        !client_membership_usage.loading &&
        !client_membership_usage.data.length &&
        !hasUsageInfo &&
        membership?.product_usage?.length === 0 && (
          <span>
            <Translate>No usage history found.</Translate>
          </span>
        )}
      <div>
        <h3>
          <Translate>Usage Information</Translate>
        </h3>
        <div className="usage-data">
          <Checkbox
            label="Service"
            checked={showServiceHistory}
            onChange={() => SetShowServiseHisory(!showServiceHistory)}
          />
          {showServiceHistory &&
            (hasUsageInfo ? (
              <HoverTable
                header={clientMembershipsUsageHeaders}
                src={membership.usage}
                renderSpecial={clientServiceUsageRenderSpecial}
              />
            ) : (
              <div className="no-data-txt">
                <Translate>No usage history found.</Translate>
              </div>
            ))}
        </div>
        <div className="usage-data">
          <Checkbox
            label="Product"
            checked={showProductHistory}
            onChange={() => SetShowProductHisory(!showProductHistory)}
          />
          {showProductHistory &&
            (membership?.product_usage?.length !== 0 ? (
              <HoverTable
                header={clientProductUsageHeaders}
                src={membership.product_usage}
                renderSpecial={clientProductRenderSpecial}
              />
            ) : (
              <div className="no-data-txt">
                <Translate>No usage history found.</Translate>
              </div>
            ))}
        </div>
      </div>

      {!!client_membership_usage.data.length && (
        <>
          <h3>
            <Translate>Membership Usage History</Translate>
          </h3>
          <HoverTable
            header={clientMembershipsUsageHistoryHeaders}
            src={client_membership_usage.data}
            renderSpecial={clientMembershipsUsageHistoryRenderSpecial}
          />
        </>
      )}
    </div>,
  ];
}

export default ClientMembershipUsageHistory;
