import styled from "styled-components/macro";

export const SideNavMain = styled.div`
  @media (max-width: 800px) {
    width: 100%;
    ${(props) =>
      props.usesDnd
        ? `width: ${props.condition ? "100%" : "0"};`
        : `transform: ${props.condition ? "translateX(0%)" : "translateX(100%)"};`}
  }
  /* transform: ${(props) => (props.condition ? "translateX(0%)" : "translateX(100%)")}; */
  height: 100%; /* 100% Full-height */
  width: 600px;
  ${(props) =>
    props.usesDnd
      ? `width: ${props.condition ? "600px" : "0"};`
      : `transform: ${props.condition ? "translateX(0%)" : "translateX(100%)"};`}
  /* width: ${(props) => (props.condition ? "35%" : "0")};  */
    position: fixed; /* Stay in place */
  z-index: 1001; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: var(--theme-white); /* White*/
  color: var(--primary-black);
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 15px; /* Place content 15px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  border: 1px solid gray;
`;

export const SideNavHide = styled.div`
  padding: 0;
  margin: 0;
  z-index: 1000;
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: ${(props) => (props.condition ? "100%" : "0")};
  background-color: ${(props) => (props.condition ? "rgba(0,0,0,0.4)" : "transparent")};
  transition: background-color 0.5s;
  overflow: hidden;
`;

export const SideNavContainer = styled.div`
  padding: 0 20px;
`;
