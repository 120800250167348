import React from "react";
import "./index.scss";
import { BrowserView, MobileView } from "react-device-detect";


function Accordion({ onClick, isOpen, children, action_title = "Today's Report" }) {
  const open = isOpen ? "content slide-up" : "content content-padding slide-down";
  return (
    <div className="accordion">
      <BrowserView>
        <div className={open}>{children}</div>
      </BrowserView>
      <MobileView>
        <div className={!isOpen ? "content slide-up" : "content content-padding slide-down"}>{children}</div>
      </MobileView>
    </div>
  );
}

export default Accordion;
