//if partner, change all the "appoitnment" to "booking"
import { IS_PARTNER } from "./constants";
const APPOINTMENT_LABEL = IS_PARTNER ? "Booking" : "Appointment";

export const errorMessage = "Oops! Something Went Wrong, Please Try Again Later";

export const aclGrouptActions = {
  success_create: `Successfully Created Access Type`,
  success_update: `Successfully Updated Access Type`,
  success_delete: `Successfully Deleted Access Type`,
};

export const appointmentActions = {
  success_create: `Successfully Created ${APPOINTMENT_LABEL}`,
  success_update: `Successfully Updated ${APPOINTMENT_LABEL}`,
  success_delete: `Successfully Deleted ${APPOINTMENT_LABEL}`,
};

export const customerWalletActions = {
  success_create: "Successfully Added Customer Payment",
  success_update: "Successfully Updated Customer Payment",
  success_delete: "Successfully Deleted Customer Payment",
};

export const promotionActions = {
  success_create: "Successfully Sent",
  success_update: "Successfully Updated",
  success_delete: "Successfully Deleted",
};

export const positionActions = {
  success_create: "Successfully Created Position",
  success_update: "Successfully Updated Position",
  success_delete: "Successfully Deleted Position",
};

export const overtimeActions = {
  success_create: "Successfully Added Overtime",
  success_update: "Successfully Updated Overtime",
  success_delete: "Successfully Deleted Overtime",
};

export const salaryBracketActions = {
  success_create: "Successfully Created Salary Bracket",
  success_update: "Successfully Updated Salary Bracket",
  success_delete: "Successfully Deleted Salary Bracket",
};

export const shiftActions = {
  success_create: "Successfully Added Shift",
  success_update: "Successfully Updated Shift",
  success_delete: "Successfully Deleted Shift",
};

export const commissionActions = {
  success_create: "Successfully Added Commission",
  success_update: "Successfully Updated Commission",
  success_delete: "Successfully Deleted Commission",
};

export const staffActions = {
  success_create: "Successfully Added Staff",
  success_update: "Successfully Updated Staff",
  success_delete: "Successfully Deleted Staff",
};

export const staffSalaryActions = {
  success_update: "Successfully Updated Staff Salary",
};

export const attendenceActions = {
  success_create: "Successfully Added Attendence",
  success_update: "Successfully Updated Attendence",
  success_delete: "Successfully Deleted Attendence",
};

export const absentActions = {
  success_create: "Successfully Added Absence",
  success_update: "Successfully Updated Absence",
  success_delete: "Successfully Deleted Absence",
};

export const membershipActions = {
  success_create: "Successfully Created Membership Plan",
  success_update: "Successfully Updated Membership Plan",
  success_delete: "Successfully Deleted Membership Plan",
};

export const campaignActions = {
  success_create: "Successfully Created Discount",
  success_update: "Successfully Updated Discount",
  success_delete: "Successfully Deleted Discount",
};

export const expenseActions = {
  success_create: "Successfully Added Expense",
  success_update: "Successfully Updated Expense",
  success_delete: "Successfully Deleted Expense",
};

export const expenseCategoriesActions = {
  success_create: "Successfully Added Expense Category",
  success_update: "Successfully Updated Expense Category",
  success_delete: "Successfully Deleted Expense Category",
};

export const serviceActions = {
  success_create: "Successfully Added Service",
  success_update: "Successfully Updated Service",
  success_delete: "Successfully Deleted Service",
};

export const productActions = {
  success_create: "Successfully Added Product",
  success_update: "Successfully Updated Product",
  success_delete: "Successfully Deleted Product",
};

export const companyActions = {
  success_create: "Successfully Added Company Details",
  success_update: "Successfully Updated Company Details",
  success_delete: "Successfully Deleted Company Details",
};

export const sellersActions = {
  success_create: "Successfully Added A Seller",
  success_update: "Successfully Updated The Seller",
  success_delete: "Successfully Deleted The Seller",
};

export const inventoryDetailsActions = {
  success_create: "Successfully Added Inventory Detail",
  success_update: "Successfully Updated The Inventory Detail",
  success_delete: "Successfully Deleted The Inventory Detail",
};

export const clientActions = {
  success_create: "Successfully Added Client",
  success_update: "Successfully Updated Client",
  success_delete: "Successfully Deleted Client",
};

export const labelActions = {
  success_create: "Successfully Created Label",
  success_update: "Successfully Updated Label",
  success_delete: "Successfully Deleted Label",
};

export const userDetailsActions = {
  success_create: "Successfully Added User Details",
  success_update: "Successfully Updated User Details",
  success_delete: "Successfully Deleted User Details",
};

export const reviewLinkActions = {
  success_create: "Successfully Added Review Link",
  success_update: "Successfully Updated Review Link",
  success_delete: "Successfully Deleted Review Link",
};

export const barnchShortNameActions = {
  success_create: "Successfully Added Branch Short Name",
  success_update: "Successfully Updated Branch Short Name",
  success_delete: "Successfully Deleted Branch Short Name",
};

export const customerLabelsActions = {
  success_create: "Successfully Added Labels",
  success_update: "Successfully Updated Labels",
  success_delete: "Successfully Deleted Labels",
};

export const serviceLabelsActions = {
  success_create: "Successfully Added Labels",
  success_update: "Successfully Updated Labels",
  success_delete: "Successfully Deleted Labels",
};

export const productUsageActions = {
  success_update: "Successfully Updated Product Usage",
};

export const productLabelsActions = {
  success_create: "Successfully Added Labels",
  success_update: "Successfully Updated Labels",
  success_delete: "Successfully Deleted Labels",
};

export const walletLabelsActions = {
  expense_aggregate_fail: "Expenses data fetch failed",
};
