import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteScheduledPromotion } from "../../store/actions/promotion";
import { Icon, Label, Popup } from "semantic-ui-react";

import HoverTable from "HoverTable";
import Description from "Description";
import moment from "moment";
import { convertUtcToLocal } from "../../utilities/dateTime";
import Toggler from "../Toggler/index";
import { bulkPromotionViewFetchData } from "../../store/actions/bulkPromotionView";
import { Translate } from "react-auto-translate";

const PROMOTION_TYPE = {
  0: "EMAIL",
  1: "SMS",
  2: "WHATSAPP",
};

const HEADERS = ["Type", "Message", "Count sent/total", "Scheduled", "Info", "Status", "Action"];
const SKIPPABLE_HOURS = -2;

const ErrorPopup = ({ message }) => (
  <Popup
    trigger={
      <Label color="red" size="tiny">
        Error
      </Label>
    }
  >
    {message}
  </Popup>
);
const MessagePopup = ({ message }) => <Popup trigger={<span className="showmore">...</span>}>{message}</Popup>;
const IconPopup = ({ disabled, icon, click, content }) => (
  <Popup trigger={<Icon disabled={disabled} name={icon} onClick={click} />} position="bottom right" content={content} />
);

const NOTIFICATION_STATUS = {
  0: "RECEIVED",
  1: "PENDING",
  2: "SENT",
  3: "FAILED",
  4: "INVALID",
};

const LABELCOLOR = {
  0: "olive",
  1: "yellow",
  2: "green",
  3: "red",
  4: "orange",
};

const TYPELABELCOLOR = {
  0: "yellow",
  1: "orange",
  2: "green",
};

const historyViewRenderer = [
  ({ type }) => {
    return (
      <Label size="tiny" as="a" color={TYPELABELCOLOR[type]}>
        {PROMOTION_TYPE[type]}
      </Label>
    );
  },
  ({ message }) => {
    const slicedMessage = message?.slice(0, 30) || "";

    return (
      <div className="flex message-description">
        <Description title={<Translate>{slicedMessage}</Translate>} />
        {message?.length > 30 && <MessagePopup message={<Translate>{message}</Translate>} />}
      </div>
    );
  },
  ({ contact_total_count, contact_sent_count }) => {
    if (contact_total_count || contact_sent_count) {
      return (
        <div className="promotion_sent_count">
          <Label color="green" size="tiny">
            {contact_sent_count || 0} / {contact_total_count || "∞"}
          </Label>
        </div>
      );
    } else {
      return (
        <div className="promotion_sent_count">
          <Label color="black" size="tiny">
            N/A
          </Label>
        </div>
      );
    }
  },
  ({ schedule }) => {
    if (schedule) {
      const localSchedule = convertUtcToLocal(schedule);
      return <Description title={localSchedule} />;
    }
    return (
      <Label color="black" size="tiny">
        <Translate>One Time</Translate>
      </Label>
    );
  },
  ({ contacts, image }) => {
    const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;
    let content = [];

    if (contacts) {
      content.push(
        <a
          key={0}
          href={contacts?.url}
          target="_blank"
          onClick={(e) => {
            if (isSubscriptionExpired) {
              e.preventDefault();
            }
          }}
          className="promotion-external-link"
          style={{ color: isSubscriptionExpired ? "gray" : "#4183c4" }}
        >
          <Translate>Contacts</Translate> <Icon name="external" link={true} size="small" />
        </a>
      );
    }
    if (image) {
      content.push(
        <a
          key={1}
          href={image?.url}
          target="_blank"
          onClick={(e) => {
            if (isSubscriptionExpired) {
              e.preventDefault();
            }
          }}
          className="promotion-external-link"
          style={{ color: isSubscriptionExpired ? "gray" : "#4183c4" }}
        >
          <Translate>Image</Translate> <Icon name="external" link={true} size="small" />
        </a>
      );
    }
    if (content.length) {
      return <div className="flex info-content">{content}</div>;
    }
    return (
      <Label color="black" size="tiny">
        N/A
      </Label>
    );
  },
  ({ status, skip, error_message }) => {
    if (error_message) {
      return <ErrorPopup message={error_message} />;
    }
    if (skip) {
      return (
        <Label size="tiny" as="a" color={LABELCOLOR[4]}>
          <Translate>Skipped</Translate>
        </Label>
      );
    }
    return (
      <Label size="tiny" as="a" color={LABELCOLOR[status]}>
        {NOTIFICATION_STATUS[status]}
      </Label>
    );
  },
  (data, edit, remove) => {
    const { id, recurring_id, schedule, skip } = data;
    let btnDisabled = true;
    if (!skip && schedule) {
      const scheduleInLocalDateTime = convertUtcToLocal(schedule);
      if (moment(scheduleInLocalDateTime).diff(moment()) >= 0) {
        // scheduleInLocalDateTime is in future
        btnDisabled = false;
        if (recurring_id && schedule) {
          btnDisabled = moment().diff(moment(scheduleInLocalDateTime), "hours") > SKIPPABLE_HOURS;
        }
      }
    }
    return (
      <IconPopup
        disabled={btnDisabled}
        icon={`${recurring_id ? "pointer " : ""}step forward`}
        click={() => {
          remove(id);
        }}
        content="Skip this round"
      />
    );
  },
];

function PromotionHistory(props) {
  const { show, promotionHistoryData } = props;
  const [hideSkipped, setHideSkipped] = useState(false);
  const dispatch = useDispatch();

  if (!show) return null;

  const toggleSkippedPromotions = () => {
    setHideSkipped(!hideSkipped);
  };

  const data = JSON.parse(JSON.stringify(promotionHistoryData));
  if (hideSkipped) {
    delete data.results;
    data.results = promotionHistoryData.results.filter((item) => !item.skip);
  }
  return (
    <>
      <Toggler
        toggle
        style={{
          maxWidth: "400px",
          minWidth: "200px",
          marginBottom: "20px",
        }}
        onChange={toggleSkippedPromotions}
        checked={hideSkipped}
        label="Hide Skipped Promotions"
      />
      <p className="info">
        <Translate> Promotions can only be</Translate>{" "}
        <strong>
          <Translate>skipped</Translate>
        </strong>
        <Translate> 2 hrs before schedule</Translate>
      </p>
      <HoverTable
        header={HEADERS}
        src={data}
        renderSpecial={historyViewRenderer}
        remove={(id) => dispatch(deleteScheduledPromotion(id))}
        toPaginate={true}
        changePage={(pageNum) => dispatch(bulkPromotionViewFetchData(pageNum))}
        defaultRowClassName="" // to now show the red row color
      />
    </>
  );
}

export default PromotionHistory;
