import React from "react";
import "./style.scss";

function Index({ imageUrl, min_price, max_price, title, onClick }) {
  return (
    <div className="product-container">
      <div className="img-container">
        {/* <i className="angle left icon" onClick={() => console.log('leftArrow')}></i> */}
        <img
          onClick={onClick}
          className="product-img"
          src={`${imageUrl.length ? imageUrl[0].url : "/no-image.png"} `}
          alt={title}
        />
        {/* <i className="angle right icon" onClick={() => console.log('rightArrow')}></i> */}
      </div>
      <div onClick={onClick} className="product-title">
        {title}
      </div>
      <div className="price-cart">
        <div className="product-price">
          Rs. {min_price}-{max_price}
        </div>
      </div>
    </div>
  );
}

export default Index;
