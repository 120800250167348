import { API_BASE_URL, CLIENT_API } from "utilities";
import _ from "lodash";
import axios from "axios";
import lodash from "lodash"
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export function clientFetchDataSuccess(client) {
  return {
    type: "CLIENT_FETCH_DATA_SUCCESS",
    client,
  };
}

export function clientsMapCreationSuccess(clients) {
  const clientsMap = lodash.keyBy(clients, "id");

  return {
    type: "CLIENTS_MAP_CREATION_SUCCESS",
    data: clientsMap,
  };
}

export function clientFetchData() {
  return (dispatch) => {
    dispatch(startLoading("CLIENT_LOADER", "loading client"));
    axios
      .get(API_BASE_URL + CLIENT_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(clientsMapCreationSuccess(response.data.results))
          dispatch(clientFetchDataSuccess(response.data.results));
          dispatch(endLoading("CLIENT_LOADER"));
        } else {
          dispatch(errored("CLIENT_LOADER", true));
          dispatch(endLoading("CLIENT_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CLIENT_LOADER", true));
        dispatch(endLoading("CLIENT_LOADER"));
      });
  };
}

export function getCumulativeInvoice(data,getInvoice) {
  return (dispatch) => {
    axios({
      method: "GET",
      url: API_BASE_URL + "cumulative/invoice/"+data.id+"/"+data.startDate+"/"+data.endDate+"/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => {
        getInvoice( res.data);
      })
      .catch((err) => {
        ToastAlert({ message: "There is no appointments for this dates", type: "error" });
        getInvoice("error");
      });
  };
}
