const initialState = {
    error: null,
    success: null,
    loading: false,
  };
  
  export function uploadDataFileReducer(state = initialState, action) {
    switch (action.type) {
      case "FILE_UPLOAD_ERROR":
        return { ...state, error: action.err, loading: false };
      case "FILE_UPLOAD_SUCCESS":
        return { ...state, success: action.success, loading: false };
      case "FILE_UPLOAD_RESET":
        return { ...state, error: null, success: null };
      case "FILE_UPLOAD_LOADER":
        return { ...state, loading: action.loading };
      default:
        return state;
    }
  }