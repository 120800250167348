import React, { useState, useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";

import { Icon, Dropdown } from "semantic-ui-react";

import HoverTable from "HoverTable";
import Description from "Description";
import Ellipsis from "Ellipsis";

import { Form as NewComponentForm } from "../../new-components";
import { createGroupPermissions, updateGroupPermissions } from "../../store/actions/aclGroupPermissions";
import { Translate } from "react-auto-translate";

const accessLevelHeaders = ["Access Type", "Accessible Pages", "Accessible Components", "Action"];

const accessLevelRenderSpecial = [
  ({ name }) => {
    return <Description title={name} />;
  },
  ({ base_permissions, is_active }) => {
    const _base_permissions = base_permissions.map((permission) => permission.name).join(", ");
    return <Description description={<Ellipsis text={_base_permissions} />} status={is_active} />;
  },
  ({ component_permissions, is_active }) => {
    const _component_permissions = component_permissions.map((permission) => permission.name).join(", ");
    return <Description description={<Ellipsis text={_component_permissions} />} status={is_active} />;
  },
  (value, edit, remove) => {
    if (value.is_base_group) {
      return [];
    }
    return [
      <Icon key={0} className="pointer" name="edit" onClick={() => edit(value.id, value)} />,
      <Icon key={1} className="pointer trash" onClick={() => remove(value.id, value)} />,
    ];
  },
];

function AclSidebarView({ isClosed, filteredAclAllGroupsPermissions }) {
  const dispatch = useDispatch();
  const { data: aclBasePermissionsOptions } = useSelector((state) => state.aclBasePermissionsOptions);
  const { data: aclComponentsPermissionsOptions } = useSelector((state) => state.aclComponentsPermissionsOptions);
  const [accessControlFormData, setAccessControlFormData] = useState({
    id: null,
    accessType: "",
    accessiblePages: [],
    accessibleComponents: [],
    update: false,
    error: false,
  });

  useEffect(() => {
    if (isClosed) {
      resetAccessControlForm();
    }
  }, [isClosed]);

  //mapping of acl options
  const mappedAclBasePermissionsOptions = aclBasePermissionsOptions.map((permission) => {
    return { key: permission.id, text: permission.name, value: permission.id };
  });
  const mappedAclComponentPermissionsOptions = aclComponentsPermissionsOptions.map((permission) => {
    return { key: permission.id, text: permission.name, value: permission.id };
  });

  const editAccessControlFormData = (id, accessType) => {
    const accessiblePages = accessType.base_permissions.map((group) => group.id);
    const accessibleComponents = accessType.component_permissions.map((group) => group.id);

    setAccessControlFormData((prevState) => {
      return {
        ...prevState.accessControlFormData,
        id,
        accessType: accessType.name,
        accessiblePages,
        accessibleComponents,
        update: true,
        error: false,
      };
    });
  };

  const resetAccessControlForm = () => {
    setAccessControlFormData({
      id: null,
      accessType: "",
      accessiblePages: [],
      accessibleComponents: [],
      update: false,
      error: false,
    });
  };

  const accessTypeCreateUpdate = () => {
    const { id, accessType, accessiblePages, accessibleComponents, update } = accessControlFormData;

    // form validation
    if (!accessType || accessType.length < 3 || accessiblePages.length === 0) {
      setAccessControlFormData((prevState) => {
        return {
          ...prevState,
          error: true,
        };
      });
      return;
    }

    const serverData = {
      base_permissions: accessiblePages,
      component_permissions: accessibleComponents,
      name: accessType,
    };

    if (update) {
      dispatch(updateGroupPermissions(id, serverData, 1));
    } else {
      dispatch(createGroupPermissions(serverData));
    }
    resetAccessControlForm();
  };

  const accessTypeDelete = (id, accessType) => {
    const accessiblePages = accessType.base_permissions.map((group) => group.id);
    const accessibleComponents = accessType.component_permissions.map((group) => group.id);

    const serverData = {
      ...accessType,
      base_permissions: accessiblePages,
      component_permissions: accessibleComponents,
      is_active: false,
    };

    dispatch(updateGroupPermissions(id, serverData, 0));
    resetAccessControlForm();
  };

  return (
    <div>
      <NewComponentForm>
        <NewComponentForm.Title><Translate>Access Level Control</Translate></NewComponentForm.Title>
        <NewComponentForm.Divider> </NewComponentForm.Divider>
      </NewComponentForm>
      <h4 className={accessControlFormData.update ? "StaffEditModeON" : "StaffEditModeOFF"}>
        <Translate>Edit mode :</Translate> {accessControlFormData.update ? "ON" : "OFF"}
      </h4>
      <div style={{ fontWeight: "bold", fontSize: "15px", marginTop: "10px" }}>Create/Update Access Level *</div>
      {/* textfield and dropdowns */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <NewComponentForm.FieldContainer>
          <NewComponentForm.TextFieldContainer>
            <NewComponentForm.TextField
              value={accessControlFormData.accessType}
              onChange={(e) => {
                const value = e.target.value;
                setAccessControlFormData((prevState) => {
                  return {
                    ...prevState,
                    accessType: value,
                    error: false,
                  };
                });
              }}
              type="text"
              placeholder="Access Type Name"
            />
          </NewComponentForm.TextFieldContainer>
        </NewComponentForm.FieldContainer>

        {/* dropdowns */}
        <div style={{ marginBottom: "20px" }}>
          {/* page selector in access sidenav */}
          <div style={{ flex: 6 }}>
            <div style={{ fontWeight: "bold", fontSize: "15px" }}><Translate>Allow Pages *</Translate></div>
            <Dropdown
              style={{ marginBottom: "20px", marginTop: "5px" }}
              placeholder="Allow Pages"
              fluid
              multiple
              search
              selection
              value={accessControlFormData.accessiblePages}
              options={mappedAclBasePermissionsOptions}
              onChange={(e, value) => {
                setAccessControlFormData((prevState) => {
                  return {
                    ...prevState,
                    accessiblePages: value.value,
                    error: false,
                  };
                });
              }}
            />
          </div>
          {/* component selector in access sidenav */}
          <div style={{ flex: 6 }}>
            <div style={{ fontWeight: "bold", fontSize: "15px" }}><Translate>Allow Components</Translate></div>
            <Dropdown
              style={{ marginBottom: "10px", marginTop: "5px" }}
              placeholder="Allow Components"
              fluid
              multiple
              search
              selection
              value={accessControlFormData.accessibleComponents}
              options={mappedAclComponentPermissionsOptions}
              onChange={(e, value) => {
                setAccessControlFormData((prevState) => {
                  return {
                    ...prevState,
                    accessibleComponents: value.value,
                    error: false,
                  };
                });
              }}
            />
          </div>
        </div>
      </div>
      {/* showing error */}
      {accessControlFormData.error ? <span style={{ color: "red" }}><Translate>Please fill all the required fields</Translate></span> : null}

      <NewComponentForm.SubmitButton
        onClick={() => {
          accessTypeCreateUpdate();
        }}
      >{accessControlFormData.update ? <Translate>Update</Translate> : <Translate>Create</Translate>}
      </NewComponentForm.SubmitButton>

      {/* hovertable */}
      {filteredAclAllGroupsPermissions.length > 0 ? (
        <div
          style={{
            maxHeight: "400px",
            overflow: "auto",
            marginTop: "50px",
            marginBottom: "10px",
          }}
        >
          <div style={{ width: "100%" }}>
            <HoverTable
              header={accessLevelHeaders}
              src={filteredAclAllGroupsPermissions}
              renderSpecial={accessLevelRenderSpecial}
              edit={(id, accessType) => editAccessControlFormData(id, accessType)}
              remove={(id, accessType) => accessTypeDelete(id, accessType)}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginBottom: "20px" }}><Translate>No Access Type Found</Translate></div>
      )}
    </div>
  );
}

export default AclSidebarView;
