import React from "react";
import "./index.scss";
import moment from "moment";
import HoverTable from "HoverTable";
import Modal from "Modal";
import CustomSearch from "CustomSearch";
import Description from "Description";
import GenerateInvoice from "GenerateInvoice";
import { Button, Icon, Dropdown, Pagination } from "semantic-ui-react";
import { toast } from "react-toastify";
import { invoicesAction } from "../../store/actions/appointments";

import { appointmentsFetchData, appointmentsInvoices, appointmentUpdate } from "../../store/actions/appointments";
import { connect } from "react-redux";
import _, { debounce } from "lodash";
import { downloadReportFromServer } from "../../store/actions/downloadReportsFromServer";

import DatesDropdown from "../../components/DatesDropdown/datesDropdown";
import { getInvoiceFilters } from "../../store/actions/invoices";
import { calcDateDifference } from "../../utilities/CommonFunctions";
import { Translate } from "react-auto-translate";
import AppointmentDeleteModal from "../../components/AppointmentDeleteModal";
import { getAppoitmentDeleteData } from "../../api/getAppointmentDeleteData";
import { handleMaskNumber } from "../../utilities/CommonFunctions";
import CustomerFeedback from "../../components/CustomerFeedback";
import { appointmentFeedbackReset } from "../../store/actions/appointmentFeedback";
import { servicesFetchData } from "../../store/actions/services";
import { LoadingState } from "../../store/reducers/types";
import { productsFetchData } from "../../store/actions/products";

const tableHeadersServices = [
  "ID",
  "Appointment Date/Time",
  "Customer Details",
  "Services Used",
  "Products Used",
  "Feedbacks",
  "Total",
  "Actions",
];

const pageSize = 15;

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generateInvoice: false,
      generateInvoiceAppointment: {},
      search: "",
      feedbackModal: {
        isOpen: false,
        id: null,
      },
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      invoiceFilter: null,
      openAppointmentDeleteModal: false,
      deleteAppointmentsAffected: [],
      deleteAppointmentId: null,
      generateInvoicePayload: null,
      deleteInvoiceInProgress: false,
      deletingInvoiceId: null,
      selectedPage: 1,
      confirmDelete: false,
      invoiceId: null,
    };
  }

  componentDidMount() {
    const { start_date, end_date, search, selectedPage } = this.state;
    const { services, products } = this.props;
    const services_loaded = services.loading === LoadingState.loaded;
    const products_loaded = products.loading === LoadingState.loaded;
    Date.prototype.getWeekNumber = function () {
      let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
      let dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    };

    this.props.fetchInvoices(search, start_date, end_date, 3, this.state.invoiceFilter, pageSize, selectedPage);
    this.props.fetchFilters();
    if (!services_loaded) {
      this.props.fetchServices();
    }
    if (!products_loaded) {
      this.props.fetchProducts();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.addAppointmentPaymentMethod.loading !== this.props.addAppointmentPaymentMethod.loading &&
      this.props.addAppointmentPaymentMethod.loading
    ) {
      const { start_date, end_date, invoiceFilter, search, selectedPage } = this.state;
      this.props.fetchInvoices(search, start_date, end_date, 3, invoiceFilter, pageSize, selectedPage);
    }
  }

  renderSpecial({ deleteInvoiceInProgress, deletingInvoiceId }) {
    const { invoiceFilter } = this.state;
    const { formatted_component_permissions } = this.props.aclUserPermissions;
    const { invoice_pre_text } = this.props.branch;

    return [
      ({ appointment_number, payable_amount, paid_amount }) => {
        return (
          <div className="invoice-number">
            <Description title={`#${invoice_pre_text}${appointment_number}`} />
            {payable_amount != 0 && <span className="invoice-indicator" />}
          </div>
        );
      },
      ({ datetime }) => {
        return <Description title={moment(datetime).format("DD-MM-YY hh:mm")} />;
      },
      ({ customer }) => {
        const maskedNumber = handleMaskNumber(customer.number, formatted_component_permissions);
        return <Description title={customer.name} description={maskedNumber} />;
      },
      (data) => {
        const { required_services } = data;
        let value = "N/A";
        if (required_services.length) {
          value = required_services
            .filter(({ status }) => status != 4)
            .map((item) => item.name)
            .join(", ");
        }
        return <Description title={value} />;
      },
      (data) => {
        const { required_products } = data;
        let value = "N/A";
        if (required_products.length) {
          value = required_products
            .filter(({ status }) => status != 4)
            .map((item) => item.name)
            .join(", ");
        }
        return <Description title={value} />;
      },
      ({ id, feedback_rating }) => {
        return (
          <span
            className={feedback_rating !== "N/A" ? "clickable" : ""}
            onClick={() => {
              if (feedback_rating !== "N/A") {
                this.openFeedbackModal(id);
              }
            }}
          >
            <Description title={feedback_rating} />
          </span>
        );
      },
      ({ total }) => {
        return <span>&#x20B9; {total}/- </span>;
      },

      (value, edit, remove) => {
        const isDeleting = deleteInvoiceInProgress && deletingInvoiceId === value.invoice_id;

        let actionList = [
          <Icon
            key={0}
            className="rupee sign pointer"
            title={"generate invoice"}
            name="edit"
            size="large"
            onClick={() => edit(value)}
          />,
        ];
        if (invoiceFilter !== 3) {
          actionList.push(
            <Icon
              loading={isDeleting}
              disabled={isDeleting}
              key={0}
              className={isDeleting ? "sync" : "trash sign pointer"}
              title={"delete"}
              name="edit"
              size="large"
              onClick={() => {
                remove(value);
              }}
            />
          );
        }
        return actionList;
      },
    ];
  }

  generateInvoice(appointment) {
    this.setState({
      generateInvoiceAppointment: appointment,
      generateInvoice: true,
    });
  }

  closeModal() {
    const { start_date, end_date, invoiceFilter, search, selectedPage } = this.state;
    this.props.fetchInvoices(search, start_date, end_date, 3, invoiceFilter, pageSize, selectedPage);
    this.setState({
      generateInvoice: false,
      confirmDelete: false,
    });
  }

  searchInvoice = debounce(
    (search) => {
      const { start_date, end_date, invoiceFilter } = this.state;

      // always select page 1 whenever changing any filter, date, serach
      const selectedPage = 1;

      this.setState({
        search,
        selectedPage,
      });
      if (search === "") {
        this.dateChange(this.state.selected_date);
        return;
      }
      this.props.fetchInvoices(search, start_date, end_date, 3, invoiceFilter, pageSize, selectedPage);
    },
    [300]
  );

  searchInvoices = debounce(
    (selectedPage) => {
      const { search, start_date, end_date, invoiceFilter } = this.state;
      this.setState({
        selectedPage,
      });

      this.props.fetchInvoices(search, start_date, end_date, 3, invoiceFilter, pageSize, selectedPage);
    },
    [300]
  );

  onDateChange(start_date, end_date) {
    const { invoiceFilter, search } = this.state;

    // always select page 1 whenever changing any filter, date, serach
    const selectedPage = 1;

    this.setState({
      start_date,
      end_date,
      selectedPage,
    });

    this.props.fetchInvoices(search, start_date, end_date, 3, invoiceFilter, pageSize, selectedPage);
  }

  setInvoiceFilter = (filter) => {
    // always select page 1 whenever changing any filter, date, serach
    const selectedPage = 1;

    this.setState({ invoiceFilter: filter, selectedPage });

    this.props.fetchInvoices(
      this.state.search,
      this.state.start_date,
      this.state.end_date,
      3,
      filter,
      pageSize,
      selectedPage
    );
  };

  downloadInvoicePDF = (type, item_type = "") => {
    const { start_date, end_date, invoiceFilter, search } = this.state;
    const { branch } = this.props;
    const invoiceFilterValue = invoiceFilter ? `${invoiceFilter}` : null;
    const params = {
      start_date: start_date,
      end_date: end_date,
      filter: invoiceFilterValue,
      search: search,
      branch_id: branch.id,
      ...(item_type && { item_type }),
    };
    let downloadPayload = { type, params };
    this.props.downloadReportFromServer(downloadPayload);
  };

  deleteAppointment = async (invoice_id, status = "delete") => {
    this.setState({ deleteInvoiceInProgress: true, deletingInvoiceId: invoice_id });
    const { response } = await getAppoitmentDeleteData(invoice_id);

    if (!response) {
      this.setState({ deleteInvoiceInProgress: false, deletingInvoiceId: null });
      return;
    }

    if (status === "delete") {
      if (response?.data?.data === null) {
        this.props.appointmentUpdate(invoice_id, null, null, null, "DELETE", null).then((data) => {
          if (data.status === "success") {
            this.closeModal();
          }
          this.setState({ deleteInvoiceInProgress: false, deletingInvoiceId: null });
        });
        return;
      } else {
        this.setState({
          deleteAppointmentId: invoice_id,
          openAppointmentDeleteModal: true,
          deleteAppointmentsAffected: response.data.data,
          deleteInvoiceInProgress: false,
          deletingInvoiceId: null,
        });
      }
    } else if ((status = "delete_confirm")) {
      this.props.appointmentUpdate(invoice_id, null, null, null, "DELETE", null).then((data) => {
        if (data.status === "success") {
          this.closeModal();
        }
        this.setState({ deleteInvoiceInProgress: false, deletingInvoiceId: null });
      });
      return;
    }
  };

  closeGenerateInvoice = () => {
    this.setState({
      generateInvoice: false,
    });
  };

  closeConfirmDeleteModal() {
    this.setState({
      confirmDelete: !this.state.confirmDelete,
      invoiceId: null,
    });
  }

  openFeedbackModal(id) {
    this.setState({
      feedbackModal: {
        isOpen: true,
        id,
      },
    });
  }

  closeFeedbackModal() {
    this.setState({
      feedbackModal: {
        isOpen: false,
        id: null,
      },
    });
    this.props.resetAppointmentFeedback();
  }

  render() {
    const {
      generateInvoice,
      generateInvoiceAppointment,
      start_date,
      end_date,
      invoiceFilter,
      openAppointmentDeleteModal,
      deleteAppointmentsAffected,
      deleteAppointmentId,
      selectedPage,
      confirmDelete,
      deleteInvoiceInProgress,
      invoiceId,
      feedbackModal,
    } = this.state;
    const { appointments, branch, invoiceFilters, aclUserPermissions, invoices } = this.props;
    const { formatted_component_permissions } = aclUserPermissions;
    const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");
    const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

    const invoiceFilterOptions = invoiceFilters.map((filter) => {
      return {
        key: filter.id,
        text: filter.name,
        value: filter.id,
      };
    });
    invoiceFilterOptions.push({
      key: -1,
      text: "Remove Filter",
      value: null,
    });

    const GST_INFO = {
      gst_applicable: branch.gst_applicable,
      gst_applicable_on_items: branch.gst_applicable_on_items,
      gst_bracket: branch.gst_bracket,
      sgst_applicable: branch.sgst_applicable,
    };

    return [
      <div key={0} className="invoices width100" style={{ position: "relative" }}>
        {openAppointmentDeleteModal && (
          <Modal
            zIndex={10}
            close={() => {
              this.setState({
                openAppointmentDeleteModal: false,
                deleteAppointmentsAffected: [],
              });
            }}
          >
            <AppointmentDeleteModal
              data={deleteAppointmentsAffected}
              onConfirm={() => {
                this.setState(
                  {
                    openAppointmentDeleteModal: false,
                    deleteAppointmentsAffected: [],
                  },
                  () => {
                    this.deleteAppointment(deleteAppointmentId, "delete_confirm");
                  }
                );
              }}
              onSkip={() => {
                this.setState({
                  openAppointmentDeleteModal: false,
                  deleteAppointmentsAffected: [],
                });
              }}
              onViewInvoice={({ id, customer }) => {
                this.setState(
                  {
                    openAppointmentDeleteModal: false,
                    deleteAppointmentsAffected: [],
                    generateInvoice: false,
                  },
                  () => {
                    const payload = {
                      id: id,
                      customer: {
                        id: customer,
                      },
                    };
                    this.generateInvoice(payload);
                  }
                );
              }}
            />
          </Modal>
        )}
        {feedbackModal.isOpen && (
          <Modal close={() => this.closeFeedbackModal()}>
            <CustomerFeedback id={feedbackModal.id} />
          </Modal>
        )}
        <div className="controller">
          <div className="controller-up">
            <div className="search-dropdown">
              <CustomSearch
                onChange={(search) => this.searchInvoice(search)}
                placeholder="search by customer name or number"
              />
            </div>
            {/* download buttons */}
            {invoices?.results?.length !== 0 && isDownloadReportsAllowed && (
              <div>
                {isSubscriptionExpired && <p style={{ fontSize: "16px", color: "red" }}>Subscription is Expired</p>}
                <div className="download-buttons">
                  <Dropdown
                    disabled={isSubscriptionExpired}
                    text="All"
                    icon="download"
                    floating
                    labeled
                    button
                    className="icon"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Format 1 CSV"
                        onClick={() => {
                          this.downloadInvoicePDF("AppointmentsReportFormat1");
                        }}
                      />
                      <Dropdown.Item
                        text="Format 2 CSV"
                        onClick={() => {
                          this.downloadInvoicePDF("AppointmentsReportFormat2");
                        }}
                      />
                      <Dropdown.Item
                        text="PDF"
                        onClick={() => {
                          this.downloadInvoicePDF("InvoicesPdfReport");
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>

                  <Button
                    disabled={isSubscriptionExpired}
                    key="download-service"
                    onClick={() => {
                      this.downloadInvoicePDF("AppointmentBilledItemsReport", "service");
                    }}
                  >
                    <Icon name="download" size="large" />
                    <Translate>Services</Translate>
                  </Button>

                  <Button
                    disabled={isSubscriptionExpired}
                    key="download-products"
                    onClick={() => {
                      this.downloadInvoicePDF("AppointmentBilledItemsReport", "product");
                    }}
                  >
                    <Icon name="download" size="large" />
                    <Translate>Products</Translate>
                  </Button>

                  <Button
                    disabled={isSubscriptionExpired}
                    key="download-memberships"
                    onClick={() => {
                      this.downloadInvoicePDF("AppointmentBilledItemsReport", "membership");
                    }}
                  >
                    <Icon name="download" size="large" />
                    <Translate>Memberships</Translate>
                  </Button>
                </div>
              </div>
            )}
          </div>

          {/* dropdowns */}
          <div className="controller-down">
            <DatesDropdown onChange={(start_date, end_date) => this.onDateChange(start_date, end_date)} />
            {/* FilterInvoiceStatusDropdown */}
            <Dropdown
              style={{ width: "280px", height: "38px" }}
              placeholder="Filter Invoices"
              fluid
              selection
              value={this.state.invoiceFilter}
              options={invoiceFilterOptions}
              onChange={(e, value) => this.setInvoiceFilter(value.value)}
            />
          </div>
          {this.state.date_type === 3 && (
            <p>
              <Translate>Input date range</Translate>
              {this.state.dateError.length ? (
                <span style={{ color: "red" }}>({this.state.dateError})</span>
              ) : (
                <span>
                  <Translate>(maximum 31 days)</Translate>
                </span>
              )}
            </p>
          )}
        </div>
        <div className="bg-info">
          <Translate>All total estimates are Excluding Taxes & Discounts</Translate>
        </div>
        {invoices?.results?.length ? (
          <HoverTable
            header={tableHeadersServices}
            src={invoices?.results}
            renderSpecial={this.renderSpecial({
              deleteInvoiceInProgress: this.state.deleteInvoiceInProgress,
              deletingInvoiceId: this.state.deletingInvoiceId,
            })}
            edit={(data) => {
              const payload = {
                id: data.id,
                customer: {
                  ...data.customer,
                },
                total: data.total,
              };
              this.generateInvoice(payload);
            }}
            remove={(data) => {
              this.setState({ confirmDelete: true, invoiceId: data.id });
              return;
            }}
          />
        ) : (
          <h1>
            <Translate>
              No completed appointments found for {start_date} to {end_date}
            </Translate>
          </h1>
        )}
        {invoices?.results?.length > 0 && (
          <div className="pages flex" style={{ position: "absolute", bottom: "10px", width: "100%" }}>
            <Pagination
              defaultActivePage={1}
              firstItem={false}
              lastItem={false}
              activePage={selectedPage}
              totalPages={invoices?.pages || 1}
              size="mini"
              onPageChange={(event, data) => {
                this.searchInvoices(data.activePage);
              }}
            />
          </div>
        )}
      </div>,
      <div>
        {generateInvoice && (
          <Modal close={() => this.closeModal()}>
            <GenerateInvoice
              data={generateInvoiceAppointment}
              branch={branch}
              total_amount={generateInvoiceAppointment?.total_amount}
              isActionButtonsDisabled={this.state.deleteInvoiceInProgress}
              showDeleteAppointment={invoiceFilter !== 3}
              deleteAppointment={(data) => {
                this.setState({ confirmDelete: true, invoiceId: data.id });
              }}
            />
          </Modal>
        )}
      </div>,
      <div>
        {confirmDelete && (
          <Modal zIndex={1} onClose={() => this.closeConfirmDeleteModal()}>
            <div className="appointmentConfirmDeleteContainer">
              <div>
                <p>
                  <Translate>Are you sure you want to delete the Appointment ?</Translate>
                </p>
              </div>
              <div className="buttonContainer">
                <div>
                  <Button
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      this.closeConfirmDeleteModal();
                    }}
                  >
                    <Translate>No</Translate>
                  </Button>
                  <Button
                    primary
                    disabled={deleteInvoiceInProgress}
                    onClick={() => {
                      this.setState({
                        deleteInvoiceInProgress: true,
                      });
                      this.deleteAppointment(invoiceId);
                    }}
                  >
                    <Translate>Yes</Translate>
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    aclUserPermissions: state.aclUserPermissions.data,
    appointments: state.appointments,
    appointmentsHasErrored: state.appointmentsHasErrored,
    appointmentsIsLoading: state.appointmentsIsLoading,
    staff: state.staff.results,
    branch: state.branch.data,
    invoiceFilters: state.invoiceFilters,
    invoices: invoicesAction(state),
    addAppointmentPaymentMethod: state.addAppointmentPaymentMethod,
    services: state.services,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvoices: (search, start_date, end_date, status, filter, pageSize, selectedPage) =>
      dispatch(appointmentsInvoices(search, start_date, end_date, status, filter, pageSize, selectedPage)),
    fetchAppointments: (search, start_date, end_date, status) =>
      dispatch(appointmentsFetchData(search, start_date, end_date, status)),
    fetchFilters: () => dispatch(getInvoiceFilters()),
    downloadReportFromServer: (data) => dispatch(downloadReportFromServer(data)),
    appointmentUpdate: (id, appointment, startStr, endStr, method, toPreview) =>
      dispatch(appointmentUpdate(id, appointment, startStr, endStr, method, toPreview)),
    resetAppointmentFeedback: () => dispatch(appointmentFeedbackReset()),
    fetchServices: () => dispatch(servicesFetchData()),
    fetchProducts: () => dispatch(productsFetchData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
