import axios from "axios";
import { API_BASE_URL, APPOINTMENT_LOCATION_API } from "utilities";
import { authenticationLogout } from "./authentication";
import { startLoading, endLoading, errored } from "./withLoader";

export function appointmentOutdoorAnalyticsFetch(
  start_date = new Date().toISOString().split("T")[0],
  end_date = "",
  branch = ""
) {
  const urlParam = `?start_date=${start_date}&end_date=${end_date}`;
  return (dispatch) => {
    dispatch(startLoading("STAFF_ANALYTICS_LOADER", "Loading Appointment Locations"));
    axios
      .get(API_BASE_URL + APPOINTMENT_LOCATION_API + urlParam, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "OUTDOOR_ANALYTICS_FETCH_DATA_SUCCESS",
            data: response.data,
          });
        } else {
          dispatch(errored("STAFF_ANALYTICS_LOADER", true));
        }
        dispatch(endLoading("STAFF_ANALYTICS_LOADER"));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_ANALYTICS_LOADER", true));
        dispatch(endLoading("STAFF_ANALYTICS_LOADER"));
      });
  };
}
