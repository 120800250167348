import React, { useEffect, useState } from "react";
import "./index.scss";
import { Dimmer, Loader, Segment, Form as Forms, Pagination } from "semantic-ui-react";
import { APPOINTMENT_STATUS } from "utilities";
import _ from "lodash";
import HoverTable from "../HoverTable";
import Description from "Description";
import GenerateInvoice from "GenerateInvoice";
import { getCumulativeInvoice } from "../../store/actions/client";
import Modal from "Modal";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import { CumulativeInvoice } from "../CumulativeInvoice";
import { calcDateDifference } from "../../utilities/CommonFunctions";
import { useMaskNumber } from "../../hooks/useMaskNumber";
import { fetchAppointmentsBasicDetails } from "../../store/actions/appointments";
import CustomerFeedback from "../CustomerFeedback";
import { appointmentFeedbackReset } from "../../store/actions/appointmentFeedback";

const tableHeaders = ["Date", "Status", "Services", "Staff", "Feedbacks", "Preview Invoice"];

const servicesFormatter = (services, servicesMap) => {
  if (services.length === 0) {
    return "No Service";
  }

  const serviceArr = [];

  services.forEach((service) => {
    if (service?.service_id in servicesMap) {
      serviceArr.push(servicesMap[service.service_id].name);
    }
  });

  if (serviceArr.length === 0) {
    return "No Service";
  }

  return serviceArr.join(", ");
};
const staffFormatter = (services) => {
  let staffString = "No Staff";
  if (services.length === 0) {
    return staffString;
  }
  const allStaffs = [];
  services.map((service, index) => {
    if (service.staffs.length) {
      staffString += service.staffs.map((_staff) => allStaffs.push(_staff.name));
    }
  });

  if (allStaffs.length > 0) {
    staffString = allStaffs.join(",");
  }
  return staffString;
};

const renderSpecial = ({ servicesMap, openFeedbackModal }) => {
  return [
    ({ datetime }) => {
      return <Description title={new Date(datetime).toLocaleDateString()} />;
    },
    ({ status }) => {
      return <Description title={APPOINTMENT_STATUS[status]} />;
    },
    ({ required_services }) => {
      required_services = required_services.filter((service) => service.status !== 4);
      return <Description title={servicesFormatter(required_services, servicesMap)} />;
    },
    ({ required_services }) => {
      required_services = required_services.filter((service) => service.status !== 4);
      return <Description title={staffFormatter(required_services)} />;
    },
    ({ id, feedback_rating }) => {
      return (
        <span
          className={feedback_rating !== "N/A" ? "clickable" : ""}
          onClick={() => {
            if (feedback_rating !== "N/A") {
              openFeedbackModal(id);
            }
          }}
        >
          <Description title={feedback_rating} />
        </span>
      );
    },
    (value, edit) => {
      if (value.status === 4) {
        return <Description className="red" title="Cancelled" />;
      } else if (value.status === 0 || value.status === 1 || value.status === 2) {
        return <Description className="green" title="In Process" />;
      } else if (value.status === 3) {
        return [
          <Icon
            key={0}
            className="rupee sign pointer"
            title={"generate invoice"}
            name="edit"
            size="large"
            onClick={() => edit(value)}
          />,
        ];
      } else if (value.status === 5) {
        return <Description className="gray" title="Hidden" />;
      } else if (value.status === 6) {
        return <Description className="red" title="Deleted" />;
      }
    },
  ];
};

function Customer360({ customerId, customerData }) {
  const dispatch = useDispatch();
  const [selectedInvoice, setSelectedInvoice] = useState({
    showInvoice: false,
    appointment: {},
  });
  const { maskNumber } = useMaskNumber();
  // const history = useSelector((state) => state.appointments);
  const history = useSelector((state) => state.basicAppointments);
  const servicesMap = useSelector((state) => state.servicesMap);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invoice, setInvoice] = useState("");
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [dateError, setDateError] = useState(false);
  const pageSize = 15;
  const initialPageNum = 1;
  const { results, count, loading, totalPages } = history;
  const [feedbackModal, setFeedbackModal] = useState({
    isOpen: false,
    id: null,
  });

  useEffect(() => {
    getCustomerHistory(initialPageNum);
    setInvoice("");
  }, [customerId]);

  const getCustomerHistory = (pageNum) => {
    dispatch(fetchAppointmentsBasicDetails({ customer_id: customerId, page_size: pageSize, page: pageNum }));
  };

  const showInvoice = (appointment) => {
    setSelectedInvoice({
      showInvoice: true,
      appointment: appointment,
    });
  };
  const hideInvoice = () => {
    setSelectedInvoice({
      showInvoice: false,
      appointment: {},
    });
  };

  // *** DATE DIFFERENCE CALCULATION BASED ON START & END DATE UPDATION *** //
  useEffect(() => {
    if (startDate && endDate) {
      let calc = calcDateDifference(startDate, endDate);
      if (calc >= 0 && calc <= 60) {
        setDateError(false);
      } else {
        setDateError(true);
      }
    }
  }, [startDate, endDate]);

  const dateSetFunction = (type, value) => {
    if (type === "start") {
      setStartDate(value);
    }
    if (type === "end") {
      setEndDate(value);
    }
  };

  const getInvoice = (invoice) => {
    if (invoice !== "error") {
      setInvoice(invoice);
      setInvoiceShow(true);
    }
  };

  const openInvoiceFunc = async () => {
    if (startDate && endDate) {
      const param = {
        startDate: moment(startDate).local(true).format("DD-MM-YYYY"),
        endDate: moment(endDate).local(true).format("DD-MM-YYYY"),
        id: customerId,
      };
      await dispatch(getCumulativeInvoice(param, getInvoice));
    }
  };

  const openFeedbackModal = (id) => {
    setFeedbackModal({ isOpen: true, id });
  };

  const closeFeedbackModal = () => {
    setFeedbackModal({ isOpen: false, id: null });
    dispatch(appointmentFeedbackReset());
  };

  const maskedNumber = customerData?.number ? maskNumber(customerData?.number) : customerData?.number;

  return (
    <div className="customer360">
      {invoiceShow && invoice && (
        <div style={{ position: "absolute", width: "100%", height: "100%" }}>
          <Modal className="customer360-modal" close={() => setInvoiceShow(false)}>
            <CumulativeInvoice
              invoice={invoice}
              data={{
                startDate: moment(startDate).local(true).format("DD-MM-YYYY"),
                endDate: moment(endDate).local(true).format("DD-MM-YYYY"),
                id: customerId,
              }}
            />
          </Modal>
        </div>
      )}
      {selectedInvoice.showInvoice && (
        <div style={{ position: "absolute", width: "100%", height: "100%" }}>
          <Modal close={() => hideInvoice()}>
            <GenerateInvoice data={selectedInvoice.appointment} />
          </Modal>
        </div>
      )}
      {feedbackModal.isOpen && (
        <Modal close={closeFeedbackModal}>
          <CustomerFeedback id={feedbackModal.id} />
        </Modal>
      )}
      <div style={{ margin: "10px 0px" }}>
        <h3>
          <Translate>Appointments History</Translate>
        </h3>
      </div>
      {/*  */}
      <div className="customer360-info">
        <div>
          <p>
            <Translate>Name:</Translate> {customerData?.name}
          </p>
          <p>
            <Translate>Gender:</Translate> {customerData?.gender}
          </p>
          <p>
            <Translate>Number:</Translate> {maskedNumber}
          </p>
          <p>
            <Translate>Total Revenue generated :</Translate> <b>₹ {customerData?.revenue || 0}</b>
          </p>
          <p>
            <Translate>Total Appointments:</Translate> {count}
          </p>
        </div>
        {/*  */}
        <div style={{ marginTop: "10px" }}>
          {/* date selector */}
          <Forms style={{ display: "flex", gap: "35px" }}>
            <Forms.Field className="start-date">
              <label>
                <Translate>Start Date</Translate>
              </label>
              <input
                placeholder="Start Date"
                type="date"
                min={"2020-10-18"}
                value={startDate}
                onChange={(e) => dateSetFunction("start", e.currentTarget.value)}
              />
            </Forms.Field>
            <Forms.Field className="end-date">
              <label>
                <Translate>End Date</Translate>
              </label>
              <input
                placeholder="End Date"
                type="date"
                value={endDate}
                onChange={(e) => dateSetFunction("end", e.currentTarget.value)}
              />
            </Forms.Field>
          </Forms>

          <p style={{ color: dateError && "red" }}>
            <Translate>Input date range </Translate>
            <span>
              <Translate>(maximum 60 days) </Translate>
            </span>
          </p>

          <button
            disabled={startDate === "" || endDate === "" || dateError}
            style={{ float: "right", width: "160px", height: "35px" }}
            onClick={() => openInvoiceFunc()}
          >
            <Translate>open invoice</Translate>
          </button>
        </div>
      </div>
      <div className="customer-history">
        {loading && (
          <Dimmer active inverted>
            <Loader inverted content="Loading..." />
          </Dimmer>
        )}
        {!loading && (
          <HoverTable
            header={tableHeaders}
            src={results}
            renderSpecial={renderSpecial({ servicesMap, openFeedbackModal })}
            edit={(appointment) => {
              showInvoice(appointment);
            }}
            showDeleted={false}
          />
        )}
        <div className="pages flex width100">
          <Pagination
            firstItem={false}
            lastItem={false}
            defaultActivePage={1}
            totalPages={totalPages}
            onPageChange={(event, val) => {
              getCustomerHistory(val.activePage);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Customer360;
