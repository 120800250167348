import { API_BASE_URL, COMPANIES_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { companyActions, errorMessage } from "../../utilities/actionMessages";
import { LoadingState } from "../reducers/types";

export function companiesFetchDataLoading() {
  return {
    type: "COMPANIES_FETCH_DATA_LOADING",
    data: { data: [], loading: LoadingState.loading },
  };
}

export function companiesFetchDataSuccess(companies) {
  return {
    type: "COMPANIES_FETCH_DATA_SUCCESS",
    data: { data: companies, loading: LoadingState.loaded },
  };
}

export function companiesFetchData() {
  return (dispatch) => {
    dispatch(startLoading("COMPANIES_LOADER", "loading companies"));
    dispatch(companiesFetchDataLoading());
    axios
      .get(API_BASE_URL + COMPANIES_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(companiesFetchDataSuccess(response.data.results));
          dispatch(endLoading("COMPANIES_LOADER"));
        } else {
          dispatch(errored("COMPANIES_LOADER", true));
          dispatch(endLoading("COMPANIES_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("COMPANIES_LOADER", true));
        dispatch(endLoading("COMPANIES_LOADER"));
      });
  };
}

export function companiesPostData(companies) {
  return (dispatch) => {
    dispatch(startLoading("COMPANIES_LOADER", "loading companies"));
    axios({
      method: "post",
      url: API_BASE_URL + COMPANIES_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: companies,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: companyActions.success_create, type: "success" });
          dispatch(companiesFetchData());
          dispatch(endLoading("COMPANIES_LOADER"));
        } else {
          dispatch(errored("COMPANIES_LOADER", true));
          dispatch(endLoading("COMPANIES_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("COMPANIES_LOADER", true));
        dispatch(endLoading("COMPANIES_LOADER"));
      });
  };
}

export function companiesEditData(id, companies = {}, method = "PUT") {
  return (dispatch) => {
    dispatch(startLoading("COMPANIES_LOADER", "loading companies"));
    axios({
      method: method,
      url: API_BASE_URL + COMPANIES_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: companies,
    })
      .then(function (response) {
        if ([201, 200].includes(response.status)) {
          if (!response.data.status) {
            ToastAlert({ message: companyActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: companyActions.success_update, type: "success" });
          }
          dispatch(companiesFetchData());
          dispatch(endLoading("COMPANIES_LOADER"));
        } else {
          ToastAlert({ message: errorMessage, type: "error" });
          dispatch(errored("COMPANIES_LOADER", true));
          dispatch(endLoading("COMPANIES_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("COMPANIES_LOADER", true));
        dispatch(endLoading("COMPANIES_LOADER"));
      });
  };
}
