import { API_BASE_URL, MEMBERSHIP_API, MEMBERSHIP_BASIC_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { membershipActions, errorMessage } from "../../utilities/actionMessages";
import { shouldRefreshClientSearchList } from "./shouldRefreshClientSearchList";

const defaultState = {
  loading: false,
  data: [],
  error: false,
};

export function membershipsFetchDataSuccess(memberships) {
  return {
    type: "MEMBERSHIPS_FETCH_SUCCESS",
    memberships,
  };
}

export function membershipsBasicSuccess(memberships) {
  return {
    type: "MEMBERSHIPS_BASIC_FETCH_SUCCESS",
    data: memberships,
  };
}

export function membershipsLoading() {
  return {
    type: "MEMBERSHIPS_LOADING",
    memberships: { ...defaultState, loading: true },
  };
}

export function membershipsErrored() {
  return {
    type: "MEMBERSHIPS_ERRORED",
    memberships: { ...defaultState, error: true },
  };
}

export function membershipUpdatedData(data) {
  return {
    type: "MEMBERSHIP_UPDATE",
    response: { data: data },
  };
}

export function membershipsBasicFetchData() {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + MEMBERSHIP_BASIC_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(membershipsBasicSuccess(response.data));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function membershipsFetchData({ page = 1, search = "", status = 1 }) {
  const params = { search, page, status };
  
  return (dispatch) => {
    dispatch(startLoading("MEMBERSHIPS_LOADER", "loading memberships"));
    axios
      .get(API_BASE_URL + MEMBERSHIP_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params,
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(membershipsFetchDataSuccess(response.data));
          dispatch(endLoading("MEMBERSHIPS_LOADER"));
        } else {
          dispatch(errored("MEMBERSHIPS_LOADER", true));
          dispatch(endLoading("MEMBERSHIPS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("MEMBERSHIPS_LOADER", true));
        dispatch(endLoading("MEMBERSHIPS_LOADER"));
      });
  };
}

export function membershipsPostData(memberships, inAppointment) {
  return (dispatch) => {
    dispatch(startLoading("MEMBERSHIPS_LOADER", "loading memberships"));
    axios({
      method: "post",
      url: API_BASE_URL + MEMBERSHIP_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: memberships,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: membershipActions.success_create, type: "success" });
          if (inAppointment) {
            // because in appointment we just need basic membership details
            dispatch(membershipsBasicFetchData());
          } else {
            dispatch(membershipsFetchData({}));
          }
          dispatch(endLoading("MEMBERSHIPS_LOADER"));
        } else {
          dispatch(errored("MEMBERSHIPS_LOADER", true));
          dispatch(endLoading("MEMBERSHIPS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("MEMBERSHIPS_LOADER", true));
        dispatch(endLoading("MEMBERSHIPS_LOADER"));
      });
  };
}

export function membershipsPutData(id, membership, inAppointment) {
  return (dispatch) => {
    dispatch(startLoading("MEMBERSHIPS_LOADER", "loading memberships"));
    axios({
      method: "put",
      url: API_BASE_URL + MEMBERSHIP_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: membership,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.status) {
            ToastAlert({ message: membershipActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: membershipActions.success_update, type: "success" });
          }
          dispatch(membershipUpdatedData(response.data));
          if (inAppointment) {
            // because in appointment we just need basic membership details
            dispatch(membershipsBasicFetchData());
            // because this membership might be associated with any searched client list in appointment
            // so we need latest membership data in that searched client list
            dispatch(shouldRefreshClientSearchList(true));
          } else {
            dispatch(membershipsFetchData({}));
          }
          dispatch(endLoading("MEMBERSHIPS_LOADER"));
        } else {
          dispatch(errored("MEMBERSHIPS_LOADER", true));
          dispatch(endLoading("MEMBERSHIPS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("MEMBERSHIPS_LOADER", true));
        dispatch(endLoading("MEMBERSHIPS_LOADER"));
      });
  };
}
