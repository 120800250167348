import { API_BASE_URL, INVENTORY_DETAILS_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import { productsFetchData } from "./products";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { inventoryDetailsActions, errorMessage } from "../../utilities/actionMessages";
import { LoadingState } from "../reducers/types";

export function inventoryDetailsFetchDataLoading(loading) {
  return {
    type: "INVENTORY_DETAILS_FETCH_DATA_LOADING",
    data: { data: [], loading: loading },
  };
}

export function inventoryDetailsFetchDataSuccess(data) {
  return {
    type: "INVENTORY_DETAILS_FETCH_DATA_SUCCESS",
    data: { data: data, loading: LoadingState.loaded },
  };
}

export function inventoryDetailsFetchData() {
  return (dispatch) => {
    dispatch(startLoading("INVENTORY_DETAILS_LOADER", "loading inventory details"));
    dispatch(inventoryDetailsFetchDataLoading(LoadingState.loading));
    axios
      .get(API_BASE_URL + INVENTORY_DETAILS_API, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          dispatch(inventoryDetailsFetchDataSuccess(response.data.results));
          dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
        } else {
          dispatch(errored("INVENTORY_DETAILS_LOADER", true));
          dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
        }
      })
      .catch(function (error) {
        dispatch(inventoryDetailsFetchDataLoading(LoadingState.failed));
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("INVENTORY_DETAILS_LOADER", true));
        dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
      });
  };
}

export function inventoryDetailsPostData(data) {
  return (dispatch) => {
    dispatch(startLoading("INVENTORY_DETAILS_LOADER", "loading inventory details"));
    axios({
      method: "post",
      url: API_BASE_URL + INVENTORY_DETAILS_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: inventoryDetailsActions.success_create, type: "success" });
          dispatch(inventoryDetailsFetchData());
          dispatch(productsFetchData());
          dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
        } else {
          dispatch(errored("INVENTORY_DETAILS_LOADER", true));
          dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
        }
      })
      .catch(function (error) {
        const message = errorDetailsFormattor(error);

        ToastAlert({ message: message || errorMessage, type: "error" });

        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("INVENTORY_DETAILS_LOADER", true));
        dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
      });
  };
}

const errorDetailsFormattor = (error) => {
  let message = [];

  if (error?.response?.data) {
    Object.entries(error.response.data).map((err) => {
      message.push(err[0] + " : " + err[1][0]);
    });
  }
  return message.join(",    ");
};

export function inventoryDetailsEditData(id, data) {
  return (dispatch) => {
    dispatch(startLoading("INVENTORY_DETAILS_LOADER", "loading products"));
    axios({
      method: "put",
      url: API_BASE_URL + INVENTORY_DETAILS_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({ message: inventoryDetailsActions.success_update, type: "success" });
          dispatch(inventoryDetailsFetchData());
          dispatch(productsFetchData());
          dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
        } else {
          dispatch(errored("INVENTORY_DETAILS_LOADER", true));
          dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
        }
      })
      .catch(function (error) {
        const error_message = errorDetailsFormattor(error);

        ToastAlert({ message: error_message || errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("INVENTORY_DETAILS_LOADER", true));
        dispatch(endLoading("INVENTORY_DETAILS_LOADER"));
      });
  };
}
