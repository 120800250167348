import { API_BASE_URL, REPORTS } from "utilities";
import _ from "lodash";
import axios from "axios";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export function downloadReportFromServer(data) {
  return (dispatch) => {
    axios({
      method: "POST",
      url: API_BASE_URL + REPORTS + "generate/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    }).finally(function () {
      ToastAlert({ message: "File is being generated at server", type: "success" });
      dispatch(getGenerateReports());
    });
  };
}

export function getGenerateReports() {
  return (dispatch) => {
    const url = API_BASE_URL + REPORTS + "generate/";
    axios({
      method: "GET",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "NOTIFICATION_DATA",
            data: response.data.results,
          });
        }
      })
      .catch(function (error) {
        console.log(`errored: `, { error });
        // dispatch(authenticationLogout());
      });
  };
}

export function reportViewed(id) {
  return (dispatch) => {
    const url = API_BASE_URL + REPORTS + "generate/" + id + "/";
    axios({
      method: "PATCH",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        is_viewed: true,
      },
    })
      .then(function (response) {})
      .catch(function (error) {
        console.log(`errored: `, { error });
      })
      .finally(function () {
        dispatch(getGenerateReports());
      });
  };
}
