import { API_BASE_URL, PRODUCT_USAGE } from "utilities";
import axios from "axios";
import { startLoading, endLoading, errored } from "./withLoader";
import { authenticationLogout } from "./authentication";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { errorMessage, productUsageActions } from "../../utilities/actionMessages";
import { createSelector } from "reselect";
import { LoadingState } from "../reducers/types";

export function productUsageFetchLoading(loading) {
  return {
    type: "PRODUCT_USAGE_FETCH_DATA_LOADING",
    data: { data: [], loading: loading },
  };
}

export function productUsageFetchSuccess(productUsage) {
  return {
    type: "PRODUCT_USAGE_FETCH_DATA_SUCCESS",
    data: { data: productUsage, loading: LoadingState.loaded },
  };
}

export function productUsageReset() {
  return {
    type: "PRODUCT_USAGE_FETCH_DATA_SUCCESS",
    ata: { data: [], loading: LoadingState.loaded },
  };
}

export function productUsageFetchData(
  start_date = false,
  end_date = false,
  appointment_id = false,
  required_service_id = false,
  appointment_completed = false
) {
  let URL = API_BASE_URL + PRODUCT_USAGE + "?";
  if (start_date) {
    URL += `&start_date=${start_date}`;
  }
  if (end_date) {
    URL += `&end_date=${end_date}`;
  }
  if (appointment_id) {
    URL += `&appointment_id=${appointment_id}`;
  }
  if (required_service_id) {
    URL += `&required_service_id=${required_service_id}`;
  }
  if (appointment_completed) {
    URL += `&appointment_completed=${appointment_completed}`;
  }
  return (dispatch) => {
    dispatch(startLoading("APPOINTMENT_LOADER", "loading appointment"));
    dispatch(productUsageFetchLoading(LoadingState.loading));
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(productUsageFetchSuccess(response.data.results));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
      })
      .catch(function (error) {
        dispatch(productUsageFetchLoading(LoadingState.failed));
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("APPOINTMENT_LOADER", true));
        dispatch(endLoading("APPOINTMENT_LOADER"));
      });
  };
}

export function productUsagePostData(
  productUsagePayload,
  start_date = false,
  end_date = false,
  appointment_completed = false
) {
  let URL = API_BASE_URL + PRODUCT_USAGE;
  return (dispatch) => {
    dispatch(startLoading("APPOINTMENT_LOADER", "updating appointment"));
    axios({
      method: "post",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: productUsagePayload,
    })
      .then(function (response) {
        if (response.status === 201) {
          if (start_date && start_date) {
            dispatch(productUsageFetchData(start_date, end_date, false, false, appointment_completed));
          }
          dispatch(endLoading("APPOINTMENT_LOADER"));
          ToastAlert({ message: productUsageActions, type: "success" });
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        const message = JSON.stringify(error?.response?.data) || errorMessage;
        ToastAlert({ message: message, type: "error" });
        dispatch(endLoading("APPOINTMENT_LOADER"));
      });
  };
}

export function productUsageModal(productUsageData) {
  return {
    type: "PRODUCT_USAGE_MODAL",
    data: productUsageData,
  };
}

const productUsageSelector = (state) => state.productUsage.data;
const productsSelector = (state) => state.products.data;
const servicesSelector = (state) => state.services.data || [];

export const PRODUCTS_USAGE_DATA = createSelector(
  [productUsageSelector, productsSelector, servicesSelector],
  (productUsage, products, services) => {
    const productUsageDataFormat = productUsage.map((ele) => {
      const findProductIndex = products.findIndex((productEle) => productEle.id === ele.product_id);
      const findServiceIndex = services.findIndex((servicesEle) => servicesEle.id === ele.service_id);
      return {
        ...ele,
        product_name: (products && products[findProductIndex] && products[findProductIndex]?.name) || "",
        product_remaining_quantity:
          (products[findProductIndex]?.quantity || 0) * (products[findProductIndex]?.volume || 0),
        service_name: (services && services[findServiceIndex] && services[findServiceIndex]?.name) || "",
        consumption_value: `${ele.consumption_value}`,
      };
    });
    return productUsageDataFormat;
  }
);
