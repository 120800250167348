import axios from "axios";
import { API_BASE_URL } from "../utilities";
import ToastAlert from "../utilities/toastAlert/toastAlert";

export async function getUpdatedInvoice(id, printerType) {
    const headers = {
        Authorization: localStorage.getItem("token"),
    };
    const url = API_BASE_URL + "invoice/" + id + "/" + (printerType === "thermal" ? "?pt=th" : "");
    try {
        const response = await axios({
            method: "get",
            url,
            headers,
        });

    return { status: "success", response };
  } catch (error) {
    const message = "something went wrong";

    ToastAlert({ message, type: "error" });
    return { status: "error", error };
  }
}
