import React, { useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentAnswers } from "../../store/actions/getAppointmentAnswers";
import { Translate } from "react-auto-translate";

function AppointmentAnswerDetails({ appointment_id }) {
  const dispatch = useDispatch();
  const appointmentAnswers = useSelector((state) => state.appointmentAnswers);

  useEffect(() => {
    dispatch(getAppointmentAnswers(appointment_id));
  }, [appointment_id]);

  return (
    <div className="answer-details">
      <h3>
        <Translate>Answer Details</Translate>
      </h3>
      {appointmentAnswers?.data?.results?.length ? (
        appointmentAnswers.data.results.map((ele, index) => {
          return (
            <div key={index} className="answer-container">
              <div className="question">
                <div style={{ marginRight: 2 }}>{index + 1}) </div>
                <div>{ele.question.question}</div>
              </div>
              <div className="answer">{ele.answer}</div>
            </div>
          );
        })
      ) : (
        <div>
          <Translate>No questionaire details available</Translate>
        </div>
      )}
    </div>
  );
}

export default AppointmentAnswerDetails;
