import axios from "axios";
import { API_BASE_URL, APPOINTMENT_API } from "utilities";
import { authenticationLogout } from "./authentication";

function singularAppointmentSuccess(data) {
  return {
    type: "SINGULAR_APPOINTMENT_SUCCESS",
    data,
  };
}

function singularAppointmentFailed() {
  return {
    type: "SINGULAR_APPOINTMENT_FAILED",
  };
}

export function singularAppointmentReset() {
  return {
    type: "SINGULAR_APPOINTMENT_RESET",
  };
}

export function getsingularAppointment(id) {
  return (dispatch) => {
    let URL = API_BASE_URL + APPOINTMENT_API + `${id}/`;
    axios({
      method: "GET",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(singularAppointmentSuccess(response.data));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(singularAppointmentFailed());
      });
  };
}
