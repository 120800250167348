import React, { useState, useEffect } from "react";
import { Form, Message, Dropdown, Checkbox } from "semantic-ui-react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { getCities } from "../../../store/actions/address";
import { register as userRegister, resetRegister } from "../../../store/actions/register";
import { registerationSchema } from "../../../utilities/validator";
import WhatsappImg from "../../../images/whatsapp.svg";
import "./index.scss";
import { Translate } from "react-auto-translate";

import { INDUSTRY_TYPES } from "../../../utilities/constants";

const initialFormData = {
  name: "",
  contact: "",
  industry_type: "",
  address: "",
  country: "",
  state: "",
  city1: "",
  pin_code: "",
  gst: "",
  referral: "",
  whatsapp: true,
};

const RegisterForm = ({ setRegister = () => {} }) => {
  const dispatch = useDispatch();
  const addressForm = useSelector((state) => state.addressForm);
  const registerStatus = useSelector((state) => state.register);
  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: registerationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      let formData = {
        ...values,
      };

      if (otherChecked) {
        formData = {
          ...formData,
          industry_type: "[other]- " + formData.industry_type,
        };
      }

      if (!registerStatus.status) {
        createBranch(formData);
      } else {
        dispatch(resetRegister());
        setRegister(false);
      }
    },
  });
  const [otherChecked, setOtherChecked] = useState(false);

  const handleDropdownSelection = (value) => {
    if (value === "Other") {
      setOtherChecked(true);
    } else {
      formik.setFieldValue("industry_type", value, false);
    }
  };

  const getDropdownLabel = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "20px", marginBottom: "5px" }}>
        <label style={{ fontSize: "13px", fontWeight: 700 }}>Industry Type *</label>
        {/* checkbox */}
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Checkbox
            checked={true}
            onChange={() => {
              setOtherChecked(!otherChecked);
            }}
          />
          <label style={{ fontSize: "13px", fontWeight: 700 }}>Other</label>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (formik.values.state) {
      dispatch(getCities(formik.values.state));
    }
  }, [formik.values.state]);

  const regionDataFormat = (data) => {
    return data.map(({ id, name }) => ({ key: id, text: name, value: id }));
  };

  const createBranch = (data) => {
    data["code"] = data["contact"];
    data["start_date"] = new Date().toISOString().split("T")[0];
    dispatch(userRegister(data));
  };

  return (
    <Form onSubmit={formik.handleSubmit} className="register-form">
      <Form.Group widths="equal">
        <Form.Input
          id="name"
          fluid
          label="Outlet Name *"
          placeholder="Enter Outlet Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
        />
        <Form.Input
          fluid
          id="contact"
          label="Number *"
          placeholder="Enter Number"
          value={formik.values.contact}
          onChange={formik.handleChange}
          error={formik.errors.contact}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          id="registration_email"
          fluid
          label="Email"
          placeholder="Enter Email"
          value={formik.values.registration_email}
          onChange={formik.handleChange}
          error={formik.errors.registration_email}
        />
        {/* Industry Type Selection or Addition */}
        <>
          {!otherChecked && (
            <Form.Dropdown
              fluid
              search
              selection
              id="industry_type"
              label="Industry Type *"
              placeholder="Select Industry Type"
              options={INDUSTRY_TYPES}
              value={formik.values.industry_type}
              onChange={(_, data) => {
                handleDropdownSelection(data.value);
              }}
              allowAdditions
              additionLabel="Other: "
              onAddItem={() => {
                setOtherChecked(true);
              }}
              error={formik.errors.industry_type}
            />
          )}
          {otherChecked && (
            <Form.Input
              id="industry_type"
              fluid
              label={getDropdownLabel()}
              placeholder="Enter Industry Type"
              value={formik.values.industry_type}
              onChange={formik.handleChange}
              error={formik.errors.industry_type}
            />
          )}
        </>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          id="address"
          fluid
          label="Outlet Address *"
          placeholder="Enter Outlet Address"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.errors.address}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Dropdown
          fluid
          search
          selection
          id="country"
          label="Country *"
          placeholder="Select Country"
          onChange={(_, data) => {
            formik.setFieldValue("country", data.value, false);
          }}
          options={regionDataFormat(addressForm.countries.data)}
          value={formik.values.country}
          error={formik.errors.country}
        />
        <Form.Dropdown
          fluid
          search
          selection
          id="state"
          label="State *"
          placeholder="Select State"
          onChange={(_, data) => {
            formik.setFieldValue("state", data.value, false);
          }}
          options={regionDataFormat(addressForm.states.data)}
          value={formik.values.state}
          error={formik.errors.state}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Dropdown
          fluid
          search
          selection
          id="city1"
          label="City *"
          placeholder="Select City"
          loading={addressForm.cities.isFetching}
          onChange={(_, data) => {
            formik.setFieldValue("city1", data.value, false);
          }}
          options={regionDataFormat(addressForm.cities.data)}
          value={formik.values.city1}
          error={formik.errors.city1}
        />
        <Form.Input
          id="pin_code"
          fluid
          label="Pin Code *"
          placeholder="Enter Pin Code"
          value={formik.values.pin_code}
          onChange={formik.handleChange}
          error={formik.errors.pin_code}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          fluid
          id="gst"
          label="GST Number"
          placeholder="Enter GST Number"
          value={formik.values.gst}
          onChange={formik.handleChange}
          error={formik.errors.gst}
        />
        <Form.Input
          fluid
          id="referral"
          label="Referral Code (Employee Id)"
          placeholder="Enter Referral Code"
          value={formik.values.referral}
          onChange={formik.handleChange}
          error={formik.errors.referral}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          id="whatsapp"
          toggle
          label={"Get updates on Whatsapp "}
          defaultChecked
          onChange={formik.handleChange}
        />
        <span class="whatsapp-asterisk">*</span>
        <img src={WhatsappImg} />
      </Form.Group>
      {registerStatus.error && (
        <div className="availability-error">
          <div className="details">{registerStatus.error}</div>
        </div>
      )}
      {registerStatus.status && (
        <Message positive>
          <p>
            <Translate>Success! Close and login</Translate> &#x1f64c;
          </p>
        </Message>
      )}
      <Form.Button
        loading={registerStatus.loading}
        color={!registerStatus.status && "green"}
        className="right-align"
        type="submit"
      >
        <Translate>{registerStatus.status ? "Close" : "Submit"}</Translate>
      </Form.Button>
    </Form>
  );
};

export default RegisterForm;
