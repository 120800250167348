import React, {useEffect, useMemo, useRef, useState} from "react";
import "./index.scss";
import { Button, Icon, Form, Radio, Placeholder, Input } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import { API_BASE_URL } from "utilities";
import Modal from "Modal";
import CustomerWallet from "../../containers/CustomerWallet";
import CustomerNotes from "../../components/CustomerNotes";
import { useDispatch, useSelector } from "react-redux";
import { customerWalletBalanceFetch } from "../../store/actions/customerWallet";
import PaymentMode from "../PaymentMode";
import { GetAppointmentPaymentMethod } from "../../store/actions/appointmentPayment";
import InvoiceRemarks from "../InvoiceRemarks";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import {
  getAppointmentDirectDiscount,
  postAppointmentDirectDiscount,
} from "../../store/actions/appointmentDirectDiscount";
import moment from "moment";
import { ConformationModal } from "../../new-components/ConformationModal";
import axios from "axios";
import ApiEndpoint from "../../utilities/ApiEndpoint";
import { getCustomerLoyaltyPointBalance } from "../../store/actions/loyaltyPoints";
import CompletionOtpForm from "../CompletionOtpForm";
import { updateCustomerLoyaltyPointsApi } from "../../api/updateCustomerLoyaltyPointsApi";
import { customerWalletBalanceDeductManuallyApi } from "../../api/customerWalletBalanceDeductManuallyApi";
import { getUpdatedInvoice } from "../../api/getInvoiceData";
import { sendInvoice } from "../../store/actions/sendInvoice";
import { FALSE } from "sass";
import { getWhatsAppLink } from "../../store/actions/invoices";
import { LoadingState } from "../../store/reducers/types";

function send(id, type) {
  const url = "send/invoice/" + id + "/" + type + "/";

  sendInvoice(url, type);
}

function GenerateInvoice({
  data,
  branch,
  isPreview,
  complete,
  showOtpForm = false,
  fetchAppointments = () => {},
  extraDiscount = "",
  setExtraDiscount = () => {},
  showDeleteAppointment = false,
  deleteAppointment = () => {},
  isActionButtonsDisabled = false,
}) {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.customerWallet);
  const { loading: addPaymentModeLoading } = useSelector((state) => state.addAppointmentPaymentMethod);
  const [invoiceHtml, setInvoiceHtml] = useState("");
  const [showCustomerWallet, setShowCustomerWallet] = useState(false);
  const [showCustomerNotes, setShowCustomerNotes] = useState(null);
  const [showInvoiceRemarks, setShowInvoiceRemarks] = useState(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [manuallyDeductFromWallet, setManuallyDeductFromWallet] = useState("");
  const [deductFromLoyaltyPoint, setDeductFromLoyaltyPoint] = useState("");
  const [printerType, setPrinterType] = useState("web");
  const [modalOpen, setModalOpen] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const { id, customer } = data;
  const { loading: extraDiscountLoading } = useSelector((state) => state.addAppointmentDirectDiscount);
  const { data: directDiscount, loading: directDiscountLoading } = useSelector(
    (state) => state.getAppointmentDirectDiscount
  );
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;
  const { formatted_component_permissions } = useSelector((state) => state.aclUserPermissions.data);
  const { data: loyaltyPointBalance, loading: loyaltyPointBalanceLoading } = useSelector(
    (state) => state.customerLoyaltyPointBalance
  );
  const { data: branchData } = useSelector((state) => state.branch);
  const { loyalty_point_otp, wallet_otp, membership_usage_otp } = branchData;
  const branchLoyaltyPoint = useSelector((state) => state.getBranchLoyaltyPoint);
  const [showCompleteOtpModal, setShowCompleteOtpModal] = useState(false);

  const [loader, setLoader] = useState(false);
  const loyaltyPoints_to_rupee = branchLoyaltyPoint?.results[0]?.loyalty_point_to_rupees || 1;

  const translateFormLabel = (labelText) => {
    return [<Translate>{labelText}</Translate>];
  };

  function print() {
    document.getElementById("iframe-invoice").contentWindow.print();
  }

  function derivedTotalCostFunc(data) {
    if (data?.direct_discount_applied && Number(data?.direct_discount_applied)) {
      const tax = data.branch_tax_applicable ? data.branch_tax / 100 : 0;
      const membership_discount = data?.memberships_applied_on_total.reduce(
        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.discount),
        0
      );
      const campaign_discount = data?.campaigns_applied_on_total.reduce(
        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.discount),
        0
      );
      let userDiscountedTotal =
        parseInt(data.adjusted_services_cost) +
        Number(data.adjusted_wallet_transaction_amount) +
        Number(data.adjusted_loyalty_point_amount);
      let derviedTotalCost =
        (userDiscountedTotal + Number(data?.direct_discount_applied) + Number(data?.direct_discount_applied) * tax) /
          (1 + tax) +
        membership_discount +
        campaign_discount;
      setTotalCost(derviedTotalCost);
    } else {
      setTotalCost(data.total_cost);
    }
  }

  const unsetLoading = () => {
    dispatch({ type: "GET_APPOINTMENT_DIRECT_DISCOUNT_UNSET_LOADING" });
    dispatch({ type: "LOYALTY_POINT_UNSET_LOADING" });
    dispatch({ type: "APPOINTMENT_PAYMENT_UPDATE_UNSET_LOADING" });
  };

  const isPreviewMemo = useMemo(() => {
    if (
      isPreview &&
      loyaltyPointBalanceLoading === LoadingState.loaded &&
      directDiscountLoading === LoadingState.loaded
    ) {
      unsetLoading();
      return true;
    } else {
      return false;
    }
  }, [isPreview, loyaltyPointBalanceLoading, directDiscountLoading]);

  const isNotPreviewMemo = useMemo(() => {
    if (!isPreview) {
      unsetLoading();
      return true;
    } else {
      return false;
    }
  }, [isPreview, loyaltyPointBalanceLoading, directDiscountLoading, addPaymentModeLoading]);

  const fetchInvoice = () => {
    if (isPreviewMemo) {
      getInvoiceData(id);
      getInvoice(id, printerType);
    }
    if (isNotPreviewMemo) {
      getInvoice(id, printerType);
      dispatch(GetAppointmentPaymentMethod(id));
    }
  }

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (isPreview) {
        updateManuallyAddedWalletMoney(id);
      }
    }
  }, [printerType])

  useEffect(() => {
    fetchInvoice();
  }, [isPreviewMemo, isNotPreviewMemo, printerType, directDiscount?.discount]);

  useEffect(() => {
    if (directDiscount.length) {
      setExtraDiscount(directDiscount[0].discount.toString());
    } else {
      setExtraDiscount("");
    }
  }, [directDiscount]);

  const paymentMethod = useSelector((state) => state.appointmentPaymentMethod);

  const pendingDue = paymentMethod?.[0]?.balance_amount || 0;
  const invoiceAmount = paymentMethod?.[0]?.invoice_total || 0;
  function handlePaymentMethod() {
    setAddPaymentMethod(true);
  }

  function addDirectDiscount() {
    if (!extraDiscount.length) {
      ToastAlert({ message: "Invalid discount amount.", type: "error" });
      return;
    }
    if (directDiscount.length && directDiscount[0].discount.toString() === extraDiscount) {
      ToastAlert({ message: "This discount value already exists.", type: "error" });
      return;
    }

    const paylaod = {
      appointment: id,
      discount: extraDiscount,
    };
    dispatch(postAppointmentDirectDiscount(paylaod));
    updateManuallyAddedWalletMoney(id);
  }

  const updateManuallyAddedWalletMoney = (id) => {
    getInvoice(id, printerType);
    getInvoiceData(id);
  };

  const fetchCustomerWalletBalance = () => {
    dispatch(customerWalletBalanceFetch({ customer_id: customer.id, appointment_id: data.id, branch_id: branch?.id }));
  };

  const moneyDeductFromWalletFunction = async (amount_payable = null) => {
    const input = {
      branch: branch.id,
      customer: customer.id,
      appointment: id,
      date: moment(data.datetime).format("YYYY-MM-DD"),
      amount_payable: amount_payable !== null ? amount_payable : manuallyDeductFromWallet,
      type: "CASHBACK",
    };
    try {
      const res = await customerWalletBalanceDeductManuallyApi({ data: input });
      if (res.status === "success") {
        updateManuallyAddedWalletMoney(id);
        fetchCustomerWalletBalance();
      }

      setModalOpen(false);
      return res;
    } catch (error) {
      ToastAlert({ message: error, type: "error" });
    }
  };

  const updateCustomerLoyaltyPointsHandler = async (debit) => {
    const data = {
      customer: customer.id,
      date: moment(new Date()).format("YYYY-MM-DD"),
      credit: 0,
      debit,
      appointment: id,
    };

    const res = await updateCustomerLoyaltyPointsApi({ data, adding: false });
    if (res.status === "success") {
      updateManuallyAddedWalletMoney(id);
      dispatch(getCustomerLoyaltyPointBalance(customer.id));
    }

    return res;
  };

  async function deductFromLoyaltyPointHandler() {
    if (
      invoiceData?.adjusted_services_cost === 0 ||
      invoiceData?.adjusted_services_cost < loyaltyPoints_to_rupee * deductFromLoyaltyPoint
    ) {
      if (invoiceData?.adjusted_services_cost === 0) {
        ToastAlert({ message: "can't use the loyalty points", type: "error" });
      } else if (invoiceData?.adjusted_services_cost < loyaltyPoints_to_rupee * deductFromLoyaltyPoint) {
        ToastAlert({ message: "Redeem Amount Should not be greater than invoice total Amount", type: "error" });
      } else {
        ToastAlert({ message: "Insufficent Balance", type: "error" });
      }
      return;
    }

    updateCustomerLoyaltyPointsHandler(Number(deductFromLoyaltyPoint));
    setDeductFromLoyaltyPoint("");
  }

  const getInvoice = async (id, printerType) => {
    setLoader(true);
    try {
      const res = await getUpdatedInvoice(id, printerType);

      if (res.status === "success") {
        setInvoiceHtml(res.response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setInvoiceHtml(<div />);
    }
  };

  const didMountMemo = useMemo(() => {
    if (customer.id && id && isPreview) return true;
    return false;
  }, [dispatch, customer.id, id, isPreview]);

  useEffect(() => {
    fetchCustomerWalletBalance();
    dispatch(getCustomerLoyaltyPointBalance(customer.id));
    dispatch(getAppointmentDirectDiscount(id));
  }, [didMountMemo]);

  async function getInvoiceData(id) {
    try {
      const { data } = await axios.get(API_BASE_URL + ApiEndpoint.INVOICE_DATA + `/${id}/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      derivedTotalCostFunc(data);
      setInvoiceData(data);
    } catch (error) {}
  }

  useEffect(() => {
    if (!showOtpForm.val && showCompleteOtpModal) {
      setShowCompleteOtpModal(false);
    }
  }, [showOtpForm]);

  const isBillingRestricted = formatted_component_permissions.includes("staff_access_no_billing");
  const enableDelete =
    (formatted_component_permissions.includes("admin_access") ||
      formatted_component_permissions.includes("owner_access")) &&
    formatted_component_permissions.includes("backdated_bill");
  const enablePaymentMode = formatted_component_permissions.includes("edit_delete_payment_mode") ||
    formatted_component_permissions.includes("admin_access") ||
    formatted_component_permissions.includes("owner_access");

  const canCompleteTransaction = [0, 1, 2].indexOf(data?.status) !== -1 && !isBillingRestricted;

  let walletAmountDeducted = parseFloat(transactions?.results?.filter((i) => i.appointment === data.id)[0]?.total) || 0;
  if (walletAmountDeducted < 0) {
    walletAmountDeducted = 0;
  }

  if (typeof invoiceHtml === "object") {
    return (
      <p style={{ color: "red" }}>
        <Translate>**Something went wrong with this appointment. Please update appointment and try again.</Translate>
      </p>
    );
  }

  const printTypeChange = (input) => {
    setPrinterType(input);
  };

  const isMembershipApplied = (invoiceData) => {
    let isApplied = false;

    if (invoiceData?.memberships_applied_on_total?.length > 0) {
      isApplied = Number(invoiceData?.memberships_applied_on_total[0].discount) > 0;
    } else {
      isApplied = invoiceData?.services?.some((service) => {
        if (service.type === "service" || service.type === "product") {
          if (service.memberships?.length > 0) {
            return Number(service.memberships[0].discount) > 0;
          }
          return false;
        }
        return false;
      });
    }

    return isApplied;
  };

  const handleCompleteButton = (event) => {
    const { adjusted_loyalty_point_transaction, adjusted_wallet_transaction } = invoiceData;

    // if loyalty points or wallet deduction added or membership is being applied only then otp process happens
    if (
      (loyalty_point_otp && adjusted_loyalty_point_transaction) ||
      (wallet_otp && adjusted_wallet_transaction) ||
      shouldOpenMembershipOTP
    ) {
      setShowCompleteOtpModal(true);
    } else {
      event.currentTarget.disabled = true;
      complete({ customer_otp: null });
    }
  };

  const handleOTPFormSubmit = async ({ clickedButton, data }) => {
    const { adjusted_wallet_transaction, adjusted_loyalty_point_transaction } = invoiceData;

    // reset Wallet Deduction and Deduct From Loyalty Points
    if (clickedButton === 1) {
      if (adjusted_wallet_transaction) {
        await moneyDeductFromWalletFunction(0);
        setManuallyDeductFromWallet("");
      }
      if (adjusted_loyalty_point_transaction) {
        await updateCustomerLoyaltyPointsHandler(0);
      }
    }
    complete({ customer_otp: data?.otp });
  };

  const shouldOpenMembershipOTP = invoiceData ? membership_usage_otp && isMembershipApplied(invoiceData) : false;

  return (
    <div className="generate-invoice width-100 height-100 flex column">
      {addPaymentMethod && (
        <PaymentMode
          close={(close, updated) => {
            setAddPaymentMethod(close);
            if (updated) {
              fetchInvoice();
            }
          }}
          bill={invoiceAmount}
          pendingAmount={pendingDue}
          prevData={paymentMethod}
          id={id}
          customerId={customer.id}
          fetchAppointments={fetchAppointments}
        />
      )}

      {showPaymentMethod && (
        <ShowPaymentMethods data={paymentMethod} bill={Math.round(data.total)} close={setShowPaymentMethod} />
      )}

      {showCompleteOtpModal && (
        <Modal close={() => setShowCompleteOtpModal(false)}>
          <CompletionOtpForm
            customer_id={data.customer.id}
            description={shouldOpenMembershipOTP ? "Membership is being applied" : ""}
            otp_type="APPOINTMENT"
            primaryButtonText="Complete"
            secondaryButtonText={shouldOpenMembershipOTP ? "" : "Complete (Without LP & Wallet)"}
            onComplete={(res) => handleOTPFormSubmit(res)}
            close={() => {
              setShowCompleteOtpModal(false);
            }}
          />
        </Modal>
      )}
      {isSubscriptionExpired && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
          <p style={{ color: "red", fontSize: "16px" }}>
            <Translate>Subscription is Expired</Translate>
          </p>
        </div>
      )}
      <div className="flex width-100 row action" style={{ marginBottom: "10px" }}>
        {isPreview && canCompleteTransaction && (
          <Button disabled={!invoiceData?.invoice_id} onClick={handleCompleteButton}>
            <Translate>Complete</Translate>
          </Button>
        )}
        {!isPreview && (
          <span>
            <Button onClick={() => setShowCustomerWallet(true)}>
              <Translate>Wallet Balance</Translate>
            </Button>
            {showDeleteAppointment && enableDelete && (
              <Button
                disabled={isActionButtonsDisabled}
                onClick={() => {
                  deleteAppointment(data);
                }}
              >
                <Translate>Delete</Translate>
              </Button>
            )}
          </span>
        )}

        <Button.Group>
          <Button onClick={() => print()}>
            <Translate>Print</Translate> <Icon className="print" />
          </Button>
          <Button.Or />
          <Button disabled={isSubscriptionExpired} onClick={() => send(data.id, "email")}>
            <Translate>Email</Translate> <Icon className="mail" />
          </Button>
          <Button.Or />
          <Button disabled={isSubscriptionExpired} onClick={() => send(data.id, "sms")}>
            <Translate>SMS</Translate> <Icon className="phone" />
          </Button>
          <Button.Or />
          <Button disabled={isSubscriptionExpired} onClick={() => send(data.id, "whatsApp")}>
            <Translate>WhatsApp</Translate>
            <Icon className="whatsapp" />
          </Button>
          <Button.Or />
          <Button
            disabled={isSubscriptionExpired}
            onClick={() => {
              getWhatsAppLink(data, customer, branch);
            }}
          >
            <Icon className="share" />
          </Button>
        </Button.Group>
      </div>

      <div className="hide-print">
        <Form className="printer_type">
          <Form.Group inline>
            <Form.Field>
              <Radio
                label={translateFormLabel("web")}
                name="radioGroup"
                value="web"
                checked={printerType === "web"}
                onChange={() => printTypeChange("web")}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label={translateFormLabel("thermal")}
                name="radioGroup"
                value="thermal"
                checked={printerType === "thermal"}
                onChange={() => printTypeChange("thermal")}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>

      {loader && (
        <div className="print-invoice">
          <Placeholder>
            <Placeholder.Image rectangular />
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      )}
      {!loader && (
        <iframe
          id="iframe-invoice"
          srcDoc={`${invoiceHtml}`}
          frameborder="0"
          height={"100%"}
          className={`${printerType === "thermal" ? "print-invoice thermal" : "print-invoice"}`}
          style={{ overflow: "auto" }}
        />
      )}
      {showCustomerWallet && (
        <Modal close={() => setShowCustomerWallet(false)}>
          <CustomerWallet customer={customer} />
        </Modal>
      )}
      {showCustomerNotes && (
        <Modal close={() => setShowCustomerNotes(null)} customStyle={{ position: "fixed" }}>
          <CustomerNotes customer_id={customer.id} />
        </Modal>
      )}
      {showInvoiceRemarks && (
        <Modal
          close={() => {
            getInvoice(id, printerType);
            setShowInvoiceRemarks(false);
          }}
        >
          <InvoiceRemarks appointmentId={data.id} />
        </Modal>
      )}
      <div className="extra-actions">
        {!isPreview && (
          <div className="btn-group">
            <Button
              size="mini"
              onClick={() => {
                setShowCustomerNotes(customer.id);
              }}
            >
              <Translate>Add/View Internal Notes</Translate>
            </Button>
            <Button
              size="mini"
              onClick={() => {
                setShowInvoiceRemarks(true);
              }}
            >
              <Translate>Add/View Invoice Remarks</Translate>
            </Button>
          </div>
        )}

        <div className="wallet-container">
          {!isPreview ? (
            <button
              className="ui button primary"
              onClick={handlePaymentMethod}
              disabled={data.total === 0 || !enablePaymentMode}
            >
              <Translate>Edit/View Payment Method</Translate> <br />(<Translate>Pending due Rs. </Translate>
              {pendingDue})
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "50px",
                width: "100%",
                gap: "10px",
              }}
            >
              <div className="extra-discount-container">
                <Input
                  type="number"
                  style={{ width: "200px", paddingRight: 20 }}
                  placeholder="Adjust Total"
                  onChange={(e) => {
                    const tax = invoiceData.branch_tax_applicable ? invoiceData.branch_tax / 100 : 0;
                    const campaign_discount = invoiceData?.campaigns_applied_on_total.reduce(
                      (previousValue, currentValue) => Number(previousValue) + Number(currentValue.discount),
                      0
                    );
                    const membership_discount = invoiceData?.memberships_applied_on_total.reduce(
                      (previousValue, currentValue) => Number(previousValue) + Number(currentValue.discount),
                      0
                    );
                    const taxValue = tax * (totalCost - membership_discount - campaign_discount);
                    const taxDivider = tax + 1;
                    const taxSum =
                      parseFloat(totalCost - membership_discount - campaign_discount) +
                      taxValue -
                      (parseInt(e.target.value) +
                        Number(invoiceData.adjusted_wallet_transaction_amount) +
                        Number(invoiceData.adjusted_loyalty_point_amount));
                    const discountToApply = parseInt(taxSum / taxDivider);
                    setExtraDiscount(`${discountToApply}`);
                  }}
                />
                <Input
                  action={{
                    color: "blue",
                    content: "Apply",
                    onClick: () => addDirectDiscount(),
                  }}
                  type="number"
                  loading={extraDiscountLoading}
                  style={{ width: "250px" }}
                  placeholder="Extra Discount"
                  value={extraDiscount}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (!val.length) setExtraDiscount("");
                    const numberRegex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                    if (numberRegex.test(val)) setExtraDiscount(val.toString()); // Number(val).toString() -> trim extra zero from starting
                  }}
                />
                <Input
                  type="text"
                  action={{
                    color: "blue",
                    content: "Apply",
                    disabled: !manuallyDeductFromWallet,
                    onClick: () => setModalOpen(true),
                  }}
                  style={{ width: "200px" }}
                  placeholder="Deduct from Wallet"
                  value={manuallyDeductFromWallet}
                  onChange={(e) => {
                    let val = e.target.value;
                    const numberRegex = /^(0|[1-9]\d*)$/;
                    if (numberRegex.test(val) || val === "") {
                      setManuallyDeductFromWallet(e.target.value);
                    } else {
                      setManuallyDeductFromWallet((prev) => prev);
                    }
                  }}
                />
              </div>

              <Input
                label={`Rs. ${loyaltyPoints_to_rupee * deductFromLoyaltyPoint || 0}`}
                type="text"
                action={{
                  color: "blue",
                  content: "Redeem",
                  onClick: () => deductFromLoyaltyPointHandler(),
                }}
                style={{ width: "320px", marginRight: "40px" }}
                placeholder="Deduct from Loyalty points"
                value={deductFromLoyaltyPoint}
                onChange={(e) => setDeductFromLoyaltyPoint(e.target.value)}
              />
              <div style={{ position: "relative", bottom: "15px" }}>
                <p style={{ color: "orange" }}>
                  <Translate> ** 1 Loyalty Point Equal to </Translate> Rs.
                  {branchLoyaltyPoint?.results[0]?.loyalty_point_to_rupees}/-
                </p>
              </div>
            </div>
          )}
          <div className="invoice-wallet-balance">
            <div className="child">
              <Translate>Wallet Balance:</Translate>
            </div>
            <div className="child right-align">{-1 * transactions.totalPending}</div>
          </div>
          <div className="invoice-wallet-balance">
            <div className="child">
              <Translate>Loyalty Points:</Translate>
            </div>
            <div className="child right-align">{loyaltyPointBalance ? `${loyaltyPointBalance} Points` : 0}</div>
          </div>
        </div>
        <div>
          {isPreview && (
            <p style={{ fontSize: "12px", color: "red" }}>
              <Translate>**Additional discount can only be applied to the total cost (excluding any tax)</Translate>
            </p>
          )}
        </div>
      </div>
      <ConformationModal
        isOpen={modalOpen}
        message="Are you sure you want to pay from wallet balance?"
        confirmFunction={moneyDeductFromWalletFunction}
        cancelFunction={() => setModalOpen(false)}
      />
    </div>
  );
}

export default GenerateInvoice;

function ShowPaymentMethods({ close, data, bill }) {
  if (data.length !== 1) {
    return (
      <p>
        <Translate>invalid data</Translate>
      </p>
    );
  }
  const _payment = data[0];
  return (
    <Modal close={() => close(false)} zIndex={1}>
      <div className="show-payment-methods-container">
        <h2>
          <Translate>Payment Method</Translate>
        </h2>
        {_payment.payment_info.map((method) => {
          return (
            <div className="payment-method" key={method.payment_mode}>
              <div className="payment-method-individual">
                <div>{method.payment_mode}</div>
                <div>{method.reference_id}</div>
              </div>
              <div>Rs. {method.amount}</div>
            </div>
          );
        })}
        <h3 className="footer">
          <Translate>Total: Rs.</Translate> {bill}
        </h3>
      </div>
    </Modal>
  );
}
