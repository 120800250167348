import { API_BASE_URL, LOGIN_API, LOGOUT_API } from "utilities";
import axios from "axios";

export function authenticationHasErrored(bool) {
  return {
    type: "AUTHENTICATION_HAS_ERRORED",
    hasErrored: bool,
  };
}

export function authenticationIsLoading(bool) {
  return {
    type: "AUTHENTICATION_IS_LOADING",
    isLoading: bool,
  };
}

export function authenticationLoginSuccess(authentication) {
  return {
    type: "AUTHENTICATION_LOGIN_SUCCESS",
    authentication,
  };
}

export function authenticationLogoutSuccess() {
  return {
    type: "AUTHENTICATION_LOGOUT_SUCCESS",
  };
}

export function authenticationLogin({ username, password }) {
  return (dispatch) => {
    dispatch(authenticationIsLoading(true));
    axios({
      method: "post",
      url: API_BASE_URL + LOGIN_API,
      headers: {},
      data: {
        username,
        password,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(authenticationIsLoading(false));
          dispatch(authenticationLoginSuccess(response.data.token));
        } else {
          dispatch(authenticationIsLoading(false));
          dispatch(authenticationHasErrored(true));
        }
      })
      .catch(function (error) {
        dispatch(authenticationIsLoading(false));
        dispatch(authenticationHasErrored(true));
      });
  };
}

export function authenticationLogout() {
  return (dispatch) => {
    dispatch(authenticationIsLoading(true));
    axios({
      method: "post",
      url: API_BASE_URL + LOGOUT_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          dispatch(authenticationIsLoading(false));
          dispatch(authenticationLogoutSuccess());
        } else {
          dispatch(authenticationIsLoading(false));
          dispatch(authenticationHasErrored(true));
          dispatch(authenticationLogoutSuccess());
        }
      })
      .catch(function (error) {
        dispatch(authenticationIsLoading(false));
        dispatch(authenticationHasErrored(true));
        dispatch(authenticationLogoutSuccess());
      });
  };
}
