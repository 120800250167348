const initData = {
  data: {
    wallet_payment: [],
  },
  error: false,
  loading: false,
};

export function analytics(state = initData, action) {
  switch (action.type) {
    case "WALLET_PAYMENT_ANALYTICS_FETCH_DATA_SUCCESS":
      return { ...state, data: { ...state.data.wallet_payment, wallet_payment: action.results } };
    default:
      return state;
  }
}
