import React, { useState } from "react";
import { serviceProductUsageFetchData, postServiceProductUsage } from "../api/serviceProductUsageApi";

export const useServiceProductUsage = () => {
  const [productUsage, setProductUsage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getProductUsage = async (service_id) => {
    setIsLoading(true);
    const response = await serviceProductUsageFetchData(service_id);

    if (response.status === "success") {
      setProductUsage(response.response?.data?.results);
      setIsError(false);
    } else {
      setProductUsage([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const postProductUsage = async (data) => {
    const response = await postServiceProductUsage(data.service_id, data);

    if (response.status === "success") {
      getProductUsage(data.service_id);
    } else {
      setIsError(true);
    }
  };

  return {
    isLoading,
    isError,
    productUsage,
    getProductUsage,
    postProductUsage,
  };
};

export default useServiceProductUsage;
