const defaultState = {
  data: [],
  error: false,
  loading: false,
};

export function clientMembershipUsageHistory(state = { ...defaultState }, action) {
  switch (action.type) {
    case "CLIENT_MEMBERSHIP_USAGE_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "CLIENT_MEMBERSHIP_USAGE_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case "CLIENT_MEMBERSHIP_USAGE_ERROR":
      return false;
    default:
      return {
        ...state,
        error: false,
      };
  }
}
