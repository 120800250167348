import React, { useEffect, useState } from "react";

import "./index.scss";
import { Icon, Label, Menu } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { getGenerateReports, reportViewed } from "../../store/actions/downloadReportsFromServer";
import { getReadableDateTime } from "../../utilities/dateTime";

const reportTitles = {
  InventoryDetailReport: "Inventory Details Report",
  CustomerListReport: "Customer Report",
  SellersReport: "Sellers Report",
  InvoicesPdfReport: "Invoices Report",
  AppointmentsReportFormat1: "Invoices Report",
  AppointmentBilledItemsReport: {
    service: "Invoices Service Report",
    product: "Invoices Product Report",
    membership: "Invoices Membership Report",
  },
};

const Entry = ({ data = null, empty = null, update = () => {} }) => {
  if (empty) {
    return (
      <div className="notification-entry">
        <p>No notifications here</p>
      </div>
    );
  }
  const fileGenerated = data?.url || false;
  const reportType =
    data.type === "AppointmentBilledItemsReport"
      ? reportTitles[data.type][data.params.item_type]
      : reportTitles[data.type];
  return (
    <div className="notification-entry">
      {data?.type && (
        <>
          <p className="title">
            {reportType} {!fileGenerated && "being"} Generated
          </p>
          <div className="secondary">
            <span>{getReadableDateTime(data.created_on)}</span>
            {fileGenerated ? (
              <a href={data.url} target="_blank" onClick={update}>
                Download
              </a>
            ) : (
              <span>{data.task_status}</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const [showDropdown, toggleView] = useState(false);

  useEffect(() => {
    dispatch(getGenerateReports());
    const repeated = setInterval(() => dispatch(getGenerateReports()), 90000);

    return () => repeated;
  }, []);

  const notificationLength = notifications.length;

  return (
    <>
      <Menu size="mini">
        <Menu.Item as="a" onClick={() => toggleView(!showDropdown)}>
          <Icon className="pointer" name="bell" />
          {!!notificationLength && (
            <Label color="red" floating>
              {notificationLength}
            </Label>
          )}
        </Menu.Item>
      </Menu>
      {showDropdown && (
        <div className="notification-container">
          {notifications.map((item, index) => (
            <Entry
              data={item}
              key={index}
              update={() => {
                dispatch(reportViewed(item.id));
              }}
            />
          ))}
          {notifications.length === 0 && <Entry empty />}
        </div>
      )}
    </>
  );
};

export default Notification;
