import { API_BASE_URL, BASE_PERMISSIONS_API, COMPONENTS_PERMISSIONS_API } from "utilities";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { errorMessage } from "../../utilities/actionMessages";
import { authenticationLogout } from "./authentication";
import { IsJsonString } from "../../utilities/CommonFunctions";

import axios from "axios";

export const getBasePermissions = () => {
  let URL = API_BASE_URL + BASE_PERMISSIONS_API;
  return (dispatch) => {
    dispatch({
      type: "GET_BASE_PERMISSIONS_API_FETCHING",
    });
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "GET_BASE_PERMISSIONS_API_SUCCESS",
          data: response.data.results || [],
        });
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "GET_BASE_PERMISSIONS_API_FAILURE",
        });
      });
  };
};

export const getComponentsPermissions = () => {
  let URL = API_BASE_URL + COMPONENTS_PERMISSIONS_API;
  return (dispatch) => {
    dispatch({
      type: "GET_COMPONENTS_PERMISSIONS_API_FETCHING",
    });
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch({
          type: "GET_COMPONENTS_PERMISSIONS_API_SUCCESS",
          data: response.data.results || [],
        });
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "GET_COMPONENTS_PERMISSIONS_API_FAILURE",
        });
      });
  };
};