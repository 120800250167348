const init = {
  error: false,
  loading: false,
  results: [],
  pages: 0,
  current_page: 0,
};

export function branchSubscriptions(state = init, action) {
  switch (action.type) {
    case "GET_BRANCH_SUBSCRIPTION_SUCCESS":
      return {
        error: false,
        loading: false,
        ...action.data,
      };
    default:
      return state;
  }
}
