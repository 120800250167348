import React, { useState, useEffect } from "react";
import CustomSearch from "../../components/CustomSearch/index";
import "./index.scss";
import SideNav from "../../new-components/SideNav";
import MarketplaceProductCard from "../../components/MarketplaceProductCard";
import { Pagination } from "semantic-ui-react";
import MarketplaceProductDetails from "../../components/MarketPlaceProductDetails";
import MarketplaceCartDetail from "../../components/MarketplaceCartDetail";
import MarketplacePayments from "../../containers/MarketplacePayments";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../store/actions/marketPlace";
import { debounce } from "lodash";
import { cartData } from "../../store/actions/marketplaceCart";

function MarketPlace() {
  const dispatch = useDispatch();

  const [showCartDetail, setShowCartDetail] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [search, setSearch] = useState("");
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [productId, setproductId] = useState(0);

  const productList = useSelector((state) => state.productList);
  const { data, pages } = productList;

  const cart = useSelector((state) => state.cart);
  const cartProductsCount = cart.reduce((acc, curr) => curr.quantity + acc, 0);

  const placeOrder = useSelector((state) => state.placeOrder);
  const { order } = placeOrder;

  useEffect(() => {
    dispatch(listProducts(search));
    dispatch(cartData());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (order?.id) {
      setCurrentOrder(order.id);
      setShowCartDetail(false);
      setMakePayment(true);
    }
  }, [order]);

  const handleSearch = debounce(
    (search) => {
      setSearch(search);
      dispatch(listProducts(search));
    },
    [500]
  );

  return (
    <div className="marketplace-container">
      <div className="search-cart">
        <CustomSearch
          style={{ flex:5, minWidth: "300px"}}
          placeholder="Search Product"
          onChange={(search) => handleSearch(search)}
        />
        <div className="order-actions">
          <div className="cart-details" onClick={() => setMakePayment(true)}>
            <i className="payment icon"></i>Due Orders
          </div>
          <div className="cart-details" onClick={() => setShowCartDetail(true)}>
            <i className="shopping cart icon"></i>Cart
            <div className="cart-num">{cartProductsCount}</div>
          </div>
        </div>
        <SideNav
          condition={showProductDetail || showCartDetail || makePayment}
          onCross={() => {
            showCartDetail && setShowCartDetail(false);
            showProductDetail && setShowProductDetail(false);
            makePayment && setMakePayment(false);
          }}
        >
          {showProductDetail && <MarketplaceProductDetails productId={productId} />}
          {showCartDetail && <MarketplaceCartDetail />}
          {makePayment && <MarketplacePayments current_order_id={currentOrder} />}
        </SideNav>
      </div>
      <div className="products-container">
        {data.map(({ product, min_cost, max_cost }, index) => (
          <MarketplaceProductCard
            key={index}
            imageUrl={product.images}
            min_price={min_cost}
            max_price={max_cost}
            title={product.name}
            onClick={() => {
              setShowProductDetail(true);
              setproductId(product.id);
            }}
          />
        ))}
      </div>
      <div className="products-pagination">
        <Pagination
          size="mini"
          defaultActivePage={1}
          totalPages={pages}
          firstItem={false}
          lastItem={false}
          onPageChange={(event, data) => dispatch(listProducts(search, data.activePage))}
        />
      </div>
    </div>
  );
}

export default MarketPlace;
