import { API_BASE_URL, CUSTOMER_API, CUSTOMER_FILTER_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { clientActions, errorMessage } from "../../utilities/actionMessages";

export function customersFetchDataSuccess(customers) {
  return {
    type: "CUSTOMERS_FETCH_DATA_SUCCESS",
    customersDetails: {
      customers: customers.results,
      totalCustomers: customers.count,
      totalPages: customers.pages,
    },
  };
}

export function putDataFailure(message) {
  return {
    type: "CUSTOMER_PUT_DATA_FAILURE",
    error: {
      showError: true,
      message: message,
    },
  };
}

export function putDataSuccess() {
  return {
    type: "CUSTOMER_PUT_DATA_SUCCESS",
    error: {
      showError: false,
      message: "Successfully updated customer details",
    },
  };
}
export function putDataReset() {
  return {
    type: "CUSTOMER_PUT_DATA_RESET",
    error: {},
  };
}

export function customersFetchData(search, filter = null, currentPage = 1, labels = []) {
  let URL = API_BASE_URL + CUSTOMER_API;

  let params = {
    search,
    filter,
    page:currentPage,
    labels: JSON.stringify(labels),
  };

  return (dispatch) => {
    dispatch(startLoading("CLIENTS_LOADER", "loading customers"));
    axios({
      method: "get",
      url: URL,
      params,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(customersFetchDataSuccess(response.data));
          dispatch(endLoading("CLIENTS_LOADER"));
        } else {
          dispatch(errored("CLIENTS_LOADER", true));
          dispatch(endLoading("CLIENTS_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CLIENTS_LOADER", true));
        dispatch(endLoading("CLIENTS_LOADER"));
      });
  };
}

export function customersPutData({
  data,
  id,
  search,
  filter,
  currentPage = 1,
  addCustomer = false,
  deletedClientsSelected = false,
}) {
  let url = `${API_BASE_URL}${addCustomer ? CUSTOMER_API : CUSTOMER_API + id + "/"}`;
  url += deletedClientsSelected ? `?filter=${filter}` : "";

  return (dispatch) => {
    dispatch(startLoading("CLIENTS_LOADER", "loading customers"));
    axios({
      method: addCustomer ? "post" : "put",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
      .then(function (response) {
        if (response.status === 201 || response.status === 200) {
          ToastAlert({
            message: addCustomer ? clientActions.success_create : clientActions.success_update,
            type: "success",
          });
          dispatch(customersFetchData(search, filter, currentPage));
          dispatch(endLoading("CLIENTS_LOADER"));
          if (!addCustomer) dispatch(putDataSuccess());
        } else {
          dispatch(errored("CLIENTS_LOADER", true));
          dispatch(endLoading("CLIENTS_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: error?.response?.data?.detail, type: "error" });
        if (error && error.response) {
          dispatch(putDataFailure(error.response?.data?.detail));
          if (error.response.status === 401) dispatch(authenticationLogout());
        }
        dispatch(endLoading("CLIENTS_LOADER"));
        dispatch(errored("CLIENTS_LOADER", true));
      });
  };
}

function customersFilterFetchDataSuccess(data) {
  const dummy = [
    {
      key: 1,
      text: "High Value(>1000/- | 1 months)",
      value: 1,
    },
    {
      key: 2,
      text: "Premium(>5000/- | 3 months)",
      value: 2,
    },
    {
      key: 3,
      text: "No Service(=0/- | 1 months)",
      value: 3,
    },
    {
      key: 4,
      text: "Drop Out(=0/- | 3 months)",
      value: 4,
    },
    {
      key: 5,
      text: "Pending Wallet Balance",
      value: 5,
    },
    {
      key: 6,
      text: "Active Membership",
      value: 6,
    },
    {
      key: 7,
      text: "Pending Dues",
      value: 7,
    },
    {
      key: 10,
      text: "1 Appointment",
      value: 10,
    },
    {
      key: 8,
      text: "Males",
      value: 8,
    },
    {
      key: 9,
      text: "Females",
      value: 9,
    },
  ];

  let customerFilters = data.map(({ id, name }) => ({ key: id, text: name, value: id }));

  if (data.length === 0) {
    ToastAlert({ message: "Resorting to backup Customer filters", type: "info" });
    customerFilters = dummy;
  }
  return {
    type: "CUSTOMERS_FILTER_FETCH_DATA_SUCCESS",
    data: customerFilters,
  };
}

export function customersFilterFetch() {
  return (dispatch) => {
    dispatch(startLoading("CLIENTS_LOADER", "loading filters"));
    const url = API_BASE_URL + CUSTOMER_FILTER_API;
    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(customersFilterFetchDataSuccess(response?.data?.results || []));
        } else {
          dispatch(errored("CLIENTS_LOADER", true));
        }
        dispatch(endLoading("CLIENTS_LOADER"));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CLIENTS_LOADER", true));
        dispatch(endLoading("CLIENTS_LOADER"));
      });
  };
}
