import React from "react";
import "./index.scss";

import HoverTable from "HoverTable";
import CustomSearch from "CustomSearch";
import Description from "Description";
import Customer360 from "Customer360";
import Modal from "Modal";
import mockData from "../../mockData";
import { Checkbox, Dropdown, Icon, Pagination, Label, Button } from "semantic-ui-react";
import { Form as NewComponentForm, SideNav } from "../../new-components";

import { customersFetchData, customersFilterFetch, customersPutData, putDataReset } from "../../store/actions/customer";
import { appointmentsFetchData } from "../../store/actions/appointments";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";

import ClientLabelsFilter from "./ClientLabelsFilter";
import { clientLabelOptionsSelector } from "../../store/selectors/labelOptions";

import Moment from "moment";
import CustomerWallet from "../CustomerWallet";
import CustomerNotes from "../../components/CustomerNotes";
import { debounce } from "lodash";
import { clientMembershipFetchData, clientMembershipReset } from "../../store/actions/clientMemberships";
import { getPendingHistory } from "../../store/actions/pendings";
import PendingHistory from "../PendingHistory";
import { fileUploadReset } from "../../store/actions/uploadDataFile";
import { downloadReportFromServer } from "../../store/actions/downloadReportsFromServer";
import { serverDate } from "../../utilities/dateTime";
import Uploader from "Uploader";
import ClientMembershipHistory from "../../components/ClientMembershipHistory";
import LabelSideNav from "./LabelSideNav";
import { getLabels } from "../../store/actions/labels";
import { postCustomerLabels } from "../../store/actions/customerLabels";
import LabelPopUp from "../../components/LabelPopUp";
import { LabelGroup } from "../../components/LabelGroup";
import Promotions from "../../components/Promotion/Index";
import { COUNTRY_DIAL_INFO } from "../../utilities/constants";
import ToastAlert from "../../utilities/toastAlert/toastAlert";
import CustomerLoyaltyPointsDetails from "../../components/CustomerLoyaltyPointsDetails";
import ClientSelectionPreview from "./ClientSelectionPreview";
import { handleMaskNumber } from "../../utilities/CommonFunctions";
// import ClinetTable from "./ClinetTable";

const tableHeaders = [
  "SMS?",
  "Name",
  "Label",
  "Number",
  "Notes",
  "Revenue",
  "Wallet Balance",
  "Loyalty Points",
  "Membership",
  "Pending Dues",
  "Action",
];

const defaultCountryCode = "+91"; //india
const initialCustomerInfo = {
  name: "",
  number: "",
  email: "",
  gender: "",
  date_of_anniversary: null,
  date_of_birth: null,
  country_code: defaultCountryCode,
  custom_field_one: "",
  custom_field_two: "",
  customer_gst_number: "",
};

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addClientForm: mockData.client_form,
      clientFormData: {},
      client360: false,
      search: "",
      membershipDetails: null,
      viewMembership: false,
      viewPendingPayments: false,
      viewLoyaltyPoints: false,
      viewPendingHistory: false,
      viewClientUploader: false,
      isSelected: {
        all: false,
        selectAllExclusion: null,
        selected_clients: {},
      },
      clientFilter: null,
      selectedLabels: [],
      viewCustomerNotes: null,
      selectedMembershipUsageHistory: null,
      updateInfo: {
        addCustomer: false,
        showSidenav: false,
        id: -1,
        customer: initialCustomerInfo,
      },
      downloadRequestSent: false,
      openLabelSideNav: false,
      viewClientAppointments: false,
      deletedClientsSelected: false,
    };
  }

  countryCode = COUNTRY_DIAL_INFO.map(({ dial_code, flag, code, name }) => {
    return {
      key: code,
      text: `${dial_code} ${flag}`,
      value: dial_code,
      description: name,
    };
  });

  addAndEditCustomerDetails(data) {
    if (data) {
      this.setState({
        updateInfo: {
          showSidenav: true,
          id: data.id || "",
          addCustomer: false,
          customer: {
            name: data.name || "",
            number: data.number || "",
            email: data.email || "",
            gender: data.gender || "",
            date_of_birth: data.date_of_birth || null,
            date_of_anniversary: data.date_of_anniversary || null,
            country_code: data.country_code || "",
            custom_field_one: data.custom_field_one || "",
            custom_field_two: data.custom_field_two || "",
            customer_gst_number: data.customer_gst_number || "",
          },
        },
      });
    } else {
      this.setState({
        updateInfo: {
          showSidenav: true,
          id: "",
          addCustomer: true,
          customer: initialCustomerInfo,
        },
      });
    }
  }

  hideCustomerUpdate() {
    this.props.putCustomerReset();
    this.setState({
      updateInfo: {
        id: -1,
        showSidenav: false,
        customer: initialCustomerInfo,
      },
    });
  }

  renderSpecial({ aclUserPermissions }) {
    return [
      // SMS
      ({ id, name }, edit, remove, select, isSelected) => {
        let isChecked = false;

        if (isSelected.all) {
          isChecked = true;
        } else if (isSelected.selectAllExclusion) {
          // if id is checked true then we will show unchecked otherwise checked
          if (id in isSelected.selectAllExclusion) {
            isChecked = isSelected.selectAllExclusion[id] ? false : true;
          } else {
            isChecked = true;
          }
        } else if (id in isSelected.selected_clients) {
          isChecked = isSelected.selected_clients[id] ? true : false;
        }

        return (
          <Checkbox
            onChange={(event, data) => {
              select(id, event, data, name);
            }}
            checked={isChecked}
          />
        );
      },
      // Name
      ({ name, gender, email }) => {
        return <Description style={{color:"#C95E3D"}} title={name} description={email} add_on={gender} />;
      },
      //Label
      ({ id, name, labels }, edit, remove) => {
        const { search = "", clientFilter = "", currentPage = 1, selectedLabels = [] } = this.state;
        const { clientLabels, clientLabelsOptions } = this.props;

        return (
          <LabelPopUp
            trigger={
              <div style={{ maxWidth: "150px", borderRadius:"12px" }}>
                <LabelGroup labels={labels} options={clientLabels} />
              </div>
            }
            style={{color:"#C95E3D",fontSize:"21px" ,fontWeight: "normal"}}
            name={name}
            options={clientLabelsOptions}
            onSave={(labelsIds) => {
              this.updateClientLabels(id, labelsIds);
            }}
            labels={labels}
          />
        );
      },
      // Number
      ({ number, custom_field_one, custom_field_two }) => {
        const maskedNumber = handleMaskNumber(number, aclUserPermissions);

        if (custom_field_one && custom_field_two) {
          return <Description title={maskedNumber} description={`${custom_field_one}/${custom_field_two}`} />;
        } else if (custom_field_one) {
          return <Description title={maskedNumber} description={`${custom_field_one}`} />;
        } else {
          return <Description title={maskedNumber} />;
        }
      },
      // Notes
      ({ id }, edit, remove) => {
        return (
          <span className="membership" onClick={() => remove("notes", id)}>
            <Translate>View</Translate>
          </span>
        );
      },
      // Revenue
      ({ revenue }) => {
        return <Description style={{color: "#77AD65"}} title={`${revenue}/-`} />;
      },
      // Wallet Balance
      ({ id, pending }, edit, remove) => {
        return (
          <span className="membership" onClick={() => remove("pending", id)}>
            {" "}
            Rs. {parseFloat(pending).toFixed(2) * -1}/-
          </span>
        );
      },
      //Loyalty Points
      ({ id, loyalty_points }, edit, remove) => {
        return (
          <span className="membership" onClick={() => remove("Loyalty Points", id)}>
            {loyalty_points}
          </span>
        );
      },
      // Membership
      (value, edit, remove) => {
        return value.memberships.length ? (
          <span className="membership" onClick={() => remove("memberships", value.memberships, value.id)}>
            <Translate>Yes</Translate>
          </span>
        ) : (
          // <span className="membership" onClick={() => remove("memberships", value.memberships, value.id)}>
          //   <Translate>No</Translate>
          // </span>
          <Translate>No</Translate>
        );
      },
      // Pending Dues
      ({ id, pending_payments, pending }, edit, remove) => {
        return (
          <span className="membership" onClick={() => remove("pending_history", id)}>
            <Translate>{pending_payments ? "Yes" : "No"}</Translate>
          </span>
        );
      },
      // action
      (value, edit, remove) => {
        return value.status === 0 ? (
          <Icon key={0} className="user delete" size="large" />
        ) : (
          [
            <div key={0} className="action-container">
              <Icon
                disabled={this.state.deletedClientsSelected}
                key={0}
                className="edit pointer"
                size="large"
                title="Update Details"
                onClick={() => this.addAndEditCustomerDetails(value)}
              />
              <Icon
                key={1}
                disabled={this.state.deletedClientsSelected}
                className="eye pointer"
                size="large"
                title="View Details"
                onClick={() => edit(value)}
              />
              {this.state.deletedClientsSelected ? (
                <Icon key={2} className="undo" size="large" onClick={() => this.undoCustomer(value)} />
              ) : (
                <Icon key={2} className="user delete" size="large" onClick={() => remove("client", value)} />
              )}
            </div>,
          ]
        );
      },
    ];
  }

  clientMembershipsRenderSpecial = [
    ({ membership }) => {
      return <Description title={membership.name} />;
    },
    ({ membership }) => {
      return <Description title={membership.cost} />;
    },
    ({ status, expiry_date }) => {
      if (status === "EXPIRED" && expiry_date) {
        return <Description title={status} description={expiry_date} />;
      }
      return <Description title={status} />;
    },
    ({ id }) => {
      return (
        <Icon
          className="dropdown cursor-pointer"
          size="large"
          onClick={() => {
            this.setState({
              selectedMembershipUsageHistory: id,
            });
          }}
        />
      );
    },
  ];

  componentDidMount() {
    this.props.fetchCustomers();
    this.props.fetchLabels();
    this.props.fetchCustomersFilters();
  }

  addCustomerModal = () => {
    this.setState({
      client360: false,
    });
  };

  viewCustomer = (customer) => {
    this.setState({
      viewClientAppointments: true,
      client360: customer,
    });
  };

  undoCustomer = (data) => {
    const { clientFilter, search, currentPage } = this.state;

    this.props.putCustomerInfo({
      data: { ...data, is_active: true },
      id: data.id,
      search,
      filter: clientFilter,
      currentPage,
      addCustomer: false,
      deletedClientsSelected: this.state.deletedClientsSelected,
    });
  };

  //debounce optimization the search requests by adding delay
  searchCustomer = debounce(
    (search, clientFilter, currentPage, selectedLabels) => {
      this.setState({
        search,
        clientFilter,
        currentPage,
      });

      this.props.fetchCustomers(search, clientFilter, currentPage, selectedLabels);
    },
    [300]
  );

  viewMembership(memberships) {
    this.setState({
      membershipDetails: memberships,
      viewMembership: true,
    });
  }

  onSelectChange(id, event, { checked }, name) {
    const { isSelected } = this.state;

    // if selectAll is checked and client checkbox is unchecking means we are now in exlusion operation
    if (isSelected.all) {
      this.setState({
        isSelected: {
          ...this.state.isSelected,
          all: false,
          selectAllExclusion: {
            [id]: { name },
          },
        },
      });
    }
    // if client checkbox is checked than we will store it as false because this is exclusion list
    else if (isSelected.selectAllExclusion) {
      this.setState({
        isSelected: {
          ...this.state.isSelected,
          selectAllExclusion: {
            ...this.state.isSelected.selectAllExclusion,
            [id]: !checked ? { name } : false,
          },
        },
      });
    }
    // if neither select all is checked or nor exclusion operation is on then we are select_client operation
    else {
      this.setState({
        isSelected: {
          ...this.state.isSelected,
          selected_clients: {
            ...this.state.isSelected.selected_clients,
            [id]: checked ? { name } : false,
          },
        },
      });
    }
  }

  selectAll(checked) {
    this.setState({
      isSelected: {
        ...this.state.isSelected,
        all: checked,
        selectAllExclusion: false,
        selected_clients: {},
      },
    });
  }

  resetClientSelection() {
    this.setState({
      isSelected: {
        all: false,
        selectAllExclusion: false,
        selected_clients: {},
      },
    });
  }

  verifyTime() {
    const currentTime = Moment();
    const beforeTime = Moment("10:00:00", "HH:mm:ss");
    const afterTime = Moment("20:45:00", "HH:mm:ss");
    return !currentTime.isBetween(beforeTime, afterTime);
  }

  handleUpdateInfo(name, value) {
    this.setState((prev) => {
      return {
        updateInfo: {
          ...prev.updateInfo,
          customer: {
            ...prev.updateInfo.customer,
            [name]: value,
          },
        },
      };
    });
  }

  async updateCustomerInfo() {
    const { updateInfo, clientFilter, search, currentPage } = this.state;
    const { name, number, email, gender, country_code } = updateInfo.customer;

    if (name && number && gender && country_code) {
      //to keep deleted clients deletd we need to send is_active: false if deletedClients selected
      let data = { ...updateInfo.customer };
      if (this.state.deletedClientsSelected) {
        data = { is_active: false, ...updateInfo.customer };
      }

      await this.props.putCustomerInfo({
        data,
        id: updateInfo.id,
        search,
        filter: clientFilter,
        currentPage,
        addCustomer: updateInfo.addCustomer,
        deletedClientsSelected: this.state.deletedClientsSelected,
      });

      this.setState((prev) => {
        return { updateInfo: { ...prev.updateInfo, showSidenav: false } };
      });
    } else {
      ToastAlert({ message: "Please Fill required field", type: "error" });
    }
  }

  updateClientLabels = (customer_id, label_ids) => {
    const { search, clientFilter, currentPage, selectedLabels } = this.state;

    const payload = { customer_id, label_ids };
    const getPagePayload = { search, clientFilter, currentPage, selectedLabels };
    const pageType = "";

    this.props.postCustomerLabels(payload, getPagePayload, pageType);
  };

  closeClientUploader() {
    this.setState({ viewClientUploader: false });
    this.props.fileUploadReset();
  }

  translateFormLabel = (labelText) => {
    return [<Translate>{labelText}</Translate>];
  };

  render() {
    const {
      client360,
      viewClientAppointments,
      search,
      viewMembership,
      viewPendingPayments,
      viewLoyaltyPoints,
      viewPendingHistory,
      membershipDetails,
      isSelected,
      clientFilter,
      selectedLabels,
      viewCustomerNotes,
      selectedMembershipUsageHistory,
      currentPage,
      updateInfo,
      downloadRequestSent,
      viewClientUploader,
      openLabelSideNav,
      deletedClientsSelected,
    } = this.state;

    const {
      appointments,
      appointmentsIsLoading,
      branchSubscriptionsServices,
      customers,
      clientMemberships,
      clientMembershipReset,
      aclUserPermissions,
      customerFilters,
    } = this.props;

    let selectedClientsPreview = null;
    let excludedClientsPreview = null;

    // customer filter value(key) = text(value) map
    const customerFilterMap = {};
    customerFilters.forEach((filter) => {
      const { value, text } = filter;

      customerFilterMap[value] = text;
    });

    const customerFiltersTranslated = customerFilters.map((filter) => {
      const { key, value, text } = filter;

      return {
        key,
        value,
        text: <Translate>{text}</Translate>,
      };
    });

    const { customers: customerData, totalCustomers, totalPages } = customers;
    const { formatted_component_permissions } = aclUserPermissions;

    let canSendBulkPromo = false;
    if (branchSubscriptionsServices.data.length) {
      canSendBulkPromo = branchSubscriptionsServices.data.some(({ subscription_service }) => {
        return subscription_service.name === "BULK_PROMOTION";
      });
    }
    const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

    // formatting isSelected data into ids and names
    let allClientsSelected = false;
    let excluded_clients_data = null;
    let selected_clients_data = null;

    if (isSelected.all) {
      allClientsSelected = true;
    } else if (isSelected.selectAllExclusion) {
      excluded_clients_data = {
        ids: [],
        names: [],
      };

      // filtering the exluded clients basis on checked or not
      for (const [id, value] of Object.entries(isSelected.selectAllExclusion)) {
        if (value) {
          excluded_clients_data.ids.push(id);
          excluded_clients_data.names.push(value.name);
        }
      }

      // let clientNames = "";
      // clientNames = excluded_clients_data.names.join(", ");
      excludedClientsPreview = excluded_clients_data.names;
    } else {
      selected_clients_data = {
        ids: [],
        names: [],
      };

      // filtering the selected clients basis on checked or not
      for (const [id, value] of Object.entries(isSelected.selected_clients)) {
        if (value) {
          selected_clients_data.ids.push(id);
          selected_clients_data.names.push(value.name);
        }
      }

      selectedClientsPreview = selected_clients_data.names;
    }

    // for passing to promotion
    const selectedClientsData = {
      allClientsSelected,
      excluded_clients: excluded_clients_data,
      selected_clients: selected_clients_data,
    };

    return [
      <div key={0} className="clients width100">
        <LabelSideNav
          openLabelSideNav={openLabelSideNav}
          closeLabelSideNav={() => {
            this.setState({ openLabelSideNav: false });
          }}
        />
        <SideNav
          condition={updateInfo.showSidenav}
          onCross={() => {
            this.hideCustomerUpdate();
          }}
        >
          <NewComponentForm>
            <NewComponentForm.Title>
              <Translate>{`${updateInfo.addCustomer ? "Add" : "Update"} Customer Information`}</Translate>
            </NewComponentForm.Title>
            <NewComponentForm.Divider> </NewComponentForm.Divider>
            <NewComponentForm.FieldContainer>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={updateInfo.customer.name}
                  onChange={(e) => {
                    this.handleUpdateInfo("name", e.target.value);
                  }}
                  type="text"
                  placeholder="Client Name*"
                />
              </NewComponentForm.TextFieldContainer>
              <NewComponentForm.AsyncSelect
                style={{ marginLeft: 20, marginRight: 0 }}
                disabled={false}
                placeholder="Country Code*"
                value={updateInfo.customer.country_code}
                toDisplay={updateInfo.customer.country_code}
              >
                {this.countryCode.map((ele, optionIndex) => {
                  return (
                    <NewComponentForm.AsyncSelectOption
                      key={optionIndex}
                      onClick={() => {
                        this.handleUpdateInfo("country_code", ele.value);
                      }}
                      value={ele.value}
                    >
                      {ele.text}
                    </NewComponentForm.AsyncSelectOption>
                  );
                })}
              </NewComponentForm.AsyncSelect>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={updateInfo.customer.number}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                      this.handleUpdateInfo("number", e.target.value);
                    }
                  }}
                  type="text"
                  placeholder="Client Number*"
                />
              </NewComponentForm.TextFieldContainer>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={updateInfo.customer.email || ""}
                  onChange={(e) => {
                    this.handleUpdateInfo("email", e.target.value);
                  }}
                  type="email"
                  placeholder="Client email"
                />
              </NewComponentForm.TextFieldContainer>

              <NewComponentForm.Radio label={<Translate>Gender*</Translate>}>
                <NewComponentForm.RadioButton
                  onClick={() => this.handleUpdateInfo("gender", "M")}
                  value={updateInfo.customer.gender}
                  name="M"
                >
                  <Translate>Male</Translate>
                </NewComponentForm.RadioButton>
                <NewComponentForm.RadioButton
                  onClick={() => this.handleUpdateInfo("gender", "F")}
                  value={updateInfo.customer.gender}
                  name="F"
                >
                  <Translate>Female</Translate>
                </NewComponentForm.RadioButton>
                <NewComponentForm.RadioButton
                  onClick={() => this.handleUpdateInfo("gender", "O")}
                  value={updateInfo.customer.gender}
                  name="O"
                >
                  <Translate>Others</Translate>
                </NewComponentForm.RadioButton>
              </NewComponentForm.Radio>

              <NewComponentForm.DateContainer alwaysActive={true}>
                <NewComponentForm.Date
                  value={updateInfo?.customer?.date_of_birth || ""}
                  onChange={(e) => {
                    e.target.value
                      ? this.handleUpdateInfo("date_of_birth", serverDate(e.target.value))
                      : this.handleUpdateInfo("date_of_birth", null);
                  }}
                  placeholder="Date of Birth"
                  type="date"
                />
              </NewComponentForm.DateContainer>
              <NewComponentForm.DateContainer alwaysActive={true}>
                <NewComponentForm.Date
                  value={updateInfo?.customer?.date_of_anniversary || ""}
                  onChange={(e) => {
                    e.target.value
                      ? this.handleUpdateInfo("date_of_anniversary", serverDate(e.target.value))
                      : this.handleUpdateInfo("date_of_anniversary", null);
                  }}
                  placeholder="Date of Anniversary"
                  type="date"
                />
              </NewComponentForm.DateContainer>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={updateInfo.customer.custom_field_one}
                  onChange={(e) => {
                    this.handleUpdateInfo("custom_field_one", e.target.value);
                  }}
                  type="text"
                  placeholder="Custom Field One"
                />
              </NewComponentForm.TextFieldContainer>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={updateInfo.customer.custom_field_two}
                  onChange={(e) => {
                    this.handleUpdateInfo("custom_field_two", e.target.value);
                  }}
                  type="text"
                  placeholder="Custom Field Two"
                />
              </NewComponentForm.TextFieldContainer>
              <NewComponentForm.TextFieldContainer>
                <NewComponentForm.TextField
                  value={updateInfo.customer.customer_gst_number}
                  onChange={(e) => {
                    this.handleUpdateInfo("customer_gst_number", e.target.value);
                  }}
                  type="text"
                  placeholder="Custom GST field"
                />
              </NewComponentForm.TextFieldContainer>
            </NewComponentForm.FieldContainer>
            <div className="CancelButtonContainer">
              <NewComponentForm.SubmitButton
                disabled={false}
                onClick={() => {
                  this.updateCustomerInfo();
                }}
              >
                <Translate>{`${updateInfo.addCustomer ? "ADD" : "Update"}`}</Translate>
              </NewComponentForm.SubmitButton>
            </div>
            {/* {this.props.customerPutError && this.props.customerPutError.showError ? (
              <div className="error-message">{this.props.customerPutError?.message}</div>
            ) : (
              <div className="success-message">{this.props.customerPutError?.message}</div>
            )} */}
          </NewComponentForm>
        </SideNav>
        {viewClientAppointments && (
          <Modal
            close={() => {
              this.setState({
                client360: false,
                viewClientAppointments: false,
              });
            }}
          >
            <div className="customer-details flex">
              {client360 ? (
                <Customer360 customerId={client360.id} customerData={client360} />
              ) : (
                <div className="flex width-100 height-100 unselected">
                  <Translate>Select Customer To View History</Translate>
                </div>
              )}
            </div>
          </Modal>
        )}
        {viewClientUploader && (
          <Modal close={() => this.closeClientUploader()}>
            <Uploader type="clients" />
          </Modal>
        )}
        {viewCustomerNotes && (
          <Modal close={() => this.setState({ viewCustomerNotes: null })}>
            <CustomerNotes customer_id={viewCustomerNotes} />
          </Modal>
        )}
        {viewPendingHistory && (
          <Modal
            close={() => {
              this.setState({ viewPendingHistory: false });
            }}
          >
            <PendingHistory />
          </Modal>
        )}
        {viewPendingPayments && (
          <Modal
            close={() => {
              this.setState({ viewPendingPayments: false });
            }}
          >
            <CustomerWallet
              customer={{ id: viewPendingPayments }}
              appliedCustomerFilters={{ search, clientFilter, currentPage }}
            />
          </Modal>
        )}
        {viewLoyaltyPoints && (
          <Modal
            close={() => {
              this.setState({ viewLoyaltyPoints: false });
            }}
          >
            <CustomerLoyaltyPointsDetails
              customer={{ id: viewLoyaltyPoints }}
              appliedCustomerFilters={{ search, clientFilter, currentPage }}
            />
          </Modal>
        )}
        <ClientMembershipHistory
          viewMembership={viewMembership}
          membershipDetails={membershipDetails}
          clientMemberships={clientMemberships}
          closeMemberhship={() => {
            clientMembershipReset();
            this.setState({
              viewMembership: false,
            });
          }}
        />

        <div className="controller flex row">
          <CustomSearch
            style={{ maxWidth: "400px", width: "325px", minWidth: "150px", marginRight: "50px" }}
            onChange={(search) => {
              this.searchCustomer(search, clientFilter, 1, selectedLabels);
              this.resetClientSelection();
            }}
            placeholder="search by customer name or number"
          />
          {!deletedClientsSelected && (
            <Promotions
              selectedClientFilter={clientFilter}
              selectedClients={selectedClientsData}
              labelFilter={selectedLabels}
            />
          )}
        </div>
        <div className="flex height-100 width-100 row client-list-view">
          <div className="table-container flex column width-100">
            {/* filter dropdowns */}
            <div className="flex row" style={{ margin: "20px 0", gap: "10px", alignItems: "flex-start" }}>
              <div>
                <Dropdown
                  style={{ minWidth: "325px" }}
                  placeholder={<Translate>Filter By Category</Translate>}
                  clearable
                  selection
                  value={clientFilter}
                  onChange={(_, { value }) => {
                    this.searchCustomer(search, value, 1, selectedLabels);
                    const isDeletedClientsSelected = customerFilterMap[value] === "Deleted Clients";

                    this.setState({
                      deletedClientsSelected: isDeletedClientsSelected,
                    });
                    this.resetClientSelection();
                  }}
                  options={customerFiltersTranslated}
                />
              </div>
              <ClientLabelsFilter
                onFiltersChange={(selectedLabels) => {
                  this.searchCustomer(search, clientFilter, 1, selectedLabels);
                  this.setState({
                    selectedLabels: selectedLabels,
                  });
                  this.resetClientSelection();
                }}
              />
            </div>
            {/* Send to all, total clients, download, upload, add client */}
            <div className="flex row" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
              <div className="flex row" style={{ gap: "30px", alignItems: "center" }}>
                <Checkbox
                  label={[<b>Send to all?</b>]}
                  className="send-to-all"
                  onChange={(event, { checked }) => this.selectAll(checked)}
                  checked={isSelected?.all}
                />
                <ClientSelectionPreview
                  selectedClientsPreview={selectedClientsPreview}
                  excludedClientsPreview={excludedClientsPreview}
                />
              </div>
              {isSubscriptionExpired && (
                <p style={{ fontSize: "16px", color: "red" }}>
                  <Translate>Subscription is Expired</Translate>
                </p>
              )}
              <div className="total-clients" style={{ marginBottom: "10px" }}>
                <div>
                  <span style={{ marginRight: "20px" }}>
                    <Translate>Total Clients:</Translate> <span>{totalCustomers}</span>
                  </span>
                  {!!(
                    customerData &&
                    customerData.length &&
                    formatted_component_permissions.includes("download_reports")
                  ) && (
                    <Icon
                      disabled={isSubscriptionExpired}
                      name="download"
                      size="large"
                      onClick={() => {
                        const downloadPayload = {
                          type: "CustomerListReport",
                          params: { client_filter_id: clientFilter, labels_list: selectedLabels },
                        };
                        this.props.downloadReportFromServer(downloadPayload);
                        this.setState({
                          downloadRequestSent: true,
                        });
                        const that = this;
                        setTimeout(function () {
                          that.setState({
                            downloadRequestSent: false,
                          });
                        }, 3000);
                      }}
                    />
                  )}
                  <Icon
                    disabled={isSubscriptionExpired}
                    title="upload Client details"
                    name="upload"
                    size="large"
                    onClick={() => {
                      this.setState({ viewClientUploader: true });
                    }}
                  />
                </div>
                <div>
                  <Button onClick={() => this.addAndEditCustomerDetails()}>
                    <Icon name="add user" />
                    &nbsp;<Translate>Add Client</Translate>
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ openLabelSideNav: true });
                    }}
                  >
                    <Icon name="add label" />
                    &nbsp;<Translate>Label</Translate>
                  </Button>
                </div>
              </div>
            </div>
            {/* <ClinetTable src={customerData} 
            renderSpecial={this.renderSpecial({ aclUserPermissions: formatted_component_permissions })}
            edit={(customer) => this.viewCustomer(customer)}
            remove={(type, data, id) => {
              if (type === "pending_history") {
                this.props.fetchPendingHistory(data);
                this.setState({ viewPendingHistory: data });
              } else if (type === "pending") {
                this.setState({ viewPendingPayments: data });
              } else if (type === "Loyalty Points") {
                this.setState({ viewLoyaltyPoints: data });
              } else if (type === "notes") {
                this.setState({ viewCustomerNotes: data });
              } else if (type === "client") {
                data.is_active = false;
                const { clientFilter, search, currentPage } = this.state;
                this.props.putCustomerInfo({
                  data,
                  id: data.id,
                  search,
                  filter: clientFilter,
                  currentPage,
                  deletedClientsSelected: this.state.deletedClientsSelected,
                });
              } else {
                this.props.fetchMemberships(id);
                this.viewMembership(data);
              }
            }}
            select={(id, event, data, name) => this.onSelectChange(id, event, data, name)}
            isSelected={isSelected}/> */}
            <HoverTable
              header={tableHeaders}
              src={customerData}
              renderSpecial={this.renderSpecial({ aclUserPermissions: formatted_component_permissions })}
              edit={(customer) => this.viewCustomer(customer)}
              remove={(type, data, id) => {
                if (type === "pending_history") {
                  this.props.fetchPendingHistory(data);
                  this.setState({ viewPendingHistory: data });
                } else if (type === "pending") {
                  this.setState({ viewPendingPayments: data });
                } else if (type === "Loyalty Points") {
                  this.setState({ viewLoyaltyPoints: data });
                } else if (type === "notes") {
                  this.setState({ viewCustomerNotes: data });
                } else if (type === "client") {
                  data.is_active = false;
                  const { clientFilter, search, currentPage } = this.state;
                  this.props.putCustomerInfo({
                    data,
                    id: data.id,
                    search,
                    filter: clientFilter,
                    currentPage,
                    deletedClientsSelected: this.state.deletedClientsSelected,
                  });
                } else {
                  this.props.fetchMemberships(id);
                  this.viewMembership(data);
                }
              }}
              select={(id, event, data, name) => this.onSelectChange(id, event, data, name)}
              isSelected={isSelected}
            />
           
            <div className="pages flex width100">
              {totalCustomers && (
                <Pagination
                  firstItem={false}
                  lastItem={false}
                  activePage={currentPage}
                  totalPages={totalPages}
                  // size="mini"
                  onPageChange={(event, data) => {
                    this.searchCustomer(search, clientFilter, data.activePage, selectedLabels);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    aclUserPermissions: state.aclUserPermissions.data,
    customers: state.customers.data,
    customerFilters: state.customers.filters,
    appointments: state.appointments,
    branchSubscriptionsServices: state.branchSubscriptionsServices,
    clientMemberships: state.clientMemberships,
    customerPutError: state.customerPutError,
    clientLabels: state.clientLabels.data,
    clientLabelsOptions: clientLabelOptionsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomers: (search, clientFilter, currentPage, selectedLabels) =>
      dispatch(customersFetchData(search, clientFilter, currentPage, selectedLabels)),
    fetchCustomersFilters: () => dispatch(customersFilterFetch()),
    appointmentsFetchData: (customer_id, showDataCount, pageNum) =>
      dispatch(appointmentsFetchData(false, false, false, false, customer_id, showDataCount, pageNum)),
    fetchMemberships: (id) => dispatch(clientMembershipFetchData(id)),
    fetchPendingHistory: (id) => dispatch(getPendingHistory(id)),
    putCustomerInfo: (payload) => dispatch(customersPutData(payload)),
    putCustomerReset: () => dispatch(putDataReset()),
    downloadReportFromServer: (data) => dispatch(downloadReportFromServer(data)),
    fileUploadReset: () => dispatch(fileUploadReset()),
    clientMembershipReset: () => dispatch(clientMembershipReset()),

    fetchLabels: () => dispatch(getLabels("client")),
    postCustomerLabels: (payload, getPagePayload, pageType) =>
      dispatch(postCustomerLabels(payload, getPagePayload, pageType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
