import React from "react";
import { Button } from "semantic-ui-react";

export const AddRemoveButtons = (props) => {
  const { addRemove, type, className } = props;
  return (
    <div className={className}>
      <Button circular icon="plus" onClick={(e) => addRemove(e, "ADD", type)} />
      <Button circular icon="minus" onClick={(e) => addRemove(e, "REMOVE", type)} />
    </div>
  );
};
