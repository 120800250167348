import React from "react";
import exportFromJSON from "export-from-json";
import { Icon, Checkbox } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import "./index.scss";

import { connect } from "react-redux";

import { Bar, Doughnut } from "react-chartjs-2";
import { Form as Forms, Dropdown, Table } from "semantic-ui-react";
import { appointmentAnalyticsFetchData } from "../../store/actions/appointmentAnalytics";
import { staffAnalyticsFetchData } from "../../store/actions/staffAnalytics";
import { paymentsAnalyticsFetchData } from "../../store/actions/paymentAnalytics";
import { staffRevenueAnalyticsFetchData } from "../../store/actions/staffRevenueAnalytics";
import { staffServiceAnalyticsFetchData } from "../../store/actions/staffServiceAnalytics";
import { membershipsRevenueAnalyticsFetchData } from "../../store/actions/membershipRevenueAnalytics";
import { appointmentOutdoorAnalyticsFetch } from "../../store/actions/appointmentOutdoor";
import { staffBasicFetchData } from "../../store/actions/staff";
import { walletPaymentAnalyticsFetchData } from "../../store/actions/analytics";
import { calcDateDifference } from "../../utilities/CommonFunctions";
import sortBy  from "../../cdn/Vector.png";

const appointmentsBarData = {
  maintainAspectRatio: false,
  labels: [],
  datasets: [
    {
      label: "Appointments/Day",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const servicesBarData = {
  maintainAspectRatio: false,
  labels: [],
  datasets: [
    {
      label: "Staff/Services",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const staffRevenueBarData = {
  maintainAspectRatio: false,
  labels: [],
  datasets: [
    {
      label: "Staff/Revenue",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const staffMembershipBarData = {
  maintainAspectRatio: false,
  labels: [],
  datasets: [
    {
      label: "Staff/Membership",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const paymentsBarData = {
  maintainAspectRatio: false,
  labels: [],
  datasets: [
    {
      label: "Payment Mode(Count)/Revenue",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const walletPaymentBarData = {
  maintainAspectRatio: false,
  labels: [],
  datasets: [
    {
      label: "Direct Wallet Transaction - Paid Vs Payable",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const staffPieData = {
  labels: ["No Staff"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#20B2AA", "#FF6384"],
      hoverBackgroundColor: ["#20B2AA", "#FF6384"],
    },
  ],
};

const appointmentLocationPieData = {
  labels: ["No Appointments"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#20B2AA", "#FF6384"],
      hoverBackgroundColor: ["#20B2AA", "#FF6384"],
    },
  ],
};

const revenueLineData = {
  labels: ["No Data"],
  datasets: [
    {
      label: "Revenue",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0],
      paymentModeOrder: "0",
    },
  ],
};

const legendLeft = {
  display: true,
  position: "left",
  // align: 'end',
  fullWidth: true,
  reverse: false,
  labels: {
    // fontColor: 'rgb(255, 99, 132)',
    padding: 10,
    boxWidth: 12,
    margin: 0,
  },
};

const COLORS = [
  "rgba(255,0,0,1)",
  "rgba(0,255,0,1)",
  "rgba(0,0,255,1)",
  "rgba(255,255,0,1)",
  "rgba(0,255,255,1)",
  "rgba(255,0,255,1)",
  "rgba(192,192,192,1)",
  "rgba(0,0,0,1)",
];

const serviceVsStaffDataSet = {
  label: "My First dataset",
  backgroundColor: "rgba(255,99,132,0.2)",
  borderColor: "rgba(255,99,132,1)",
  borderWidth: 1,
  stack: 1,
  data: [65, 59, 80, 81, 56, 55, 40],
};

const commonOptions = {
  responsive: true,
  maintainAspectRatio: true,
  legend: {
    display: true,
  },
  type: "bar",
};

const stepSizeTrue = {
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true, //for starting y-axes from 0
          stepSize: 1, //for taking gap of 1 step on y-axes
        },
      },
    ],
  },
};

const stepSizeFalse = {
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true, //for taking gap of 1 step on y-axes
        },
      },
    ],
  },
};

const paymentModeOrderOptions = [
  {
    key: "0",
    text: "By Payment Date",
    value: "0",
  },
  {
    key: "1",
    text: "By Appointment Date",
    value: "1",
  },
];

const TabularAnalytics = ({ content }) => {
  const { data, headers } = content;
  return (
    <Table celled padded>
      <Table.Header>
        <Table.Row>
          {headers.map((item, index) => (
            <Table.HeaderCell key={index}>
              <Translate>{item}</Translate>
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((row, index) => {
          const entries = row.map((item, index) => <Table.Cell key={index}>{item}</Table.Cell>);
          return <Table.Row key={index}>{entries} </Table.Row>;
        })}
      </Table.Body>
    </Table>
  );
};

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    this.state = {
      start_date: date.toISOString().slice(0, 10),
      end_date: date.toISOString().slice(0, 10),
      branch: 0,
      error: false,
      showGraphs: false,
      setColumnView: false,
    };
  }

  componentDidMount() {
    const { start_date, end_date } = this.state;
    this.fetchAnalytics(start_date, end_date);
  }

  startDateChange(date) {
    const { end_date } = this.state;
    let editError = false;
    const difference = calcDateDifference(date, end_date);
    if (difference >= 0 && difference < 60) {
      editError = false;
      this.fetchAnalytics(date, end_date);
    } else {
      editError = true;
    }
    this.setState({
      error: editError,
      start_date: date,
    });
  }

  endDateChange(date) {
    const { start_date } = this.state;
    let editError = false;
    const difference = calcDateDifference(start_date, date);
    if (difference >= 0 && difference < 60) {
      editError = false;
      this.fetchAnalytics(start_date, date);
    } else {
      editError = true;
    }
    this.setState({
      error: editError,
      end_date: date,
    });
  }

  fetchAnalytics(start_date, end_date) {
    const { branch, paymentModeOrder } = this.state;
    const { formatted_component_permissions } = this.props.aclUserPermissions;
    this.props.fetchAppointmentAnalytics(start_date, end_date);
    this.props.fetchStaffAnalytics(start_date, end_date);
    this.props.fetchStaffRevenueAnalytics(start_date, end_date);
    this.props.fetchStaffServiceAnalytics(start_date, end_date);
    this.props.fetchPaymentsAnalytics(start_date, end_date, "", paymentModeOrder);
    this.props.fetchMembershipsRevenueAnalytics(start_date, end_date);
    this.props.fetchOutdoorAnalytics(start_date, end_date);
    this.props.fetchWalletPaymentAnalytics(start_date, end_date);
    this.props.staffBasicFetchData();
  }

  revenueDataFormat(appointmentAnalytics, forDownload = false, tabular = false) {
    let date = [],
      revenue = [],
      rows = [];
    if (appointmentAnalytics) {
      appointmentAnalytics.forEach((element) => {
        date.push(element.date);
        revenue.push(element.revenue);
        rows.push([element.date, element.revenue]);
      });
      if (forDownload) {
        return appointmentAnalytics;
      }
    }
    if (tabular) {
      const totalRevenue = rows.reduce((total, element) => total + element[1], 0);
      rows.push([<Translate>Total</Translate>, totalRevenue.toFixed(2)]);
      return {
        headers: ["Date", "Revenue"],
        data: rows,
      };
    }
    revenueLineData.labels = date;
    revenueLineData.datasets[0].data = revenue;
    return revenueLineData;
  }

  appointmentsDataFormat(appointmentAnalytics, forDownload = false, tabular = false) {
    let date = [],
      data = [],
      rows = [];
    appointmentAnalytics.forEach((elements) => {
      date.push(elements.date);
      data.push(elements.total - elements.cancelled);
      rows.push([elements.date, elements.total - elements.cancelled]);
    });
    if (forDownload) {
      return appointmentAnalytics;
    }
    if (tabular) {
      const totalAppointments = rows.reduce((total, element) => total + element[1], 0);
      rows.push([<Translate>Total</Translate>, totalAppointments]);
      return {
        headers: ["Date", "Appointments"],
        data: rows,
      };
    }
    appointmentsBarData.labels = date;
    appointmentsBarData.datasets[0].data = data;
    return appointmentsBarData;
  }

  appointmentLocationDataFormat(appointmentLocations, tabular = false) {
    let data;
    if (appointmentLocations) {
      const { outdoor_service, indoor_service } = appointmentLocations;
      data = [indoor_service, outdoor_service];
      appointmentLocationPieData.labels = ["Indoor", "Outdoor"];
      appointmentLocationPieData.datasets[0].data = data;
    }
    if (tabular) {
      const rows = [[<Translate>Indoor</Translate>, data[0] || 0], [<Translate>Outdoor</Translate>, data[1]] || 0];
      return {
        headers: ["Location Type", "Count"],
        data: rows,
      };
    }
    return appointmentLocationPieData;
  }

  staffDataFormat(staff, tabular = false) {
    if (staff) {
      let data = [0, 0];
      staff.forEach((element) => {
        if (element.status === 0) {
          data[1]++;
        } else {
          data[0]++;
        }
      });
      staffPieData.labels = ["Active", "Inactive"];
      staffPieData.datasets[0].data = data;
      if (tabular) {
        const rows = [
          [<Translate>Active</Translate>, data[0]],
          [<Translate>Inactive</Translate>, data[1]],
        ];
        return {
          headers: ["Status", "Count"],
          data: rows,
        };
      }
    }
    return staffPieData;
  }

  staffServiceDataFormat(staffAnalytics, forDownload = false, tabular = false) {
    let staff = [],
      services = [],
      rows = [];
    if (staffAnalytics) {
      staffAnalytics.forEach((element) => {
        staff.push(element["staff__user__first_name"] || "unassigned");
        services.push(element.count);
        rows.push([element["staff__user__first_name"] || "unassigned", element.count]);
      });
      if (forDownload) {
        return staffAnalytics;
      }
      servicesBarData.labels = staff;
      servicesBarData.datasets[0].data = services;
    }
    if (tabular) {
      return {
        headers: ["Staff", "Services Revenue"],
        data: rows,
      };
    }
    return servicesBarData;
  }

  staffRevenueDataFormat(staffRevenue, forDownload = false, tabular = false) {
    let staff = [],
      total = [],
      rows = [];
    if (staffRevenue) {
      staffRevenue.forEach((element) => {
        staff.push(element["staff__user__first_name"] || "unassigned");
        total.push(element.total);
        rows.push([element["staff__user__first_name"] || "unassigned", element.total]);
      });
      if (forDownload) {
        return staffRevenue;
      }
      staffRevenueBarData.labels = staff;
      staffRevenueBarData.datasets[0].data = total;
    }
    if (tabular) {
      return {
        headers: ["Staff", "Revenue"],
        data: rows,
      };
    }
    return staffRevenueBarData;
  }

  staffMembershipDataFormat(membershipsRevenue, forDownload = false, tabular = false) {
    let staff = [],
      total = [],
      rows = [];

    if (membershipsRevenue) {
      membershipsRevenue.forEach((element) => {
        staff.push(element["first_name"] || "unassigned");
        total.push(element.total || 0);
        rows.push([element["first_name"] || "unassigned", element.total || 0]);
      });
      if (forDownload) {
        return membershipsRevenue;
      }
      staffMembershipBarData.labels = staff;
      staffMembershipBarData.datasets[0].data = total;
    }
    if (tabular) {
      return {
        headers: ["Staff", "Memberships Revenue"],
        data: rows,
      };
    }
    return staffMembershipBarData;
  }

  serviceVsStaffDataFormat(staffServiceRevenue, forDownload = false, tabular = false) {
    const serviceVsStaff = {
      labels: [],
      datasets: [],
    };

    let service = [];
    let serviceName = [];
    let servicesMap = {};
    let staffServices = {};

    try {
      if (staffServiceRevenue) {
        staffServiceRevenue.forEach((element) => {
          if (!staffServices.hasOwnProperty(element["staff__user__first_name"])) {
            staffServices[element["staff__user__first_name"]] = new Array(5).fill(0);
          }
          let serviceIndex = 0;
          if (servicesMap.hasOwnProperty(element["required__service_id"])) {
            serviceIndex = servicesMap[element["required__service_id"]];
          } else {
            service.push(element["required__service_id"]);
            serviceName.push(element["required__service__name"]);
            servicesMap[element["required__service_id"]] = service.length - 1;
            serviceIndex = service.length - 1;
          }
          staffServices[element["staff__user__first_name"]][serviceIndex] = element["total"];
        });
        if (forDownload) {
          return staffServiceRevenue;
        }
      }
      const dataSet = [];
      let color_picker = 0;
      for (const [key, value] of Object.entries(staffServices)) {
        dataSet.push({
          ...serviceVsStaffDataSet,
          label: key,
          data: value,
          backgroundColor: COLORS[color_picker].replace(",1)", ",0.2)"),
          borderColor: COLORS[color_picker],
        });
        color_picker++;
        if (color_picker === COLORS.length) {
          color_picker = 0;
        }
      }
      if (tabular) {
        return {
          headers: ["Staff", ...serviceName],
          data: dataSet.map((item) => [item.label, ...item.data.splice(0, serviceName.length)]),
        };
      }
      serviceVsStaff.datasets = dataSet;
      serviceVsStaff.labels = serviceName;
    } catch (err) {
      console.error({ err });
    }
    return serviceVsStaff;
  }

  paymentsDataFormat(paymentsAnalytics, forDownload = false, tabular = false) {
    let mode = [],
      count = [],
      downloadAnalytics = [],
      rows = [];
    if (paymentsAnalytics) {
      paymentsAnalytics.forEach((element) => {
        mode.push(`${element["payment_mode"] || "Undefined"}`);
        count.push(element.amount || element.total);
        rows.push([`${element["payment_mode"] || "Undefined"}`, element.amount || element.total]);
        if (forDownload) {
          downloadAnalytics.push({
            payment_mode: element["payment_mode"] || "Undefined",
            count: element["count"],
            amount: element.amount || element.total,
          });
        }
      });
    }
    if (forDownload) {
      return downloadAnalytics;
    }
    if (tabular) {
      const total = rows.reduce((total, item) => total + (parseInt(item[1]) || 0), 0);
      rows.push([<Translate>Total</Translate>, total]);
      return {
        headers: ["Payment Mode", "Amount"],
        data: rows,
      };
    }
    paymentsBarData.labels = mode;
    paymentsBarData.datasets[0].data = count;
    return paymentsBarData;
  }

  walletPaymentDataFormat(walletPaymentAnalytics, forDownload = false, tabular = false) {
    let downloadAnalytics = [],
      totalpaid = 0,
      totalpayable = 0;
    walletPaymentAnalytics.forEach((element) => {
      totalpaid = totalpaid + element.amount_paid;
      totalpayable = totalpayable + element.amount_payable;

      if (forDownload) {
        downloadAnalytics.push({
          appointment: element.appointment ? `${this.props.branch.invoice_pre_text}${element.appointment}` : "",
          date: element.date || "",
          amount_paid: element.amount_paid || "",
          amount_payable: element.amount_payable || "",
          customer_name: element.customer_name || "",
          customer_number: element.customer_number || "",
          payment_mode: element.payment_mode || "",
        });
      }
    });
    if (forDownload) {
      return downloadAnalytics;
    }
    if (tabular) {
      const rows = [
        [<Translate>Total Paid</Translate>, totalpaid || 0],
        [<Translate>Total Payable</Translate>, totalpayable] || 0,
      ];
      return {
        headers: ["Transaction Type", "Count"],
        data: rows,
      };
    }
    walletPaymentBarData.labels = ["Total Paid", "Total Payable"];
    walletPaymentBarData.datasets[0].data = [totalpaid, totalpayable];
    return walletPaymentBarData;
  }

  getClientBranch() {
    const { client } = this.props;
    let branches = [];
    let key = 0;
    branches.push({
      key: key++,
      text: "All Branches",
      value: 0,
    });
    if (client) {
      client.forEach((element) => {
        branches.push({
          key: key++,
          text: element.name,
          value: element.id,
        });
      });
    }
    return branches;
  }

  render() {
    const { start_date, end_date, branch, error, paymentModeOrder, showGraphs, setColumnView } = this.state;
    let {
      appointmentAnalytics,
      user,
      staff,
      staffAnalytics,
      staffRevenueAnalytics,
      staffServiceAnalytics,
      paymentsAnalytics,
      membershipsRevenue,
      appointmentOutdoorAnaltyics,
      aclUserPermissions,
      analytics,
    } = this.props;
    const { formatted_component_permissions } = aclUserPermissions;
    const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");
    const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

    const paymentModeOrderOptions_translated = paymentModeOrderOptions.map((option) => {
      const { key, text, value } = option;

      return { key, value, text: <Translate>{text}</Translate> };
    });

    return [
      <div className="analytics-container">
        <div className="analytics">
          <div className="date-range">
            <Forms>
              <Forms.Field className="start-date">
                <label>
                  <Translate>Start Date</Translate>
                </label>
                <input
                  placeholder="Start Date"
                  type="date"
                  min={"2020-10-18"}
                  value={start_date}
                  onChange={(e) => this.startDateChange(e.currentTarget.value)}
                />
              </Forms.Field>
              <Forms.Field className="end-date">
                <label>
                  <Translate>End Date</Translate>
                </label>
                <input
                  placeholder="End Date"
                  type="date"
                  value={end_date}
                  onChange={(e) => this.endDateChange(e.currentTarget.value)}
                />
              </Forms.Field>
              <Forms.Field className="end-date check-Box-text">
                {/* <label>
                  <Translate>Show Graphs?</Translate>
                </label> */}
                <input
                  className="check-BoxInput"
                  type="checkbox"
                  checked={showGraphs}
                  onChange={(e) => {
                    this.setState({ showGraphs: e.target.checked });
                  }}
                />
                {/* <div> */}
                  <label>
                    <Translate>Show Graphs?</Translate>
                  </label>
                {/* </div> */}
              </Forms.Field>
              <Forms.Field className="end-date">
                <label>
                  <Translate>Column View</Translate>
                </label>
                <Checkbox
                  toggle
                  onChange={(_, data) => {
                    this.setState({ setColumnView: data.checked });
                  }}
                />
              </Forms.Field>
            </Forms>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>
                <Translate>Input date range</Translate>
                <span style={{ color: error && "red" }}>
                  <Translate>(maximum 60 days)</Translate>
                </span>
              </p>
              {isSubscriptionExpired && (
                <p style={{ fontSize: "16px", color: "red" }}>
                  <Translate>Subscription is Expired</Translate>
                </p>
              )}
            </div>
          </div>
          <div className="cards" style={{ ...(setColumnView && { gridTemplateColumns: "repeat(1, 1fr)" }) }}>
            <div className="card">
              <h1 style={{color:"#C95B31",display:"flex",justifyContent:"space-between" ,gap:"22px", alignItems:"center", fontSize:"24px", fontWeight:"500",width:"100%"}}>
                <Translate style={{color:"#C95B31"}}>Daily Revenue</Translate>
                {isDownloadReportsAllowed && (
                  <Icon
                    disabled={isSubscriptionExpired}
                    name="download"
                    size="small"
                    style={{ marginLeft: "8px", color:"#333" }}
                    onClick={() => {
                      const data = JSON.parse(JSON.stringify(this.revenueDataFormat(appointmentAnalytics, true)));
                      const totalRevenue = data.reduce((total, item) => total + item.revenue, 0);
                      const total = data.reduce((total, item) => total + item.total, 0);
                      const realised_revenue = data.reduce((total, item) => total + item.realised_revenue, 0);
                      data.push({
                        date: "Total",
                        revenue: totalRevenue.toFixed(2),
                        total,
                        realised_revenue: realised_revenue.toFixed(2),
                      });
                      const fileName = "revenue";
                      const exportType = "csv";
                      exportFromJSON({ data, fileName, exportType });
                    }}
                  />
                )}
              </h1>
              {showGraphs ? (
                <Bar data={this.revenueDataFormat(appointmentAnalytics)} redraw options={{ responsive: true }} />
              ) : (
                <TabularAnalytics content={this.revenueDataFormat(appointmentAnalytics, false, "table")} />
              )}
            </div>

            <div className="card">
              <h1 style={{alignItems:"center", fontSize:"24px", fontWeight:"500", display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Appointments per day</Translate>
                {isDownloadReportsAllowed && (
                  <Icon
                    disabled={isSubscriptionExpired}
                    name="download"
                    size="small"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      const data = this.appointmentsDataFormat(appointmentAnalytics, true);
                      const fileName = "appointments";
                      const exportType = "csv";
                      exportFromJSON({ data, fileName, exportType });
                    }}
                  />
                )}
              </h1>
              {showGraphs ? (
                <Bar
                  data={this.appointmentsDataFormat(appointmentAnalytics)}
                  options={{ ...commonOptions, ...stepSizeTrue }}
                  // options={options}
                  // plugins={plugins}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.appointmentsDataFormat(appointmentAnalytics, false, "table")} />
              )}
            </div>
            <div className="card">
              <h1 style={{color:"#4568D2",alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Top Staff(by services)</Translate>
                {isDownloadReportsAllowed && (
                  <Icon
                    disabled={isSubscriptionExpired}
                    name="download"
                    size="small"
                    style={{ marginLeft: "8px",color:"#333" }}
                    onClick={() => {
                      const data = this.staffServiceDataFormat(staffAnalytics, true);
                      const fileName = "staff_service";
                      const exportType = "csv";
                      exportFromJSON({ data, fileName, exportType });
                    }}
                  />
                )}
              </h1>
              {showGraphs ? (
                <Bar
                  data={this.staffServiceDataFormat(staffAnalytics)}
                  options={{ ...commonOptions, ...stepSizeTrue }}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.staffServiceDataFormat(staffAnalytics, false, "table")} />
              )}
            </div>
            <div className="card">
              <h1 style={{color:"#0BACF0",alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Top Staff(by revenue)</Translate>
                {isDownloadReportsAllowed && (
                  <Icon
                    disabled={isSubscriptionExpired}
                    name="download"
                    size="small"
                    style={{ marginLeft: "8px", color:"#333" }}
                    onClick={() => {
                      const data = this.staffRevenueDataFormat(staffRevenueAnalytics, true);
                      const fileName = "staff_revenue";
                      const exportType = "csv";
                      exportFromJSON({ data, fileName, exportType });
                    }}
                  />
                )}
              </h1>
              {showGraphs ? (
                <Bar
                  data={this.staffRevenueDataFormat(staffRevenueAnalytics)}
                  options={{ ...commonOptions, ...stepSizeFalse }}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.staffRevenueDataFormat(staffRevenueAnalytics, false, "table")} />
              )}
            </div>
            <div className="card">
              <h1 style={{color:"#F48462",alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Services Vs Staff(Top 5)</Translate>
                {isDownloadReportsAllowed && (
                  <Icon
                    disabled={isSubscriptionExpired}
                    name="download"
                    size="small"
                    style={{ marginLeft: "8px" , color:"#333"}}
                    onClick={() => {
                      const data = this.serviceVsStaffDataFormat(staffServiceAnalytics, true);
                      const fileName = "services_vs_staff";
                      const exportType = "csv";
                      exportFromJSON({ data, fileName, exportType });
                    }}
                  />
                )}
              </h1>
              {showGraphs ? (
                <Bar
                  data={this.serviceVsStaffDataFormat(staffServiceAnalytics)}
                  options={{ ...commonOptions, ...stepSizeTrue }}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.serviceVsStaffDataFormat(staffServiceAnalytics, false, "table")} />
              )}
            </div>
            <div className="card">
              <div className="flex row center space-between" style={{width:"100%"}}>
                <h1 style={{color:"#966DB8" ,alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                  <Translate>Payments Modes</Translate>
                  {/* {isDownloadReportsAllowed && (
                    <Icon
                      disabled={isSubscriptionExpired}
                      name="download"
                      size="small"
                      style={{ marginLeft: "8px" ,color:"#333"}}
                      onClick={() => {
                        const data = JSON.parse(JSON.stringify(this.paymentsDataFormat(paymentsAnalytics, true)));
                        const total = data.reduce((total, item) => total + (parseInt(item.amount) || 0), 0);
                        data.push({
                          payment_mode: "Total",
                          amount: total,
                        });
                        const fileName = "payment_mode";
                        const exportType = "csv";
                        exportFromJSON({ data, fileName, exportType });
                      }}
                    />
                  )} */}
                </h1>
                <Forms.Field style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"12px"}}>
                  <Dropdown
                    // placeholder="Payment mode order"
                    placeholder="sort by"
                    fluid
                    selection
                    floating
                    style={{border:"none",width:"180px"}}
                    icon=""
                    value={paymentModeOrder}
                    options={paymentModeOrderOptions_translated}
                    onChange={(e, { value }) => {
                      const { start_date, end_date, branch } = this.state;
                      this.setState({ paymentModeOrder: value });
                      this.props.fetchPaymentsAnalytics(start_date, end_date, branch, value);
                    }}
                  />
                  {isDownloadReportsAllowed && (
                    <Icon
                      disabled={isSubscriptionExpired}
                      name="download"
                      size="large"
                      style={{ marginLeft: "8px" ,color:"#333"}}
                      onClick={() => {
                        const data = JSON.parse(JSON.stringify(this.paymentsDataFormat(paymentsAnalytics, true)));
                        const total = data.reduce((total, item) => total + (parseInt(item.amount) || 0), 0);
                        data.push({
                          payment_mode: "Total",
                          amount: total,
                        });
                        const fileName = "payment_mode";
                        const exportType = "csv";
                        exportFromJSON({ data, fileName, exportType });
                      }}
                    />
                  )}
                </Forms.Field>
              </div>
              {showGraphs ? (
                <Bar
                  data={this.paymentsDataFormat(paymentsAnalytics)}
                  options={{ ...commonOptions, ...stepSizeFalse }}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.paymentsDataFormat(paymentsAnalytics, false, "table")} />
              )}
            </div>
            <div className="card">
              <h1 style={{alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Memberships Revenue</Translate>
                {isDownloadReportsAllowed && (
                  <Icon
                    disabled={isSubscriptionExpired}
                    name="download"
                    size="small"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      const data = this.staffMembershipDataFormat(membershipsRevenue, true);
                      const fileName = "Staff_membership_Revenue";
                      const exportType = "csv";
                      exportFromJSON({ data, fileName, exportType });
                    }}
                  />
                )}
              </h1>
              {showGraphs ? (
                <Bar
                  data={this.staffMembershipDataFormat(membershipsRevenue)}
                  options={{ ...commonOptions, ...stepSizeFalse }}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.staffMembershipDataFormat(membershipsRevenue, false, "table")} />
              )}
            </div>

            <div className="card">
              <h1 style={{alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Staff</Translate>
              </h1>
              {showGraphs ? (
                <Doughnut
                  data={this.staffDataFormat(staff)}
                  legend={legendLeft}
                  redraw
                  options={{ responsive: true }}
                />
              ) : (
                <TabularAnalytics content={this.staffDataFormat(staff, "table")} />
              )}
            </div>

            <div className="card">
              <h1 style={{alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                <Translate>Appointment Indoor/Outdoor</Translate>
              </h1>
              {showGraphs ? (
                <Doughnut
                  options={{ responsive: true }}
                  data={this.appointmentLocationDataFormat(appointmentOutdoorAnaltyics)}
                  legend={legendLeft}
                  redraw
                />
              ) : (
                <TabularAnalytics content={this.appointmentLocationDataFormat(appointmentOutdoorAnaltyics, "table")} />
              )}
            </div>
            <div className="card">
              <div className="flex row center space-between">
                <h1 style={{alignItems:"center", fontSize:"24px", fontWeight:"500",display:"flex",justifyContent:"space-between", width:"100%"}}>
                  <Translate>Direct Wallet Transaction</Translate>
                  <br></br>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <h1 style={{alignItems:"center", fontSize:"24px", fontWeight:"500",margin:"0px 12px",marginTop:"-1px"}}>
                      <Translate>Paid Vs Payable</Translate>
                      {isDownloadReportsAllowed && (
                        <Icon
                          disabled={isSubscriptionExpired}
                          name="download"
                          size="small"
                          style={{ marginLeft: "8px" }}
                          onClick={() => {
                            const data = this.walletPaymentDataFormat(analytics.data.wallet_payment, true);
                            const fileName = "Paid vs Payable";
                            const exportType = "csv";
                            exportFromJSON({ data, fileName, exportType });
                          }}
                        />
                      )}
                    </h1>
                  </div>
                </h1>
              </div>
              {showGraphs ? (
                <Bar
                  data={this.walletPaymentDataFormat(analytics.data.wallet_payment)}
                  options={{ ...commonOptions, ...stepSizeFalse }}
                  redraw
                />
              ) : (
                <TabularAnalytics
                  content={this.walletPaymentDataFormat(analytics.data.wallet_payment, false, "table")}
                />
              )}
            </div>
          </div>
        </div>
      </div>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    staff: state.staffBasic.results,
    user: state.user,
    client: state.client.clients,
    aclUserPermissions: state.aclUserPermissions.data,

    appointmentAnalytics: state.appointmentAnalytics,
    serviceAnalytics: state.serviceAnalytics,
    staffRevenueAnalytics: state.staffRevenueAnalytics,
    staffServiceAnalytics: state.staffServiceAnalytics,
    staffAnalytics: state.staffAnalytics,
    paymentsAnalytics: state.paymentsAnalytics,
    membershipsRevenue: state.membershipsRevenue,
    appointmentOutdoorAnaltyics: state.appointmentOutdoorAnaltyics,
    analytics: state.analytics,
    branch: state.branch.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAppointmentAnalytics: (start_date, end_date, branch) =>
      dispatch(appointmentAnalyticsFetchData(start_date, end_date, branch)),
    fetchStaffAnalytics: (start_date, end_date, branch) =>
      dispatch(staffAnalyticsFetchData(start_date, end_date, branch)),
    fetchStaffRevenueAnalytics: (start_date, end_date, branch) =>
      dispatch(staffRevenueAnalyticsFetchData(start_date, end_date, branch)),
    fetchStaffServiceAnalytics: (start_date, end_date, branch) =>
      dispatch(staffServiceAnalyticsFetchData(start_date, end_date, branch)),
    fetchPaymentsAnalytics: (start_date, end_date, branch, paymentModeOrder) =>
      dispatch(paymentsAnalyticsFetchData(start_date, end_date, branch, paymentModeOrder)),
    fetchMembershipsRevenueAnalytics: (start_date, end_date, branch) =>
      dispatch(membershipsRevenueAnalyticsFetchData(start_date, end_date, branch)),
    fetchOutdoorAnalytics: (start_date, end_date, branch) =>
      dispatch(appointmentOutdoorAnalyticsFetch(start_date, end_date, branch)),
    fetchWalletPaymentAnalytics: (start_date, end_date, branch) => {
      dispatch(walletPaymentAnalyticsFetchData(start_date, end_date, branch));
    },
    staffBasicFetchData: () => dispatch(staffBasicFetchData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
