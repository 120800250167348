import React from "react";
import { Container, FieldIcon, GeneralFieldContainer, GeneralFieldInput } from "./styles/search";
import searchIcon from "./search-icon.svg";
export default function Search({ children, ...restOfTheProps }) {
  return <Container {...restOfTheProps}>{children}</Container>;
}

Search.GeneralField = function SearchField({ value, onChange, placeholder, children, ...restOfTheProps }) {
  return (
    <GeneralFieldContainer {...restOfTheProps}>
      <FieldIcon alt="Search" src={searchIcon} />
      <GeneralFieldInput value={value} onChange={onChange} type="text" placeholder={placeholder} />
    </GeneralFieldContainer>
  );
};
