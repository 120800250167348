import "./index.scss";
import React, { useState, useEffect } from "react";
import SearchDropdown from "SearchDropdown";
import SearchMultiDropdown from "../SearchMultDropdown/index";
import { Input, Dropdown, Button, Modal } from "semantic-ui-react";
import consumption from "../../images/consumption.svg";
import { useDispatch, useSelector } from "react-redux";
import { productUsageModal, productUsageReset } from "../../store/actions/productUsage";
import { Translate } from "react-auto-translate";
import { TIME_UNIT_OPTIONS } from "../../utilities/constants";
import { validatePercentage } from "../../utilities/CommonFunctions";

const quantityOptions = Array.apply(null, Array(20)).map((_, i) => ({
  key: i + 1,
  text: `${i + 1}`,
  value: i + 1,
}));

const discountTypes = [
  { key: 1, text: "%", value: 0 },
  { key: 2, text: "/-", value: 1 },
];

function ServiceDetails(props) {
  const dispatch = useDispatch();
  const {
    placeholder,
    services,
    allServices, //services or products without label filter
    providers,
    selectedStaff,
    index,
    onChange,
    update,
    setRows,
    rows,
    options = [],
    form,
  } = props;

  const isQuickBook = useSelector((state) => state.isQuickBook.status);
  const [revenuePercentage, setRevenuePercentage] = useState({
    staff_id: null,
    value: 0,
  });
  const [revenueModalOpen, setRevenueModalOpen] = useState(false);
  const [revenueModalError, setRevenueModalError] = useState("");

  // staff revenue percentage  validation logic
  const staffsPercentage = {};
  const selectedProviders = rows[index]["staffs"] || [];
  let total_revenue_percentage = 0;

  selectedProviders.forEach((staff) => {
    const { id, revenue_percentage } = staff;

    staffsPercentage[id] = revenue_percentage;
    total_revenue_percentage += parseFloat(revenue_percentage);
  });

  // retriving unseleted staff list from localstorage
  const unselected_staff = JSON.parse(localStorage.getItem("UNSELECTED_STAFF")) || [];

  // hashing for faster searching
  const unselected_staff_hash = {};
  unselected_staff.forEach((staff) => {
    unselected_staff_hash[staff] = true;
  });

  const TIME_UNIT_OPTIONS_TRANSLATED = TIME_UNIT_OPTIONS.map((option) => {
    const { key, text, value } = option;
    return {
      key,
      value,
      text: <Translate>{text}</Translate>,
    };
  });

  // filtering out the unselected staff from appointment screen
  const filteredProviders = providers.filter((provider) => !(provider.id in unselected_staff_hash));

  function searchStaffs(options, query) {
    return options.filter((option) => option.text.toLowerCase().startsWith(query.toLowerCase()));
  }

  const discountValidation = (rows, index, type, value) => {
    if (rows[index].service_id || rows[index].product_id) {
      if (validatePercentage(value) && type == 0) {
        rows[index].discount_type_error = "Discount must be higher than 0 and equal to or lower than 100";
      } else {
        delete rows[index].discount_type_error;
      }
    }
  };

  const updateStaffRevenuePercentage = () => {
    const { staff_id, value } = revenuePercentage;

    if (!isValidNumber(value) || value < 0 || value > 100) {
      setRevenueModalError("Please enter a valid Revenue Percentage");
      return false;
    }

    const providers = rows[index]["staffs"].map((staff) => {
      return {
        ...staff,
        revenue_percentage: staff.id === staff_id ? value : staff.revenue_percentage,
      };
    });

    rows[index]["staffs"] = providers;

    setRevenueModalError("");
    setRows([...rows]);
    setRevenueModalOpen(false);
    setRevenuePercentage({
      staff_id: null,
      value: 0,
    });
  };

  const RevenueModal = () => {
    return (
      <Modal
        style={{ backgroundColor: "rgba(0,0,0,0)", width: "400px" }}
        onClose={() => {
          setRevenueModalOpen(false);
          setRevenuePercentage({
            staff_id: null,
            value: 0,
          });
        }}
        onOpen={() => setRevenueModalOpen(true)}
        open={revenueModalOpen}
      >
        <Modal.Content>
          <div style={{ width: "100%" }}>
            <h3>
              <Translate>Staff Revenue Percentage</Translate>
            </h3>
            <Input
              value={revenuePercentage.value}
              onChange={(e, { value }) => {
                setRevenuePercentage({
                  ...revenuePercentage,
                  value,
                });
              }}
              placeholder="Revenue..."
              icon="percent"
              iconPosition="right"
              style={{ width: "100%" }}
            />
          </div>
          {revenueModalError.length > 0 && <p style={{ color: "red", padding: "10px 0px" }}>{revenueModalError}</p>}
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: "20px" }}>
            <Button
              onClick={() => {
                setRevenueModalOpen(false);
                setRevenuePercentage({
                  staff_id: null,
                  value: 0,
                });
              }}
            >
              <Translate>Cancel</Translate>
            </Button>
            <Button onClick={updateStaffRevenuePercentage}>Update</Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  const isValidNumber = (value) => {
    if (value === null || value === "" || isNaN(value)) {
      return false;
    }
    return true;
  };

  const renderLabelUI = (item) => {
    let revenue_percentage = staffsPercentage[item.id];

    if (!isValidNumber(revenue_percentage)) {
      revenue_percentage = 100;
    }

    return {
      content: (
        <span
          style={{ color: total_revenue_percentage > 100 ? "rgb(255,55,55)" : "rgba(0,0,0,0.7)" }}
          onClick={() => {
            setRevenuePercentage({
              staff_id: item.id,
              value: revenue_percentage,
            });
            setRevenueModalOpen(true);
          }}
        >
          {item.user.name} | {revenue_percentage}%
        </span>
      ),
    };
  };

  return (
    <div id="appointment-service-details" key={index} className="service-details">
      <SearchMultiDropdown
        fluid={isQuickBook ? false : true}
        placeholder={placeholder[0]}
        options={services}
        allOptions={allServices}
        index={index}
        onChange={onChange}
        update={update}
        setRows={setRows}
        rows={rows}
        selectedStaff={selectedStaff}
      />
      {placeholder[0] === "product" ? (
        <Input
          className="quantity"
          value={rows[index].quantity}
          type="number"
          onChange={(event, data) => {
            update(event, data, "quantity", onChange, index, setRows, rows, selectedStaff);
            update(
              event,
              { value: data.value * rows[index].original_cost },
              "cost",
              onChange,
              index,
              setRows,
              rows,
              selectedStaff
            );
          }}
        />
      ) : (
        <>
          <Input
            placeholder="Duration"
            className="duration"
            fluid={isQuickBook ? false : true}
            label={
              <Dropdown
                options={TIME_UNIT_OPTIONS_TRANSLATED}
                value={rows[index].time_unit}
                onChange={(event, data) => {
                  update(event, data, "time_unit", onChange, index, setRows, rows, selectedStaff);
                }}
              />
            }
            labelPosition="right"
            value={rows[index].duration}
            onChange={(event, data) => {
              const numberReg = /^[0-9]*$/;
              if (numberReg.test(data.value)) {
                update(event, data, "duration", onChange, index, setRows, rows, selectedStaff);
              }
            }}
          />

          <Input
            placeholder="Quantity"
            className="quantity"
            value={rows[index].quantity}
            onChange={(event, data) => {
              const numberReg = /^[0-9]*$/;
              if (numberReg.test(data.value) && parseInt(data.value) !== 0) {
                update(event, data, "quantity", onChange, index, setRows, rows, selectedStaff);
                update(
                  event,
                  { value: data.value * rows[index].original_cost },
                  "cost",
                  onChange,
                  index,
                  setRows,
                  rows,
                  selectedStaff
                );
              }
            }}
          />
        </>
      )}

      {placeholder[0] === "service" && form && form.id && rows && rows[index].id && (
        <Button
          className="product-usage-btn"
          onClick={() => {
            dispatch(productUsageReset());
            dispatch(
              productUsageModal({
                productUsageModal: true,
                form: form,
                rows: rows[index],
                options: options,
              })
            );
          }}
          icon
        >
          <img src={consumption} width="30" height="25" alt="" />
        </Button>
      )}
      <Input
        className="cost"
        label={{ icon: "rupee" }}
        labelPosition="right corner"
        placeholder="Cost"
        onChange={(event, data) => {
          update(event, data, "cost", onChange, index, setRows, rows, selectedStaff);
        }}
        value={rows[index].cost || 0}
        // type='number'
      />

      <div className="provider-selector-dropdown">
        {placeholder[0] === "service" ? (
          <Dropdown
            value={
              rows[index][placeholder[2]] && rows[index][placeholder[2]].length > 0
                ? rows[index][placeholder[2]].map(({ id, staff_id }) => id || staff_id)
                : []
            }
            placeholder={"Select " + placeholder[2]}
            fluid
            renderLabel={renderLabelUI}
            multiple
            selection
            scrolling
            search={searchStaffs}
            options={filteredProviders}
            index={index}
            onChange={(event, data) => {
              update(event, data, "staffs", onChange, index, setRows, rows, selectedStaff);
            }}
          />
        ) : (
          <SearchDropdown
            placeholder={placeholder[1]}
            options={filteredProviders}
            index={index}
            onChange={onChange}
            update={update}
            setRows={setRows}
            rows={rows}
            selectedStaff={selectedStaff}
            searchKeys={["text"]}
          />
        )}
      </div>

      <Input
        className="discountDropdown"
        label={
          <Dropdown
            options={discountTypes}
            value={rows[index].discount_type || 0}
            onChange={(e, data) => {
              rows[index].discount_type = data.value;
              discountValidation(rows, index, data.value, rows[index].discount_type_value);
              setRows([...rows]);
            }}
          />
        }
        fluid={isQuickBook ? false : true}
        labelPosition="right"
        placeholder="Discount"
        value={rows[index].discount_type_value}
        onChange={(e) => {
          rows[index].discount_type_value = e.target.value;
          discountValidation(rows, index, rows[index].discount_type, e.target.value);
          if (rows[index].discount_type === null || !("discount_type" in rows[index])) {
            rows[index].discount_type = 0;
          }
          setRows([...rows]);
        }}
        type="number"
      />
      {placeholder[0] === "service" && !isQuickBook && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Input
            placeholder="Seat/Table"
            value={rows[index].seat_number || ""}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              rows[index].seat_number = e.target.value === "" ? null : e.target.value;
              setRows([...rows]);
            }}
            type="number"
          />
        </div>
      )}
      {RevenueModal()}
    </div>
  );
}

export default ServiceDetails;
