import { API_BASE_URL, BULK_PROMOTION_VIEW_API, RECURRING_PROMOTION_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";
import ToastAlert from "../../utilities/toastAlert/toastAlert";

export function bulkPromotionViewFetchDataSuccess(bulkPromotionView) {
  return {
    type: "BULK_PROMOTION_VIEW_FETCH_DATA_SUCCESS",
    data: bulkPromotionView,
  };
}

function scheduledBulkPromotionViewFetchDataSuccess(data) {
  return {
    type: "SCHEDULED_BULK_PROMOTION_VIEW_FETCH_DATA_SUCCESS",
    data,
  };
}

export function bulkPromotionViewFetchData(pageNum = 1) {
  return (dispatch) => {
    dispatch(startLoading("BULK_PROMOTION_VIEW_LOADER", "loading bulkPromotionView"));
    axios
      .get(API_BASE_URL + BULK_PROMOTION_VIEW_API + "?page=" + pageNum, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(bulkPromotionViewFetchDataSuccess(response.data));
          dispatch(endLoading("BULK_PROMOTION_VIEW_LOADER"));
        } else {
          dispatch(errored("BULK_PROMOTION_VIEW_LOADER", true));
          dispatch(endLoading("BULK_PROMOTION_VIEW_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BULK_PROMOTION_VIEW_LOADER", true));
        dispatch(endLoading("BULK_PROMOTION_VIEW_LOADER"));
      });
  };
}

export function getRecurringPromotion(pageNum = 1) {
  return (dispatch) => {
    dispatch(startLoading("BULK_PROMOTION_VIEW_LOADER", "loading scheduled promotions"));
    axios({
      method: "GET",
      url: API_BASE_URL + RECURRING_PROMOTION_API + "?page=" + pageNum,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(scheduledBulkPromotionViewFetchDataSuccess(response.data));
        } else {
          dispatch(errored("BULK_PROMOTION_VIEW_LOADER", true));
        }
        dispatch(endLoading("BULK_PROMOTION_VIEW_LOADER"));
      })
      .catch(function (error) {
        console.log(`recurring Promotion Error: `, { error });
        ToastAlert({ message: "Unable to fetch recurring Promotions", type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("BULK_PROMOTION_VIEW_LOADER", true));
        dispatch(endLoading("BULK_PROMOTION_VIEW_LOADER"));
      });
  };
}
