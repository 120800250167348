import axios from "axios";
import { API_BASE_URL, MEMBERHSHIP_ASSOCIATED_APPOINTMENTS } from "../utilities";
import ToastAlert from "../utilities/toastAlert/toastAlert";

import configureStore from "../store/configureStore";
import { authenticationLogout } from "../store/actions/authentication";

const store = configureStore();

export async function getAppoitmentDeleteData(id) {
  const url = API_BASE_URL + MEMBERHSHIP_ASSOCIATED_APPOINTMENTS;
  const headers = {
    Authorization: localStorage.getItem("token"),
  };

  try {
    const response = await axios.get(url, {
      params: {
        appointment_id: id,
      },
      headers: headers,
    });

    return { status: "success", response };
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      store.dispatch(authenticationLogout());
    }
    const message = error.response?.data?.detail ? error.response?.data?.detail : error.response?.data[0];

    ToastAlert({ message, type: "error" });
    return { status: "error", error };
  }
}
