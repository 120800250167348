export function branchSubscriptionsServices(
  state = { error: false, loading: false, data: [], isFetched: false },
  action
) {
  switch (action.type) {
    case "GET_BRANCH_SUBSCRIPTIONS_SERVICES_SUCCESS":
      return action;
    default:
      return state;
  }
}
