import React, { useState, useEffect } from "react";
import "./StaffRevenue.scss";
import { useSelector, useDispatch } from "react-redux";
import { getStaffServiceRevenue } from "../../store/actions/staffRevenue";
import { Button, Icon, Input } from "semantic-ui-react";
import exportFromJSON from "export-from-json";
import DatePicker from "react-datepicker";
import moment from "moment";
import { PopperContainer } from "../DatePickerContainer/PopperContainer";
import { Translate } from "react-auto-translate";

//pure functions
import {
  getNumberOfStaffs,
  //
  calculateServiceRevenueCost,
  calculateRevenuePostDirectDiscount,
  calculateRevenuePostMembershipDiscount,
  calculateRevenuePostCampaign,
  //
  calculateProductRevenueCost,
  calculateProductRevenuePostDirectDiscount,
  calculateProductRevenuePostMembershipDiscount,
  calculateProductRevenuePostCampaign,
} from "./utils";
import DatesDropdown from "../DatesDropdown/datesDropdown";

const showRevenueOptions = {
  showServiceRevenue: false,
  showProductRevenue: false,
  showMembershipRevenue: false,
};

function StaffRevenue({ staffDetail }) {
  const dispatch = useDispatch();
  //
  const serviceRevenue = useSelector((state) => state.staffServicesRevenue);
  //
  const [dates, setDates] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [showRevenue, setShowRevenue] = useState({
    ...showRevenueOptions,
    showServiceRevenue: true,
  });
  const [totalRevenue, setTotalRevenue] = useState({
    serviceRevenue: 0,
    productRevenue: 0,
    membershipRevenue: 0,
  });
  const [totalDiscountedRevenue, setTotalDiscountedRevenue] = useState({
    serviceRevenue: 0,
    productRevenue: 0,
  });
  const [totalMembershipDiscountedRevenue, setTotalMembershipDiscountedRevenue] = useState({
    serviceRevenue: 0,
    productRevenue: 0,
  });
  const [totalCampaignDiscountedRevenue, setTotalCampaignDiscountedRevenue] = useState({
    serviceRevenue: 0,
    productRevenue: 0,
  });
  const [finalRevenue, setFinalRevenue] = useState({
    serviceRevenue: 0,
    productRevenue: 0,
  });

  const [serviceDiscountedRevenue, setServiceDiscountedRevenue] = useState([]);
  const [productDiscountedRevenue, setProductDiscountedRevenue] = useState([]);
  const [membershipRevenueDetails, setMembershipRevenueDetails] = useState([]);
  //
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;

  useEffect(() => {
    const staff_service_cost = [];
    let totalServiceRevenue = 0;
    let totalDirectDiscountedServiceRevenue = 0;
    let totalCampaignDiscountedServiceRevenue = 0;
    let totalMembershipDiscountedServiceRevenue = 0;
    let serviceFinalRevenue = 0;
    //
    const staff_product_cost = [];
    let totalProductRevenue = 0;
    let totalDirectDiscountedProductRevenue = 0;
    let totalCampaignDiscountedProductRevenue = 0;
    let totalMembershipDiscountedProductRevenue = 0;
    let productFinalRevenue = 0;
    //
    const staff_membership_sold = [];
    let totalMembershipRevenue = 0;

    for (let i = 0; i < serviceRevenue.length; i++) {
      const appointment_data = JSON.parse(serviceRevenue[i].data);
      const appointment_id = appointment_data.appointment_number || "";
      const appointment_date = appointment_data.invoice_date;

      appointment_data["services"].forEach((billed_item) => {
        //if staffs = [] (Empty Array) or staff = ""
        const numberOfStaffs = getNumberOfStaffs(billed_item);

        if (numberOfStaffs === 0) {
          return false;
        }

        if (billed_item["type"] === "service") {
          let thisStaff = [];

          // getting thisStaff
          if ("staffs" in billed_item) {
            thisStaff = billed_item["staffs"].filter((ele) => ele.staff_id === staffDetail.id);
          }

          // if this staff is not in the service then skip this service revenue
          if (thisStaff.length === 0) {
            return false;
          }

          const cost = calculateServiceRevenueCost(billed_item, thisStaff);

          const [revenue_post_direct_discount, staff_direct_discount] = calculateRevenuePostDirectDiscount(
            billed_item,
            thisStaff
          );
          const [revenue_post_membership_discount, staff_membership_discount] = calculateRevenuePostMembershipDiscount(
            billed_item,
            thisStaff
          );
          const [revenue_post_campaign, staff_campaign_discount] = calculateRevenuePostCampaign(billed_item, thisStaff);

          // revenue_post_campaign is the final revenue
          const final_revenue = revenue_post_campaign;

          staff_service_cost.push({
            appointment_id,
            date: appointment_date,
            cost,
            revenue_post_direct_discount,
            discount: staff_direct_discount,
            revenue_post_campaign,
            staff_campaign_discount,
            revenue_post_membership_discount,
            staff_membership_discount,
            final_revenue,
          });

          totalServiceRevenue += cost;
          totalDirectDiscountedServiceRevenue += revenue_post_direct_discount;
          totalCampaignDiscountedServiceRevenue += revenue_post_campaign;
          totalMembershipDiscountedServiceRevenue += revenue_post_membership_discount;
          serviceFinalRevenue += final_revenue;
        }
        //
        else if (billed_item["type"] === "product") {
          // checking whether curr staff was involved in product revenue or not

          const currStaffName = staffDetail.user?.first_name;
          const currStaffId = staffDetail.id;
          const billedStaffName = billed_item["staff"] || "";
          const billedStaffId = billed_item["staff_id"];

          if (billedStaffId) {
            if (billedStaffId !== currStaffId) {
              return false;
            }
          } else if (billedStaffName !== currStaffName) {
            return false;
          }

          const cost = calculateProductRevenueCost(billed_item);

          const [revenue_post_direct_discount, direct_discount] =
            calculateProductRevenuePostDirectDiscount(billed_item);

          const [revenue_post_membership_discount, staff_membership_discount] =
            calculateProductRevenuePostMembershipDiscount(billed_item);

          const [revenue_post_campaign, staff_campaign_discount] = calculateProductRevenuePostCampaign(billed_item);

          // revenue_post_campaign is the final revenue
          const final_revenue = revenue_post_campaign;

          staff_product_cost.push({
            appointment_id,
            date: appointment_date,
            cost,
            revenue_post_direct_discount,
            discount: direct_discount,
            revenue_post_campaign,
            staff_campaign_discount,
            revenue_post_membership_discount,
            staff_membership_discount,
            final_revenue,
          });

          totalProductRevenue += cost;
          totalDirectDiscountedProductRevenue += revenue_post_direct_discount;
          totalCampaignDiscountedProductRevenue += revenue_post_campaign;
          totalMembershipDiscountedProductRevenue += revenue_post_membership_discount;
          productFinalRevenue += final_revenue;
        }
        //
        else if (billed_item["type"] === "membership") {
          // checking whether curr staff was involved in membership revenue or not

          const currStaffName = staffDetail.user?.first_name;
          const currStaffId = staffDetail.id;
          const billedStaffName = billed_item["staff"] || "";
          const billedStaffId = billed_item["staff_id"];

          if (billedStaffId) {
            if (billedStaffId !== currStaffId) {
              return false;
            }
          } else if (billedStaffName !== currStaffName) {
            return false;
          }

          totalMembershipRevenue += Number(billed_item.cost);
          const cost = Number(billed_item.cost).toFixed();

          staff_membership_sold.push({
            customer_name: appointment_data.cust_name,
            date: appointment_date,
            cost,
            name: billed_item.name,
          });
        }
      });
    }

    //
    if (totalProductRevenue || totalServiceRevenue || totalMembershipRevenue)
      setTotalRevenue({
        ...totalRevenue,
        serviceRevenue: Math.round(totalServiceRevenue),
        productRevenue: Math.round(totalProductRevenue),
        membershipRevenue: Math.round(totalMembershipRevenue),
      });

    if (totalDirectDiscountedProductRevenue || totalDirectDiscountedServiceRevenue)
      setTotalDiscountedRevenue({
        ...totalDiscountedRevenue,
        serviceRevenue: Math.round(totalDirectDiscountedServiceRevenue),
        productRevenue: Math.round(totalDirectDiscountedProductRevenue),
      });

    if (totalMembershipDiscountedProductRevenue || totalMembershipDiscountedServiceRevenue)
      setTotalMembershipDiscountedRevenue({
        ...totalMembershipDiscountedRevenue,
        serviceRevenue: Math.round(totalMembershipDiscountedServiceRevenue),
        productRevenue: Math.round(totalMembershipDiscountedProductRevenue),
      });

    if (totalCampaignDiscountedProductRevenue || totalCampaignDiscountedServiceRevenue)
      setTotalCampaignDiscountedRevenue({
        ...totalCampaignDiscountedRevenue,
        serviceRevenue: Math.round(totalCampaignDiscountedServiceRevenue),
        productRevenue: Math.round(totalCampaignDiscountedProductRevenue),
      });

    // totalCampaignDiscountedProductRevenue and totalCampaignDiscountedServiceRevenue are the final revenues respectively
    if (serviceFinalRevenue || productFinalRevenue)
      setFinalRevenue({
        ...finalRevenue,
        serviceRevenue: Math.round(serviceFinalRevenue),
        productRevenue: Math.round(productFinalRevenue),
      });

    setServiceDiscountedRevenue(staff_service_cost);
    setProductDiscountedRevenue(staff_product_cost);
    setMembershipRevenueDetails(staff_membership_sold);
  }, [serviceRevenue]);

  function handleRevenue(type) {
    switch (type) {
      case "service": {
        setShowRevenue({ ...showRevenueOptions, showServiceRevenue: true });
        break;
      }
      case "product": {
        setShowRevenue({ ...showRevenueOptions, showProductRevenue: true });
        break;
      }
      case "membership": {
        setShowRevenue({ ...showRevenueOptions, showMembershipRevenue: true });
        break;
      }
    }
  }

  function serviceRevenueDataFormat(data) {
    let download = [];

    data.forEach((element) => {
      const revenue_post_direct_discount = parseFloat(element.discount) ? element.revenue_post_direct_discount : "N/A";
      const revenue_post_campaign = parseFloat(element.staff_campaign_discount) ? element.revenue_post_campaign : "N/A";
      const revenue_post_membership_discount = parseFloat(element.staff_membership_discount)
        ? element.revenue_post_membership_discount
        : "N/A";

      download.push({
        "Appointment Id": element.appointment_id,
        "Date Time": element.date,
        Revenue: element.cost,
        "Revenue Post Direct Discount": revenue_post_direct_discount,
        "Direct Discount": element.discount || "0.00/-",
        "Revenue Post Membership Discount": revenue_post_membership_discount,
        "Membership Discount": element.staff_membership_discount || "0.00/-",
        "Revenue Post Campaign Discount": revenue_post_campaign,
        "Campaign Discount": element.staff_campaign_discount || "0.00/-",
        "Final Revenue": element.final_revenue,
      });
    });

    download.push({
      "Appointment Id": "",
      "Date Time": "Total Revenue",
      Revenue: totalRevenue.serviceRevenue,
      "Revenue Post Direct Discount": "",
      "Direct Discount": "",
      "Revenue Post Membership Discount": "",
      "Membership Discount": "",
      "Revenue Post Campaign Discount": "",
      "Campaign Discount": "",
      "Final Revenue": finalRevenue.serviceRevenue,
    });

    return download;
  }

  function productRevenueDataFormat(data) {
    let download = [];

    data.forEach((element) => {
      const revenue_post_direct_discount = parseFloat(element.discount) ? element.revenue_post_direct_discount : "N/A";
      const revenue_post_campaign = parseFloat(element.staff_campaign_discount) ? element.revenue_post_campaign : "N/A";
      const revenue_post_membership_discount = parseFloat(element.staff_membership_discount)
        ? element.revenue_post_membership_discount
        : "N/A";

      download.push({
        "Appointment Id": element.appointment_id,
        "Date Time": element.date,
        Revenue: element.cost,
        "Revenue Post Direct Discount": revenue_post_direct_discount,
        "Direct Discount": element.discount || "0.00/-",
        "Revenue Post Membership Discount": revenue_post_membership_discount,
        "Membership Discount": element.staff_membership_discount || "0.00/-",
        "Revenue Post Campaign Discount": revenue_post_campaign,
        "Campaign Discount": element.staff_campaign_discount || "0.00/-",
        "Final Revenue": element.final_revenue,
      });
    });

    download.push({
      "Appointment Id": "",
      "Date Time": "Total Revenue",
      Revenue: totalRevenue.productRevenue,
      "Revenue Post Direct Discount": "",
      "Direct Discount": "",
      "Revenue Post Membership Discount": "",
      "Membership Discount": "",
      "Revenue Post Campaign Discount": "",
      "Campaign Discount": "",
      "Final Revenue": finalRevenue.productRevenue,
    });

    return download;
  }

  function membershipRevenueDataFormat(data) {
    let download = [];

    data.forEach((revenue) => {
      download.push({
        "Customer Name": revenue.customer_name,
        "Membership Name": revenue.name,
        Date: revenue.date,
        Price: revenue.cost,
      });
    });

    download.push({
      "Customer Name": "",
      "Membership Name": "",
      Date: "Total Revenue",
      Price: totalRevenue.membershipRevenue,
    });

    return download;
  }

  useEffect(() => {
    const { start_date, end_date } = dates;
    dispatch(getStaffServiceRevenue({ id: staffDetail.id, start_date, end_date }));
  }, []);

  const onDateChange = (start_date, end_date) => {
    setDates({ start_date, end_date });
    dispatch(getStaffServiceRevenue({ id: staffDetail.id, start_date, end_date }));
  };

  return (
    <div className="staff-revenue-container">
      <h1>
        <Translate>Revenue by</Translate> {staffDetail.user.name}
      </h1>
      {isSubscriptionExpired && (
        <p style={{ color: "red" }}>
          <Translate>Subscription is Expired</Translate>
        </p>
      )}
      <div className="show-container">
        <DatesDropdown onChange={onDateChange} maxDays={45} />

        <div className="handles">
          <Button onClick={() => handleRevenue("service")} active={showRevenue.showServiceRevenue}>
            <Translate>Show Services Revenue</Translate>
          </Button>
          <Button onClick={() => handleRevenue("product")} active={showRevenue.showProductRevenue}>
            <Translate>Show Product Revenue</Translate>
          </Button>
          <Button onClick={() => handleRevenue("membership")} active={showRevenue.showMembershipRevenue}>
            <Translate>Show Membership Revenue</Translate>
          </Button>
        </div>
      </div>
      {showRevenue.showServiceRevenue && serviceDiscountedRevenue.length === 0 ? (
        <h2>
          <Translate>No service revenue for this date range</Translate>
        </h2>
      ) : (
        showRevenue.showServiceRevenue && (
          <div>
            <h1>
              <Translate>Services Revenue</Translate>
              <Icon
                disabled={isSubscriptionExpired}
                name="download"
                size="small"
                onClick={() => {
                  const data = serviceRevenueDataFormat(serviceDiscountedRevenue, true);
                  const fileName = "service_revenue";
                  const exportType = "csv";
                  exportFromJSON({ data, fileName, exportType });
                }}
              />
            </h1>
            <div className="service-container">
              <table className="ui celled table">
                <thead>
                  <tr>
                    <th>
                      <Translate>Appointment Id</Translate>
                    </th>
                    <th>
                      <Translate>Date</Translate>
                    </th>
                    <th>
                      <Translate>Revenue</Translate>
                    </th>
                    <th>
                      <Translate>Revenue Post Direct Discount</Translate>
                    </th>
                    <th>
                      <Translate>Revenue Post Membership Discount</Translate>
                    </th>
                    <th>
                      <Translate>Revenue Post Campaign Discount</Translate>
                    </th>
                    <th>
                      <Translate>Final Revenue</Translate>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {serviceDiscountedRevenue.map((revenue, index) => {
                    let staff_direct_discount = "";
                    let staff_membership_discount = "";
                    let staff_campaign_discount = "";

                    let revenue_post_direct_discount = "N/A";
                    let revenue_post_membership_discount = "N/A";
                    let revenue_post_campaign = "N/A";

                    if (parseFloat(revenue.discount)) {
                      staff_direct_discount = `( ${revenue.discount} )`;
                      revenue_post_direct_discount = <span>&#x20B9; {revenue.revenue_post_direct_discount}/-</span>;
                    }
                    if (parseFloat(revenue.staff_membership_discount)) {
                      staff_membership_discount = `( ${revenue.staff_membership_discount} )`;
                      revenue_post_membership_discount = (
                        <span>&#x20B9; {revenue.revenue_post_membership_discount}/-</span>
                      );
                    }
                    if (parseFloat(revenue.staff_campaign_discount)) {
                      staff_campaign_discount = `( ${revenue.staff_campaign_discount} )`;
                      revenue_post_campaign = <span>&#x20B9; {revenue.revenue_post_campaign}/-</span>;
                    }

                    return (
                      <tr key={index}>
                        <td>{revenue.appointment_id}</td>
                        <td>{revenue.date}</td>
                        <td>&#x20B9; {revenue.cost}/-</td>
                        <td>
                          {revenue_post_direct_discount} {staff_direct_discount}
                        </td>
                        <td>
                          {revenue_post_membership_discount} {staff_membership_discount}
                        </td>
                        <td>
                          {revenue_post_campaign} {staff_campaign_discount}
                        </td>
                        <td>{revenue.final_revenue}/-</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td />
                    <td>
                      <Translate>Total Revenue</Translate>
                    </td>
                    <td>&#x20B9; {totalRevenue.serviceRevenue}</td>
                    <td />
                    <td />
                    <td />
                    <td>&#x20B9; {finalRevenue.serviceRevenue}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "20px" }}>
              <p className="info">
                <Icon name="info circle" />
                Discount on total bill either through campaign, membership or direct discount is not considered
              </p>
            </div>
          </div>
        )
      )}
      {showRevenue.showProductRevenue && productDiscountedRevenue.length === 0 ? (
        <h2>
          <Translate>No product revenue for this date range</Translate>
        </h2>
      ) : (
        showRevenue.showProductRevenue && (
          <>
            <h1>
              <Translate>Products Revenue</Translate>
              <Icon
                disabled={isSubscriptionExpired}
                name="download"
                size="small"
                onClick={() => {
                  const data = productRevenueDataFormat(productDiscountedRevenue, true);
                  const fileName = "product_revenue";
                  const exportType = "csv";
                  exportFromJSON({ data, fileName, exportType });
                }}
              />
            </h1>
            <div className="service-container">
              <table className="ui celled table">
                <thead>
                  <tr>
                    <th>
                      <Translate>Appointment Id</Translate>
                    </th>
                    <th>
                      <Translate>Date</Translate>
                    </th>
                    <th>
                      <Translate>Revenue</Translate>
                    </th>
                    <th>
                      <Translate>Revenue post direct discount</Translate>
                    </th>
                    <th>
                      <Translate>Revenue post direct membership discount</Translate>
                    </th>
                    <th>
                      <Translate>Revenue post direct campaign discount</Translate>
                    </th>
                    <th>
                      <Translate>Final Revenue</Translate>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productDiscountedRevenue.map((revenue, index) => {
                    let staff_direct_discount = "";
                    let staff_membership_discount = "";
                    let staff_campaign_discount = "";

                    let revenue_post_direct_discount = "N/A";
                    let revenue_post_membership_discount = "N/A";
                    let revenue_post_campaign = "N/A";

                    if (parseFloat(revenue.discount)) {
                      staff_direct_discount = `( ${revenue.discount} )`;
                      revenue_post_direct_discount = <span>&#x20B9; {revenue.revenue_post_direct_discount}/-</span>;
                    }
                    if (parseFloat(revenue.staff_membership_discount)) {
                      staff_membership_discount = `( ${revenue.staff_membership_discount} )`;
                      revenue_post_membership_discount = (
                        <span>&#x20B9; {revenue.revenue_post_membership_discount}/-</span>
                      );
                    }
                    if (parseFloat(revenue.staff_campaign_discount)) {
                      staff_campaign_discount = `( ${revenue.staff_campaign_discount} )`;
                      revenue_post_campaign = <span>&#x20B9; {revenue.revenue_post_campaign}/-</span>;
                    }

                    return (
                      <tr key={index}>
                        <td>{revenue.appointment_id}</td>
                        <td>{revenue.date}</td>
                        <td>&#x20B9; {revenue.cost}/-</td>
                        <td>
                          {revenue_post_direct_discount} {staff_direct_discount}
                        </td>
                        <td>
                          {revenue_post_membership_discount} {staff_membership_discount}
                        </td>
                        <td>
                          {revenue_post_campaign} {staff_campaign_discount}
                        </td>
                        <td>{revenue.final_revenue}/-</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td />
                    <td>
                      <Translate>Total Revenue</Translate>
                    </td>
                    <td>&#x20B9; {totalRevenue.productRevenue}</td>
                    <td />
                    <td />
                    <td />
                    <td>&#x20B9; {finalRevenue.productRevenue}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "20px" }}>
              <p className="info">
                <Icon name="info circle" />
                Discount on total bill either through campaign, membership or direct discount is not considered
              </p>
            </div>
          </>
        )
      )}
      {showRevenue.showMembershipRevenue && membershipRevenueDetails.length === 0 ? (
        <h2>
          <Translate>No membership revenue for this date range</Translate>
        </h2>
      ) : (
        showRevenue.showMembershipRevenue && (
          <>
            <h1>
              <Translate>Membership Revenue</Translate>
              <Icon
                disabled={isSubscriptionExpired}
                name="download"
                size="small"
                onClick={() => {
                  const data = membershipRevenueDataFormat(membershipRevenueDetails, true);
                  const fileName = "membership_revenue";
                  const exportType = "csv";
                  exportFromJSON({ data, fileName, exportType });
                }}
              />
            </h1>
            <div className="service-container">
              <table className="ui celled table">
                <thead>
                  <tr>
                    <th>
                      <Translate>Customer Name</Translate>
                    </th>
                    <th>
                      <Translate>Membership Name</Translate>
                    </th>
                    <th>
                      <Translate>Date</Translate>
                    </th>
                    <th>
                      <Translate>Price</Translate>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {membershipRevenueDetails.map((revenue, index) => {
                    return (
                      <tr key={index}>
                        <td>{revenue.customer_name}</td>
                        <td>{revenue.name}</td>
                        <td>{revenue.date}</td>
                        <td>&#8377; {revenue.cost}/-</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td />
                    <td />
                    <td>
                      <Translate>Total Revenue</Translate>
                    </td>
                    <td>&#x20B9; {totalRevenue.membershipRevenue}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      )}
    </div>
  );
}

export default StaffRevenue;
