import { API_BASE_URL, STYLIST_OTP_API, CUSTOMER_OTP_API } from "utilities";
import axios from "axios";
import { smsSendingStatus } from "../../utilities/CommonFunctions";

export function otpHasErrored() {
  return {
    type: "OTP_HAS_ERRORED",
    status: false,
  };
}

export function otpSuccess() {
  return {
    type: "OTP_SUCCESS",
    status: true,
  };
}

export function sendOtp({ data, receiver = "stylist" }) {
  let url = API_BASE_URL;
  url += receiver === "stylist" ? STYLIST_OTP_API : CUSTOMER_OTP_API;

  const headers = {};
  if (receiver === "customer") {
    headers.Authorization = localStorage.getItem("token");
  }

  return (dispatch) => {
    axios({
      method: "post",
      url,
      data,
      headers,
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          smsSendingStatus(response?.data?.subscription_details?.sms_status);
          dispatch(otpSuccess());
        } else {
          dispatch(otpHasErrored());
        }
      })
      .catch(function (error) {
        dispatch(otpHasErrored());
      });
  };
}
