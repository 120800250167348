import { API_BASE_URL, CUSTOMER_WALLET_API, CUSTOMER_WALLET_BALANCE_API } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { customerWalletActions, errorMessage } from "../../utilities/actionMessages";

export function customerWalletFetchDataSuccess(customerWallet) {
  return {
    type: "CUSTOMER_WALLET_FETCH_DATA_SUCCESS",
    customerWallet,
  };
}

export function customerWalletBalanceFetch({ customer_id, appointment_id, branch_id }) {
  return (dispatch) => {
    let URL = API_BASE_URL + CUSTOMER_WALLET_BALANCE_API + `?customer_id=${customer_id}`;
    URL = appointment_id ? URL + "&appointment_id=" + appointment_id : URL;
    URL = branch_id ? URL + "&branch_id=" + branch_id : URL;

    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "CUSTOMER_WALLET_PENDING_AMOUNT",
            data: response.data?.balance || 0,
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function customerWalletFetchData(customerId, pageNum = 1) {
  return (dispatch) => {
    dispatch(startLoading("CUSTOMER_WALLET_LOADER", "loading customerWallet"));
    let URL = API_BASE_URL + CUSTOMER_WALLET_API;
    URL = customerId ? URL + "?search=" + customerId + "&page=" + pageNum : URL + "/&page=" + pageNum;
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(customerWalletFetchDataSuccess(response.data));
          dispatch(endLoading("CUSTOMER_WALLET_LOADER"));
        } else {
          dispatch(errored("CUSTOMER_WALLET_LOADER", true));
          dispatch(endLoading("CUSTOMER_WALLET_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("CUSTOMER_WALLET_LOADER", true));
        dispatch(endLoading("CUSTOMER_WALLET_LOADER"));
      });
  };
}


export function getcustomerWalletInvoice(id) {
  return (dispatch) => {
    dispatch(startLoading("CUSTOMER_WALLET_LOADER", "loading customerWallet"));
    axios({
      method: "get",
      url: API_BASE_URL + `wallet-invoice/${id}/`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(endLoading("CUSTOMER_WALLET_LOADER"));
        dispatch({ type: "CUSTOMER_WALLET_INVOICE_SUCCESS", data: response.data });
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch({ type: "CUSTOMER_WALLET_INVOICE_FAIL" });
        dispatch(endLoading("CUSTOMER_WALLET_LOADER"));
      });
  };
}
