const branchInitialState = {
  isFetching: false,
  data: [],
};

export function branch(state = branchInitialState, action) {
  switch (action.type) {
    case "BRANCH_FETCH_DATA_FETCHING":
      return {
        ...state,
        isFetching: true,
      };
    case "BRANCH_FETCH_DATA_SUCCESS":
      return {
        isFetching: false,
        data: action.branch,
      };
    default:
      return state;
  }
}

const initialState = {
  results: [],
};

export function getBranchLoyaltyPoint(state = initialState, action) {
  switch (action.type) {
    case "BRANCH_LOYALTY_POINT_FETCH_DATA_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
