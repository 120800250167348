import { API_BASE_URL, BRANCH_SHORT_NAME } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { barnchShortNameActions, errorMessage } from "../../utilities/actionMessages";

export function branchShortNameFetchData() {
  return (dispatch) => {
    axios
      .get(API_BASE_URL + BRANCH_SHORT_NAME, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "BRANCH_SHORT_NAME_FETCH_SUCCESS",
            data: response.data.results.length > 0 ? response.data.results[0] : {},
          });
        } else {
          dispatch({
            type: "BRANCH_SHORT_NAME_FETCH_ERROR",
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function branchShortNamePostData(short_name) {
  return (dispatch) => {
    axios({
      method: "post",
      url: API_BASE_URL + BRANCH_SHORT_NAME,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: { short_name },
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: barnchShortNameActions.success_update, type: "success" });
          dispatch(branchShortNameFetchData());
        } else {
          dispatch({
            type: "BRANCH_SHORT_NAME_FETCH_ERROR",
          });
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}
