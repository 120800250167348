import axios from "axios";
import { API_BASE_URL, STAFF_API, STAFF_BASIC_API, COMMISSION_API } from "utilities";
import { startLoading, endLoading, errored } from "./withLoader";
import { authenticationLogout } from "./authentication";
import _ from "lodash";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { staffActions, errorMessage, commissionActions } from "../../utilities/actionMessages";
import { getUserPermissions } from "./aclUserPermissions";

export function staffFetchDataSuccess(staff) {
  return {
    type: "STAFF_FETCH_DATA_SUCCESS",
    staff,
  };
}

export function staffBasicSuccess(staff) {
  const staffMap = createStaffMap(staff);

  return {
    type: "STAFF_BASIC_FETCH_DATA_SUCCESS",
    staff,
    staffMap,
  };
}

export function staffFetchDataLoading() {
  return {
    type: "STAFF_DATA_FETCHING",
  };
}

function createStaffMap(staff) {
  // for better searching the staff data by staff id
  const staffMap = _.keyBy(staff, function (_staff) {
    return _staff.user.id;
  });

  return staffMap;
}

export function staffBasicFetchData() {
  let URL = API_BASE_URL + STAFF_BASIC_API;

  return (dispatch) => {
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffBasicSuccess(response.data.results));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
      });
  };
}

export function staffFetchData(search, pageSize = 15, selectedPage = 1, status = 1) {
  let URL = API_BASE_URL + STAFF_API + "?";
  if (search) {
    URL += `search=${search}`;
  }
  URL += `&page_size=${pageSize}&page=${selectedPage}&status=${status}`;
  return (dispatch) => {
    dispatch(staffFetchDataLoading());
    dispatch(startLoading("STAFF_LOADER", "loading staff"));
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(staffFetchDataSuccess(response.data));
          dispatch(endLoading("STAFF_LOADER"));
        } else {
          dispatch(errored("STAFF_LOADER", true));
          dispatch(endLoading("STAFF_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_LOADER", true));
        dispatch(endLoading("STAFF_LOADER"));
      });
  };
}

export function staffPostData(staff) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_LOADER", "updating staff"));
    if ("files_list" in staff) {
      dispatch(getPresignedLinks("post", API_BASE_URL + STAFF_API, staff));
    } else {
      dispatch(updateStaff("post", API_BASE_URL + STAFF_API, staff));
    }
  };
}

export function staffPutData(id, staff, shouldFetchUserPermissions = false) {
  return (dispatch) => {
    dispatch(startLoading("STAFF_LOADER", "updating staff"));
    if ("files_list" in staff) {
      dispatch(getPresignedLinks("put", API_BASE_URL + STAFF_API + id + "/", staff, shouldFetchUserPermissions));
    } else {
      dispatch(updateStaff("put", API_BASE_URL + STAFF_API + id + "/", staff, shouldFetchUserPermissions));
    }
  };
}

export function updateStaffStatus() {
  return {
    type: "UPDATE_STAFF",
  };
}

export function updateStaffStatusReset() {
  return {
    type: "UPDATE_STAFF_RESET",
  };
}

export function updateStaff(method, staffUrl, staff, shouldFetchUserPermissions = false) {
  return (dispatch) => {
    axios({
      method: method,
      url: staffUrl,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: staff,
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status) {
            if (method === "post") {
              ToastAlert({ message: staffActions.success_create, type: "success" });
            } else {
              ToastAlert({ message: staffActions.success_update, type: "success" });
            }
          } else {
            ToastAlert({ message: staffActions.success_delete, type: "success" });
          }
          dispatch(updateStaffStatus());
          if (shouldFetchUserPermissions) {
            dispatch(getUserPermissions());
          }
          dispatch(endLoading("STAFF_LOADER"));
        } else {
          dispatch(errored("STAFF_LOADER", true));
          dispatch(endLoading("STAFF_LOADER"));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("STAFF_LOADER", true));
        dispatch(endLoading("STAFF_LOADER"));
      });
  };
}

export function getPresignedLinks(method, staffUrl, staff, shouldFetchUserPermissions = false) {
  return (dispatch) => {
    let get_presigned_url_body = _.filter(staff.files_list, ({ id = false }) => {
      return !id;
    });
    get_presigned_url_body = get_presigned_url_body.map(({ name, type, key, size }) => {
      return { name, type, key, size };
    });
    if (get_presigned_url_body.length) {
      axios({
        method: "post",
        url: API_BASE_URL + "upload/presigned/",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: get_presigned_url_body,
      })
        .then(function (response) {
          if (response.status === 200) {
            dispatch(uploadFiles(method, staffUrl, staff, response.data, shouldFetchUserPermissions));
          } else {
            dispatch(errored("STAFF_LOADER", true));
            dispatch(endLoading("STAFF_LOADER"));
          }
        })
        .catch(function (error) {
          dispatch(errored("STAFF_LOADER", true));
          dispatch(endLoading("STAFF_LOADER"));
        });
    } else {
      dispatch(uploadFiles(method, staffUrl, staff, null, shouldFetchUserPermissions));
    }
  };
}

export function uploadFiles(method, staffUrl, staff, presignedLinks, shouldFetchUserPermissions = false) {
  return (dispatch) => {
    let presigned_list_index = 0;
    let promiseArray = _.map(staff.files_list, ({ id = false, name, key, size, type }, index) => {
      if (!id) {
        const data = new FormData();
        for (let header in presignedLinks[presigned_list_index].url.fields) {
          data.append(header, presignedLinks[presigned_list_index].url.fields[header]);
        }
        data.append("file", staff.files_list[index]);
        staff.files_list[index] = {
          name,
          key,
          size,
        };
        return axios.post(presignedLinks[presigned_list_index++].url.url, data);
      }
    });
    Promise.all(promiseArray)
      .then((results) => {
        dispatch(updateStaff(method, staffUrl, staff, shouldFetchUserPermissions));
        dispatch(endLoading("STAFF_LOADER"));
      })
      .catch((error) => {
        ToastAlert({ message: errorMessage, type: "error" });
        dispatch(errored("STAFF_LOADER", true));
        dispatch(endLoading("STAFF_LOADER"));
      });
  };
}

function commissionsFetchSuccess(brackets) {
  return {
    type: "COMMISSION_FETCH_SUCCESS",
    brackets,
  };
}

function commissionsFetchFailed() {
  return {
    type: "COMMISSION_FETCH_FAILED",
  };
}

function commissionsReset() {
  return {
    type: "COMMISSION_FETCH_RESET",
  };
}

export function commissionFetchData() {
  return (dispatch) => {
    const url = `${API_BASE_URL}${COMMISSION_API}`;
    axios({
      method: "get",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(commissionsFetchSuccess(response.data));
        dispatch(commissionsReset());
      })
      .catch(function () {
        dispatch(commissionsFetchFailed());
      });
  };
}

export function commissionPostData(bracket) {
  return (dispatch) => {
    const url = `${API_BASE_URL}${COMMISSION_API}`;
    axios({
      method: "post",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: bracket,
    })
      .then(function (response) {
        ToastAlert({ message: commissionActions.success_create, type: "success" });
        dispatch({
          type: "COMMISSION_OP_SUCCESS",
        });
        dispatch(commissionFetchData());
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        dispatch({
          type: "COMMISSION_OP_FAILED",
        });
      });
  };
}

export function commissionPatchData(id, payload) {
  return (dispatch) => {
    const url = `${API_BASE_URL}${COMMISSION_API}${id}/`;
    axios({
      method: "patch",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(function () {
        //since only deletion is happening no updation
        ToastAlert({ message: commissionActions.success_delete, type: "success" });
        dispatch({
          type: "COMMISSION_OP_SUCCESS",
        });
        dispatch(commissionFetchData());
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        dispatch({
          type: "COMMISSION_OP_FAILED",
        });
      });
  };
}

export function staffCommission(id, payload = null) {
  return (dispatch) => {
    const url = `${API_BASE_URL}staff/${id}/commission/`;
    const method = payload ? `post` : `get`;
    axios({
      method,
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload ? payload : null,
    })
      .then(function (response) {
        if (method === "post") {
          if ("active" in response.data && !response.data.active) {
            ToastAlert({ message: commissionActions.success_delete, type: "success" });
          } else {
            ToastAlert({ message: commissionActions.success_create, type: "success" });
          }
        }
        if (payload) {
          dispatch(staffCommission(id));
        } else {
          dispatch({
            type: "STAFF_COMMISSION_FETCH",
            data: response.data.results,
          });
        }
      })
      .catch(function (err) {
        ToastAlert({ message: errorMessage, type: "error" });
        console.error({ err });
      });
  };
}

export function updateAppointmentServiceState(requiredID, status, appointmentId) {
  return (dispatch) => {
    const url = `${API_BASE_URL}required/${requiredID}/`;
    axios({
      method: "PUT",
      url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        status,
      },
    })
      .then(function (response) {
        // console.log({ response });
      })
      .catch(function (err) {
        console.error({ err });
      });
  };
}
