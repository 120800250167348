import React, { useEffect } from "react";
import "./index.scss";

function PageLoad() {
  return (
    <div className="pageload-container">
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  );
}

export default PageLoad;
