import React from "react";
import { Translate } from "react-auto-translate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form } from "../../new-components";
import "./index.scss";

export default function FilterSidenav({ resources, staffListUpdate, staffListCheckChange, unselectedStaff }) {
  return (
    <Form>
      <Form.Title>
        <Translate>Re-Organize staff</Translate>
      </Form.Title>
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination, draggableId } = result;
          if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
          }
          staffListUpdate(source.index, destination.index);
        }}
      >
        <div className="filter-sidenav-Container">
          <Droppable droppableId={"0"}>
            {(provided) => (
              <div className="filter-sidenav-TaskList" ref={provided.innerRef} {...provided.droppableProps}>
                {resources.map((staff, index) => {
                  return (
                    <Draggable draggableId={staff.id.toString()} index={index} key={staff.id}>
                      {(provider) => (
                        <div
                          className="filter-sidenav-TaskContainer"
                          ref={provider.innerRef}
                          {...provider.draggableProps}
                          {...provider.dragHandleProps}
                        >
                          <input
                            type="checkbox"
                            checked={!unselectedStaff.includes(staff.id)}
                            onChange={() => {
                              staffListCheckChange(staff.id);
                            }}
                            className="filter-sidenav-TaskCheckBox"
                          />
                          <p
                            className={
                              !unselectedStaff.includes(staff.id)
                                ? "filter-sidenav-TaskText-Checked"
                                : "filter-sidenav-TaskText-UnChecked"
                            }
                          >
                            {staff.title}
                          </p>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </Form>
  );
}
