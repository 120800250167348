const initialState = {
  data: [],
  loading: false,
  errored: false,
  message: "",
};

export function invoiceRemarks(state = initialState, action) {
  switch (action.type) {
    case "INVOICE_REMARKS_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "GET_INVOICE_REMARKS_SUCCESS": {
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    }
    case "POST_INVOICE_REMARKS_SUCCESS": {
      return {
        ...state,
        loading: false,
      };
    }
    case "INVOICE_REMARKS_OPS_FAILURE": {
      return {
        ...state,
        loading: false,
        errored: true,
        message: action.data,
      };
    }

    case "INVOICE_REMARKS_RESET": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
