import { API_BASE_URL, APPOINTMENT_API, APPOINTMENT_BASIC_API, APPOINTMENT_INVOICES_API } from "utilities";
import axios from "axios";
import { createSelector } from "reselect";
import _ from "lodash";
import { startLoading, endLoading, errored } from "./withLoader";
import { authenticationLogout } from "./authentication";
import { appointmentAnalyticsFetchData } from "./appointmentAnalytics";
import { serviceAnalyticsFetchData } from "./serviceAnalytics";
import { IsJsonString } from "../../utilities/CommonFunctions";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import { appointmentActions, errorMessage } from "../../utilities/actionMessages";
import { smsSendingStatus } from "../../utilities/CommonFunctions";
import { PRODUCTS_DATA } from "./products";

export async function fetchAppointmentById(id) {
  const response = {
    error: false,
    data: null,
  };

  let URL = API_BASE_URL + APPOINTMENT_API + "?id=" + id;
  return axios
    .get(URL, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then(function (res) {
      return {
        ...response,
        data: res.data.results[0],
      };
    })
    .catch(function (error) {
      return {
        ...response,
        error: error,
      };
    });
}

export function appointmentsFetchDataLoading() {
  return {
    type: "APPOINTMENTS_FETCH_DATA_LOADING",
  };
}
export function appointmentsFetchBasicDataLoading() {
  return {
    type: "APPOINTMENTS_BASIC_FETCH_DATA_LOADING",
  };
}
export function appointmentsFetchDataSuccess(appointments) {
  return {
    type: "APPOINTMENTS_FETCH_DATA_SUCCESS",
    appointments,
  };
}

export function appointmentsBasicFetchDataSuccess(appointments) {
  return {
    type: "APPOINTMENTS_BASIC_FETCH_DATA_SUCCESS",
    appointments: appointments,
  };
}

export function appointmentsForInvoiceFetchDataSuccess(appointments) {
  return {
    type: "APPOINTMENTS_INVOICE_FETCH_DATA_SUCCESS",
    appointments,
  };
}

export function appointmentShowInvoice(appointment) {
  return {
    type: "APPOINTMENTS_SHOW_INVOICE",
    appointmentInvoice: appointment,
  };
}

export function appointmentHideInvoice() {
  return {
    type: "APPOINTMENTS_HIDE_INVOICE",
    appointmentInvoice: null,
  };
}

export function resetAppointmentError() {
  return {
    type: "APPOINTMENTS_ERROR_RESET",
    error: "",
  };
}

export function fetchAppointmentsBasicDetails({
  start_date = false,
  end_date = false,
  customer_id,
  page_size,
  page = 1,
}) {
  let URL = API_BASE_URL + APPOINTMENT_BASIC_API + "?";
  if (start_date) {
    URL += `&start_datetime=${start_date}`;
  }
  if (end_date) {
    URL += `&end_datetime=${end_date}`;
  }
  if (customer_id) {
    URL += `customer_id=${customer_id}&page_size=${page_size}&page=${page}`;
  }

  return (dispatch) => {
    dispatch(appointmentsFetchBasicDataLoading());
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(appointmentsBasicFetchDataSuccess(response.data));
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("APPOINTMENT_LOADER", true));
        dispatch(endLoading("APPOINTMENT_LOADER"));
      });
  };
}

export function appointmentsFetchData(
  search = false,
  start_date = false,
  end_date = false,
  status = false,
  customer_id = false,
  page_size = 0,
  page = 1
) {
  let URL = API_BASE_URL + APPOINTMENT_API + "?";
  if (search) {
    URL += `search=${search}&`;
  }
  if (start_date) {
    URL += `&start_datetime=${start_date}`;
  }
  if (end_date) {
    URL += `&end_datetime=${end_date}`;
  }
  if (status) {
    URL += `&status=${status}`;
  }
  if (customer_id) {
    URL += `customer_id=${customer_id}&page_size=${page_size}&page=${page}`;
  }
  return (dispatch) => {
    dispatch(appointmentsFetchDataLoading());
    dispatch(startLoading("APPOINTMENT_LOADER", "loading appointment"));
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(appointmentsFetchDataSuccess(response.data));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("APPOINTMENT_LOADER", true));
        dispatch(endLoading("APPOINTMENT_LOADER"));
      });
  };
}

export function appointmentsPostData(appointment, start_date = false, end_date = false, isPreview = false) {
  let URL = API_BASE_URL + APPOINTMENT_API;
  return (dispatch) => {
    dispatch(startLoading("APPOINTMENT_LOADER", "updating appointment"));
    return axios({
      method: "post",
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: appointment,
    })
      .then(function (response) {
        if (response.status === 201) {
          ToastAlert({ message: appointmentActions.success_create, type: "success" });
          smsSendingStatus(response?.data?.subscription_details?.sms_status);
          dispatch(fetchAppointmentsBasicDetails({ start_date, end_date }));
          dispatch(appointmentAnalyticsFetchData());
          dispatch(serviceAnalyticsFetchData());
          if (isPreview || response.data.status === 3) {
            dispatch(appointmentShowInvoice(response.data));
          }
          dispatch(endLoading("APPOINTMENT_LOADER"));
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
        return {
          status: "success",
          message: "",
          info: response.data,
        };
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail)
                ? JSON.parse(error.response.data.detail)
                : error.response.data.detail;
            }
            if (error.response.data?.number) {
              message = error.response.data.number;
            }
          }
          if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "APPOINTMENTS_FETCH_DATA_FAIL",
          error: error.message,
        });
        dispatch(endLoading("APPOINTMENT_LOADER"));
        return {
          status: "error",
          message,
        };
      });
  };
}

export function appointmentUpdate(id, appointment, start_date = false, end_date = false, method = "PUT", toPreview) {
  let URL = API_BASE_URL + APPOINTMENT_API + id + "/";
  return (dispatch) => {
    dispatch(startLoading("APPOINTMENT_LOADER", "updating appointment"));
    return axios({
      method,
      url: URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: appointment,
    })
      .then(function (response) {
        if (response.data) {
          ToastAlert({ message: appointmentActions.success_update, type: "success" });
        } else {
          //no data means it has been deleted
          ToastAlert({ message: appointmentActions.success_delete, type: "success" });
        }
        dispatch(fetchAppointmentsBasicDetails({ start_date, end_date }));
        if (response.status === 200 || 204) {
          smsSendingStatus(response?.data?.subscription_details?.sms_status);
          dispatch(appointmentAnalyticsFetchData());
          dispatch(serviceAnalyticsFetchData());
          if (toPreview) {
            dispatch(appointmentShowInvoice(appointment));
          } else if (response.data.status === 3) {
            dispatch(appointmentShowInvoice(response.data));
          }
          dispatch(endLoading("APPOINTMENT_LOADER"));
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
        return {
          status: "success",
          message: null,
          response,
        };
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          const { status } = error.response;
          if (status === 500 || status === 400 || status === 404) {
            const error_message = error.response.data?.detail || error.response.data[0];
            message = IsJsonString(error_message) ? JSON.parse(error_message) : error_message;
          } else if (error.response.status === 401) {
            dispatch(authenticationLogout());
          }
        }
        ToastAlert({ message: message || errorMessage, type: "error" });
        dispatch({
          type: "APPOINTMENTS_FETCH_DATA_FAIL",
          error: error.message,
        });
        dispatch(endLoading("APPOINTMENT_LOADER"));
        return {
          status: "error",
          message,
        };
      });
  };
}

export function appointmentsInvoices(
  search = false,
  start_date = false,
  end_date = false,
  status = false,
  filter = false,
  page_size,
  page = 1
) {
  let URL = API_BASE_URL + APPOINTMENT_INVOICES_API + "?";
  if (search) {
    URL += `search=${search}`;
  }
  if (start_date) {
    URL += `&start_datetime=${start_date}`;
  }
  if (end_date) {
    URL += `&end_datetime=${end_date}`;
  }
  if (status) {
    URL += `&status=${status}`;
  }
  if (filter) {
    URL += `&filter=${filter}`;
  }
  URL += `&page_size=${page_size}&page=${page}`;

  return (dispatch) => {
    dispatch(startLoading("APPOINTMENT_LOADER", "loading appointment"));
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(appointmentsForInvoiceFetchDataSuccess(response.data));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        } else {
          dispatch(errored("APPOINTMENT_LOADER", true));
          dispatch(endLoading("APPOINTMENT_LOADER"));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(errored("APPOINTMENT_LOADER", true));
        dispatch(endLoading("APPOINTMENT_LOADER"));
      });
  };
}

const invoicesSelector = (state) => state.appointments?.invoices || [];
const servicesSelector = (state) => state.services.data || [];
const productsSelector = (state) => PRODUCTS_DATA(state) || [];

export const invoicesAction = createSelector(
  [invoicesSelector, servicesSelector, productsSelector],
  (invoices, services, products) => {
    const newServices = _.keyBy(services, "id");
    const newProducts = _.keyBy(products, "id");
    let newInvoices = {
      ...invoices,
      results: invoices?.results?.map((ele) => ({
        ...ele,
        required_products: ele.required_products.map((ele) => ({ ...newProducts[ele.product_id], ...ele })),
        required_services: ele.required_services.map((ele) => ({ ...newServices[ele.service_id], ...ele })),
      })),
    };
    return newInvoices;
  }
);
