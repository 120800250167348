const basicInitialState = {
  data: [],
};

export function campaignBasic(state = basicInitialState, action) {
  switch (action.type) {
    case "CAMPAIGN_BASIC_FETCH_DATA_SUCCESS":
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
export function campaign(state = [], action) {
  switch (action.type) {
    case "CAMPAIGN_FETCH_DATA_SUCCESS":
      return action.campaign;
    default:
      return state;
  }
}
