import React, { useEffect, useState } from "react";
import "./index.scss";

import { Button, TextArea } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceRemarks, postInvoiceRemarks } from "../../store/actions/invoiceremarks";

function InvoiceRemarks({ appointmentId }) {
  const dispatch = useDispatch();
  const remarks = useSelector((state) => state.invoiceRemarks);
  const [note, setNote] = useState("");

  useEffect(() => {
    dispatch(getInvoiceRemarks(appointmentId));
    setNote("");
  }, [dispatch, appointmentId]);

  return (
    <div className="customer-notes">
      <div className="add-note">
        <TextArea
          placeholder="start typing here(maximum 200 characters)..."
          style={{ minHeight: 50, width: "100%" }}
          maxLength="200"
          className="note-area"
          value={note}
          onChange={(e) => {
            setNote(e.currentTarget.value);
          }}
        />
        <Button
          size="mini"
          className="add-note-btn"
          onClick={() => {
            if (note) {
              dispatch(
                postInvoiceRemarks({
                  appointment_id: appointmentId,
                  remark: note,
                })
              );
              setNote("");
            }
          }}
        >
          Add Remarks
        </Button>
      </div>
      <div className="notes-wrapper">
        {remarks.data.length ? (
          remarks.data.map(({ remark }, key) => (
            <div key={key} className="notes">
              {remark}
            </div>
          ))
        ) : (
          <span>No remarks found. Add one from the form above.</span>
        )}
      </div>
    </div>
  );
}

export default InvoiceRemarks;
