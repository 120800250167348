import { LoadingState } from "./types";

const init = {
  loading: false,
  success: false,
  error: false,
};

export function addAppointmentDirectDiscount(state = init, action) {
  switch (action.type) {
    case "APPOINTMENT_DIRECT_DISCOUNT_LOADING":
      return { ...init, loading: true };
    case "POST_APPOINTMENT_DIRECT_DISCOUNT_SUCCESS":
      return { ...init, success: true };
    case "POST_APPOINTMENT_DIRECT_DISCOUNT_FAIL":
      return { ...init, error: true };
    case "RESET_APPOINTMENT_DIRECT_DISCOUNT":
      return init;
    default:
      return state;
  }
}

const getInit = {
  data: [],
  loading: LoadingState.unset,
};

export function getAppointmentDirectDiscount(state = getInit, action) {
  switch (action.type) {
    case "GET_APPOINTMENT_DIRECT_DISCOUNT_LOADING":
      return { ...getInit, ...action.response, loading: LoadingState.loading };

    case "GET_APPOINTMENT_DIRECT_DISCOUNT_SUCCESS":
      return { ...getInit, ...action.response, loading: LoadingState.loaded };

    case "GET_APPOINTMENT_DIRECT_DISCOUNT_FAIL":
      return { ...getInit, ...action.response, loading: LoadingState.failed };

    case "RESET_GET_APPOINTMENT_DIRECT_DISCOUNT": {
      return { ...getInit, ...action.response };
    }
    case "GET_APPOINTMENT_DIRECT_DISCOUNT_UNSET_LOADING":
      return { ...state, loading: LoadingState.unset };

    default:
      return state;
  }
}
