import axios from "axios";
import { API_BASE_URL, PENDING_PAYMENTS_API } from "../../utilities";
import { authenticationLogout } from "./authentication";

function pendingHistorySuccess(data, id) {
  return {
    type: "PENDING_HISTORY_SUCCESS",
    data,
    id,
  };
}

function pendingHistoryFailed() {
  return {
    type: "PENDING_HISTORY_FAILED",
  };
}

export function getPendingHistory(customer_id, pageNum = 1) {
  return (dispatch) => {
    const url = `${API_BASE_URL}${PENDING_PAYMENTS_API}?customer_id=${customer_id}&page=${pageNum}`;
    axios({
      method: "GET",
      url: url,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(pendingHistorySuccess(response.data, customer_id));
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(pendingHistoryFailed());
      });
  };
}
