import React, { useState } from "react";
import "./index.scss";

function Index({ orderDetail }) {
  const { products } = orderDetail;
  const [imageNum, setImageNum] = useState(0);

  function handleRightArrow(images) {
    setImageNum((imageNum + 1) % images.length);
  }

  function handleLeftArrow(images) {
    if (imageNum === 0) {
      setImageNum(images.length - 1);
    } else {
      setImageNum(imageNum - 1);
    }
  }
  return (
    <div className="orderDetail-container">
      <h1>Order Detail</h1>
      {products.map((data, index) => {
        const { quantity, unit_cost, variant } = data;
        const { size, unit, product } = variant;
        const { images, name } = product;
        return (
          <div key={index} className="order-container">
            <div className="img-container">
              <i
                className={`${images && images.length <= 1 && "none"} angle left icon`}
                onClick={() => handleLeftArrow(images)}
              ></i>
              <img
                className="product-img"
                src={images && images.length ? images[imageNum].url : "/no-image.png"}
                alt={name}
              />
              <i
                className={`${images && images.length <= 1 && "none"} angle right icon`}
                onClick={() => handleRightArrow(images)}
              ></i>
            </div>
            <div>
              <h4>{name}</h4>
            </div>
            <div>Ordered Quantiy: {quantity}</div>
            {size && (
              <div>
                Item Size: {size} {unit}
              </div>
            )}
            <div>Unit Price: Rs. {unit_cost}</div>
            <div>
              Total Price: {quantity} x {unit_cost} = Rs. {quantity * unit_cost}
            </div>
            <hr></hr>
          </div>
        );
      })}
    </div>
  );
}

export default Index;
