import isEmail from "validator/lib/isEmail";
import * as yup from "yup";
import { formatTimeIntoMin, validatePercentage, validateEmail } from "./CommonFunctions";

// Define the custom email validation function
yup.addMethod(yup.string, "email", function (message = "Invalid email") {
  return this.test("email", message, function (value) {
    // Your email validation logic goes here
    return validateEmail(value);
  });
});

// Define the custom registration email validation function
yup.addMethod(yup.string, "registrationEmail", function (message = "Invalid email") {
  return this.test("email", message, function (value) {
    // Your email validation logic goes here
    // if email is given then only validate since it is optional
    if (!value || !value.length) {
      return true;
    }

    return validateEmail(value);
  });
});

function validateRegex(check, truth) {
  return !new RegExp(truth).test(check);
}

function validateInList(check, list) {
  return !list.includes(check);
}

function validateEmailInput(email) {
  return isEmail(email);
}

function validateMembershipForm(values) {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length <= 1) {
    errors.name = "Minimum 2 characters";
  } else if (values.name.length > 30) {
    errors.name = "Limit 30 characters";
  }

  if (!values.description) {
    errors.description = "Required";
  } else if (values.description.length <= 1) {
    errors.description = "Minimum 2 characters";
  } else if (values.description.length > 30) {
    errors.description = "Limit 30 characters";
  }
  if (!values.validity_in_days) {
    errors.validity_in_days = "Required";
  } else if (values.validity_in_days < 1) {
    errors.validity_in_days = "Must be greater than 0";
  } else if (!Number.isInteger(values.validity_in_days)) {
    errors.validity_in_days = "Must be integer";
  }

  if (!values.cost) {
    errors.cost = "Required";
  } else if (values.cost < 0) {
    errors.cost = "Must be greater than 0";
  }

  if (!values.wallet_credit_amount) {
    values.wallet_credit_amount = 0;
  } else if (values.wallet_credit_amount < 0) {
    errors.wallet_credit_amount = "Must be non-negative";
  }

  if (!values.min_bill_amount) {
    values.min_bill_amount = 0;
  } else if (values.min_bill_amount < 0) {
    errors.min_bill_amount = "Must be non-negative";
  }

  if (values.total_bill_discount) {
    if (!values.discount_type_value && values.discount_type_value !== 0) {
      errors.discount_type_value = "Required";
    } else if (validatePercentage(values.discount_type_value) && values.discount_type == 0) {
      errors.discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
    }
  } else if (
    values.all_services_fields_show ||
    values.all_products_fields_show ||
    values.specific_services_fields_show ||
    values.specific_products_fields_show
  ) {
    if (values.all_services_fields_show) {
      if (!values.all_services_discount_type_value && values.all_services_discount_type_value !== 0) {
        errors.all_services_discount_type_value = "Required";
      } else if (
        validatePercentage(values.all_services_discount_type_value) &&
        values.all_services_discount_type == 0
      ) {
        errors.all_services_discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
      }
      if (!values.all_services_discount_max_count && values.all_services_discount_max_count !== 0) {
        errors.all_services_discount_max_count = "Required";
      } else if (values.all_services_discount_max_count < 0) {
        errors.all_services_discount_max_count = "Must be non-negative";
      }
      if (!values.minimum_service_cost && values.minimum_service_cost !== 0) {
        errors.minimum_service_cost = "Required";
      } else if (values.minimum_service_cost < 0) {
        errors.minimum_service_cost = "Must be non-negative";
      }
    }
    if (values.all_products_fields_show) {
      if (!values.all_products_discount_type_value && values.all_products_discount_type_value !== 0) {
        errors.all_products_discount_type_value = "Required";
      } else if (
        validatePercentage(values.all_products_discount_type_value) &&
        values.all_products_discount_type == 0
      ) {
        errors.all_products_discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
      }
      if (!values.all_products_discount_max_count && values.all_products_discount_max_count !== 0) {
        errors.all_products_discount_max_count = "Required";
      } else if (values.all_products_discount_max_count < 0) {
        errors.all_products_discount_max_count = "Must be non-negative";
      }
      if (!values.minimum_product_cost && values.minimum_product_cost !== 0) {
        errors.minimum_product_cost = "Required";
      } else if (values.minimum_product_cost < 0) {
        errors.minimum_product_cost = "Must be non-negative";
      }
    }
    if (values.specific_services_fields_show) {
      if (values.services.length > 0) {
        errors.services = values.services.map((ele) => {
          let errorObj = {};
          if (!ele.service) {
            errorObj.service = "Required";
          }
          if (!ele.allotted_count && ele.allotted_count !== 0) {
            errorObj.allotted_count = "Required";
          } else if (ele.allotted_count < 0) {
            errorObj.allotted_count = "Must be non-negative";
          }

          if (!ele.duration_in_minutes && ele.duration_in_minutes !== 0) {
            errorObj.duration_in_minutes = "Required";
          } else if (ele.duration_in_minutes < 0) {
            errorObj.duration_in_minutes = "Must be non-negative";
          } else {
            const formattedTime = formatTimeIntoMin(ele.duration_in_minutes, ele.duration_type);
            if (formattedTime.error) {
              errorObj.duration_in_minutes = formattedTime.message;
            }
          }

          if (!ele.discount_type_value && ele.discount_type_value !== 0) {
            errorObj.discount_type_value = "Required";
          } else if (validatePercentage(ele.discount_type_value) && ele.discount_type == 0) {
            errorObj.discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
          }
          return errorObj;
        });
        const allDataFilledCheck = errors.services.filter((ele) => {
          if (ele) {
            if (Object.keys(ele).length === 0) {
              return false;
            } else {
              return true;
            }
          }
        });
        if (allDataFilledCheck.length === 0) {
          delete errors.services;
        }
      }
    }
    if (values.specific_products_fields_show) {
      if (values.products.length > 0) {
        errors.products = values.products.map((ele) => {
          let errorObj = {};
          if (!ele.product) {
            errorObj.product = "Required";
          }
          if (!ele.allotted_count && ele.allotted_count !== 0) {
            errorObj.allotted_count = "Required";
          } else if (ele.allotted_count > ele.product.quantity) {
            errorObj.allotted_count = `Only ${ele.product.quantity} available`;
          } else if (ele.allotted_count < 0) {
            errorObj.allotted_count = "Must be non-negative";
          }

          if (!ele.discount_type_value && ele.discount_type_value !== 0) {
            errorObj.discount_type_value = "Required";
          } else if (validatePercentage(ele.discount_type_value) && ele.discount_type == 0) {
            errorObj.discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
          }
          return errorObj;
        });
        const allDataFilledCheck = errors.products.filter((ele) => {
          if (ele) {
            if (Object.keys(ele).length === 0) {
              return false;
            } else {
              return true;
            }
          }
        });
        if (allDataFilledCheck.length === 0) {
          delete errors.products;
        }
      }
    }
  } else {
    errors.common_error = "Please Select service or product";
  }
  return errors;
}

function validateCampaignForm(values) {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length <= 1) {
    errors.name = "Minimum 2 characters";
  } else if (values.name.length > 30) {
    errors.name = "Limit 30 characters";
  }

  if (!values.description) {
    errors.description = "Required";
  } else if (values.description.length <= 1) {
    errors.description = "Minimum 2 characters";
  } else if (values.description.length > 30) {
    errors.description = "Limit 30 characters";
  }

  if (values.total_bill_discount) {
    if (!values.discount_type_value && values.discount_type_value !== 0) {
      errors.discount_type_value = "Required";
    } else if (validatePercentage(values.discount_type_value) && values.discount_type == "PERCENTAGE") {
      errors.discount_type_value = "Discount value must be higher than 0 and equal to or lower than 100";
    }
    if (!values.max_discount_amount && values.max_discount_amount !== 0) {
      errors.max_discount_amount = "Required";
    } else if (values.max_discount_amount < 0) {
      errors.max_discount_amount = "Must be non-negative";
    }
    if (!values.min_bill_amount && values.min_bill_amount !== 0) {
      errors.min_bill_amount = "Required";
    } else if (values.min_bill_amount < 0) {
      errors.min_bill_amount = "Must be non-negative";
    }
  } else if (
    values.all_service_checked ||
    values.all_product_checked ||
    values.specific_service_checked ||
    values.specific_product_checked
  ) {
    if (values.all_service_checked) {
      if (!values.all_services_discount_type_value && values.all_services_discount_type_value !== 0) {
        errors.all_services_discount_type_value = "Required";
      } else if (
        validatePercentage(values.all_services_discount_type_value) &&
        values.all_services_discount_type == 0
      ) {
        errors.all_services_discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
      }
    }
    if (values.services.length > 0 && values.specific_service_checked) {
      errors.services = values.services.map((ele) => {
        let errorObj = {};
        if (!ele.service_id) {
          errorObj.service = "Required";
        }
        if (!ele.discount_type_value && ele.discount_type_value !== 0) {
          errorObj.discount_type_value = "Required";
        } else if (validatePercentage(ele.discount_type_value) && ele.discount_type == 0) {
          errorObj.discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
        }
        if (!ele.max_discount_amount && ele.max_discount_amount !== 0) {
          errorObj.max_discount_amount = "Required";
        } else if (ele.max_discount_amount < 0) {
          errorObj.max_discount_amount = "Must be non-negative";
        }
        return errorObj;
      });
      const allDataFilledCheck = errors.services.filter((ele) => {
        if (ele) {
          if (Object.keys(ele).length === 0) {
            return false;
          } else {
            return true;
          }
        }
      });
      if (allDataFilledCheck.length === 0) {
        delete errors.services;
      }
    }
    if (values.all_product_checked) {
      if (!values.all_products_discount_type_value && values.all_products_discount_type_value !== 0) {
        errors.all_products_discount_type_value = "Required";
      } else if (
        validatePercentage(values.all_products_discount_type_value) &&
        values.all_products_discount_type == 0
      ) {
        errors.all_products_discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
      }
      if (!values.all_product_checked && !values.all_service_checked) {
        errors.all_discount_checked = "Please Select service or product";
      }
    }
    if (values.products.length > 0 && values.specific_product_checked) {
      errors.products = values.products.map((ele) => {
        let errorObj = {};
        if (!ele.product_id) {
          errorObj.product = "Required";
        }
        if (!ele.discount_type_value && ele.discount_type_value !== 0) {
          errorObj.discount_type_value = "Required";
        } else if (validatePercentage(ele.discount_type_value) && ele.discount_type == 0) {
          errorObj.discount_type_value = "Discount must be higher than 0 and equal to or lower than 100";
        }
        if (!ele.max_discount_amount && ele.max_discount_amount !== 0) {
          errorObj.max_discount_amount = "Required";
        } else if (ele.max_discount_amount < 0) {
          errorObj.max_discount_amount = "Must be non-negative";
        }
        return errorObj;
      });
      const allDataFilledCheck = errors.products.filter((ele) => {
        if (ele) {
          if (Object.keys(ele).length === 0) {
            return false;
          } else {
            return true;
          }
        }
      });
      if (allDataFilledCheck.length === 0) {
        delete errors.products;
      }
    }
  } else {
    errors.all_discount_checked = "Please Select service or product";
  }
  return errors;
}

export const labelsSchema = yup.object().shape({
  name: yup.string().min(5, "Minimum 5 characters").required("Please add name"),
  description: yup
    .string()
    .min(5, "Minimum 5 characters")
    .max(30, "Max 30 characters")
    .required("Please add description"),
  short_name: yup
    .string()
    .min(1, "Minimum 1 character")
    .max(3, "Max 3 characters")
    .matches(/^[^,]*$/, "Comma(,) is not allowed") // returns true if the string does not contain a comma
    .required("Please add Short name"),
  properties: yup.object().shape({
    color: yup.string().required("Please select color"),
    shape: yup.string().required("Please select shape"),
  }),
});

export const registerationSchema = yup.object().shape({
  name: yup.string().min(5, "Minimum 5 characters").required("Please add name"),
  contact: yup
    .number()
    .min(999999999, "Minimum 10 characters")
    .max(9999999999, "Max 10 characters")
    .required("Please add mobile"),
  registration_email: yup.string().max(30, "Maximum 30 characters").registrationEmail("Please add valid email"),
  address: yup.string().min(5, "Minimum 5 characters").required("Please add address"),
  pin_code: yup.string().length(6, "Should be 6 characters").required("Please add pin code"),
  industry_type: yup.string().required("Please select/enter industry type"),
  country: yup.number().required("Please select country"),
  state: yup.number().required("Please select state"),
  city1: yup.number().required("Please select city"),
  referral: yup.string(),
});

export { validateRegex, validateInList, validateEmailInput, validateMembershipForm, validateCampaignForm };
