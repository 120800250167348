import { API_BASE_URL, USER_API } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";

import ToastAlert from "../../utilities/toastAlert/toastAlert";
import {userDetailsActions, errorMessage} from "../../utilities/actionMessages"

export function userHasErrored(bool) {
  return {
    type: "USER_HAS_ERRORED",
    hasErrored: bool,
  };
}

export function userIsLoading(bool) {
  return {
    type: "USER_IS_LOADING",
    isLoading: bool,
  };
}

export function userSuccess(user) {
  return {
    type: "USER_SUCCESS",
    user,
  };
}

export function getUser() {
  return (dispatch) => {
    dispatch(userIsLoading(true));
    axios({
      method: "get",
      url: API_BASE_URL + USER_API,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(userIsLoading(false));
          dispatch(userSuccess(response.data.results[0]));
        } else {
          dispatch(userIsLoading(false));
          dispatch(userHasErrored(true));
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(userIsLoading(false));
        dispatch(userHasErrored(true));
      });
  };
}

export function putUser(id, user) {
  return (dispatch) => {
    dispatch(userIsLoading(true));
    axios({
      method: "put",
      url: API_BASE_URL + USER_API + id + "/",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: user,
    })
      .then(function (response) {
        if (response.status === 200) {
          ToastAlert({ message: userDetailsActions.success_update, type: "success" });
          dispatch(userIsLoading(false));
          // dispatch(userSuccess(response.data.results[0]));
          dispatch(authenticationLogout());
        } else {
          dispatch(userIsLoading(false));
          dispatch(userHasErrored(true));
        }
      })
      .catch(function (error) {
        ToastAlert({ message: errorMessage, type: "error" });
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        }
        dispatch(userIsLoading(false));
        dispatch(userHasErrored(true));
      });
  };
}
