import React, { useEffect, useState } from "react";
import "../index.scss";

import { useDispatch, useSelector } from "react-redux";

import { AiOutlineFolderAdd, AiOutlineCloudDownload, AiOutlineCloudUpload } from "react-icons/ai";
import { Translate } from "react-auto-translate";
import Description from "Description";
import { Icon, Modal, Popup } from "semantic-ui-react";
import HoverTable from "HoverTable";
import moment from "moment";
import Fuse from "fuse.js";
import { Search } from "../../../new-components";

import DateRangeForm from "../../../components/DateRangeForm";
import Uploader from "Uploader";

import InventoryDetailsForm from "./InventoryDetailForm";
import {
  inventoryDetailsFetchData,
  inventoryDetailsPostData,
  inventoryDetailsEditData,
} from "../../../store/actions/inventoryDetails";

import { inventoryDetailsListSelector } from "../../../store/selectors/inventoryDetailsListSelector";

import { downloadReportFromServer } from "../../../store/actions/downloadReportsFromServer";
import { LoadingState } from "../../../store/reducers/types";
import { sellersFetchData } from "../../../store/actions/sellers";
import { companiesFetchData } from "../../../store/actions/companies";
import { useMemo } from "react";

const inventoryDetailsHeaders = [
  "Seller/Branch/Third Party",
  "Date Time",
  "IN/OUT",
  "Transaction",
  "Products",
  "Action",
];

const MessagePopup = ({ message }) => <Popup trigger={<span className="showmore">...</span>}>{message}</Popup>;

const inventoryDetailsRenderSpecial = () => {
  return [
    ({ sellerName }) => {
      const slicingPoint = 15;
      const slicedName = sellerName.length > slicingPoint ? `${sellerName.slice(0, slicingPoint)}...` : sellerName;

      return (
        <div className="flex">
          <Description title={slicedName} />
          {sellerName.length > slicingPoint && <MessagePopup message={sellerName} />}
        </div>
      );
    },
    ({ date_time }) => {
      const formatted_date_time = moment(date_time).utc().format("DD/MM/YY LT");
      return <Description title={formatted_date_time} />;
    },
    ({ in_out }) => {
      const text = in_out ? "IN" : "OUT";
      return <Description title={text} />;
    },

    ({ total_calculated_transaction_amount }) => {
      return <Description title={total_calculated_transaction_amount} />;
    },
    ({ product_names = "" }) => {
      const slicingPoint = 30;

      return (
        <div className="flex">
          <Description title={product_names.slice(0, slicingPoint)} />
          {product_names?.length > slicingPoint && <MessagePopup message={product_names} />}
        </div>
      );
    },
    (value, edit) => {
      return <Icon key={1} className="pointer edit" onClick={() => edit(value.id, value)} />;
    },
  ];
};

function InventoryDetails() {
  const dispatch = useDispatch();
  //
  const inventoryDetails = useSelector(inventoryDetailsListSelector);
  const { id: branch_id } = useSelector((state) => state.branch.data);
  const { formatted_component_permissions } = useSelector((state) => state.aclUserPermissions.data);
  const sellers = useSelector((state) => state.sellers);
  const companies = useSelector((state) => state.companies);
  //
  const [searchInventoryDetails, setSearchInventoryDetails] = useState("");
  const [showInventoryDetailsForm, setShowInventoryDetailsForm] = useState(false);
  const [inventoryDetailsFormData, setInventoryDetailsFormData] = useState(null);
  const [showDateRangeForm, setShowDateRangeForm] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const inventoryDetailsLoading = useSelector((state) => state.inventoryDetails.loading);
  const depLoading = useMemo(() => {
    return sellers.loading === LoadingState.unset || companies.loading === LoadingState.unset;
  }, [sellers, companies]);
  const depLoaded = useMemo(() => {
    return (
      sellers.loading === LoadingState.loaded &&
      companies.loading === LoadingState.loaded &&
      inventoryDetailsLoading === LoadingState.unset
    );
  }, [sellers, companies, inventoryDetailsLoading]);

  useEffect(() => {
    if (depLoading) {
      dispatch(sellersFetchData());
      dispatch(companiesFetchData());
    }

    if (depLoaded) dispatch(inventoryDetailsFetchData());
  }, [sellers, companies, inventoryDetailsLoading]);

  const addInventoryDetailsHandler = (data = null) => {
    setShowInventoryDetailsForm(true);
    if (data) {
      setInventoryDetailsFormData(data);
    }
  };

  const onAddUpdate = (type, data) => {
    if (type === 0) {
      dispatch(inventoryDetailsPostData(data));
    } else {
      dispatch(inventoryDetailsEditData(data.id, data));
    }
    closeForm();
  };

  const closeForm = () => {
    setShowInventoryDetailsForm(false);
    setInventoryDetailsFormData(null);
  };

  const closeDateRangeForm = () => {
    setShowDateRangeForm(false);
  };

  const downloadInventoryDetails = ({ start_date, end_date }) => {
    const type = "InventoryDetailReport";

    const data = {
      type,
      params: {
        start_date,
        end_date,
        branch_id,
      },
    };

    dispatch(downloadReportFromServer(data));
    closeDateRangeForm();
  };

  const getSearchedInventoryDetails = (search, inventoryDetails) => {
    const fuse = new Fuse(inventoryDetails, { keys: ["search_key"], threshold: 0.2 });
    const results = fuse.search(search).map(({ item }) => item);

    if (inventoryDetails?.length > 0 && search.length > 0) {
      return results;
    } else {
      return inventoryDetails;
    }
  };

  //
  let INVENTORY_DETAILS_DATA = getSearchedInventoryDetails(searchInventoryDetails, inventoryDetails);

  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;
  const isDownloadReportsAllowed = formatted_component_permissions.includes("download_reports");

  return (
    <div style={{ height: "100%" }}>
      <Modal style={{ width: "90%", maxWidth: "1200px" }} open={showInventoryDetailsForm}>
        <InventoryDetailsForm data={inventoryDetailsFormData} onAddUpdate={onAddUpdate} onClose={closeForm} />
      </Modal>
      {/* date selectors for downloading */}
      <Modal style={{ width: "90%", maxWidth: "700px" }} open={showDateRangeForm}>
        <DateRangeForm
          heading="Inventory Details Date Range"
          closeIcon={true}
          onClose={closeDateRangeForm}
          onSubmit={downloadInventoryDetails}
          maxRange={60}
        />
      </Modal>
      {/* inventory details upload form */}
      <Modal style={{ width: "90%", maxWidth: "600px" }} open={showUploadForm}>
        <Uploader
          type="inventoryDetails"
          closeIcon={true}
          onClose={() => {
            setShowUploadForm(false);
          }}
        />
      </Modal>
      {/*  */}
      {/* heading, search, download, add form */}
      <div>
        <h2>
          <Translate>Inventory Details</Translate>
        </h2>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Search>
            <Search.GeneralField
              value={searchInventoryDetails}
              onChange={(e) => {
                const changedValue = e.target.value;
                setSearchInventoryDetails(changedValue);
              }}
              placeholder="Search Details"
            />
          </Search>

          <div className="controller flex row" style={{ gap: "5px" }}>
            {INVENTORY_DETAILS_DATA?.length > 0 && isDownloadReportsAllowed && (
              <button
                disabled={isSubscriptionExpired}
                onClick={() => {
                  setShowDateRangeForm(true);
                }}
                style={{ padding: 5 }}
              >
                <AiOutlineCloudDownload size="20" title="download inventory details" />
              </button>
            )}

            <button
              disabled={isSubscriptionExpired}
              onClick={() => {
                setShowUploadForm(true);
              }}
              style={{ padding: 5 }}
            >
              <AiOutlineCloudUpload size="20" title="Upload Inventory Details" />
            </button>
            <button
              style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 5 }}
              onClick={() => {
                addInventoryDetailsHandler();
              }}
            >
              <AiOutlineFolderAdd size="20" title="add Inventory Detail" />
              <p>
                &nbsp;<Translate>Details</Translate>
              </p>
            </button>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="inventory-details-table" style={{ marginTop: "20px" }}>
        {INVENTORY_DETAILS_DATA?.length > 0 ? (
          <HoverTable
            header={inventoryDetailsHeaders}
            src={INVENTORY_DETAILS_DATA}
            renderSpecial={inventoryDetailsRenderSpecial()}
            edit={(id, data) => {
              addInventoryDetailsHandler(data);
            }}
          />
        ) : (
          <div className="no-data-error">
            <Translate>No inventory details found</Translate>
          </div>
        )}
      </div>
    </div>
  );
}

export default InventoryDetails;
