import React from "react";
import { Form } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import "./index.scss";

export const ViewSpecificServiceProducts = (props) => {
  const { type, dataObj, filteredShowValue } = props;
  const { discount_type, discount_type_value, max_discount_amount } = dataObj;
  return (
    <>
      <Form.Group widths="equal">
        <Form.Field>
          <label>{type}</label>
          <input
            type="text"
            value={filteredShowValue && filteredShowValue.name}
            className="textarea"
            readOnly="readonly"
            title={filteredShowValue && filteredShowValue.name}
          />
        </Form.Field>
        <Form.Field className="grouped-radio">
          <label>
            <Translate>Discount Type</Translate>
          </label>
          <Form.Radio label="Percentage" checked={discount_type === 0} />
          <Form.Radio label="Value" checked={discount_type === 1} />
        </Form.Field>
        <Form.Field>
          <label>
            <Translate>Discount {["%", "Value"][discount_type]}</Translate>
          </label>
          <input
            type="number"
            placeholder={"Enter Discount " + ["Percentage", "Value"][discount_type]}
            value={discount_type_value}
            readOnly="readonly"
          />
        </Form.Field>
        <Form.Field>
          <label>
            <Translate>Max Discount</Translate>
          </label>
          <input type="number" placeholder="Enter Maximum Discount" value={max_discount_amount} readOnly="readonly" />
        </Form.Field>
      </Form.Group>
    </>
  );
};
