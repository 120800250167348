const initalState = [];

export const CartDetailReducer = (state = initalState, action) => {
  switch (action.type) {
    case "CART_LIST_SUCCESS": {
      return action.payload.results;
    }
    default:
      return state;
  }
};

export const updateCartProductQuantityReducer = (state = {}, action) => {
  switch (action.type) {
    case "CART_UPDATE_SUCCESS": {
      return action.payload;
    }
    default:
      return state;
  }
};

export const DeteteCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case "CART_ITEM_DELETE_SUCCESS": {
      return action.payload;
    }
    default:
      return state;
  }
};

export const addCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case "CART_ITEM_ADDED_SUCCESS": {
      return action.payload;
    }
    default:
      return state;
  }
};
