const initialState = {
    data: null,
    fetching: false,
    error: false,
  };
  
  export const campaignDataByIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case "CAMPAIGN_BY_ID_SUCCESS":
        return {
          ...state,
          data: action?.data,
          fetching: false,
          error: false,
        };
  
      case "CAMPAIGN_BY_ID_FETCHING":
        return {
          ...state,
          fetching: true,
          error: false,
        };
  
      case "CAMPAIGN_BY_ID_ERROR":
        return {
          ...state,
          fetching: false,
          error: true,
          data: null,
        };
  
      default:
        return state;
    }
  };
  