const initialState = {
  data: {},
  error: false,
};
export function branchShortName(state = initialState, action) {
  switch (action.type) {
    case "BRANCH_SHORT_NAME_FETCH_SUCCESS":
      return { ...initialState, data: action.data };
    case "BRANCH_SHORT_NAME_FETCH_ERROR":
      return { ...initialState, error: true };
    default:
      return state;
  }
}
