const showOnlyNew = (data) => data.filter((i) => !i.is_viewed);

export const notifications = (state = [], action) => {
  switch (action.type) {
    case "NOTIFICATION_DATA":
      return showOnlyNew(action.data);

    case "NEW_CLIENT_DOWNLOAD":
      const _temp = [...state, action.data];
      return showOnlyNew(_temp);

    default:
      return state;
  }
};
