import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import "./index.scss";

import { sendInvoice } from "../../store/actions/sendInvoice";

export const CumulativeInvoice = ({ invoice, data }) => {
  const isSubscriptionExpired = sessionStorage.getItem("subscription_valid_status") < 0;
  const { startDate, endDate, id } = data;

  function print() {
    document.getElementById("iframe-cumulative-invoice").contentWindow.print();
  }

  function send(type) {
    const url = "send/cumulative/invoice/" + type + "/" + id + "/" + startDate + "/" + endDate + "/";

    sendInvoice(url, type);
  }

  return (
    <div className="modal-container">
      {isSubscriptionExpired && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
          <p style={{ color: "red", fontSize: "16px" }}>
            <Translate>Subscription is Expired</Translate>
          </p>
        </div>
      )}
      {/*  */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <Button.Group>
          <Button onClick={print}>
            <Translate>Print</Translate> <Icon className="print" />
          </Button>
          <Button.Or />
          <Button disabled={isSubscriptionExpired} onClick={() => send("email")}>
            <Translate>Email</Translate> <Icon className="mail" />
          </Button>
          <Button.Or />
          <Button disabled={isSubscriptionExpired} onClick={() => send("sms")}>
            <Translate>SMS</Translate> <Icon className="phone" />
          </Button>
          <Button.Or />
          <Button disabled={isSubscriptionExpired} onClick={() => send("whatsApp")}>
            <Translate>WhatsApp</Translate>
            <Icon className="whatsapp" />
          </Button>
        </Button.Group>
      </div>
      {/*  */}
      <iframe
        id="iframe-cumulative-invoice"
        srcDoc={`${invoice}`}
        frameborder="0"
        height={"100%"}
        className="print-invoice thermal"
        style={{ overflow: "auto" }}
      />
    </div>
  );
};
