import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Dropdown } from "semantic-ui-react";

import { clientLabelOptionsSelector } from "../../store/selectors/labelOptions";

function ClientLabelsFilter({ onFiltersChange }) {
  const labelOptions = useSelector(clientLabelOptionsSelector);
  const [openLabelFilters, setOpenLabelFilters] = useState(false);
  const [isLabelsChanged, setIsLabelsChanged] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);

  return (
    <div>
      <Dropdown
        style={{ minWidth: "325px" }}
        placeholder="Filter By Labels (Maximum 3 Labels)"
        fluid
        selection
        multiple
        open={openLabelFilters}
        onChange={(_, data) => {
          const { value } = data;

          if (value.length > 3) {
            return;
          }

          setSelectedLabels(value);
          setIsLabelsChanged(true);
          setOpenLabelFilters(true);
        }}
        onClick={() => {
          setOpenLabelFilters(true);
        }}
        value={selectedLabels}
        options={labelOptions}
        onClose={() => {
          if (!isLabelsChanged) {
            setOpenLabelFilters(false);
            return;
          }

          setOpenLabelFilters(false);
          setIsLabelsChanged(false);
          onFiltersChange(selectedLabels);
        }}
      />
    </div>
  );
}

export default ClientLabelsFilter;
