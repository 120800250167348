import { LoadingState } from "./types";

const initialState = {
  data: {},
  loading: LoadingState.unset,
};
export const addAppointmentPaymentMethod = (state = initialState, action) => {
  switch (action.type) {
    case "APPOINTMENT_PAYMENT_UPDATE_LOADING":
      return { ...state, ...action.response, loading: LoadingState.loading };
    case "APPOINTMENT_PAYMENT_UPDATE_SUCCESS":
      return { ...state, ...action.response, loading: LoadingState.loaded };
    case "APPOINTMENT_PAYMENT_UPDATE_UNSET_LOADING":
      return {
        ...state,
        loading: LoadingState.unset,
      };

    default:
      return state;
  }
};

export const appointmentPaymentMethod = (state = [], action) => {
  switch (action.type) {
    case "APPOINTMENT_PAYMENT_SUCCESS":
      return action.payload;

    default:
      return state;
  }
};
