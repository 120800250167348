export function promotion(state = { status: null, loading: false }, action) {
  switch (action.type) {
    case "PROMOTION_SUCCESS":
      return { status: true, loading: false };
    case "PROMOTION_HAS_ERRORED":
      return { status: false, loading: false };
    case "PROMOTION_LOADING":
      return { status: null, loading: true };
    case "PROMOTION_RESET":
      return { status: null, loading: false };
    default:
      return state;
  }
}
