import React, { useContext, useState, useRef, useEffect } from "react";
import { createContext } from "react";
import {
  Container,
  Title,
  FieldContainer,
  TextFieldContainer,
  TextField,
  Description,
  FormDescriptionIcon,
  DescriptionText,
  TextFieldSpan,
  DateContainer,
  Date,
  DateSpan,
  SubmitButtonContainer,
  SubmitButtonText,
  CurrentDateContainer,
  CurrentDate,
  DividerContainer,
  Divider,
  FormSubHeadingContainer,
  SubHeadingText,
  SubHeadingOpenClose,
  RadioContainer,
  RadioLabel,
  RadioButtonText,
  RadioButton,
  AsyncSelectContainer,
  AsyncSelectOption,
  AsyncSelectContainerMain,
  AsyncSelectOptionContainer,
  AsyncSelectOptionSpan,
  PriceFieldContainer,
  PriceFieldSpan,
  PriceField,
  AddNewSelectField,
  SingleLineFieldsContainer,
} from "./styles/styles.js";
import accordionUp from "./accordion-up.svg";
import accordionDown from "./accordion-down.svg";
import { FormInput } from "semantic-ui-react";
import { Translate } from "react-auto-translate";

const TextFieldContext = createContext(null);
const DateContext = createContext(null);

const AsyncSelectContext = createContext(null);
const PriceSelectContext = createContext(null);

export default function Form({ children, ...restOfTheProps }) {
  return <Container {...restOfTheProps}>{children}</Container>;
}

Form.Title = function FormTitle({ children, ...restOfTheProps }) {
  return <Title {...restOfTheProps}>{children}</Title>;
};

Form.FieldContainer = function FormFieldContainer({ children, ...restOfTheProps }) {
  return <FieldContainer {...restOfTheProps}>{children}</FieldContainer>;
};

Form.TextFieldContainer = function FormTextFieldContainer({ children, ...restOfTheProps }) {
  const [isActive, setIsActive] = useState(false);
  const TextFieldInput = useRef(null);
  return (
    <TextFieldContainer
      isActive={isActive}
      onClick={() => {
        TextFieldInput.current.focus();
      }}
      onFocus={() => {
        setIsActive(true);
      }}
      onBlur={() => {
        setIsActive(false);
      }}
      {...restOfTheProps}
    >
      <TextFieldContext.Provider value={{ isActive, setIsActive, TextFieldInput }}>
        {children}
      </TextFieldContext.Provider>
    </TextFieldContainer>
  );
};

Form.TextField = function FormTextField({ placeholder, onChange, value, ...restOfTheProps }) {
  const { isActive, setIsActive, TextFieldInput } = useContext(TextFieldContext);
  return (
    <>
      <TextField
        value={value}
        ref={TextFieldInput}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        onChange={onChange}
        {...restOfTheProps}
      />
      <TextFieldSpan isActive={isActive || (value && value.length > 0)}>
        <Translate>{placeholder}</Translate>
      </TextFieldSpan>
    </>
  );
};

Form.Description = function FormDescription({ children, ...restOfTheProps }) {
  return <Description {...restOfTheProps}>{children}</Description>;
};

Form.Icon = function FormIcon({ children, ...restOfTheProps }) {
  return <FormDescriptionIcon {...restOfTheProps}>{children}</FormDescriptionIcon>;
};

Form.DescriptionText = function FormDescriptionText({ children, ...restOfTheProps }) {
  return <DescriptionText {...restOfTheProps}>{children}</DescriptionText>;
};

Form.DateContainer = function FormDateContainer({ children, alwaysActive = false, ...restOfTheProps }) {
  const [isActive, setIsActive] = useState(false);
  const DateInput = useRef(null);

  useEffect(() => {
    if (alwaysActive) {
      setIsActive(alwaysActive);
    }
  }, [alwaysActive]);

  return (
    <DateContainer
      isActive={isActive}
      onClick={() => {
        DateInput.current.focus();
      }}
      onFocus={() => {
        if (!alwaysActive) {
          setIsActive(true);
        }
      }}
      onBlur={() => {
        if (!alwaysActive) {
          setIsActive(false);
        }
      }}
      {...restOfTheProps}
    >
      <DateContext.Provider value={{ isActive: alwaysActive || isActive, setIsActive, DateInput }}>
        {children}
      </DateContext.Provider>
    </DateContainer>
  );
};

Form.Date = function FormDate({ placeholder, onChange, value, ...restOfTheProps }) {
  const { isActive, setIsActive, DateInput } = useContext(DateContext);
  return (
    <>
      <Date
        showDate={isActive || (value && value.length > 0)}
        value={value}
        ref={DateInput}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        onChange={onChange}
        {...restOfTheProps}
      />
      <DateSpan isActive={isActive || (value && value.length > 0)}>
        <Translate>{placeholder}</Translate>
      </DateSpan>
    </>
  );
};

Form.SubmitButton = function FormSubmitButton({ children, ...restOfTheProps }) {
  return (
    <SubmitButtonContainer {...restOfTheProps}>
      <SubmitButtonText>{children}</SubmitButtonText>
    </SubmitButtonContainer>
  );
};

Form.CurrentDate = function FormCurrentDate({ children, ...restOfTheProps }) {
  return (
    <CurrentDateContainer {...restOfTheProps}>
      <CurrentDate>{children}</CurrentDate>
    </CurrentDateContainer>
  );
};

Form.Divider = function FormDivider({ ...restOfTheProps }) {
  return (
    <DividerContainer {...restOfTheProps}>
      <Divider></Divider>
    </DividerContainer>
  );
};

Form.SubHeading = function FormSubHeading({ children, onClick, isOpen, ...restOfTheProps }) {
  return (
    <FormSubHeadingContainer onClick={onClick} {...restOfTheProps}>
      <SubHeadingText>{children}</SubHeadingText>
      <SubHeadingOpenClose src={isOpen ? accordionDown : accordionUp} alt="Accordion" />
    </FormSubHeadingContainer>
  );
};

Form.Radio = function FormRadio({ label, children, ...restOfTheProps }) {
  return (
    <RadioContainer {...restOfTheProps}>
      <RadioLabel>{label}</RadioLabel>
      {children}
    </RadioContainer>
  );
};

Form.RadioButton = function FormRadioButton({ value, name, children, ...restOfTheProps }) {
  return (
    <RadioButton name={name} {...restOfTheProps} isSelected={value === name} isActive={value.length > 0}>
      <RadioButtonText isSelected={value === name}>{children}</RadioButtonText>
    </RadioButton>
  );
};

Form.AsyncSelect = function FormAsyncSelect({ disabled, placeholder, toDisplay, value, children, ...restOfTheProps }) {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <AsyncSelectContainerMain
      onBlur={() => {
        setShowOptions(false);
      }}
      onFocus={() => {
        setShowOptions(true);
      }}
      value={value}
      {...restOfTheProps}
    >
      <AsyncSelectContainer
        onClick={() => {
          if (!disabled) setShowOptions((prev) => !prev);
        }}
        isActive={showOptions}
      >
        {toDisplay}
        <SubHeadingOpenClose src={showOptions ? accordionUp : accordionDown} alt="Accordion" />
      </AsyncSelectContainer>
      <AsyncSelectOptionSpan isActive={showOptions || toDisplay !== placeholder}>
        <Translate>{placeholder}</Translate>
      </AsyncSelectOptionSpan>
      {showOptions && (
        <AsyncSelectOptionContainer>
          <AsyncSelectContext.Provider value={{ showOptions, setShowOptions }}>{children}</AsyncSelectContext.Provider>
        </AsyncSelectOptionContainer>
      )}
    </AsyncSelectContainerMain>
  );
};

Form.AsyncSelectOption = function FormAsyncSelectOption({ onClick, children, ...restOfTheProps }) {
  const { setShowOptions } = useContext(AsyncSelectContext);
  return (
    <AsyncSelectOption
      {...restOfTheProps}
      onClick={() => {
        onClick();
        setShowOptions(false);
      }}
    >
      {children}
    </AsyncSelectOption>
  );
};

Form.PriceFieldContainer = function FormPriceFieldContainer({ children, ...restOfTheProps }) {
  const [isActive, setIsActive] = useState(false);
  const PriceInput = useRef(null);
  return (
    <PriceFieldContainer
      isActive={isActive}
      onClick={() => {
        PriceInput.current.focus();
      }}
      onFocus={() => {
        setIsActive(true);
      }}
      onBlur={() => {
        setIsActive(false);
      }}
      {...restOfTheProps}
    >
      <PriceSelectContext.Provider value={{ isActive, setIsActive, PriceInput }}>
        {children}
      </PriceSelectContext.Provider>
    </PriceFieldContainer>
  );
};

Form.PriceField = function FormPriceField({ placeholder, onChange, value, ...restOfTheProps }) {
  const { isActive, setIsActive, PriceInput } = useContext(PriceSelectContext);
  return (
    <>
      <PriceField
        value={value}
        ref={PriceInput}
        onFocus={(e) => {
          setIsActive(true);
          e.target.select();
        }}
        onBlur={() => {
          if (value.length === 0 || isNaN(value)) setIsActive(false);
        }}
        onChange={onChange}
        {...restOfTheProps}
      />
      <PriceFieldSpan isActive={isActive || value.length > 0 || !isNaN(value)}>{placeholder}</PriceFieldSpan>
    </>
  );
};

Form.SingleLineFieldsContainer = function FormSingleLineFieldsContainer({ children, ...restOfTheProps }) {
  return <SingleLineFieldsContainer {...restOfTheProps}>{children}</SingleLineFieldsContainer>;
};

Form.AddNewSelectField = function FormAddNewSelectField({ children, ...restOfTheProps }) {
  return <AddNewSelectField {...restOfTheProps}>{children}</AddNewSelectField>;
};
