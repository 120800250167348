const initalState = {
  results: [],
  pages: 0,
  current_page: 0,
};

export const orderListReducer = (state = initalState, action) => {
  switch (action.type) {
    case "ORDER_LIST_SUCCESS":
      return action.payload;

    default:
      return state;
  }
};
