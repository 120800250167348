import React, { useState } from "react";
import FileUpload from "FileUpload";
import { Button, Icon } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";

import { uploadDataFile, fileUploadReset } from "../../store/actions/uploadDataFile";
import { fileUploaderTypes } from "./uploadFileTypes";

function Uploader({ type = "", closeIcon = false, onClose = () => {} }) {
  const dispatch = useDispatch();
  const uploadFileStatus = useSelector((state) => state.uploadDataFile);

  const [upload, setUpload] = useState([]);

  const { title = "", infoTitle = "", sampleFileLink = "#" } = type && fileUploaderTypes[type];

  const closeForm = () => {
    dispatch(fileUploadReset());
    onClose();
  };

  return (
    <div className="upload-container" style={{ minWidth: 500, padding: closeIcon ? "20px 40px 40px" : "10px" }}>
      {closeIcon && <Icon className="close pointer closeIcon" onClick={closeForm} style={{ padding: "10px" }} />}
      <h2>
        <Translate>{title}</Translate>
      </h2>
      <p className="info" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <Icon name="info" />
          <Translate>{infoTitle}</Translate>
        </div>
        <a className="link" href={sampleFileLink}>
          <Translate>Download Sample file</Translate>
        </a>
      </p>
      <div style={{ flexDirection: "row", display: "flex", gap: "20px", marginTop: "40px" }}>
        <FileUpload
          limit={1}
          onChange={(value) => setUpload(value)}
          value={upload || []}
          ALLOWED_TYPES={["xlsx", "xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
        />
        <Button
          loading={uploadFileStatus.loading}
          disabled={upload.length > 0 ? false : true}
          onClick={() => {
            dispatch(uploadDataFile(upload));
            dispatch(fileUploadReset());
          }}
        >
          <Translate>Upload</Translate>
        </Button>
      </div>
      {uploadFileStatus.error && (
        <div className="error-container">
          <p style={{ color: "red" }}>
            Error: <span>{JSON.stringify(uploadFileStatus.error)}</span>
          </p>
        </div>
      )}
      {uploadFileStatus.success && (
        <div className="error-container">
          <p style={{ color: "green" }}>
            <Translate>Success</Translate>
          </p>
        </div>
      )}
    </div>
  );
}

export default Uploader;
