import React from "react";
import { Icon, TextArea, Input, Form, Divider } from "semantic-ui-react";
import PromotionActions from "./Actions";
import { getUTCTime } from "../../utilities/dateTime";
import { Translate } from "react-auto-translate";
import { validatePhone } from "../../utilities/CommonFunctions";

const ALLOWED_MAX_FILE_SIZE = 1048576;
const ALLOWED_IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
const ALLOWED_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xls",
  "application/xlsx",
  "text/csv",
];

const WhatsappPromotionUI = ({
  isBulk,
  data,
  update,
  clientFilter,
  labelFilter,
  send,
  selectImageFile,
  selectedClients,
  selectContactFile,
}) => {
  return (
    <div className="whatsapp-promotion-container">
      {isBulk && (
        <>
          <h4>
            Upload contacts in excel/csv with maximum size of {parseInt(ALLOWED_MAX_FILE_SIZE / 1000000)} Mb(download
            format &nbsp;&nbsp;{" "}
            <a href="https://s3.ap-south-1.amazonaws.com/app.downloadables/contacts_sample.xlsx" download>
              here
            </a>
            )
          </h4>
          <div className="flex column width-100" style={{ padding: "10px", justifyContent: "center" }}>
            <label>
              <Translate>Select an excel file:</Translate>
            </label>
            {data.file ? (
              <span>
                {data.file.name} <Icon className="cancel pointer" onClick={() => selectContactFile()} />
              </span>
            ) : (
              <input type="file" onChange={(e) => selectContactFile(e)} />
            )}
          </div>
          {data?.error && <p style={{ color: "orange", fontSize: 12 }}>{data?.error}</p>}
          <Divider />
        </>
      )}

      <h4>
        <Translate>WhatsApp promotions are sent after 24 working hours!</Translate>
      </h4>
      <div
        className="flex column width-100"
        style={{
          padding: "10px",
          justifyContent: "center",
        }}
      >
        <label>
          <Translate>Select Promotional Image:</Translate>
        </label>
        {data.image ? (
          <span>
            {data.image.name}
            <Icon
              className="cancel pointer"
              onClick={() =>
                update({
                  ...data,
                  image: null,
                })
              }
            />
          </span>
        ) : (
          <input type="file" onChange={selectImageFile} />
        )}
      </div>
      {data.error && <p style={{ color: "orange", fontSize: 12 }}>{data.error}</p>}
      <label style={{ display: "flex", justifyContent: "space-between" }}>
        <Translate>Input Promotion Message:</Translate>
        <span>{data.message?.length || 0}/1000 Characters</span>
      </label>
      <Form style={{ marginBottom: "10px" }}>
        <TextArea
          placeholder="Enter message..."
          onChange={(e) => update({ ...data, message: e.target.value })}
          value={data.message || ""}
          maxLength={1000}
        />
      </Form>
      {/* callback numbers */}
      <label>
        <Translate>Add Callback Numbers (Max 4 Callback Numbers) *</Translate>
      </label>
      <Form>
        <TextArea
          placeholder="Add Callback Numbers *"
          onChange={(e) => {
            update({ ...data, call_back_number: e.target.value });
          }}
          value={data.call_back_number || ""}
        />
      </Form>
      <div style={{ marginTop: "16px" }}>
        <PromotionActions
          isBulk={isBulk}
          selected={selectedClients}
          clientFilter={clientFilter}
          labelFilter={labelFilter}
          promotionType="WHATSAPP"
          sendSMSError={data.error}
          recurring={data.scheduleDetails}
          updateRecurring={(payload) => update({ ...data, error: "", scheduleDetails: { ...payload } })}
          send={send}
        />
      </div>
    </div>
  );
};

export default function WhatsAppPromotion({
  isBulk = false,
  show,
  branch,
  data,
  update,
  clientFilter,
  send,
  selectedClients,
  labelFilter,
}) {
  if (!show) return null;

  const selectImageFile = (ev) => {
    if (ev?.target?.files?.length) {
      const { size, type } = ev.target.files[0];
      if (size > ALLOWED_MAX_FILE_SIZE || !ALLOWED_IMAGE_TYPES.includes(type)) {
        update({ ...data, error: "Please check file type and size." });
        return;
      }

      const image = ev.target.files[0];
      image["key"] = new Date().valueOf().toString();
      update({ ...data, image, error: "" });
      return;
    }
    update({ ...data, image: null, error: "" });
  };

  const selectContactFile = (ev) => {
    if (ev) {
      const { name, size, type } = ev.target.files[0];
      if (size > ALLOWED_MAX_FILE_SIZE || !ALLOWED_FILE_TYPES.includes(type)) {
        update({ ...data, error: "Please check file type and size" });
      } else {
        const parts = name.split(".");
        const extension = parts[parts.length - 1];
        const file = ev.target.files[0];
        file["key"] = new Date().valueOf().toString() + "." + extension;
        update({ ...data, file, error: null });
      }
    } else {
      update({ ...data, file: null, error: null });
    }
  };

  const validateCallbacks = (callbacks) => {
    const phoneNumbers = callbacks.split("/");

    for (let phone of phoneNumbers) {
      phone = phone.trim();
      const isCallbackValid = validatePhone(phone);

      if (!isCallbackValid) {
        return { error: true, errorMessage: "Please enter valid / separated callback numbers" };
      }
    }

    const minCallbacks = 1;
    const maxCallbacks = 4;

    if (phoneNumbers.length < minCallbacks) {
      return { error: true, errorMessage: "Callback number is mandatory" };
    }

    if (phoneNumbers.length > maxCallbacks) {
      return { error: true, errorMessage: "Maximum 4 callback numbers are allowed" };
    }

    return { error: false };
  };

  const validate = () => {
    if (isBulk && !data.file) {
      update({ ...data, error: "Please select an excel file" });
      return false;
    }

    if (!data.image && !data.message) {
      update({ ...data, error: "Please select an image and/or enter a message" });
      return false;
    }

    if (!data.call_back_number) {
      update({ ...data, error: "Callback Number is mandatory" });
      return false;
    }

    const callbacks = data.call_back_number;
    const isCallbackValid = validateCallbacks(callbacks);

    if (isCallbackValid.error) {
      update({ ...data, error: isCallbackValid.errorMessage });
      return false;
    }

    update({ ...data, error: "" });

    const { scheduleDetails } = data;
    if (scheduleDetails.isActive) {
      switch (scheduleDetails.scheduleType) {
        case 0: {
          if (!scheduleDetails.time) {
            update({ ...data, error: "Enter schedule time to send message" });
            return false;
          }
          break;
        }

        case 1: {
          if (!scheduleDetails.time) {
            update({ ...data, error: "Enter schedule time to send message" });
            return false;
          }

          if (scheduleDetails.day === "") {
            update({ ...data, error: "Enter day of the week to send message" });
            return false;
          }
          break;
        }

        case 2: {
          if (!scheduleDetails.time) {
            update({ ...data, error: "Enter schedule time to send message" });
            return false;
          }

          if (!scheduleDetails.singleDate) {
            update({ ...data, error: "Enter date of the month to send message" });
            return false;
          }
          break;
        }
      }
    }

    // because dont require the client data if it is bulk promotion
    if (!isBulk && !selectedClients.allClientsSelected && !selectedClients.excluded_clients) {
      if (selectedClients.selected_clients && !selectedClients.selected_clients.ids?.length) {
        update({ ...data, error: "No Client is selected" });
        return false;
      }
    }

    // validated
    // format the data

    const payload = {
      message: data.message,
      image: data.image,
      client_filter_id: null,
      send_to_all: !!(selectedClients.allClientsSelected || selectedClients.excluded_clients),
      file: data?.file || null,
      template: null,
      value: null,
      excluded_customer_ids: selectedClients.excluded_clients ? selectedClients.excluded_clients?.ids : null,
      customer_ids: selectedClients.selected_clients ? selectedClients.selected_clients?.ids : null,
      call_back_number: data.call_back_number,
      label_ids: null,
    };

    if (!selectedClients.selected_clients && (clientFilter?.key || clientFilter?.value)) {
      payload.client_filter_id = clientFilter?.key || clientFilter?.value;
      // if filter_id is populated, we're sending promotion to everyone in that filter_id
    }

    if (!selectedClients.selected_clients && labelFilter.length) {
      payload.label_ids = labelFilter;
    }

    if (scheduleDetails.isActive) {
      payload["type"] = 2; // 2 being WHATSAPP
      payload["recurring_frequency"] = 1;
      payload["schedule_time"] = null;
      payload["schedule_day"] = null;
      payload["schedule_day_number"] = null;
      switch (scheduleDetails.scheduleType) {
        case 0: {
          // daily
          payload["recurring_frequency"] = 0;
          break;
        }
        case 1: {
          // weekly
          payload["recurring_frequency"] = 1;
          payload["schedule_day"] = scheduleDetails.day;
          break;
        }
        case 2: {
          // monthly
          payload["recurring_frequency"] = 2;
          payload["schedule_day_number"] = scheduleDetails.singleDate;
          break;
        }
      }
      // convert the following time to utc
      payload["schedule_time"] = getUTCTime(scheduleDetails.time);
    } else {
      payload["promotion_type"] = "WHATSAPP";
    }

    if (isBulk) {
      payload.client_filter_id = null;
      payload.label_ids = null;
      payload.send_to_all = false;
      payload.customer_ids = null;
      payload.excluded_customer_ids = null;
    }

    send(payload);
  };

  return (
    <WhatsappPromotionUI
      isBulk={isBulk}
      branch={branch}
      data={data}
      update={update}
      clientFilter={clientFilter}
      labelFilter={labelFilter}
      send={validate}
      selectImageFile={selectImageFile}
      selectedClients={selectedClients}
      selectContactFile={selectContactFile}
    />
  );
}
