import React from "react";
import "./index.scss";
import { Icon } from "semantic-ui-react";

function CustomSearch({ onChange, value, placeholder, style }) {
  return (
    <div className="custom-search" style={style}>
      <Icon name="search" className="search-icon" />
      <input
        placeholder={placeholder || "search"}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        className="searchInput"
      />
    </div>
  );
}

export default CustomSearch;
