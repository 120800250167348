import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { Translate } from "react-auto-translate";
import { Icon } from "semantic-ui-react";
import "./index.scss";

export const ConformationModal = (props) => {
  const { message, isOpen, confirmFunction, cancelFunction } = props;
  return (
    <Modal open={isOpen} size="mini">
      {/* <Modal.Header>Delete Your Account</Modal.Header> */}
      <Modal.Content id="content">
        <Icon id="icon" name="exclamation circle" size="big" />
        <div className="cnt-msg">
          <Translate>{message}</Translate>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => cancelFunction()}>
          <Translate>Cancel</Translate>
        </Button>
        <Button className="btn-conform" onClick={() => confirmFunction()}>
          <Translate>Ok</Translate>
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
