import React, { useEffect } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Translate } from "react-auto-translate";
import HoverTable from "HoverTable";
import Description from "Description";
import { Button } from "semantic-ui-react";
import moment from "moment";

function AppointmentDeleteModal({ data, onConfirm, onSkip, onViewInvoice }) {
  const branch = useSelector((state) => state.branch.data);
  const clientMembershipsHeaders = ["Invoice No", "Serial No", "Date"];
  const renderSpecial = [
    ({ id, customer }) => {
      return (
        <a style={{ cursor: "pointer" }} onClick={() => onViewInvoice({ id: id, customer: customer })}>
          <Description title={`${branch.invoice_pre_text}${id}`} />
        </a>
      );
    },
    ({ appointment_number }) => {
      return <Description title={`${branch.invoice_pre_text}${appointment_number}`} />;
    },
    ({ datetime }) => {
      return <Description title={moment(datetime).format("DD-MM-YY hh:mm")} />;
    },
  ];

  return (
    <div className="appointment-delete-modal">
      <h3>
        <Translate>Are you sure, you want to delete this appointment ?</Translate>
      </h3>
      <p style={{ color: "red" }}>
        All Appointments associated with membership created in this appointment will get deleted
      </p>
      <p style={{ color: "red" }}>All Services and Products usage will get reset according to this</p>

      <p>Below Appointments will get deleted</p>
      <HoverTable header={clientMembershipsHeaders} src={data} renderSpecial={renderSpecial} />
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
        <Button
          onClick={() => {
            onSkip();
          }}
        >
          No
        </Button>
        <Button
          color="blue"
          onClick={() => {
            onConfirm();
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
}

export default AppointmentDeleteModal;
