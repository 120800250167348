import { API_BASE_URL, BRANCH_API } from "utilities";
import { IsJsonString } from "../../utilities/CommonFunctions";
import axios from "axios";

export function branchHasErrored(message) {
  return {
    type: "BRANCH_HAS_ERRORED",
    response: {
      status: false,
      error: message,
      loading: false,
    },
  };
}

export function branchSuccess() {
  return {
    type: "BRANCH_SUCCESS",
    response: {
      status: true,
      error: "",
      loading: false,
    },
  };
}

export function resetRegister() {
  return {
    type: "BRANCH_RESET",
    response: {
      status: false,
      error: "",
      loading: false,
    },
  };
}

export function resetLoading() {
  return {
    type: "BRANCH_LOADING",
    response: {
      status: false,
      error: "",
      loading: true,
    },
  };
}

export function register(data) {
  return (dispatch) => {
    dispatch(resetLoading());
    axios({
      method: "post",
      url: API_BASE_URL + BRANCH_API,
      data: data,
    })
      .then(function (response) {
        if (response.status === 201) {
          dispatch(branchSuccess());
        } else {
          dispatch(branchHasErrored());
        }
      })
      .catch(function (error) {
        let message;
        if (error && error.response) {
          if (error.response.status === 500) {
            if (error.response.data?.detail) {
              message = IsJsonString(error.response.data.detail) ? error.response.data.detail : "Error";
            }
          }
        }
        dispatch(branchHasErrored(message));
      });
  };
}
