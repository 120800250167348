import React from "react";
import { useSelector } from "react-redux";
import { maskNumber } from "../utilities/CommonFunctions";

export const useMaskNumber = () => {
  const { formatted_component_permissions } = useSelector((state) => state.aclUserPermissions.data);
  const shouldMaskNumber = !formatted_component_permissions.includes("view_masked_client_number");

  const handleMaskNumber = (phone) => {
    if (shouldMaskNumber) {
      return maskNumber(phone);
    }

    return phone;
  };

  return {
    maskNumber: handleMaskNumber,
  };
};

export default useMaskNumber;
