import firebase from "firebase/app";
import "firebase/messaging";
import { registerFCMToken } from "./store/actions/notifications";

const firebaseConfig = {
  apiKey: "AIzaSyDLh5hIF6NCFaJpLUQ76OevBEYKE4HIEFI",
  authDomain: "glamplus-ab242.firebaseapp.com",
  projectId: "glamplus-ab242",
  storageBucket: "glamplus-ab242.appspot.com",
  messagingSenderId: "655119626795",
  appId: "1:655119626795:web:4ef2a486accf3a787ab06e",
  measurementId: "G-7H0VKNR7SJ",
};
let messaging = null;
const isSupported = firebase.messaging.isSupported();
if (isSupported) {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
}

export const getToken = (setTokenFound) => {
  return !isSupported
    ? ""
    : messaging
        .getToken({
          vapidKey: "BLdY_v0BulAM5i00IkKOoxWsP7JxIhFP70gLF92hyDsQYUl7xUSWrxCwoOBuBSwg7CPipk_a-xgu-cip2eh01gA",
        })
        .then((currentToken) => {
          if (currentToken) {
            registerFCMToken(currentToken);
            console.log("fcm token generated");
            // setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log("No registration token available. Request permission to generate one.");
            // setTokenFound(false);
            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // catch error while creating client token
        });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (isSupported) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    } else {
      resolve("");
    }
  });
