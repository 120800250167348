import { API_BASE_URL, CUSTOMER_NOTES } from "utilities";
import axios from "axios";
import { authenticationLogout } from "./authentication";

export function customerGetData(customer_id, page = 1) {
  const URL = API_BASE_URL + CUSTOMER_NOTES + "?search=" + customer_id + "&page=" + page;

  return (dispatch) => {
    axios
      .get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: "CUSTOMER_GET_DATA_SUCCESS",
            data: response.data,
          });
        } else {
          dispatch({
            type: "CUSTOMER_GET_DATA_ERROR",
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        } else {
          dispatch({
            type: "CUSTOMER_GET_DATA_ERROR",
          });
        }
      });
  };
}

export function customerPutData(customer_id, NoteId, data) {
  const URL = API_BASE_URL + CUSTOMER_NOTES + NoteId;
  return (dispatch) => {
    axios({
      method: "put",
      url: URL,
      data: data,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            action: "CUSTOMER_PUT_DATA_SUCCESS",
            data: response.results,
          });
        } else {
          dispatch({
            action: "CUSTOMER_PUT_DATA_ERROR",
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        } else {
          dispatch({
            action: "CUSTOMER_PUT_DATA_ERROR",
          });
        }
      });
  };
}

export function customerPostData(customer_id, data) {
  const URL = API_BASE_URL + CUSTOMER_NOTES;
  return (dispatch) => {
    axios({
      method: "post",
      url: URL,
      data: data,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          dispatch(customerGetData(customer_id));
        } else {
          dispatch({
            type: "CUSTOMER_POST_DATA_ERROR",
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          dispatch(authenticationLogout());
        } else {
          dispatch({
            type: "CUSTOMER_POST_DATA_ERROR",
          });
        }
      });
  };
}
