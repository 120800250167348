import { API_BASE_URL } from "utilities";
import axios from "axios";
import { endLoading, errored, startLoading } from "./withLoader";

function MembershipRevenueAnalyticsFetchDataSuccess(data) {
  return {
    type: "MEMBERSHIP_REVENUE_ANALYTICS_SUCCESS",
    data,
  };
}

export function membershipsRevenueAnalyticsFetchData(
  start_date = new Date().toISOString().split("T")[0],
  end_date = "",
  branch = ""
) {
  const urlParam = `analytics/staff/memberships/?start_date=${start_date}&end_date=${end_date}`;
  return (dispatch) => {
    dispatch(startLoading("STAFF_REVENUE_ANALYTICS_LOADER", "loading memberships revenue analytics"));
    axios
      .get(API_BASE_URL + urlParam, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(MembershipRevenueAnalyticsFetchDataSuccess(response.data.results));
          dispatch(endLoading("STAFF_REVENUE_ANALYTICS_LOADER"));
        } else {
          dispatch(errored("STAFF_REVENUE_ANALYTICS_LOADER", true));
          dispatch(endLoading("STAFF_REVENUE_ANALYTICS_LOADER"));
        }
      })
      .catch(function () {
        dispatch(errored("STAFF_REVENUE_ANALYTICS_LOADER", true));
        dispatch(endLoading("STAFF_REVENUE_ANALYTICS_LOADER"));
      });
  };
}
