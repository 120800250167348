import React, { useEffect, useState } from "react";
import "./index.scss";

import { Button, Checkbox, Input, Table, Label } from "semantic-ui-react";
import Paytm from "../../components/Paytm";

import { useDispatch, useSelector } from "react-redux";
import { getDueOrders } from "../../store/actions/marketplaceOrderPayments";
import { marketplaceStartTransaction } from "../../store/actions/marketplaceOrderTransactions";
// import { marketPlaceOrderTransaction } from "../../store/reducers/marketpalceOrderTransactions";
import { Translate } from "react-auto-translate";

const MINIMUM_PAYABLE_PERCENTAGE = 20;

function MarketplacePayments({ current_order_id }) {
  const dispatch = useDispatch();
  const dueOrders = useSelector((state) => state.marketPlaceDueOrders);
  const transactionDetails = useSelector((state) => state.marketPlaceOrderTransaction);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    dispatch(getDueOrders());
  }, [dispatch]);

  useEffect(() => {
    if (dueOrders.count) {
      const orders = dueOrders.data.results.map((order) => {
        return {
          order_id: order.id,
          created_at: new Date(order.created_at),
          due_date: new Date(order.payment_due_date),
          due_amount: order.total_cost - order.paid_amount,
          amount: order.total_cost - order.paid_amount,
          checked: true,
        };
      });
      setSelectedOrder(orders);
    }
    // eslint-disable-next-line
  }, [dueOrders.count]);

  const checkOrderPayment = (index, checked) => {
    let orderDetails = selectedOrder[index];
    orderDetails.checked = checked;
    setSelectedOrder([...selectedOrder]);
  };

  const updatePaymentAmount = (index, amount) => {
    // const minimum_due = (MINIMUM_PAYABLE_PERCENTAGE/100)*selectedOrder[0].due_amount;
    // console.log(minimum_due, amount)
    // if(amount < minimum_due) {
    //   return;
    // }
    let orderDetails = selectedOrder[index];
    orderDetails.amount = amount;
    setSelectedOrder([...selectedOrder]);
  };

  const startPayment = () => {
    const selectedOrderPayments = selectedOrder.filter((order) => {
      return order.checked;
    });
    let orderErrors = "";
    // eslint-disable-next-line
    selectedOrderPayments.map((order) => {
      if (order.amount < (MINIMUM_PAYABLE_PERCENTAGE / 100) * order.due_amount) {
        orderErrors += `#${order.order_id}, `;
      }
    });
    if (orderErrors) {
      let errors = "Payment amount in orders - ";
      errors += orderErrors;
      errors += `is less than the minimum payment amount(${MINIMUM_PAYABLE_PERCENTAGE}%).`;
      setPaymentError(errors);
    } else {
      setPaymentError("");
      dispatch(marketplaceStartTransaction(selectedOrderPayments));
    }
  };

  let transactionDetailsAdapter = null;
  if (transactionDetails.data) {
    transactionDetailsAdapter = {
      ...transactionDetails.data,
      cost: transactionDetails.data.amount,
      txnJsUrl: transactionDetails.data.txn_js_url,
    };
  }

  return (
    <div className="marketplace-payments">
      <div>
        {!!dueOrders.count && (
          <Table celled compact definition>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell><Translate>Order Id</Translate></Table.HeaderCell>
                <Table.HeaderCell><Translate>Order Date</Translate></Table.HeaderCell>
                <Table.HeaderCell><Translate>Due Date</Translate></Table.HeaderCell>
                <Table.HeaderCell><Translate>Due Amount</Translate></Table.HeaderCell>
                <Table.HeaderCell>
                  <Translate>Pay Amount</Translate>
                  <br />
                  <span><Translate>(Edit amount)</Translate></span>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {selectedOrder.map((item, key) => {
                return (
                  <Table.Row key={key}>
                    <Table.Cell collapsing>
                      {item.order_id === current_order_id && <Label ribbon>New</Label>}
                      {item.order_id !== current_order_id && (
                        <Checkbox
                          slider
                          checked={item.checked}
                          onChange={(e, data) => {
                            checkOrderPayment(key, data.checked);
                          }}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>{key + 1}</Table.Cell>
                    <Table.Cell>#{item.order_id}</Table.Cell>
                    <Table.Cell>{item.created_at.toLocaleDateString()}</Table.Cell>
                    <Table.Cell>{item.due_date.toLocaleDateString()}</Table.Cell>
                    <Table.Cell>{item.due_amount}/-</Table.Cell>
                    <Table.Cell>
                      <Input
                        className="pay-input"
                        type="number"
                        // label='/-'
                        // labelPosition='right'
                        disabled={!item.checked}
                        value={item.amount}
                        onChange={(e) => {
                          updatePaymentAmount(key, e.currentTarget.value);
                        }}
                        // onBlue={() => {
                        //   alert()
                        // }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row className="action-row">
                <Table.HeaderCell colSpan="6">
                  Total Payable(
                  {selectedOrder.reduce((acc, cur) => (cur.checked ? ++acc : acc), 0)}
                  &nbsp;&nbsp;orders selected )
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {selectedOrder.reduce((a, b) => a + parseFloat(b.checked ? b.amount : 0), 0) || 0.0}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row className="action-row">
                <Table.HeaderCell colSpan="7">
                  <div className="payment-btn-error">
                    <Button
                      primary
                      disabled={!selectedOrder.reduce((a, b) => a + parseFloat(b.checked ? b.amount : 0), 0)}
                      onClick={startPayment}
                    >
                      <Translate>Pay Now</Translate>
                    </Button>
                    <span className="error">{paymentError}</span>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        {!dueOrders.count && <span><Translate>No due orders.</Translate></span>}
        {!!dueOrders.count && (
          <div>
            <span><Translate>*new unpaid orders automatically get cancelled after 30 min.</Translate></span>
            <br />
            <span><Translate>**minimum amount payable is</Translate> {MINIMUM_PAYABLE_PERCENTAGE}<Translate>% or the due amount.</Translate></span>
          </div>
        )}
      </div>
      {transactionDetailsAdapter && (
        <Paytm
          params={transactionDetailsAdapter}
          closed={() => {
            dispatch({
              type: "MARKETPLACE_START_TRANSACTION_RESET",
            });
          }}
        />
      )}
    </div>
  );
}

export default MarketplacePayments;
