export function subscriptionDiscount(
  state = {
    error: false,
    loading: false,
    data: [],
  },
  action
) {
  switch (action.type) {
    case "SUBSCRIPTIONS_DISCOUNT_SUCCESS":
      return action;
    case "SUBSCRIPTIONS_DISCOUNT_HAS_ERRORED":
      return action;
    default:
      return state;
  }
}
