const initClientLabels = {
  data: [],
  isFetching: false,
  error: false,
};

export function clientLabels(state = initClientLabels, action) {
  switch (action.type) {
    case "FETCH_CLIENT_LABELS_FETCHING": {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case "FETCH_CLIENT_LABELS_SUCCESS": {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case "FETCH_CLIENT_LABELS_FAILURE": {
      return {
        ...state,
        error: true,
      };
    }
    default:
      return state;
  }
}

const initServiceLabels = {
  data: [],
  isFetching: false,
  error: false,
};

export function serviceLabels(state = initServiceLabels, action) {
  switch (action.type) {
    case "FETCH_SERVICE_LABELS_FETCHING": {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case "FETCH_SERVICE_LABELS_SUCCESS": {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case "FETCH_SERVICE_LABELS_FAILURE": {
      return {
        ...state,
        error: true,
      };
    }
    default:
      return state;
  }
}

const initProductLabels = {
  data: [],
  isFetching: false,
  error: false,
};

export function productLabels(state = initProductLabels, action) {
  switch (action.type) {
    case "FETCH_PRODUCT_LABELS_FETCHING": {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case "FETCH_PRODUCT_LABELS_SUCCESS": {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case "FETCH_PRODUCT_LABELS_FAILURE": {
      return {
        ...state,
        error: true,
      };
    }
    default:
      return state;
  }
}
